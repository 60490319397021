import AsyncStorage from '@react-native-community/async-storage';
import { CachePersistor } from 'apollo-cache-persist';
import { getItem, removeItem, setItem } from 'localstorage-idb-keyval';
import { Platform } from 'react-native';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import introspectionQueryResultData from './fragmentTypes.json';

export const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

export const cache = new InMemoryCache({
  freezeResults: true,
  fragmentMatcher,
});

export const persistor = new CachePersistor({
  cache,
  key: 'tp-data',
  storage:
    Platform.OS === 'web' ? { setItem, getItem, removeItem } : AsyncStorage,
});
