import { ApolloClient } from 'apollo-client';
import produce from 'immer';
import {
  ListMessagesDocument,
  ListMessagesQuery,
  ListMessagesQueryVariables,
  MessageUpdateSubscription,
} from './../../types/apolloTypes';

export function messageUpdateUpdateMessages(
  client: ApolloClient<object>,
  messageData: MessageUpdateSubscription,
) {
  const cache = client.cache;

  // Update message list query with new message
  try {
    const variables = {
      conversationId: messageData.messageUpdate.conversationId,
      input: {
        conversationId: messageData.messageUpdate.conversationId,
        page: 1,
        pageSize: 50,
      },
    };

    const listQueryData = cache.readQuery<
      ListMessagesQuery,
      ListMessagesQueryVariables
    >({
      query: ListMessagesDocument,
      variables,
    });

    if (listQueryData.conversation.pausedUntil) return;

    const nextListQueryState = produce(listQueryData, (draftState) => {
      const messageIndex = draftState.messages.items.findIndex(
        (m) => m.id === messageData.messageUpdate.id,
      );
      if (messageIndex > -1) {
        draftState.messages[messageIndex] = messageData.messageUpdate;
      }
    });

    client.writeQuery({
      query: ListMessagesDocument,
      data: nextListQueryState,
      variables,
    });
  } catch (error) {
    if (error?.name !== 'Invariant Violation') {
      throw new Error(error);
    }
  }
}
