import React, { useContext } from 'react';
import {
  Text,
  Platform,
  ScrollView,
  useWindowDimensions,
  StyleSheet,
  View,
} from 'react-native';
import { ThemeContext } from './ThemeProvider';
import screenSizes from '../configs/screenSizes';
import { MenuContext } from './MenuContextProvider';
import PlatformTouchable from './PlatformTouchable';
import BellIcon from './icons/BellIcon';
import { NotificationContext } from './NotificationContext';
import { blue, black } from '../configs/colours';
import { useGetFirmMembership } from '../hooks/useGetFirm';
import useIsNonPro from '../hooks/usIsNonPro';

interface Props {
  notificationsVisibility: boolean;
  toggleNotificationsVisibility: (visibility: boolean) => void;
}

const ContextAside: React.FunctionComponent<Props> = ({
  notificationsVisibility,
  toggleNotificationsVisibility,
}) => {
  const { width = 1000 } = useWindowDimensions();
  const { themeColours } = useContext(ThemeContext);
  const { contextTitle, contextComponent } = useContext(MenuContext);
  const { totalUnread } = useContext(NotificationContext);

  const isNonPro = useIsNonPro();
  const firmMembership = useGetFirmMembership();

  const membershipActive = isNonPro ? true : firmMembership?.isActive;

  return (
    <View
      style={[
        ContextAsideStyle.main,
        width > screenSizes.medium
          ? ContextAsideStyle.desktop
          : ContextAsideStyle.mobile,
      ]}
    >
      <View
        style={[
          ContextAsideStyle.topSection,
          width > screenSizes.medium && ContextAsideStyle.topSectionDesktop,
        ]}
      >
        {width <= screenSizes.medium && (
          <Text style={[ContextAsideStyle.header]}>{contextTitle}</Text>
        )}
        {width > screenSizes.medium && (
          <PlatformTouchable
            style={[
              ContextAsideStyle.notificationButton,
              ContextAsideStyle.notificationButtonDesktop,
            ]}
            onPress={() =>
              membershipActive &&
              toggleNotificationsVisibility(!notificationsVisibility)
            }
          >
            <>
              <BellIcon />
              {totalUnread > 0 && membershipActive && (
                <View
                  style={[
                    ContextAsideStyle.notificationButtonAttention,
                    ContextAsideStyle.notificationButtonAttentionDesktop,
                  ]}
                />
              )}
            </>
          </PlatformTouchable>
        )}
      </View>
      <ScrollView
        style={[
          ContextAsideStyle.inner,
          width > screenSizes.medium && ContextAsideStyle.innerDesktop,
        ]}
      >
        {width > screenSizes.medium && !!contextTitle && (
          <Text
            style={[ContextAsideStyle.header, ContextAsideStyle.headerDesktop]}
          >
            {contextTitle}
          </Text>
        )}
        {contextComponent}
      </ScrollView>
    </View>
  );
};
export default ContextAside;

const ContextAsideStyle = StyleSheet.create({
  header: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 16,
    textAlign: 'center',
    color: '#E5F3F8',
  },
  headerDesktop: {
    // marginVertical: 15,
    borderBottomColor: '#4B4F52',
    paddingVertical: 15,
    fontSize: 12,
    borderBottomWidth: 1,
  },
  inner: {
    flex: 1,
    backgroundColor: '#383D3F',
  },
  innerDesktop: {
    shadowOffset: {
      width: -2,
      height: 3,
    },
    shadowColor: black,
    shadowOpacity: 0.04,
    shadowRadius: 20,
    elevation: 5,
  },
  topSection: {
    backgroundColor: '#2B2F31',
    padding: 10,
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 72,
    borderBottomColor: '#4B4F52',
    borderBottomWidth: 1,
  },
  topSectionDesktop: {
    height: 100,
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    padding: 15,
  },
  main: {
    zIndex: 5,
    flex: 1,
    shadowColor: black,
    shadowOffset: {
      width: -3,
      height: 3,
    },
    shadowOpacity: 0.04,
    shadowRadius: 6,
    elevation: 5,
    backgroundColor: '#2B2F31',
  },
  desktop: {
    width: 215,
    maxWidth: 215,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0,
    shadowRadius: 0,
  },
  mobile: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 300,
    height: '100%',
    transform: [{ translateX: Platform.OS !== 'android' ? 300 : 0 }],
  },
  notificationButton: {
    position: 'relative',
    marginRight: 10,
    marginLeft: -26,
  },
  notificationButtonAttention: {
    position: 'absolute',
    top: -4,
    right: -1,
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: blue,
  },
  notificationButtonAttentionDesktop: {
    top: 6,
    right: 7,
  },
  notificationButtonDesktop: {
    width: 36,
    height: 32,
    borderRadius: 3,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#323738',
    marginLeft: 15,
    marginRight: 0,
  },
  highlightCircle: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: blue,
    position: 'absolute',
    top: -4,
    right: 8,
  },
});
