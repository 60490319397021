import * as React from 'react';
import { Path, Svg, G } from 'react-native-svg';
import { ThemeContext } from '../ThemeProvider';

interface Props {
  fill?: string;
  secondaryFill?: string;
  stroke?: string;
  width?: number;
  height?: number;
}

const DivorceIcon: React.FunctionComponent<Props> = ({
  fill,
  secondaryFill,
  width = 26.44,
  height = 24.59,
}) => {
  const { themeColours } = React.useContext(ThemeContext);

  return (
    <Svg height={height} viewBox="0 0 26.44 24.59" width={width}>
      <Path
        d="M5.65,5.73h0A2.92,2.92,0,0,0,5,0H4.29a2.92,2.92,0,0,0-.68,5.73h0c-2.06,1-4.33,4.76-3.39,6.15.55.81,2.45,1.21,4.4,1.21S8.48,12.68,9,11.88C10,10.49,7.71,6.75,5.65,5.73ZM2.5,2.94A2.13,2.13,0,1,1,4.63,5.06h0A2.13,2.13,0,0,1,2.5,2.94Zm5.86,8.52c-.25.32-1.56.81-3.74.81s-3.49-.5-3.73-.81-.13-1,.49-2.15c.86-1.63,2.38-3.07,3.27-3.07S7,7.68,7.93,9.31c.57,1.14.57,1.89.43,2.15Z"
        fill={fill || themeColours.DivorceIcon.fill}
      />
      <G>
        <Path
          d="M21.84,17.7c-.89,0-2.41,1.43-3.27,3.07-.62,1.18-.63,1.93-.49,2.14s1.55.86,3.73.86,3.49-.5,3.74-.86.13-1-.44-2.14C24.22,19.13,22.73,17.7,21.84,17.7Z"
          fill="none"
        />
        <Path
          d="M22.84,17.19a2.92,2.92,0,0,0-.7-5.73h-.67a2.92,2.92,0,0,0-.68,5.73c-2.05,1.06-4.32,4.8-3.38,6.18.55.82,2.46,1.22,4.4,1.22s3.87-.41,4.41-1.22C27.16,22,24.89,18.21,22.84,17.19Zm-3.15-2.75v0a2.16,2.16,0,1,1,2.18,2.18A2.15,2.15,0,0,1,19.69,14.39Zm5.86,8.47c-.25.36-1.56.86-3.74.86s-3.49-.5-3.73-.86-.13-1,.49-2.14c.86-1.64,2.38-3.07,3.27-3.07s2.38,1.43,3.27,3.07C25.68,21.91,25.69,22.66,25.55,22.91Z"
          fill={secondaryFill || themeColours.DivorceIcon.secondary}
        />
      </G>
      <Path
        d="M17.59,14.73a.41.41,0,1,0-.81,0V17l-5.15-5.14h2.28a.41.41,0,0,0,.41-.41.41.41,0,0,0-.41-.41H10.23v3.68a.41.41,0,0,0,.41.41.41.41,0,0,0,.41-.41h0V12.45l5.14,5.15H13.91a.41.41,0,1,0,0,.81h3.68Z"
        fill={fill || themeColours.DivorceIcon.fill}
      />
    </Svg>
  );
};

export default DivorceIcon;
