import React from 'react';
import { StyleSheet, Text, useWindowDimensions } from 'react-native';
import { white } from '../configs/colours';
import screenSizes from '../configs/screenSizes';
import useIsNonPro from '../hooks/usIsNonPro';
import { SearchMatch } from '../types/Search';
import { useHistory } from '../utils/routing';
import { getIcon, getLink } from './GlobalSearchResult';
import PlatformTouchable from './PlatformTouchable';

interface Props {
  match: SearchMatch;
  onPress: () => void;
}

function getTitle(match: SearchMatch, isNonPro: boolean) {
  if (match.__typename === 'MatchedClientAccount') return match.clientName;
  if (match.__typename === 'MatchedFile') return match.name;
  if (match.__typename === 'MatchedFolder') return match.name;
  if (match.__typename === 'MatchedContact') return match.name;
  if (match.__typename === 'MatchedNote') return match.title;
  if (match.__typename === 'MatchedMessage') {
    return (
      <>
        {`${match.title?.toLowerCase()}: `}
        <Text
          style={[GlobalSearchResultGenericStyle.greyText]}
          numberOfLines={1}
        >
          {`"${match.body?.substr(0, 100)}"`}
        </Text>
      </>
    );
  }
  if (match.__typename === 'MatchedTask') {
    if (isNonPro) {
      return `${match.name} - ${match.path[0]?.name?.toLowerCase()}`;
    }
    return `${match.name} - ${match.path[0]?.name?.toLowerCase()} - ${
      match.clientName
    }`;
  }

  return '';
}

function getType(match: SearchMatch) {
  if (match.__typename === 'MatchedClientAccount') return 'client account';
  if (match.__typename === 'MatchedContact') return 'contact';
  if (match.__typename === 'MatchedFile') return 'file';
  if (match.__typename === 'MatchedFolder') return 'folder';
  if (match.__typename === 'MatchedMessage') return 'message';
  if (match.__typename === 'MatchedTask') return 'task';
  if (match.__typename === 'MatchedNote') return 'note';

  return '';
}

const GlobalSearchResultGeneric: React.FunctionComponent<Props> = ({
  match,
  onPress,
}) => {
  const { width } = useWindowDimensions();
  const history = useHistory();
  const isNonPro = useIsNonPro();

  return (
    <PlatformTouchable
      style={[
        GlobalSearchResultGenericStyle.main,
        width > screenSizes.medium &&
          GlobalSearchResultGenericStyle.mainDesktop,
      ]}
      onPress={() => {
        history.push(getLink(match, isNonPro));
        onPress();
      }}
    >
      <>
        {getIcon(match)}
        <Text style={[GlobalSearchResultGenericStyle.title]} numberOfLines={1}>
          {getTitle(match, isNonPro)}
        </Text>
        <Text style={[GlobalSearchResultGenericStyle.type]}>
          {getType(match)}
        </Text>
      </>
    </PlatformTouchable>
  );
};

export default GlobalSearchResultGeneric;

const GlobalSearchResultGenericStyle = StyleSheet.create({
  main: {
    paddingHorizontal: 20,
    paddingVertical: 10,
    marginTop: -1,
    borderRadius: 4,
    borderColor: '#393E40',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  mainDesktop: {
    borderRadius: 4,
    borderLeftWidth: 1,
    borderRightWidth: 1,
  },
  picture: {
    width: 20,
    height: 20,
    borderRadius: 3,
  },
  title: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 13,
    color: white,
    flex: 1,
    marginLeft: 12,
  },
  type: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 13,
    color: '#AAA9A9',
    marginLeft: 10,
  },
  greyText: {
    color: '#AAA9A9',
  },
});
