import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { Rect, Path, Svg, G } from 'react-native-svg';
import { blue } from '../../configs/colours';

interface Props {
  fill?: string;
  secondaryFill?: string;
  height?: number;
  width?: number;
  style?: StyleProp<ViewStyle>;
}

const FolderIcon: React.FunctionComponent<Props> = ({
  fill = blue,
  secondaryFill = '#2c3f45',
  width = 20,
  height = 16,
  style,
}) => {
  return (
    <Svg height={height} viewBox="0 0 20 16" width={width} style={style}>
      <Path
        d="M18,15.25H2A1.25,1.25,0,0,1,.75,14V.89A.15.15,0,0,1,.91.75H14.37l1.45,1.54.22.24h2a1.25,1.25,0,0,1,1.25,1.25V14A1.25,1.25,0,0,1,18,15.25Z"
        transform="translate(0 0)"
        fill={secondaryFill}
        stroke={fill}
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default FolderIcon;
