import { StyleSheet } from 'react-native';

export const QuestionPartStyles = StyleSheet.create({
  splitColWrap: {
    marginHorizontal: -5,
    flexDirection: 'row',
  },
  splitCol: {
    width: '50%',
    paddingHorizontal: 5,
  },
  preview: {
    flex: 1,
    justifyContent: 'center',
    paddingTop: 15,
  },

  label: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 14,
    color: '#AAA9A9',
    marginBottom: 5,
    paddingLeft: 10,
  },
  labelDesktop: {
    fontSize: 12,
  },
  instruction: {
    fontFamily: 'Quicksand-Regular',
    fontSize: 12,
    color: '#AAA9A9',
    marginBottom: 5,
  },
  errorText: {
    marginBottom: 10,
    marginTop: -10,
    fontFamily: 'Quicksand-Medium',
    fontSize: 12,
    color: '#fcc12d',
  },
  content: {
    flex: 1,
  },
  contentWrap: {
    flexDirection: 'row',
  },
  wrappedLabel: {
    marginBottom: 0,
  },
  labelWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
});
