import * as React from 'react';
import {
  GestureResponderEvent,
  StyleProp,
  Text,
  TextStyle,
} from 'react-native';
import { useHistory } from '../utils/routing';

export interface LinkProps {
  to: string;
  style?: StyleProp<TextStyle>;
  onLinkPress?: (e: GestureResponderEvent) => void;
  text: string;
}

const Link: React.FunctionComponent<LinkProps> = ({
  to = '/',
  style = {
    fontSize: 16,
  },
  text,
  onLinkPress,
}) => {
  const history = useHistory();
  const onPress = (e: GestureResponderEvent) => {
    history.push(to);
    if (onLinkPress) {
      onLinkPress(e);
    }
    // @ts-ignore
    e.nativeEvent.preventDefault();
  };
  return (
    // @ts-ignore
    <Text style={style} accessibilityRole="link" href={to} onPress={onPress}>
      {text}
    </Text>
  );
};

export default Link;
