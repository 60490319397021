import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { white } from '../../configs/colours';

interface Props {
  fill?: string;
  width?: number;
  height?: number;
}

const ChevronLeftLightIcon: React.FunctionComponent<Props> = ({
  fill = '#e5f3f8',
  width = 8.68,
  height = 15.2,
}) => {
  return (
    <Svg height={height} viewBox="0 0 8.68 15.2" width={width}>
      <Path
        d="M7.93,14.84.75,8,7.93,1.06"
        transform="translate(0 -0.31)"
        fill="none"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </Svg>
  );
};
export default ChevronLeftLightIcon;
