import * as React from 'react';
import { Svg, Path } from 'react-native-svg';

interface Props {
  fill?: string;
  width?: number;
  height?: number;
}

const EditIcon: React.FunctionComponent<Props> = ({
  fill = '#C9C9C9',
  width = 24,
  height = 24,
}) => {
  return (
    <Svg height={height} viewBox="0 0 24 24" width={width}>
      <Path
        d="M21,12v9a2,2,0,0,1-2,2H3a2,2,0,0,1-2-2V5A2,2,0,0,1,3,3h9"
        fill="none"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <Path
        d="M6,18l4.7-.94a1,1,0,0,0,.51-.28L23,5a4.55,4.55,0,0,0-4-4L7.21,12.78a1,1,0,0,0-.27.52Z"
        fill="none"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </Svg>
  );
};
export default EditIcon;
