import { DataProxy } from 'apollo-cache';
import produce from 'immer';
import {
  GetTaskDocument,
  GetTaskQuery,
  GetTaskQueryVariables,
  RemoveTaskSectionMutation,
  RemoveTaskSectionMutationVariables,
} from './../../types/apolloTypes';

// Update Cache Function to update after pause mutation
export default function removeTaskSectionUpdate(
  cache: DataProxy,
  data?: RemoveTaskSectionMutation,
  variables?: RemoveTaskSectionMutationVariables,
) {
  if (!variables.input.taskId) return;

  // List task query variables
  const taskId = variables.input.taskId;

  // Try and update task query
  try {
    const getTaskQueryData = cache.readQuery<
      GetTaskQuery,
      GetTaskQueryVariables
    >({
      query: GetTaskDocument,
      variables: { taskId },
    });

    const nextTaskState = produce(getTaskQueryData, (draftState) => {
      if (draftState && draftState.task && data && data.removeTaskSection) {
        draftState.task = {
          ...draftState.task,
          sections: data.removeTaskSection.sections,
        };
      }
    });

    cache.writeQuery({
      query: GetTaskDocument,
      data: nextTaskState,
      variables: { taskId },
    });
  } catch (error) {
    if (error?.name !== 'Invariant Violation') {
      throw new Error(error);
    }
  }
}
