import { useState, useRef, ReactNode } from 'react';
import {
  NativeSyntheticEvent,
  Platform,
  TextInputChangeEventData,
  TextInput,
} from 'react-native';

export default function useUpdateInputHeight(settings?: {
  minHeight?: number;
}) {
  const defaults = { minHeight: 32, ...settings };
  const [inputHeight, setInputHeight] = useState<number>(defaults.minHeight);

  function updateInputHeight<T>(target: T) {
    // @ts-ignore
    target.style.height = null;
    // @ts-ignore
    if (target.scrollHeight !== inputHeight) {
      // @ts-ignore
      setInputHeight(target.scrollHeight);
    }
    // @ts-ignore
    target.style.height = `${target.scrollHeight}px`;
  }

  // On conversation input change
  function onInputChange(
    e: NativeSyntheticEvent<TextInputChangeEventData>,
  ): void {
    // Hack to get auto height textarea on web
    if (Platform.OS === 'web') {
      updateInputHeight<ReactNode>(e.target);
    }
  }

  const textInput = useRef<TextInput | null>(null);

  return {
    onInputChange,
    updateInputHeight,
    inputHeight,
    textInput,
  };
}
