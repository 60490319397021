import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { Path, Rect, Svg } from 'react-native-svg';
import { blue, white } from '../../configs/colours';

interface Props {
  fill?: string;
  secondaryFill?: string;
  height?: number;
  width?: number;
  style?: StyleProp<ViewStyle>;
}

const VideoFileIcon: React.FunctionComponent<Props> = ({
  fill = blue,
  secondaryFill = white,
  width = 16.13,
  height = 16.55,
  style,
}) => {
  return (
    <Svg height={height} viewBox="0 0 16.13 16.55" width={width} style={style}>
      <Rect width="16.13" height="16.55" rx="2" fill="none" />
      <Rect
        x="0.5"
        y="0.5"
        width="15.13"
        height="15.55"
        rx="1.5"
        fill="none"
        stroke={secondaryFill}
      />
      <Path
        d="M11.33,7.8a.5.5,0,0,1,.16.69.61.61,0,0,1-.16.16L6.1,11.87a.51.51,0,0,1-.76-.43V5a.51.51,0,0,1,.5-.5.47.47,0,0,1,.26.08Z"
        fill={fill}
      />
    </Svg>
  );
};

export default VideoFileIcon;
