import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date/time in ISO 8601 format */
  DateTime: string;
  /** A standard email address */
  EmailAddress: any;
  /** A monetary value in a named currency */
  Money: any;
  /**
   * The `Upload` special type represents a file to be uploaded in the same HTTP request as specified by
   *  [graphql-multipart-request-spec](https://github.com/jaydenseric/graphql-multipart-request-spec).
   */
  Upload: any;
};

export type AcceptAnswerInput = {
  /** The unique ID of the answer to be accepted */
  answerId: Scalars['ID'];
};

export type AddContactInput = {
  /** Which kind of contact this is */
  type: ContactType;
  /** The contact's last name */
  firstName: Scalars['String'];
  /** The contact's last name */
  lastName: Scalars['String'];
  /** The contact's email address. Their verification code will be sent here. */
  emailAddress: Scalars['EmailAddress'];
};

export type AddFirmMemberInput = {
  /** Which type of professional is being added to the firm */
  professionalType: FirmProfessionalType;
  /** The SRA number of the professional (if applicable) */
  sraNumber?: Maybe<Scalars['String']>;
  /** The paralegal number of the professional (if applicable) */
  paralegalNumber?: Maybe<Scalars['String']>;
  /** The contact email address of the professional */
  emailAddress: Scalars['EmailAddress'];
  /** The professional's preferred first name */
  preferredName?: Maybe<Scalars['String']>;
  /** The professional's first name */
  firstName: Scalars['String'];
  /** The professional's last name */
  lastName: Scalars['String'];
  /** The unique ID of the firm to which the professional will be added as a member */
  firmId: Scalars['ID'];
  /** An optional employee reference number */
  employeeNumber?: Maybe<Scalars['String']>;
  /** The firm member's formal job title */
  jobTitle?: Maybe<Scalars['String']>;
  /** The name or location of the office in which the firm member works */
  office?: Maybe<Scalars['String']>;
  /** The role the professional should take in the firm */
  role?: Maybe<FirmRole>;
};

export type AddFolderInput = {
  /** The unique ID of the folder into which the folder will be added. */
  parentId: Scalars['ID'];
  /** A short name for the folder (max 250 characters) */
  name: Scalars['String'];
};

export type AddKeyDateInput = {
  /** The unique ID of the matter to which this key date will be added */
  matterId: Scalars['ID'];
  /** A short name for the key date (max 250 characters) */
  name: Scalars['String'];
  /** A description for the key date (optional, max 1500 characters) */
  description?: Maybe<Scalars['String']>;
  /** The actual date/time itself */
  date: Scalars['DateTime'];
  /** Whether or not the time portion of the date/time should be removed */
  isAllDay: Scalars['Boolean'];
};

export type AddQuestionPartInput = {
  /** The unique ID of the question to which the part will be added */
  questionId: Scalars['ID'];
  /** A specification for the new part to add to the question */
  part: DefineQuestionPartInput;
};

export type AddSupportingFileInput = {
  /** The unique ID of the matter to which the file will be added */
  matterId: Scalars['ID'];
  /** The description of the file to add */
  file: FileDescriptorInput;
};

/** A standardised street address */
export type Address = {
  __typename?: 'Address';
  /** The name of the addressee */
  name?: Maybe<Scalars['String']>;
  /** The first line of the address */
  line1: Scalars['String'];
  /** The second line of the address */
  line2?: Maybe<Scalars['String']>;
  /** The third line of the address */
  line3?: Maybe<Scalars['String']>;
  /** The town or city */
  city: Scalars['String'];
  /** The region or county */
  region?: Maybe<Scalars['String']>;
  /** The post code */
  postCode: Scalars['String'];
  /** The name of the country */
  country?: Maybe<Scalars['String']>;
};

/** An answer to a part of a question that requires an address in response */
export type AddressAnswer = Answer & {
  __typename?: 'AddressAnswer';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The part of a question for which this is an answer */
  questionPart: QuestionPart;
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  proposedOn: Scalars['DateTime'];
  /** Whether or not this answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The revision history of this answer */
  history: Array<AnswerRevision>;
  /** The current status of this answer */
  status: AnswerStatus;
  /** Whether or not this answer has been accepted by a professional */
  isAcceptedByProfessional: Scalars['Boolean'];
  /** Whether or not this answer has been accepted by a client */
  isAcceptedByClient: Scalars['Boolean'];
  /** Whether or not this question part has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
  /** The address given as an answer */
  currentValue?: Maybe<Address>;
};

/** A draft answer to a part of a question that requires an address in response */
export type AddressAnswerDraft = AnswerDraft & {
  __typename?: 'AddressAnswerDraft';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The part of a question for which this is an answer */
  questionPart: QuestionPart;
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  startedOn: Scalars['DateTime'];
  /** Whether or not this draft of the answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The address given as an answer */
  currentValue?: Maybe<Address>;
};

/** A revision to an answer to a part of a question that requires an address in response */
export type AddressAnswerRevision = AnswerRevision & {
  __typename?: 'AddressAnswerRevision';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  proposedOn: Scalars['DateTime'];
  /** Whether or not this revision of the answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The address given as an answer */
  value?: Maybe<Address>;
};

/** An attribute of a list part that uses an address value */
export type AddressAttribute = ListAttribute & {
  __typename?: 'AddressAttribute';
  /** The unique ID of this attribute */
  id: Scalars['String'];
  /** Which type of attribute this is */
  type: QuestionPartType;
  /** The label to be shown for this attribute */
  label: Scalars['String'];
  /** Additional instructions and information to support the user */
  instructions?: Maybe<Scalars['String']>;
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** The ordering index for use when showing this attribute alongside other attributes of the same part */
  ordering: Scalars['Int'];
  /** Whether or not to use the name part of the address */
  useName: Scalars['Boolean'];
  /** The configuration for this part */
  configuration: AddressPartConfiguration;
};

export enum AddressDocumentType {
  ElectoralRegister = 'ELECTORAL_REGISTER',
  UtilityBill = 'UTILITY_BILL',
  CouncilTax = 'COUNCIL_TAX',
  DriversLicence = 'DRIVERS_LICENCE',
  BankStatement = 'BANK_STATEMENT',
  MortgageStatement = 'MORTGAGE_STATEMENT',
  SolicitorsLetter = 'SOLICITORS_LETTER',
  LandRegistry = 'LAND_REGISTRY',
  CouncilRentBook = 'COUNCIL_RENT_BOOK',
  BenefitsBook = 'BENEFITS_BOOK',
  HmrcSelfAssessment = 'HMRC_SELF_ASSESSMENT',
  HouseInsurance = 'HOUSE_INSURANCE',
  CarInsurance = 'CAR_INSURANCE'
}

export type AddressInput = {
  /** The name of the addressee */
  name?: Maybe<Scalars['String']>;
  /** The first line of the address */
  line1: Scalars['String'];
  /** The second line of the address */
  line2?: Maybe<Scalars['String']>;
  /** The third line of the address */
  line3?: Maybe<Scalars['String']>;
  /** The town or city */
  city: Scalars['String'];
  /** The region or county */
  region?: Maybe<Scalars['String']>;
  /** The post code */
  postCode: Scalars['String'];
  /** The name of the country */
  country?: Maybe<Scalars['String']>;
};

/** A list property that makes use of an address */
export type AddressListItemProperty = ListItemProperty & {
  __typename?: 'AddressListItemProperty';
  /** The unique ID of the attribute for which this property exists */
  attributeId: Scalars['ID'];
  /** The address given */
  value?: Maybe<Address>;
};

/** A part of a question that requires an address in response */
export type AddressPart = QuestionPart & {
  __typename?: 'AddressPart';
  /** The unique ID of this question part */
  id: Scalars['String'];
  /** Which type of part this is */
  type: QuestionPartType;
  /** The label to be shown for this part */
  label: Scalars['String'];
  /** Additional instructions and information to support the user */
  instructions?: Maybe<Scalars['String']>;
  /** Official advice for completing this question part */
  officialAdvice?: Maybe<Scalars['String']>;
  /** Additional advice for completing this question part */
  additionalAdvice?: Maybe<Scalars['String']>;
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** Whether or not this question part has been completed */
  isComplete: Scalars['Boolean'];
  /** The ordering index for use when showing this part alongside other parts of the same question */
  ordering: Scalars['Int'];
  /** The current draft answer for this question part */
  answerDraft?: Maybe<AnswerDraft>;
  /** The answer given to this question part */
  answer?: Maybe<Answer>;
  /** Whether or not this question part has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
  /** Whether or not to use the name part of the address */
  useName: Scalars['Boolean'];
  /** The configuration for this part */
  configuration: AddressPartConfiguration;
};

/** The configuration for a part of a question that requires an address in response */
export type AddressPartConfiguration = {
  __typename?: 'AddressPartConfiguration';
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** Whether or not to use the name part of the address */
  useName: Scalars['Boolean'];
};

/** Proof of address held by a client */
export type AddressProof = {
  __typename?: 'AddressProof';
  /** An internal ID for this form of proof */
  id: Scalars['ID'];
  /** The official reference number of ID for this form of proof */
  type: AddressDocumentType;
  /** The official reference number of ID for this form of proof */
  reference: Scalars['String'];
  /** The ID of the image that was used */
  assetFileId: Scalars['ID'];
  /** The version of the image/document that was used */
  assetFileVersion: Scalars['Int'];
  /** The MIME type of the image/document that was used */
  assetMime: Scalars['String'];
  /** The size, in bytes, of the image/document that was used */
  assetSize: Scalars['Int'];
  /** The height, in pixels, of the image/document that was used */
  assetHeight: Scalars['Int'];
  /** The width, in pixels, of the image/document that was used */
  assetWidth: Scalars['Int'];
  /** A URL to the provided image/document of the physical identity document */
  assetUrl: Scalars['String'];
  /** The country in which the document was issued (if applicable) */
  country?: Maybe<Scalars['String']>;
  /** The date when the document expires (if applicable) */
  expiresAt?: Maybe<Scalars['DateTime']>;
};

export type AddressProofInput = {
  /** The official reference number of ID for this form of proof */
  type: AddressDocumentType;
  /** The official reference number of ID for this form of proof */
  reference: Scalars['String'];
  /** The ID of the file to import from the file manager */
  fileId: Scalars['ID'];
  /** The version of the file to import from the file manager (optional) */
  fileVersion?: Maybe<Scalars['Int']>;
  /** The country in which the document was issued (if applicable) */
  country?: Maybe<Scalars['String']>;
  /** The date when the document expires (if applicable) */
  expiresAt?: Maybe<Scalars['DateTime']>;
};

/** An answer to a part of a question */
export type Answer = {
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The part of a question for which this is an answer */
  questionPart: QuestionPart;
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  proposedOn: Scalars['DateTime'];
  /** Whether or not this answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The revision history of this answer */
  history: Array<AnswerRevision>;
  /** The current status of this answer */
  status: AnswerStatus;
  /** Whether or not this answer has been accepted by a professional */
  isAcceptedByProfessional: Scalars['Boolean'];
  /** Whether or not this answer has been accepted by a client */
  isAcceptedByClient: Scalars['Boolean'];
  /** Whether or not this question part has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
};

/** An attachment that accompanies an answer */
export type AnswerAttachment = {
  /** The unique ID of this attachment */
  id: Scalars['ID'];
  /** The unique ID of the file revision that was originally attached */
  fileRevisionId: Scalars['ID'];
  /** The original name of the asset used to revise the file */
  originalName: Scalars['String'];
  /** A simple file type, for example: document */
  type: Scalars['String'];
  /** The file's full MIME type (e.g. image/jpeg) */
  mime: Scalars['String'];
  /** The size of the file asset in bytes */
  size: Scalars['Int'];
};

/** A draft answer for a part of a question */
export type AnswerDraft = {
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The part of a question for which this is an answer */
  questionPart: QuestionPart;
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  startedOn: Scalars['DateTime'];
  /** Whether or not this draft of the answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
};

export type AnswerQuestionPartInput = {
  /** The unique ID of the question */
  questionId: Scalars['ID'];
  /** The unique ID of the question part */
  questionPartId: Scalars['ID'];
  /** Set as true if the question part is not applicable, in which case any answer value given will be ignored (defaults to false) */
  notApplicable?: Maybe<Scalars['Boolean']>;
  /** Address fields for an address answer */
  address?: Maybe<AddressInput>;
  /** A simple yes/no answer */
  boolean?: Maybe<Scalars['Boolean']>;
  /** The choice(s) made for a choice answer */
  choice?: Maybe<Array<Scalars['String']>>;
  /** The date/time selected for a date answer */
  date?: Maybe<Scalars['DateTime']>;
  /** An email address for an email answer */
  email?: Maybe<Scalars['EmailAddress']>;
  /** Descriptions of the files to be used as a file answer */
  file?: Maybe<Array<FileDescriptorInput>>;
  /** A list of items to put in a list answer */
  list?: Maybe<Array<ListItemInput>>;
  /** An amount of money for a money answer */
  money?: Maybe<Scalars['Money']>;
  /** A whole number for a number answer */
  number?: Maybe<Scalars['Float']>;
  /** A percentage for a percent answer */
  percent?: Maybe<Scalars['Float']>;
  /** A phone number for a phone answer */
  phone?: Maybe<Scalars['String']>;
  /** A short string for a string answer */
  string?: Maybe<Scalars['String']>;
  /** A long string for a text answer */
  text?: Maybe<Scalars['String']>;
};

/** A revision to an answer to a part of a question */
export type AnswerRevision = {
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  proposedOn: Scalars['DateTime'];
  /** Whether or not this revision of the answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
};

export enum AnswerStatus {
  Pending = 'PENDING',
  Agreed = 'AGREED',
  Locked = 'LOCKED'
}

export type ApproveClientAccountInput = {
  /** The unique ID of the client account */
  clientAccountId: Scalars['ID'];
  /** A custom reference number or code for this account */
  referenceNumber: Scalars['String'];
};

/** An archive attached to an answer */
export type ArchiveAnswerAttachment = AnswerAttachment & {
  __typename?: 'ArchiveAnswerAttachment';
  /** The unique ID of this attachment */
  id: Scalars['ID'];
  /** The unique ID of the file revision that was originally attached */
  fileRevisionId: Scalars['ID'];
  /** The original name of the asset used to revise the file */
  originalName: Scalars['String'];
  /** A simple file type, for example: document */
  type: Scalars['String'];
  /** The file's full MIME type (e.g. image/jpeg) */
  mime: Scalars['String'];
  /** The size of the file asset in bytes */
  size: Scalars['Int'];
};

/** A single uploaded archive */
export type ArchiveFile = File & {
  __typename?: 'ArchiveFile';
  /** The unique ID for the file */
  id: Scalars['ID'];
  /** The folder's owner */
  owner?: Maybe<FileOwner>;
  /** The folder in which the file is placed */
  folder: Folder;
  /** The name given to the file */
  name: Scalars['String'];
  /** What type of file this is (e.g. Document, Image, etc.) */
  type: Scalars['String'];
  /** The file's full MIME type (e.g. image/jpeg) */
  mime: Scalars['String'];
  /** The size of the raw file, in bytes */
  size: Scalars['Int'];
  /** The files current version number */
  version: Scalars['Int'];
  /** The ID of the revision that represents the current version of this file */
  currentRevisionId: Scalars['ID'];
  /** The date and time when this file was last revised */
  lastRevisedOn: Scalars['DateTime'];
  /** The history of revisions made to this file */
  revisions: Array<FileRevision>;
  /** The topics associated with this file by its owner */
  topics: Array<Topic>;
};

/** A version of an archive */
export type ArchiveFileRevision = FileRevision & {
  __typename?: 'ArchiveFileRevision';
  /** The unique ID of this file */
  id: Scalars['ID'];
  /** The revision number of this file */
  version: Scalars['Int'];
  /** The original name of the asset used to revise the file */
  originalName: Scalars['String'];
  /** A simple file type, for example: document */
  type: Scalars['String'];
  /** The file's full MIME type (e.g. image/jpeg) */
  mime: Scalars['String'];
  /** The size of the file asset in bytes */
  size: Scalars['Int'];
  /** The date and time when this file was revised */
  revisedOn: Scalars['DateTime'];
};

/** Metadata about an uploaded asset */
export type AssetMeta = {
  __typename?: 'AssetMeta';
  /** The original name of the asset */
  originalName: Scalars['String'];
  /** A simple file type, for example: document */
  type: Scalars['String'];
  /** The file's full MIME type (e.g. image/jpeg) */
  mime: Scalars['String'];
  /** The size of the file asset in bytes */
  size: Scalars['Int'];
};

export type AssignFirmMemberToClientInput = {
  /** The unique ID of the firm member */
  firmMemberId: Scalars['ID'];
  /** The unique ID of the client account */
  clientAccountId: Scalars['ID'];
};

export type AssociateFileWithTopicInput = {
  fileId: Scalars['ID'];
  topicId: Scalars['ID'];
};

export type AssociateMessageWithTopicInput = {
  messageId: Scalars['ID'];
  topicId: Scalars['ID'];
};

export type AssociateNoteInput = {
  /** The unique ID of the note to be associated */
  noteId: Scalars['ID'];
  /** The unique ID of the client account with which to associate the note */
  clientAccountId?: Maybe<Scalars['ID']>;
  /** The unique ID of the matter with which to associate the note */
  matterId?: Maybe<Scalars['ID']>;
};

/** An archive attached to a message */
export type AttachedArchive = Attachment & {
  __typename?: 'AttachedArchive';
  /** The unique ID of this attachment */
  id: Scalars['ID'];
  /** The original name of the file */
  name: Scalars['String'];
  /** A simple file type, for example: document */
  type: Scalars['String'];
  /** The file's full MIME type (e.g. image/jpeg) */
  mime: Scalars['String'];
  /** The size of the file asset in bytes */
  size: Scalars['Int'];
};

/** A piece of audio attached to a message */
export type AttachedAudio = Attachment & {
  __typename?: 'AttachedAudio';
  /** The unique ID of this attachment */
  id: Scalars['ID'];
  /** The original name of the file */
  name: Scalars['String'];
  /** A simple file type, for example: document */
  type: Scalars['String'];
  /** The file's full MIME type (e.g. image/jpeg) */
  mime: Scalars['String'];
  /** The size of the file asset in bytes */
  size: Scalars['Int'];
  /** The duration of the audio in seconds */
  duration?: Maybe<Scalars['Int']>;
};

/** A document attached to a message */
export type AttachedDocument = Attachment & {
  __typename?: 'AttachedDocument';
  /** The unique ID of this attachment */
  id: Scalars['ID'];
  /** The original name of the file */
  name: Scalars['String'];
  /** A simple file type, for example: document */
  type: Scalars['String'];
  /** The file's full MIME type (e.g. image/jpeg) */
  mime: Scalars['String'];
  /** The size of the file asset in bytes */
  size: Scalars['Int'];
};

/** A image attached to a message */
export type AttachedImage = Attachment & {
  __typename?: 'AttachedImage';
  /** The unique ID of this attachment */
  id: Scalars['ID'];
  /** The original name of the file */
  name: Scalars['String'];
  /** A simple file type, for example: document */
  type: Scalars['String'];
  /** The file's full MIME type (e.g. image/jpeg) */
  mime: Scalars['String'];
  /** The size of the file asset in bytes */
  size: Scalars['Int'];
  /** The height of the image in pixels */
  height?: Maybe<Scalars['Int']>;
  /** The width of the image in pixels */
  width?: Maybe<Scalars['Int']>;
};

/** A piece of video attached to a message */
export type AttachedVideo = Attachment & {
  __typename?: 'AttachedVideo';
  /** The unique ID of this attachment */
  id: Scalars['ID'];
  /** The original name of the file */
  name: Scalars['String'];
  /** A simple file type, for example: document */
  type: Scalars['String'];
  /** The file's full MIME type (e.g. image/jpeg) */
  mime: Scalars['String'];
  /** The size of the file asset in bytes */
  size: Scalars['Int'];
  /** The duration of the video in seconds */
  duration?: Maybe<Scalars['Int']>;
  /** The height of the video in pixels */
  height?: Maybe<Scalars['Int']>;
  /** The width of the video in pixels */
  width?: Maybe<Scalars['Int']>;
};

/** An attachment that accompanies a message */
export type Attachment = {
  /** The unique ID of this attachment */
  id: Scalars['ID'];
  /** The original name of the file */
  name: Scalars['String'];
  /** A simple file type, for example: document */
  type: Scalars['String'];
  /** The file's full MIME type (e.g. image/jpeg) */
  mime: Scalars['String'];
  /** The size of the file asset in bytes */
  size: Scalars['Int'];
};

/** A piece of audio attached to an answer */
export type AudioAnswerAttachment = AnswerAttachment & {
  __typename?: 'AudioAnswerAttachment';
  /** The unique ID of this attachment */
  id: Scalars['ID'];
  /** The unique ID of the file revision that was originally attached */
  fileRevisionId: Scalars['ID'];
  /** The original name of the asset used to revise the file */
  originalName: Scalars['String'];
  /** A simple file type, for example: document */
  type: Scalars['String'];
  /** The file's full MIME type (e.g. image/jpeg) */
  mime: Scalars['String'];
  /** The size of the file asset in bytes */
  size: Scalars['Int'];
};

/** A single uploaded piece of audio */
export type AudioFile = File & {
  __typename?: 'AudioFile';
  /** The unique ID for the file */
  id: Scalars['ID'];
  /** The folder's owner */
  owner?: Maybe<FileOwner>;
  /** The folder in which the file is placed */
  folder: Folder;
  /** The name given to the file */
  name: Scalars['String'];
  /** What type of file this is (e.g. Document, Image, etc.) */
  type: Scalars['String'];
  /** The file's full MIME type (e.g. image/jpeg) */
  mime: Scalars['String'];
  /** The size of the raw file, in bytes */
  size: Scalars['Int'];
  /** The files current version number */
  version: Scalars['Int'];
  /** The ID of the revision that represents the current version of this file */
  currentRevisionId: Scalars['ID'];
  /** The date and time when this file was last revised */
  lastRevisedOn: Scalars['DateTime'];
  /** The history of revisions made to this file */
  revisions: Array<FileRevision>;
  /** The topics associated with this file by its owner */
  topics: Array<Topic>;
  /** The duration of the audio in seconds */
  duration?: Maybe<Scalars['Int']>;
};

/** A version of a piece of audio */
export type AudioFileRevision = FileRevision & {
  __typename?: 'AudioFileRevision';
  /** The unique ID of this file */
  id: Scalars['ID'];
  /** The revision number of this file */
  version: Scalars['Int'];
  /** The original name of the asset used to revise the file */
  originalName: Scalars['String'];
  /** A simple file type, for example: document */
  type: Scalars['String'];
  /** The file's full MIME type (e.g. image/jpeg) */
  mime: Scalars['String'];
  /** The size of the file asset in bytes */
  size: Scalars['Int'];
  /** The date and time when this file was revised */
  revisedOn: Scalars['DateTime'];
  /** The duration of the audio in seconds */
  duration?: Maybe<Scalars['Int']>;
};

/** The outcome of an authentication attempt */
export type AuthenticationPayload = {
  /** Whether or not the authentication was successful */
  successful: Scalars['Boolean'];
  /** A message describing the authentication outcome */
  message: Scalars['String'];
};

/** Bank account details for a direct debit */
export type BankDetails = {
  __typename?: 'BankDetails';
  /** The name of the bank */
  name?: Maybe<Scalars['String']>;
  /** The address of the branch associated with the account */
  branchAddress?: Maybe<Address>;
  /** The bank account number */
  accountNumber?: Maybe<Scalars['String']>;
  /** The bank account sort code */
  sortCode?: Maybe<Scalars['String']>;
};

/** An answer to a part of a question that requires a boolean response */
export type BooleanAnswer = Answer & {
  __typename?: 'BooleanAnswer';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The part of a question for which this is an answer */
  questionPart: QuestionPart;
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  proposedOn: Scalars['DateTime'];
  /** Whether or not this answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The revision history of this answer */
  history: Array<AnswerRevision>;
  /** The current status of this answer */
  status: AnswerStatus;
  /** Whether or not this answer has been accepted by a professional */
  isAcceptedByProfessional: Scalars['Boolean'];
  /** Whether or not this answer has been accepted by a client */
  isAcceptedByClient: Scalars['Boolean'];
  /** Whether or not this question part has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
  /** The value given as an answer */
  currentValue: Scalars['Boolean'];
};

/** A draft answer to a part of a question that requires a boolean response */
export type BooleanAnswerDraft = AnswerDraft & {
  __typename?: 'BooleanAnswerDraft';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The part of a question for which this is an answer */
  questionPart: QuestionPart;
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  startedOn: Scalars['DateTime'];
  /** Whether or not this draft of the answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The value given as an answer */
  currentValue?: Maybe<Scalars['Boolean']>;
};

/** A revision to an answer to a part of a question that requires a boolean response */
export type BooleanAnswerRevision = AnswerRevision & {
  __typename?: 'BooleanAnswerRevision';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  proposedOn: Scalars['DateTime'];
  /** Whether or not this revision of the answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The value given as an answer */
  value: Scalars['Boolean'];
};

/** A part of a question that requires a boolean response */
export type BooleanPart = QuestionPart & {
  __typename?: 'BooleanPart';
  /** The unique ID of this question part */
  id: Scalars['String'];
  /** Which type of part this is */
  type: QuestionPartType;
  /** The label to be shown for this part */
  label: Scalars['String'];
  /** Additional instructions and information to support the user */
  instructions?: Maybe<Scalars['String']>;
  /** Official advice for completing this question part */
  officialAdvice?: Maybe<Scalars['String']>;
  /** Additional advice for completing this question part */
  additionalAdvice?: Maybe<Scalars['String']>;
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** Whether or not this question part has been completed */
  isComplete: Scalars['Boolean'];
  /** The ordering index for use when showing this part alongside other parts of the same question */
  ordering: Scalars['Int'];
  /** The current draft answer for this question part */
  answerDraft?: Maybe<AnswerDraft>;
  /** The answer given to this question part */
  answer?: Maybe<Answer>;
  /** Whether or not this question part has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
  /** The label for a positive response */
  positiveLabel: Scalars['String'];
  /** The label for a negative response */
  negativeLabel: Scalars['String'];
  /** The configuration for this part */
  configuration: BooleanPartConfiguration;
};

/** The configuration for a part of a question that requires a boolean response */
export type BooleanPartConfiguration = {
  __typename?: 'BooleanPartConfiguration';
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** The label for a positive response */
  positiveLabel: Scalars['String'];
  /** The label for a negative response */
  negativeLabel: Scalars['String'];
};

export type Breadcrumb = {
  __typename?: 'Breadcrumb';
  to: Scalars['String'];
  name: Scalars['String'];
  image?: Maybe<Scalars['String']>;
};

export type BreadcrumbInput = {
  to: Scalars['String'];
  name: Scalars['String'];
  image?: Maybe<Scalars['String']>;
};

export type ChangeAnswerLockInput = {
  /** The unique ID of the answer to be locked or unlocked */
  answerId: Scalars['ID'];
  /** Whether or not to lock the answer */
  lock: Scalars['Boolean'];
};

export type ChangeClientAccountActivityInput = {
  /** The unique ID of the client account */
  clientAccountId: Scalars['ID'];
  /** Whether or not this account should be active */
  isActive: Scalars['Boolean'];
};

export type ChangeFirmMemberStatusInput = {
  /** The unique ID of the firm member affected */
  firmMemberId: Scalars['ID'];
  /** Whether or not the firm member is active */
  isActive: Scalars['Boolean'];
};

export type ChangeMatterLeadInput = {
  /** The unique ID of the matter */
  matterId: Scalars['ID'];
  /** The unique ID of the new lead professional */
  leadProfessionalId: Scalars['ID'];
};

export type ChangeTaskAdviceInput = {
  /** The unique ID of the task to be renamed */
  taskId: Scalars['ID'];
  /** Official advice for the task (max 5000 characters) */
  officialAdvice?: Maybe<Scalars['String']>;
  /** Additional advice for the task (max 5000 characters) */
  additionalAdvice?: Maybe<Scalars['String']>;
};

export type ChangeTaskMatterTypesInput = {
  /** The unique ID of the task */
  taskId: Scalars['ID'];
  /** The matter types for which this task is used. Leave empty if this is a general task. */
  matterTypes?: Maybe<Array<MatterType>>;
};

export type ChangeTaskPublishStatusInput = {
  /** The unique ID of the task */
  taskId: Scalars['ID'];
  /** Whether or not the task is published */
  isPublished: Scalars['Boolean'];
};

export type ChangeTaskSectionAdviceInput = {
  /** The unique ID of the task to be renamed */
  taskId: Scalars['ID'];
  /** The unique ID of the section */
  sectionId: Scalars['ID'];
  /** Official advice for the task section (max 5000 characters) */
  officialAdvice?: Maybe<Scalars['String']>;
  /** Additional advice for the task section (max 5000 characters) */
  additionalAdvice?: Maybe<Scalars['String']>;
};

export type ChangeUserEmailInput = {
  /** The email address which will be used to identify and contact the user */
  email: Scalars['String'];
};

export type ChangeUserPasswordInput = {
  /** The unique ID of the user being updated */
  id: Scalars['ID'];
  /** The unique ID of the client being used by the user */
  clientId: Scalars['ID'];
  /** The identifier for the resource for which access is required */
  audience: Scalars['String'];
  /** The user's old password */
  oldPassword: Scalars['String'];
  /** The user's new password */
  newPassword: Scalars['String'];
};

export type ChangeUserPhoneInput = {
  /** The mobile phone number which will be used to help identify the user */
  phone: Scalars['String'];
};

export type ChangeUserPictureInput = {
  /** The picture which will be associated with the user */
  picture: Scalars['Upload'];
};

export enum Channel {
  Transparently = 'TRANSPARENTLY',
  Email = 'EMAIL'
}

/** An answer to a part of a question that requires a choice from a set of options */
export type ChoiceAnswer = Answer & {
  __typename?: 'ChoiceAnswer';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The part of a question for which this is an answer */
  questionPart: QuestionPart;
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  proposedOn: Scalars['DateTime'];
  /** Whether or not this answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The revision history of this answer */
  history: Array<AnswerRevision>;
  /** The current status of this answer */
  status: AnswerStatus;
  /** Whether or not this answer has been accepted by a professional */
  isAcceptedByProfessional: Scalars['Boolean'];
  /** Whether or not this answer has been accepted by a client */
  isAcceptedByClient: Scalars['Boolean'];
  /** Whether or not this question part has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
  /** The option(s) chosen for the answer */
  currentValue?: Maybe<Array<Scalars['String']>>;
};

/** A draft answer to a part of a question that requires a choice from a set of options */
export type ChoiceAnswerDraft = AnswerDraft & {
  __typename?: 'ChoiceAnswerDraft';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The part of a question for which this is an answer */
  questionPart: QuestionPart;
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  startedOn: Scalars['DateTime'];
  /** Whether or not this draft of the answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The option(s) chosen for the answer */
  currentValue?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** A revision to an answer to a part of a question that requires a choice from a set of options */
export type ChoiceAnswerRevision = AnswerRevision & {
  __typename?: 'ChoiceAnswerRevision';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  proposedOn: Scalars['DateTime'];
  /** Whether or not this revision of the answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The option(s) chosen for the answer */
  value?: Maybe<Array<Scalars['String']>>;
};

/** A part of a question that requires a choice from a set of options */
export type ChoicePart = QuestionPart & {
  __typename?: 'ChoicePart';
  /** The unique ID of this question part */
  id: Scalars['String'];
  /** Which type of part this is */
  type: QuestionPartType;
  /** The label to be shown for this part */
  label: Scalars['String'];
  /** Additional instructions and information to support the user */
  instructions?: Maybe<Scalars['String']>;
  /** Official advice for completing this question part */
  officialAdvice?: Maybe<Scalars['String']>;
  /** Additional advice for completing this question part */
  additionalAdvice?: Maybe<Scalars['String']>;
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** Whether or not this question part has been completed */
  isComplete: Scalars['Boolean'];
  /** The ordering index for use when showing this part alongside other parts of the same question */
  ordering: Scalars['Int'];
  /** The current draft answer for this question part */
  answerDraft?: Maybe<AnswerDraft>;
  /** The answer given to this question part */
  answer?: Maybe<Answer>;
  /** Whether or not this question part has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
  /** The list of available options */
  options: Array<Option>;
  /** The minimum number of choices permitted */
  minimumChoices: Scalars['Int'];
  /** The maximum number of choices permitted */
  maximumChoices: Scalars['Int'];
  /** The configuration for this part */
  configuration: ChoicePartConfiguration;
};

/** The configuration for a part of a question that requires a choice from a set of options */
export type ChoicePartConfiguration = {
  __typename?: 'ChoicePartConfiguration';
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** The list of available options */
  options: Array<Option>;
  /** The minimum number of choices permitted */
  minimumChoices: Scalars['Int'];
  /** The maximum number of choices permitted */
  maximumChoices: Scalars['Int'];
};

export type ClearAnswerDraftInput = {
  /** The unique ID of the question to which the part belongs */
  questionId: Scalars['ID'];
  /** The unique ID of the question part to which the answer draft belongs */
  questionPartId: Scalars['ID'];
};

export type ClearHighlightInput = {
  highlightableId: Scalars['ID'];
};

export type ClearHighlightsInput = {
  highlightableIds?: Maybe<Array<Scalars['ID']>>;
};

export type ClearTaskDueDateInput = {
  /** The unique ID of the task */
  taskId: Scalars['ID'];
};

export type ClearTaskSectionDueDateInput = {
  /** The unique ID of the task */
  taskId: Scalars['ID'];
  /** The unique ID of the task section */
  sectionId: Scalars['ID'];
};

/** An account held with a firm by a client */
export type ClientAccount = {
  __typename?: 'ClientAccount';
  /** The unique ID of this attachment */
  id: Scalars['ID'];
  /** A custom reference number or code for this account */
  referenceNumber?: Maybe<Scalars['String']>;
  /** The client */
  client: FirmClient;
  /** The firm for which this is a client account */
  firm: Firm;
  /** The lead professional on this client account */
  leadProfessional: FirmProfessional;
  /** The professionals assigned to this client account */
  assignedProfessionals: Array<FirmProfessional>;
  /** The firm members assigned to this client account */
  assignedFirmMembers: Array<FirmMember>;
  /** The current status of this client account */
  status: ClientAccountStatus;
  /** A list of notes that are associated with this client account */
  notes?: Maybe<NoteList>;
  /** A list of matters that are related to this client account */
  matters: Array<Matter>;
  /** The key dates associated with this client account */
  keyDates: Array<KeyDate>;
  /** Whether or not this client account has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
  /** Highlights on objects related to this client account for the current user */
  highlights: HighlightCountsPayload;
  /** The conversation between the client and the firm */
  clientConversation?: Maybe<Conversation>;
  /** The conversation between members of the client's legal team */
  firmConversation?: Maybe<Conversation>;
};


/** An account held with a firm by a client */
export type ClientAccountNotesArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type ClientAccountList = {
  __typename?: 'ClientAccountList';
  /** List of items on the current page */
  items?: Maybe<Array<Maybe<ClientAccount>>>;
  /** Number of total items selected by the query */
  total: Scalars['Int'];
  /** Number of items returned per page */
  perPage: Scalars['Int'];
  /** Current page of the cursor */
  currentPage: Scalars['Int'];
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']>;
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']>;
  /** The last page (number of pages) */
  lastPage: Scalars['Int'];
  /** Determines if cursor has more pages after the current page */
  hasMorePages: Scalars['Boolean'];
};

export enum ClientAccountStatus {
  ProfileIncomplete = 'PROFILE_INCOMPLETE',
  ReviewRequired = 'REVIEW_REQUIRED',
  ApprovalRequired = 'APPROVAL_REQUIRED',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

/** A summary of all features for the current individual, relating to a specific client account */
export type ClientAccountSummary = {
  __typename?: 'ClientAccountSummary';
  /** The number of client profile changes that require attention */
  unverifiedProfileChangesCount?: Maybe<Scalars['Int']>;
  /** The number of answers that require attention */
  pendingAnswersCount?: Maybe<Scalars['Int']>;
  /** The number of messages that are unread */
  unreadMessagesCount?: Maybe<Scalars['Int']>;
  /** The number of notes that are unread */
  unreadNotesCount?: Maybe<Scalars['Int']>;
  /** The number of key dates that occur in the next 7 days */
  upcomingKeyDatesCount?: Maybe<Scalars['Int']>;
  /** The number of professionals that have been assigned to the client */
  assignedProfessionalsCount?: Maybe<Scalars['Int']>;
};

/** An assignment of a firm member to a client account */
export type ClientAssignment = {
  __typename?: 'ClientAssignment';
  /** The unique ID of this assignment */
  id: Scalars['ID'];
  /** The firm member assigned */
  firmMember: FirmMember;
  /** The client account to which the firm member is assigned */
  clientAccount: ClientAccount;
  /** Whether or not this is a lead assignment */
  isLead: Scalars['Boolean'];
  /** The date/time when this assignment was made */
  assignedOn: Scalars['DateTime'];
  /** Whether or not this assignment has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
};

/** The result of revoking a client assignment */
export type ClientAssignmentRevokedPayload = {
  __typename?: 'ClientAssignmentRevokedPayload';
  /** The unique ID of the removed client assignment */
  clientAssignmentId: Scalars['ID'];
  /** The date and time when the client assignment was revoked */
  revokedOn: Scalars['DateTime'];
};

export type CloseCourtCaseInput = {
  /** The unique ID of the court case being closed */
  courtCaseId: Scalars['ID'];
};

export type ComposeMessageInput = {
  /** The ID of the conversation into which the message will be sent */
  conversationId: Scalars['ID'];
  /** If this is a reply, this is a reference to the subject message */
  subjectMessageId?: Maybe<Scalars['ID']>;
  /** The main body of the message */
  body: Scalars['String'];
  /** A list of references to file revisions that should be added as attachments */
  fileRevisionIds?: Maybe<Array<Scalars['ID']>>;
  /** A list of IDs of topics to be added to the message */
  topicIds?: Maybe<Array<Scalars['ID']>>;
};

export type ConfigureListItemAttributeInput = {
  /** Whether or not a response is expected for this part. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Whether or not to use the name portion of the address. */
  useName?: Maybe<Scalars['Boolean']>;
  /** Whether or not to make use of the time portion of the date. */
  useTime?: Maybe<Scalars['Boolean']>;
  /** The earliest date allowed. */
  earliestDate?: Maybe<Scalars['DateTime']>;
  /** The latest date allowed. */
  latestDate?: Maybe<Scalars['DateTime']>;
  /** The maximum number of files that can be chosen. */
  maximumFiles?: Maybe<Scalars['Int']>;
  /** The currency in which the amount should be shown. */
  currency?: Maybe<Scalars['String']>;
  /** The maximum amount allowed. */
  maximumAmount?: Maybe<Scalars['Int']>;
  /** The minimum value expected. */
  minimumValue?: Maybe<Scalars['Int']>;
  /** The maximum value allowed. */
  maximumValue?: Maybe<Scalars['Int']>;
  /** The minimum length of text expected. */
  minimumLength?: Maybe<Scalars['Int']>;
  /** The maximum length of text allowed. */
  maximumLength?: Maybe<Scalars['Int']>;
};

export type ConfigureQuestionPartInput = {
  /** Whether or not a response is expected for this part. */
  isRequired?: Maybe<Scalars['Boolean']>;
  /** Whether or not to use the name portion of the address. */
  useName?: Maybe<Scalars['Boolean']>;
  /** The label to show for the positive option. */
  positiveLabel?: Maybe<Scalars['String']>;
  /** The label to show for the negative option. */
  negativeLabel?: Maybe<Scalars['String']>;
  /** The list of options from which choices can be made. */
  options?: Maybe<Array<DefineChoiceOptionInput>>;
  /** The minimum number of choices expected. */
  minimumChoices?: Maybe<Scalars['Int']>;
  /** The maximum number of choices allowed. */
  maximumChoices?: Maybe<Scalars['Int']>;
  /** Whether or not to make use of the time portion of the date. */
  useTime?: Maybe<Scalars['Boolean']>;
  /** The earliest date allowed. */
  earliestDate?: Maybe<Scalars['DateTime']>;
  /** The latest date allowed. */
  latestDate?: Maybe<Scalars['DateTime']>;
  /** The maximum number of files that can be chosen. */
  maximumFiles?: Maybe<Scalars['Int']>;
  /** The attributes of each list item. */
  attributes?: Maybe<Array<DefineListItemAttributeInput>>;
  /** The minimum number of items expected. */
  minimumItems?: Maybe<Scalars['Int']>;
  /** The maximum number of items allowed. */
  maximumItems?: Maybe<Scalars['Int']>;
  /** The currency in which the amount should be shown. */
  currency?: Maybe<Scalars['String']>;
  /** The minimum amount allowed. */
  minimumAmount?: Maybe<Scalars['Float']>;
  /** The maximum amount allowed. */
  maximumAmount?: Maybe<Scalars['Float']>;
  /** The minimum value expected. */
  minimumValue?: Maybe<Scalars['Float']>;
  /** The maximum value allowed. */
  maximumValue?: Maybe<Scalars['Float']>;
  /** The minimum length of text expected. */
  minimumLength?: Maybe<Scalars['Int']>;
  /** The maximum length of text allowed. */
  maximumLength?: Maybe<Scalars['Int']>;
};

/** A single trusted contact */
export type Contact = {
  __typename?: 'Contact';
  /** The unique ID of this contact */
  id: Scalars['ID'];
  /** The owner of this contact */
  owner: ContactOwner;
  /** Which kind of contact this is */
  type: ContactType;
  /** The contact's email address */
  emailAddress: Scalars['EmailAddress'];
  /** A URL pointing to the contact's picture */
  pictureUrl: Scalars['String'];
  /** The contact's preferred name */
  preferredName: Scalars['String'];
  /** The contact's first name */
  firstName: Scalars['String'];
  /** The contact's last name */
  lastName: Scalars['String'];
  /** The contact's full name */
  fullName: Scalars['String'];
  /** The contact's title (if appropriate) */
  title?: Maybe<Scalars['String']>;
  /** The ID of the conversation the current user has with the contact, if at all */
  conversationId?: Maybe<Scalars['ID']>;
  /** Whether or not this contact has a transparently user account */
  isTransparentlyUser: Scalars['Boolean'];
  /** The ID of the associated transparently user account. This will always have a value, even if the contact is not a transparently user. */
  userId: Scalars['ID'];
  /** Whether or not this contact is associated with a firm (e.g. a solicitor) */
  isAssociatedWithFirm: Scalars['Boolean'];
  /** The firm the contact is associated with, if at all */
  firm?: Maybe<Firm>;
  /** Whether or not this contact is associated with an issue (e.g. an opponent) */
  isAssociatedWithIssue: Scalars['Boolean'];
  /** The issue the contact is associated with, if at all */
  issue?: Maybe<Issue>;
  /** The current status of this contact */
  status: ContactStatus;
  /** Whether or not this contact has has their details verified */
  isVerified: Scalars['Boolean'];
  /** If this contact has not been verified, this is the code needed to verify them */
  verificationCode?: Maybe<Scalars['String']>;
  /** Whether or not this note has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
};

/** The owner of a contact list */
export type ContactOwner = {
  __typename?: 'ContactOwner';
  /** The unique ID of this owner */
  id: Scalars['ID'];
  /** The owner's email address */
  emailAddress: Scalars['EmailAddress'];
  /** A URL pointing to the owner's picture */
  pictureUrl: Scalars['String'];
  /** The owner's preferred name */
  preferredName: Scalars['String'];
  /** The owner's first name */
  firstName: Scalars['String'];
  /** The owner's last name */
  lastName: Scalars['String'];
  /** The owner's full name */
  fullName: Scalars['String'];
};

export enum ContactStatus {
  Pending = 'PENDING',
  VerificationExpired = 'VERIFICATION_EXPIRED',
  Verified = 'VERIFIED',
  Rejected = 'REJECTED',
  Inactive = 'INACTIVE'
}

export enum ContactType {
  Client = 'CLIENT',
  Colleague = 'COLLEAGUE',
  General = 'GENERAL',
  LegalProfessional = 'LEGAL_PROFESSIONAL',
  Opponent = 'OPPONENT',
  SupportProfessional = 'SUPPORT_PROFESSIONAL'
}

/** A single conversation */
export type Conversation = {
  __typename?: 'Conversation';
  /** The unique ID of this conversation */
  id: Scalars['ID'];
  /** The name of this conversation from the perspective of the current user */
  name: Scalars['String'];
  /** The description of this conversation from the perspective of the current user */
  description?: Maybe<Scalars['String']>;
  /** The URL for this conversation's picture from the perspective of the current user */
  pictureUrl: Scalars['String'];
  /** The date and time when this conversation was originally started */
  startedAt: Scalars['DateTime'];
  /** A list of all of this conversation's groups */
  groups: Array<ConversationGroup>;
  /** A list of all of this conversation's members */
  members: Array<ConversationMember>;
  /** A list of only this conversation's participants */
  participants: Array<ConversationParticipant>;
  /** Whether or not the current user is participating */
  participating: Scalars['Boolean'];
  /** A list of only this conversation's observers */
  observers: Array<ConversationObserver>;
  /** Whether or not the current user is observing */
  observing: Scalars['Boolean'];
  /** The message draft for the current user. This is only available for participants. */
  draft?: Maybe<MessageDraft>;
  /** Whether or not the conversation is paused for the current user */
  isPaused: Scalars['Boolean'];
  /** The date/time on which the conversation was paused for the current user, if at all */
  pausedOn?: Maybe<Scalars['DateTime']>;
  /** The date/time until which the conversation is paused for the current user, if at all */
  pausedUntil?: Maybe<Scalars['DateTime']>;
  /** The total number of messages unread in the conversation */
  messageCount: Scalars['Int'];
  /** The number of messages unread by the current user */
  unreadMessageCount: Scalars['Int'];
  /** The last time a message was sent by any participant in this conversation */
  lastMessageSentAt?: Maybe<Scalars['DateTime']>;
  /** The last time the current user had a message delivered to them in this conversation */
  lastMessageDeliveredAt?: Maybe<Scalars['DateTime']>;
  /** The last time the current user read a message in this conversation */
  lastMessageReadAt?: Maybe<Scalars['DateTime']>;
  /** The last message sent to this conversation */
  lastMessage?: Maybe<Message>;
  /** The paginated list of messages in this conversation */
  messages?: Maybe<MessageList>;
  /** Additional metadata specified for this conversation */
  metadata: Array<KeyValue>;
};


/** A single conversation */
export type ConversationMessagesArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  focusMessageId?: Maybe<Scalars['ID']>;
};

/** A single extract from a conversation for a matter */
export type ConversationExtract = {
  __typename?: 'ConversationExtract';
  /** The unique ID of this conversation extract */
  id: Scalars['ID'];
  /** The reference name given to the conversation extract */
  name: Scalars['String'];
  /** The matter to which this belongs */
  matter: Matter;
  /** The conversation from which this is an extract */
  conversation: Conversation;
  /** The date and time when this extract was made */
  extractedAt?: Maybe<Scalars['DateTime']>;
  /** The filters used to make this extract */
  messageFilters: MessageFilters;
  /** The extracted messages */
  messages?: Maybe<MessageList>;
};


/** A single extract from a conversation for a matter */
export type ConversationExtractMessagesArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

/** The result of discarding a conversation extract */
export type ConversationExtractDiscardedPayload = {
  __typename?: 'ConversationExtractDiscardedPayload';
  /** The unique ID of the discarded conversation extract */
  conversationExtractId: Scalars['ID'];
  /** The date and time when the conversation extract was discarded */
  discardedOn: Scalars['DateTime'];
};

/** A group of members within a conversation */
export type ConversationGroup = {
  __typename?: 'ConversationGroup';
  /** The unique ID of this conversation group */
  id: Scalars['ID'];
  /** The name given to this group */
  name: Scalars['String'];
  /** The URL of the display image for this group */
  pictureUrl: Scalars['String'];
  /** A list of all of this conversation group's current members */
  members: Array<ConversationMember>;
};

/** Someone who is involved in a conversation in some way */
export type ConversationMember = {
  /** The unique ID of this member */
  id: Scalars['ID'];
  /** The member's full name */
  name?: Maybe<Scalars['String']>;
  /** The member's preferred name */
  preferredName?: Maybe<Scalars['String']>;
  /** The member's first name */
  firstName?: Maybe<Scalars['String']>;
  /** The member's last name */
  lastName?: Maybe<Scalars['String']>;
  /** A URL pointing to the member's picture */
  pictureUrl?: Maybe<Scalars['String']>;
  /** The member's title (if appropriate) */
  title?: Maybe<Scalars['String']>;
  /** The role played in the conversation by this member */
  role: ConversationMemberRole;
  /** Whether or not this member has paused their conversation membership */
  isPaused: Scalars['Boolean'];
  /** The date and time when this member paused their conversation membership, if at all */
  pausedOn?: Maybe<Scalars['DateTime']>;
  /** The date and time until which this member paused their conversation membership, if at all */
  pausedUntil?: Maybe<Scalars['DateTime']>;
  /** The conversation group in which this conversation member has been placed */
  group?: Maybe<ConversationGroup>;
  /** The current status of this conversation member */
  status: ConversationMemberStatus;
};

export enum ConversationMemberRole {
  Professional = 'PROFESSIONAL',
  Client = 'CLIENT',
  Other = 'OTHER'
}

export enum ConversationMemberStatus {
  Joined = 'JOINED',
  Paused = 'PAUSED',
  Inactive = 'INACTIVE',
  Left = 'LEFT'
}

/** Someone who can observe messages sent in a conversation */
export type ConversationObserver = ConversationMember & {
  __typename?: 'ConversationObserver';
  /** The unique ID of this member */
  id: Scalars['ID'];
  /** The member's full name */
  name?: Maybe<Scalars['String']>;
  /** The member's preferred name */
  preferredName?: Maybe<Scalars['String']>;
  /** The member's first name */
  firstName?: Maybe<Scalars['String']>;
  /** The member's last name */
  lastName?: Maybe<Scalars['String']>;
  /** A URL pointing to the member's picture */
  pictureUrl?: Maybe<Scalars['String']>;
  /** The member's title (if appropriate) */
  title?: Maybe<Scalars['String']>;
  /** The role played in the conversation by this member */
  role: ConversationMemberRole;
  /** Whether or not this member has paused their conversation membership */
  isPaused: Scalars['Boolean'];
  /** The date and time when this member paused their conversation membership, if at all */
  pausedOn?: Maybe<Scalars['DateTime']>;
  /** The date and time until which this member paused their conversation membership, if at all */
  pausedUntil?: Maybe<Scalars['DateTime']>;
  /** The conversation group in which this conversation member has been placed */
  group?: Maybe<ConversationGroup>;
  /** The current status of this conversation member */
  status: ConversationMemberStatus;
};

/** Someone who can contribute to a conversation by sending messages */
export type ConversationParticipant = ConversationMember & {
  __typename?: 'ConversationParticipant';
  /** The unique ID of this member */
  id: Scalars['ID'];
  /** The member's full name */
  name?: Maybe<Scalars['String']>;
  /** The member's preferred name */
  preferredName?: Maybe<Scalars['String']>;
  /** The member's first name */
  firstName?: Maybe<Scalars['String']>;
  /** The member's last name */
  lastName?: Maybe<Scalars['String']>;
  /** A URL pointing to the member's picture */
  pictureUrl?: Maybe<Scalars['String']>;
  /** The member's title (if appropriate) */
  title?: Maybe<Scalars['String']>;
  /** The role played in the conversation by this member */
  role: ConversationMemberRole;
  /** Whether or not this member has paused their conversation membership */
  isPaused: Scalars['Boolean'];
  /** The date and time when this member paused their conversation membership, if at all */
  pausedOn?: Maybe<Scalars['DateTime']>;
  /** The date and time until which this member paused their conversation membership, if at all */
  pausedUntil?: Maybe<Scalars['DateTime']>;
  /** The conversation group in which this conversation member has been placed */
  group?: Maybe<ConversationGroup>;
  /** The current status of this conversation member */
  status: ConversationMemberStatus;
  /** The participant's email address */
  emailAddress: Scalars['EmailAddress'];
  /** The channel being used for communication with this participant */
  channel: Channel;
};

/** A court case during which a matter may be heard */
export type CourtCase = {
  __typename?: 'CourtCase';
  /** The unique ID for the court case */
  id: Scalars['ID'];
  /** The matter to which this court case refers */
  matter: Matter;
  /** The name of the court in which this case will be heard */
  courtName: Scalars['String'];
  /** The case number given to this case by the courts (if known) */
  caseNumber?: Maybe<Scalars['String']>;
  /** The date/time when the case will be heard in court (if known) */
  hearingDate?: Maybe<KeyDate>;
  /** The current status of this court case */
  status: CourtCaseStatus;
};

export enum CourtCaseStatus {
  Preparing = 'PREPARING',
  Pending = 'PENDING',
  Open = 'OPEN',
  Closed = 'CLOSED'
}

export type CreatePrivateTopicInput = {
  /** The display name for the topic */
  name: Scalars['String'];
  /** The colour name to use when displaying the topic */
  colour: Scalars['String'];
};

/** An answer to a part of a question that requires a date/time response */
export type DateAnswer = Answer & {
  __typename?: 'DateAnswer';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The part of a question for which this is an answer */
  questionPart: QuestionPart;
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  proposedOn: Scalars['DateTime'];
  /** Whether or not this answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The revision history of this answer */
  history: Array<AnswerRevision>;
  /** The current status of this answer */
  status: AnswerStatus;
  /** Whether or not this answer has been accepted by a professional */
  isAcceptedByProfessional: Scalars['Boolean'];
  /** Whether or not this answer has been accepted by a client */
  isAcceptedByClient: Scalars['Boolean'];
  /** Whether or not this question part has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
  /** The date/time selected for the answer */
  currentValue?: Maybe<Scalars['DateTime']>;
};

/** A draft answer to a part of a question that requires a date/time response */
export type DateAnswerDraft = AnswerDraft & {
  __typename?: 'DateAnswerDraft';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The part of a question for which this is an answer */
  questionPart: QuestionPart;
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  startedOn: Scalars['DateTime'];
  /** Whether or not this draft of the answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The date/time selected for the answer */
  currentValue?: Maybe<Scalars['DateTime']>;
};

/** A revision to an answer to a part of a question that requires a date/time response */
export type DateAnswerRevision = AnswerRevision & {
  __typename?: 'DateAnswerRevision';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  proposedOn: Scalars['DateTime'];
  /** Whether or not this revision of the answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The date/time selected for the answer */
  value?: Maybe<Scalars['DateTime']>;
};

/** An attribute of a list part that uses a date/time value */
export type DateAttribute = ListAttribute & {
  __typename?: 'DateAttribute';
  /** The unique ID of this attribute */
  id: Scalars['String'];
  /** Which type of attribute this is */
  type: QuestionPartType;
  /** The label to be shown for this attribute */
  label: Scalars['String'];
  /** Additional instructions and information to support the user */
  instructions?: Maybe<Scalars['String']>;
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** The ordering index for use when showing this attribute alongside other attributes of the same part */
  ordering: Scalars['Int'];
  /** Whether or not to use the time portion of the date response */
  useTime: Scalars['Boolean'];
  /** The earliest date required in the response */
  earliestDate?: Maybe<Scalars['DateTime']>;
  /** The latest date required in the response */
  latestDate?: Maybe<Scalars['DateTime']>;
  /** The configuration for this part */
  configuration: DatePartConfiguration;
};

/** A list property that makes use of an date */
export type DateListItemProperty = ListItemProperty & {
  __typename?: 'DateListItemProperty';
  /** The unique ID of the attribute for which this property exists */
  attributeId: Scalars['ID'];
  /** The date given */
  value?: Maybe<Scalars['DateTime']>;
};

/** A part of a question that requires a date/time response */
export type DatePart = QuestionPart & {
  __typename?: 'DatePart';
  /** The unique ID of this question part */
  id: Scalars['String'];
  /** Which type of part this is */
  type: QuestionPartType;
  /** The label to be shown for this part */
  label: Scalars['String'];
  /** Additional instructions and information to support the user */
  instructions?: Maybe<Scalars['String']>;
  /** Official advice for completing this question part */
  officialAdvice?: Maybe<Scalars['String']>;
  /** Additional advice for completing this question part */
  additionalAdvice?: Maybe<Scalars['String']>;
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** Whether or not this question part has been completed */
  isComplete: Scalars['Boolean'];
  /** The ordering index for use when showing this part alongside other parts of the same question */
  ordering: Scalars['Int'];
  /** The current draft answer for this question part */
  answerDraft?: Maybe<AnswerDraft>;
  /** The answer given to this question part */
  answer?: Maybe<Answer>;
  /** Whether or not this question part has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
  /** Whether or not to use the time portion of the date response */
  useTime: Scalars['Boolean'];
  /** The earliest date required in the response */
  earliestDate?: Maybe<Scalars['DateTime']>;
  /** The latest date required in the response */
  latestDate?: Maybe<Scalars['DateTime']>;
  /** The configuration for this part */
  configuration: DatePartConfiguration;
};

/** The configuration for a part of a question that requires a date/time response */
export type DatePartConfiguration = {
  __typename?: 'DatePartConfiguration';
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** Whether or not to use the time portion of the date response */
  useTime: Scalars['Boolean'];
  /** The earliest date required in the response */
  earliestDate?: Maybe<Scalars['DateTime']>;
  /** The latest date required in the response */
  latestDate?: Maybe<Scalars['DateTime']>;
};


export type DefineChoiceOptionInput = {
  /** The value to be offered as an option. */
  value: Scalars['String'];
  /** Additional descriptive information to elaborate on the value (optional). */
  description?: Maybe<Scalars['String']>;
};

export type DefineListItemAttributeInput = {
  /** The attribute ID, if known */
  id?: Maybe<Scalars['ID']>;
  /** Which type of part this is */
  type: QuestionPartType;
  /** The label to show for this part */
  label: Scalars['String'];
  /** Any additional instructions for this part of the question (optional). */
  instructions?: Maybe<Scalars['String']>;
  /** Type-specific configuration values for this part */
  configuration?: Maybe<ConfigureQuestionPartInput>;
  /** The ordering to apply to this attribute (optional). */
  ordering?: Maybe<Scalars['Int']>;
};

export type DefineQuestionPartInput = {
  /** Which type of part this is */
  type: QuestionPartType;
  /** The label to show for this part */
  label: Scalars['String'];
  /** Any additional instructions for this part of the question (optional). */
  instructions?: Maybe<Scalars['String']>;
  /** Official advice for the question part (max 5000 characters) */
  officialAdvice?: Maybe<Scalars['String']>;
  /** Additional advice for the question part (max 5000 characters) */
  additionalAdvice?: Maybe<Scalars['String']>;
  /** The ordering to apply to this part (optional). */
  ordering?: Maybe<Scalars['Int']>;
  /** Type-specific configuration values for this part */
  configuration?: Maybe<ConfigureQuestionPartInput>;
};

export type DefineTaskSectionInput = {
  /** The unique ID of the task */
  taskId: Scalars['ID'];
  /** The name to give the new section */
  sectionName?: Maybe<Scalars['String']>;
  /** The name to give the new section */
  sectionDescription?: Maybe<Scalars['String']>;
  /** Official advice for the task section (max 5000 characters) */
  officialAdvice?: Maybe<Scalars['String']>;
  /** Additional advice for the task section (max 5000 characters) */
  additionalAdvice?: Maybe<Scalars['String']>;
};

export type DescribeTaskInput = {
  /** The unique ID of the task to be described */
  taskId: Scalars['ID'];
  /** A new description for the task (max 500 characters) */
  description: Scalars['String'];
};

export type DescribeTaskSectionInput = {
  /** The unique ID of the task */
  taskId: Scalars['ID'];
  /** The unique ID of the section */
  sectionId: Scalars['ID'];
  /** The new description for the section */
  sectionDescription?: Maybe<Scalars['String']>;
};

export type DisassociateFileFromTopicInput = {
  fileId: Scalars['ID'];
  topicId: Scalars['ID'];
};

export type DisassociateMessageFromTopicInput = {
  messageId: Scalars['ID'];
  topicId: Scalars['ID'];
};

export type DiscardConversationExtractInput = {
  /** The unique ID of the conversation extract to be discarded */
  conversationExtractId: Scalars['ID'];
};

export type DiscardNoteInput = {
  /** The unique ID of the note */
  noteId: Scalars['ID'];
};

/** A document attached to an answer */
export type DocumentAnswerAttachment = AnswerAttachment & {
  __typename?: 'DocumentAnswerAttachment';
  /** The unique ID of this attachment */
  id: Scalars['ID'];
  /** The unique ID of the file revision that was originally attached */
  fileRevisionId: Scalars['ID'];
  /** The original name of the asset used to revise the file */
  originalName: Scalars['String'];
  /** A simple file type, for example: document */
  type: Scalars['String'];
  /** The file's full MIME type (e.g. image/jpeg) */
  mime: Scalars['String'];
  /** The size of the file asset in bytes */
  size: Scalars['Int'];
};

/** A single uploaded document */
export type DocumentFile = File & {
  __typename?: 'DocumentFile';
  /** The unique ID for the file */
  id: Scalars['ID'];
  /** The folder's owner */
  owner?: Maybe<FileOwner>;
  /** The folder in which the file is placed */
  folder: Folder;
  /** The name given to the file */
  name: Scalars['String'];
  /** What type of file this is (e.g. Document, Image, etc.) */
  type: Scalars['String'];
  /** The file's full MIME type (e.g. image/jpeg) */
  mime: Scalars['String'];
  /** The size of the raw file, in bytes */
  size: Scalars['Int'];
  /** The files current version number */
  version: Scalars['Int'];
  /** The ID of the revision that represents the current version of this file */
  currentRevisionId: Scalars['ID'];
  /** The date and time when this file was last revised */
  lastRevisedOn: Scalars['DateTime'];
  /** The history of revisions made to this file */
  revisions: Array<FileRevision>;
  /** The topics associated with this file by its owner */
  topics: Array<Topic>;
};

/** A version of a document */
export type DocumentFileRevision = FileRevision & {
  __typename?: 'DocumentFileRevision';
  /** The unique ID of this file */
  id: Scalars['ID'];
  /** The revision number of this file */
  version: Scalars['Int'];
  /** The original name of the asset used to revise the file */
  originalName: Scalars['String'];
  /** A simple file type, for example: document */
  type: Scalars['String'];
  /** The file's full MIME type (e.g. image/jpeg) */
  mime: Scalars['String'];
  /** The size of the file asset in bytes */
  size: Scalars['Int'];
  /** The date and time when this file was revised */
  revisedOn: Scalars['DateTime'];
};

export type DraftNoteInput = {
  /** The category into which to place the note */
  category: NoteCategory;
  /** The date and time associated with the note body */
  dateTime: Scalars['DateTime'];
  /** The note title */
  title: Scalars['String'];
  /** The note body */
  body: Scalars['String'];
};

export type EditQuestionInput = {
  /** The unique ID of the question */
  questionId: Scalars['ID'];
  /** The new text for the question */
  text?: Maybe<Scalars['String']>;
  /** The new description for the question */
  description?: Maybe<Scalars['String']>;
  /** Official advice for the question (max 5000 characters) */
  officialAdvice?: Maybe<Scalars['String']>;
  /** Additional advice for the question (max 5000 characters) */
  additionalAdvice?: Maybe<Scalars['String']>;
  /** A list of specifications for the parts of this question */
  parts?: Maybe<Array<EditQuestionPartInput>>;
};

export type EditQuestionPartInput = {
  /** The unique ID of this question part, if known */
  id?: Maybe<Scalars['String']>;
  /** Which type of part this is */
  type: QuestionPartType;
  /** The label to show for this part */
  label: Scalars['String'];
  /** Any additional instructions for this part of the question (optional). */
  instructions?: Maybe<Scalars['String']>;
  /** Official advice for the question part (max 5000 characters) */
  officialAdvice?: Maybe<Scalars['String']>;
  /** Additional advice for the question part (max 5000 characters) */
  additionalAdvice?: Maybe<Scalars['String']>;
  /** The ordering to apply to this part (optional). */
  ordering?: Maybe<Scalars['Int']>;
  /** Type-specific configuration values for this part */
  configuration?: Maybe<ConfigureQuestionPartInput>;
};


/** An answer to a part of a question that requires an email address response */
export type EmailAnswer = Answer & {
  __typename?: 'EmailAnswer';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The part of a question for which this is an answer */
  questionPart: QuestionPart;
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  proposedOn: Scalars['DateTime'];
  /** Whether or not this answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The revision history of this answer */
  history: Array<AnswerRevision>;
  /** The current status of this answer */
  status: AnswerStatus;
  /** Whether or not this answer has been accepted by a professional */
  isAcceptedByProfessional: Scalars['Boolean'];
  /** Whether or not this answer has been accepted by a client */
  isAcceptedByClient: Scalars['Boolean'];
  /** Whether or not this question part has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
  /** The email address given for the answer */
  currentValue?: Maybe<Scalars['EmailAddress']>;
};

/** A draft answer to a part of a question that requires an email address response */
export type EmailAnswerDraft = AnswerDraft & {
  __typename?: 'EmailAnswerDraft';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The part of a question for which this is an answer */
  questionPart: QuestionPart;
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  startedOn: Scalars['DateTime'];
  /** Whether or not this draft of the answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The email address given for the answer */
  currentValue?: Maybe<Scalars['EmailAddress']>;
};

/** A revision to an answer to a part of a question that requires an email address response */
export type EmailAnswerRevision = AnswerRevision & {
  __typename?: 'EmailAnswerRevision';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  proposedOn: Scalars['DateTime'];
  /** Whether or not this revision of the answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The email address given for the answer */
  value?: Maybe<Scalars['EmailAddress']>;
};

/** An attribute of a list part that uses an email value */
export type EmailAttribute = ListAttribute & {
  __typename?: 'EmailAttribute';
  /** The unique ID of this attribute */
  id: Scalars['String'];
  /** Which type of attribute this is */
  type: QuestionPartType;
  /** The label to be shown for this attribute */
  label: Scalars['String'];
  /** Additional instructions and information to support the user */
  instructions?: Maybe<Scalars['String']>;
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** The ordering index for use when showing this attribute alongside other attributes of the same part */
  ordering: Scalars['Int'];
  /** The configuration for this part */
  configuration: EmailPartConfiguration;
};

/** A list property that makes use of an email address */
export type EmailListItemProperty = ListItemProperty & {
  __typename?: 'EmailListItemProperty';
  /** The unique ID of the attribute for which this property exists */
  attributeId: Scalars['ID'];
  /** The email address given */
  value?: Maybe<Scalars['EmailAddress']>;
};

/** A part of a question that requires an email address response */
export type EmailPart = QuestionPart & {
  __typename?: 'EmailPart';
  /** The unique ID of this question part */
  id: Scalars['String'];
  /** Which type of part this is */
  type: QuestionPartType;
  /** The label to be shown for this part */
  label: Scalars['String'];
  /** Additional instructions and information to support the user */
  instructions?: Maybe<Scalars['String']>;
  /** Official advice for completing this question part */
  officialAdvice?: Maybe<Scalars['String']>;
  /** Additional advice for completing this question part */
  additionalAdvice?: Maybe<Scalars['String']>;
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** Whether or not this question part has been completed */
  isComplete: Scalars['Boolean'];
  /** The ordering index for use when showing this part alongside other parts of the same question */
  ordering: Scalars['Int'];
  /** The current draft answer for this question part */
  answerDraft?: Maybe<AnswerDraft>;
  /** The answer given to this question part */
  answer?: Maybe<Answer>;
  /** Whether or not this question part has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
  /** The configuration for this part */
  configuration: EmailPartConfiguration;
};

/** The configuration for a part of a question that requires an email address response */
export type EmailPartConfiguration = {
  __typename?: 'EmailPartConfiguration';
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
};

export type EndMatterInput = {
  /** The unique ID of the matter */
  matterId: Scalars['ID'];
};

export type ExportSupportingMaterialInput = {
  /** The unique ID of the matter */
  matterId: Scalars['ID'];
  /** A list of IDs of items to include in the export. Set as null to export everything. */
  materialIds?: Maybe<Array<Scalars['ID']>>;
};

/** A failed outcome of an authentication attempt */
export type FailedAuthenticationPayload = AuthenticationPayload & {
  __typename?: 'FailedAuthenticationPayload';
  /** Whether or not the authentication was successful */
  successful: Scalars['Boolean'];
  /** A message describing the authentication outcome */
  message: Scalars['String'];
};

/** An single uploaded file */
export type File = {
  /** The unique ID for the file */
  id: Scalars['ID'];
  /** The folder's owner */
  owner?: Maybe<FileOwner>;
  /** The folder in which the file is placed */
  folder: Folder;
  /** The name given to the file */
  name: Scalars['String'];
  /** What type of file this is (e.g. Document, Image, etc.) */
  type: Scalars['String'];
  /** The file's full MIME type (e.g. image/jpeg) */
  mime: Scalars['String'];
  /** The size of the raw file, in bytes */
  size: Scalars['Int'];
  /** The files current version number */
  version: Scalars['Int'];
  /** The ID of the revision that represents the current version of this file */
  currentRevisionId: Scalars['ID'];
  /** The date and time when this file was last revised */
  lastRevisedOn: Scalars['DateTime'];
  /** The history of revisions made to this file */
  revisions: Array<FileRevision>;
  /** The topics associated with this file by its owner */
  topics: Array<Topic>;
};

/** An answer to a part of a question that requires one or more files */
export type FileAnswer = Answer & {
  __typename?: 'FileAnswer';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The part of a question for which this is an answer */
  questionPart: QuestionPart;
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  proposedOn: Scalars['DateTime'];
  /** Whether or not this answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The revision history of this answer */
  history: Array<AnswerRevision>;
  /** The current status of this answer */
  status: AnswerStatus;
  /** Whether or not this answer has been accepted by a professional */
  isAcceptedByProfessional: Scalars['Boolean'];
  /** Whether or not this answer has been accepted by a client */
  isAcceptedByClient: Scalars['Boolean'];
  /** Whether or not this question part has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
  /** The file(s) chosen for the answer */
  currentValue?: Maybe<Array<SupportingFile>>;
};

/** A draft answer to a part of a question that requires one or more files */
export type FileAnswerDraft = AnswerDraft & {
  __typename?: 'FileAnswerDraft';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The part of a question for which this is an answer */
  questionPart: QuestionPart;
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  startedOn: Scalars['DateTime'];
  /** Whether or not this draft of the answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The file(s) chosen for the answer */
  currentValue?: Maybe<Array<SupportingFile>>;
};

/** A revision to an answer to a part of a question that requires one or more files */
export type FileAnswerRevision = AnswerRevision & {
  __typename?: 'FileAnswerRevision';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  proposedOn: Scalars['DateTime'];
  /** Whether or not this revision of the answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The file(s) chosen for the answer */
  value?: Maybe<Array<SupportingFile>>;
};

/** An attribute of a list part that uses one or more files */
export type FileAttribute = ListAttribute & {
  __typename?: 'FileAttribute';
  /** The unique ID of this attribute */
  id: Scalars['String'];
  /** Which type of attribute this is */
  type: QuestionPartType;
  /** The label to be shown for this attribute */
  label: Scalars['String'];
  /** Additional instructions and information to support the user */
  instructions?: Maybe<Scalars['String']>;
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** The ordering index for use when showing this attribute alongside other attributes of the same part */
  ordering: Scalars['Int'];
  /** The maximum number of files allowed in the response */
  maximumFiles: Scalars['Int'];
  /** The configuration for this part */
  configuration: FilePartConfiguration;
};

export type FileDescriptorInput = {
  /** The unique ID of the file being described */
  fileId: Scalars['ID'];
  /** The version number of the file being described */
  fileVersion: Scalars['Int'];
};

/** A list property that makes use of a list of file IDs */
export type FileListItemProperty = ListItemProperty & {
  __typename?: 'FileListItemProperty';
  /** The unique ID of the attribute for which this property exists */
  attributeId: Scalars['ID'];
  /** The file IDs given */
  value?: Maybe<Array<Scalars['ID']>>;
};

/** The owner of a file or folder */
export type FileOwner = {
  __typename?: 'FileOwner';
  /** The unique ID of this owner */
  id: Scalars['ID'];
  /** The owner's preferred name */
  preferredName: Scalars['String'];
  /** The owner's first name */
  firstName: Scalars['String'];
  /** The owner's last name */
  lastName: Scalars['String'];
  /** The owner's email address */
  emailAddress: Scalars['String'];
  /** The URL to the owner's picture */
  pictureUrl: Scalars['String'];
};

/** A part of a question that requires one or more files in response */
export type FilePart = QuestionPart & {
  __typename?: 'FilePart';
  /** The unique ID of this question part */
  id: Scalars['String'];
  /** Which type of part this is */
  type: QuestionPartType;
  /** The label to be shown for this part */
  label: Scalars['String'];
  /** Additional instructions and information to support the user */
  instructions?: Maybe<Scalars['String']>;
  /** Official advice for completing this question part */
  officialAdvice?: Maybe<Scalars['String']>;
  /** Additional advice for completing this question part */
  additionalAdvice?: Maybe<Scalars['String']>;
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** Whether or not this question part has been completed */
  isComplete: Scalars['Boolean'];
  /** The ordering index for use when showing this part alongside other parts of the same question */
  ordering: Scalars['Int'];
  /** The current draft answer for this question part */
  answerDraft?: Maybe<AnswerDraft>;
  /** The answer given to this question part */
  answer?: Maybe<Answer>;
  /** Whether or not this question part has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
  /** The maximum number of files allowed in the response */
  maximumFiles: Scalars['Int'];
  /** The configuration for this part */
  configuration: FilePartConfiguration;
};

/** The configuration for a part of a question that requires one or more files in response */
export type FilePartConfiguration = {
  __typename?: 'FilePartConfiguration';
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** The maximum number of files allowed in the response */
  maximumFiles: Scalars['Int'];
};

/** The result of removing a file */
export type FileRemovedPayload = {
  __typename?: 'FileRemovedPayload';
  /** The unique ID of the removed file */
  fileId: Scalars['ID'];
  /** The date and time when the file was removed */
  removedOn: Scalars['DateTime'];
};

/** A version of a single file */
export type FileRevision = {
  /** The unique ID of this file */
  id: Scalars['ID'];
  /** The revision number of this file */
  version: Scalars['Int'];
  /** The original name of the asset used to revise the file */
  originalName: Scalars['String'];
  /** A simple file type, for example: document */
  type: Scalars['String'];
  /** The file's full MIME type (e.g. image/jpeg) */
  mime: Scalars['String'];
  /** The size of the file asset in bytes */
  size: Scalars['Int'];
  /** The date and time when this file was revised */
  revisedOn: Scalars['DateTime'];
};

/** A single legal firm */
export type Firm = {
  __typename?: 'Firm';
  /** The unique ID of this firm */
  id: Scalars['ID'];
  /** Which type of firm this is */
  type: FirmType;
  /** The firm's transparently reference number */
  referenceNumber: Scalars['String'];
  /** The firm's company registration number number */
  companyNumber: Scalars['String'];
  /** The firm's SRA number */
  sraNumber: Scalars['String'];
  /** The official firm name */
  name: Scalars['String'];
  /** A URL pointing firm's logo */
  logoUrl: Scalars['String'];
  /** The total number of seats available for use by the firm */
  seatCount: Scalars['Int'];
  /** The number of seats that are currently occupied */
  occupiedSeatCount: Scalars['Int'];
  /** The number of seats that are currently vacant */
  vacantSeatCount: Scalars['Int'];
  /** The total number of members, including inactive members */
  memberCount: Scalars['Int'];
  /** The list of this firm's members */
  members: Array<FirmMember>;
  /** The number of client accounts held by this firm */
  clientAccountCount: Scalars['Int'];
  /** The number of client accounts held by this firm that are currently open */
  openClientAccountCount: Scalars['Int'];
};

/** A legal professional that represents clients in court */
export type FirmBarrister = FirmProfessional & {
  __typename?: 'FirmBarrister';
  /** The professional's unique ID */
  id: Scalars['ID'];
  /** Which type of professional this is */
  type: FirmProfessionalType;
  /** The professional's unique reference number */
  referenceNumber: Scalars['String'];
  /** The professional's first and last name */
  name: Scalars['String'];
  /** The professional's preferred name (defaults to their first name) */
  preferredName: Scalars['String'];
  /** The professional's first name */
  firstName?: Maybe<Scalars['String']>;
  /** The professional's middle name(s) */
  middleNames: Scalars['String'];
  /** The professional's last name */
  lastName: Scalars['String'];
  /** The professional's full name */
  fullName: Scalars['String'];
  /** The professional's email address */
  emailAddress: Scalars['EmailAddress'];
  /** The professional's work phone number */
  workPhoneNumber?: Maybe<Scalars['String']>;
  /** The URL pointing to the professional's picture */
  pictureUrl: Scalars['String'];
  /** The current status of the professional */
  status: FirmProfessionalStatus;
  /** The barrister's SRA number */
  sraNumber: Scalars['String'];
};

/** A client that may or may not hold and account with one or more firms */
export type FirmClient = {
  __typename?: 'FirmClient';
  /** The client's unique ID */
  id: Scalars['ID'];
  /** The client's unique reference number */
  referenceNumber: Scalars['String'];
  /** The client's first and last name */
  name: Scalars['String'];
  /** The client's preferred name */
  preferredName: Scalars['String'];
  /** The client's first name */
  firstName: Scalars['String'];
  /** The client's middle name(s) */
  middleNames?: Maybe<Scalars['String']>;
  /** The client's last name */
  lastName: Scalars['String'];
  /** The client's full name */
  fullName: Scalars['String'];
  /** The client's previous first name */
  previousFirstName: Scalars['String'];
  /** The client's previous middle name(s) */
  previousMiddleNames?: Maybe<Scalars['String']>;
  /** The client's previous last name */
  previousLastName: Scalars['String'];
  /** The client's previous full name */
  previousFullName: Scalars['String'];
  /** The URL pointing to the client's picture */
  pictureUrl: Scalars['String'];
  /** The client's date of birth */
  birthDate?: Maybe<Scalars['DateTime']>;
  /** The client's email address */
  emailAddress: Scalars['EmailAddress'];
  /** The client's mobile phone number */
  mobilePhoneNumber?: Maybe<Scalars['String']>;
  /** The client's home phone number */
  homePhoneNumber?: Maybe<Scalars['String']>;
  /** The client's work phone number */
  workPhoneNumber?: Maybe<Scalars['String']>;
  /** The client's national insurance number */
  nationalInsuranceNumber?: Maybe<Scalars['String']>;
  /** The client's home address */
  homeAddress?: Maybe<Address>;
  /** The client's previous home addresses */
  previousHomeAddresses?: Maybe<Array<Address>>;
  /** The client's work address */
  workAddress?: Maybe<Address>;
  /** A list of forms of identity proof */
  identityProof?: Maybe<Array<IdentityProof>>;
  /** A list of forms of address proof */
  addressProof?: Maybe<Array<AddressProof>>;
  /** The client's bank details used for direct debits */
  bankDetails?: Maybe<BankDetails>;
  /** Whether or not this profile can be considered complete */
  profileIsComplete: Scalars['Boolean'];
  /** The date and time when this client's profile was verified, if at all */
  verifiedAt?: Maybe<Scalars['DateTime']>;
  /** Whether or not this profile has been verified */
  isVerified: Scalars['Boolean'];
  /** The current status of this client */
  status: FirmClientStatus;
  /** All accounts that the client has, in any state */
  accounts: Array<ClientAccount>;
  /** All legal issues the client has started */
  issues: Array<Issue>;
  /** Whether or not this client has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
};

export enum FirmClientStatus {
  Invited = 'INVITED',
  InviteExpired = 'INVITE_EXPIRED',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

/** An existing member of a firm */
export type FirmMember = {
  __typename?: 'FirmMember';
  /** The unique ID for the firm member */
  id: Scalars['ID'];
  /** The role held by the member in the firm */
  role: FirmRole;
  /** The firm of which the professional is a member */
  firm: Firm;
  /** The professional which is a member of the firm */
  professional: FirmProfessional;
  /** An optional employee reference number */
  employeeNumber?: Maybe<Scalars['String']>;
  /** The firm member's formal job title */
  jobTitle?: Maybe<Scalars['String']>;
  /** The name or location of the office in which the firm member works */
  office?: Maybe<Scalars['String']>;
  /** The current status of this firm member */
  status: FirmMemberStatus;
  /** Whether or not this member is active */
  isActive: Scalars['Boolean'];
  /** The date/time when this member was last made active */
  activeSince?: Maybe<Scalars['DateTime']>;
  /** A list of the client accounts to which this firm member is assigned */
  assignedClientAccounts: Array<ClientAccount>;
  /** A list of the clients to which this firm member is assigned */
  assignedClients: Array<FirmClient>;
  /** Whether or not this firm member has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
};

/** The result of removing a firm member */
export type FirmMemberRemovedPayload = {
  __typename?: 'FirmMemberRemovedPayload';
  /** The unique ID of the firm member */
  firmMemberId: Scalars['ID'];
  /** The date and time when the firm member was removed */
  removedOn: Scalars['DateTime'];
};

export enum FirmMemberStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

/** A person trained in subsidiary legal matters but not fully qualified as a lawyer */
export type FirmParalegal = FirmProfessional & {
  __typename?: 'FirmParalegal';
  /** The professional's unique ID */
  id: Scalars['ID'];
  /** Which type of professional this is */
  type: FirmProfessionalType;
  /** The professional's unique reference number */
  referenceNumber: Scalars['String'];
  /** The professional's first and last name */
  name: Scalars['String'];
  /** The professional's preferred name (defaults to their first name) */
  preferredName: Scalars['String'];
  /** The professional's first name */
  firstName?: Maybe<Scalars['String']>;
  /** The professional's middle name(s) */
  middleNames: Scalars['String'];
  /** The professional's last name */
  lastName: Scalars['String'];
  /** The professional's full name */
  fullName: Scalars['String'];
  /** The professional's email address */
  emailAddress: Scalars['EmailAddress'];
  /** The professional's work phone number */
  workPhoneNumber?: Maybe<Scalars['String']>;
  /** The URL pointing to the professional's picture */
  pictureUrl: Scalars['String'];
  /** The current status of the professional */
  status: FirmProfessionalStatus;
  /** The paralegal's registration number */
  paralegalNumber: Scalars['String'];
};

/** A professional that may or may not be a member of one or more firms */
export type FirmProfessional = {
  /** The professional's unique ID */
  id: Scalars['ID'];
  /** Which type of professional this is */
  type: FirmProfessionalType;
  /** The professional's unique reference number */
  referenceNumber: Scalars['String'];
  /** The professional's first and last name */
  name: Scalars['String'];
  /** The professional's preferred name (defaults to their first name) */
  preferredName: Scalars['String'];
  /** The professional's first name */
  firstName?: Maybe<Scalars['String']>;
  /** The professional's middle name(s) */
  middleNames: Scalars['String'];
  /** The professional's last name */
  lastName: Scalars['String'];
  /** The professional's full name */
  fullName: Scalars['String'];
  /** The professional's email address */
  emailAddress: Scalars['EmailAddress'];
  /** The professional's work phone number */
  workPhoneNumber?: Maybe<Scalars['String']>;
  /** The URL pointing to the professional's picture */
  pictureUrl: Scalars['String'];
  /** The current status of the professional */
  status: FirmProfessionalStatus;
};

export enum FirmProfessionalStatus {
  Invited = 'INVITED',
  InviteExpired = 'INVITE_EXPIRED',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum FirmProfessionalType {
  Barrister = 'BARRISTER',
  Paralegal = 'PARALEGAL',
  Solicitor = 'SOLICITOR',
  Support = 'SUPPORT'
}

export enum FirmRole {
  Administrator = 'ADMINISTRATOR',
  Member = 'MEMBER'
}

/** A member of the legal profession qualified to deal with legal matters */
export type FirmSolicitor = FirmProfessional & {
  __typename?: 'FirmSolicitor';
  /** The professional's unique ID */
  id: Scalars['ID'];
  /** Which type of professional this is */
  type: FirmProfessionalType;
  /** The professional's unique reference number */
  referenceNumber: Scalars['String'];
  /** The professional's first and last name */
  name: Scalars['String'];
  /** The professional's preferred name (defaults to their first name) */
  preferredName: Scalars['String'];
  /** The professional's first name */
  firstName?: Maybe<Scalars['String']>;
  /** The professional's middle name(s) */
  middleNames: Scalars['String'];
  /** The professional's last name */
  lastName: Scalars['String'];
  /** The professional's full name */
  fullName: Scalars['String'];
  /** The professional's email address */
  emailAddress: Scalars['EmailAddress'];
  /** The professional's work phone number */
  workPhoneNumber?: Maybe<Scalars['String']>;
  /** The URL pointing to the professional's picture */
  pictureUrl: Scalars['String'];
  /** The current status of the professional */
  status: FirmProfessionalStatus;
  /** The solicitor's SRA number */
  sraNumber: Scalars['String'];
};

/** A non-legal professional that supports work carried out by legal practitioners */
export type FirmSupport = FirmProfessional & {
  __typename?: 'FirmSupport';
  /** The professional's unique ID */
  id: Scalars['ID'];
  /** Which type of professional this is */
  type: FirmProfessionalType;
  /** The professional's unique reference number */
  referenceNumber: Scalars['String'];
  /** The professional's first and last name */
  name: Scalars['String'];
  /** The professional's preferred name (defaults to their first name) */
  preferredName: Scalars['String'];
  /** The professional's first name */
  firstName?: Maybe<Scalars['String']>;
  /** The professional's middle name(s) */
  middleNames: Scalars['String'];
  /** The professional's last name */
  lastName: Scalars['String'];
  /** The professional's full name */
  fullName: Scalars['String'];
  /** The professional's email address */
  emailAddress: Scalars['EmailAddress'];
  /** The professional's work phone number */
  workPhoneNumber?: Maybe<Scalars['String']>;
  /** The URL pointing to the professional's picture */
  pictureUrl: Scalars['String'];
  /** The current status of the professional */
  status: FirmProfessionalStatus;
};

/** A task available for selection from the firm's own task library */
export type FirmTask = Task & TemplateTask & {
  __typename?: 'FirmTask';
  /** The unique ID of this task */
  id: Scalars['ID'];
  /** The name given to this task */
  name: Scalars['String'];
  /** A detailed description this task */
  description?: Maybe<Scalars['String']>;
  /** The key date by which this task is due to be completed */
  dueDate?: Maybe<KeyDate>;
  /** Official advice for completing this task */
  officialAdvice?: Maybe<Scalars['String']>;
  /** Additional advice for completing this task */
  additionalAdvice?: Maybe<Scalars['String']>;
  /** The sections this task is divided in to */
  sections: Array<TaskSection>;
  /** The number of sections in this task */
  sectionCount: Scalars['Int'];
  /** Whether or not the structure of this task can be modified when being used */
  isModifiable: Scalars['Boolean'];
  /** Whether or not this task has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
  /** The types of matter for which this task should be used. If empty, this is a generally available task. */
  matterTypes?: Maybe<Array<MatterType>>;
  /** Whether or not this template task has been published for use */
  isPublished: Scalars['Boolean'];
  /** The ID of the firm to which this task belongs */
  firmId: Scalars['ID'];
};

/** A topic that is owned by a firm */
export type FirmTopic = Topic & {
  __typename?: 'FirmTopic';
  /** The unique ID of the firm that owns this topic */
  firmId: Scalars['ID'];
  /** The unique ID of the topic */
  id: Scalars['ID'];
  /** The name given to the topic by its creator */
  name: Scalars['String'];
  /** A unique, URL-safe version of the topic name */
  slug: Scalars['String'];
  /** The colour name given to the topic by its creator */
  colour: Scalars['String'];
};

export enum FirmType {
  LimitedLiabilityPartnership = 'LIMITED_LIABILITY_PARTNERSHIP',
  LimitedCompany = 'LIMITED_COMPANY'
}

/** A single folder */
export type Folder = {
  __typename?: 'Folder';
  /** The unique ID for the folder */
  id: Scalars['ID'];
  /** The folder's owner */
  owner?: Maybe<FileOwner>;
  /** The folder's parent folder */
  parent?: Maybe<Folder>;
  /** The name given to the folder */
  name: Scalars['String'];
  /** Whether or not this is a restricted folder which cannot be renamed or removed */
  isRestricted: Scalars['Boolean'];
  /** The folders contained within this folder */
  subFolders: Array<Folder>;
  /** The number of files in this folder */
  fileCount?: Maybe<Scalars['Int']>;
  /** The files contained within this folder */
  files: Array<File>;
};

/** The result of removing a folder */
export type FolderRemovedPayload = {
  __typename?: 'FolderRemovedPayload';
  /** The unique ID of the removed folder */
  folderId: Scalars['ID'];
  /** The date and time when the folder was removed */
  removedOn: Scalars['DateTime'];
};

/** A topic that is system-owned */
export type GeneralTopic = Topic & {
  __typename?: 'GeneralTopic';
  /** The unique ID of the topic */
  id: Scalars['ID'];
  /** The name given to the topic by its creator */
  name: Scalars['String'];
  /** A unique, URL-safe version of the topic name */
  slug: Scalars['String'];
  /** The colour name given to the topic by its creator */
  colour: Scalars['String'];
};

/** The result of clearing a highlight */
export type HighlightClearedPayload = {
  __typename?: 'HighlightClearedPayload';
  /** The ID of the highlightable from which the highlight was cleared */
  highlightableId: Scalars['ID'];
  /** The date/time when the highlight was cleared */
  clearedOn: Scalars['DateTime'];
};

/** The current highlight counts for the current user */
export type HighlightCountsPayload = {
  __typename?: 'HighlightCountsPayload';
  /** The number of highlighted contacts */
  contactCount: Scalars['Int'];
  /** The number of highlighted conversations */
  conversationCount: Scalars['Int'];
  /** The number of highlighted messages */
  messageCount: Scalars['Int'];
  /** The number of highlighted firm members */
  firmMemberCount: Scalars['Int'];
  /** The number of highlighted notes */
  noteCount: Scalars['Int'];
  /** The number of highlighted clients registered with the current users' firm */
  firmClientCount: Scalars['Int'];
  /** The number of highlighted client assignments for the current user */
  clientAssignmentCount: Scalars['Int'];
  /** The number of highlighted client profiles visible to the current user */
  clientCount: Scalars['Int'];
  /** The number of highlighted matters */
  matterCount: Scalars['Int'];
  /** The number of highlighted key dates */
  keyDateCount: Scalars['Int'];
  /** The number of highlighted tasks */
  taskCount: Scalars['Int'];
  /** The number of highlighted task sections */
  taskSectionCount: Scalars['Int'];
  /** The number of highlighted questions */
  questionCount: Scalars['Int'];
  /** The number of highlighted answers */
  answerCount: Scalars['Int'];
};

/** A key/value based metadata filter for highlights */
export type HighlightFilter = {
  /** The metadata key by which to filter */
  key: Scalars['String'];
  /** The metadata value by which to filter */
  value: Scalars['String'];
};

/** The result of clearing multiple highlights */
export type HighlightsClearedPayload = {
  __typename?: 'HighlightsClearedPayload';
  /** The ID of the highlightable objects from which the highlights were cleared */
  highlightableIds: Array<Scalars['ID']>;
  /** The date/time when the highlight was cleared */
  clearedOn: Scalars['DateTime'];
};

export type IdentifyOpponentInput = {
  /** The unique ID of the issue for which the opponent is being identified */
  issueId: Scalars['ID'];
  /** Details about the opponent (if known) */
  opponent?: Maybe<OpponentInput>;
  /** Whether to send a connection request */
  sendRequest?: Maybe<Scalars['Boolean']>;
};

export type IdentifyOpponentSolicitorInput = {
  /** The unique ID of the issue for which the opponent's solicitor is being identified */
  issueId: Scalars['ID'];
  /** The solicitor's first name */
  firstName: Scalars['String'];
  /** The solicitor's last name */
  lastName: Scalars['String'];
  /** The solicitor's email address */
  emailAddress?: Maybe<Scalars['EmailAddress']>;
  /** The solicitor's firm name */
  firmName?: Maybe<Scalars['String']>;
};

export enum IdentityDocumentType {
  ConstructionId = 'CONSTRUCTION_ID',
  DriversLicence = 'DRIVERS_LICENCE',
  FirearmCertificate = 'FIREARM_CERTIFICATE',
  NameChange = 'NAME_CHANGE',
  NationalIdentity = 'NATIONAL_IDENTITY',
  Passport = 'PASSPORT'
}

/** Proof of identity held by a client */
export type IdentityProof = {
  __typename?: 'IdentityProof';
  /** An internal ID for this form of proof */
  id: Scalars['ID'];
  /** The official reference number of ID for this form of proof */
  type: IdentityDocumentType;
  /** The official reference number of ID for this form of proof */
  reference: Scalars['String'];
  /** The ID of the image that was used */
  assetFileId: Scalars['ID'];
  /** The version of the image/document that was used */
  assetFileVersion: Scalars['Int'];
  /** The MIME type of the image/document that was used */
  assetMime: Scalars['String'];
  /** The size, in bytes, of the image/document that was used */
  assetSize: Scalars['Int'];
  /** The height, in pixels, of the image/document that was used */
  assetHeight: Scalars['Int'];
  /** The width, in pixels, of the image/document that was used */
  assetWidth: Scalars['Int'];
  /** A URL to the provided image/document of the physical identity document */
  assetUrl: Scalars['String'];
  /** The country in which the document was issued (if applicable) */
  country?: Maybe<Scalars['String']>;
  /** The date when the document expires (if applicable) */
  expiresAt?: Maybe<Scalars['DateTime']>;
};

export type IdentityProofInput = {
  /** The official reference number of ID for this form of proof */
  type: IdentityDocumentType;
  /** The official reference number of ID for this form of proof */
  reference: Scalars['String'];
  /** The ID of the file to import from the file manager */
  fileId: Scalars['ID'];
  /** The version of the file to import from the file manager (optional) */
  fileVersion?: Maybe<Scalars['Int']>;
  /** The country in which the document was issued (if applicable) */
  country?: Maybe<Scalars['String']>;
  /** The date when the document expires (if applicable) */
  expiresAt?: Maybe<Scalars['DateTime']>;
};

/** A image attached to an answer */
export type ImageAnswerAttachment = AnswerAttachment & {
  __typename?: 'ImageAnswerAttachment';
  /** The unique ID of this attachment */
  id: Scalars['ID'];
  /** The unique ID of the file revision that was originally attached */
  fileRevisionId: Scalars['ID'];
  /** The original name of the asset used to revise the file */
  originalName: Scalars['String'];
  /** A simple file type, for example: document */
  type: Scalars['String'];
  /** The file's full MIME type (e.g. image/jpeg) */
  mime: Scalars['String'];
  /** The size of the file asset in bytes */
  size: Scalars['Int'];
  /** The height of the image in pixels */
  height?: Maybe<Scalars['Int']>;
  /** The width of the image in pixels */
  width?: Maybe<Scalars['Int']>;
  /** The orientation of the image, based on it's dimensions */
  orientation?: Maybe<Scalars['String']>;
};

/** A single uploaded image */
export type ImageFile = File & {
  __typename?: 'ImageFile';
  /** The unique ID for the file */
  id: Scalars['ID'];
  /** The folder's owner */
  owner?: Maybe<FileOwner>;
  /** The folder in which the file is placed */
  folder: Folder;
  /** The name given to the file */
  name: Scalars['String'];
  /** What type of file this is (e.g. Document, Image, etc.) */
  type: Scalars['String'];
  /** The file's full MIME type (e.g. image/jpeg) */
  mime: Scalars['String'];
  /** The size of the raw file, in bytes */
  size: Scalars['Int'];
  /** The files current version number */
  version: Scalars['Int'];
  /** The ID of the revision that represents the current version of this file */
  currentRevisionId: Scalars['ID'];
  /** The date and time when this file was last revised */
  lastRevisedOn: Scalars['DateTime'];
  /** The history of revisions made to this file */
  revisions: Array<FileRevision>;
  /** The topics associated with this file by its owner */
  topics: Array<Topic>;
  /** The height of the image in pixels */
  height?: Maybe<Scalars['Int']>;
  /** The width of the image in pixels */
  width?: Maybe<Scalars['Int']>;
  /** The orientation of the image, based on it's dimensions */
  orientation?: Maybe<Scalars['String']>;
};

/** A version of an image */
export type ImageFileRevision = FileRevision & {
  __typename?: 'ImageFileRevision';
  /** The unique ID of this file */
  id: Scalars['ID'];
  /** The revision number of this file */
  version: Scalars['Int'];
  /** The original name of the asset used to revise the file */
  originalName: Scalars['String'];
  /** A simple file type, for example: document */
  type: Scalars['String'];
  /** The file's full MIME type (e.g. image/jpeg) */
  mime: Scalars['String'];
  /** The size of the file asset in bytes */
  size: Scalars['Int'];
  /** The date and time when this file was revised */
  revisedOn: Scalars['DateTime'];
  /** The height of the image in pixels */
  height?: Maybe<Scalars['Int']>;
  /** The width of the image in pixels */
  width?: Maybe<Scalars['Int']>;
  /** The orientation of the image, based on it's dimensions */
  orientation?: Maybe<Scalars['String']>;
};

/** A representation of a legal issue */
export type Issue = {
  __typename?: 'Issue';
  /** The unique ID of this issue */
  id: Scalars['ID'];
  /** Which kind of issue this is */
  type: IssueType;
  /** A display name for the issue */
  name: Scalars['String'];
  /** The client for whom this is an issue */
  client: FirmClient;
  /** The client accounts that are associated with this issue */
  clientAccounts: Array<ClientAccount>;
  /** The legal professionals that make up the legal team for this issue */
  legalTeam: Array<LegalTeamMember>;
  /** The opponent in this issue */
  opponent?: Maybe<Opponent>;
  /** The opponent's counterpart issue */
  opponentIssue?: Maybe<Issue>;
  /** Whether or not a link to an opponent issue has been requested */
  linkRequested: Scalars['Boolean'];
  /** The code to use when linking this issue to the opponent's issue */
  linkCode?: Maybe<Scalars['String']>;
  /** Whether or not this issue is linked to the opponent's issue */
  isLinked: Scalars['Boolean'];
  /** The client's matters that relate to this issue */
  matters: Array<Matter>;
  /** The conversation between the two opponents */
  opponentConversation?: Maybe<Conversation>;
  /** The conversation between the legal teams of the two opponents */
  disclosureConversation?: Maybe<Conversation>;
};

export enum IssueType {
  Separation = 'SEPARATION'
}

/** An important date in a matter */
export type KeyDate = {
  __typename?: 'KeyDate';
  /** The unique ID for the key date */
  id: Scalars['ID'];
  /** The matter to which this key date belongs */
  matter: Matter;
  /** The short name given to the key date */
  name: Scalars['String'];
  /** Optional supporting descriptive information about the key date */
  description?: Maybe<Scalars['String']>;
  /** The date/time itself */
  date: Scalars['DateTime'];
  /** Whether this key date is all day, or at a specific time */
  isAllDay: Scalars['Boolean'];
  /** Whether or not this key date has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
  /** Any events that occur on this key date, e.g. Court Cases, Task Due dates, etc. */
  events: Array<MatterEvent>;
};

export type KeyDateList = {
  __typename?: 'KeyDateList';
  /** List of items on the current page */
  items?: Maybe<Array<Maybe<KeyDate>>>;
  /** Number of total items selected by the query */
  total: Scalars['Int'];
  /** Number of items returned per page */
  perPage: Scalars['Int'];
  /** Current page of the cursor */
  currentPage: Scalars['Int'];
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']>;
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']>;
  /** The last page (number of pages) */
  lastPage: Scalars['Int'];
  /** Determines if cursor has more pages after the current page */
  hasMorePages: Scalars['Boolean'];
};

/** The result of removing a key date */
export type KeyDateRemovedPayload = {
  __typename?: 'KeyDateRemovedPayload';
  /** The unique ID of the removed key date */
  keyDateId: Scalars['ID'];
  /** The date and time when the task was removed */
  removedOn: Scalars['DateTime'];
};

/** A key/value pair, with a string or array of strings as the value */
export type KeyValue = KeyValueArray | KeyValueString;

/** A key/value pair where the value is an array of strings */
export type KeyValueArray = {
  __typename?: 'KeyValueArray';
  key: Scalars['String'];
  value: Array<Maybe<Scalars['String']>>;
};

/** A simple key/value pair */
export type KeyValueString = {
  __typename?: 'KeyValueString';
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

/** A single member of a legal team for a matter */
export type LegalTeamMember = {
  __typename?: 'LegalTeamMember';
  /** The unique ID of this matter */
  id: Scalars['ID'];
  /** Details about the professional */
  professional: FirmProfessional;
  /** Details about the firm the professional is representing for this matter */
  firm: Firm;
  /** The job title of the professional in the firm they are representing */
  jobTitle?: Maybe<Scalars['String']>;
  /** Whether or not this team member is a lead professional for their client and firm */
  isLead: Scalars['Boolean'];
};

/** A task available for selection from the task library */
export type LibraryTask = Task & TemplateTask & {
  __typename?: 'LibraryTask';
  /** The unique ID of this task */
  id: Scalars['ID'];
  /** The name given to this task */
  name: Scalars['String'];
  /** A detailed description this task */
  description?: Maybe<Scalars['String']>;
  /** The key date by which this task is due to be completed */
  dueDate?: Maybe<KeyDate>;
  /** Official advice for completing this task */
  officialAdvice?: Maybe<Scalars['String']>;
  /** Additional advice for completing this task */
  additionalAdvice?: Maybe<Scalars['String']>;
  /** The sections this task is divided in to */
  sections: Array<TaskSection>;
  /** The number of sections in this task */
  sectionCount: Scalars['Int'];
  /** Whether or not the structure of this task can be modified when being used */
  isModifiable: Scalars['Boolean'];
  /** Whether or not this task has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
  /** The types of matter for which this task should be used. If empty, this is a generally available task. */
  matterTypes?: Maybe<Array<MatterType>>;
  /** Whether or not this template task has been published for use */
  isPublished: Scalars['Boolean'];
};

export type LinkIssueInput = {
  /** The issue link code supplied by the opponent. */
  linkCode: Scalars['String'];
  /** The unique ID of the client forming the issue link. */
  clientId: Scalars['ID'];
  /** The unique ID of the existing issue to link. If not set, a new issue will be started. */
  issueId?: Maybe<Scalars['ID']>;
};

/** An answer to a part of a question that requires a list of items in response */
export type ListAnswer = Answer & {
  __typename?: 'ListAnswer';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The part of a question for which this is an answer */
  questionPart: QuestionPart;
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  proposedOn: Scalars['DateTime'];
  /** Whether or not this answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The revision history of this answer */
  history: Array<AnswerRevision>;
  /** The current status of this answer */
  status: AnswerStatus;
  /** Whether or not this answer has been accepted by a professional */
  isAcceptedByProfessional: Scalars['Boolean'];
  /** Whether or not this answer has been accepted by a client */
  isAcceptedByClient: Scalars['Boolean'];
  /** Whether or not this question part has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
  /** The items listed for the answer */
  currentValue?: Maybe<Array<ListItem>>;
};

/** A draft answer to a part of a question that requires a list of items in response */
export type ListAnswerDraft = AnswerDraft & {
  __typename?: 'ListAnswerDraft';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The part of a question for which this is an answer */
  questionPart: QuestionPart;
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  startedOn: Scalars['DateTime'];
  /** Whether or not this draft of the answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The items listed for the answer */
  currentValue?: Maybe<Array<ListItem>>;
};

/** A revision to an answer to a part of a question that requires a list of items in response */
export type ListAnswerRevision = AnswerRevision & {
  __typename?: 'ListAnswerRevision';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  proposedOn: Scalars['DateTime'];
  /** Whether or not this revision of the answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The items listed for the answer */
  value?: Maybe<Array<ListItem>>;
};

/** A single attribute of a list part */
export type ListAttribute = {
  /** The unique ID of this attribute */
  id: Scalars['String'];
  /** Which type of attribute this is */
  type: QuestionPartType;
  /** The label to be shown for this attribute */
  label: Scalars['String'];
  /** Additional instructions and information to support the user */
  instructions?: Maybe<Scalars['String']>;
  /** Whether or not a value for this attribute is required */
  isRequired: Scalars['Boolean'];
  /** The ordering index for use when showing this attribute alongside other attributes of the same part */
  ordering: Scalars['Int'];
};

/** A single item added in response to a list question part */
export type ListItem = {
  __typename?: 'ListItem';
  /** The place in which this item belongs in the list */
  ordering: Scalars['Int'];
  /** The place in which this item belongs in the list */
  properties: Array<ListItemProperty>;
};

export type ListItemInput = {
  /** The properties of this list item */
  properties?: Maybe<Array<ListItemPropertyInput>>;
};

/** A property of a single item added in response to a list question part */
export type ListItemProperty = {
  /** The unique ID of the attribute for which this property exists */
  attributeId: Scalars['ID'];
};

export type ListItemPropertyInput = {
  /** The unique ID of the list item attribute */
  attributeId: Scalars['ID'];
  /** Address fields for an address answer */
  address?: Maybe<AddressInput>;
  /** The date/time selected for a date answer */
  date?: Maybe<Scalars['DateTime']>;
  /** An email address for an email answer */
  email?: Maybe<Scalars['EmailAddress']>;
  /** The file(s) chosen for a file answer */
  file?: Maybe<Array<Scalars['ID']>>;
  /** An amount of money for a money answer */
  money?: Maybe<Scalars['Money']>;
  /** A whole number for a number answer */
  number?: Maybe<Scalars['Int']>;
  /** A percentage for a percent answer */
  percent?: Maybe<Scalars['Float']>;
  /** A phone number for a phone answer */
  phone?: Maybe<Scalars['String']>;
  /** A short string for a string answer */
  string?: Maybe<Scalars['String']>;
  /** A long string for a text answer */
  text?: Maybe<Scalars['String']>;
};

/** A part of a question that requires a list of items in response */
export type ListPart = QuestionPart & {
  __typename?: 'ListPart';
  /** The unique ID of this question part */
  id: Scalars['String'];
  /** Which type of part this is */
  type: QuestionPartType;
  /** The label to be shown for this part */
  label: Scalars['String'];
  /** Additional instructions and information to support the user */
  instructions?: Maybe<Scalars['String']>;
  /** Official advice for completing this question part */
  officialAdvice?: Maybe<Scalars['String']>;
  /** Additional advice for completing this question part */
  additionalAdvice?: Maybe<Scalars['String']>;
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** Whether or not this question part has been completed */
  isComplete: Scalars['Boolean'];
  /** The ordering index for use when showing this part alongside other parts of the same question */
  ordering: Scalars['Int'];
  /** The current draft answer for this question part */
  answerDraft?: Maybe<AnswerDraft>;
  /** The answer given to this question part */
  answer?: Maybe<Answer>;
  /** Whether or not this question part has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
  /** The list of item attributes */
  attributes: Array<ListAttribute>;
  /** The minimum number of items permitted */
  minimumItems: Scalars['Int'];
  /** The maximum number of items permitted */
  maximumItems?: Maybe<Scalars['Int']>;
  /** The configuration for this part */
  configuration: ListPartConfiguration;
};

/** The configuration for a part of a question that requires a list of items in response */
export type ListPartConfiguration = {
  __typename?: 'ListPartConfiguration';
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** The list of item attributes */
  attributes: Array<ListAttribute>;
  /** The minimum number of items permitted */
  minimumItems: Scalars['Int'];
  /** The maximum number of items permitted */
  maximumItems?: Maybe<Scalars['Int']>;
};

export type LoginWithPasswordInput = {
  /** The unique ID of the client being used by the user */
  clientId: Scalars['ID'];
  /** The identifier for the resource for which access is required */
  audience: Scalars['String'];
  /** The capabilities to which access is required */
  scope: Scalars['String'];
  /** The user's username (usually their email address) */
  username: Scalars['String'];
  /** The user's password */
  password: Scalars['String'];
};

export type MakeConversationExtractInput = {
  /** A reference name to give the conversation extract */
  name?: Maybe<Scalars['String']>;
  /** The filters used to generate the conversation extract */
  filters: QueryMessagesInput;
  /** The unique ID of the matter to which the extract will be associated */
  matterId?: Maybe<Scalars['ID']>;
};

export type MarkAllMessagesReadInput = {
  conversationId: Scalars['ID'];
};

export type MarkMessageDeliveredInput = {
  conversationId: Scalars['ID'];
  messageId: Scalars['ID'];
};

export type MarkMessageReadInput = {
  conversationId: Scalars['ID'];
  messageId: Scalars['ID'];
};

export type MarkNoteViewedInput = {
  /** The unique ID of the note */
  noteId: Scalars['ID'];
};

export type MarkNotificationReadInput = {
  notificationId?: Maybe<Scalars['ID']>;
};

/** A client account that matches a search term */
export type MatchedClientAccount = {
  __typename?: 'MatchedClientAccount';
  /** The client account's unique ID */
  id: Scalars['ID'];
  /** The name of the client */
  clientName: Scalars['String'];
  /** A URl pointing to the client's picture */
  clientPictureUrl: Scalars['String'];
  /** The status of the client's account */
  status: Scalars['String'];
  /** The hierarchical path to the matched file */
  path: Array<PathSegment>;
};

/** A contact that matches a search term */
export type MatchedContact = {
  __typename?: 'MatchedContact';
  /** The contact's unique ID */
  id: Scalars['ID'];
  /** The contact name */
  name: Scalars['String'];
  /** The contact picture URL */
  pictureUrl: Scalars['String'];
  /** The type of contact */
  type: Scalars['String'];
  /** Whether or not this contact is a transparently user */
  isTransparentlyUser: Scalars['Boolean'];
  /** The ID of the user associated with this contact */
  userId?: Maybe<Scalars['ID']>;
  /** Whether or not this contact is associated with a firm */
  isAssociatedWithFirm: Scalars['Boolean'];
  /** The name of the firm associated with this contact, if at all */
  firmName?: Maybe<Scalars['String']>;
  /** Whether or not this contact is associated with an issue */
  isAssociatedWithIssue: Scalars['Boolean'];
  /** The name of the issue associated with this contact, if at all */
  issueName?: Maybe<Scalars['String']>;
  /** The date and time when this contact was added */
  addedOn: Scalars['DateTime'];
  /** The hierarchical path to the matched contact */
  path: Array<PathSegment>;
};

/** A file that matches a search term */
export type MatchedFile = {
  __typename?: 'MatchedFile';
  /** The file's unique ID */
  id: Scalars['ID'];
  /** The unique ID of the folder in which the file exists */
  folderId: Scalars['ID'];
  /** The name given to the file */
  name: Scalars['String'];
  /** What type of file this is (e.g. Document, Image, etc.) */
  type: Scalars['String'];
  /** The file's full MIME type (e.g. image/jpeg) */
  mime: Scalars['String'];
  /** The size of the raw file, in bytes */
  size: Scalars['Int'];
  /** The date and time when this file was first stored */
  storedOn: Scalars['DateTime'];
  /** The date and time when this file was last revised */
  lastRevisedOn: Scalars['DateTime'];
  /** The hierarchical path to the matched file */
  path: Array<PathSegment>;
};

/** A folder that matches a search term */
export type MatchedFolder = {
  __typename?: 'MatchedFolder';
  /** The folder's unique ID */
  id: Scalars['ID'];
  /** The name given to the folder */
  name: Scalars['String'];
  /** The date and time when this file was first added */
  addedOn: Scalars['DateTime'];
  /** The hierarchical path to the matched folder */
  path: Array<PathSegment>;
};

/** A message that matches a search term */
export type MatchedMessage = {
  __typename?: 'MatchedMessage';
  /** The message's unique ID */
  id: Scalars['ID'];
  /** The unique ID of the conversation in which the message was sent */
  conversationId: Scalars['ID'];
  /** This position of this message in the conversation. Starts at 1. */
  messageNumber: Scalars['Int'];
  /** The name of the message sender */
  senderName: Scalars['String'];
  /** The title given to the message */
  title: Scalars['String'];
  /** The text body of the message. This will be null if the message has not been read yet. */
  body: Scalars['String'];
  /** The date and time when this message was sent */
  sentAt: Scalars['DateTime'];
  /** The date and time when this message was read, if at all */
  readAt?: Maybe<Scalars['DateTime']>;
  /** Whether or not this message has been read */
  isRead: Scalars['Boolean'];
  /** The number of message attachments */
  attachmentCount: Scalars['Int'];
  /** The hierarchical path to the matched file */
  path: Array<PathSegment>;
};

/** A note that matches a search term */
export type MatchedNote = {
  __typename?: 'MatchedNote';
  /** The note's unique ID */
  id: Scalars['ID'];
  /** The category into which the note has been placed */
  category: Scalars['String'];
  /** The date and time associated with the body of the note */
  dateTime?: Maybe<Scalars['DateTime']>;
  /** The note's current version number */
  versionNumber: Scalars['Int'];
  /** The unique ID of the author who drafted this note */
  authorId: Scalars['ID'];
  /** The name of the author who drafted this note */
  authorName: Scalars['String'];
  /** The URL of the author's picture */
  authorPictureUrl: Scalars['String'];
  /** The title given to the note */
  title: Scalars['String'];
  /** The main body of the note */
  body: Scalars['String'];
  /** The unique ID of the client to which this note relates */
  clientId?: Maybe<Scalars['ID']>;
  /** The name of the client to which this note relates */
  clientName?: Maybe<Scalars['String']>;
  /** The URL of the client's picture */
  clientPictureUrl?: Maybe<Scalars['String']>;
  /** The hierarchical path to the matched note */
  path: Array<PathSegment>;
};

/** A task that matches a search term */
export type MatchedTask = {
  __typename?: 'MatchedTask';
  /** The task's unique ID */
  id: Scalars['ID'];
  /** The unique ID of the matter to which this task relates */
  matterId: Scalars['ID'];
  /** The unique ID of the client account to which this task relates */
  clientAccountId?: Maybe<Scalars['ID']>;
  /** The unique ID of the client to which this task relates */
  clientId: Scalars['ID'];
  /** The name of the client to which this task relates */
  clientName: Scalars['String'];
  /** The name given to the task */
  name: Scalars['String'];
  /** How much of this task has been completed, as a decimal percentage */
  proportionComplete: Scalars['Float'];
  /** The date and time by which this task is due to be completed */
  dueDate?: Maybe<Scalars['DateTime']>;
  /** The hierarchical path to the matched task */
  path: Array<PathSegment>;
};

/** A representation of a legal matter */
export type Matter = {
  __typename?: 'Matter';
  /** The unique ID of this matter */
  id: Scalars['ID'];
  /** Which kind of matter this is */
  type: MatterType;
  /** Which issue this matter is part of */
  issue: Issue;
  /** The current status of this matter */
  status: MatterStatus;
  /** The client for whom this is a matter */
  client: FirmClient;
  /** The client account in place for dealing with this matter */
  clientAccount?: Maybe<ClientAccount>;
  /** The solicitor in charge of dealing with this matter */
  leadProfessional?: Maybe<FirmProfessional>;
  /** The legal professionals that make up the legal team for this matter */
  legalTeam: Array<LegalTeamMember>;
  /** The date and time when this matter was started */
  startedAt: Scalars['DateTime'];
  /** The date and time when this matter was ended */
  endedAt?: Maybe<Scalars['DateTime']>;
  /** The court cases associated with this matter */
  courtCases: Array<CourtCase>;
  /** The key dates associated with this matter */
  keyDates: Array<KeyDate>;
  /** The tasks associated with this matter */
  tasks: Array<Task>;
  /** A list of advisory actions to take before ending this matter */
  advisories?: Maybe<Array<Scalars['String']>>;
  /** The number of advisory actions to take before ending this matter */
  advisoryCount: Scalars['Int'];
  /** Whether or not this matter has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
  /** Whether or not this matter can be removed */
  isRemovable: Scalars['Boolean'];
};

/** Anything with a date that relates to a matter */
export type MatterEvent = CourtCase | MatterTask | TaskSection;

export type MatterList = {
  __typename?: 'MatterList';
  /** List of items on the current page */
  items?: Maybe<Array<Maybe<Matter>>>;
  /** Number of total items selected by the query */
  total: Scalars['Int'];
  /** Number of items returned per page */
  perPage: Scalars['Int'];
  /** Current page of the cursor */
  currentPage: Scalars['Int'];
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']>;
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']>;
  /** The last page (number of pages) */
  lastPage: Scalars['Int'];
  /** Determines if cursor has more pages after the current page */
  hasMorePages: Scalars['Boolean'];
};

/** The result of removing an empty matter */
export type MatterRemovedPayload = {
  __typename?: 'MatterRemovedPayload';
  /** The unique ID of the removed matter */
  matterId: Scalars['ID'];
  /** The date and time when the matter was removed */
  removedOn: Scalars['DateTime'];
};

export enum MatterStatus {
  Started = 'STARTED',
  Frozen = 'FROZEN',
  Ended = 'ENDED'
}

/** A summary of all features for the current individual, relating to a specific matter */
export type MatterSummary = {
  __typename?: 'MatterSummary';
  /** The number of answers that require attention */
  pendingAnswersCount?: Maybe<Scalars['Int']>;
  /** The number of messages that are unread */
  unreadMessagesCount?: Maybe<Scalars['Int']>;
  /** The number of notes that are unread */
  unreadNotesCount?: Maybe<Scalars['Int']>;
  /** The number of key dates that occur in the next 7 days */
  upcomingKeyDatesCount?: Maybe<Scalars['Int']>;
};

/** A task associated with a matter */
export type MatterTask = Task & {
  __typename?: 'MatterTask';
  /** The unique ID of this task */
  id: Scalars['ID'];
  /** The name given to this task */
  name: Scalars['String'];
  /** A detailed description this task */
  description?: Maybe<Scalars['String']>;
  /** The key date by which this task is due to be completed */
  dueDate?: Maybe<KeyDate>;
  /** Official advice for completing this task */
  officialAdvice?: Maybe<Scalars['String']>;
  /** Additional advice for completing this task */
  additionalAdvice?: Maybe<Scalars['String']>;
  /** The sections this task is divided in to */
  sections: Array<TaskSection>;
  /** The number of sections in this task */
  sectionCount: Scalars['Int'];
  /** Whether or not the structure of this task can be modified when being used */
  isModifiable: Scalars['Boolean'];
  /** Whether or not this task has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
  /** The ID of the matter for which this is a task */
  matter: Matter;
  /** How much of this task has been released to the client, as a decimal percentage */
  proportionReleased: Scalars['Float'];
  /** Whether or not this task has been completed */
  isComplete: Scalars['Boolean'];
  /** How much of this task has been completed, as a decimal percentage */
  proportionComplete: Scalars['Float'];
  /** The current status of this task */
  status: TaskStatus;
};

export enum MatterType {
  Divorce = 'DIVORCE',
  Finance = 'FINANCE',
  Child = 'CHILD'
}

/** A single message */
export type Message = {
  __typename?: 'Message';
  /** The unique ID of this message */
  id: Scalars['ID'];
  /** The ID of the conversation in which this message was sent */
  conversationId: Scalars['ID'];
  /** The sender of this message */
  sender: ConversationParticipant;
  /** The message to which this is a reply, if at all */
  repliedTo?: Maybe<Message>;
  /** The main message body */
  body: Scalars['String'];
  /** The attachments that accompany the message body */
  attachments: Array<Attachment>;
  /** The date and time when this message was sent */
  sentAt: Scalars['DateTime'];
  /** The date and time when this message was first delivered to the current user */
  deliveredAt?: Maybe<Scalars['DateTime']>;
  /** The date and time when this message was first read by the current user */
  readAt?: Maybe<Scalars['DateTime']>;
  /** The delivery status of this message to each group or non-grouped member */
  deliveries?: Maybe<Array<Maybe<MessageDelivery>>>;
  /** The topics assigned to the message by the current user */
  topics: Array<Topic>;
  /** The copies of the message each belonging to a current participant/observer */
  copies?: Maybe<Array<Maybe<MessageCopy>>>;
};

/** A participant's copy of a message */
export type MessageCopy = {
  __typename?: 'MessageCopy';
  /** The unique ID of this message copy */
  id: Scalars['ID'];
  /** The individual to whom the copy belongs */
  recipient: ConversationMember;
  /** The date and time when the message was delivered, if at all */
  deliveredAt?: Maybe<Scalars['DateTime']>;
  /** The date and time when the message was read, if at all */
  readAt?: Maybe<Scalars['DateTime']>;
  /** The topics assigned to the message by the recipient of this copy */
  topics: Array<Topic>;
};

/** A message delivery status for a group or non-grouped member */
export type MessageDelivery = {
  __typename?: 'MessageDelivery';
  /** The date and time when this message was first delivered */
  deliveredAt?: Maybe<Scalars['DateTime']>;
  /** The date and time when this message was first read */
  readAt?: Maybe<Scalars['DateTime']>;
  /** A group in this conversation */
  group?: Maybe<ConversationGroup>;
  /** The member of the conversation that took delivery of the message. If a group is specified but the message has not been read by a group member then this will be null. */
  recipient?: Maybe<ConversationMember>;
};

/** A message draft belonging to a participant in a conversation */
export type MessageDraft = {
  __typename?: 'MessageDraft';
  /** The unique ID of this message draft */
  id: Scalars['ID'];
  /** The ID of the conversation in which this message was sent */
  conversationId: Scalars['ID'];
  /** The main message body */
  body?: Maybe<Scalars['String']>;
  /** IDs of any file revisions to be attached to the message */
  fileRevisionIds?: Maybe<Array<Scalars['ID']>>;
  /** IDs of any topics to be attached to the message */
  topicIds?: Maybe<Array<Scalars['ID']>>;
  /** The date and time when this message draft was last edited */
  updatedAt: Scalars['DateTime'];
};

/** A set of filters used to extract messages */
export type MessageFilters = {
  __typename?: 'MessageFilters';
  /** The UUID of the conversation from which the messages were extracted */
  conversationId: Scalars['ID'];
  /** The UUID of each sender (participant) that sent messages */
  senderIds?: Maybe<Array<Scalars['ID']>>;
  /** The date/time before which messages were sent */
  sentBefore?: Maybe<Scalars['DateTime']>;
  /** The date/time after which messages were sent */
  sentAfter?: Maybe<Scalars['DateTime']>;
  /** Whether or not the messages have attachments */
  hasAttachments?: Maybe<Scalars['Boolean']>;
  /** The ID of the viewer used to check isUnread and isVisible */
  viewerId: Scalars['ID'];
  /** Whether or not the messages are unread by the viewer */
  isUnread?: Maybe<Scalars['Boolean']>;
  /** Whether or not the messages are visible by the viewer */
  isVisible?: Maybe<Scalars['Boolean']>;
};

export type MessageList = {
  __typename?: 'MessageList';
  /** List of items on the current page */
  items?: Maybe<Array<Maybe<Message>>>;
  /** Number of total items selected by the query */
  total: Scalars['Int'];
  /** Number of items returned per page */
  perPage: Scalars['Int'];
  /** Current page of the cursor */
  currentPage: Scalars['Int'];
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']>;
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']>;
  /** The last page (number of pages) */
  lastPage: Scalars['Int'];
  /** Determines if cursor has more pages after the current page */
  hasMorePages: Scalars['Boolean'];
};

export type ModifyKeyDateInput = {
  /** The unique ID of the key date */
  keyDateId: Scalars['ID'];
  /** The new short name for the key date */
  name?: Maybe<Scalars['String']>;
  /** The new description of the key date */
  description?: Maybe<Scalars['String']>;
  /** The new date of the key date */
  date?: Maybe<Scalars['DateTime']>;
  /** Whether or not this is an all-day key date */
  isAllDay?: Maybe<Scalars['Boolean']>;
};

export type ModifyQuestionPartInput = {
  /** The unique ID of the question in which the part belongs */
  questionId: Scalars['ID'];
  /** The unique ID of the part in the question */
  partId: Scalars['ID'];
  /** The new label to be used when referring to the part */
  label?: Maybe<Scalars['String']>;
  /** The new instructions to supply with the part */
  instructions?: Maybe<Scalars['String']>;
  /** Type-specific configuration values for this part */
  configuration?: Maybe<ConfigureQuestionPartInput>;
};


/** An answer to a part of a question that requires a money response */
export type MoneyAnswer = Answer & {
  __typename?: 'MoneyAnswer';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The part of a question for which this is an answer */
  questionPart: QuestionPart;
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  proposedOn: Scalars['DateTime'];
  /** Whether or not this answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The revision history of this answer */
  history: Array<AnswerRevision>;
  /** The current status of this answer */
  status: AnswerStatus;
  /** Whether or not this answer has been accepted by a professional */
  isAcceptedByProfessional: Scalars['Boolean'];
  /** Whether or not this answer has been accepted by a client */
  isAcceptedByClient: Scalars['Boolean'];
  /** Whether or not this question part has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
  /** The amount of money given for the answer */
  currentValue?: Maybe<Scalars['Money']>;
};

/** A draft answer to a part of a question that requires a money response */
export type MoneyAnswerDraft = AnswerDraft & {
  __typename?: 'MoneyAnswerDraft';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The part of a question for which this is an answer */
  questionPart: QuestionPart;
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  startedOn: Scalars['DateTime'];
  /** Whether or not this draft of the answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The amount of money given for the answer */
  currentValue?: Maybe<Scalars['Money']>;
};

/** A revision to an answer to a part of a question that requires a money response */
export type MoneyAnswerRevision = AnswerRevision & {
  __typename?: 'MoneyAnswerRevision';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  proposedOn: Scalars['DateTime'];
  /** Whether or not this revision of the answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The amount of money given for the answer */
  value?: Maybe<Scalars['Money']>;
};

/** An attribute of a list part that uses a monetary value */
export type MoneyAttribute = ListAttribute & {
  __typename?: 'MoneyAttribute';
  /** The unique ID of this attribute */
  id: Scalars['String'];
  /** Which type of attribute this is */
  type: QuestionPartType;
  /** The label to be shown for this attribute */
  label: Scalars['String'];
  /** Additional instructions and information to support the user */
  instructions?: Maybe<Scalars['String']>;
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** The ordering index for use when showing this attribute alongside other attributes of the same part */
  ordering: Scalars['Int'];
  /** The currency in which the amount should be specified. "ANY" means any currency is allowed. */
  currency: Scalars['String'];
  /** The minimum amount allowed in the response */
  minimumAmount?: Maybe<Scalars['Float']>;
  /** The maximum amount allowed in the response */
  maximumAmount?: Maybe<Scalars['Float']>;
  /** The configuration for this part */
  configuration: MoneyPartConfiguration;
};

/** A list property that makes use of a monetary value */
export type MoneyListItemProperty = ListItemProperty & {
  __typename?: 'MoneyListItemProperty';
  /** The unique ID of the attribute for which this property exists */
  attributeId: Scalars['ID'];
  /** The monetary amount given */
  value?: Maybe<Scalars['Money']>;
};

/** A part of a question that requires a monetary response */
export type MoneyPart = QuestionPart & {
  __typename?: 'MoneyPart';
  /** The unique ID of this question part */
  id: Scalars['String'];
  /** Which type of part this is */
  type: QuestionPartType;
  /** The label to be shown for this part */
  label: Scalars['String'];
  /** Additional instructions and information to support the user */
  instructions?: Maybe<Scalars['String']>;
  /** Official advice for completing this question part */
  officialAdvice?: Maybe<Scalars['String']>;
  /** Additional advice for completing this question part */
  additionalAdvice?: Maybe<Scalars['String']>;
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** Whether or not this question part has been completed */
  isComplete: Scalars['Boolean'];
  /** The ordering index for use when showing this part alongside other parts of the same question */
  ordering: Scalars['Int'];
  /** The current draft answer for this question part */
  answerDraft?: Maybe<AnswerDraft>;
  /** The answer given to this question part */
  answer?: Maybe<Answer>;
  /** Whether or not this question part has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
  /** The currency in which the amount should be specified. "ANY" means any currency is allowed. */
  currency: Scalars['String'];
  /** The minimum amount allowed in the response */
  minimumAmount?: Maybe<Scalars['Float']>;
  /** The maximum amount allowed in the response */
  maximumAmount?: Maybe<Scalars['Float']>;
  /** The configuration for this part */
  configuration: MoneyPartConfiguration;
};

/** The configuration for a part of a question that requires a monetary response */
export type MoneyPartConfiguration = {
  __typename?: 'MoneyPartConfiguration';
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** The currency in which the amount should be specified. "ANY" means any currency is allowed. */
  currency: Scalars['String'];
  /** The minimum amount allowed in the response */
  minimumAmount?: Maybe<Scalars['Float']>;
  /** The maximum amount allowed in the response */
  maximumAmount?: Maybe<Scalars['Float']>;
};

export type MoveFileInput = {
  /** The unique ID of the file to be moved. */
  fileId: Scalars['ID'];
  /** The unique ID of the folder into which the file will be moved. */
  folderId: Scalars['ID'];
};

export type MoveFolderInput = {
  /** The unique ID of the folder to be moved. */
  folderId: Scalars['ID'];
  /** The unique ID of the folder into which the folder will be moved. */
  parentFolderId: Scalars['ID'];
};

export type MoveQuestionInput = {
  /** The unique ID of the question being moved */
  questionId: Scalars['ID'];
  /** The unique ID of the section to which to move the question */
  sectionId: Scalars['ID'];
  /** The new 1-indexed ordering to give the question in it's new section */
  ordering: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Accept an answer to a question */
  acceptAnswer?: Maybe<Answer>;
  /** Add a contact to the current user's contact list */
  addContact?: Maybe<Contact>;
  /** Add a file to the file manager */
  addFile?: Maybe<File>;
  /** Add multiple files to the same folder in the file manager */
  addFiles?: Maybe<Array<Maybe<File>>>;
  /** Add a professional to a firm as a member */
  addFirmMember?: Maybe<FirmMember>;
  /** Add a new folder */
  addFolder?: Maybe<Folder>;
  /** Add a key date to a matter */
  addKeyDate?: Maybe<KeyDate>;
  /** Add another part to an existing question */
  addQuestionPart?: Maybe<Question>;
  /** Add a file to the matter's supporting material */
  addSupportingFile?: Maybe<SupportingFile>;
  /** Answer a single part of a question */
  answerQuestionPart?: Maybe<Answer>;
  /** Approve a client account */
  approveClientAccount?: Maybe<ClientAccount>;
  /** Assign a firm member to a client account */
  assignFirmMemberToClient?: Maybe<ClientAssignment>;
  /** Associate a file with a topic for its owner */
  associateFileWithTopic?: Maybe<File>;
  /** Associate a message with a topic for a conversation participant */
  associateMessageWithTopic?: Maybe<MessageCopy>;
  /** Associate a note with a client account */
  associateNote?: Maybe<Note>;
  /** Lock or unlock an accepted answer to prevent/allow further revisions */
  changeAnswerLock?: Maybe<Answer>;
  /** Change whether or not a client account is active */
  changeClientAccountActivity?: Maybe<ClientAccount>;
  /** Make a firm member active or inactive */
  changeFirmMemberStatus?: Maybe<FirmMember>;
  /** Change the lead professional on a matter */
  changeMatterLead?: Maybe<Matter>;
  /** Change the advice given for a task */
  changeTaskAdvice?: Maybe<Task>;
  /** Change which matter types are associated with a task that's not related to a matter already */
  changeTaskMatterTypes?: Maybe<TemplateTask>;
  /** Change the publish status of a task not associated with a matter */
  changeTaskPublishStatus?: Maybe<TemplateTask>;
  /** Change the advice given for a task */
  changeTaskSectionAdvice?: Maybe<Task>;
  /** Change an existing transparently user's password */
  changeUserPassword?: Maybe<User>;
  /** Clear an answer draft, allowing answer edits to be carried out by other users */
  clearAnswerDraft?: Maybe<Question>;
  /** Clear a highlight from a highlightable object */
  clearHighlight: HighlightClearedPayload;
  /** Clear a highlight from a set of highlightable objects */
  clearHighlights: HighlightsClearedPayload;
  /** Clear a due date on a task */
  clearTaskDueDate?: Maybe<MatterTask>;
  /** Clear a due date on a task section */
  clearTaskSectionDueDate?: Maybe<Task>;
  /** Close a court case for a matter */
  closeCourtCase?: Maybe<CourtCase>;
  /** Make a privately-owned topic */
  createPrivateTopic?: Maybe<PrivateTopic>;
  /** Define a new section of a task, with or without a custom name */
  defineTaskSection?: Maybe<Task>;
  /** Describe an existing task */
  describeTask?: Maybe<Task>;
  /** Describe an existing section of a task */
  describeTaskSection?: Maybe<Task>;
  /** Disassociate a file from a topic for its owner */
  disassociateFileFromTopic?: Maybe<File>;
  /** Disassociate a message from a topic for a conversation participant */
  disassociateMessageFromTopic?: Maybe<MessageCopy>;
  /** Discard an existing conversation extract */
  discardConversationExtract?: Maybe<ConversationExtractDiscardedPayload>;
  /** Discard a personal note */
  discardNote?: Maybe<NoteDiscardedPayload>;
  /** Add another part to an existing question */
  draftAnswerQuestionPart?: Maybe<AnswerDraft>;
  /** Draft a new note */
  draftNote?: Maybe<Note>;
  /** Edit the draft message held for a participant in a conversation */
  editMessageDraft?: Maybe<MessageDraft>;
  /** Edit the main text or description of a question */
  editQuestion?: Maybe<Question>;
  /** End a new matter */
  endMatter?: Maybe<Matter>;
  /** Export an archive of supporting material from a matter */
  exportSupportingMaterial: SupportingMaterialExportStartedPayload;
  /** Identify the opponent for an issue */
  identifyOpponent?: Maybe<Issue>;
  /** Identify the opponent's solicitor for an issue */
  identifyOpponentSolicitor?: Maybe<Issue>;
  /** Link an issue to a counterpart issue, using a link code */
  linkIssue?: Maybe<Issue>;
  /** Login to a client with a username and password */
  loginWithPassword?: Maybe<AuthenticationPayload>;
  /** Make a conversation extract using a set of filters */
  makeConversationExtract?: Maybe<ConversationExtract>;
  /** Mark all messages in a conversation as having been read by a participant */
  markAllMessagesRead?: Maybe<Conversation>;
  /** Mark all notifications as read */
  markAllNotificationsRead: NotificationsReadPayload;
  /** Mark a message as having been delivered to a participant */
  markMessageDelivered?: Maybe<Message>;
  /** Mark a message as having been read by a participant */
  markMessageRead?: Maybe<Message>;
  /** Mark a note as having been viewed by an individual */
  markNoteViewed?: Maybe<Note>;
  /** Mark a notification as read */
  markNotificationRead?: Maybe<Notification>;
  /** Modify an existing key date */
  modifyKeyDate?: Maybe<KeyDate>;
  /** Modify an existing part of a question */
  modifyQuestionPart?: Maybe<QuestionPart>;
  /** Move a file to another folder in the file manager */
  moveFile?: Maybe<File>;
  /** Move a folder to another parent folder in the file manager */
  moveFolder?: Maybe<Folder>;
  /** Move a question to another section of the same task */
  moveQuestion?: Maybe<Question>;
  /** Pause interaction with a conversation for a participant */
  pauseConversation?: Maybe<Conversation>;
  /** Prepare a court case for a matter */
  prepareCourtCase?: Maybe<CourtCase>;
  /** Prepare a task for a firm */
  prepareFirmTask?: Maybe<FirmTask>;
  /** Prepare a task for the task library */
  prepareLibraryTask?: Maybe<LibraryTask>;
  /** Prepare a task for a matter */
  prepareMatterTask?: Maybe<MatterTask>;
  /** Prepare a new question in a section of a task */
  prepareQuestion?: Maybe<Question>;
  /** Register a new firm */
  registerFirm?: Maybe<Firm>;
  /** Register an internal user */
  registerInternalUser?: Maybe<User>;
  /** Register a push interest using an Expo push token */
  registerPushInterest?: Maybe<PushInterestPayload>;
  /** Release a question to a client */
  releaseQuestion?: Maybe<Question>;
  /** Release all the questions in a task to a client */
  releaseTask?: Maybe<MatterTask>;
  /** Release all the questions in a task section to a client */
  releaseTaskSection?: Maybe<Task>;
  /** Remove a file from the file manager */
  removeFile?: Maybe<FileRemovedPayload>;
  /** Remove a professional from a firm as a member */
  removeFirmMember?: Maybe<FirmMemberRemovedPayload>;
  /** Remove a folder from the file manager */
  removeFolder?: Maybe<FolderRemovedPayload>;
  /** Remove an internal user */
  removeInternalUser?: Maybe<UserRemovedPayload>;
  /** Remove a key date */
  removeKeyDate?: Maybe<KeyDateRemovedPayload>;
  /** Remove a new matter that has no tasks released to the client */
  removeMatter?: Maybe<MatterRemovedPayload>;
  /** Remove an existing question */
  removeQuestion?: Maybe<QuestionRemovedPayload>;
  /** Remove a part from an existing question */
  removeQuestionPart?: Maybe<Question>;
  /** Remove a file from a matter's supporting material */
  removeSupportingFile?: Maybe<SupportingFileRemovedPayload>;
  /** Remove a task */
  removeTask?: Maybe<TaskRemovedPayload>;
  /** Remove a section from a task */
  removeTaskSection?: Maybe<Task>;
  /** Remove a topic from everywhere */
  removeTopic?: Maybe<TopicPayload>;
  /** Rename an existing file */
  renameFile?: Maybe<File>;
  /** Rename an existing folder */
  renameFolder?: Maybe<Folder>;
  /** Rename an existing task */
  renameTask?: Maybe<Task>;
  /** Rename an existing section of a task */
  renameTaskSection?: Maybe<Task>;
  /** Change the order of the parts of a question by moving one to a new position */
  reorderQuestionParts?: Maybe<Question>;
  /** Change the order of questions in a task section, by moving the selected question */
  reorderQuestions?: Maybe<TaskSection>;
  /** Move a task section to a different place in the order */
  reorderTaskSections?: Maybe<Task>;
  /** Request an email address verification email for a user */
  requestEmailAddressVerification?: Maybe<User>;
  /** Send a connection request to the opponent for an issue */
  requestOpponentConnection?: Maybe<Issue>;
  /** Request that a user's password be reset */
  requestPasswordReset?: Maybe<RequestPasswordResetPayload>;
  /** Resend invite to a client for their account */
  resendClientInvite?: Maybe<ClientAccount>;
  /** Resend invite to a professional for their firm membership */
  resendProfessionalInvite?: Maybe<FirmMember>;
  /** Reset that a user's password using a reset token */
  resetPassword?: Maybe<ResetPasswordPayload>;
  /** Restart the contact verification process */
  restartContactVerification?: Maybe<Contact>;
  /** Resume interaction with a conversation for a participant which was previously paused */
  resumeConversation?: Maybe<Conversation>;
  /** Revise a file already present in the file manager */
  reviseFile?: Maybe<File>;
  /** Revise an existing note */
  reviseNote?: Maybe<Note>;
  /** Revoke an existing client assignment */
  revokeClientAssignment?: Maybe<ClientAssignmentRevokedPayload>;
  /** Revoke a previously registered push interest */
  revokePushInterest?: Maybe<PushInterestPayload>;
  /** Send a message as part of a conversation */
  sendMessage?: Maybe<Message>;
  setCurrentUser?: Maybe<User>;
  /** Set a due date on a task */
  setTaskDueDate?: Maybe<MatterTask>;
  /** Set a due date on a task section */
  setTaskSectionDueDate?: Maybe<Task>;
  /** Setup a client account for a non professional */
  setupClientAccount?: Maybe<ClientAccount>;
  /** Start a new issue */
  startIssue?: Maybe<Issue>;
  /** Start a new matter */
  startMatter?: Maybe<Matter>;
  /** Unrelease a question from a client */
  unreleaseQuestion?: Maybe<Question>;
  /** Unrelease all the questions in a task from a client */
  unreleaseTask?: Maybe<MatterTask>;
  /** Unrelease all the questions in a task section from a client */
  unreleaseTaskSection?: Maybe<Task>;
  updateBreadcrumbs?: Maybe<Array<Maybe<Breadcrumb>>>;
  /** Update a client account */
  updateClientAccount?: Maybe<ClientAccount>;
  /** Change the proof of address held for a client */
  updateClientAddressProof?: Maybe<FirmClient>;
  /** Change the addresses held for a client */
  updateClientAddresses?: Maybe<FirmClient>;
  /** Change the proof of address held for a client */
  updateClientBankDetails?: Maybe<FirmClient>;
  /** Change the contact information held for a client */
  updateClientContactInformation?: Maybe<FirmClient>;
  /** Change the proof of identity held for a client */
  updateClientIdentityProof?: Maybe<FirmClient>;
  /** Change the personal details held for a client */
  updateClientPersonalDetails?: Maybe<FirmClient>;
  /** Update the details held for a contact */
  updateContact?: Maybe<Contact>;
  /** Update a court case for a matter */
  updateCourtCase?: Maybe<CourtCase>;
  /** Update a firm's details */
  updateFirmDetails?: Maybe<Firm>;
  /** Update a firm member's details */
  updateFirmMember?: Maybe<FirmMember>;
  /** Update a professional's details */
  updateProfessional?: Maybe<FirmProfessional>;
  /** Update the name and/or colour of a topic */
  updateTopic?: Maybe<Topic>;
  /** Update an existing transparently user's information */
  updateUser?: Maybe<User>;
  /** Update an existing transparently user's ability to access the system */
  updateUserAccess?: Maybe<User>;
  /** Verify the identity and profile information held for a client */
  verifyClient?: Maybe<FirmClient>;
  /** Verify a contact's details */
  verifyContact?: Maybe<Contact>;
  /** Verify a user's email address using a token */
  verifyEmailAddress?: Maybe<User>;
};


export type MutationAcceptAnswerArgs = {
  input: AcceptAnswerInput;
};


export type MutationAddContactArgs = {
  input: AddContactInput;
};


export type MutationAddFileArgs = {
  asset: Scalars['Upload'];
  folderId: Scalars['ID'];
};


export type MutationAddFilesArgs = {
  assets: Array<Scalars['Upload']>;
  folderId: Scalars['ID'];
};


export type MutationAddFirmMemberArgs = {
  input: AddFirmMemberInput;
};


export type MutationAddFolderArgs = {
  input: AddFolderInput;
};


export type MutationAddKeyDateArgs = {
  input: AddKeyDateInput;
};


export type MutationAddQuestionPartArgs = {
  input: AddQuestionPartInput;
};


export type MutationAddSupportingFileArgs = {
  input: AddSupportingFileInput;
};


export type MutationAnswerQuestionPartArgs = {
  input: AnswerQuestionPartInput;
};


export type MutationApproveClientAccountArgs = {
  input: ApproveClientAccountInput;
};


export type MutationAssignFirmMemberToClientArgs = {
  input: AssignFirmMemberToClientInput;
};


export type MutationAssociateFileWithTopicArgs = {
  input: AssociateFileWithTopicInput;
};


export type MutationAssociateMessageWithTopicArgs = {
  input: AssociateMessageWithTopicInput;
};


export type MutationAssociateNoteArgs = {
  input: AssociateNoteInput;
};


export type MutationChangeAnswerLockArgs = {
  input: ChangeAnswerLockInput;
};


export type MutationChangeClientAccountActivityArgs = {
  input: ChangeClientAccountActivityInput;
};


export type MutationChangeFirmMemberStatusArgs = {
  input: ChangeFirmMemberStatusInput;
};


export type MutationChangeMatterLeadArgs = {
  input: ChangeMatterLeadInput;
};


export type MutationChangeTaskAdviceArgs = {
  input: ChangeTaskAdviceInput;
};


export type MutationChangeTaskMatterTypesArgs = {
  input: ChangeTaskMatterTypesInput;
};


export type MutationChangeTaskPublishStatusArgs = {
  input: ChangeTaskPublishStatusInput;
};


export type MutationChangeTaskSectionAdviceArgs = {
  input: ChangeTaskSectionAdviceInput;
};


export type MutationChangeUserPasswordArgs = {
  input: ChangeUserPasswordInput;
};


export type MutationClearAnswerDraftArgs = {
  input: ClearAnswerDraftInput;
};


export type MutationClearHighlightArgs = {
  input: ClearHighlightInput;
};


export type MutationClearHighlightsArgs = {
  input: ClearHighlightsInput;
};


export type MutationClearTaskDueDateArgs = {
  input: ClearTaskDueDateInput;
};


export type MutationClearTaskSectionDueDateArgs = {
  input: ClearTaskSectionDueDateInput;
};


export type MutationCloseCourtCaseArgs = {
  input: CloseCourtCaseInput;
};


export type MutationCreatePrivateTopicArgs = {
  input: CreatePrivateTopicInput;
};


export type MutationDefineTaskSectionArgs = {
  input: DefineTaskSectionInput;
};


export type MutationDescribeTaskArgs = {
  input: DescribeTaskInput;
};


export type MutationDescribeTaskSectionArgs = {
  input: DescribeTaskSectionInput;
};


export type MutationDisassociateFileFromTopicArgs = {
  input: DisassociateFileFromTopicInput;
};


export type MutationDisassociateMessageFromTopicArgs = {
  input: DisassociateMessageFromTopicInput;
};


export type MutationDiscardConversationExtractArgs = {
  input: DiscardConversationExtractInput;
};


export type MutationDiscardNoteArgs = {
  input: DiscardNoteInput;
};


export type MutationDraftAnswerQuestionPartArgs = {
  input: AnswerQuestionPartInput;
};


export type MutationDraftNoteArgs = {
  input: DraftNoteInput;
};


export type MutationEditMessageDraftArgs = {
  input: ComposeMessageInput;
};


export type MutationEditQuestionArgs = {
  input: EditQuestionInput;
};


export type MutationEndMatterArgs = {
  input: EndMatterInput;
};


export type MutationExportSupportingMaterialArgs = {
  input: ExportSupportingMaterialInput;
};


export type MutationIdentifyOpponentArgs = {
  input: IdentifyOpponentInput;
};


export type MutationIdentifyOpponentSolicitorArgs = {
  input: IdentifyOpponentSolicitorInput;
};


export type MutationLinkIssueArgs = {
  input: LinkIssueInput;
};


export type MutationLoginWithPasswordArgs = {
  input: LoginWithPasswordInput;
};


export type MutationMakeConversationExtractArgs = {
  input: MakeConversationExtractInput;
};


export type MutationMarkAllMessagesReadArgs = {
  input: MarkAllMessagesReadInput;
};


export type MutationMarkMessageDeliveredArgs = {
  input: MarkMessageDeliveredInput;
};


export type MutationMarkMessageReadArgs = {
  input: MarkMessageReadInput;
};


export type MutationMarkNoteViewedArgs = {
  input: MarkNoteViewedInput;
};


export type MutationMarkNotificationReadArgs = {
  input: MarkNotificationReadInput;
};


export type MutationModifyKeyDateArgs = {
  input: ModifyKeyDateInput;
};


export type MutationModifyQuestionPartArgs = {
  input: ModifyQuestionPartInput;
};


export type MutationMoveFileArgs = {
  input: MoveFileInput;
};


export type MutationMoveFolderArgs = {
  input: MoveFolderInput;
};


export type MutationMoveQuestionArgs = {
  input: MoveQuestionInput;
};


export type MutationPauseConversationArgs = {
  input: PauseConversationInput;
};


export type MutationPrepareCourtCaseArgs = {
  input: PrepareCourtCaseInput;
};


export type MutationPrepareFirmTaskArgs = {
  input: PrepareFirmTaskInput;
};


export type MutationPrepareLibraryTaskArgs = {
  input: PrepareLibraryTaskInput;
};


export type MutationPrepareMatterTaskArgs = {
  input: PrepareMatterTaskInput;
};


export type MutationPrepareQuestionArgs = {
  input: PrepareQuestionInput;
};


export type MutationRegisterFirmArgs = {
  input: RegisterFirmInput;
};


export type MutationRegisterInternalUserArgs = {
  input: RegisterInternalUserInput;
};


export type MutationRegisterPushInterestArgs = {
  input: RegisterPushInterestInput;
};


export type MutationReleaseQuestionArgs = {
  input: ReleaseQuestionInput;
};


export type MutationReleaseTaskArgs = {
  input: ReleaseTaskInput;
};


export type MutationReleaseTaskSectionArgs = {
  input: ReleaseTaskSectionInput;
};


export type MutationRemoveFileArgs = {
  input: RemoveFileInput;
};


export type MutationRemoveFirmMemberArgs = {
  input: RemoveFirmMemberInput;
};


export type MutationRemoveFolderArgs = {
  input: RemoveFolderInput;
};


export type MutationRemoveInternalUserArgs = {
  input: RemoveInternalUserInput;
};


export type MutationRemoveKeyDateArgs = {
  input: RemoveKeyDateInput;
};


export type MutationRemoveMatterArgs = {
  input: RemoveMatterInput;
};


export type MutationRemoveQuestionArgs = {
  input: RemoveQuestionInput;
};


export type MutationRemoveQuestionPartArgs = {
  input: RemoveQuestionPartInput;
};


export type MutationRemoveSupportingFileArgs = {
  input: RemoveSupportingFileInput;
};


export type MutationRemoveTaskArgs = {
  input: RemoveTaskInput;
};


export type MutationRemoveTaskSectionArgs = {
  input: RemoveTaskSectionInput;
};


export type MutationRemoveTopicArgs = {
  input: RemoveTopicInput;
};


export type MutationRenameFileArgs = {
  input: RenameFileInput;
};


export type MutationRenameFolderArgs = {
  input: RenameFolderInput;
};


export type MutationRenameTaskArgs = {
  input: RenameTaskInput;
};


export type MutationRenameTaskSectionArgs = {
  input: RenameTaskSectionInput;
};


export type MutationReorderQuestionPartsArgs = {
  input: ReorderQuestionPartsInput;
};


export type MutationReorderQuestionsArgs = {
  input: ReorderQuestionsInput;
};


export type MutationReorderTaskSectionsArgs = {
  input: ReorderTaskSectionsInput;
};


export type MutationRequestEmailAddressVerificationArgs = {
  input: RequestEmailAddressVerificationInput;
};


export type MutationRequestOpponentConnectionArgs = {
  input: RequestOpponentConnectionInput;
};


export type MutationRequestPasswordResetArgs = {
  input: RequestPasswordResetInput;
};


export type MutationResendClientInviteArgs = {
  input: ResendClientInviteInput;
};


export type MutationResendProfessionalInviteArgs = {
  input: ResendProfessionalInviteInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationRestartContactVerificationArgs = {
  input: RestartContactVerificationInput;
};


export type MutationResumeConversationArgs = {
  input: ResumeConversationInput;
};


export type MutationReviseFileArgs = {
  asset: Scalars['Upload'];
  fileId: Scalars['ID'];
};


export type MutationReviseNoteArgs = {
  input: ReviseNoteInput;
};


export type MutationRevokeClientAssignmentArgs = {
  input: RevokeClientAssignmentInput;
};


export type MutationRevokePushInterestArgs = {
  input: RevokePushInterestInput;
};


export type MutationSendMessageArgs = {
  input: ComposeMessageInput;
};


export type MutationSetCurrentUserArgs = {
  input: UserInput;
};


export type MutationSetTaskDueDateArgs = {
  input: SetTaskDueDateInput;
};


export type MutationSetTaskSectionDueDateArgs = {
  input: SetTaskSectionDueDateInput;
};


export type MutationSetupClientAccountArgs = {
  input: SetupClientAccountInput;
};


export type MutationStartIssueArgs = {
  input: StartIssueInput;
};


export type MutationStartMatterArgs = {
  input: StartMatterInput;
};


export type MutationUnreleaseQuestionArgs = {
  input: UnreleaseQuestionInput;
};


export type MutationUnreleaseTaskArgs = {
  input: UnreleaseTaskInput;
};


export type MutationUnreleaseTaskSectionArgs = {
  input: UnreleaseTaskSectionInput;
};


export type MutationUpdateBreadcrumbsArgs = {
  input: Array<Maybe<BreadcrumbInput>>;
};


export type MutationUpdateClientAccountArgs = {
  input: UpdateClientAccountInput;
};


export type MutationUpdateClientAddressProofArgs = {
  input: UpdateClientAddressProofInput;
};


export type MutationUpdateClientAddressesArgs = {
  input: UpdateClientAddressesInput;
};


export type MutationUpdateClientBankDetailsArgs = {
  input: UpdateClientBankDetailsInput;
};


export type MutationUpdateClientContactInformationArgs = {
  input: UpdateClientContactInformationInput;
};


export type MutationUpdateClientIdentityProofArgs = {
  input: UpdateClientIdentityProofInput;
};


export type MutationUpdateClientPersonalDetailsArgs = {
  input: UpdateClientPersonalDetailsInput;
};


export type MutationUpdateContactArgs = {
  input: UpdateContactInput;
};


export type MutationUpdateCourtCaseArgs = {
  input: UpdateCourtCaseInput;
};


export type MutationUpdateFirmDetailsArgs = {
  input: UpdateFirmDetailsInput;
};


export type MutationUpdateFirmMemberArgs = {
  input: UpdateFirmMemberInput;
};


export type MutationUpdateProfessionalArgs = {
  input: UpdateProfessionalInput;
};


export type MutationUpdateTopicArgs = {
  input: UpdateTopicInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateUserAccessArgs = {
  input: UpdateUserAccessInput;
};


export type MutationVerifyClientArgs = {
  input: VerifyClientInput;
};


export type MutationVerifyContactArgs = {
  input: VerifyContactInput;
};


export type MutationVerifyEmailAddressArgs = {
  input: VerifyEmailAddressInput;
};

/** A note made by a legal professional, optionally on a client account */
export type Note = {
  __typename?: 'Note';
  /** The unique ID of the note */
  id: Scalars['ID'];
  /** The note's owner and original author */
  author?: Maybe<FirmProfessional>;
  /** If this note has been associated with a client account and/or matter, it is not a personal note */
  isPersonal: Scalars['Boolean'];
  /** If this note has been viewed by the current user */
  isViewed: Scalars['Boolean'];
  /** The client account to which this note is assigned (if at all) */
  clientAccount?: Maybe<ClientAccount>;
  /** The matter to which this note is assigned (if at all) */
  matter?: Maybe<Matter>;
  /** The date and time when this note was first started */
  startedAt: Scalars['DateTime'];
  /** The note's current version number */
  versionNumber: Scalars['Int'];
  /** The title given to the note */
  title: Scalars['String'];
  /** The main body of the note */
  body: Scalars['String'];
  /** The category into which the note has been placed */
  category: NoteCategory;
  /** The date and time associated with the body of the note */
  dateTime: Scalars['DateTime'];
  /** The last date and time when this note was revised */
  revisedAt: Scalars['DateTime'];
  /** The history of revisions made to this note */
  revisions: Array<NoteRevision>;
  /** Whether or not this note has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
};

export enum NoteCategory {
  Court = 'COURT',
  Meeting = 'MEETING',
  Other = 'OTHER',
  PhoneCall = 'PHONE_CALL',
  WalkIn = 'WALK_IN'
}

/** The result of discarding a note */
export type NoteDiscardedPayload = {
  __typename?: 'NoteDiscardedPayload';
  /** The unique ID of the discarded note */
  noteId: Scalars['ID'];
  /** The date and time when the note was discarded */
  discardedOn: Scalars['DateTime'];
};

export type NoteList = {
  __typename?: 'NoteList';
  /** List of items on the current page */
  items?: Maybe<Array<Maybe<Note>>>;
  /** Number of total items selected by the query */
  total: Scalars['Int'];
  /** Number of items returned per page */
  perPage: Scalars['Int'];
  /** Current page of the cursor */
  currentPage: Scalars['Int'];
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']>;
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']>;
  /** The last page (number of pages) */
  lastPage: Scalars['Int'];
  /** Determines if cursor has more pages after the current page */
  hasMorePages: Scalars['Boolean'];
};

/** A revision to a note made by a legal professional */
export type NoteRevision = {
  __typename?: 'NoteRevision';
  /** The unique ID of the note revision */
  id: Scalars['ID'];
  /** The note revision's author */
  author?: Maybe<FirmProfessional>;
  /** The note revision/version number */
  versionNumber: Scalars['Int'];
  /** The title given to the note revision */
  title: Scalars['String'];
  /** The main body of the note revision */
  body: Scalars['String'];
  /** The category into which the note revision has been placed */
  category: NoteCategory;
  /** The date and time associated with the body of the note revision */
  dateTime: Scalars['DateTime'];
  /** The last date and time when this note revision was drafted */
  draftedAt: Scalars['DateTime'];
};

/** A single notification received by a recipient */
export type Notification = {
  __typename?: 'Notification';
  /** The unique ID of this notification */
  id: Scalars['ID'];
  /** The context from which the notification originated */
  context: Scalars['String'];
  /** Which kind of notification this is */
  type: Scalars['String'];
  /** The short title of the notification */
  title: Scalars['String'];
  /** The full body of the notification */
  body: Scalars['String'];
  /** A URL for a picture to associate with a notification */
  pictureUrl?: Maybe<Scalars['String']>;
  /** The hierarchical path to the subject of the notification */
  path: Array<PathSegment>;
  /** The date and time when this notification was received */
  occurredOn: Scalars['DateTime'];
  /** The date and time when this notification was read, if at all */
  readAt?: Maybe<Scalars['DateTime']>;
  /** Whether or not this notification has been read */
  isRead: Scalars['Boolean'];
};

/** A list of notifications including unread/read counts */
export type NotificationList = {
  __typename?: 'NotificationList';
  /** List of notifications on the current page */
  notifications?: Maybe<Array<Maybe<Notification>>>;
  /** Number of total items selected by the query */
  totalUnread: Scalars['Int'];
  /** Number of total notifications selected by the query */
  total: Scalars['Int'];
  /** Number of notifications returned per page */
  perPage: Scalars['Int'];
  /** Current page of the cursor */
  currentPage: Scalars['Int'];
  /** Number of the first notification returned */
  from?: Maybe<Scalars['Int']>;
  /** Number of the last notification returned */
  to?: Maybe<Scalars['Int']>;
  /** The last page (number of pages) */
  lastPage: Scalars['Int'];
  /** Determines if cursor has more pages after the current page */
  hasMorePages: Scalars['Boolean'];
};

/** The result of marking multiple notifications as read */
export type NotificationsReadPayload = {
  __typename?: 'NotificationsReadPayload';
  /** The number of notifications marked as read */
  affectedNotificationCount?: Maybe<Scalars['Int']>;
};

/** An answer to a part of a question that requires a number response */
export type NumberAnswer = Answer & {
  __typename?: 'NumberAnswer';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The part of a question for which this is an answer */
  questionPart: QuestionPart;
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  proposedOn: Scalars['DateTime'];
  /** Whether or not this answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The revision history of this answer */
  history: Array<AnswerRevision>;
  /** The current status of this answer */
  status: AnswerStatus;
  /** Whether or not this answer has been accepted by a professional */
  isAcceptedByProfessional: Scalars['Boolean'];
  /** Whether or not this answer has been accepted by a client */
  isAcceptedByClient: Scalars['Boolean'];
  /** Whether or not this question part has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
  /** The number given for the answer */
  currentValue?: Maybe<Scalars['Float']>;
};

/** A draft answer to a part of a question that requires a number response */
export type NumberAnswerDraft = AnswerDraft & {
  __typename?: 'NumberAnswerDraft';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The part of a question for which this is an answer */
  questionPart: QuestionPart;
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  startedOn: Scalars['DateTime'];
  /** Whether or not this draft of the answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The number given for the answer */
  currentValue?: Maybe<Scalars['Float']>;
};

/** A revision to an answer to a part of a question that requires a number response */
export type NumberAnswerRevision = AnswerRevision & {
  __typename?: 'NumberAnswerRevision';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  proposedOn: Scalars['DateTime'];
  /** Whether or not this revision of the answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The number given for the answer */
  value?: Maybe<Scalars['Float']>;
};

/** An attribute of a list part that uses a numeric value */
export type NumberAttribute = ListAttribute & {
  __typename?: 'NumberAttribute';
  /** The unique ID of this attribute */
  id: Scalars['String'];
  /** Which type of attribute this is */
  type: QuestionPartType;
  /** The label to be shown for this attribute */
  label: Scalars['String'];
  /** Additional instructions and information to support the user */
  instructions?: Maybe<Scalars['String']>;
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** The ordering index for use when showing this attribute alongside other attributes of the same part */
  ordering: Scalars['Int'];
  /** The minimum number required in the response */
  minimumValue?: Maybe<Scalars['Float']>;
  /** The maximum number allowed in the response */
  maximumValue?: Maybe<Scalars['Float']>;
  /** The configuration for this part */
  configuration: NumberPartConfiguration;
};

/** A list property that makes use of a numeric value */
export type NumberListItemProperty = ListItemProperty & {
  __typename?: 'NumberListItemProperty';
  /** The unique ID of the attribute for which this property exists */
  attributeId: Scalars['ID'];
  /** The number given */
  value?: Maybe<Scalars['Float']>;
};

/** A part of a question that requires a numeric response */
export type NumberPart = QuestionPart & {
  __typename?: 'NumberPart';
  /** The unique ID of this question part */
  id: Scalars['String'];
  /** Which type of part this is */
  type: QuestionPartType;
  /** The label to be shown for this part */
  label: Scalars['String'];
  /** Additional instructions and information to support the user */
  instructions?: Maybe<Scalars['String']>;
  /** Official advice for completing this question part */
  officialAdvice?: Maybe<Scalars['String']>;
  /** Additional advice for completing this question part */
  additionalAdvice?: Maybe<Scalars['String']>;
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** Whether or not this question part has been completed */
  isComplete: Scalars['Boolean'];
  /** The ordering index for use when showing this part alongside other parts of the same question */
  ordering: Scalars['Int'];
  /** The current draft answer for this question part */
  answerDraft?: Maybe<AnswerDraft>;
  /** The answer given to this question part */
  answer?: Maybe<Answer>;
  /** Whether or not this question part has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
  /** The minimum number required in the response */
  minimumValue?: Maybe<Scalars['Float']>;
  /** The maximum number allowed in the response */
  maximumValue?: Maybe<Scalars['Float']>;
  /** The configuration for this part */
  configuration: NumberPartConfiguration;
};

/** The configuration for a part of a question that requires a numeric response */
export type NumberPartConfiguration = {
  __typename?: 'NumberPartConfiguration';
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** The minimum number required in the response */
  minimumValue?: Maybe<Scalars['Float']>;
  /** The maximum number allowed in the response */
  maximumValue?: Maybe<Scalars['Float']>;
};

/** A representation of an opponent in an issue */
export type Opponent = {
  __typename?: 'Opponent';
  /** The opponent's transparently client reference number (if known) */
  clientReferenceNumber?: Maybe<Scalars['String']>;
  /** The opponent's first and last name */
  name: Scalars['String'];
  /** The opponent's preferred name */
  preferredName: Scalars['String'];
  /** The opponent's first name */
  firstName: Scalars['String'];
  /** The opponent's last name */
  lastName: Scalars['String'];
  /** The opponent's full name */
  fullName: Scalars['String'];
  /** The opponent's email address */
  emailAddress: Scalars['EmailAddress'];
  /** The opponent's national insurance number (if known) */
  nationalInsuranceNumber?: Maybe<Scalars['String']>;
  /** Basic details about the opponent's solicitor */
  solicitor: OpponentSolicitor;
  /** The client for whom this is an issue */
  client: FirmClient;
  /** Whether this opponent information has been verified by the opponent themselves */
  isVerified: Scalars['Boolean'];
};

export type OpponentInput = {
  /** The opponent's client reference number, if known */
  referenceNumber?: Maybe<Scalars['String']>;
  /** The opponent's first name */
  firstName: Scalars['String'];
  /** The opponent's last name */
  lastName: Scalars['String'];
  /** The opponent's email address */
  emailAddress: Scalars['EmailAddress'];
  /** The opponent's national insurance number */
  nationalInsuranceNumber?: Maybe<Scalars['String']>;
};

/** A representation of an opponent's solicitor in an issue */
export type OpponentSolicitor = {
  __typename?: 'OpponentSolicitor';
  /** The solicitor's first name (if known) */
  firstName?: Maybe<Scalars['String']>;
  /** The solicitor's last name (if known) */
  lastName?: Maybe<Scalars['String']>;
  /** The solicitor's email address (if known) */
  emailAddress?: Maybe<Scalars['EmailAddress']>;
  /** The solicitor's firm name (if known) */
  firmName?: Maybe<Scalars['String']>;
};

/** A single selectable option in a choice */
export type Option = {
  __typename?: 'Option';
  /** The selectable value */
  value: Scalars['String'];
  /** Supporting information for the value */
  description?: Maybe<Scalars['String']>;
};

/** A segment of a path to a matched object */
export type PathSegment = {
  __typename?: 'PathSegment';
  /** The unique ID of the object in this segment */
  id: Scalars['ID'];
  /** The type of object in this segment */
  type: Scalars['String'];
  /** A display name for this segment */
  name: Scalars['String'];
};

export type PauseConversationInput = {
  conversationId: Scalars['ID'];
  untilDateTime: Scalars['DateTime'];
};

/** An answer to a part of a question that requires a percent response */
export type PercentAnswer = Answer & {
  __typename?: 'PercentAnswer';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The part of a question for which this is an answer */
  questionPart: QuestionPart;
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  proposedOn: Scalars['DateTime'];
  /** Whether or not this answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The revision history of this answer */
  history: Array<AnswerRevision>;
  /** The current status of this answer */
  status: AnswerStatus;
  /** Whether or not this answer has been accepted by a professional */
  isAcceptedByProfessional: Scalars['Boolean'];
  /** Whether or not this answer has been accepted by a client */
  isAcceptedByClient: Scalars['Boolean'];
  /** Whether or not this question part has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
  /** The percent given for the answer */
  currentValue?: Maybe<Scalars['Float']>;
};

/** A draft answer to a part of a question that requires a percent response */
export type PercentAnswerDraft = AnswerDraft & {
  __typename?: 'PercentAnswerDraft';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The part of a question for which this is an answer */
  questionPart: QuestionPart;
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  startedOn: Scalars['DateTime'];
  /** Whether or not this draft of the answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The percent given for the answer */
  currentValue?: Maybe<Scalars['Float']>;
};

/** A revision to an answer to a part of a question that requires a percent response */
export type PercentAnswerRevision = AnswerRevision & {
  __typename?: 'PercentAnswerRevision';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  proposedOn: Scalars['DateTime'];
  /** Whether or not this revision of the answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The percent given for the answer */
  value?: Maybe<Scalars['Float']>;
};

/** An attribute of a list part that uses a percentage value */
export type PercentAttribute = ListAttribute & {
  __typename?: 'PercentAttribute';
  /** The unique ID of this attribute */
  id: Scalars['String'];
  /** Which type of attribute this is */
  type: QuestionPartType;
  /** The label to be shown for this attribute */
  label: Scalars['String'];
  /** Additional instructions and information to support the user */
  instructions?: Maybe<Scalars['String']>;
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** The ordering index for use when showing this attribute alongside other attributes of the same part */
  ordering: Scalars['Int'];
  /** The minimum percent required in the response */
  minimumValue?: Maybe<Scalars['Float']>;
  /** The maximum percent allowed in the response */
  maximumValue?: Maybe<Scalars['Float']>;
  /** The configuration for this part */
  configuration: PercentPartConfiguration;
};

/** A list property that makes use of a percentage value */
export type PercentListItemProperty = ListItemProperty & {
  __typename?: 'PercentListItemProperty';
  /** The unique ID of the attribute for which this property exists */
  attributeId: Scalars['ID'];
  /** The percent given */
  value?: Maybe<Scalars['Float']>;
};

/** A part of a question that requires a percentage response */
export type PercentPart = QuestionPart & {
  __typename?: 'PercentPart';
  /** The unique ID of this question part */
  id: Scalars['String'];
  /** Which type of part this is */
  type: QuestionPartType;
  /** The label to be shown for this part */
  label: Scalars['String'];
  /** Additional instructions and information to support the user */
  instructions?: Maybe<Scalars['String']>;
  /** Official advice for completing this question part */
  officialAdvice?: Maybe<Scalars['String']>;
  /** Additional advice for completing this question part */
  additionalAdvice?: Maybe<Scalars['String']>;
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** Whether or not this question part has been completed */
  isComplete: Scalars['Boolean'];
  /** The ordering index for use when showing this part alongside other parts of the same question */
  ordering: Scalars['Int'];
  /** The current draft answer for this question part */
  answerDraft?: Maybe<AnswerDraft>;
  /** The answer given to this question part */
  answer?: Maybe<Answer>;
  /** Whether or not this question part has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
  /** The minimum percent required in the response */
  minimumValue?: Maybe<Scalars['Float']>;
  /** The maximum percent allowed in the response */
  maximumValue?: Maybe<Scalars['Float']>;
  /** The configuration for this part */
  configuration: PercentPartConfiguration;
};

/** The configuration for a part of a question that requires a percentage response */
export type PercentPartConfiguration = {
  __typename?: 'PercentPartConfiguration';
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** The minimum percent required in the response */
  minimumValue?: Maybe<Scalars['Float']>;
  /** The maximum percent allowed in the response */
  maximumValue?: Maybe<Scalars['Float']>;
};

/** A single permissible action or set of actions */
export type Permission = {
  __typename?: 'Permission';
  /** Details about the scope and intended use of the permission */
  description: Scalars['String'];
  /** The unique ID for this permission */
  id: Scalars['ID'];
  /** The unique name of the permission to be used when checking against requirements */
  name: Scalars['String'];
};

/** A single permissible action or set of actions */
export type PermissionInput = {
  /** The unique ID for this permission */
  id: Scalars['ID'];
  /** The unique name of the permission to be used when checking against requirements */
  name: Scalars['String'];
  /** Details about the scope and intended use of the permission */
  description: Scalars['String'];
};

/** An answer to a part of a question that requires a phone number response */
export type PhoneAnswer = Answer & {
  __typename?: 'PhoneAnswer';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The part of a question for which this is an answer */
  questionPart: QuestionPart;
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  proposedOn: Scalars['DateTime'];
  /** Whether or not this answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The revision history of this answer */
  history: Array<AnswerRevision>;
  /** The current status of this answer */
  status: AnswerStatus;
  /** Whether or not this answer has been accepted by a professional */
  isAcceptedByProfessional: Scalars['Boolean'];
  /** Whether or not this answer has been accepted by a client */
  isAcceptedByClient: Scalars['Boolean'];
  /** Whether or not this question part has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
  /** The phone number given for the answer */
  currentValue?: Maybe<Scalars['String']>;
};

/** A draft answer to a part of a question that requires an phone number response */
export type PhoneAnswerDraft = AnswerDraft & {
  __typename?: 'PhoneAnswerDraft';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The part of a question for which this is an answer */
  questionPart: QuestionPart;
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  startedOn: Scalars['DateTime'];
  /** Whether or not this draft of the answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The phone number given for the answer */
  currentValue?: Maybe<Scalars['String']>;
};

/** A revision to an answer to a part of a question that requires an phone number response */
export type PhoneAnswerRevision = AnswerRevision & {
  __typename?: 'PhoneAnswerRevision';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  proposedOn: Scalars['DateTime'];
  /** Whether or not this revision of the answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The phone number given for the answer */
  value?: Maybe<Scalars['String']>;
};

/** An attribute of a list part that uses a phone number value */
export type PhoneAttribute = ListAttribute & {
  __typename?: 'PhoneAttribute';
  /** The unique ID of this attribute */
  id: Scalars['String'];
  /** Which type of attribute this is */
  type: QuestionPartType;
  /** The label to be shown for this attribute */
  label: Scalars['String'];
  /** Additional instructions and information to support the user */
  instructions?: Maybe<Scalars['String']>;
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** The ordering index for use when showing this attribute alongside other attributes of the same part */
  ordering: Scalars['Int'];
  /** The configuration for this part */
  configuration: PhonePartConfiguration;
};

/** A list property that makes use of a phone number */
export type PhoneListItemProperty = ListItemProperty & {
  __typename?: 'PhoneListItemProperty';
  /** The unique ID of the attribute for which this property exists */
  attributeId: Scalars['ID'];
  /** The phone number given */
  value?: Maybe<Scalars['String']>;
};

/** A part of a question that requires a phone number response */
export type PhonePart = QuestionPart & {
  __typename?: 'PhonePart';
  /** The unique ID of this question part */
  id: Scalars['String'];
  /** Which type of part this is */
  type: QuestionPartType;
  /** The label to be shown for this part */
  label: Scalars['String'];
  /** Additional instructions and information to support the user */
  instructions?: Maybe<Scalars['String']>;
  /** Official advice for completing this question part */
  officialAdvice?: Maybe<Scalars['String']>;
  /** Additional advice for completing this question part */
  additionalAdvice?: Maybe<Scalars['String']>;
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** Whether or not this question part has been completed */
  isComplete: Scalars['Boolean'];
  /** The ordering index for use when showing this part alongside other parts of the same question */
  ordering: Scalars['Int'];
  /** The current draft answer for this question part */
  answerDraft?: Maybe<AnswerDraft>;
  /** The answer given to this question part */
  answer?: Maybe<Answer>;
  /** Whether or not this question part has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
  /** The configuration for this part */
  configuration: PhonePartConfiguration;
};

/** The configuration for a part of a question that requires a phone number response */
export type PhonePartConfiguration = {
  __typename?: 'PhonePartConfiguration';
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
};

export type PrepareCourtCaseInput = {
  /** The unique ID of the matter for which this court case will be prepared */
  matterId: Scalars['ID'];
  /** The name of the court in which this case will be heard */
  courtName: Scalars['String'];
  /** The case number given to this case by the courts (if known) */
  caseNumber?: Maybe<Scalars['String']>;
  /** The date/time when the case will be heard in court, or the ID of an existing key date (if known) */
  hearingDate?: Maybe<Scalars['String']>;
};

export type PrepareFirmTaskInput = {
  /** The unique ID of the firm for which this task will be prepared. */
  firmId: Scalars['ID'];
  /** The unique ID of the existing task to copy (if applicable). */
  copyTaskId?: Maybe<Scalars['ID']>;
  /** A name for the task (optional, max 250 characters). */
  name?: Maybe<Scalars['String']>;
  /** A description for the task. (optional, max 5000 characters) */
  description?: Maybe<Scalars['String']>;
  /** Additional advice for the task (max 5000 characters) */
  additionalAdvice?: Maybe<Scalars['String']>;
  /** The matter types for which this task is used. Leave empty if this is a general task. */
  matterTypes?: Maybe<Array<MatterType>>;
};

export type PrepareLibraryTaskInput = {
  /** A name for the task (optional, max 250 characters) */
  name: Scalars['String'];
  /** A description for the task (optional, max 5000 characters) */
  description?: Maybe<Scalars['String']>;
  /** Official advice for the task (max 5000 characters) */
  officialAdvice?: Maybe<Scalars['String']>;
  /** The matter types for which this task is used. Leave empty if this is a general task. */
  matterTypes?: Maybe<Array<MatterType>>;
};

export type PrepareMatterTaskInput = {
  /** The unique ID of the matter for which this task will be prepared. */
  matterId: Scalars['ID'];
  /** The unique ID of the library or firm task being copied, if chosen. (optional) */
  templateTaskId?: Maybe<Scalars['ID']>;
  /** A name for the task (optional, max 250 characters). */
  name?: Maybe<Scalars['String']>;
  /** A description for the task. (optional, max 5000 characters) */
  description?: Maybe<Scalars['String']>;
  /** Additional advice for the task (max 5000 characters) */
  additionalAdvice?: Maybe<Scalars['String']>;
  /** The date/time by when the task should be complete, or the ID of an existing key date (if known) */
  dueDate?: Maybe<Scalars['String']>;
};

export type PrepareQuestionInput = {
  /** The unique ID of the task in which this question will be prepared */
  taskId: Scalars['ID'];
  /** The unique ID of the task section in which this question will be prepared. (Defaults to the last section of the task). */
  sectionId?: Maybe<Scalars['ID']>;
  /** The text of the question itself. */
  text: Scalars['String'];
  /** Additional descriptive text. */
  description?: Maybe<Scalars['String']>;
  /** Official advice for the question (max 5000 characters) */
  officialAdvice?: Maybe<Scalars['String']>;
  /** Additional advice for the question (max 5000 characters) */
  additionalAdvice?: Maybe<Scalars['String']>;
  /** A list of specifications for the parts of this question */
  parts: Array<DefineQuestionPartInput>;
};

/** A topic that is privately owned */
export type PrivateTopic = Topic & {
  __typename?: 'PrivateTopic';
  /** The unique ID of the owner of this topic */
  ownerId: Scalars['ID'];
  /** The unique ID of the topic */
  id: Scalars['ID'];
  /** The name given to the topic by its creator */
  name: Scalars['String'];
  /** A unique, URL-safe version of the topic name */
  slug: Scalars['String'];
  /** The colour name given to the topic by its creator */
  colour: Scalars['String'];
};

/** The proposer of an answer */
export type Proposer = {
  __typename?: 'Proposer';
  /** The unique ID of this proposer */
  id: Scalars['ID'];
  /** The proposer's preferred name */
  preferredName: Scalars['String'];
  /** The proposer's first name */
  firstName: Scalars['String'];
  /** The proposer's last name */
  lastName: Scalars['String'];
  /** The proposer's email address */
  emailAddress: Scalars['String'];
  /** The URL to the proposer's picture */
  pictureUrl: Scalars['String'];
};

/** The result of registering or revoking a push interest */
export type PushInterestPayload = {
  __typename?: 'PushInterestPayload';
  /** The token registered or revoked */
  affectedPushToken?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  /** Get a list of all key dates that are visible by the current user */
  allKeyDates?: Maybe<KeyDateList>;
  /** Get the answers for every part of a question */
  answers: Array<Answer>;
  /** List all client assignments for the given client */
  assignmentsForClient?: Maybe<Array<Maybe<ClientAssignment>>>;
  /** List all client assignments for the given professional */
  assignmentsForProfessional?: Maybe<Array<Maybe<ClientAssignment>>>;
  /** Get a list of notes authored by an individual */
  authoredNotes?: Maybe<NoteList>;
  /** Get the details of a client */
  client?: Maybe<FirmClient>;
  /** Get the details of a client account for a legal firm */
  clientAccount?: Maybe<ClientAccount>;
  /** Get a list of key dates that are related to a specified client account */
  clientAccountKeyDates: Array<KeyDate>;
  /** Get a list of notes associated with a client account */
  clientAccountNotes?: Maybe<NoteList>;
  /** Get a list of client accounts for a client */
  clientAccountsForClient?: Maybe<ClientAccountList>;
  /** Get a list of client accounts for a legal firm */
  clientAccountsForFirm?: Maybe<ClientAccountList>;
  /** Get a list of client accounts assigned to a professional */
  clientAccountsForProfessional?: Maybe<ClientAccountList>;
  /** Get the contact list belonging to the current user */
  contactList: Array<Contact>;
  /** Get the details of a single conversation */
  conversation?: Maybe<Conversation>;
  /** Get the details of a single conversation between the current user and a specified participant */
  conversationWith?: Maybe<Conversation>;
  /** Get a list of conversations involving the specified member or current user */
  conversations?: Maybe<Array<Maybe<Conversation>>>;
  /** Get details for a single court case */
  courtCase?: Maybe<CourtCase>;
  /** Get a list of court cases for a matter */
  courtCases: Array<CourtCase>;
  /** Get details of the currently authenticated user */
  currentUser?: Maybe<User>;
  /** Get the details for a single file */
  file?: Maybe<File>;
  /** Get the details of a single Firm */
  firm?: Maybe<Firm>;
  /** Get a single firm member */
  firmMember?: Maybe<FirmMember>;
  /** List all members of a firm that fit the criteria */
  firmMembers?: Maybe<Array<Maybe<FirmMember>>>;
  /** List all memberships to firms held by a professional */
  firmMemberships?: Maybe<Array<Maybe<FirmMember>>>;
  /** List all tasks in a firm's library */
  firmTasks: Array<FirmTask>;
  /** List all firms that fit the criteria */
  firms?: Maybe<Array<Maybe<Firm>>>;
  /** Get the details for a single folder */
  folder?: Maybe<Folder>;
  /** List all folders that fit the criteria */
  folders?: Maybe<Array<Maybe<Folder>>>;
  getBreadcrumbs: Array<Maybe<Breadcrumb>>;
  getCurrentUser: User;
  /** Get highlight counts for the current user */
  highlightCounts: HighlightCountsPayload;
  /** Get a list of issues for a client */
  issues: Array<Issue>;
  /** List all library tasks */
  libraryTasks: Array<LibraryTask>;
  /** Get the details of a single matter */
  matter?: Maybe<Matter>;
  /** Get a list of key dates that are related to a specified matter */
  matterKeyDates: Array<KeyDate>;
  /** List all tasks for a matter */
  matterTasks: Array<MatterTask>;
  /** Get a list of matters for a client */
  mattersForClient?: Maybe<MatterList>;
  /** Get a list of matters for a client account */
  mattersForClientAccount?: Maybe<MatterList>;
  /** Get a list of matters for a firm */
  mattersForFirm?: Maybe<MatterList>;
  /** Get a list of matters for a professional */
  mattersForProfessional?: Maybe<MatterList>;
  /** Get a list of messages in a conversation, from the point of view of the current user */
  messages?: Maybe<MessageList>;
  /** Get the details for a single note */
  note?: Maybe<Note>;
  /** Get recent notifications */
  notifications: NotificationList;
  /** Get all private topics owned by the specified user */
  privateTopics: Array<PrivateTopic>;
  /** Get a single question from a task */
  question?: Maybe<Question>;
  /** Get the questions from a task */
  questions?: Maybe<QuestionList>;
  /** Search all indexed objects */
  search?: Maybe<SearchResult>;
  /** Get the supporting material for a matter */
  supportingMaterial: Array<SupportingMaterial>;
  /** Get a single task for a matter */
  task?: Maybe<Task>;
  /** Get a single section of a task */
  taskSection?: Maybe<TaskSection>;
  /** List all available template tasks */
  templateTasks: Array<TemplateTask>;
  /** Get the topic with the specified topic ID */
  topic?: Maybe<Topic>;
  /** Get details of a single registered user */
  user?: Maybe<User>;
  /** Get a list of registered users */
  users?: Maybe<UserList>;
};


export type QueryAllKeyDatesArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type QueryAnswersArgs = {
  questionId: Scalars['ID'];
};


export type QueryAssignmentsForClientArgs = {
  clientId: Scalars['ID'];
  firmId?: Maybe<Scalars['ID']>;
};


export type QueryAssignmentsForProfessionalArgs = {
  professionalId: Scalars['ID'];
  firmId?: Maybe<Scalars['ID']>;
};


export type QueryAuthoredNotesArgs = {
  authorId: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type QueryClientArgs = {
  clientId: Scalars['ID'];
};


export type QueryClientAccountArgs = {
  clientAccountId: Scalars['ID'];
};


export type QueryClientAccountKeyDatesArgs = {
  clientAccountId?: Maybe<Scalars['ID']>;
};


export type QueryClientAccountNotesArgs = {
  clientAccountId: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type QueryClientAccountsForClientArgs = {
  clientId: Scalars['ID'];
  firmId?: Maybe<Scalars['ID']>;
};


export type QueryClientAccountsForFirmArgs = {
  firmId: Scalars['ID'];
  professionalId?: Maybe<Scalars['ID']>;
  clientId?: Maybe<Scalars['ID']>;
};


export type QueryClientAccountsForProfessionalArgs = {
  professionalId: Scalars['ID'];
  firmId?: Maybe<Scalars['ID']>;
};


export type QueryConversationArgs = {
  conversationId: Scalars['ID'];
};


export type QueryConversationWithArgs = {
  participantId: Scalars['ID'];
};


export type QueryConversationsArgs = {
  memberId?: Maybe<Scalars['ID']>;
  includeObserved?: Maybe<Scalars['Boolean']>;
};


export type QueryCourtCaseArgs = {
  courtCaseId: Scalars['ID'];
};


export type QueryCourtCasesArgs = {
  matterId: Scalars['ID'];
};


export type QueryFileArgs = {
  fileId: Scalars['ID'];
};


export type QueryFirmArgs = {
  firmId: Scalars['ID'];
};


export type QueryFirmMemberArgs = {
  firmMemberId: Scalars['ID'];
};


export type QueryFirmMembersArgs = {
  firmId: Scalars['ID'];
};


export type QueryFirmMembershipsArgs = {
  professionalId: Scalars['ID'];
};


export type QueryFirmTasksArgs = {
  firmId: Scalars['ID'];
  includeUnpublished?: Maybe<Scalars['Boolean']>;
};


export type QueryFolderArgs = {
  folderId?: Maybe<Scalars['ID']>;
};


export type QueryFoldersArgs = {
  parentId?: Maybe<Scalars['ID']>;
};


export type QueryHighlightCountsArgs = {
  filters?: Maybe<Array<HighlightFilter>>;
};


export type QueryIssuesArgs = {
  clientId: Scalars['ID'];
};


export type QueryLibraryTasksArgs = {
  includeUnpublished?: Maybe<Scalars['Boolean']>;
};


export type QueryMatterArgs = {
  matterId: Scalars['ID'];
};


export type QueryMatterKeyDatesArgs = {
  matterId?: Maybe<Scalars['ID']>;
};


export type QueryMatterTasksArgs = {
  matterId: Scalars['ID'];
};


export type QueryMattersForClientArgs = {
  clientId: Scalars['ID'];
  firmId?: Maybe<Scalars['ID']>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type QueryMattersForClientAccountArgs = {
  clientAccountId: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type QueryMattersForFirmArgs = {
  firmId: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type QueryMattersForProfessionalArgs = {
  professionalId: Scalars['ID'];
  firmId?: Maybe<Scalars['ID']>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type QueryMessagesArgs = {
  input: QueryMessagesInput;
};


export type QueryNoteArgs = {
  noteId: Scalars['ID'];
};


export type QueryNotificationsArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type QueryPrivateTopicsArgs = {
  ownerId?: Maybe<Scalars['ID']>;
};


export type QueryQuestionArgs = {
  questionId: Scalars['ID'];
};


export type QueryQuestionsArgs = {
  taskId: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type QuerySearchArgs = {
  term: Scalars['String'];
  context?: Maybe<SearchContext>;
};


export type QuerySupportingMaterialArgs = {
  matterId: Scalars['ID'];
};


export type QueryTaskArgs = {
  taskId: Scalars['ID'];
};


export type QueryTaskSectionArgs = {
  taskId: Scalars['ID'];
  sectionId: Scalars['ID'];
};


export type QueryTemplateTasksArgs = {
  matterType?: Maybe<MatterType>;
  includeUnpublished?: Maybe<Scalars['Boolean']>;
};


export type QueryTopicArgs = {
  topicId?: Maybe<Scalars['ID']>;
};


export type QueryUserArgs = {
  userId: Scalars['ID'];
};


export type QueryUsersArgs = {
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type QueryMessagesInput = {
  /** The ID of the conversation in which the messages were sent */
  conversationId: Scalars['ID'];
  /** Set to restrict to messages sent by the listed senders */
  senderIds?: Maybe<Array<Scalars['ID']>>;
  /** The date/time before which messages should have been sent */
  sentBefore?: Maybe<Scalars['DateTime']>;
  /** The date/time after which messages should have been sent */
  sentAfter?: Maybe<Scalars['DateTime']>;
  /** If set, ony messages with or without attachments are returned as appropriate */
  hasAttachments?: Maybe<Scalars['Boolean']>;
  /** If set, only read or unread messages are returned as appropriate */
  isUnread?: Maybe<Scalars['Boolean']>;
  /** The page of messages to return */
  page?: Maybe<Scalars['Int']>;
  /** The maximum number of messages to return */
  pageSize?: Maybe<Scalars['Int']>;
  /** The unique ID of the message being focused. This overrides the 'page' variable. */
  focusMessageId?: Maybe<Scalars['ID']>;
};

/** A single question in a task, that requires a response from an individual */
export type Question = {
  __typename?: 'Question';
  /** The unique ID of this question */
  id: Scalars['ID'];
  /** The task to which this question belongs */
  task: Task;
  /** The text of the question itself */
  text: Scalars['String'];
  /** Additional descriptive text */
  description: Scalars['String'];
  /** Official advice for completing this question */
  officialAdvice?: Maybe<Scalars['String']>;
  /** Additional advice for completing this question */
  additionalAdvice?: Maybe<Scalars['String']>;
  /** All the parts that make up this question */
  parts: Array<QuestionPart>;
  /** The number of parts in this question */
  partCount: Scalars['Int'];
  /** The status of this question from the perspective of the current user */
  status: QuestionStatus;
  /** Whether or not this question has been released to the client */
  isReleased: Scalars['Boolean'];
  /** Whether or not this question has been completed */
  isComplete: Scalars['Boolean'];
  /** How much of this question has been completed, as a decimal percentage */
  proportionComplete: Scalars['Float'];
  /** Whether or not this question has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
};

export type QuestionList = {
  __typename?: 'QuestionList';
  /** List of items on the current page */
  items?: Maybe<Array<Maybe<Question>>>;
  /** Number of total items selected by the query */
  total: Scalars['Int'];
  /** Number of items returned per page */
  perPage: Scalars['Int'];
  /** Current page of the cursor */
  currentPage: Scalars['Int'];
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']>;
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']>;
  /** The last page (number of pages) */
  lastPage: Scalars['Int'];
  /** Determines if cursor has more pages after the current page */
  hasMorePages: Scalars['Boolean'];
};

/** A single part of a question */
export type QuestionPart = {
  /** The unique ID of this question part */
  id: Scalars['String'];
  /** Which type of part this is */
  type: QuestionPartType;
  /** The label to be shown for this part */
  label: Scalars['String'];
  /** Additional instructions and information to support the user */
  instructions?: Maybe<Scalars['String']>;
  /** Official advice for completing this question part */
  officialAdvice?: Maybe<Scalars['String']>;
  /** Additional advice for completing this question part */
  additionalAdvice?: Maybe<Scalars['String']>;
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** Whether or not this question part has been completed */
  isComplete: Scalars['Boolean'];
  /** The ordering index for use when showing this part alongside other parts of the same question */
  ordering: Scalars['Int'];
  /** The current draft answer for this question part */
  answerDraft?: Maybe<AnswerDraft>;
  /** The answer given to this question part */
  answer?: Maybe<Answer>;
  /** Whether or not this question part has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
};

export enum QuestionPartType {
  Address = 'ADDRESS',
  Boolean = 'BOOLEAN',
  Choice = 'CHOICE',
  Date = 'DATE',
  Email = 'EMAIL',
  File = 'FILE',
  List = 'LIST',
  Money = 'MONEY',
  Number = 'NUMBER',
  Percent = 'PERCENT',
  Phone = 'PHONE',
  String = 'STRING',
  Text = 'TEXT'
}

/** The result of removing a question */
export type QuestionRemovedPayload = {
  __typename?: 'QuestionRemovedPayload';
  /** The unique ID of the removed question */
  questionId: Scalars['ID'];
  /** The date and time when the question was removed */
  removedOn: Scalars['DateTime'];
};

export enum QuestionStatus {
  Unreleased = 'UNRELEASED',
  Released = 'RELEASED',
  InProgress = 'IN_PROGRESS',
  NeedsAttention = 'NEEDS_ATTENTION',
  Complete = 'COMPLETE'
}

export type RegisterFirmInput = {
  /** Which type of firm is being registered */
  type: FirmType;
  /** The firm's company registration number */
  companyNumber: Scalars['String'];
  /** The firm's SRA number */
  sraNumber: Scalars['String'];
  /** The firm's official name */
  name: Scalars['String'];
  /** The number of seats available to the firm */
  seatCount: Scalars['Int'];
};

export type RegisterInternalUserInput = {
  /** The email address which will be used to identify and contact the user */
  email: Scalars['String'];
  /** The user's first name */
  givenName: Scalars['String'];
  /** The user's last name */
  familyName: Scalars['String'];
  /** The user's password */
  password?: Maybe<Scalars['String']>;
};

export type RegisterPushInterestInput = {
  pushToken: Scalars['String'];
};

export type ReleaseQuestionInput = {
  /** The unique ID of the question to be released */
  questionId: Scalars['ID'];
};

export type ReleaseTaskInput = {
  /** The unique ID of the task to which the questions to be released belong */
  taskId: Scalars['ID'];
};

export type ReleaseTaskSectionInput = {
  /** The unique ID of the task to which the section to be released belongs */
  taskId: Scalars['ID'];
  /** The unique ID of the task section to be released */
  sectionId: Scalars['ID'];
};

export type RemoveFileInput = {
  /** The unique ID of the file to be removed. */
  fileId: Scalars['ID'];
};

export type RemoveFirmMemberInput = {
  /** The unique ID of the firm member */
  firmMemberId: Scalars['ID'];
};

export type RemoveFolderInput = {
  /** The unique ID of the folder to be removed. */
  folderId: Scalars['ID'];
};

export type RemoveInternalUserInput = {
  /** The unique ID of the user being removed */
  id: Scalars['ID'];
};

export type RemoveKeyDateInput = {
  /** The unique ID of the key date */
  keyDateId: Scalars['ID'];
};

export type RemoveMatterInput = {
  /** The unique ID of the matter */
  matterId: Scalars['ID'];
};

export type RemoveQuestionInput = {
  /** The unique ID of the question which will be removed */
  questionId: Scalars['ID'];
};

export type RemoveQuestionPartInput = {
  /** The unique ID of the question from which a part will be removed */
  questionId: Scalars['ID'];
  /** The unique ID of the part to be removed */
  partId: Scalars['ID'];
};

export type RemoveSupportingFileInput = {
  /** The unique ID of the supporting file to remove */
  supportingFileId: Scalars['ID'];
};

export type RemoveTaskInput = {
  /** The unique ID of the task */
  taskId: Scalars['ID'];
};

export type RemoveTaskSectionInput = {
  /** The unique ID of the task */
  taskId: Scalars['ID'];
  /** The unique ID of the section */
  sectionId: Scalars['ID'];
};

export type RemoveTopicInput = {
  /** The unique ID of the topic to be removed */
  topicId: Scalars['ID'];
};

export type RenameFileInput = {
  /** The unique ID of the file to be renamed */
  fileId: Scalars['ID'];
  /** A new name for the file (max 250 characters) */
  name: Scalars['String'];
};

export type RenameFolderInput = {
  /** The unique ID of the folder to be renamed */
  folderId: Scalars['ID'];
  /** A new name for the folder (max 250 characters) */
  name: Scalars['String'];
};

export type RenameTaskInput = {
  /** The unique ID of the task to be renamed */
  taskId: Scalars['ID'];
  /** A new name for the task (max 250 characters) */
  name: Scalars['String'];
};

export type RenameTaskSectionInput = {
  /** The unique ID of the task */
  taskId: Scalars['ID'];
  /** The unique ID of the section */
  sectionId: Scalars['ID'];
  /** The new name for the section */
  sectionName?: Maybe<Scalars['String']>;
};

export type RenameUserInput = {
  /** The user's preferred name */
  preferredName?: Maybe<Scalars['String']>;
  /** The user's first name */
  givenName: Scalars['String'];
  /** The user's middle name(s) */
  middleName?: Maybe<Scalars['String']>;
  /** The user's last name */
  familyName: Scalars['String'];
};

export type ReorderQuestionPartsInput = {
  /** The unique ID of the question */
  questionId: Scalars['ID'];
  /** The unique ID of the part to give a new ordering */
  partId: Scalars['ID'];
  /** The new 1-indexed ordering to give the part */
  ordering: Scalars['Int'];
};

export type ReorderQuestionsInput = {
  /** The unique ID of the task */
  taskId: Scalars['ID'];
  /** The unique ID of the section */
  sectionId: Scalars['ID'];
  /** The unique ID of the question to start with */
  questionId: Scalars['ID'];
  /** The new ordering position for the question */
  ordering?: Maybe<Scalars['Int']>;
};

export type ReorderTaskSectionsInput = {
  /** The unique ID of the task */
  taskId: Scalars['ID'];
  /** The unique ID of the section */
  sectionId: Scalars['ID'];
  /** The new ordering position for the section */
  ordering?: Maybe<Scalars['Int']>;
};

export type RequestEmailAddressVerificationInput = {
  /** The user's unique ID */
  userId: Scalars['ID'];
};

export type RequestOpponentConnectionInput = {
  /** The unique ID of the issue */
  issueId: Scalars['ID'];
};

export type RequestPasswordResetInput = {
  /** The user's email address */
  email: Scalars['String'];
  /** The URL to which the token will be appended when it is sent to the user */
  resetUrl?: Maybe<Scalars['String']>;
};

/** The result of a password reset request */
export type RequestPasswordResetPayload = {
  __typename?: 'RequestPasswordResetPayload';
  /** The password reset token issued to the user (TEMP) */
  token: Scalars['String'];
  /** The number of seconds for which the ticket issued is valid */
  expiresIn: Scalars['Int'];
  /** The date and time when the token expires */
  expiresAt: Scalars['String'];
};

export type ResendClientInviteInput = {
  /** The unique ID of the client account */
  clientAccountId: Scalars['ID'];
};

export type ResendProfessionalInviteInput = {
  /** The unique ID of the firm member */
  firmMemberId: Scalars['ID'];
};

export type ResetPasswordInput = {
  /** The password reset token issued to the user */
  token: Scalars['String'];
  /** The password reset token issued to the user */
  password: Scalars['String'];
};

/** The result of an email address verification */
export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  /** Details of the user whose password was just reset */
  user?: Maybe<User>;
};

export type RestartContactVerificationInput = {
  /** The unique ID of the contact being verified */
  contactId: Scalars['ID'];
};

export type ResumeConversationInput = {
  conversationId: Scalars['ID'];
};

export type ReviseNoteInput = {
  /** The unique ID of the note being revised */
  noteId: Scalars['ID'];
  /** The category into which to place the note */
  category?: Maybe<NoteCategory>;
  /** The date and time associated with the note body */
  dateTime?: Maybe<Scalars['DateTime']>;
  /** The note title */
  title?: Maybe<Scalars['String']>;
  /** The note body */
  body?: Maybe<Scalars['String']>;
};

export type RevokeClientAssignmentInput = {
  /** The unique ID of the client assignment */
  clientAssignmentId: Scalars['ID'];
};

export type RevokePushInterestInput = {
  pushToken: Scalars['String'];
};

/** A role that can be performed by a user, which carries with it a set of permissions */
export type Role = {
  __typename?: 'Role';
  /** Details about the intended uses of this role */
  description: Scalars['String'];
  /** The unique ID for this role */
  id: Scalars['ID'];
  /** The name given to this role */
  name: Scalars['String'];
  /** The permissions assigned to this role */
  permissions: Array<Permission>;
};

export type RoleInput = {
  /** The unique ID for this role */
  id: Scalars['ID'];
  /** The name given to this role */
  name: Scalars['String'];
  /** Details about the intended uses of this role */
  description: Scalars['String'];
  /** The permissions assigned to this role */
  permissions: PermissionInput;
};

export enum SearchContext {
  Contacts = 'CONTACTS',
  Files = 'FILES',
  FirmAdmin = 'FIRM_ADMIN',
  Matters = 'MATTERS',
  Messaging = 'MESSAGING'
}

/** A single match made during a search query */
export type SearchMatch = MatchedContact | MatchedFile | MatchedFolder | MatchedMessage | MatchedClientAccount | MatchedNote | MatchedTask;

/** The result of a search query */
export type SearchResult = {
  __typename?: 'SearchResult';
  /** The search term used when making the query */
  term: Scalars['String'];
  /** The context in which the search was made */
  context?: Maybe<SearchContext>;
  /** The matches made that are part of the specified context */
  contextualMatches?: Maybe<Array<SearchMatch>>;
  /** The matches made that are from outside the specified context */
  otherMatches: Array<SearchMatch>;
};

export type SetTaskDueDateInput = {
  /** The unique ID of the task */
  taskId: Scalars['ID'];
  /** The date/time by when the task should be complete, or the ID of an existing key date (if known) */
  dueDate: Scalars['String'];
};

export type SetTaskSectionDueDateInput = {
  /** The unique ID of the task */
  taskId: Scalars['ID'];
  /** The unique ID of the task section */
  sectionId: Scalars['ID'];
  /** The date/time by when the section should be complete, or the ID of an existing key date (if known) */
  dueDate: Scalars['String'];
};

export type SetupClientAccountInput = {
  /** The client's preferred name */
  preferredName?: Maybe<Scalars['String']>;
  /** The client's first name */
  firstName: Scalars['String'];
  /** The client's last name */
  lastName: Scalars['String'];
  /** The client's national insurance number without spaces */
  nationalInsuranceNumber: Scalars['String'];
  /** The client's email address */
  emailAddress: Scalars['EmailAddress'];
  /** The client's contact phone number */
  mobilePhoneNumber: Scalars['String'];
  /** The unique ID of the firm with which the client will hold an account */
  firmId: Scalars['ID'];
  /** A custom reference number or code for this account */
  referenceNumber?: Maybe<Scalars['String']>;
  /** The unique ID of the professional in the firm who will be the main point of contact for the client */
  leadProfessionalId?: Maybe<Scalars['ID']>;
  /** Optionally specify an issue type to start straight away */
  issueType?: Maybe<IssueType>;
};

export type StartIssueInput = {
  /** The type of issue being started */
  issueType: IssueType;
  /** The unique ID of the client account with which to associate the issue */
  clientAccountId: Scalars['ID'];
  /** The unique ID of the client to whom the issue belongs */
  clientId: Scalars['ID'];
  /** Details about the opponent (if known) */
  opponent?: Maybe<OpponentInput>;
  /** Whether to send a connection request */
  sendRequest?: Maybe<Scalars['Boolean']>;
};

export type StartMatterInput = {
  /** The type of matter being started */
  matterType: MatterType;
  /** The unique ID of the issue under which this matter is to be started */
  issueId: Scalars['ID'];
  /** The unique ID of the client's account with the a firm */
  clientAccountId: Scalars['ID'];
  /** The unique ID of the solicitor leading the matter */
  leadProfessionalId: Scalars['ID'];
};

/** An answer to a part of a question that requires a string response */
export type StringAnswer = Answer & {
  __typename?: 'StringAnswer';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The part of a question for which this is an answer */
  questionPart: QuestionPart;
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  proposedOn: Scalars['DateTime'];
  /** Whether or not this answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The revision history of this answer */
  history: Array<AnswerRevision>;
  /** The current status of this answer */
  status: AnswerStatus;
  /** Whether or not this answer has been accepted by a professional */
  isAcceptedByProfessional: Scalars['Boolean'];
  /** Whether or not this answer has been accepted by a client */
  isAcceptedByClient: Scalars['Boolean'];
  /** Whether or not this question part has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
  /** The string given for the answer */
  currentValue?: Maybe<Scalars['String']>;
};

/** A draft answer to a part of a question that requires a string response */
export type StringAnswerDraft = AnswerDraft & {
  __typename?: 'StringAnswerDraft';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The part of a question for which this is an answer */
  questionPart: QuestionPart;
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  startedOn: Scalars['DateTime'];
  /** Whether or not this draft of the answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The string given for the answer */
  currentValue?: Maybe<Scalars['String']>;
};

/** A revision to an answer to a part of a question that requires a string response */
export type StringAnswerRevision = AnswerRevision & {
  __typename?: 'StringAnswerRevision';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  proposedOn: Scalars['DateTime'];
  /** Whether or not this revision of the answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The string given for the answer */
  value?: Maybe<Scalars['String']>;
};

/** An attribute of a list part that uses a string value */
export type StringAttribute = ListAttribute & {
  __typename?: 'StringAttribute';
  /** The unique ID of this attribute */
  id: Scalars['String'];
  /** Which type of attribute this is */
  type: QuestionPartType;
  /** The label to be shown for this attribute */
  label: Scalars['String'];
  /** Additional instructions and information to support the user */
  instructions?: Maybe<Scalars['String']>;
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** The ordering index for use when showing this attribute alongside other attributes of the same part */
  ordering: Scalars['Int'];
  /** The minimum number of characters required in the response */
  minimumLength?: Maybe<Scalars['Int']>;
  /** The maximum number of characters allowed in the response */
  maximumLength?: Maybe<Scalars['Int']>;
  /** The configuration for this part */
  configuration: StringPartConfiguration;
};

/** A list property that makes use of a string value */
export type StringListItemProperty = ListItemProperty & {
  __typename?: 'StringListItemProperty';
  /** The unique ID of the attribute for which this property exists */
  attributeId: Scalars['ID'];
  /** The string given */
  value?: Maybe<Scalars['String']>;
};

/** A part of a question that requires a single-line string response */
export type StringPart = QuestionPart & {
  __typename?: 'StringPart';
  /** The unique ID of this question part */
  id: Scalars['String'];
  /** Which type of part this is */
  type: QuestionPartType;
  /** The label to be shown for this part */
  label: Scalars['String'];
  /** Additional instructions and information to support the user */
  instructions?: Maybe<Scalars['String']>;
  /** Official advice for completing this question part */
  officialAdvice?: Maybe<Scalars['String']>;
  /** Additional advice for completing this question part */
  additionalAdvice?: Maybe<Scalars['String']>;
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** Whether or not this question part has been completed */
  isComplete: Scalars['Boolean'];
  /** The ordering index for use when showing this part alongside other parts of the same question */
  ordering: Scalars['Int'];
  /** The current draft answer for this question part */
  answerDraft?: Maybe<AnswerDraft>;
  /** The answer given to this question part */
  answer?: Maybe<Answer>;
  /** Whether or not this question part has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
  /** The minimum number of characters required in the response */
  minimumLength?: Maybe<Scalars['Int']>;
  /** The maximum number of characters allowed in the response */
  maximumLength?: Maybe<Scalars['Int']>;
  /** The configuration for this part */
  configuration: StringPartConfiguration;
};

/** The configuration for a part of a question that requires a single-line string response */
export type StringPartConfiguration = {
  __typename?: 'StringPartConfiguration';
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** The minimum number of characters required in the response */
  minimumLength?: Maybe<Scalars['Int']>;
  /** The maximum number of characters allowed in the response */
  maximumLength?: Maybe<Scalars['Int']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  /** Subscribe to all updates to firms */
  firmUpdate?: Maybe<Firm>;
  /** Subscribe to all updates to a firm member */
  firmMemberUpdate?: Maybe<FirmMember>;
  /** Subscribe to all new client accounts */
  newClientAccount?: Maybe<ClientAccount>;
  /** Subscribe to all client account updates */
  clientAccountUpdate?: Maybe<ClientAccount>;
  /** Subscribe to all updates to a client */
  clientUpdate?: Maybe<FirmClient>;
  /** Subscribe to all client assignment updates */
  clientAssignmentUpdate?: Maybe<ClientAssignment>;
  /** Subscribe to all updates to an issue */
  issueUpdate?: Maybe<Issue>;
  /** Subscribe to new tasks on a matter */
  newMatterTask?: Maybe<MatterTask>;
  /** Subscribe to all updates to a task */
  taskUpdate?: Maybe<Task>;
  /** Subscribe to all updates to a question */
  questionUpdate?: Maybe<Question>;
  /** Subscribe to all new messages in a conversation */
  newMessage?: Maybe<Message>;
  /** Subscribe to all message updates (read status, delivered status, etc) */
  messageUpdate?: Maybe<Message>;
  /** Subscribe to all new conversations involving the member */
  newConversation?: Maybe<Conversation>;
  /** Subscribe to all conversation updates (sent status, paused until, etc) */
  conversationUpdate?: Maybe<Conversation>;
  /** Subscribe to any updates to a participant's message draft */
  messageDraftUpdate?: Maybe<MessageDraft>;
  /** Subscribe to any changes to highlights */
  highlightChange: HighlightCountsPayload;
  /** Subscribe to all new notifications */
  newNotification?: Maybe<Notification>;
};


export type SubscriptionFirmUpdateArgs = {
  firmId?: Maybe<Scalars['ID']>;
};


export type SubscriptionFirmMemberUpdateArgs = {
  professionalId?: Maybe<Scalars['ID']>;
};


export type SubscriptionNewClientAccountArgs = {
  firmId?: Maybe<Scalars['ID']>;
};


export type SubscriptionClientAccountUpdateArgs = {
  firmId?: Maybe<Scalars['ID']>;
  clientAccountId?: Maybe<Scalars['ID']>;
};


export type SubscriptionClientUpdateArgs = {
  clientId?: Maybe<Scalars['ID']>;
};


export type SubscriptionClientAssignmentUpdateArgs = {
  professionalId?: Maybe<Scalars['ID']>;
  clientId?: Maybe<Scalars['ID']>;
};


export type SubscriptionIssueUpdateArgs = {
  issueId: Scalars['ID'];
};


export type SubscriptionNewMatterTaskArgs = {
  matterId: Scalars['ID'];
};


export type SubscriptionTaskUpdateArgs = {
  taskId: Scalars['ID'];
};


export type SubscriptionQuestionUpdateArgs = {
  questionId?: Maybe<Scalars['ID']>;
};


export type SubscriptionNewMessageArgs = {
  conversationId?: Maybe<Scalars['ID']>;
  memberId?: Maybe<Scalars['ID']>;
};


export type SubscriptionMessageUpdateArgs = {
  conversationId?: Maybe<Scalars['ID']>;
  memberId?: Maybe<Scalars['ID']>;
};


export type SubscriptionNewConversationArgs = {
  memberId?: Maybe<Scalars['ID']>;
};


export type SubscriptionConversationUpdateArgs = {
  memberId?: Maybe<Scalars['ID']>;
};


export type SubscriptionMessageDraftUpdateArgs = {
  conversationId: Scalars['ID'];
  participantId: Scalars['ID'];
};

/** A successful outcome of an authentication attempt */
export type SuccessfulAuthenticationPayload = AuthenticationPayload & {
  __typename?: 'SuccessfulAuthenticationPayload';
  /** Whether or not the authentication was successful */
  successful: Scalars['Boolean'];
  /** A message describing the authentication outcome */
  message: Scalars['String'];
  /** The access token to be used for future requests */
  accessToken: Scalars['String'];
  /** The ID token representing the user's identity */
  idToken?: Maybe<Scalars['String']>;
  /** The type of token that was issued */
  tokenType: Scalars['String'];
  /** The scope given to the access token */
  scope?: Maybe<Scalars['String']>;
  /** The number of seconds until the access token expires */
  expiresIn: Scalars['Int'];
  /** The user that has authenticated */
  user?: Maybe<User>;
};

/** A summary of all features for the current individual */
export type Summary = {
  __typename?: 'Summary';
  /** How many clients the individual has access to */
  clientsCount?: Maybe<Scalars['Int']>;
  /** How many clients the individual has access to that have unverified profiles */
  unverifiedClientsCount?: Maybe<Scalars['Int']>;
  /** The number of answers that require attention */
  pendingAnswersCount?: Maybe<Scalars['Int']>;
  /** The number of messages that are unread */
  unreadMessagesCount?: Maybe<Scalars['Int']>;
  /** The number of notes that are unread */
  unreadNotesCount?: Maybe<Scalars['Int']>;
  /** The number of key dates that occur in the next 7 days */
  upcomingKeyDatesCount?: Maybe<Scalars['Int']>;
};

/** An archive submitted in support of a matter */
export type SupportingArchive = SupportingFile & {
  __typename?: 'SupportingArchive';
  /** The unique ID of the supporting file */
  id: Scalars['ID'];
  /** Which method was used to add this supporting file to the matter */
  source: SupportingFileSource;
  /** The unique ID of the file that was added */
  fileId: Scalars['ID'];
  /** The version of the file that was added */
  fileVersion: Scalars['Int'];
  /** The name of the file */
  name: Scalars['String'];
  /** A simple file type, for example: document */
  type: Scalars['String'];
  /** The file's full MIME type (e.g. image/jpeg) */
  mime: Scalars['String'];
  /** The size of the file asset in bytes */
  size: Scalars['Int'];
};

/** A piece of audio submitted in support of a matter */
export type SupportingAudio = SupportingFile & {
  __typename?: 'SupportingAudio';
  /** The unique ID of the supporting file */
  id: Scalars['ID'];
  /** Which method was used to add this supporting file to the matter */
  source: SupportingFileSource;
  /** The unique ID of the file that was added */
  fileId: Scalars['ID'];
  /** The version of the file that was added */
  fileVersion: Scalars['Int'];
  /** The name of the file */
  name: Scalars['String'];
  /** A simple file type, for example: document */
  type: Scalars['String'];
  /** The file's full MIME type (e.g. image/jpeg) */
  mime: Scalars['String'];
  /** The size of the file asset in bytes */
  size: Scalars['Int'];
};

/** A document submitted in support of a matter */
export type SupportingDocument = SupportingFile & {
  __typename?: 'SupportingDocument';
  /** The unique ID of the supporting file */
  id: Scalars['ID'];
  /** Which method was used to add this supporting file to the matter */
  source: SupportingFileSource;
  /** The unique ID of the file that was added */
  fileId: Scalars['ID'];
  /** The version of the file that was added */
  fileVersion: Scalars['Int'];
  /** The name of the file */
  name: Scalars['String'];
  /** A simple file type, for example: document */
  type: Scalars['String'];
  /** The file's full MIME type (e.g. image/jpeg) */
  mime: Scalars['String'];
  /** The size of the file asset in bytes */
  size: Scalars['Int'];
};

/** A file added to a matter's supporting material */
export type SupportingFile = {
  /** The unique ID of the supporting file */
  id: Scalars['ID'];
  /** Which method was used to add this supporting file to the matter */
  source: SupportingFileSource;
  /** The unique ID of the file that was added */
  fileId: Scalars['ID'];
  /** The version of the file that was added */
  fileVersion: Scalars['Int'];
  /** The name of the file */
  name: Scalars['String'];
  /** A simple file type, for example: document */
  type: Scalars['String'];
  /** The file's full MIME type (e.g. image/jpeg) */
  mime: Scalars['String'];
  /** The size of the file asset in bytes */
  size: Scalars['Int'];
};

/** The result of removing a supporting file from a matter */
export type SupportingFileRemovedPayload = {
  __typename?: 'SupportingFileRemovedPayload';
  /** The unique ID of the removed supporting file */
  supportingFileId: Scalars['ID'];
  /** The date and time when the supporting file was removed */
  removedOn: Scalars['DateTime'];
};

export enum SupportingFileSource {
  Direct = 'DIRECT',
  Draft = 'DRAFT',
  Answer = 'ANSWER'
}

/** An image submitted in support of a matter */
export type SupportingImage = SupportingFile & {
  __typename?: 'SupportingImage';
  /** The unique ID of the supporting file */
  id: Scalars['ID'];
  /** Which method was used to add this supporting file to the matter */
  source: SupportingFileSource;
  /** The unique ID of the file that was added */
  fileId: Scalars['ID'];
  /** The version of the file that was added */
  fileVersion: Scalars['Int'];
  /** The name of the file */
  name: Scalars['String'];
  /** A simple file type, for example: document */
  type: Scalars['String'];
  /** The file's full MIME type (e.g. image/jpeg) */
  mime: Scalars['String'];
  /** The size of the file asset in bytes */
  size: Scalars['Int'];
  /** The height of the image in pixels */
  height?: Maybe<Scalars['Int']>;
  /** The width of the image in pixels */
  width?: Maybe<Scalars['Int']>;
};

/** Any of the items that have been collected in support of a matter */
export type SupportingMaterial = MatterTask | ConversationExtract | SupportingAudio | SupportingDocument | SupportingImage | SupportingVideo;

/** The result of starting a supporting material export */
export type SupportingMaterialExportStartedPayload = {
  __typename?: 'SupportingMaterialExportStartedPayload';
  /** The unique ID of the matter from which the supporting material is being exported */
  matterId: Scalars['ID'];
  /** A list of the specifically selected material IDs */
  materialIds?: Maybe<Array<Scalars['ID']>>;
  /** The date and time when the export was started */
  startedOn: Scalars['DateTime'];
};

/** A piece of video submitted in support of a matter */
export type SupportingVideo = SupportingFile & {
  __typename?: 'SupportingVideo';
  /** The unique ID of the supporting file */
  id: Scalars['ID'];
  /** Which method was used to add this supporting file to the matter */
  source: SupportingFileSource;
  /** The unique ID of the file that was added */
  fileId: Scalars['ID'];
  /** The version of the file that was added */
  fileVersion: Scalars['Int'];
  /** The name of the file */
  name: Scalars['String'];
  /** A simple file type, for example: document */
  type: Scalars['String'];
  /** The file's full MIME type (e.g. image/jpeg) */
  mime: Scalars['String'];
  /** The size of the file asset in bytes */
  size: Scalars['Int'];
};

/** A single task */
export type Task = {
  /** The unique ID of this task */
  id: Scalars['ID'];
  /** The name given to this task */
  name: Scalars['String'];
  /** A detailed description this task */
  description?: Maybe<Scalars['String']>;
  /** The key date by which this task is due to be completed */
  dueDate?: Maybe<KeyDate>;
  /** Official advice for completing this task */
  officialAdvice?: Maybe<Scalars['String']>;
  /** Additional advice for completing this task */
  additionalAdvice?: Maybe<Scalars['String']>;
  /** The sections this task is divided in to */
  sections: Array<TaskSection>;
  /** The number of sections in this task */
  sectionCount: Scalars['Int'];
  /** Whether or not the structure of this task can be modified when being used */
  isModifiable: Scalars['Boolean'];
  /** Whether or not this task has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
};

/** The result of removing a task */
export type TaskRemovedPayload = {
  __typename?: 'TaskRemovedPayload';
  /** The unique ID of the removed task */
  taskId: Scalars['ID'];
  /** The date and time when the task was removed */
  removedOn: Scalars['DateTime'];
};

/** A section of a task */
export type TaskSection = {
  __typename?: 'TaskSection';
  /** The unique ID of this section */
  id: Scalars['ID'];
  /** The unique ID of the task for which this is a section */
  taskId: Scalars['ID'];
  /** The ordering index for use when showing this section alongside other sections of the same task */
  ordering: Scalars['Int'];
  /** The name given to this section */
  name: Scalars['String'];
  /** A description of the section's content */
  description?: Maybe<Scalars['String']>;
  /** The key date by which this task section is due to be completed */
  dueDate?: Maybe<KeyDate>;
  /** Official advice for completing this task section */
  officialAdvice?: Maybe<Scalars['String']>;
  /** Additional advice for completing this task section */
  additionalAdvice?: Maybe<Scalars['String']>;
  /** The questions in this task */
  questions: Array<Question>;
  /** The number of questions in this section */
  questionCount: Scalars['Int'];
  /** How much of this section has been released to the client, as a decimal percentage */
  proportionReleased: Scalars['Float'];
  /** Whether or not this section has been completed */
  isComplete: Scalars['Boolean'];
  /** How much of this section has been completed, as a decimal percentage */
  proportionComplete: Scalars['Float'];
  /** Whether or not this section has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
};

export enum TaskStatus {
  ToDo = 'TO_DO',
  InProgress = 'IN_PROGRESS',
  Done = 'DONE'
}

/** A task that can be used as a template for other tasks */
export type TemplateTask = {
  /** The unique ID of this task */
  id: Scalars['ID'];
  /** The name given to this task */
  name: Scalars['String'];
  /** A detailed description this task */
  description?: Maybe<Scalars['String']>;
  /** Official advice for completing this task */
  officialAdvice?: Maybe<Scalars['String']>;
  /** Additional advice for completing this task */
  additionalAdvice?: Maybe<Scalars['String']>;
  /** The sections this task is divided in to */
  sections: Array<TaskSection>;
  /** The number of sections in this task */
  sectionCount: Scalars['Int'];
  /** The types of matter for which this task should be used. If empty, this is a generally available task. */
  matterTypes?: Maybe<Array<MatterType>>;
  /** Whether or not the structure of this task can be modified when being used */
  isModifiable: Scalars['Boolean'];
  /** Whether or not this template task has been published for use */
  isPublished: Scalars['Boolean'];
  /** Whether or not this task has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
};

/** An answer to a part of a question that requires a text response */
export type TextAnswer = Answer & {
  __typename?: 'TextAnswer';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The part of a question for which this is an answer */
  questionPart: QuestionPart;
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  proposedOn: Scalars['DateTime'];
  /** Whether or not this answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The revision history of this answer */
  history: Array<AnswerRevision>;
  /** The current status of this answer */
  status: AnswerStatus;
  /** Whether or not this answer has been accepted by a professional */
  isAcceptedByProfessional: Scalars['Boolean'];
  /** Whether or not this answer has been accepted by a client */
  isAcceptedByClient: Scalars['Boolean'];
  /** Whether or not this question part has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
  /** The text given for the answer */
  currentValue?: Maybe<Scalars['String']>;
};

/** A draft answer to a part of a question that requires a text response */
export type TextAnswerDraft = AnswerDraft & {
  __typename?: 'TextAnswerDraft';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The part of a question for which this is an answer */
  questionPart: QuestionPart;
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  startedOn: Scalars['DateTime'];
  /** Whether or not this draft of the answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The text given for the answer */
  currentValue?: Maybe<Scalars['String']>;
};

/** A revision to an answer to a part of a question that requires a text response */
export type TextAnswerRevision = AnswerRevision & {
  __typename?: 'TextAnswerRevision';
  /** The unique ID of this answer */
  id: Scalars['ID'];
  /** The proposer of this answer */
  proposer: Proposer;
  /** The date and time when this answer was proposed */
  proposedOn: Scalars['DateTime'];
  /** Whether or not this revision of the answer was marked as "not applicable" */
  notApplicable: Scalars['Boolean'];
  /** The text given for the answer */
  value?: Maybe<Scalars['String']>;
};

/** An attribute of a list part that uses a multi-line text value */
export type TextAttribute = ListAttribute & {
  __typename?: 'TextAttribute';
  /** The unique ID of this attribute */
  id: Scalars['String'];
  /** Which type of attribute this is */
  type: QuestionPartType;
  /** The label to be shown for this attribute */
  label: Scalars['String'];
  /** Additional instructions and information to support the user */
  instructions?: Maybe<Scalars['String']>;
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** The ordering index for use when showing this attribute alongside other attributes of the same part */
  ordering: Scalars['Int'];
  /** The minimum number of characters required in the response */
  minimumLength?: Maybe<Scalars['Int']>;
  /** The maximum number of characters allowed in the response */
  maximumLength?: Maybe<Scalars['Int']>;
  /** The configuration for this part */
  configuration: TextPartConfiguration;
};

/** A list property that makes use of a text value */
export type TextListItemProperty = ListItemProperty & {
  __typename?: 'TextListItemProperty';
  /** The unique ID of the attribute for which this property exists */
  attributeId: Scalars['ID'];
  /** The text given */
  value?: Maybe<Scalars['String']>;
};

/** A part of a question that requires a multi-line text response */
export type TextPart = QuestionPart & {
  __typename?: 'TextPart';
  /** The unique ID of this question part */
  id: Scalars['String'];
  /** Which type of part this is */
  type: QuestionPartType;
  /** The label to be shown for this part */
  label: Scalars['String'];
  /** Additional instructions and information to support the user */
  instructions?: Maybe<Scalars['String']>;
  /** Official advice for completing this question part */
  officialAdvice?: Maybe<Scalars['String']>;
  /** Additional advice for completing this question part */
  additionalAdvice?: Maybe<Scalars['String']>;
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** Whether or not this question part has been completed */
  isComplete: Scalars['Boolean'];
  /** The ordering index for use when showing this part alongside other parts of the same question */
  ordering: Scalars['Int'];
  /** The current draft answer for this question part */
  answerDraft?: Maybe<AnswerDraft>;
  /** The answer given to this question part */
  answer?: Maybe<Answer>;
  /** Whether or not this question part has been highlighted for the current user */
  isHighlighted: Scalars['Boolean'];
  /** The minimum number of characters required in the response */
  minimumLength?: Maybe<Scalars['Int']>;
  /** The maximum number of characters allowed in the response */
  maximumLength?: Maybe<Scalars['Int']>;
  /** The configuration for this part */
  configuration: TextPartConfiguration;
};

/** The configuration for a part of a question that requires a multi-line text response */
export type TextPartConfiguration = {
  __typename?: 'TextPartConfiguration';
  /** Whether or not an answer to this question part is required */
  isRequired: Scalars['Boolean'];
  /** The minimum number of characters required in the response */
  minimumLength?: Maybe<Scalars['Int']>;
  /** The maximum number of characters allowed in the response */
  maximumLength?: Maybe<Scalars['Int']>;
};

/** A single topic that can be attributed to other content */
export type Topic = {
  /** The unique ID of the topic */
  id: Scalars['ID'];
  /** The name given to the topic by its creator */
  name: Scalars['String'];
  /** A unique, URL-safe version of the topic name */
  slug: Scalars['String'];
  /** The colour name given to the topic by its creator */
  colour: Scalars['String'];
};

/** The result of removing a topic */
export type TopicPayload = {
  __typename?: 'TopicPayload';
  /** The unique ID of the removed topic */
  topicId: Scalars['ID'];
  /** The date and time when the topic was removed */
  removedOn: Scalars['DateTime'];
};

export type UnreleaseQuestionInput = {
  /** The unique ID of the question to be unreleased */
  questionId: Scalars['ID'];
};

export type UnreleaseTaskInput = {
  /** The unique ID of the task to which the questions to be unreleased belong */
  taskId: Scalars['ID'];
};

export type UnreleaseTaskSectionInput = {
  /** The unique ID of the task to which the section to be unreleased belongs */
  taskId: Scalars['ID'];
  /** The unique ID of the task section to be unreleased */
  sectionId: Scalars['ID'];
};

export type UpdateClientAccountInput = {
  /** The unique ID of the client account */
  clientAccountId: Scalars['ID'];
  /** A custom reference number or code for this account */
  referenceNumber?: Maybe<Scalars['String']>;
  /** The unique ID of the professional who will lead on this account */
  leadProfessionalId?: Maybe<Scalars['ID']>;
};

export type UpdateClientAddressProofInput = {
  /** The unique ID of the client */
  clientId: Scalars['ID'];
  /** The various forms of address proof */
  proof?: Maybe<Array<AddressProofInput>>;
};

export type UpdateClientAddressesInput = {
  /** The unique ID of the client */
  clientId: Scalars['ID'];
  /** The client's home address */
  homeAddress?: Maybe<AddressInput>;
  /** The client's previous home addresses */
  previousHomeAddresses?: Maybe<Array<AddressInput>>;
  /** The client's work address */
  workAddress?: Maybe<AddressInput>;
};

export type UpdateClientBankDetailsInput = {
  /** The unique ID of the client */
  clientId: Scalars['ID'];
  /** The name of the bank */
  name?: Maybe<Scalars['String']>;
  /** The address of the branch associated with the account */
  branchAddress?: Maybe<AddressInput>;
  /** The bank account number */
  accountNumber?: Maybe<Scalars['String']>;
  /** The bank account sort code */
  sortCode?: Maybe<Scalars['String']>;
};

export type UpdateClientContactInformationInput = {
  /** The unique ID of the client */
  clientId: Scalars['ID'];
  /** The client's email address */
  emailAddress?: Maybe<Scalars['EmailAddress']>;
  /** The client's mobile phone number */
  mobilePhoneNumber?: Maybe<Scalars['String']>;
  /** The client's home phone number */
  homePhoneNumber?: Maybe<Scalars['String']>;
  /** The client's work phone number */
  workPhoneNumber?: Maybe<Scalars['String']>;
};

export type UpdateClientIdentityProofInput = {
  /** The unique ID of the client */
  clientId: Scalars['ID'];
  /** The various forms of identity proof */
  proof?: Maybe<Array<IdentityProofInput>>;
};

export type UpdateClientPersonalDetailsInput = {
  /** The unique ID of the client */
  clientId: Scalars['ID'];
  /** The client's preferred name */
  preferredName?: Maybe<Scalars['String']>;
  /** The client's first name */
  firstName?: Maybe<Scalars['String']>;
  /** The client's middle name(s) */
  middleNames?: Maybe<Scalars['String']>;
  /** The client's last name */
  lastName?: Maybe<Scalars['String']>;
  /** The client's previous first name */
  previousFirstName?: Maybe<Scalars['String']>;
  /** The client's previous middle name(s) */
  previousMiddleNames?: Maybe<Scalars['String']>;
  /** The client's previous last name */
  previousLastName?: Maybe<Scalars['String']>;
  /** A picture of the client */
  picture?: Maybe<Scalars['Upload']>;
  /** The client's date of birth */
  birthDate?: Maybe<Scalars['DateTime']>;
  /** The client's national insurance number without spaces */
  nationalInsuranceNumber?: Maybe<Scalars['String']>;
};

export type UpdateContactInput = {
  /** The unique ID of the contact being updated */
  contactId?: Maybe<Scalars['ID']>;
  /** The verification code issued when the contact was first added */
  verificationCode?: Maybe<Scalars['String']>;
  /** Which kind of contact this is */
  type?: Maybe<ContactType>;
  /** The contact's last name */
  firstName?: Maybe<Scalars['String']>;
  /** The contact's last name */
  lastName?: Maybe<Scalars['String']>;
  /** The contact's email address. Their verification code will be sent here. */
  emailAddress?: Maybe<Scalars['EmailAddress']>;
};

export type UpdateCourtCaseInput = {
  /** The unique ID of the court case being updated */
  courtCaseId: Scalars['ID'];
  /** The name of the court in which this case will be heard */
  courtName?: Maybe<Scalars['String']>;
  /** The case number given to this case by the courts (if known) */
  caseNumber?: Maybe<Scalars['String']>;
  /** The date/time when the case will be heard in court, or the ID of an existing key date (if known) */
  hearingDate?: Maybe<Scalars['String']>;
};

export type UpdateFirmDetailsInput = {
  /** The firm's unique ID */
  id: Scalars['ID'];
  /** The firm's company registration number */
  companyNumber?: Maybe<Scalars['String']>;
  /** The firm's SRA number */
  sraNumber?: Maybe<Scalars['String']>;
  /** The firm's official name */
  name?: Maybe<Scalars['String']>;
  /** The firm's official name */
  logo?: Maybe<Scalars['Upload']>;
  /** The number of seats available to the firm */
  seatCount?: Maybe<Scalars['Int']>;
};

export type UpdateFirmMemberInput = {
  /** The unique ID of the firm member */
  firmMemberId: Scalars['ID'];
  /** An optional employee reference number */
  employeeNumber?: Maybe<Scalars['String']>;
  /** The firm member's formal job title */
  jobTitle?: Maybe<Scalars['String']>;
  /** The name or location of the office in which the firm member works */
  office?: Maybe<Scalars['String']>;
  /** The role the professional should take in the firm */
  role?: Maybe<FirmRole>;
};

export type UpdateProfessionalInput = {
  /** The unique ID of the professional */
  professionalId: Scalars['ID'];
  /** Which type of professional is being added to the firm */
  professionalType?: Maybe<FirmProfessionalType>;
  /** The SRA number of the professional (if applicable) */
  sraNumber?: Maybe<Scalars['String']>;
  /** The paralegal number of the professional (if applicable) */
  paralegalNumber?: Maybe<Scalars['String']>;
  /** The professional's preferred first name */
  preferredName?: Maybe<Scalars['String']>;
  /** The professional's first name */
  firstName?: Maybe<Scalars['String']>;
  /** The client's middle name(s) */
  middleNames?: Maybe<Scalars['String']>;
  /** The professional's last name */
  lastName?: Maybe<Scalars['String']>;
  /** The contact email address of the professional */
  emailAddress?: Maybe<Scalars['EmailAddress']>;
  /** The professional's work phone number */
  workPhoneNumber?: Maybe<Scalars['String']>;
  /** A picture of the professional */
  picture?: Maybe<Scalars['Upload']>;
};

export type UpdateTopicInput = {
  /** The unique ID of the topic to be renamed */
  topicId: Scalars['ID'];
  /** The new display name for the topic */
  name?: Maybe<Scalars['String']>;
  /** The new display colour for the topic */
  colour?: Maybe<Scalars['String']>;
};

export type UpdateUserAccessInput = {
  /** The unique ID of the user being updated */
  id: Scalars['ID'];
  /** Whether or not this user is blocked */
  isBlocked?: Maybe<Scalars['Boolean']>;
};

export type UpdateUserInput = {
  /** The unique ID of the user being updated */
  id: Scalars['ID'];
  /** Change any part of the user's name */
  rename?: Maybe<RenameUserInput>;
  /** Change the user's email address */
  changeEmail?: Maybe<ChangeUserEmailInput>;
  /** Change the user's mobile phone number */
  changePhone?: Maybe<ChangeUserPhoneInput>;
  /** Change the user's picture */
  changePicture?: Maybe<Scalars['Upload']>;
};


/** A single registered user */
export type User = {
  __typename?: 'User';
  /** Whether or not this user is blocked and therefore cannot log in */
  blocked: Scalars['Boolean'];
  /** The email address used to identify and communicate with the user */
  email: Scalars['String'];
  /** Whether or not the email address has been verified */
  emailVerified: Scalars['Boolean'];
  /** The user's last name */
  familyName: Scalars['String'];
  /** The user's full name */
  fullName: Scalars['String'];
  /** The user's first name */
  givenName: Scalars['String'];
  /** The unique ID for this role */
  id: Scalars['ID'];
  /** The user's middle name(s) */
  middleName?: Maybe<Scalars['String']>;
  /** The phone number used to help identify the user */
  phone: Scalars['String'];
  /** The URL of the user's picture */
  picture: Scalars['String'];
  /** The user's preferred name */
  preferredName: Scalars['String'];
  /** The unique reference number for this user (if applicable) */
  referenceNumber?: Maybe<Scalars['String']>;
  /** All the roles that have been assigned to the user */
  roles: Array<Role>;
  /** Which type of user this is */
  type: UserType;
};

export type UserInput = {
  /** The unique ID for this role */
  id: Scalars['ID'];
  /** The user's preferred name */
  preferredName: Scalars['String'];
  /** The user's first name */
  givenName: Scalars['String'];
  /** The user's middle name(s) */
  middleName?: Maybe<Scalars['String']>;
  /** The user's last name */
  familyName: Scalars['String'];
  /** The URL of the user's picture */
  picture: Scalars['String'];
  /** The email address used to identify and communicate with the user */
  email: Scalars['String'];
  /** The phone number used to help identify the user */
  phone: Scalars['String'];
  /** Whether or not this user is blocked and therefore cannot log in */
  blocked: Scalars['Boolean'];
  /** The unique reference number for this user (if applicable) */
  referenceNumber?: Maybe<Scalars['String']>;
  /** All the roles that have been assigned to the user */
  roles: RoleInput;
};

export type UserList = {
  __typename?: 'UserList';
  /** List of items on the current page */
  items?: Maybe<Array<Maybe<User>>>;
  /** Number of total items selected by the query */
  total: Scalars['Int'];
  /** Number of items returned per page */
  perPage: Scalars['Int'];
  /** Current page of the cursor */
  currentPage: Scalars['Int'];
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']>;
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']>;
  /** The last page (number of pages) */
  lastPage: Scalars['Int'];
  /** Determines if cursor has more pages after the current page */
  hasMorePages: Scalars['Boolean'];
};

/** The result of a user being removed */
export type UserRemovedPayload = {
  __typename?: 'UserRemovedPayload';
  /** The ID of the user that was just removed */
  userId: Scalars['ID'];
  /** The date and time when removal took place */
  removedAt: Scalars['DateTime'];
};

export enum UserType {
  Internal = 'INTERNAL',
  Client = 'CLIENT',
  Professional = 'PROFESSIONAL'
}

export type VerifyClientInput = {
  /** The unique ID of the client */
  clientId: Scalars['ID'];
};

export type VerifyContactInput = {
  /** The verification code issued when the contact was first added */
  verificationCode: Scalars['String'];
};

export type VerifyEmailAddressInput = {
  /** The email verification token issued to the user */
  token: Scalars['String'];
};

/** A piece of video attached to an answer */
export type VideoAnswerAttachment = AnswerAttachment & {
  __typename?: 'VideoAnswerAttachment';
  /** The unique ID of this attachment */
  id: Scalars['ID'];
  /** The unique ID of the file revision that was originally attached */
  fileRevisionId: Scalars['ID'];
  /** The original name of the asset used to revise the file */
  originalName: Scalars['String'];
  /** A simple file type, for example: document */
  type: Scalars['String'];
  /** The file's full MIME type (e.g. image/jpeg) */
  mime: Scalars['String'];
  /** The size of the file asset in bytes */
  size: Scalars['Int'];
};

/** A single uploaded piece of video */
export type VideoFile = File & {
  __typename?: 'VideoFile';
  /** The unique ID for the file */
  id: Scalars['ID'];
  /** The folder's owner */
  owner?: Maybe<FileOwner>;
  /** The folder in which the file is placed */
  folder: Folder;
  /** The name given to the file */
  name: Scalars['String'];
  /** What type of file this is (e.g. Document, Image, etc.) */
  type: Scalars['String'];
  /** The file's full MIME type (e.g. image/jpeg) */
  mime: Scalars['String'];
  /** The size of the raw file, in bytes */
  size: Scalars['Int'];
  /** The files current version number */
  version: Scalars['Int'];
  /** The ID of the revision that represents the current version of this file */
  currentRevisionId: Scalars['ID'];
  /** The date and time when this file was last revised */
  lastRevisedOn: Scalars['DateTime'];
  /** The history of revisions made to this file */
  revisions: Array<FileRevision>;
  /** The topics associated with this file by its owner */
  topics: Array<Topic>;
  /** The height of the video in pixels */
  height?: Maybe<Scalars['Int']>;
  /** The width of the video in pixels */
  width?: Maybe<Scalars['Int']>;
  /** The orientation of the video, based on it's dimensions */
  orientation?: Maybe<Scalars['String']>;
  /** The duration of the video in seconds */
  duration?: Maybe<Scalars['Int']>;
};

/** A version of a piece of video */
export type VideoFileRevision = FileRevision & {
  __typename?: 'VideoFileRevision';
  /** The unique ID of this file */
  id: Scalars['ID'];
  /** The revision number of this file */
  version: Scalars['Int'];
  /** The original name of the asset used to revise the file */
  originalName: Scalars['String'];
  /** A simple file type, for example: document */
  type: Scalars['String'];
  /** The file's full MIME type (e.g. image/jpeg) */
  mime: Scalars['String'];
  /** The size of the file asset in bytes */
  size: Scalars['Int'];
  /** The date and time when this file was revised */
  revisedOn: Scalars['DateTime'];
  /** The height of the video in pixels */
  height?: Maybe<Scalars['Int']>;
  /** The width of the video in pixels */
  width?: Maybe<Scalars['Int']>;
  /** The orientation of the video, based on it's dimensions */
  orientation?: Maybe<Scalars['String']>;
  /** The duration of the video in seconds */
  duration?: Maybe<Scalars['Int']>;
};

export type UpdateBreadcrumbsMutationVariables = Exact<{
  breadcrumbs: Array<Maybe<BreadcrumbInput>> | Maybe<BreadcrumbInput>;
}>;


export type UpdateBreadcrumbsMutation = (
  { __typename?: 'Mutation' }
  & { updateBreadcrumbs?: Maybe<Array<Maybe<(
    { __typename?: 'Breadcrumb' }
    & Pick<Breadcrumb, 'name' | 'to' | 'image'>
  )>>> }
);

export type SetupClientAccountMutationVariables = Exact<{
  input: SetupClientAccountInput;
}>;


export type SetupClientAccountMutation = (
  { __typename?: 'Mutation' }
  & { setupClientAccount?: Maybe<(
    { __typename?: 'ClientAccount' }
    & Pick<ClientAccount, 'id' | 'status' | 'referenceNumber'>
    & { client: (
      { __typename?: 'FirmClient' }
      & Pick<FirmClient, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl' | 'emailAddress' | 'mobilePhoneNumber' | 'nationalInsuranceNumber' | 'status' | 'referenceNumber'>
    ), leadProfessional: (
      { __typename?: 'FirmBarrister' }
      & Pick<FirmBarrister, 'id' | 'preferredName' | 'firstName' | 'lastName'>
    ) | (
      { __typename?: 'FirmParalegal' }
      & Pick<FirmParalegal, 'id' | 'preferredName' | 'firstName' | 'lastName'>
    ) | (
      { __typename?: 'FirmSolicitor' }
      & Pick<FirmSolicitor, 'id' | 'preferredName' | 'firstName' | 'lastName'>
    ) | (
      { __typename?: 'FirmSupport' }
      & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'lastName'>
    ), firm: (
      { __typename?: 'Firm' }
      & Pick<Firm, 'id' | 'name'>
    ) }
  )> }
);

export type AssignFirmMemberToClientMutationVariables = Exact<{
  input: AssignFirmMemberToClientInput;
}>;


export type AssignFirmMemberToClientMutation = (
  { __typename?: 'Mutation' }
  & { assignFirmMemberToClient?: Maybe<(
    { __typename?: 'ClientAssignment' }
    & Pick<ClientAssignment, 'id' | 'isLead' | 'assignedOn'>
    & { firmMember: (
      { __typename?: 'FirmMember' }
      & Pick<FirmMember, 'id' | 'employeeNumber' | 'jobTitle' | 'office' | 'isActive' | 'isHighlighted' | 'activeSince' | 'role' | 'status'>
      & { professional: (
        { __typename?: 'FirmBarrister' }
        & Pick<FirmBarrister, 'sraNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'status' | 'referenceNumber'>
      ) | (
        { __typename?: 'FirmParalegal' }
        & Pick<FirmParalegal, 'paralegalNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'status' | 'referenceNumber'>
      ) | (
        { __typename?: 'FirmSolicitor' }
        & Pick<FirmSolicitor, 'sraNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'status' | 'referenceNumber'>
      ) | (
        { __typename?: 'FirmSupport' }
        & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'status' | 'referenceNumber'>
      ), firm: (
        { __typename?: 'Firm' }
        & Pick<Firm, 'id' | 'name' | 'logoUrl'>
      ) }
    ), clientAccount: (
      { __typename?: 'ClientAccount' }
      & Pick<ClientAccount, 'id' | 'isHighlighted' | 'status' | 'referenceNumber'>
      & { client: (
        { __typename?: 'FirmClient' }
        & Pick<FirmClient, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl' | 'emailAddress' | 'mobilePhoneNumber' | 'nationalInsuranceNumber' | 'isVerified' | 'profileIsComplete' | 'status' | 'referenceNumber'>
        & { issues: Array<(
          { __typename?: 'Issue' }
          & Pick<Issue, 'id' | 'type' | 'isLinked' | 'linkCode' | 'linkRequested'>
          & { opponentIssue?: Maybe<(
            { __typename?: 'Issue' }
            & Pick<Issue, 'id'>
            & { legalTeam: Array<(
              { __typename?: 'LegalTeamMember' }
              & Pick<LegalTeamMember, 'id' | 'jobTitle' | 'isLead'>
              & { firm: (
                { __typename?: 'Firm' }
                & Pick<Firm, 'id' | 'name'>
              ), professional: (
                { __typename?: 'FirmBarrister' }
                & Pick<FirmBarrister, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
              ) | (
                { __typename?: 'FirmParalegal' }
                & Pick<FirmParalegal, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
              ) | (
                { __typename?: 'FirmSolicitor' }
                & Pick<FirmSolicitor, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
              ) | (
                { __typename?: 'FirmSupport' }
                & Pick<FirmSupport, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
              ) }
            )> }
          )>, opponent?: Maybe<(
            { __typename?: 'Opponent' }
            & Pick<Opponent, 'preferredName' | 'firstName' | 'lastName' | 'emailAddress' | 'nationalInsuranceNumber' | 'clientReferenceNumber' | 'isVerified'>
            & { solicitor: (
              { __typename?: 'OpponentSolicitor' }
              & Pick<OpponentSolicitor, 'firstName' | 'lastName' | 'emailAddress' | 'firmName'>
            ) }
          )> }
        )> }
      ), leadProfessional: (
        { __typename?: 'FirmBarrister' }
        & Pick<FirmBarrister, 'id' | 'preferredName' | 'firstName' | 'lastName'>
      ) | (
        { __typename?: 'FirmParalegal' }
        & Pick<FirmParalegal, 'id' | 'preferredName' | 'firstName' | 'lastName'>
      ) | (
        { __typename?: 'FirmSolicitor' }
        & Pick<FirmSolicitor, 'id' | 'preferredName' | 'firstName' | 'lastName'>
      ) | (
        { __typename?: 'FirmSupport' }
        & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'lastName'>
      ), firm: (
        { __typename?: 'Firm' }
        & Pick<Firm, 'id' | 'name'>
      ) }
    ) }
  )> }
);

export type RevokeClientAssignmentMutationVariables = Exact<{
  input: RevokeClientAssignmentInput;
}>;


export type RevokeClientAssignmentMutation = (
  { __typename?: 'Mutation' }
  & { revokeClientAssignment?: Maybe<(
    { __typename?: 'ClientAssignmentRevokedPayload' }
    & Pick<ClientAssignmentRevokedPayload, 'clientAssignmentId' | 'revokedOn'>
  )> }
);

export type UpdateClientPersonalDetailsMutationVariables = Exact<{
  input: UpdateClientPersonalDetailsInput;
}>;


export type UpdateClientPersonalDetailsMutation = (
  { __typename?: 'Mutation' }
  & { updateClientPersonalDetails?: Maybe<(
    { __typename?: 'FirmClient' }
    & Pick<FirmClient, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'previousFirstName' | 'previousLastName' | 'previousMiddleNames' | 'middleNames' | 'birthDate' | 'nationalInsuranceNumber' | 'profileIsComplete'>
  )> }
);

export type UpdateClientContactInformationMutationVariables = Exact<{
  input: UpdateClientContactInformationInput;
}>;


export type UpdateClientContactInformationMutation = (
  { __typename?: 'Mutation' }
  & { updateClientContactInformation?: Maybe<(
    { __typename?: 'FirmClient' }
    & Pick<FirmClient, 'id' | 'fullName' | 'emailAddress' | 'mobilePhoneNumber' | 'nationalInsuranceNumber' | 'homePhoneNumber' | 'workPhoneNumber' | 'profileIsComplete'>
  )> }
);

export type UpdateClientAddressesMutationVariables = Exact<{
  input: UpdateClientAddressesInput;
}>;


export type UpdateClientAddressesMutation = (
  { __typename?: 'Mutation' }
  & { updateClientAddresses?: Maybe<(
    { __typename?: 'FirmClient' }
    & Pick<FirmClient, 'id' | 'profileIsComplete'>
    & { homeAddress?: Maybe<(
      { __typename?: 'Address' }
      & AddressFieldsFragment
    )>, previousHomeAddresses?: Maybe<Array<(
      { __typename?: 'Address' }
      & AddressFieldsFragment
    )>>, workAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'name'>
      & AddressFieldsFragment
    )> }
  )> }
);

export type AddressFieldsFragment = (
  { __typename?: 'Address' }
  & Pick<Address, 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
);

export type UpdateClientIdentityProofMutationVariables = Exact<{
  input: UpdateClientIdentityProofInput;
}>;


export type UpdateClientIdentityProofMutation = (
  { __typename?: 'Mutation' }
  & { updateClientIdentityProof?: Maybe<(
    { __typename?: 'FirmClient' }
    & Pick<FirmClient, 'id' | 'name' | 'profileIsComplete'>
    & { identityProof?: Maybe<Array<(
      { __typename?: 'IdentityProof' }
      & Pick<IdentityProof, 'id' | 'type' | 'reference' | 'assetUrl' | 'country' | 'expiresAt' | 'assetFileId' | 'assetMime' | 'assetFileVersion'>
    )>> }
  )> }
);

export type UpdateClientAddressProofMutationVariables = Exact<{
  input: UpdateClientAddressProofInput;
}>;


export type UpdateClientAddressProofMutation = (
  { __typename?: 'Mutation' }
  & { updateClientAddressProof?: Maybe<(
    { __typename?: 'FirmClient' }
    & Pick<FirmClient, 'id' | 'name' | 'profileIsComplete'>
    & { addressProof?: Maybe<Array<(
      { __typename?: 'AddressProof' }
      & Pick<AddressProof, 'id' | 'type' | 'reference' | 'assetUrl' | 'country' | 'expiresAt' | 'assetFileId' | 'assetMime' | 'assetFileVersion'>
    )>> }
  )> }
);

export type UpdateClientBankDetailsMutationVariables = Exact<{
  input: UpdateClientBankDetailsInput;
}>;


export type UpdateClientBankDetailsMutation = (
  { __typename?: 'Mutation' }
  & { updateClientBankDetails?: Maybe<(
    { __typename?: 'FirmClient' }
    & Pick<FirmClient, 'id' | 'profileIsComplete'>
    & { bankDetails?: Maybe<(
      { __typename?: 'BankDetails' }
      & Pick<BankDetails, 'name' | 'accountNumber' | 'sortCode'>
      & { branchAddress?: Maybe<(
        { __typename?: 'Address' }
        & AddressFieldsFragment
      )> }
    )> }
  )> }
);

export type VerifyClientMutationVariables = Exact<{
  input: VerifyClientInput;
}>;


export type VerifyClientMutation = (
  { __typename?: 'Mutation' }
  & { verifyClient?: Maybe<(
    { __typename?: 'FirmClient' }
    & Pick<FirmClient, 'id' | 'name' | 'isVerified' | 'verifiedAt'>
  )> }
);

export type ResendClientInviteMutationVariables = Exact<{
  input: ResendClientInviteInput;
}>;


export type ResendClientInviteMutation = (
  { __typename?: 'Mutation' }
  & { resendClientInvite?: Maybe<(
    { __typename?: 'ClientAccount' }
    & Pick<ClientAccount, 'id'>
  )> }
);

export type ApproveClientAccountMutationVariables = Exact<{
  input: ApproveClientAccountInput;
}>;


export type ApproveClientAccountMutation = (
  { __typename?: 'Mutation' }
  & { approveClientAccount?: Maybe<(
    { __typename?: 'ClientAccount' }
    & Pick<ClientAccount, 'id' | 'status' | 'referenceNumber'>
    & { client: (
      { __typename?: 'FirmClient' }
      & Pick<FirmClient, 'id' | 'name' | 'status'>
    ) }
  )> }
);

export type ChangeClientAccountActivityMutationVariables = Exact<{
  input: ChangeClientAccountActivityInput;
}>;


export type ChangeClientAccountActivityMutation = (
  { __typename?: 'Mutation' }
  & { changeClientAccountActivity?: Maybe<(
    { __typename?: 'ClientAccount' }
    & Pick<ClientAccount, 'id' | 'status' | 'referenceNumber'>
    & { client: (
      { __typename?: 'FirmClient' }
      & Pick<FirmClient, 'id' | 'name' | 'status'>
    ) }
  )> }
);

export type UpdateClientAccountMutationVariables = Exact<{
  input: UpdateClientAccountInput;
}>;


export type UpdateClientAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateClientAccount?: Maybe<(
    { __typename?: 'ClientAccount' }
    & Pick<ClientAccount, 'id' | 'referenceNumber'>
    & { leadProfessional: (
      { __typename?: 'FirmBarrister' }
      & Pick<FirmBarrister, 'id' | 'preferredName' | 'firstName' | 'lastName'>
    ) | (
      { __typename?: 'FirmParalegal' }
      & Pick<FirmParalegal, 'id' | 'preferredName' | 'firstName' | 'lastName'>
    ) | (
      { __typename?: 'FirmSolicitor' }
      & Pick<FirmSolicitor, 'id' | 'preferredName' | 'firstName' | 'lastName'>
    ) | (
      { __typename?: 'FirmSupport' }
      & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'lastName'>
    ) }
  )> }
);

export type VerifyEmailAddressMutationVariables = Exact<{
  input: VerifyEmailAddressInput;
}>;


export type VerifyEmailAddressMutation = (
  { __typename?: 'Mutation' }
  & { verifyEmailAddress?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'fullName' | 'email'>
  )> }
);

export type AddContactMutationVariables = Exact<{
  input: AddContactInput;
}>;


export type AddContactMutation = (
  { __typename?: 'Mutation' }
  & { addContact?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'emailAddress' | 'type' | 'verificationCode' | 'conversationId'>
  )> }
);

export type VerifyContactMutationVariables = Exact<{
  input: VerifyContactInput;
}>;


export type VerifyContactMutation = (
  { __typename?: 'Mutation' }
  & { verifyContact?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'fullName' | 'isTransparentlyUser' | 'isVerified'>
  )> }
);

export type UpdateContactMutationVariables = Exact<{
  input: UpdateContactInput;
}>;


export type UpdateContactMutation = (
  { __typename?: 'Mutation' }
  & { updateContact?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'emailAddress' | 'type' | 'status' | 'isVerified'>
  )> }
);

export type RestartContactVerificationMutationVariables = Exact<{
  input: RestartContactVerificationInput;
}>;


export type RestartContactVerificationMutation = (
  { __typename?: 'Mutation' }
  & { restartContactVerification?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'emailAddress' | 'status'>
  )> }
);

export type PauseConversationMutationVariables = Exact<{
  input: PauseConversationInput;
}>;


export type PauseConversationMutation = (
  { __typename?: 'Mutation' }
  & { pauseConversation?: Maybe<(
    { __typename?: 'Conversation' }
    & Pick<Conversation, 'id' | 'startedAt' | 'pausedUntil'>
  )> }
);

export type ResumeConversationMutationVariables = Exact<{
  input: ResumeConversationInput;
}>;


export type ResumeConversationMutation = (
  { __typename?: 'Mutation' }
  & { resumeConversation?: Maybe<(
    { __typename?: 'Conversation' }
    & Pick<Conversation, 'id' | 'startedAt' | 'pausedUntil'>
  )> }
);

export type MarkAllMessagesReadMutationVariables = Exact<{
  input: MarkAllMessagesReadInput;
}>;


export type MarkAllMessagesReadMutation = (
  { __typename?: 'Mutation' }
  & { markAllMessagesRead?: Maybe<(
    { __typename?: 'Conversation' }
    & Pick<Conversation, 'id' | 'lastMessageReadAt'>
  )> }
);

export type SetCurrentUserMutationVariables = Exact<{
  user: UserInput;
}>;


export type SetCurrentUserMutation = (
  { __typename?: 'Mutation' }
  & { setCurrentUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'preferredName' | 'givenName' | 'middleName' | 'familyName' | 'picture' | 'email' | 'phone' | 'blocked' | 'referenceNumber'>
    & { roles: Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name' | 'description'>
    )> }
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'givenName' | 'middleName' | 'familyName' | 'fullName' | 'email' | 'phone' | 'picture'>
  )> }
);

export type ChangeUserPasswordMutationVariables = Exact<{
  input: ChangeUserPasswordInput;
}>;


export type ChangeUserPasswordMutation = (
  { __typename?: 'Mutation' }
  & { changeUserPassword?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'givenName' | 'middleName' | 'familyName' | 'fullName' | 'email' | 'phone'>
  )> }
);

export type RequestPasswordResetMutationVariables = Exact<{
  input: RequestPasswordResetInput;
}>;


export type RequestPasswordResetMutation = (
  { __typename?: 'Mutation' }
  & { requestPasswordReset?: Maybe<(
    { __typename?: 'RequestPasswordResetPayload' }
    & Pick<RequestPasswordResetPayload, 'token' | 'expiresIn' | 'expiresAt'>
  )> }
);

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword?: Maybe<(
    { __typename?: 'ResetPasswordPayload' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email'>
    )> }
  )> }
);

export type AddFirmMemberMutationVariables = Exact<{
  input: AddFirmMemberInput;
}>;


export type AddFirmMemberMutation = (
  { __typename?: 'Mutation' }
  & { addFirmMember?: Maybe<(
    { __typename?: 'FirmMember' }
    & Pick<FirmMember, 'id' | 'role' | 'employeeNumber' | 'jobTitle' | 'office' | 'isActive' | 'isHighlighted' | 'activeSince' | 'status'>
    & { professional: (
      { __typename?: 'FirmBarrister' }
      & Pick<FirmBarrister, 'sraNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'referenceNumber' | 'status'>
    ) | (
      { __typename?: 'FirmParalegal' }
      & Pick<FirmParalegal, 'paralegalNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'referenceNumber' | 'status'>
    ) | (
      { __typename?: 'FirmSolicitor' }
      & Pick<FirmSolicitor, 'sraNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'referenceNumber' | 'status'>
    ) | (
      { __typename?: 'FirmSupport' }
      & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'referenceNumber' | 'status'>
    ), firm: (
      { __typename?: 'Firm' }
      & Pick<Firm, 'id' | 'name' | 'logoUrl'>
    ) }
  )> }
);

export type UpdateFirmMemberMutationVariables = Exact<{
  input: UpdateFirmMemberInput;
}>;


export type UpdateFirmMemberMutation = (
  { __typename?: 'Mutation' }
  & { updateFirmMember?: Maybe<(
    { __typename?: 'FirmMember' }
    & Pick<FirmMember, 'id' | 'role' | 'employeeNumber' | 'jobTitle' | 'office' | 'isActive' | 'isHighlighted' | 'activeSince' | 'status'>
    & { professional: (
      { __typename?: 'FirmBarrister' }
      & Pick<FirmBarrister, 'sraNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'referenceNumber' | 'status'>
    ) | (
      { __typename?: 'FirmParalegal' }
      & Pick<FirmParalegal, 'paralegalNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'referenceNumber' | 'status'>
    ) | (
      { __typename?: 'FirmSolicitor' }
      & Pick<FirmSolicitor, 'sraNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'referenceNumber' | 'status'>
    ) | (
      { __typename?: 'FirmSupport' }
      & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'referenceNumber' | 'status'>
    ), firm: (
      { __typename?: 'Firm' }
      & Pick<Firm, 'id' | 'name' | 'logoUrl'>
    ) }
  )> }
);

export type UpdateProfessionalMutationVariables = Exact<{
  input: UpdateProfessionalInput;
}>;


export type UpdateProfessionalMutation = (
  { __typename?: 'Mutation' }
  & { updateProfessional?: Maybe<(
    { __typename?: 'FirmBarrister' }
    & Pick<FirmBarrister, 'sraNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'referenceNumber' | 'status'>
  ) | (
    { __typename?: 'FirmParalegal' }
    & Pick<FirmParalegal, 'paralegalNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'referenceNumber' | 'status'>
  ) | (
    { __typename?: 'FirmSolicitor' }
    & Pick<FirmSolicitor, 'sraNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'referenceNumber' | 'status'>
  ) | (
    { __typename?: 'FirmSupport' }
    & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'referenceNumber' | 'status'>
  )> }
);

export type ChangeFirmMemberStatusMutationVariables = Exact<{
  input: ChangeFirmMemberStatusInput;
}>;


export type ChangeFirmMemberStatusMutation = (
  { __typename?: 'Mutation' }
  & { changeFirmMemberStatus?: Maybe<(
    { __typename?: 'FirmMember' }
    & Pick<FirmMember, 'id' | 'role' | 'employeeNumber' | 'jobTitle' | 'office' | 'isActive' | 'isHighlighted' | 'activeSince' | 'status'>
    & { professional: (
      { __typename?: 'FirmBarrister' }
      & Pick<FirmBarrister, 'sraNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'referenceNumber' | 'status'>
    ) | (
      { __typename?: 'FirmParalegal' }
      & Pick<FirmParalegal, 'paralegalNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'referenceNumber' | 'status'>
    ) | (
      { __typename?: 'FirmSolicitor' }
      & Pick<FirmSolicitor, 'sraNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'referenceNumber' | 'status'>
    ) | (
      { __typename?: 'FirmSupport' }
      & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'referenceNumber' | 'status'>
    ), firm: (
      { __typename?: 'Firm' }
      & Pick<Firm, 'id' | 'name' | 'logoUrl'>
    ) }
  )> }
);

export type UpdateFirmDetailsMutationVariables = Exact<{
  input: UpdateFirmDetailsInput;
}>;


export type UpdateFirmDetailsMutation = (
  { __typename?: 'Mutation' }
  & { updateFirmDetails?: Maybe<(
    { __typename?: 'Firm' }
    & Pick<Firm, 'id' | 'sraNumber' | 'name' | 'logoUrl'>
  )> }
);

export type ResendProfessionalInviteMutationVariables = Exact<{
  input: ResendProfessionalInviteInput;
}>;


export type ResendProfessionalInviteMutation = (
  { __typename?: 'Mutation' }
  & { resendProfessionalInvite?: Maybe<(
    { __typename?: 'FirmMember' }
    & Pick<FirmMember, 'id'>
  )> }
);

export type AddFileMutationVariables = Exact<{
  folderId: Scalars['ID'];
  asset: Scalars['Upload'];
}>;


export type AddFileMutation = (
  { __typename?: 'Mutation' }
  & { addFile?: Maybe<(
    { __typename?: 'ArchiveFile' }
    & Pick<ArchiveFile, 'id' | 'name' | 'type' | 'mime' | 'size' | 'version' | 'lastRevisedOn' | 'currentRevisionId'>
    & { revisions: Array<(
      { __typename?: 'ArchiveFileRevision' }
      & Pick<ArchiveFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'AudioFileRevision' }
      & Pick<AudioFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'DocumentFileRevision' }
      & Pick<DocumentFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'ImageFileRevision' }
      & Pick<ImageFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'VideoFileRevision' }
      & Pick<VideoFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    )>, topics: Array<(
      { __typename?: 'FirmTopic' }
      & Pick<FirmTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'GeneralTopic' }
      & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'PrivateTopic' }
      & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
    )> }
  ) | (
    { __typename?: 'AudioFile' }
    & Pick<AudioFile, 'id' | 'name' | 'type' | 'mime' | 'size' | 'version' | 'lastRevisedOn' | 'currentRevisionId'>
    & { revisions: Array<(
      { __typename?: 'ArchiveFileRevision' }
      & Pick<ArchiveFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'AudioFileRevision' }
      & Pick<AudioFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'DocumentFileRevision' }
      & Pick<DocumentFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'ImageFileRevision' }
      & Pick<ImageFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'VideoFileRevision' }
      & Pick<VideoFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    )>, topics: Array<(
      { __typename?: 'FirmTopic' }
      & Pick<FirmTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'GeneralTopic' }
      & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'PrivateTopic' }
      & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
    )> }
  ) | (
    { __typename?: 'DocumentFile' }
    & Pick<DocumentFile, 'id' | 'name' | 'type' | 'mime' | 'size' | 'version' | 'lastRevisedOn' | 'currentRevisionId'>
    & { revisions: Array<(
      { __typename?: 'ArchiveFileRevision' }
      & Pick<ArchiveFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'AudioFileRevision' }
      & Pick<AudioFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'DocumentFileRevision' }
      & Pick<DocumentFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'ImageFileRevision' }
      & Pick<ImageFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'VideoFileRevision' }
      & Pick<VideoFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    )>, topics: Array<(
      { __typename?: 'FirmTopic' }
      & Pick<FirmTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'GeneralTopic' }
      & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'PrivateTopic' }
      & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
    )> }
  ) | (
    { __typename?: 'ImageFile' }
    & Pick<ImageFile, 'id' | 'name' | 'type' | 'mime' | 'size' | 'version' | 'lastRevisedOn' | 'currentRevisionId'>
    & { revisions: Array<(
      { __typename?: 'ArchiveFileRevision' }
      & Pick<ArchiveFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'AudioFileRevision' }
      & Pick<AudioFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'DocumentFileRevision' }
      & Pick<DocumentFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'ImageFileRevision' }
      & Pick<ImageFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'VideoFileRevision' }
      & Pick<VideoFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    )>, topics: Array<(
      { __typename?: 'FirmTopic' }
      & Pick<FirmTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'GeneralTopic' }
      & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'PrivateTopic' }
      & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
    )> }
  ) | (
    { __typename?: 'VideoFile' }
    & Pick<VideoFile, 'id' | 'name' | 'type' | 'mime' | 'size' | 'version' | 'lastRevisedOn' | 'currentRevisionId'>
    & { revisions: Array<(
      { __typename?: 'ArchiveFileRevision' }
      & Pick<ArchiveFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'AudioFileRevision' }
      & Pick<AudioFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'DocumentFileRevision' }
      & Pick<DocumentFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'ImageFileRevision' }
      & Pick<ImageFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'VideoFileRevision' }
      & Pick<VideoFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    )>, topics: Array<(
      { __typename?: 'FirmTopic' }
      & Pick<FirmTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'GeneralTopic' }
      & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'PrivateTopic' }
      & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
    )> }
  )> }
);

export type AddFilesMutationVariables = Exact<{
  assets: Array<Scalars['Upload']> | Scalars['Upload'];
  folderId: Scalars['ID'];
}>;


export type AddFilesMutation = (
  { __typename?: 'Mutation' }
  & { addFiles?: Maybe<Array<Maybe<(
    { __typename?: 'ArchiveFile' }
    & Pick<ArchiveFile, 'id' | 'name' | 'type' | 'mime' | 'size' | 'version' | 'lastRevisedOn' | 'currentRevisionId'>
    & { revisions: Array<(
      { __typename?: 'ArchiveFileRevision' }
      & Pick<ArchiveFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'AudioFileRevision' }
      & Pick<AudioFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'DocumentFileRevision' }
      & Pick<DocumentFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'ImageFileRevision' }
      & Pick<ImageFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'VideoFileRevision' }
      & Pick<VideoFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    )>, topics: Array<(
      { __typename?: 'FirmTopic' }
      & Pick<FirmTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'GeneralTopic' }
      & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'PrivateTopic' }
      & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
    )> }
  ) | (
    { __typename?: 'AudioFile' }
    & Pick<AudioFile, 'id' | 'name' | 'type' | 'mime' | 'size' | 'version' | 'lastRevisedOn' | 'currentRevisionId'>
    & { revisions: Array<(
      { __typename?: 'ArchiveFileRevision' }
      & Pick<ArchiveFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'AudioFileRevision' }
      & Pick<AudioFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'DocumentFileRevision' }
      & Pick<DocumentFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'ImageFileRevision' }
      & Pick<ImageFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'VideoFileRevision' }
      & Pick<VideoFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    )>, topics: Array<(
      { __typename?: 'FirmTopic' }
      & Pick<FirmTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'GeneralTopic' }
      & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'PrivateTopic' }
      & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
    )> }
  ) | (
    { __typename?: 'DocumentFile' }
    & Pick<DocumentFile, 'id' | 'name' | 'type' | 'mime' | 'size' | 'version' | 'lastRevisedOn' | 'currentRevisionId'>
    & { revisions: Array<(
      { __typename?: 'ArchiveFileRevision' }
      & Pick<ArchiveFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'AudioFileRevision' }
      & Pick<AudioFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'DocumentFileRevision' }
      & Pick<DocumentFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'ImageFileRevision' }
      & Pick<ImageFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'VideoFileRevision' }
      & Pick<VideoFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    )>, topics: Array<(
      { __typename?: 'FirmTopic' }
      & Pick<FirmTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'GeneralTopic' }
      & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'PrivateTopic' }
      & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
    )> }
  ) | (
    { __typename?: 'ImageFile' }
    & Pick<ImageFile, 'id' | 'name' | 'type' | 'mime' | 'size' | 'version' | 'lastRevisedOn' | 'currentRevisionId'>
    & { revisions: Array<(
      { __typename?: 'ArchiveFileRevision' }
      & Pick<ArchiveFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'AudioFileRevision' }
      & Pick<AudioFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'DocumentFileRevision' }
      & Pick<DocumentFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'ImageFileRevision' }
      & Pick<ImageFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'VideoFileRevision' }
      & Pick<VideoFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    )>, topics: Array<(
      { __typename?: 'FirmTopic' }
      & Pick<FirmTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'GeneralTopic' }
      & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'PrivateTopic' }
      & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
    )> }
  ) | (
    { __typename?: 'VideoFile' }
    & Pick<VideoFile, 'id' | 'name' | 'type' | 'mime' | 'size' | 'version' | 'lastRevisedOn' | 'currentRevisionId'>
    & { revisions: Array<(
      { __typename?: 'ArchiveFileRevision' }
      & Pick<ArchiveFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'AudioFileRevision' }
      & Pick<AudioFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'DocumentFileRevision' }
      & Pick<DocumentFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'ImageFileRevision' }
      & Pick<ImageFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'VideoFileRevision' }
      & Pick<VideoFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    )>, topics: Array<(
      { __typename?: 'FirmTopic' }
      & Pick<FirmTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'GeneralTopic' }
      & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'PrivateTopic' }
      & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
    )> }
  )>>> }
);

export type AddFolderMutationVariables = Exact<{
  input: AddFolderInput;
}>;


export type AddFolderMutation = (
  { __typename?: 'Mutation' }
  & { addFolder?: Maybe<(
    { __typename?: 'Folder' }
    & Pick<Folder, 'id' | 'name' | 'isRestricted'>
    & { owner?: Maybe<(
      { __typename?: 'FileOwner' }
      & Pick<FileOwner, 'preferredName' | 'firstName' | 'lastName'>
    )>, parent?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'name' | 'isRestricted'>
    )> }
  )> }
);

export type ReviseFileMutationVariables = Exact<{
  fileId: Scalars['ID'];
  asset: Scalars['Upload'];
}>;


export type ReviseFileMutation = (
  { __typename?: 'Mutation' }
  & { reviseFile?: Maybe<(
    { __typename?: 'ArchiveFile' }
    & Pick<ArchiveFile, 'id' | 'name' | 'type' | 'mime' | 'size' | 'version' | 'lastRevisedOn' | 'currentRevisionId'>
    & { revisions: Array<(
      { __typename?: 'ArchiveFileRevision' }
      & Pick<ArchiveFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'AudioFileRevision' }
      & Pick<AudioFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'DocumentFileRevision' }
      & Pick<DocumentFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'ImageFileRevision' }
      & Pick<ImageFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'VideoFileRevision' }
      & Pick<VideoFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    )>, topics: Array<(
      { __typename?: 'FirmTopic' }
      & Pick<FirmTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'GeneralTopic' }
      & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'PrivateTopic' }
      & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
    )> }
  ) | (
    { __typename?: 'AudioFile' }
    & Pick<AudioFile, 'id' | 'name' | 'type' | 'mime' | 'size' | 'version' | 'lastRevisedOn' | 'currentRevisionId'>
    & { revisions: Array<(
      { __typename?: 'ArchiveFileRevision' }
      & Pick<ArchiveFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'AudioFileRevision' }
      & Pick<AudioFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'DocumentFileRevision' }
      & Pick<DocumentFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'ImageFileRevision' }
      & Pick<ImageFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'VideoFileRevision' }
      & Pick<VideoFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    )>, topics: Array<(
      { __typename?: 'FirmTopic' }
      & Pick<FirmTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'GeneralTopic' }
      & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'PrivateTopic' }
      & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
    )> }
  ) | (
    { __typename?: 'DocumentFile' }
    & Pick<DocumentFile, 'id' | 'name' | 'type' | 'mime' | 'size' | 'version' | 'lastRevisedOn' | 'currentRevisionId'>
    & { revisions: Array<(
      { __typename?: 'ArchiveFileRevision' }
      & Pick<ArchiveFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'AudioFileRevision' }
      & Pick<AudioFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'DocumentFileRevision' }
      & Pick<DocumentFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'ImageFileRevision' }
      & Pick<ImageFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'VideoFileRevision' }
      & Pick<VideoFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    )>, topics: Array<(
      { __typename?: 'FirmTopic' }
      & Pick<FirmTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'GeneralTopic' }
      & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'PrivateTopic' }
      & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
    )> }
  ) | (
    { __typename?: 'ImageFile' }
    & Pick<ImageFile, 'id' | 'name' | 'type' | 'mime' | 'size' | 'version' | 'lastRevisedOn' | 'currentRevisionId'>
    & { revisions: Array<(
      { __typename?: 'ArchiveFileRevision' }
      & Pick<ArchiveFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'AudioFileRevision' }
      & Pick<AudioFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'DocumentFileRevision' }
      & Pick<DocumentFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'ImageFileRevision' }
      & Pick<ImageFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'VideoFileRevision' }
      & Pick<VideoFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    )>, topics: Array<(
      { __typename?: 'FirmTopic' }
      & Pick<FirmTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'GeneralTopic' }
      & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'PrivateTopic' }
      & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
    )> }
  ) | (
    { __typename?: 'VideoFile' }
    & Pick<VideoFile, 'id' | 'name' | 'type' | 'mime' | 'size' | 'version' | 'lastRevisedOn' | 'currentRevisionId'>
    & { revisions: Array<(
      { __typename?: 'ArchiveFileRevision' }
      & Pick<ArchiveFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'AudioFileRevision' }
      & Pick<AudioFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'DocumentFileRevision' }
      & Pick<DocumentFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'ImageFileRevision' }
      & Pick<ImageFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    ) | (
      { __typename?: 'VideoFileRevision' }
      & Pick<VideoFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
    )>, topics: Array<(
      { __typename?: 'FirmTopic' }
      & Pick<FirmTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'GeneralTopic' }
      & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'PrivateTopic' }
      & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
    )> }
  )> }
);

export type MoveFileMutationVariables = Exact<{
  input: MoveFileInput;
}>;


export type MoveFileMutation = (
  { __typename?: 'Mutation' }
  & { moveFile?: Maybe<(
    { __typename?: 'ArchiveFile' }
    & Pick<ArchiveFile, 'id' | 'name'>
    & { folder: (
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'name'>
    ) }
  ) | (
    { __typename?: 'AudioFile' }
    & Pick<AudioFile, 'id' | 'name'>
    & { folder: (
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'name'>
    ) }
  ) | (
    { __typename?: 'DocumentFile' }
    & Pick<DocumentFile, 'id' | 'name'>
    & { folder: (
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'name'>
    ) }
  ) | (
    { __typename?: 'ImageFile' }
    & Pick<ImageFile, 'id' | 'name'>
    & { folder: (
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'name'>
    ) }
  ) | (
    { __typename?: 'VideoFile' }
    & Pick<VideoFile, 'id' | 'name'>
    & { folder: (
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'name'>
    ) }
  )> }
);

export type RenameFolderMutationVariables = Exact<{
  input: RenameFolderInput;
}>;


export type RenameFolderMutation = (
  { __typename?: 'Mutation' }
  & { renameFolder?: Maybe<(
    { __typename?: 'Folder' }
    & Pick<Folder, 'id' | 'name' | 'fileCount'>
    & { parent?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'name'>
    )> }
  )> }
);

export type RenameFileMutationVariables = Exact<{
  input: RenameFileInput;
}>;


export type RenameFileMutation = (
  { __typename?: 'Mutation' }
  & { renameFile?: Maybe<(
    { __typename?: 'ArchiveFile' }
    & Pick<ArchiveFile, 'id' | 'name' | 'type' | 'mime'>
    & { folder: (
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'name'>
    ) }
  ) | (
    { __typename?: 'AudioFile' }
    & Pick<AudioFile, 'id' | 'name' | 'type' | 'mime'>
    & { folder: (
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'name'>
    ) }
  ) | (
    { __typename?: 'DocumentFile' }
    & Pick<DocumentFile, 'id' | 'name' | 'type' | 'mime'>
    & { folder: (
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'name'>
    ) }
  ) | (
    { __typename?: 'ImageFile' }
    & Pick<ImageFile, 'id' | 'name' | 'type' | 'mime'>
    & { folder: (
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'name'>
    ) }
  ) | (
    { __typename?: 'VideoFile' }
    & Pick<VideoFile, 'id' | 'name' | 'type' | 'mime'>
    & { folder: (
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'name'>
    ) }
  )> }
);

export type MoveFolderMutationVariables = Exact<{
  input: MoveFolderInput;
}>;


export type MoveFolderMutation = (
  { __typename?: 'Mutation' }
  & { moveFolder?: Maybe<(
    { __typename?: 'Folder' }
    & Pick<Folder, 'id' | 'name'>
    & { parent?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'name'>
    )> }
  )> }
);

export type RemoveFileMutationVariables = Exact<{
  input: RemoveFileInput;
}>;


export type RemoveFileMutation = (
  { __typename?: 'Mutation' }
  & { removeFile?: Maybe<(
    { __typename?: 'FileRemovedPayload' }
    & Pick<FileRemovedPayload, 'fileId' | 'removedOn'>
  )> }
);

export type RemoveFolderMutationVariables = Exact<{
  input: RemoveFolderInput;
}>;


export type RemoveFolderMutation = (
  { __typename?: 'Mutation' }
  & { removeFolder?: Maybe<(
    { __typename?: 'FolderRemovedPayload' }
    & Pick<FolderRemovedPayload, 'folderId' | 'removedOn'>
  )> }
);

export type AssociateFileWithTopicMutationVariables = Exact<{
  input: AssociateFileWithTopicInput;
}>;


export type AssociateFileWithTopicMutation = (
  { __typename?: 'Mutation' }
  & { associateFileWithTopic?: Maybe<(
    { __typename?: 'ArchiveFile' }
    & Pick<ArchiveFile, 'id'>
    & { topics: Array<(
      { __typename?: 'FirmTopic' }
      & Pick<FirmTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'GeneralTopic' }
      & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'PrivateTopic' }
      & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
    )> }
  ) | (
    { __typename?: 'AudioFile' }
    & Pick<AudioFile, 'id'>
    & { topics: Array<(
      { __typename?: 'FirmTopic' }
      & Pick<FirmTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'GeneralTopic' }
      & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'PrivateTopic' }
      & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
    )> }
  ) | (
    { __typename?: 'DocumentFile' }
    & Pick<DocumentFile, 'id'>
    & { topics: Array<(
      { __typename?: 'FirmTopic' }
      & Pick<FirmTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'GeneralTopic' }
      & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'PrivateTopic' }
      & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
    )> }
  ) | (
    { __typename?: 'ImageFile' }
    & Pick<ImageFile, 'id'>
    & { topics: Array<(
      { __typename?: 'FirmTopic' }
      & Pick<FirmTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'GeneralTopic' }
      & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'PrivateTopic' }
      & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
    )> }
  ) | (
    { __typename?: 'VideoFile' }
    & Pick<VideoFile, 'id'>
    & { topics: Array<(
      { __typename?: 'FirmTopic' }
      & Pick<FirmTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'GeneralTopic' }
      & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'PrivateTopic' }
      & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
    )> }
  )> }
);

export type DisassociateFileFromTopicMutationVariables = Exact<{
  input: DisassociateFileFromTopicInput;
}>;


export type DisassociateFileFromTopicMutation = (
  { __typename?: 'Mutation' }
  & { disassociateFileFromTopic?: Maybe<(
    { __typename?: 'ArchiveFile' }
    & Pick<ArchiveFile, 'id'>
    & { topics: Array<(
      { __typename?: 'FirmTopic' }
      & Pick<FirmTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'GeneralTopic' }
      & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'PrivateTopic' }
      & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
    )> }
  ) | (
    { __typename?: 'AudioFile' }
    & Pick<AudioFile, 'id'>
    & { topics: Array<(
      { __typename?: 'FirmTopic' }
      & Pick<FirmTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'GeneralTopic' }
      & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'PrivateTopic' }
      & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
    )> }
  ) | (
    { __typename?: 'DocumentFile' }
    & Pick<DocumentFile, 'id'>
    & { topics: Array<(
      { __typename?: 'FirmTopic' }
      & Pick<FirmTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'GeneralTopic' }
      & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'PrivateTopic' }
      & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
    )> }
  ) | (
    { __typename?: 'ImageFile' }
    & Pick<ImageFile, 'id'>
    & { topics: Array<(
      { __typename?: 'FirmTopic' }
      & Pick<FirmTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'GeneralTopic' }
      & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'PrivateTopic' }
      & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
    )> }
  ) | (
    { __typename?: 'VideoFile' }
    & Pick<VideoFile, 'id'>
    & { topics: Array<(
      { __typename?: 'FirmTopic' }
      & Pick<FirmTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'GeneralTopic' }
      & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'PrivateTopic' }
      & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
    )> }
  )> }
);

export type ClearHighlightMutationVariables = Exact<{
  input: ClearHighlightInput;
}>;


export type ClearHighlightMutation = (
  { __typename?: 'Mutation' }
  & { clearHighlight: (
    { __typename?: 'HighlightClearedPayload' }
    & Pick<HighlightClearedPayload, 'highlightableId' | 'clearedOn'>
  ) }
);

export type ClearHighlightsMutationVariables = Exact<{
  input: ClearHighlightsInput;
}>;


export type ClearHighlightsMutation = (
  { __typename?: 'Mutation' }
  & { clearHighlights: (
    { __typename?: 'HighlightsClearedPayload' }
    & Pick<HighlightsClearedPayload, 'highlightableIds' | 'clearedOn'>
  ) }
);

export type StartIssueMutationVariables = Exact<{
  input: StartIssueInput;
}>;


export type StartIssueMutation = (
  { __typename?: 'Mutation' }
  & { startIssue?: Maybe<(
    { __typename?: 'Issue' }
    & Pick<Issue, 'id' | 'type' | 'name'>
    & { opponent?: Maybe<(
      { __typename?: 'Opponent' }
      & Pick<Opponent, 'preferredName' | 'firstName' | 'lastName' | 'emailAddress' | 'nationalInsuranceNumber' | 'clientReferenceNumber' | 'isVerified'>
    )>, opponentIssue?: Maybe<(
      { __typename?: 'Issue' }
      & Pick<Issue, 'id' | 'type' | 'name'>
    )> }
  )> }
);

export type IdentifyOpponentMutationVariables = Exact<{
  input: IdentifyOpponentInput;
}>;


export type IdentifyOpponentMutation = (
  { __typename?: 'Mutation' }
  & { identifyOpponent?: Maybe<(
    { __typename?: 'Issue' }
    & Pick<Issue, 'id' | 'type' | 'isLinked' | 'linkCode' | 'linkRequested'>
    & { opponentIssue?: Maybe<(
      { __typename?: 'Issue' }
      & Pick<Issue, 'id'>
      & { legalTeam: Array<(
        { __typename?: 'LegalTeamMember' }
        & Pick<LegalTeamMember, 'id' | 'jobTitle' | 'isLead'>
        & { firm: (
          { __typename?: 'Firm' }
          & Pick<Firm, 'id' | 'name'>
        ), professional: (
          { __typename?: 'FirmBarrister' }
          & Pick<FirmBarrister, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
        ) | (
          { __typename?: 'FirmParalegal' }
          & Pick<FirmParalegal, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
        ) | (
          { __typename?: 'FirmSolicitor' }
          & Pick<FirmSolicitor, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
        ) | (
          { __typename?: 'FirmSupport' }
          & Pick<FirmSupport, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
        ) }
      )> }
    )>, opponent?: Maybe<(
      { __typename?: 'Opponent' }
      & Pick<Opponent, 'preferredName' | 'firstName' | 'lastName' | 'emailAddress' | 'nationalInsuranceNumber' | 'clientReferenceNumber' | 'isVerified'>
      & { solicitor: (
        { __typename?: 'OpponentSolicitor' }
        & Pick<OpponentSolicitor, 'firstName' | 'lastName' | 'emailAddress' | 'firmName'>
      ) }
    )> }
  )> }
);

export type IdentifyOpponentSolicitorMutationVariables = Exact<{
  input: IdentifyOpponentSolicitorInput;
}>;


export type IdentifyOpponentSolicitorMutation = (
  { __typename?: 'Mutation' }
  & { identifyOpponentSolicitor?: Maybe<(
    { __typename?: 'Issue' }
    & Pick<Issue, 'id' | 'type' | 'isLinked' | 'linkCode' | 'linkRequested'>
    & { opponentIssue?: Maybe<(
      { __typename?: 'Issue' }
      & Pick<Issue, 'id'>
      & { legalTeam: Array<(
        { __typename?: 'LegalTeamMember' }
        & Pick<LegalTeamMember, 'id' | 'jobTitle' | 'isLead'>
        & { firm: (
          { __typename?: 'Firm' }
          & Pick<Firm, 'id' | 'name'>
        ), professional: (
          { __typename?: 'FirmBarrister' }
          & Pick<FirmBarrister, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
        ) | (
          { __typename?: 'FirmParalegal' }
          & Pick<FirmParalegal, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
        ) | (
          { __typename?: 'FirmSolicitor' }
          & Pick<FirmSolicitor, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
        ) | (
          { __typename?: 'FirmSupport' }
          & Pick<FirmSupport, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
        ) }
      )> }
    )>, opponent?: Maybe<(
      { __typename?: 'Opponent' }
      & Pick<Opponent, 'preferredName' | 'firstName' | 'lastName' | 'emailAddress' | 'nationalInsuranceNumber' | 'clientReferenceNumber' | 'isVerified'>
      & { solicitor: (
        { __typename?: 'OpponentSolicitor' }
        & Pick<OpponentSolicitor, 'firstName' | 'lastName' | 'emailAddress' | 'firmName'>
      ) }
    )> }
  )> }
);

export type RequestOpponentConnectionMutationVariables = Exact<{
  input: RequestOpponentConnectionInput;
}>;


export type RequestOpponentConnectionMutation = (
  { __typename?: 'Mutation' }
  & { requestOpponentConnection?: Maybe<(
    { __typename?: 'Issue' }
    & Pick<Issue, 'id' | 'linkCode' | 'linkRequested'>
  )> }
);

export type LinkIssueMutationVariables = Exact<{
  input: LinkIssueInput;
}>;


export type LinkIssueMutation = (
  { __typename?: 'Mutation' }
  & { linkIssue?: Maybe<(
    { __typename?: 'Issue' }
    & Pick<Issue, 'id' | 'type'>
    & { opponent?: Maybe<(
      { __typename?: 'Opponent' }
      & Pick<Opponent, 'preferredName' | 'firstName' | 'lastName' | 'emailAddress' | 'nationalInsuranceNumber' | 'clientReferenceNumber' | 'isVerified'>
    )>, opponentIssue?: Maybe<(
      { __typename?: 'Issue' }
      & Pick<Issue, 'id' | 'type'>
    )> }
  )> }
);

export type LoginWithPasswordMutationVariables = Exact<{
  input: LoginWithPasswordInput;
}>;


export type LoginWithPasswordMutation = (
  { __typename?: 'Mutation' }
  & { loginWithPassword?: Maybe<(
    { __typename: 'FailedAuthenticationPayload' }
    & Pick<FailedAuthenticationPayload, 'successful' | 'message'>
  ) | (
    { __typename: 'SuccessfulAuthenticationPayload' }
    & Pick<SuccessfulAuthenticationPayload, 'accessToken' | 'idToken' | 'expiresIn' | 'scope' | 'successful' | 'message'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'givenName' | 'middleName' | 'familyName' | 'picture' | 'email' | 'phone'>
      & { roles: Array<(
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'name' | 'description'>
      )> }
    )> }
  )> }
);

export type StartMatterMutationVariables = Exact<{
  input: StartMatterInput;
}>;


export type StartMatterMutation = (
  { __typename?: 'Mutation' }
  & { startMatter?: Maybe<(
    { __typename?: 'Matter' }
    & Pick<Matter, 'id' | 'type' | 'status' | 'advisories' | 'advisoryCount' | 'isHighlighted' | 'isRemovable' | 'startedAt' | 'endedAt'>
    & { clientAccount?: Maybe<(
      { __typename?: 'ClientAccount' }
      & Pick<ClientAccount, 'id' | 'referenceNumber'>
      & { client: (
        { __typename?: 'FirmClient' }
        & Pick<FirmClient, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )>, leadProfessional?: Maybe<(
      { __typename?: 'FirmBarrister' }
      & Pick<FirmBarrister, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) | (
      { __typename?: 'FirmParalegal' }
      & Pick<FirmParalegal, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) | (
      { __typename?: 'FirmSolicitor' }
      & Pick<FirmSolicitor, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) | (
      { __typename?: 'FirmSupport' }
      & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    )> }
  )> }
);

export type RemoveMatterMutationVariables = Exact<{
  input: RemoveMatterInput;
}>;


export type RemoveMatterMutation = (
  { __typename?: 'Mutation' }
  & { removeMatter?: Maybe<(
    { __typename?: 'MatterRemovedPayload' }
    & Pick<MatterRemovedPayload, 'matterId'>
  )> }
);

export type AddKeyDateMutationVariables = Exact<{
  input: AddKeyDateInput;
}>;


export type AddKeyDateMutation = (
  { __typename?: 'Mutation' }
  & { addKeyDate?: Maybe<(
    { __typename?: 'KeyDate' }
    & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
  )> }
);

export type ChangeMatterLeadMutationVariables = Exact<{
  input: ChangeMatterLeadInput;
}>;


export type ChangeMatterLeadMutation = (
  { __typename?: 'Mutation' }
  & { changeMatterLead?: Maybe<(
    { __typename?: 'Matter' }
    & Pick<Matter, 'id'>
    & { leadProfessional?: Maybe<(
      { __typename?: 'FirmBarrister' }
      & Pick<FirmBarrister, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) | (
      { __typename?: 'FirmParalegal' }
      & Pick<FirmParalegal, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) | (
      { __typename?: 'FirmSolicitor' }
      & Pick<FirmSolicitor, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) | (
      { __typename?: 'FirmSupport' }
      & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    )> }
  )> }
);

export type ModifyKeyDateMutationVariables = Exact<{
  input: ModifyKeyDateInput;
}>;


export type ModifyKeyDateMutation = (
  { __typename?: 'Mutation' }
  & { modifyKeyDate?: Maybe<(
    { __typename?: 'KeyDate' }
    & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
  )> }
);

export type RemoveKeyDateMutationVariables = Exact<{
  input: RemoveKeyDateInput;
}>;


export type RemoveKeyDateMutation = (
  { __typename?: 'Mutation' }
  & { removeKeyDate?: Maybe<(
    { __typename?: 'KeyDateRemovedPayload' }
    & Pick<KeyDateRemovedPayload, 'keyDateId' | 'removedOn'>
  )> }
);

export type PrepareCourtCaseMutationVariables = Exact<{
  input: PrepareCourtCaseInput;
}>;


export type PrepareCourtCaseMutation = (
  { __typename?: 'Mutation' }
  & { prepareCourtCase?: Maybe<(
    { __typename?: 'CourtCase' }
    & Pick<CourtCase, 'id' | 'courtName' | 'caseNumber' | 'status'>
    & { hearingDate?: Maybe<(
      { __typename?: 'KeyDate' }
      & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
    )> }
  )> }
);

export type UpdateCourtCaseMutationVariables = Exact<{
  input: UpdateCourtCaseInput;
}>;


export type UpdateCourtCaseMutation = (
  { __typename?: 'Mutation' }
  & { updateCourtCase?: Maybe<(
    { __typename?: 'CourtCase' }
    & Pick<CourtCase, 'id' | 'courtName' | 'caseNumber' | 'status'>
    & { hearingDate?: Maybe<(
      { __typename?: 'KeyDate' }
      & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
    )> }
  )> }
);

export type MakeConversationExtractMutationVariables = Exact<{
  input: MakeConversationExtractInput;
}>;


export type MakeConversationExtractMutation = (
  { __typename?: 'Mutation' }
  & { makeConversationExtract?: Maybe<(
    { __typename?: 'ConversationExtract' }
    & Pick<ConversationExtract, 'id' | 'name'>
    & { messageFilters: (
      { __typename?: 'MessageFilters' }
      & Pick<MessageFilters, 'conversationId' | 'isUnread' | 'isVisible' | 'hasAttachments' | 'sentAfter' | 'sentBefore' | 'senderIds'>
    ) }
  )> }
);

export type DiscardConversationExtractMutationVariables = Exact<{
  input: DiscardConversationExtractInput;
}>;


export type DiscardConversationExtractMutation = (
  { __typename?: 'Mutation' }
  & { discardConversationExtract?: Maybe<(
    { __typename?: 'ConversationExtractDiscardedPayload' }
    & Pick<ConversationExtractDiscardedPayload, 'conversationExtractId' | 'discardedOn'>
  )> }
);

export type AddSupportingFileMutationVariables = Exact<{
  input: AddSupportingFileInput;
}>;


export type AddSupportingFileMutation = (
  { __typename?: 'Mutation' }
  & { addSupportingFile?: Maybe<(
    { __typename?: 'SupportingArchive' }
    & Pick<SupportingArchive, 'id' | 'source' | 'name' | 'fileId' | 'fileVersion' | 'size' | 'type' | 'mime'>
  ) | (
    { __typename?: 'SupportingAudio' }
    & Pick<SupportingAudio, 'id' | 'source' | 'name' | 'fileId' | 'fileVersion' | 'size' | 'type' | 'mime'>
  ) | (
    { __typename?: 'SupportingDocument' }
    & Pick<SupportingDocument, 'id' | 'source' | 'name' | 'fileId' | 'fileVersion' | 'size' | 'type' | 'mime'>
  ) | (
    { __typename?: 'SupportingImage' }
    & Pick<SupportingImage, 'height' | 'width' | 'id' | 'source' | 'name' | 'fileId' | 'fileVersion' | 'size' | 'type' | 'mime'>
  ) | (
    { __typename?: 'SupportingVideo' }
    & Pick<SupportingVideo, 'id' | 'source' | 'name' | 'fileId' | 'fileVersion' | 'size' | 'type' | 'mime'>
  )> }
);

export type RemoveSupportingFileMutationVariables = Exact<{
  input: RemoveSupportingFileInput;
}>;


export type RemoveSupportingFileMutation = (
  { __typename?: 'Mutation' }
  & { removeSupportingFile?: Maybe<(
    { __typename?: 'SupportingFileRemovedPayload' }
    & Pick<SupportingFileRemovedPayload, 'supportingFileId' | 'removedOn'>
  )> }
);

export type ExportSupportingMaterialMutationVariables = Exact<{
  input: ExportSupportingMaterialInput;
}>;


export type ExportSupportingMaterialMutation = (
  { __typename?: 'Mutation' }
  & { exportSupportingMaterial: (
    { __typename?: 'SupportingMaterialExportStartedPayload' }
    & Pick<SupportingMaterialExportStartedPayload, 'matterId' | 'materialIds' | 'startedOn'>
  ) }
);

export type EndMatterMutationVariables = Exact<{
  input: EndMatterInput;
}>;


export type EndMatterMutation = (
  { __typename?: 'Mutation' }
  & { endMatter?: Maybe<(
    { __typename?: 'Matter' }
    & Pick<Matter, 'id' | 'type' | 'status' | 'endedAt'>
  )> }
);

export type SendMessageMutationVariables = Exact<{
  input: ComposeMessageInput;
}>;


export type SendMessageMutation = (
  { __typename?: 'Mutation' }
  & { sendMessage?: Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'id' | 'conversationId' | 'body' | 'sentAt' | 'deliveredAt' | 'readAt'>
    & { sender: (
      { __typename?: 'ConversationParticipant' }
      & Pick<ConversationParticipant, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl' | 'role' | 'channel' | 'title'>
      & { group?: Maybe<(
        { __typename?: 'ConversationGroup' }
        & Pick<ConversationGroup, 'id' | 'name' | 'pictureUrl'>
      )> }
    ), repliedTo?: Maybe<(
      { __typename?: 'Message' }
      & Pick<Message, 'id' | 'body' | 'sentAt'>
      & { sender: (
        { __typename?: 'ConversationParticipant' }
        & Pick<ConversationParticipant, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl' | 'role' | 'channel' | 'title'>
        & { group?: Maybe<(
          { __typename?: 'ConversationGroup' }
          & Pick<ConversationGroup, 'id' | 'name' | 'pictureUrl'>
        )> }
      ) }
    )>, copies?: Maybe<Array<Maybe<(
      { __typename?: 'MessageCopy' }
      & Pick<MessageCopy, 'deliveredAt' | 'readAt'>
      & { recipient: (
        { __typename?: 'ConversationObserver' }
        & Pick<ConversationObserver, 'id'>
        & { group?: Maybe<(
          { __typename?: 'ConversationGroup' }
          & Pick<ConversationGroup, 'id'>
        )> }
      ) | (
        { __typename?: 'ConversationParticipant' }
        & Pick<ConversationParticipant, 'id'>
        & { group?: Maybe<(
          { __typename?: 'ConversationGroup' }
          & Pick<ConversationGroup, 'id'>
        )> }
      ) }
    )>>>, attachments: Array<(
      { __typename?: 'AttachedArchive' }
      & Pick<AttachedArchive, 'id' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'AttachedAudio' }
      & Pick<AttachedAudio, 'id' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'AttachedDocument' }
      & Pick<AttachedDocument, 'id' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'AttachedImage' }
      & Pick<AttachedImage, 'height' | 'width' | 'id' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'AttachedVideo' }
      & Pick<AttachedVideo, 'id' | 'name' | 'type' | 'mime' | 'size'>
    )>, topics: Array<(
      { __typename?: 'FirmTopic' }
      & Pick<FirmTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'GeneralTopic' }
      & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'PrivateTopic' }
      & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
    )> }
  )> }
);

export type EditMessageDraftMutationVariables = Exact<{
  input: ComposeMessageInput;
}>;


export type EditMessageDraftMutation = (
  { __typename?: 'Mutation' }
  & { editMessageDraft?: Maybe<(
    { __typename?: 'MessageDraft' }
    & Pick<MessageDraft, 'id' | 'body' | 'updatedAt'>
  )> }
);

export type MarkMessageDeliveredMutationVariables = Exact<{
  input: MarkMessageDeliveredInput;
}>;


export type MarkMessageDeliveredMutation = (
  { __typename?: 'Mutation' }
  & { markMessageDelivered?: Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'id' | 'conversationId' | 'body' | 'sentAt' | 'deliveredAt' | 'readAt'>
    & { sender: (
      { __typename?: 'ConversationParticipant' }
      & Pick<ConversationParticipant, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl' | 'role' | 'channel' | 'title'>
      & { group?: Maybe<(
        { __typename?: 'ConversationGroup' }
        & Pick<ConversationGroup, 'id' | 'name' | 'pictureUrl'>
      )> }
    ), repliedTo?: Maybe<(
      { __typename?: 'Message' }
      & Pick<Message, 'id' | 'body' | 'sentAt'>
      & { sender: (
        { __typename?: 'ConversationParticipant' }
        & Pick<ConversationParticipant, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl' | 'role' | 'channel' | 'title'>
        & { group?: Maybe<(
          { __typename?: 'ConversationGroup' }
          & Pick<ConversationGroup, 'id' | 'name' | 'pictureUrl'>
        )> }
      ) }
    )>, copies?: Maybe<Array<Maybe<(
      { __typename?: 'MessageCopy' }
      & Pick<MessageCopy, 'deliveredAt' | 'readAt'>
      & { recipient: (
        { __typename?: 'ConversationObserver' }
        & Pick<ConversationObserver, 'id'>
        & { group?: Maybe<(
          { __typename?: 'ConversationGroup' }
          & Pick<ConversationGroup, 'id'>
        )> }
      ) | (
        { __typename?: 'ConversationParticipant' }
        & Pick<ConversationParticipant, 'id'>
        & { group?: Maybe<(
          { __typename?: 'ConversationGroup' }
          & Pick<ConversationGroup, 'id'>
        )> }
      ) }
    )>>>, attachments: Array<(
      { __typename?: 'AttachedArchive' }
      & Pick<AttachedArchive, 'id' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'AttachedAudio' }
      & Pick<AttachedAudio, 'id' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'AttachedDocument' }
      & Pick<AttachedDocument, 'id' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'AttachedImage' }
      & Pick<AttachedImage, 'height' | 'width' | 'id' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'AttachedVideo' }
      & Pick<AttachedVideo, 'id' | 'name' | 'type' | 'mime' | 'size'>
    )>, topics: Array<(
      { __typename?: 'FirmTopic' }
      & Pick<FirmTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'GeneralTopic' }
      & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'PrivateTopic' }
      & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
    )> }
  )> }
);

export type MarkMessageReadMutationVariables = Exact<{
  input: MarkMessageReadInput;
}>;


export type MarkMessageReadMutation = (
  { __typename?: 'Mutation' }
  & { markMessageRead?: Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'id' | 'conversationId' | 'body' | 'sentAt' | 'deliveredAt' | 'readAt'>
    & { sender: (
      { __typename?: 'ConversationParticipant' }
      & Pick<ConversationParticipant, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl' | 'role' | 'channel' | 'title'>
      & { group?: Maybe<(
        { __typename?: 'ConversationGroup' }
        & Pick<ConversationGroup, 'id' | 'name' | 'pictureUrl'>
      )> }
    ), repliedTo?: Maybe<(
      { __typename?: 'Message' }
      & Pick<Message, 'id' | 'body' | 'sentAt'>
      & { sender: (
        { __typename?: 'ConversationParticipant' }
        & Pick<ConversationParticipant, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl' | 'role' | 'channel' | 'title'>
        & { group?: Maybe<(
          { __typename?: 'ConversationGroup' }
          & Pick<ConversationGroup, 'id' | 'name' | 'pictureUrl'>
        )> }
      ) }
    )>, copies?: Maybe<Array<Maybe<(
      { __typename?: 'MessageCopy' }
      & Pick<MessageCopy, 'deliveredAt' | 'readAt'>
      & { recipient: (
        { __typename?: 'ConversationObserver' }
        & Pick<ConversationObserver, 'id'>
        & { group?: Maybe<(
          { __typename?: 'ConversationGroup' }
          & Pick<ConversationGroup, 'id'>
        )> }
      ) | (
        { __typename?: 'ConversationParticipant' }
        & Pick<ConversationParticipant, 'id'>
        & { group?: Maybe<(
          { __typename?: 'ConversationGroup' }
          & Pick<ConversationGroup, 'id'>
        )> }
      ) }
    )>>>, attachments: Array<(
      { __typename?: 'AttachedArchive' }
      & Pick<AttachedArchive, 'id' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'AttachedAudio' }
      & Pick<AttachedAudio, 'id' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'AttachedDocument' }
      & Pick<AttachedDocument, 'id' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'AttachedImage' }
      & Pick<AttachedImage, 'height' | 'width' | 'id' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'AttachedVideo' }
      & Pick<AttachedVideo, 'id' | 'name' | 'type' | 'mime' | 'size'>
    )>, topics: Array<(
      { __typename?: 'FirmTopic' }
      & Pick<FirmTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'GeneralTopic' }
      & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'PrivateTopic' }
      & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
    )> }
  )> }
);

export type AssociateMessageWithTopicMutationVariables = Exact<{
  input: AssociateMessageWithTopicInput;
}>;


export type AssociateMessageWithTopicMutation = (
  { __typename?: 'Mutation' }
  & { associateMessageWithTopic?: Maybe<(
    { __typename?: 'MessageCopy' }
    & Pick<MessageCopy, 'id'>
    & { topics: Array<(
      { __typename?: 'FirmTopic' }
      & Pick<FirmTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'GeneralTopic' }
      & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'PrivateTopic' }
      & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
    )> }
  )> }
);

export type DisassociateMessageFromTopicMutationVariables = Exact<{
  input: DisassociateMessageFromTopicInput;
}>;


export type DisassociateMessageFromTopicMutation = (
  { __typename?: 'Mutation' }
  & { disassociateMessageFromTopic?: Maybe<(
    { __typename?: 'MessageCopy' }
    & Pick<MessageCopy, 'id'>
    & { topics: Array<(
      { __typename?: 'FirmTopic' }
      & Pick<FirmTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'GeneralTopic' }
      & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'PrivateTopic' }
      & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
    )> }
  )> }
);

export type DraftNoteMutationVariables = Exact<{
  input: DraftNoteInput;
}>;


export type DraftNoteMutation = (
  { __typename?: 'Mutation' }
  & { draftNote?: Maybe<(
    { __typename?: 'Note' }
    & Pick<Note, 'id' | 'category' | 'dateTime' | 'title' | 'body' | 'startedAt' | 'versionNumber' | 'isPersonal' | 'isViewed'>
    & { revisions: Array<(
      { __typename?: 'NoteRevision' }
      & Pick<NoteRevision, 'versionNumber' | 'title' | 'body' | 'category' | 'dateTime' | 'draftedAt'>
      & { author?: Maybe<(
        { __typename?: 'FirmBarrister' }
        & Pick<FirmBarrister, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) | (
        { __typename?: 'FirmParalegal' }
        & Pick<FirmParalegal, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) | (
        { __typename?: 'FirmSolicitor' }
        & Pick<FirmSolicitor, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) | (
        { __typename?: 'FirmSupport' }
        & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      )> }
    )>, clientAccount?: Maybe<(
      { __typename?: 'ClientAccount' }
      & Pick<ClientAccount, 'id' | 'status' | 'referenceNumber'>
      & { client: (
        { __typename?: 'FirmClient' }
        & Pick<FirmClient, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )>, matter?: Maybe<(
      { __typename?: 'Matter' }
      & Pick<Matter, 'id'>
    )>, author?: Maybe<(
      { __typename?: 'FirmBarrister' }
      & Pick<FirmBarrister, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) | (
      { __typename?: 'FirmParalegal' }
      & Pick<FirmParalegal, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) | (
      { __typename?: 'FirmSolicitor' }
      & Pick<FirmSolicitor, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) | (
      { __typename?: 'FirmSupport' }
      & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    )> }
  )> }
);

export type ReviseNoteMutationVariables = Exact<{
  input: ReviseNoteInput;
}>;


export type ReviseNoteMutation = (
  { __typename?: 'Mutation' }
  & { reviseNote?: Maybe<(
    { __typename?: 'Note' }
    & Pick<Note, 'id' | 'category' | 'dateTime' | 'title' | 'body' | 'startedAt' | 'versionNumber' | 'isPersonal' | 'isViewed'>
    & { revisions: Array<(
      { __typename?: 'NoteRevision' }
      & Pick<NoteRevision, 'versionNumber' | 'title' | 'body' | 'category' | 'dateTime' | 'draftedAt'>
      & { author?: Maybe<(
        { __typename?: 'FirmBarrister' }
        & Pick<FirmBarrister, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) | (
        { __typename?: 'FirmParalegal' }
        & Pick<FirmParalegal, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) | (
        { __typename?: 'FirmSolicitor' }
        & Pick<FirmSolicitor, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) | (
        { __typename?: 'FirmSupport' }
        & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      )> }
    )>, clientAccount?: Maybe<(
      { __typename?: 'ClientAccount' }
      & Pick<ClientAccount, 'id' | 'status' | 'referenceNumber'>
      & { client: (
        { __typename?: 'FirmClient' }
        & Pick<FirmClient, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )>, matter?: Maybe<(
      { __typename?: 'Matter' }
      & Pick<Matter, 'id'>
    )>, author?: Maybe<(
      { __typename?: 'FirmBarrister' }
      & Pick<FirmBarrister, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) | (
      { __typename?: 'FirmParalegal' }
      & Pick<FirmParalegal, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) | (
      { __typename?: 'FirmSolicitor' }
      & Pick<FirmSolicitor, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) | (
      { __typename?: 'FirmSupport' }
      & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    )> }
  )> }
);

export type AssociateNoteMutationVariables = Exact<{
  input: AssociateNoteInput;
}>;


export type AssociateNoteMutation = (
  { __typename?: 'Mutation' }
  & { associateNote?: Maybe<(
    { __typename?: 'Note' }
    & Pick<Note, 'id' | 'category' | 'dateTime' | 'title' | 'body' | 'startedAt' | 'versionNumber' | 'isPersonal' | 'isViewed'>
    & { revisions: Array<(
      { __typename?: 'NoteRevision' }
      & Pick<NoteRevision, 'versionNumber' | 'title' | 'body' | 'category' | 'dateTime' | 'draftedAt'>
      & { author?: Maybe<(
        { __typename?: 'FirmBarrister' }
        & Pick<FirmBarrister, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) | (
        { __typename?: 'FirmParalegal' }
        & Pick<FirmParalegal, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) | (
        { __typename?: 'FirmSolicitor' }
        & Pick<FirmSolicitor, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) | (
        { __typename?: 'FirmSupport' }
        & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      )> }
    )>, clientAccount?: Maybe<(
      { __typename?: 'ClientAccount' }
      & Pick<ClientAccount, 'id' | 'status' | 'referenceNumber'>
      & { client: (
        { __typename?: 'FirmClient' }
        & Pick<FirmClient, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )>, matter?: Maybe<(
      { __typename?: 'Matter' }
      & Pick<Matter, 'id'>
    )>, author?: Maybe<(
      { __typename?: 'FirmBarrister' }
      & Pick<FirmBarrister, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) | (
      { __typename?: 'FirmParalegal' }
      & Pick<FirmParalegal, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) | (
      { __typename?: 'FirmSolicitor' }
      & Pick<FirmSolicitor, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) | (
      { __typename?: 'FirmSupport' }
      & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    )> }
  )> }
);

export type MarkNoteViewedMutationVariables = Exact<{
  input: MarkNoteViewedInput;
}>;


export type MarkNoteViewedMutation = (
  { __typename?: 'Mutation' }
  & { markNoteViewed?: Maybe<(
    { __typename?: 'Note' }
    & Pick<Note, 'id' | 'category' | 'dateTime' | 'title' | 'body' | 'startedAt' | 'versionNumber' | 'isPersonal' | 'isViewed'>
    & { revisions: Array<(
      { __typename?: 'NoteRevision' }
      & Pick<NoteRevision, 'versionNumber' | 'title' | 'body' | 'category' | 'dateTime' | 'draftedAt'>
      & { author?: Maybe<(
        { __typename?: 'FirmBarrister' }
        & Pick<FirmBarrister, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) | (
        { __typename?: 'FirmParalegal' }
        & Pick<FirmParalegal, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) | (
        { __typename?: 'FirmSolicitor' }
        & Pick<FirmSolicitor, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) | (
        { __typename?: 'FirmSupport' }
        & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      )> }
    )>, clientAccount?: Maybe<(
      { __typename?: 'ClientAccount' }
      & Pick<ClientAccount, 'id' | 'status' | 'referenceNumber'>
      & { client: (
        { __typename?: 'FirmClient' }
        & Pick<FirmClient, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )>, matter?: Maybe<(
      { __typename?: 'Matter' }
      & Pick<Matter, 'id'>
    )>, author?: Maybe<(
      { __typename?: 'FirmBarrister' }
      & Pick<FirmBarrister, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) | (
      { __typename?: 'FirmParalegal' }
      & Pick<FirmParalegal, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) | (
      { __typename?: 'FirmSolicitor' }
      & Pick<FirmSolicitor, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) | (
      { __typename?: 'FirmSupport' }
      & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    )> }
  )> }
);

export type DiscardNoteMutationVariables = Exact<{
  input: DiscardNoteInput;
}>;


export type DiscardNoteMutation = (
  { __typename?: 'Mutation' }
  & { discardNote?: Maybe<(
    { __typename?: 'NoteDiscardedPayload' }
    & Pick<NoteDiscardedPayload, 'noteId' | 'discardedOn'>
  )> }
);

export type MarkNotificationReadMutationVariables = Exact<{
  input: MarkNotificationReadInput;
}>;


export type MarkNotificationReadMutation = (
  { __typename?: 'Mutation' }
  & { markNotificationRead?: Maybe<(
    { __typename?: 'Notification' }
    & Pick<Notification, 'id' | 'type' | 'title' | 'body' | 'readAt' | 'isRead'>
  )> }
);

export type MarkAllNotificationsReadMutationVariables = Exact<{ [key: string]: never; }>;


export type MarkAllNotificationsReadMutation = (
  { __typename?: 'Mutation' }
  & { markAllNotificationsRead: (
    { __typename?: 'NotificationsReadPayload' }
    & Pick<NotificationsReadPayload, 'affectedNotificationCount'>
  ) }
);

export type RegisterPushInterestMutationVariables = Exact<{
  input: RegisterPushInterestInput;
}>;


export type RegisterPushInterestMutation = (
  { __typename?: 'Mutation' }
  & { registerPushInterest?: Maybe<(
    { __typename?: 'PushInterestPayload' }
    & Pick<PushInterestPayload, 'affectedPushToken'>
  )> }
);

export type RevokePushInterestMutationVariables = Exact<{
  input: RevokePushInterestInput;
}>;


export type RevokePushInterestMutation = (
  { __typename?: 'Mutation' }
  & { revokePushInterest?: Maybe<(
    { __typename?: 'PushInterestPayload' }
    & Pick<PushInterestPayload, 'affectedPushToken'>
  )> }
);

export type PrepareQuestionMutationVariables = Exact<{
  input: PrepareQuestionInput;
}>;


export type PrepareQuestionMutation = (
  { __typename?: 'Mutation' }
  & { prepareQuestion?: Maybe<(
    { __typename?: 'Question' }
    & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
    & { parts: Array<(
      { __typename?: 'AddressPart' }
      & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'BooleanPart' }
      & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'ChoicePart' }
      & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'DatePart' }
      & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'EmailPart' }
      & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'FilePart' }
      & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'ListPart' }
      & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'MoneyPart' }
      & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'NumberPart' }
      & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'PercentPart' }
      & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'PhonePart' }
      & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'StringPart' }
      & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'TextPart' }
      & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    )> }
  )> }
);

export type ReleaseQuestionMutationVariables = Exact<{
  input: ReleaseQuestionInput;
}>;


export type ReleaseQuestionMutation = (
  { __typename?: 'Mutation' }
  & { releaseQuestion?: Maybe<(
    { __typename?: 'Question' }
    & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
    & { parts: Array<(
      { __typename?: 'AddressPart' }
      & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'BooleanPart' }
      & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'ChoicePart' }
      & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'DatePart' }
      & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'EmailPart' }
      & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'FilePart' }
      & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'ListPart' }
      & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'MoneyPart' }
      & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'NumberPart' }
      & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'PercentPart' }
      & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'PhonePart' }
      & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'StringPart' }
      & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'TextPart' }
      & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    )> }
  )> }
);

export type UnreleaseQuestionMutationVariables = Exact<{
  input: UnreleaseQuestionInput;
}>;


export type UnreleaseQuestionMutation = (
  { __typename?: 'Mutation' }
  & { unreleaseQuestion?: Maybe<(
    { __typename?: 'Question' }
    & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
    & { parts: Array<(
      { __typename?: 'AddressPart' }
      & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'BooleanPart' }
      & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'ChoicePart' }
      & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'DatePart' }
      & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'EmailPart' }
      & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'FilePart' }
      & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'ListPart' }
      & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'MoneyPart' }
      & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'NumberPart' }
      & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'PercentPart' }
      & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'PhonePart' }
      & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'StringPart' }
      & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    ) | (
      { __typename?: 'TextPart' }
      & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    )> }
  )> }
);

export type EditQuestionTextMutationVariables = Exact<{
  input: EditQuestionInput;
}>;


export type EditQuestionTextMutation = (
  { __typename?: 'Mutation' }
  & { editQuestion?: Maybe<(
    { __typename?: 'Question' }
    & Pick<Question, 'id' | 'text' | 'description' | 'additionalAdvice'>
  )> }
);

export type RemoveQuestionMutationVariables = Exact<{
  input: RemoveQuestionInput;
}>;


export type RemoveQuestionMutation = (
  { __typename?: 'Mutation' }
  & { removeQuestion?: Maybe<(
    { __typename?: 'QuestionRemovedPayload' }
    & Pick<QuestionRemovedPayload, 'questionId' | 'removedOn'>
  )> }
);

export type AnswerQuestionPartMutationVariables = Exact<{
  input: AnswerQuestionPartInput;
}>;


export type AnswerQuestionPartMutation = (
  { __typename?: 'Mutation' }
  & { answerQuestionPart?: Maybe<(
    { __typename?: 'AddressAnswer' }
    & Pick<AddressAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
    )>, proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'BooleanAnswer' }
    & Pick<BooleanAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
    & { boolean: BooleanAnswer['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'ChoiceAnswer' }
    & Pick<ChoiceAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
    & { choice: ChoiceAnswer['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'DateAnswer' }
    & Pick<DateAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
    & { date: DateAnswer['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'EmailAnswer' }
    & Pick<EmailAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
    & { email: EmailAnswer['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'FileAnswer' }
    & Pick<FileAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
    & { file?: Maybe<Array<(
      { __typename?: 'SupportingArchive' }
      & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'SupportingAudio' }
      & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'SupportingDocument' }
      & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'SupportingImage' }
      & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'SupportingVideo' }
      & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
    )>>, proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'ListAnswer' }
    & Pick<ListAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
    & { list?: Maybe<Array<(
      { __typename?: 'ListItem' }
      & Pick<ListItem, 'ordering'>
      & { properties: Array<(
        { __typename?: 'AddressListItemProperty' }
        & Pick<AddressListItemProperty, 'attributeId'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )> }
      ) | (
        { __typename?: 'DateListItemProperty' }
        & Pick<DateListItemProperty, 'attributeId'>
        & { date: DateListItemProperty['value'] }
      ) | (
        { __typename?: 'EmailListItemProperty' }
        & Pick<EmailListItemProperty, 'attributeId'>
        & { email: EmailListItemProperty['value'] }
      ) | (
        { __typename?: 'FileListItemProperty' }
        & Pick<FileListItemProperty, 'attributeId'>
        & { file: FileListItemProperty['value'] }
      ) | (
        { __typename?: 'MoneyListItemProperty' }
        & Pick<MoneyListItemProperty, 'attributeId'>
        & { money: MoneyListItemProperty['value'] }
      ) | (
        { __typename?: 'NumberListItemProperty' }
        & Pick<NumberListItemProperty, 'attributeId'>
        & { number: NumberListItemProperty['value'] }
      ) | (
        { __typename?: 'PercentListItemProperty' }
        & Pick<PercentListItemProperty, 'attributeId'>
        & { percent: PercentListItemProperty['value'] }
      ) | (
        { __typename?: 'PhoneListItemProperty' }
        & Pick<PhoneListItemProperty, 'attributeId'>
        & { phone: PhoneListItemProperty['value'] }
      ) | (
        { __typename?: 'StringListItemProperty' }
        & Pick<StringListItemProperty, 'attributeId'>
        & { string: StringListItemProperty['value'] }
      ) | (
        { __typename?: 'TextListItemProperty' }
        & Pick<TextListItemProperty, 'attributeId'>
        & { text: TextListItemProperty['value'] }
      )> }
    )>>, proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'MoneyAnswer' }
    & Pick<MoneyAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
    & { money: MoneyAnswer['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'NumberAnswer' }
    & Pick<NumberAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
    & { number: NumberAnswer['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'PercentAnswer' }
    & Pick<PercentAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
    & { percent: PercentAnswer['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'PhoneAnswer' }
    & Pick<PhoneAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
    & { phone: PhoneAnswer['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'StringAnswer' }
    & Pick<StringAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
    & { string: StringAnswer['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'TextAnswer' }
    & Pick<TextAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
    & { text: TextAnswer['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  )> }
);

export type AcceptAnswerMutationVariables = Exact<{
  input: AcceptAnswerInput;
}>;


export type AcceptAnswerMutation = (
  { __typename?: 'Mutation' }
  & { acceptAnswer?: Maybe<(
    { __typename?: 'AddressAnswer' }
    & Pick<AddressAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
    )>, proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'BooleanAnswer' }
    & Pick<BooleanAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable'>
    & { boolean: BooleanAnswer['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'ChoiceAnswer' }
    & Pick<ChoiceAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable'>
    & { choice: ChoiceAnswer['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'DateAnswer' }
    & Pick<DateAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable'>
    & { date: DateAnswer['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'EmailAnswer' }
    & Pick<EmailAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable'>
    & { email: EmailAnswer['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'FileAnswer' }
    & Pick<FileAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable'>
    & { file?: Maybe<Array<(
      { __typename?: 'SupportingArchive' }
      & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'SupportingAudio' }
      & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'SupportingDocument' }
      & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'SupportingImage' }
      & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'SupportingVideo' }
      & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
    )>>, proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'ListAnswer' }
    & Pick<ListAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable'>
    & { list?: Maybe<Array<(
      { __typename?: 'ListItem' }
      & Pick<ListItem, 'ordering'>
      & { properties: Array<(
        { __typename?: 'AddressListItemProperty' }
        & Pick<AddressListItemProperty, 'attributeId'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )> }
      ) | (
        { __typename?: 'DateListItemProperty' }
        & Pick<DateListItemProperty, 'attributeId'>
        & { date: DateListItemProperty['value'] }
      ) | (
        { __typename?: 'EmailListItemProperty' }
        & Pick<EmailListItemProperty, 'attributeId'>
        & { email: EmailListItemProperty['value'] }
      ) | (
        { __typename?: 'FileListItemProperty' }
        & Pick<FileListItemProperty, 'attributeId'>
        & { file: FileListItemProperty['value'] }
      ) | (
        { __typename?: 'MoneyListItemProperty' }
        & Pick<MoneyListItemProperty, 'attributeId'>
        & { money: MoneyListItemProperty['value'] }
      ) | (
        { __typename?: 'NumberListItemProperty' }
        & Pick<NumberListItemProperty, 'attributeId'>
        & { number: NumberListItemProperty['value'] }
      ) | (
        { __typename?: 'PercentListItemProperty' }
        & Pick<PercentListItemProperty, 'attributeId'>
        & { percent: PercentListItemProperty['value'] }
      ) | (
        { __typename?: 'PhoneListItemProperty' }
        & Pick<PhoneListItemProperty, 'attributeId'>
        & { phone: PhoneListItemProperty['value'] }
      ) | (
        { __typename?: 'StringListItemProperty' }
        & Pick<StringListItemProperty, 'attributeId'>
        & { string: StringListItemProperty['value'] }
      ) | (
        { __typename?: 'TextListItemProperty' }
        & Pick<TextListItemProperty, 'attributeId'>
        & { text: TextListItemProperty['value'] }
      )> }
    )>>, proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'MoneyAnswer' }
    & Pick<MoneyAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable'>
    & { money: MoneyAnswer['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'NumberAnswer' }
    & Pick<NumberAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable'>
    & { number: NumberAnswer['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'PercentAnswer' }
    & Pick<PercentAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable'>
    & { percent: PercentAnswer['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'PhoneAnswer' }
    & Pick<PhoneAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable'>
    & { phone: PhoneAnswer['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'StringAnswer' }
    & Pick<StringAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable'>
    & { string: StringAnswer['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'TextAnswer' }
    & Pick<TextAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable'>
    & { text: TextAnswer['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  )> }
);

export type ChangeAnswerLockMutationVariables = Exact<{
  input: ChangeAnswerLockInput;
}>;


export type ChangeAnswerLockMutation = (
  { __typename?: 'Mutation' }
  & { changeAnswerLock?: Maybe<(
    { __typename?: 'AddressAnswer' }
    & Pick<AddressAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
    )>, proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'BooleanAnswer' }
    & Pick<BooleanAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable'>
    & { boolean: BooleanAnswer['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'ChoiceAnswer' }
    & Pick<ChoiceAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable'>
    & { choice: ChoiceAnswer['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'DateAnswer' }
    & Pick<DateAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable'>
    & { date: DateAnswer['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'EmailAnswer' }
    & Pick<EmailAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable'>
    & { email: EmailAnswer['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'FileAnswer' }
    & Pick<FileAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable'>
    & { file?: Maybe<Array<(
      { __typename?: 'SupportingArchive' }
      & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'SupportingAudio' }
      & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'SupportingDocument' }
      & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'SupportingImage' }
      & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'SupportingVideo' }
      & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
    )>>, proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'ListAnswer' }
    & Pick<ListAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable'>
    & { list?: Maybe<Array<(
      { __typename?: 'ListItem' }
      & Pick<ListItem, 'ordering'>
      & { properties: Array<(
        { __typename?: 'AddressListItemProperty' }
        & Pick<AddressListItemProperty, 'attributeId'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )> }
      ) | (
        { __typename?: 'DateListItemProperty' }
        & Pick<DateListItemProperty, 'attributeId'>
        & { date: DateListItemProperty['value'] }
      ) | (
        { __typename?: 'EmailListItemProperty' }
        & Pick<EmailListItemProperty, 'attributeId'>
        & { email: EmailListItemProperty['value'] }
      ) | (
        { __typename?: 'FileListItemProperty' }
        & Pick<FileListItemProperty, 'attributeId'>
        & { file: FileListItemProperty['value'] }
      ) | (
        { __typename?: 'MoneyListItemProperty' }
        & Pick<MoneyListItemProperty, 'attributeId'>
        & { money: MoneyListItemProperty['value'] }
      ) | (
        { __typename?: 'NumberListItemProperty' }
        & Pick<NumberListItemProperty, 'attributeId'>
        & { number: NumberListItemProperty['value'] }
      ) | (
        { __typename?: 'PercentListItemProperty' }
        & Pick<PercentListItemProperty, 'attributeId'>
        & { percent: PercentListItemProperty['value'] }
      ) | (
        { __typename?: 'PhoneListItemProperty' }
        & Pick<PhoneListItemProperty, 'attributeId'>
        & { phone: PhoneListItemProperty['value'] }
      ) | (
        { __typename?: 'StringListItemProperty' }
        & Pick<StringListItemProperty, 'attributeId'>
        & { string: StringListItemProperty['value'] }
      ) | (
        { __typename?: 'TextListItemProperty' }
        & Pick<TextListItemProperty, 'attributeId'>
        & { text: TextListItemProperty['value'] }
      )> }
    )>>, proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'MoneyAnswer' }
    & Pick<MoneyAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable'>
    & { money: MoneyAnswer['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'NumberAnswer' }
    & Pick<NumberAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable'>
    & { number: NumberAnswer['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'PercentAnswer' }
    & Pick<PercentAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable'>
    & { percent: PercentAnswer['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'PhoneAnswer' }
    & Pick<PhoneAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable'>
    & { phone: PhoneAnswer['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'StringAnswer' }
    & Pick<StringAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable'>
    & { string: StringAnswer['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  ) | (
    { __typename?: 'TextAnswer' }
    & Pick<TextAnswer, 'id' | 'proposedOn' | 'status' | 'isHighlighted' | 'notApplicable'>
    & { text: TextAnswer['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), history: Array<(
      { __typename?: 'AddressAnswerRevision' }
      & Pick<AddressAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
      )>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'BooleanAnswerRevision' }
      & Pick<BooleanAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { boolean: BooleanAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ChoiceAnswerRevision' }
      & Pick<ChoiceAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { choice: ChoiceAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'DateAnswerRevision' }
      & Pick<DateAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { date: DateAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'EmailAnswerRevision' }
      & Pick<EmailAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { email: EmailAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'FileAnswerRevision' }
      & Pick<FileAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { file?: Maybe<Array<(
        { __typename?: 'SupportingArchive' }
        & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingAudio' }
        & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingDocument' }
        & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingImage' }
        & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'SupportingVideo' }
        & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'ListAnswerRevision' }
      & Pick<ListAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { list?: Maybe<Array<(
        { __typename?: 'ListItem' }
        & Pick<ListItem, 'ordering'>
        & { properties: Array<(
          { __typename?: 'AddressListItemProperty' }
          & Pick<AddressListItemProperty, 'attributeId'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )> }
        ) | (
          { __typename?: 'DateListItemProperty' }
          & Pick<DateListItemProperty, 'attributeId'>
          & { date: DateListItemProperty['value'] }
        ) | (
          { __typename?: 'EmailListItemProperty' }
          & Pick<EmailListItemProperty, 'attributeId'>
          & { email: EmailListItemProperty['value'] }
        ) | (
          { __typename?: 'FileListItemProperty' }
          & Pick<FileListItemProperty, 'attributeId'>
          & { file: FileListItemProperty['value'] }
        ) | (
          { __typename?: 'MoneyListItemProperty' }
          & Pick<MoneyListItemProperty, 'attributeId'>
          & { money: MoneyListItemProperty['value'] }
        ) | (
          { __typename?: 'NumberListItemProperty' }
          & Pick<NumberListItemProperty, 'attributeId'>
          & { number: NumberListItemProperty['value'] }
        ) | (
          { __typename?: 'PercentListItemProperty' }
          & Pick<PercentListItemProperty, 'attributeId'>
          & { percent: PercentListItemProperty['value'] }
        ) | (
          { __typename?: 'PhoneListItemProperty' }
          & Pick<PhoneListItemProperty, 'attributeId'>
          & { phone: PhoneListItemProperty['value'] }
        ) | (
          { __typename?: 'StringListItemProperty' }
          & Pick<StringListItemProperty, 'attributeId'>
          & { string: StringListItemProperty['value'] }
        ) | (
          { __typename?: 'TextListItemProperty' }
          & Pick<TextListItemProperty, 'attributeId'>
          & { text: TextListItemProperty['value'] }
        )> }
      )>>, proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'MoneyAnswerRevision' }
      & Pick<MoneyAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { money: MoneyAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'NumberAnswerRevision' }
      & Pick<NumberAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { number: NumberAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PercentAnswerRevision' }
      & Pick<PercentAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { percent: PercentAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'PhoneAnswerRevision' }
      & Pick<PhoneAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { phone: PhoneAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'StringAnswerRevision' }
      & Pick<StringAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { string: StringAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    ) | (
      { __typename?: 'TextAnswerRevision' }
      & Pick<TextAnswerRevision, 'id' | 'proposedOn' | 'notApplicable'>
      & { text: TextAnswerRevision['value'] }
      & { proposer: (
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )> }
  )> }
);

export type DraftAnswerQuestionPartMutationVariables = Exact<{
  input: AnswerQuestionPartInput;
}>;


export type DraftAnswerQuestionPartMutation = (
  { __typename?: 'Mutation' }
  & { draftAnswerQuestionPart?: Maybe<(
    { __typename?: 'AddressAnswerDraft' }
    & Pick<AddressAnswerDraft, 'id' | 'notApplicable'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
    )>, proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) }
  ) | (
    { __typename?: 'BooleanAnswerDraft' }
    & Pick<BooleanAnswerDraft, 'id' | 'notApplicable'>
    & { boolean: BooleanAnswerDraft['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) }
  ) | (
    { __typename?: 'ChoiceAnswerDraft' }
    & Pick<ChoiceAnswerDraft, 'id' | 'notApplicable'>
    & { choice: ChoiceAnswerDraft['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) }
  ) | (
    { __typename?: 'DateAnswerDraft' }
    & Pick<DateAnswerDraft, 'id' | 'notApplicable'>
    & { date: DateAnswerDraft['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) }
  ) | (
    { __typename?: 'EmailAnswerDraft' }
    & Pick<EmailAnswerDraft, 'id' | 'notApplicable'>
    & { email: EmailAnswerDraft['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) }
  ) | (
    { __typename?: 'FileAnswerDraft' }
    & Pick<FileAnswerDraft, 'id' | 'notApplicable'>
    & { file?: Maybe<Array<(
      { __typename?: 'SupportingArchive' }
      & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'SupportingAudio' }
      & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'SupportingDocument' }
      & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'SupportingImage' }
      & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'SupportingVideo' }
      & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
    )>>, proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) }
  ) | (
    { __typename?: 'ListAnswerDraft' }
    & Pick<ListAnswerDraft, 'id' | 'notApplicable'>
    & { list?: Maybe<Array<(
      { __typename?: 'ListItem' }
      & Pick<ListItem, 'ordering'>
      & { properties: Array<(
        { __typename?: 'AddressListItemProperty' }
        & Pick<AddressListItemProperty, 'attributeId'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )> }
      ) | (
        { __typename?: 'DateListItemProperty' }
        & Pick<DateListItemProperty, 'attributeId'>
        & { date: DateListItemProperty['value'] }
      ) | (
        { __typename?: 'EmailListItemProperty' }
        & Pick<EmailListItemProperty, 'attributeId'>
        & { email: EmailListItemProperty['value'] }
      ) | (
        { __typename?: 'FileListItemProperty' }
        & Pick<FileListItemProperty, 'attributeId'>
        & { file: FileListItemProperty['value'] }
      ) | (
        { __typename?: 'MoneyListItemProperty' }
        & Pick<MoneyListItemProperty, 'attributeId'>
        & { money: MoneyListItemProperty['value'] }
      ) | (
        { __typename?: 'NumberListItemProperty' }
        & Pick<NumberListItemProperty, 'attributeId'>
        & { number: NumberListItemProperty['value'] }
      ) | (
        { __typename?: 'PercentListItemProperty' }
        & Pick<PercentListItemProperty, 'attributeId'>
        & { percent: PercentListItemProperty['value'] }
      ) | (
        { __typename?: 'PhoneListItemProperty' }
        & Pick<PhoneListItemProperty, 'attributeId'>
        & { phone: PhoneListItemProperty['value'] }
      ) | (
        { __typename?: 'StringListItemProperty' }
        & Pick<StringListItemProperty, 'attributeId'>
        & { string: StringListItemProperty['value'] }
      ) | (
        { __typename?: 'TextListItemProperty' }
        & Pick<TextListItemProperty, 'attributeId'>
        & { text: TextListItemProperty['value'] }
      )> }
    )>>, proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) }
  ) | (
    { __typename?: 'MoneyAnswerDraft' }
    & Pick<MoneyAnswerDraft, 'id' | 'notApplicable'>
    & { money: MoneyAnswerDraft['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) }
  ) | (
    { __typename?: 'NumberAnswerDraft' }
    & Pick<NumberAnswerDraft, 'id' | 'notApplicable'>
    & { number: NumberAnswerDraft['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) }
  ) | (
    { __typename?: 'PercentAnswerDraft' }
    & Pick<PercentAnswerDraft, 'id' | 'notApplicable'>
    & { percent: PercentAnswerDraft['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) }
  ) | (
    { __typename?: 'PhoneAnswerDraft' }
    & Pick<PhoneAnswerDraft, 'id' | 'notApplicable'>
    & { phone: PhoneAnswerDraft['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) }
  ) | (
    { __typename?: 'StringAnswerDraft' }
    & Pick<StringAnswerDraft, 'id' | 'notApplicable'>
    & { string: StringAnswerDraft['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) }
  ) | (
    { __typename?: 'TextAnswerDraft' }
    & Pick<TextAnswerDraft, 'id' | 'notApplicable'>
    & { text: TextAnswerDraft['currentValue'] }
    & { proposer: (
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) }
  )> }
);

export type ClearAnswerDraftMutationVariables = Exact<{
  input: ClearAnswerDraftInput;
}>;


export type ClearAnswerDraftMutation = (
  { __typename?: 'Mutation' }
  & { clearAnswerDraft?: Maybe<(
    { __typename?: 'Question' }
    & Pick<Question, 'id'>
  )> }
);

export type PrepareMatterTaskMutationVariables = Exact<{
  input: PrepareMatterTaskInput;
}>;


export type PrepareMatterTaskMutation = (
  { __typename?: 'Mutation' }
  & { prepareMatterTask?: Maybe<(
    { __typename?: 'MatterTask' }
    & Pick<MatterTask, 'id' | 'name' | 'description' | 'status' | 'sectionCount' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    & { dueDate?: Maybe<(
      { __typename?: 'KeyDate' }
      & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
    )>, sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  )> }
);

export type PrepareFirmTaskMutationVariables = Exact<{
  input: PrepareFirmTaskInput;
}>;


export type PrepareFirmTaskMutation = (
  { __typename?: 'Mutation' }
  & { prepareFirmTask?: Maybe<(
    { __typename?: 'FirmTask' }
    & Pick<FirmTask, 'id' | 'name' | 'description' | 'matterTypes' | 'sectionCount' | 'isModifiable' | 'isPublished' | 'additionalAdvice' | 'officialAdvice'>
  )> }
);

export type ChangeTaskPublishStatusMutationVariables = Exact<{
  input: ChangeTaskPublishStatusInput;
}>;


export type ChangeTaskPublishStatusMutation = (
  { __typename?: 'Mutation' }
  & { changeTaskPublishStatus?: Maybe<(
    { __typename?: 'FirmTask' }
    & Pick<FirmTask, 'id' | 'name' | 'isPublished'>
  ) | (
    { __typename?: 'LibraryTask' }
    & Pick<LibraryTask, 'id' | 'name' | 'isPublished'>
  )> }
);

export type SetTaskDueDateMutationVariables = Exact<{
  input: SetTaskDueDateInput;
}>;


export type SetTaskDueDateMutation = (
  { __typename?: 'Mutation' }
  & { setTaskDueDate?: Maybe<(
    { __typename?: 'MatterTask' }
    & Pick<MatterTask, 'id' | 'status'>
    & { dueDate?: Maybe<(
      { __typename?: 'KeyDate' }
      & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
    )> }
  )> }
);

export type ClearTaskDueDateMutationVariables = Exact<{
  input: ClearTaskDueDateInput;
}>;


export type ClearTaskDueDateMutation = (
  { __typename?: 'Mutation' }
  & { clearTaskDueDate?: Maybe<(
    { __typename?: 'MatterTask' }
    & Pick<MatterTask, 'id' | 'status'>
    & { dueDate?: Maybe<(
      { __typename?: 'KeyDate' }
      & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
    )> }
  )> }
);

export type RemoveTaskMutationVariables = Exact<{
  input: RemoveTaskInput;
}>;


export type RemoveTaskMutation = (
  { __typename?: 'Mutation' }
  & { removeTask?: Maybe<(
    { __typename?: 'TaskRemovedPayload' }
    & Pick<TaskRemovedPayload, 'taskId' | 'removedOn'>
  )> }
);

export type RenameTaskMutationVariables = Exact<{
  input: RenameTaskInput;
}>;


export type RenameTaskMutation = (
  { __typename?: 'Mutation' }
  & { renameTask?: Maybe<(
    { __typename?: 'FirmTask' }
    & Pick<FirmTask, 'id' | 'name' | 'description'>
  ) | (
    { __typename?: 'LibraryTask' }
    & Pick<LibraryTask, 'id' | 'name' | 'description'>
  ) | (
    { __typename?: 'MatterTask' }
    & Pick<MatterTask, 'id' | 'name' | 'description'>
  )> }
);

export type DescribeTaskMutationVariables = Exact<{
  input: DescribeTaskInput;
}>;


export type DescribeTaskMutation = (
  { __typename?: 'Mutation' }
  & { describeTask?: Maybe<(
    { __typename?: 'FirmTask' }
    & Pick<FirmTask, 'id' | 'name' | 'description'>
  ) | (
    { __typename?: 'LibraryTask' }
    & Pick<LibraryTask, 'id' | 'name' | 'description'>
  ) | (
    { __typename?: 'MatterTask' }
    & Pick<MatterTask, 'id' | 'name' | 'description'>
  )> }
);

export type DefineTaskSectionMutationVariables = Exact<{
  input: DefineTaskSectionInput;
}>;


export type DefineTaskSectionMutation = (
  { __typename?: 'Mutation' }
  & { defineTaskSection?: Maybe<(
    { __typename?: 'FirmTask' }
    & Pick<FirmTask, 'id'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  ) | (
    { __typename?: 'LibraryTask' }
    & Pick<LibraryTask, 'id'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  ) | (
    { __typename?: 'MatterTask' }
    & Pick<MatterTask, 'id'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  )> }
);

export type RemoveTaskSectionMutationVariables = Exact<{
  input: RemoveTaskSectionInput;
}>;


export type RemoveTaskSectionMutation = (
  { __typename?: 'Mutation' }
  & { removeTaskSection?: Maybe<(
    { __typename?: 'FirmTask' }
    & Pick<FirmTask, 'id'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  ) | (
    { __typename?: 'LibraryTask' }
    & Pick<LibraryTask, 'id'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  ) | (
    { __typename?: 'MatterTask' }
    & Pick<MatterTask, 'id'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  )> }
);

export type UpdateTaskSectionMutationVariables = Exact<{
  renameInput: RenameTaskSectionInput;
  describeInput: DescribeTaskSectionInput;
}>;


export type UpdateTaskSectionMutation = (
  { __typename?: 'Mutation' }
  & { renameTaskSection?: Maybe<(
    { __typename?: 'FirmTask' }
    & Pick<FirmTask, 'id'>
  ) | (
    { __typename?: 'LibraryTask' }
    & Pick<LibraryTask, 'id'>
  ) | (
    { __typename?: 'MatterTask' }
    & Pick<MatterTask, 'id'>
  )>, describeTaskSection?: Maybe<(
    { __typename?: 'FirmTask' }
    & Pick<FirmTask, 'id'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  ) | (
    { __typename?: 'LibraryTask' }
    & Pick<LibraryTask, 'id'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  ) | (
    { __typename?: 'MatterTask' }
    & Pick<MatterTask, 'id'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  )> }
);

export type RenameTaskSectionMutationVariables = Exact<{
  renameInput: RenameTaskSectionInput;
}>;


export type RenameTaskSectionMutation = (
  { __typename?: 'Mutation' }
  & { renameTaskSection?: Maybe<(
    { __typename?: 'FirmTask' }
    & Pick<FirmTask, 'id'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  ) | (
    { __typename?: 'LibraryTask' }
    & Pick<LibraryTask, 'id'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  ) | (
    { __typename?: 'MatterTask' }
    & Pick<MatterTask, 'id'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  )> }
);

export type DescribeTaskSectionMutationVariables = Exact<{
  input: DescribeTaskSectionInput;
}>;


export type DescribeTaskSectionMutation = (
  { __typename?: 'Mutation' }
  & { describeTaskSection?: Maybe<(
    { __typename?: 'FirmTask' }
    & Pick<FirmTask, 'id'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  ) | (
    { __typename?: 'LibraryTask' }
    & Pick<LibraryTask, 'id'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  ) | (
    { __typename?: 'MatterTask' }
    & Pick<MatterTask, 'id'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  )> }
);

export type ReorderTaskSectionsMutationVariables = Exact<{
  input: ReorderTaskSectionsInput;
}>;


export type ReorderTaskSectionsMutation = (
  { __typename?: 'Mutation' }
  & { reorderTaskSections?: Maybe<(
    { __typename?: 'FirmTask' }
    & Pick<FirmTask, 'id'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  ) | (
    { __typename?: 'LibraryTask' }
    & Pick<LibraryTask, 'id'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  ) | (
    { __typename?: 'MatterTask' }
    & Pick<MatterTask, 'id'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  )> }
);

export type SetTaskSectionDueDateMutationVariables = Exact<{
  input: SetTaskSectionDueDateInput;
}>;


export type SetTaskSectionDueDateMutation = (
  { __typename?: 'Mutation' }
  & { setTaskSectionDueDate?: Maybe<(
    { __typename?: 'FirmTask' }
    & Pick<FirmTask, 'id'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  ) | (
    { __typename?: 'LibraryTask' }
    & Pick<LibraryTask, 'id'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  ) | (
    { __typename?: 'MatterTask' }
    & Pick<MatterTask, 'id'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  )> }
);

export type ClearTaskSectionDueDateMutationVariables = Exact<{
  input: ClearTaskSectionDueDateInput;
}>;


export type ClearTaskSectionDueDateMutation = (
  { __typename?: 'Mutation' }
  & { clearTaskSectionDueDate?: Maybe<(
    { __typename?: 'FirmTask' }
    & Pick<FirmTask, 'id'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  ) | (
    { __typename?: 'LibraryTask' }
    & Pick<LibraryTask, 'id'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  ) | (
    { __typename?: 'MatterTask' }
    & Pick<MatterTask, 'id'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  )> }
);

export type ReleaseTaskMutationVariables = Exact<{
  input: ReleaseTaskInput;
}>;


export type ReleaseTaskMutation = (
  { __typename?: 'Mutation' }
  & { releaseTask?: Maybe<(
    { __typename?: 'MatterTask' }
    & Pick<MatterTask, 'status' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'id' | 'name' | 'description' | 'sectionCount' | 'officialAdvice' | 'additionalAdvice'>
    & { dueDate?: Maybe<(
      { __typename?: 'KeyDate' }
      & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
    )>, sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  )> }
);

export type UnreleaseTaskMutationVariables = Exact<{
  input: UnreleaseTaskInput;
}>;


export type UnreleaseTaskMutation = (
  { __typename?: 'Mutation' }
  & { unreleaseTask?: Maybe<(
    { __typename?: 'MatterTask' }
    & Pick<MatterTask, 'status' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'id' | 'name' | 'description' | 'sectionCount' | 'officialAdvice' | 'additionalAdvice'>
    & { dueDate?: Maybe<(
      { __typename?: 'KeyDate' }
      & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
    )>, sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  )> }
);

export type ReleaseTaskSectionMutationVariables = Exact<{
  input: ReleaseTaskSectionInput;
}>;


export type ReleaseTaskSectionMutation = (
  { __typename?: 'Mutation' }
  & { releaseTaskSection?: Maybe<(
    { __typename?: 'FirmTask' }
    & Pick<FirmTask, 'id' | 'name' | 'description' | 'sectionCount' | 'officialAdvice' | 'additionalAdvice'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  ) | (
    { __typename?: 'LibraryTask' }
    & Pick<LibraryTask, 'id' | 'name' | 'description' | 'sectionCount' | 'officialAdvice' | 'additionalAdvice'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  ) | (
    { __typename?: 'MatterTask' }
    & Pick<MatterTask, 'status' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'id' | 'name' | 'description' | 'sectionCount' | 'officialAdvice' | 'additionalAdvice'>
    & { dueDate?: Maybe<(
      { __typename?: 'KeyDate' }
      & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
    )>, sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  )> }
);

export type UnreleaseTaskSectionMutationVariables = Exact<{
  input: UnreleaseTaskSectionInput;
}>;


export type UnreleaseTaskSectionMutation = (
  { __typename?: 'Mutation' }
  & { unreleaseTaskSection?: Maybe<(
    { __typename?: 'FirmTask' }
    & Pick<FirmTask, 'id' | 'name' | 'description' | 'sectionCount' | 'officialAdvice' | 'additionalAdvice'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  ) | (
    { __typename?: 'LibraryTask' }
    & Pick<LibraryTask, 'id' | 'name' | 'description' | 'sectionCount' | 'officialAdvice' | 'additionalAdvice'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  ) | (
    { __typename?: 'MatterTask' }
    & Pick<MatterTask, 'status' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'id' | 'name' | 'description' | 'sectionCount' | 'officialAdvice' | 'additionalAdvice'>
    & { dueDate?: Maybe<(
      { __typename?: 'KeyDate' }
      & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
    )>, sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  )> }
);

export type ChangeTaskAdviceMutationVariables = Exact<{
  input: ChangeTaskAdviceInput;
}>;


export type ChangeTaskAdviceMutation = (
  { __typename?: 'Mutation' }
  & { changeTaskAdvice?: Maybe<(
    { __typename?: 'FirmTask' }
    & Pick<FirmTask, 'id' | 'officialAdvice' | 'additionalAdvice'>
  ) | (
    { __typename?: 'LibraryTask' }
    & Pick<LibraryTask, 'id' | 'officialAdvice' | 'additionalAdvice'>
  ) | (
    { __typename?: 'MatterTask' }
    & Pick<MatterTask, 'id' | 'officialAdvice' | 'additionalAdvice'>
  )> }
);

export type ChangeTaskSectionAdviceMutationVariables = Exact<{
  input: ChangeTaskSectionAdviceInput;
}>;


export type ChangeTaskSectionAdviceMutation = (
  { __typename?: 'Mutation' }
  & { changeTaskSectionAdvice?: Maybe<(
    { __typename?: 'FirmTask' }
    & Pick<FirmTask, 'id'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'officialAdvice' | 'additionalAdvice'>
    )> }
  ) | (
    { __typename?: 'LibraryTask' }
    & Pick<LibraryTask, 'id'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'officialAdvice' | 'additionalAdvice'>
    )> }
  ) | (
    { __typename?: 'MatterTask' }
    & Pick<MatterTask, 'id'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'officialAdvice' | 'additionalAdvice'>
    )> }
  )> }
);

export type ChangeTaskMatterTypesMutationVariables = Exact<{
  input: ChangeTaskMatterTypesInput;
}>;


export type ChangeTaskMatterTypesMutation = (
  { __typename?: 'Mutation' }
  & { changeTaskMatterTypes?: Maybe<(
    { __typename?: 'FirmTask' }
    & Pick<FirmTask, 'id' | 'matterTypes'>
  ) | (
    { __typename?: 'LibraryTask' }
    & Pick<LibraryTask, 'id' | 'matterTypes'>
  )> }
);

export type CreatePrivateTopicMutationVariables = Exact<{
  input: CreatePrivateTopicInput;
}>;


export type CreatePrivateTopicMutation = (
  { __typename?: 'Mutation' }
  & { createPrivateTopic?: Maybe<(
    { __typename?: 'PrivateTopic' }
    & Pick<PrivateTopic, 'id' | 'ownerId' | 'name' | 'slug' | 'colour'>
  )> }
);

export type UpdateTopicMutationVariables = Exact<{
  input: UpdateTopicInput;
}>;


export type UpdateTopicMutation = (
  { __typename?: 'Mutation' }
  & { updateTopic?: Maybe<(
    { __typename?: 'FirmTopic' }
    & Pick<FirmTopic, 'firmId' | 'id' | 'name' | 'slug' | 'colour'>
  ) | (
    { __typename?: 'GeneralTopic' }
    & Pick<GeneralTopic, 'id' | 'name' | 'slug' | 'colour'>
  ) | (
    { __typename?: 'PrivateTopic' }
    & Pick<PrivateTopic, 'ownerId' | 'id' | 'name' | 'slug' | 'colour'>
  )> }
);

export type RemoveTopicMutationVariables = Exact<{
  input: RemoveTopicInput;
}>;


export type RemoveTopicMutation = (
  { __typename?: 'Mutation' }
  & { removeTopic?: Maybe<(
    { __typename?: 'TopicPayload' }
    & Pick<TopicPayload, 'topicId' | 'removedOn'>
  )> }
);

export type BreadcrumbsQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type BreadcrumbsQueryQuery = (
  { __typename?: 'Query' }
  & { getBreadcrumbs: Array<Maybe<(
    { __typename?: 'Breadcrumb' }
    & Pick<Breadcrumb, 'name' | 'to' | 'image'>
  )>> }
);

export type ListClientAccountsForFirmQueryVariables = Exact<{
  firmId: Scalars['ID'];
  clientId?: Maybe<Scalars['ID']>;
}>;


export type ListClientAccountsForFirmQuery = (
  { __typename?: 'Query' }
  & { clientAccountsForFirm?: Maybe<(
    { __typename?: 'ClientAccountList' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'ClientAccount' }
      & Pick<ClientAccount, 'id' | 'isHighlighted' | 'status' | 'referenceNumber'>
      & { client: (
        { __typename?: 'FirmClient' }
        & Pick<FirmClient, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl' | 'emailAddress' | 'mobilePhoneNumber' | 'nationalInsuranceNumber' | 'isVerified' | 'profileIsComplete' | 'status' | 'referenceNumber'>
        & { issues: Array<(
          { __typename?: 'Issue' }
          & Pick<Issue, 'id' | 'type' | 'isLinked' | 'linkCode' | 'linkRequested'>
          & { opponentIssue?: Maybe<(
            { __typename?: 'Issue' }
            & Pick<Issue, 'id'>
            & { legalTeam: Array<(
              { __typename?: 'LegalTeamMember' }
              & Pick<LegalTeamMember, 'id' | 'jobTitle' | 'isLead'>
              & { firm: (
                { __typename?: 'Firm' }
                & Pick<Firm, 'id' | 'name'>
              ), professional: (
                { __typename?: 'FirmBarrister' }
                & Pick<FirmBarrister, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
              ) | (
                { __typename?: 'FirmParalegal' }
                & Pick<FirmParalegal, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
              ) | (
                { __typename?: 'FirmSolicitor' }
                & Pick<FirmSolicitor, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
              ) | (
                { __typename?: 'FirmSupport' }
                & Pick<FirmSupport, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
              ) }
            )> }
          )>, opponent?: Maybe<(
            { __typename?: 'Opponent' }
            & Pick<Opponent, 'preferredName' | 'firstName' | 'lastName' | 'emailAddress' | 'nationalInsuranceNumber' | 'clientReferenceNumber' | 'isVerified'>
            & { solicitor: (
              { __typename?: 'OpponentSolicitor' }
              & Pick<OpponentSolicitor, 'firstName' | 'lastName' | 'emailAddress' | 'firmName'>
            ) }
          )> }
        )> }
      ), leadProfessional: (
        { __typename?: 'FirmBarrister' }
        & Pick<FirmBarrister, 'id' | 'preferredName' | 'firstName' | 'lastName'>
      ) | (
        { __typename?: 'FirmParalegal' }
        & Pick<FirmParalegal, 'id' | 'preferredName' | 'firstName' | 'lastName'>
      ) | (
        { __typename?: 'FirmSolicitor' }
        & Pick<FirmSolicitor, 'id' | 'preferredName' | 'firstName' | 'lastName'>
      ) | (
        { __typename?: 'FirmSupport' }
        & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'lastName'>
      ), firm: (
        { __typename?: 'Firm' }
        & Pick<Firm, 'id' | 'name'>
      ) }
    )>>> }
  )> }
);

export type ListClientAccountsForProfessionalQueryVariables = Exact<{
  firmId?: Maybe<Scalars['ID']>;
  professionalId: Scalars['ID'];
}>;


export type ListClientAccountsForProfessionalQuery = (
  { __typename?: 'Query' }
  & { clientAccountsForProfessional?: Maybe<(
    { __typename?: 'ClientAccountList' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'ClientAccount' }
      & Pick<ClientAccount, 'id' | 'isHighlighted' | 'status' | 'referenceNumber'>
      & { client: (
        { __typename?: 'FirmClient' }
        & Pick<FirmClient, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl' | 'emailAddress' | 'mobilePhoneNumber' | 'nationalInsuranceNumber' | 'isVerified' | 'profileIsComplete' | 'status' | 'referenceNumber'>
        & { issues: Array<(
          { __typename?: 'Issue' }
          & Pick<Issue, 'id' | 'type' | 'isLinked' | 'linkCode' | 'linkRequested'>
          & { opponentIssue?: Maybe<(
            { __typename?: 'Issue' }
            & Pick<Issue, 'id'>
            & { legalTeam: Array<(
              { __typename?: 'LegalTeamMember' }
              & Pick<LegalTeamMember, 'id' | 'jobTitle' | 'isLead'>
              & { firm: (
                { __typename?: 'Firm' }
                & Pick<Firm, 'id' | 'name'>
              ), professional: (
                { __typename?: 'FirmBarrister' }
                & Pick<FirmBarrister, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
              ) | (
                { __typename?: 'FirmParalegal' }
                & Pick<FirmParalegal, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
              ) | (
                { __typename?: 'FirmSolicitor' }
                & Pick<FirmSolicitor, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
              ) | (
                { __typename?: 'FirmSupport' }
                & Pick<FirmSupport, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
              ) }
            )> }
          )>, opponent?: Maybe<(
            { __typename?: 'Opponent' }
            & Pick<Opponent, 'preferredName' | 'firstName' | 'lastName' | 'emailAddress' | 'nationalInsuranceNumber' | 'clientReferenceNumber' | 'isVerified'>
            & { solicitor: (
              { __typename?: 'OpponentSolicitor' }
              & Pick<OpponentSolicitor, 'firstName' | 'lastName' | 'emailAddress' | 'firmName'>
            ) }
          )> }
        )> }
      ), leadProfessional: (
        { __typename?: 'FirmBarrister' }
        & Pick<FirmBarrister, 'id' | 'preferredName' | 'firstName' | 'lastName'>
      ) | (
        { __typename?: 'FirmParalegal' }
        & Pick<FirmParalegal, 'id' | 'preferredName' | 'firstName' | 'lastName'>
      ) | (
        { __typename?: 'FirmSolicitor' }
        & Pick<FirmSolicitor, 'id' | 'preferredName' | 'firstName' | 'lastName'>
      ) | (
        { __typename?: 'FirmSupport' }
        & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'lastName'>
      ), firm: (
        { __typename?: 'Firm' }
        & Pick<Firm, 'id' | 'name'>
      ) }
    )>>> }
  )> }
);

export type ListClientAccountsForClientQueryVariables = Exact<{
  clientId: Scalars['ID'];
  firmId?: Maybe<Scalars['ID']>;
}>;


export type ListClientAccountsForClientQuery = (
  { __typename?: 'Query' }
  & { clientAccountsForClient?: Maybe<(
    { __typename?: 'ClientAccountList' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'ClientAccount' }
      & Pick<ClientAccount, 'id' | 'status' | 'referenceNumber'>
      & { firm: (
        { __typename?: 'Firm' }
        & Pick<Firm, 'id' | 'name' | 'logoUrl'>
      ) }
    )>>> }
  )> }
);

export type ListClientAssignmentsForProfessionalQueryVariables = Exact<{
  professionalId: Scalars['ID'];
  firmId?: Maybe<Scalars['ID']>;
}>;


export type ListClientAssignmentsForProfessionalQuery = (
  { __typename?: 'Query' }
  & { assignmentsForProfessional?: Maybe<Array<Maybe<(
    { __typename?: 'ClientAssignment' }
    & Pick<ClientAssignment, 'id'>
    & { clientAccount: (
      { __typename?: 'ClientAccount' }
      & Pick<ClientAccount, 'id' | 'isHighlighted' | 'status' | 'referenceNumber'>
      & { client: (
        { __typename?: 'FirmClient' }
        & Pick<FirmClient, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl' | 'emailAddress' | 'mobilePhoneNumber' | 'nationalInsuranceNumber' | 'isVerified' | 'profileIsComplete' | 'status' | 'referenceNumber'>
        & { issues: Array<(
          { __typename?: 'Issue' }
          & Pick<Issue, 'id' | 'type' | 'isLinked' | 'linkCode' | 'linkRequested'>
          & { opponentIssue?: Maybe<(
            { __typename?: 'Issue' }
            & Pick<Issue, 'id'>
            & { legalTeam: Array<(
              { __typename?: 'LegalTeamMember' }
              & Pick<LegalTeamMember, 'id' | 'jobTitle' | 'isLead'>
              & { firm: (
                { __typename?: 'Firm' }
                & Pick<Firm, 'id' | 'name'>
              ), professional: (
                { __typename?: 'FirmBarrister' }
                & Pick<FirmBarrister, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
              ) | (
                { __typename?: 'FirmParalegal' }
                & Pick<FirmParalegal, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
              ) | (
                { __typename?: 'FirmSolicitor' }
                & Pick<FirmSolicitor, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
              ) | (
                { __typename?: 'FirmSupport' }
                & Pick<FirmSupport, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
              ) }
            )> }
          )>, opponent?: Maybe<(
            { __typename?: 'Opponent' }
            & Pick<Opponent, 'preferredName' | 'firstName' | 'lastName' | 'emailAddress' | 'nationalInsuranceNumber' | 'clientReferenceNumber' | 'isVerified'>
            & { solicitor: (
              { __typename?: 'OpponentSolicitor' }
              & Pick<OpponentSolicitor, 'firstName' | 'lastName' | 'emailAddress' | 'firmName'>
            ) }
          )> }
        )> }
      ), leadProfessional: (
        { __typename?: 'FirmBarrister' }
        & Pick<FirmBarrister, 'id' | 'preferredName' | 'firstName' | 'lastName'>
      ) | (
        { __typename?: 'FirmParalegal' }
        & Pick<FirmParalegal, 'id' | 'preferredName' | 'firstName' | 'lastName'>
      ) | (
        { __typename?: 'FirmSolicitor' }
        & Pick<FirmSolicitor, 'id' | 'preferredName' | 'firstName' | 'lastName'>
      ) | (
        { __typename?: 'FirmSupport' }
        & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'lastName'>
      ), firm: (
        { __typename?: 'Firm' }
        & Pick<Firm, 'id' | 'name'>
      ) }
    ) }
  )>>> }
);

export type ListClientAssignmentsForClientQueryVariables = Exact<{
  clientId: Scalars['ID'];
  firmId?: Maybe<Scalars['ID']>;
}>;


export type ListClientAssignmentsForClientQuery = (
  { __typename?: 'Query' }
  & { assignmentsForClient?: Maybe<Array<Maybe<(
    { __typename?: 'ClientAssignment' }
    & Pick<ClientAssignment, 'id' | 'isLead'>
    & { firmMember: (
      { __typename?: 'FirmMember' }
      & Pick<FirmMember, 'id' | 'employeeNumber' | 'jobTitle' | 'office' | 'isActive' | 'isHighlighted' | 'activeSince' | 'role' | 'status'>
      & { professional: (
        { __typename?: 'FirmBarrister' }
        & Pick<FirmBarrister, 'sraNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'status' | 'referenceNumber'>
      ) | (
        { __typename?: 'FirmParalegal' }
        & Pick<FirmParalegal, 'paralegalNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'status' | 'referenceNumber'>
      ) | (
        { __typename?: 'FirmSolicitor' }
        & Pick<FirmSolicitor, 'sraNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'status' | 'referenceNumber'>
      ) | (
        { __typename?: 'FirmSupport' }
        & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'status' | 'referenceNumber'>
      ), firm: (
        { __typename?: 'Firm' }
        & Pick<Firm, 'id' | 'name' | 'logoUrl'>
      ) }
    ) }
  )>>> }
);

export type GetClientAccountQueryVariables = Exact<{
  clientAccountId: Scalars['ID'];
}>;


export type GetClientAccountQuery = (
  { __typename?: 'Query' }
  & { clientAccount?: Maybe<(
    { __typename?: 'ClientAccount' }
    & Pick<ClientAccount, 'id' | 'isHighlighted' | 'status' | 'referenceNumber'>
    & { client: (
      { __typename?: 'FirmClient' }
      & Pick<FirmClient, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl' | 'emailAddress' | 'mobilePhoneNumber' | 'nationalInsuranceNumber' | 'profileIsComplete' | 'status' | 'referenceNumber'>
      & { issues: Array<(
        { __typename?: 'Issue' }
        & Pick<Issue, 'id' | 'type' | 'isLinked' | 'linkCode' | 'linkRequested'>
        & { opponentIssue?: Maybe<(
          { __typename?: 'Issue' }
          & Pick<Issue, 'id'>
          & { legalTeam: Array<(
            { __typename?: 'LegalTeamMember' }
            & Pick<LegalTeamMember, 'id' | 'jobTitle' | 'isLead'>
            & { firm: (
              { __typename?: 'Firm' }
              & Pick<Firm, 'id' | 'name'>
            ), professional: (
              { __typename?: 'FirmBarrister' }
              & Pick<FirmBarrister, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
            ) | (
              { __typename?: 'FirmParalegal' }
              & Pick<FirmParalegal, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
            ) | (
              { __typename?: 'FirmSolicitor' }
              & Pick<FirmSolicitor, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
            ) | (
              { __typename?: 'FirmSupport' }
              & Pick<FirmSupport, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
            ) }
          )> }
        )>, opponent?: Maybe<(
          { __typename?: 'Opponent' }
          & Pick<Opponent, 'preferredName' | 'firstName' | 'lastName' | 'emailAddress' | 'nationalInsuranceNumber' | 'clientReferenceNumber' | 'isVerified'>
          & { solicitor: (
            { __typename?: 'OpponentSolicitor' }
            & Pick<OpponentSolicitor, 'firstName' | 'lastName' | 'emailAddress' | 'firmName'>
          ) }
        )>, opponentConversation?: Maybe<(
          { __typename?: 'Conversation' }
          & Pick<Conversation, 'id'>
        )> }
      )> }
    ), leadProfessional: (
      { __typename?: 'FirmBarrister' }
      & Pick<FirmBarrister, 'id' | 'preferredName' | 'firstName' | 'lastName'>
    ) | (
      { __typename?: 'FirmParalegal' }
      & Pick<FirmParalegal, 'id' | 'preferredName' | 'firstName' | 'lastName'>
    ) | (
      { __typename?: 'FirmSolicitor' }
      & Pick<FirmSolicitor, 'id' | 'preferredName' | 'firstName' | 'lastName'>
    ) | (
      { __typename?: 'FirmSupport' }
      & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'lastName'>
    ), firm: (
      { __typename?: 'Firm' }
      & Pick<Firm, 'id' | 'name'>
    ) }
  )> }
);

export type ListClientAccountsByNotesQueryVariables = Exact<{
  firmId: Scalars['ID'];
  professionalId: Scalars['ID'];
}>;


export type ListClientAccountsByNotesQuery = (
  { __typename?: 'Query' }
  & { clientAccountsForProfessional?: Maybe<(
    { __typename?: 'ClientAccountList' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'ClientAccount' }
      & Pick<ClientAccount, 'id' | 'status' | 'referenceNumber'>
      & { client: (
        { __typename?: 'FirmClient' }
        & Pick<FirmClient, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ), notes?: Maybe<(
        { __typename?: 'NoteList' }
        & { items?: Maybe<Array<Maybe<(
          { __typename?: 'Note' }
          & Pick<Note, 'id' | 'isViewed' | 'startedAt'>
          & { revisions: Array<(
            { __typename?: 'NoteRevision' }
            & Pick<NoteRevision, 'draftedAt'>
          )> }
        )>>> }
      )> }
    )>>> }
  )> }
);

export type ListClientAccountHighlightsQueryVariables = Exact<{
  firmId: Scalars['ID'];
  professionalId: Scalars['ID'];
}>;


export type ListClientAccountHighlightsQuery = (
  { __typename?: 'Query' }
  & { clientAccountsForProfessional?: Maybe<(
    { __typename?: 'ClientAccountList' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'ClientAccount' }
      & Pick<ClientAccount, 'id' | 'status' | 'referenceNumber'>
      & { client: (
        { __typename?: 'FirmClient' }
        & Pick<FirmClient, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ), highlights: (
        { __typename?: 'HighlightCountsPayload' }
        & Pick<HighlightCountsPayload, 'conversationCount' | 'messageCount' | 'noteCount' | 'firmClientCount' | 'clientAssignmentCount' | 'matterCount' | 'keyDateCount' | 'taskCount' | 'clientCount' | 'questionCount' | 'answerCount' | 'firmMemberCount' | 'contactCount'>
      ) }
    )>>> }
  )> }
);

export type GetClientProfileQueryVariables = Exact<{
  clientId: Scalars['ID'];
}>;


export type GetClientProfileQuery = (
  { __typename?: 'Query' }
  & { client?: Maybe<(
    { __typename?: 'FirmClient' }
    & Pick<FirmClient, 'id' | 'name' | 'fullName' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'previousFirstName' | 'previousLastName' | 'previousMiddleNames' | 'pictureUrl' | 'birthDate' | 'emailAddress' | 'mobilePhoneNumber' | 'homePhoneNumber' | 'workPhoneNumber' | 'nationalInsuranceNumber' | 'profileIsComplete' | 'status' | 'referenceNumber' | 'isVerified' | 'verifiedAt'>
    & { homeAddress?: Maybe<(
      { __typename?: 'Address' }
      & AddressFieldsFragment
    )>, previousHomeAddresses?: Maybe<Array<(
      { __typename?: 'Address' }
      & AddressFieldsFragment
    )>>, workAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'name'>
      & AddressFieldsFragment
    )>, addressProof?: Maybe<Array<(
      { __typename?: 'AddressProof' }
      & Pick<AddressProof, 'id' | 'type' | 'reference' | 'assetUrl' | 'country' | 'expiresAt' | 'assetFileId' | 'assetMime' | 'assetFileVersion'>
    )>>, identityProof?: Maybe<Array<(
      { __typename?: 'IdentityProof' }
      & Pick<IdentityProof, 'id' | 'type' | 'reference' | 'assetUrl' | 'country' | 'expiresAt' | 'assetFileId' | 'assetMime' | 'assetFileVersion'>
    )>>, bankDetails?: Maybe<(
      { __typename?: 'BankDetails' }
      & Pick<BankDetails, 'name' | 'accountNumber' | 'sortCode'>
      & { branchAddress?: Maybe<(
        { __typename?: 'Address' }
        & AddressFieldsFragment
      )> }
    )>, issues: Array<(
      { __typename?: 'Issue' }
      & Pick<Issue, 'id' | 'type' | 'isLinked' | 'linkCode' | 'linkRequested'>
      & { opponentIssue?: Maybe<(
        { __typename?: 'Issue' }
        & Pick<Issue, 'id'>
        & { legalTeam: Array<(
          { __typename?: 'LegalTeamMember' }
          & Pick<LegalTeamMember, 'id' | 'jobTitle' | 'isLead'>
          & { firm: (
            { __typename?: 'Firm' }
            & Pick<Firm, 'id' | 'name'>
          ), professional: (
            { __typename?: 'FirmBarrister' }
            & Pick<FirmBarrister, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
          ) | (
            { __typename?: 'FirmParalegal' }
            & Pick<FirmParalegal, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
          ) | (
            { __typename?: 'FirmSolicitor' }
            & Pick<FirmSolicitor, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
          ) | (
            { __typename?: 'FirmSupport' }
            & Pick<FirmSupport, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
          ) }
        )> }
      )>, opponent?: Maybe<(
        { __typename?: 'Opponent' }
        & Pick<Opponent, 'preferredName' | 'firstName' | 'lastName' | 'emailAddress' | 'nationalInsuranceNumber' | 'clientReferenceNumber' | 'isVerified'>
        & { solicitor: (
          { __typename?: 'OpponentSolicitor' }
          & Pick<OpponentSolicitor, 'firstName' | 'lastName' | 'emailAddress' | 'firmName'>
        ) }
      )> }
    )> }
  )> }
);

export type ContactListQueryVariables = Exact<{ [key: string]: never; }>;


export type ContactListQuery = (
  { __typename?: 'Query' }
  & { contactList: Array<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl' | 'emailAddress' | 'isVerified' | 'isTransparentlyUser' | 'isAssociatedWithFirm' | 'type' | 'status' | 'conversationId' | 'title' | 'isHighlighted' | 'isAssociatedWithIssue'>
    & { firm?: Maybe<(
      { __typename?: 'Firm' }
      & Pick<Firm, 'id' | 'name' | 'logoUrl'>
    )>, issue?: Maybe<(
      { __typename?: 'Issue' }
      & Pick<Issue, 'id' | 'type'>
    )> }
  )> }
);

export type ListConversationsQueryVariables = Exact<{
  memberId: Scalars['ID'];
}>;


export type ListConversationsQuery = (
  { __typename?: 'Query' }
  & { conversations?: Maybe<Array<Maybe<(
    { __typename?: 'Conversation' }
    & Pick<Conversation, 'id' | 'pictureUrl' | 'name' | 'description' | 'startedAt' | 'unreadMessageCount' | 'lastMessageReadAt' | 'lastMessageSentAt' | 'lastMessageDeliveredAt' | 'pausedUntil'>
    & { participants: Array<(
      { __typename?: 'ConversationParticipant' }
      & Pick<ConversationParticipant, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl' | 'role' | 'title' | 'status'>
    )>, groups: Array<(
      { __typename?: 'ConversationGroup' }
      & Pick<ConversationGroup, 'id' | 'name' | 'pictureUrl'>
      & { members: Array<(
        { __typename?: 'ConversationObserver' }
        & Pick<ConversationObserver, 'id' | 'name' | 'pictureUrl' | 'title'>
      ) | (
        { __typename?: 'ConversationParticipant' }
        & Pick<ConversationParticipant, 'id' | 'name' | 'pictureUrl' | 'title'>
      )> }
    )>, lastMessage?: Maybe<(
      { __typename?: 'Message' }
      & Pick<Message, 'id'>
    )>, metadata: Array<(
      { __typename?: 'KeyValueArray' }
      & Pick<KeyValueArray, 'key'>
      & { values: KeyValueArray['value'] }
    ) | (
      { __typename?: 'KeyValueString' }
      & Pick<KeyValueString, 'key' | 'value'>
    )> }
  )>>> }
);

export type GetConversationQueryVariables = Exact<{
  conversationId: Scalars['ID'];
}>;


export type GetConversationQuery = (
  { __typename?: 'Query' }
  & { conversation?: Maybe<(
    { __typename?: 'Conversation' }
    & Pick<Conversation, 'pausedUntil'>
    & { participants: Array<(
      { __typename?: 'ConversationParticipant' }
      & Pick<ConversationParticipant, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl' | 'role' | 'title' | 'status'>
    )>, groups: Array<(
      { __typename?: 'ConversationGroup' }
      & Pick<ConversationGroup, 'id' | 'name' | 'pictureUrl'>
      & { members: Array<(
        { __typename?: 'ConversationObserver' }
        & Pick<ConversationObserver, 'id' | 'name' | 'pictureUrl' | 'title'>
      ) | (
        { __typename?: 'ConversationParticipant' }
        & Pick<ConversationParticipant, 'id' | 'name' | 'pictureUrl' | 'title'>
      )> }
    )>, draft?: Maybe<(
      { __typename?: 'MessageDraft' }
      & Pick<MessageDraft, 'body'>
    )> }
  )> }
);

export type GetConversationWithQueryVariables = Exact<{
  participantId: Scalars['ID'];
}>;


export type GetConversationWithQuery = (
  { __typename?: 'Query' }
  & { conversationWith?: Maybe<(
    { __typename?: 'Conversation' }
    & Pick<Conversation, 'id'>
  )> }
);

export type ConversationFragment = (
  { __typename?: 'Conversation' }
  & Pick<Conversation, 'id' | 'pictureUrl' | 'name' | 'description' | 'startedAt' | 'unreadMessageCount' | 'lastMessageReadAt' | 'lastMessageSentAt' | 'lastMessageDeliveredAt' | 'pausedUntil'>
  & { participants: Array<(
    { __typename?: 'ConversationParticipant' }
    & Pick<ConversationParticipant, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl' | 'role' | 'title' | 'status'>
  )>, groups: Array<(
    { __typename?: 'ConversationGroup' }
    & Pick<ConversationGroup, 'id' | 'name' | 'pictureUrl'>
    & { members: Array<(
      { __typename?: 'ConversationObserver' }
      & Pick<ConversationObserver, 'id' | 'name' | 'pictureUrl' | 'title'>
    ) | (
      { __typename?: 'ConversationParticipant' }
      & Pick<ConversationParticipant, 'id' | 'name' | 'pictureUrl' | 'title'>
    )> }
  )>, lastMessage?: Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'id'>
  )>, metadata: Array<(
    { __typename?: 'KeyValueArray' }
    & Pick<KeyValueArray, 'key'>
    & { values: KeyValueArray['value'] }
  ) | (
    { __typename?: 'KeyValueString' }
    & Pick<KeyValueString, 'key' | 'value'>
  )> }
);

export type ListClientConversationsQueryVariables = Exact<{
  clientAccountId: Scalars['ID'];
}>;


export type ListClientConversationsQuery = (
  { __typename?: 'Query' }
  & { clientAccount?: Maybe<(
    { __typename?: 'ClientAccount' }
    & Pick<ClientAccount, 'id'>
    & { clientConversation?: Maybe<(
      { __typename?: 'Conversation' }
      & ConversationFragment
    )>, firmConversation?: Maybe<(
      { __typename?: 'Conversation' }
      & ConversationFragment
    )>, client: (
      { __typename?: 'FirmClient' }
      & Pick<FirmClient, 'id'>
      & { issues: Array<(
        { __typename?: 'Issue' }
        & Pick<Issue, 'id' | 'type'>
        & { opponentConversation?: Maybe<(
          { __typename?: 'Conversation' }
          & ConversationFragment
        )>, disclosureConversation?: Maybe<(
          { __typename?: 'Conversation' }
          & ConversationFragment
        )> }
      )> }
    ) }
  )> }
);

export type GetCurrentUserClientQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserClientQuery = (
  { __typename?: 'Query' }
  & { getCurrentUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'givenName' | 'middleName' | 'familyName' | 'picture' | 'email' | 'phone'>
    & { roles: Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name' | 'description'>
    )> }
  ) }
);

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'preferredName' | 'givenName' | 'middleName' | 'familyName' | 'picture' | 'email' | 'phone' | 'blocked' | 'referenceNumber'>
    & { roles: Array<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name' | 'description'>
    )> }
  )> }
);

export type GetFirmQueryVariables = Exact<{
  firmId: Scalars['ID'];
}>;


export type GetFirmQuery = (
  { __typename?: 'Query' }
  & { firm?: Maybe<(
    { __typename?: 'Firm' }
    & Pick<Firm, 'id' | 'name' | 'logoUrl' | 'sraNumber' | 'seatCount' | 'vacantSeatCount' | 'occupiedSeatCount'>
    & { members: Array<(
      { __typename?: 'FirmMember' }
      & Pick<FirmMember, 'id' | 'role' | 'employeeNumber' | 'jobTitle' | 'office' | 'isActive' | 'isHighlighted' | 'activeSince' | 'status'>
      & { professional: (
        { __typename?: 'FirmBarrister' }
        & Pick<FirmBarrister, 'sraNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'status' | 'referenceNumber'>
      ) | (
        { __typename?: 'FirmParalegal' }
        & Pick<FirmParalegal, 'paralegalNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'status' | 'referenceNumber'>
      ) | (
        { __typename?: 'FirmSolicitor' }
        & Pick<FirmSolicitor, 'sraNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'status' | 'referenceNumber'>
      ) | (
        { __typename?: 'FirmSupport' }
        & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'status' | 'referenceNumber'>
      ), firm: (
        { __typename?: 'Firm' }
        & Pick<Firm, 'id' | 'name' | 'logoUrl'>
      ) }
    )> }
  )> }
);

export type GetFirmMemberQueryVariables = Exact<{
  firmMemberId: Scalars['ID'];
}>;


export type GetFirmMemberQuery = (
  { __typename?: 'Query' }
  & { firmMember?: Maybe<(
    { __typename?: 'FirmMember' }
    & Pick<FirmMember, 'id' | 'role' | 'employeeNumber' | 'jobTitle' | 'office' | 'isActive' | 'isHighlighted' | 'activeSince' | 'status'>
    & { professional: (
      { __typename?: 'FirmBarrister' }
      & Pick<FirmBarrister, 'sraNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'referenceNumber' | 'status'>
    ) | (
      { __typename?: 'FirmParalegal' }
      & Pick<FirmParalegal, 'paralegalNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'referenceNumber' | 'status'>
    ) | (
      { __typename?: 'FirmSolicitor' }
      & Pick<FirmSolicitor, 'sraNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'referenceNumber' | 'status'>
    ) | (
      { __typename?: 'FirmSupport' }
      & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'referenceNumber' | 'status'>
    ), firm: (
      { __typename?: 'Firm' }
      & Pick<Firm, 'id' | 'name' | 'logoUrl'>
    ) }
  )> }
);

export type ListFirmMembersQueryVariables = Exact<{
  firmId: Scalars['ID'];
}>;


export type ListFirmMembersQuery = (
  { __typename?: 'Query' }
  & { firmMembers?: Maybe<Array<Maybe<(
    { __typename?: 'FirmMember' }
    & Pick<FirmMember, 'id' | 'role' | 'employeeNumber' | 'jobTitle' | 'office' | 'isActive' | 'isHighlighted' | 'activeSince' | 'status'>
    & { professional: (
      { __typename?: 'FirmBarrister' }
      & Pick<FirmBarrister, 'sraNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'referenceNumber' | 'status'>
    ) | (
      { __typename?: 'FirmParalegal' }
      & Pick<FirmParalegal, 'paralegalNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'referenceNumber' | 'status'>
    ) | (
      { __typename?: 'FirmSolicitor' }
      & Pick<FirmSolicitor, 'sraNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'referenceNumber' | 'status'>
    ) | (
      { __typename?: 'FirmSupport' }
      & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'referenceNumber' | 'status'>
    ), firm: (
      { __typename?: 'Firm' }
      & Pick<Firm, 'id' | 'name' | 'logoUrl'>
    ) }
  )>>> }
);

export type ListFirmMembershipsQueryVariables = Exact<{
  professionalId: Scalars['ID'];
}>;


export type ListFirmMembershipsQuery = (
  { __typename?: 'Query' }
  & { firmMemberships?: Maybe<Array<Maybe<(
    { __typename?: 'FirmMember' }
    & Pick<FirmMember, 'id' | 'employeeNumber' | 'jobTitle' | 'office' | 'isActive' | 'isHighlighted' | 'activeSince' | 'role' | 'status'>
    & { professional: (
      { __typename?: 'FirmBarrister' }
      & Pick<FirmBarrister, 'sraNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'referenceNumber' | 'status'>
    ) | (
      { __typename?: 'FirmParalegal' }
      & Pick<FirmParalegal, 'paralegalNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'referenceNumber' | 'status'>
    ) | (
      { __typename?: 'FirmSolicitor' }
      & Pick<FirmSolicitor, 'sraNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'referenceNumber' | 'status'>
    ) | (
      { __typename?: 'FirmSupport' }
      & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'referenceNumber' | 'status'>
    ), firm: (
      { __typename?: 'Firm' }
      & Pick<Firm, 'id' | 'name' | 'sraNumber' | 'logoUrl'>
    ) }
  )>>> }
);

export type ListFoldersQueryVariables = Exact<{
  parentId?: Maybe<Scalars['ID']>;
}>;


export type ListFoldersQuery = (
  { __typename?: 'Query' }
  & { folders?: Maybe<Array<Maybe<(
    { __typename?: 'Folder' }
    & Pick<Folder, 'id' | 'name' | 'isRestricted'>
    & { owner?: Maybe<(
      { __typename?: 'FileOwner' }
      & Pick<FileOwner, 'preferredName' | 'firstName' | 'lastName'>
    )>, subFolders: Array<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'name' | 'isRestricted'>
    )>, files: Array<(
      { __typename?: 'ArchiveFile' }
      & Pick<ArchiveFile, 'id'>
    ) | (
      { __typename?: 'AudioFile' }
      & Pick<AudioFile, 'id'>
    ) | (
      { __typename?: 'DocumentFile' }
      & Pick<DocumentFile, 'id'>
    ) | (
      { __typename?: 'ImageFile' }
      & Pick<ImageFile, 'id'>
    ) | (
      { __typename?: 'VideoFile' }
      & Pick<VideoFile, 'id'>
    )>, parent?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'isRestricted' | 'name'>
      & { parent?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'isRestricted' | 'name'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'isRestricted' | 'name'>
          & { parent?: Maybe<(
            { __typename?: 'Folder' }
            & Pick<Folder, 'id' | 'isRestricted' | 'name'>
          )> }
        )> }
      )> }
    )> }
  )>>> }
);

export type GetFoldersQueryVariables = Exact<{
  folderId?: Maybe<Scalars['ID']>;
}>;


export type GetFoldersQuery = (
  { __typename?: 'Query' }
  & { folder?: Maybe<(
    { __typename?: 'Folder' }
    & Pick<Folder, 'id' | 'name' | 'isRestricted'>
    & { owner?: Maybe<(
      { __typename?: 'FileOwner' }
      & Pick<FileOwner, 'preferredName' | 'firstName' | 'lastName'>
    )>, subFolders: Array<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'name' | 'isRestricted'>
    )>, files: Array<(
      { __typename?: 'ArchiveFile' }
      & Pick<ArchiveFile, 'id' | 'name' | 'type' | 'mime' | 'size' | 'version' | 'lastRevisedOn' | 'currentRevisionId'>
      & { revisions: Array<(
        { __typename?: 'ArchiveFileRevision' }
        & Pick<ArchiveFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
      ) | (
        { __typename?: 'AudioFileRevision' }
        & Pick<AudioFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
      ) | (
        { __typename?: 'DocumentFileRevision' }
        & Pick<DocumentFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
      ) | (
        { __typename?: 'ImageFileRevision' }
        & Pick<ImageFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
      ) | (
        { __typename?: 'VideoFileRevision' }
        & Pick<VideoFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
      )>, topics: Array<(
        { __typename?: 'FirmTopic' }
        & Pick<FirmTopic, 'id' | 'name' | 'colour'>
      ) | (
        { __typename?: 'GeneralTopic' }
        & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
      ) | (
        { __typename?: 'PrivateTopic' }
        & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
      )> }
    ) | (
      { __typename?: 'AudioFile' }
      & Pick<AudioFile, 'id' | 'name' | 'type' | 'mime' | 'size' | 'version' | 'lastRevisedOn' | 'currentRevisionId'>
      & { revisions: Array<(
        { __typename?: 'ArchiveFileRevision' }
        & Pick<ArchiveFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
      ) | (
        { __typename?: 'AudioFileRevision' }
        & Pick<AudioFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
      ) | (
        { __typename?: 'DocumentFileRevision' }
        & Pick<DocumentFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
      ) | (
        { __typename?: 'ImageFileRevision' }
        & Pick<ImageFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
      ) | (
        { __typename?: 'VideoFileRevision' }
        & Pick<VideoFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
      )>, topics: Array<(
        { __typename?: 'FirmTopic' }
        & Pick<FirmTopic, 'id' | 'name' | 'colour'>
      ) | (
        { __typename?: 'GeneralTopic' }
        & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
      ) | (
        { __typename?: 'PrivateTopic' }
        & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
      )> }
    ) | (
      { __typename?: 'DocumentFile' }
      & Pick<DocumentFile, 'id' | 'name' | 'type' | 'mime' | 'size' | 'version' | 'lastRevisedOn' | 'currentRevisionId'>
      & { revisions: Array<(
        { __typename?: 'ArchiveFileRevision' }
        & Pick<ArchiveFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
      ) | (
        { __typename?: 'AudioFileRevision' }
        & Pick<AudioFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
      ) | (
        { __typename?: 'DocumentFileRevision' }
        & Pick<DocumentFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
      ) | (
        { __typename?: 'ImageFileRevision' }
        & Pick<ImageFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
      ) | (
        { __typename?: 'VideoFileRevision' }
        & Pick<VideoFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
      )>, topics: Array<(
        { __typename?: 'FirmTopic' }
        & Pick<FirmTopic, 'id' | 'name' | 'colour'>
      ) | (
        { __typename?: 'GeneralTopic' }
        & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
      ) | (
        { __typename?: 'PrivateTopic' }
        & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
      )> }
    ) | (
      { __typename?: 'ImageFile' }
      & Pick<ImageFile, 'id' | 'name' | 'type' | 'mime' | 'size' | 'version' | 'lastRevisedOn' | 'currentRevisionId'>
      & { revisions: Array<(
        { __typename?: 'ArchiveFileRevision' }
        & Pick<ArchiveFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
      ) | (
        { __typename?: 'AudioFileRevision' }
        & Pick<AudioFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
      ) | (
        { __typename?: 'DocumentFileRevision' }
        & Pick<DocumentFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
      ) | (
        { __typename?: 'ImageFileRevision' }
        & Pick<ImageFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
      ) | (
        { __typename?: 'VideoFileRevision' }
        & Pick<VideoFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
      )>, topics: Array<(
        { __typename?: 'FirmTopic' }
        & Pick<FirmTopic, 'id' | 'name' | 'colour'>
      ) | (
        { __typename?: 'GeneralTopic' }
        & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
      ) | (
        { __typename?: 'PrivateTopic' }
        & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
      )> }
    ) | (
      { __typename?: 'VideoFile' }
      & Pick<VideoFile, 'id' | 'name' | 'type' | 'mime' | 'size' | 'version' | 'lastRevisedOn' | 'currentRevisionId'>
      & { revisions: Array<(
        { __typename?: 'ArchiveFileRevision' }
        & Pick<ArchiveFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
      ) | (
        { __typename?: 'AudioFileRevision' }
        & Pick<AudioFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
      ) | (
        { __typename?: 'DocumentFileRevision' }
        & Pick<DocumentFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
      ) | (
        { __typename?: 'ImageFileRevision' }
        & Pick<ImageFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
      ) | (
        { __typename?: 'VideoFileRevision' }
        & Pick<VideoFileRevision, 'id' | 'version' | 'originalName' | 'type' | 'mime' | 'size' | 'revisedOn'>
      )>, topics: Array<(
        { __typename?: 'FirmTopic' }
        & Pick<FirmTopic, 'id' | 'name' | 'colour'>
      ) | (
        { __typename?: 'GeneralTopic' }
        & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
      ) | (
        { __typename?: 'PrivateTopic' }
        & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
      )> }
    )>, parent?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'name'>
      & { parent?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'name'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'name'>
          & { parent?: Maybe<(
            { __typename?: 'Folder' }
            & Pick<Folder, 'id' | 'name'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type GetSubFoldersQueryVariables = Exact<{
  folderId?: Maybe<Scalars['ID']>;
}>;


export type GetSubFoldersQuery = (
  { __typename?: 'Query' }
  & { folder?: Maybe<(
    { __typename?: 'Folder' }
    & Pick<Folder, 'id' | 'name'>
    & { subFolders: Array<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'name' | 'isRestricted'>
      & { subFolders: Array<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'name' | 'isRestricted'>
      )> }
    )> }
  )> }
);

export type GetHighlightCountsQueryVariables = Exact<{
  filters?: Maybe<Array<HighlightFilter> | HighlightFilter>;
}>;


export type GetHighlightCountsQuery = (
  { __typename?: 'Query' }
  & { highlightCounts: (
    { __typename?: 'HighlightCountsPayload' }
    & Pick<HighlightCountsPayload, 'contactCount' | 'conversationCount' | 'messageCount' | 'firmMemberCount' | 'noteCount' | 'firmClientCount' | 'clientAssignmentCount' | 'clientCount' | 'matterCount' | 'keyDateCount' | 'taskCount' | 'taskSectionCount' | 'questionCount' | 'answerCount'>
  ) }
);

export type ListIssuesQueryVariables = Exact<{
  clientId: Scalars['ID'];
}>;


export type ListIssuesQuery = (
  { __typename?: 'Query' }
  & { issues: Array<(
    { __typename?: 'Issue' }
    & Pick<Issue, 'id' | 'type' | 'isLinked' | 'linkCode' | 'linkRequested'>
    & { opponentIssue?: Maybe<(
      { __typename?: 'Issue' }
      & Pick<Issue, 'id'>
      & { legalTeam: Array<(
        { __typename?: 'LegalTeamMember' }
        & Pick<LegalTeamMember, 'id' | 'jobTitle' | 'isLead'>
        & { firm: (
          { __typename?: 'Firm' }
          & Pick<Firm, 'id' | 'name'>
        ), professional: (
          { __typename?: 'FirmBarrister' }
          & Pick<FirmBarrister, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
        ) | (
          { __typename?: 'FirmParalegal' }
          & Pick<FirmParalegal, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
        ) | (
          { __typename?: 'FirmSolicitor' }
          & Pick<FirmSolicitor, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
        ) | (
          { __typename?: 'FirmSupport' }
          & Pick<FirmSupport, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
        ) }
      )> }
    )>, opponent?: Maybe<(
      { __typename?: 'Opponent' }
      & Pick<Opponent, 'preferredName' | 'firstName' | 'lastName' | 'emailAddress' | 'nationalInsuranceNumber' | 'clientReferenceNumber' | 'isVerified'>
      & { solicitor: (
        { __typename?: 'OpponentSolicitor' }
        & Pick<OpponentSolicitor, 'firstName' | 'lastName' | 'emailAddress' | 'firmName'>
      ) }
    )>, client: (
      { __typename?: 'FirmClient' }
      & { accounts: Array<(
        { __typename?: 'ClientAccount' }
        & Pick<ClientAccount, 'id'>
      )> }
    ) }
  )> }
);

export type ListMattersForClientQueryVariables = Exact<{
  clientId: Scalars['ID'];
  firmId?: Maybe<Scalars['ID']>;
}>;


export type ListMattersForClientQuery = (
  { __typename?: 'Query' }
  & { mattersForClient?: Maybe<(
    { __typename?: 'MatterList' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'Matter' }
      & Pick<Matter, 'id' | 'type' | 'status' | 'advisories' | 'advisoryCount' | 'isHighlighted' | 'isRemovable' | 'startedAt' | 'endedAt'>
      & { clientAccount?: Maybe<(
        { __typename?: 'ClientAccount' }
        & Pick<ClientAccount, 'id' | 'referenceNumber'>
        & { client: (
          { __typename?: 'FirmClient' }
          & Pick<FirmClient, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      )>, leadProfessional?: Maybe<(
        { __typename?: 'FirmBarrister' }
        & Pick<FirmBarrister, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) | (
        { __typename?: 'FirmParalegal' }
        & Pick<FirmParalegal, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) | (
        { __typename?: 'FirmSolicitor' }
        & Pick<FirmSolicitor, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) | (
        { __typename?: 'FirmSupport' }
        & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      )> }
    )>>> }
  )> }
);

export type ListMattersForClientAccountQueryVariables = Exact<{
  clientId: Scalars['ID'];
}>;


export type ListMattersForClientAccountQuery = (
  { __typename?: 'Query' }
  & { mattersForClientAccount?: Maybe<(
    { __typename?: 'MatterList' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'Matter' }
      & Pick<Matter, 'id' | 'type' | 'status' | 'advisories' | 'advisoryCount' | 'isHighlighted' | 'isRemovable' | 'startedAt' | 'endedAt'>
      & { clientAccount?: Maybe<(
        { __typename?: 'ClientAccount' }
        & Pick<ClientAccount, 'id' | 'referenceNumber'>
        & { client: (
          { __typename?: 'FirmClient' }
          & Pick<FirmClient, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      )>, leadProfessional?: Maybe<(
        { __typename?: 'FirmBarrister' }
        & Pick<FirmBarrister, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) | (
        { __typename?: 'FirmParalegal' }
        & Pick<FirmParalegal, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) | (
        { __typename?: 'FirmSolicitor' }
        & Pick<FirmSolicitor, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) | (
        { __typename?: 'FirmSupport' }
        & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      )> }
    )>>> }
  )> }
);

export type ListMattersForProfessionalQueryVariables = Exact<{
  professionalId: Scalars['ID'];
  firmId?: Maybe<Scalars['ID']>;
}>;


export type ListMattersForProfessionalQuery = (
  { __typename?: 'Query' }
  & { mattersForProfessional?: Maybe<(
    { __typename?: 'MatterList' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'Matter' }
      & Pick<Matter, 'id' | 'type' | 'status' | 'advisories' | 'advisoryCount' | 'isHighlighted' | 'isRemovable' | 'startedAt' | 'endedAt'>
      & { clientAccount?: Maybe<(
        { __typename?: 'ClientAccount' }
        & Pick<ClientAccount, 'id' | 'referenceNumber'>
        & { client: (
          { __typename?: 'FirmClient' }
          & Pick<FirmClient, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      )>, leadProfessional?: Maybe<(
        { __typename?: 'FirmBarrister' }
        & Pick<FirmBarrister, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) | (
        { __typename?: 'FirmParalegal' }
        & Pick<FirmParalegal, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) | (
        { __typename?: 'FirmSolicitor' }
        & Pick<FirmSolicitor, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) | (
        { __typename?: 'FirmSupport' }
        & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      )> }
    )>>> }
  )> }
);

export type ListMattersForFirmQueryVariables = Exact<{
  firmId: Scalars['ID'];
}>;


export type ListMattersForFirmQuery = (
  { __typename?: 'Query' }
  & { mattersForFirm?: Maybe<(
    { __typename?: 'MatterList' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'Matter' }
      & Pick<Matter, 'id' | 'type' | 'status' | 'advisories' | 'advisoryCount' | 'isHighlighted' | 'isRemovable' | 'startedAt' | 'endedAt'>
      & { clientAccount?: Maybe<(
        { __typename?: 'ClientAccount' }
        & Pick<ClientAccount, 'id' | 'referenceNumber'>
        & { client: (
          { __typename?: 'FirmClient' }
          & Pick<FirmClient, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      )>, leadProfessional?: Maybe<(
        { __typename?: 'FirmBarrister' }
        & Pick<FirmBarrister, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) | (
        { __typename?: 'FirmParalegal' }
        & Pick<FirmParalegal, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) | (
        { __typename?: 'FirmSolicitor' }
        & Pick<FirmSolicitor, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) | (
        { __typename?: 'FirmSupport' }
        & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      )> }
    )>>> }
  )> }
);

export type GetMatterQueryVariables = Exact<{
  matterId: Scalars['ID'];
}>;


export type GetMatterQuery = (
  { __typename?: 'Query' }
  & { matter?: Maybe<(
    { __typename?: 'Matter' }
    & Pick<Matter, 'id' | 'type' | 'status' | 'advisories' | 'advisoryCount' | 'isHighlighted' | 'isRemovable' | 'startedAt' | 'endedAt'>
    & { clientAccount?: Maybe<(
      { __typename?: 'ClientAccount' }
      & Pick<ClientAccount, 'id' | 'referenceNumber'>
      & { client: (
        { __typename?: 'FirmClient' }
        & Pick<FirmClient, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )>, leadProfessional?: Maybe<(
      { __typename?: 'FirmBarrister' }
      & Pick<FirmBarrister, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) | (
      { __typename?: 'FirmParalegal' }
      & Pick<FirmParalegal, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) | (
      { __typename?: 'FirmSolicitor' }
      & Pick<FirmSolicitor, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) | (
      { __typename?: 'FirmSupport' }
      & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    )> }
  )> }
);

export type GetFullMatterQueryVariables = Exact<{
  matterId: Scalars['ID'];
}>;


export type GetFullMatterQuery = (
  { __typename?: 'Query' }
  & { matter?: Maybe<(
    { __typename?: 'Matter' }
    & Pick<Matter, 'id' | 'type' | 'status' | 'advisories' | 'advisoryCount' | 'isHighlighted' | 'isRemovable' | 'startedAt' | 'endedAt'>
    & { clientAccount?: Maybe<(
      { __typename?: 'ClientAccount' }
      & Pick<ClientAccount, 'id' | 'referenceNumber'>
      & { client: (
        { __typename?: 'FirmClient' }
        & Pick<FirmClient, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )>, leadProfessional?: Maybe<(
      { __typename?: 'FirmBarrister' }
      & Pick<FirmBarrister, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) | (
      { __typename?: 'FirmParalegal' }
      & Pick<FirmParalegal, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) | (
      { __typename?: 'FirmSolicitor' }
      & Pick<FirmSolicitor, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) | (
      { __typename?: 'FirmSupport' }
      & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    )>, keyDates: Array<(
      { __typename?: 'KeyDate' }
      & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
    )>, courtCases: Array<(
      { __typename?: 'CourtCase' }
      & Pick<CourtCase, 'id' | 'courtName' | 'caseNumber' | 'status'>
      & { hearingDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )> }
    )> }
  )> }
);

export type ListClientAccountKeyDatesQueryVariables = Exact<{
  clientAccountId: Scalars['ID'];
}>;


export type ListClientAccountKeyDatesQuery = (
  { __typename?: 'Query' }
  & { clientAccountKeyDates: Array<(
    { __typename?: 'KeyDate' }
    & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description' | 'isHighlighted'>
    & { matter: (
      { __typename?: 'Matter' }
      & Pick<Matter, 'id' | 'type'>
      & { clientAccount?: Maybe<(
        { __typename?: 'ClientAccount' }
        & Pick<ClientAccount, 'id' | 'referenceNumber'>
        & { client: (
          { __typename?: 'FirmClient' }
          & Pick<FirmClient, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      )> }
    ) }
  )> }
);

export type ListAllKeyDatesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAllKeyDatesQuery = (
  { __typename?: 'Query' }
  & { allKeyDates?: Maybe<(
    { __typename?: 'KeyDateList' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'KeyDate' }
      & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description' | 'isHighlighted'>
      & { matter: (
        { __typename?: 'Matter' }
        & Pick<Matter, 'id' | 'type'>
        & { clientAccount?: Maybe<(
          { __typename?: 'ClientAccount' }
          & Pick<ClientAccount, 'id' | 'referenceNumber'>
          & { client: (
            { __typename?: 'FirmClient' }
            & Pick<FirmClient, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) }
    )>>> }
  )> }
);

export type ListCourtCasesQueryVariables = Exact<{
  matterId: Scalars['ID'];
}>;


export type ListCourtCasesQuery = (
  { __typename?: 'Query' }
  & { courtCases: Array<(
    { __typename?: 'CourtCase' }
    & Pick<CourtCase, 'id' | 'courtName' | 'caseNumber' | 'status'>
    & { hearingDate?: Maybe<(
      { __typename?: 'KeyDate' }
      & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
    )> }
  )> }
);

type FileInfo_SupportingArchive_Fragment = (
  { __typename?: 'SupportingArchive' }
  & Pick<SupportingArchive, 'id' | 'source' | 'name' | 'fileId' | 'fileVersion' | 'size' | 'type' | 'mime'>
);

type FileInfo_SupportingAudio_Fragment = (
  { __typename?: 'SupportingAudio' }
  & Pick<SupportingAudio, 'id' | 'source' | 'name' | 'fileId' | 'fileVersion' | 'size' | 'type' | 'mime'>
);

type FileInfo_SupportingDocument_Fragment = (
  { __typename?: 'SupportingDocument' }
  & Pick<SupportingDocument, 'id' | 'source' | 'name' | 'fileId' | 'fileVersion' | 'size' | 'type' | 'mime'>
);

type FileInfo_SupportingImage_Fragment = (
  { __typename?: 'SupportingImage' }
  & Pick<SupportingImage, 'id' | 'source' | 'name' | 'fileId' | 'fileVersion' | 'size' | 'type' | 'mime'>
);

type FileInfo_SupportingVideo_Fragment = (
  { __typename?: 'SupportingVideo' }
  & Pick<SupportingVideo, 'id' | 'source' | 'name' | 'fileId' | 'fileVersion' | 'size' | 'type' | 'mime'>
);

export type FileInfoFragment = FileInfo_SupportingArchive_Fragment | FileInfo_SupportingAudio_Fragment | FileInfo_SupportingDocument_Fragment | FileInfo_SupportingImage_Fragment | FileInfo_SupportingVideo_Fragment;

export type GetSupportingMaterialQueryVariables = Exact<{
  matterId: Scalars['ID'];
}>;


export type GetSupportingMaterialQuery = (
  { __typename?: 'Query' }
  & { supportingMaterial: Array<(
    { __typename?: 'MatterTask' }
    & Pick<MatterTask, 'id' | 'name' | 'status' | 'proportionComplete'>
  ) | (
    { __typename?: 'ConversationExtract' }
    & Pick<ConversationExtract, 'id' | 'name' | 'extractedAt'>
    & { messageFilters: (
      { __typename?: 'MessageFilters' }
      & Pick<MessageFilters, 'conversationId' | 'senderIds' | 'sentBefore' | 'sentAfter' | 'hasAttachments' | 'viewerId' | 'isUnread' | 'isVisible'>
    ), messages?: Maybe<(
      { __typename?: 'MessageList' }
      & Pick<MessageList, 'perPage' | 'currentPage'>
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Message' }
        & Pick<Message, 'body' | 'sentAt'>
      )>>> }
    )> }
  ) | (
    { __typename?: 'SupportingAudio' }
    & FileInfo_SupportingAudio_Fragment
  ) | (
    { __typename?: 'SupportingDocument' }
    & FileInfo_SupportingDocument_Fragment
  ) | (
    { __typename?: 'SupportingImage' }
    & Pick<SupportingImage, 'height' | 'width'>
    & FileInfo_SupportingImage_Fragment
  ) | (
    { __typename?: 'SupportingVideo' }
    & FileInfo_SupportingVideo_Fragment
  )> }
);

export type ListMessagesQueryVariables = Exact<{
  conversationId: Scalars['ID'];
  input: QueryMessagesInput;
}>;


export type ListMessagesQuery = (
  { __typename?: 'Query' }
  & { conversation?: Maybe<(
    { __typename?: 'Conversation' }
    & Pick<Conversation, 'id' | 'name' | 'description' | 'pictureUrl' | 'pausedUntil' | 'unreadMessageCount'>
    & { participants: Array<(
      { __typename?: 'ConversationParticipant' }
      & Pick<ConversationParticipant, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl' | 'role' | 'title' | 'status'>
      & { group?: Maybe<(
        { __typename?: 'ConversationGroup' }
        & Pick<ConversationGroup, 'id' | 'name' | 'pictureUrl'>
      )> }
    )>, groups: Array<(
      { __typename?: 'ConversationGroup' }
      & Pick<ConversationGroup, 'id' | 'name' | 'pictureUrl'>
      & { members: Array<(
        { __typename?: 'ConversationObserver' }
        & Pick<ConversationObserver, 'id' | 'name' | 'pictureUrl' | 'title'>
      ) | (
        { __typename?: 'ConversationParticipant' }
        & Pick<ConversationParticipant, 'id' | 'name' | 'pictureUrl' | 'title'>
      )> }
    )>, observers: Array<(
      { __typename?: 'ConversationObserver' }
      & Pick<ConversationObserver, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl' | 'role' | 'title'>
      & { group?: Maybe<(
        { __typename?: 'ConversationGroup' }
        & Pick<ConversationGroup, 'id' | 'name' | 'pictureUrl'>
      )> }
    )>, draft?: Maybe<(
      { __typename?: 'MessageDraft' }
      & Pick<MessageDraft, 'body'>
    )>, metadata: Array<(
      { __typename?: 'KeyValueArray' }
      & Pick<KeyValueArray, 'key'>
      & { values: KeyValueArray['value'] }
    ) | (
      { __typename?: 'KeyValueString' }
      & Pick<KeyValueString, 'key' | 'value'>
    )> }
  )>, messages?: Maybe<(
    { __typename?: 'MessageList' }
    & Pick<MessageList, 'perPage' | 'lastPage' | 'hasMorePages' | 'to' | 'from' | 'currentPage' | 'total'>
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'Message' }
      & Pick<Message, 'id' | 'conversationId' | 'body' | 'sentAt' | 'deliveredAt' | 'readAt'>
      & { sender: (
        { __typename?: 'ConversationParticipant' }
        & Pick<ConversationParticipant, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl' | 'role' | 'channel' | 'title'>
        & { group?: Maybe<(
          { __typename?: 'ConversationGroup' }
          & Pick<ConversationGroup, 'id' | 'name' | 'pictureUrl'>
        )> }
      ), repliedTo?: Maybe<(
        { __typename?: 'Message' }
        & Pick<Message, 'id' | 'body' | 'sentAt'>
        & { sender: (
          { __typename?: 'ConversationParticipant' }
          & Pick<ConversationParticipant, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl' | 'role' | 'channel' | 'title'>
          & { group?: Maybe<(
            { __typename?: 'ConversationGroup' }
            & Pick<ConversationGroup, 'id' | 'name' | 'pictureUrl'>
          )> }
        ) }
      )>, copies?: Maybe<Array<Maybe<(
        { __typename?: 'MessageCopy' }
        & Pick<MessageCopy, 'deliveredAt' | 'readAt'>
        & { recipient: (
          { __typename?: 'ConversationObserver' }
          & Pick<ConversationObserver, 'id'>
          & { group?: Maybe<(
            { __typename?: 'ConversationGroup' }
            & Pick<ConversationGroup, 'id'>
          )> }
        ) | (
          { __typename?: 'ConversationParticipant' }
          & Pick<ConversationParticipant, 'id'>
          & { group?: Maybe<(
            { __typename?: 'ConversationGroup' }
            & Pick<ConversationGroup, 'id'>
          )> }
        ) }
      )>>>, attachments: Array<(
        { __typename?: 'AttachedArchive' }
        & Pick<AttachedArchive, 'id' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'AttachedAudio' }
        & Pick<AttachedAudio, 'id' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'AttachedDocument' }
        & Pick<AttachedDocument, 'id' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'AttachedImage' }
        & Pick<AttachedImage, 'height' | 'width' | 'id' | 'name' | 'type' | 'mime' | 'size'>
      ) | (
        { __typename?: 'AttachedVideo' }
        & Pick<AttachedVideo, 'id' | 'name' | 'type' | 'mime' | 'size'>
      )>, topics: Array<(
        { __typename?: 'FirmTopic' }
        & Pick<FirmTopic, 'id' | 'name' | 'colour'>
      ) | (
        { __typename?: 'GeneralTopic' }
        & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
      ) | (
        { __typename?: 'PrivateTopic' }
        & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
      )> }
    )>>> }
  )> }
);

export type ListAuthoredNotesQueryVariables = Exact<{
  authorId: Scalars['ID'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
}>;


export type ListAuthoredNotesQuery = (
  { __typename?: 'Query' }
  & { authoredNotes?: Maybe<(
    { __typename?: 'NoteList' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'Note' }
      & Pick<Note, 'id' | 'isPersonal' | 'isViewed' | 'category' | 'dateTime' | 'title' | 'body' | 'startedAt' | 'versionNumber'>
      & { revisions: Array<(
        { __typename?: 'NoteRevision' }
        & Pick<NoteRevision, 'versionNumber' | 'title' | 'body' | 'category' | 'dateTime' | 'draftedAt'>
        & { author?: Maybe<(
          { __typename?: 'FirmBarrister' }
          & Pick<FirmBarrister, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) | (
          { __typename?: 'FirmParalegal' }
          & Pick<FirmParalegal, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) | (
          { __typename?: 'FirmSolicitor' }
          & Pick<FirmSolicitor, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) | (
          { __typename?: 'FirmSupport' }
          & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        )> }
      )>, clientAccount?: Maybe<(
        { __typename?: 'ClientAccount' }
        & Pick<ClientAccount, 'id' | 'status' | 'referenceNumber'>
        & { client: (
          { __typename?: 'FirmClient' }
          & Pick<FirmClient, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      )>, matter?: Maybe<(
        { __typename?: 'Matter' }
        & Pick<Matter, 'id'>
      )>, author?: Maybe<(
        { __typename?: 'FirmBarrister' }
        & Pick<FirmBarrister, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) | (
        { __typename?: 'FirmParalegal' }
        & Pick<FirmParalegal, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) | (
        { __typename?: 'FirmSolicitor' }
        & Pick<FirmSolicitor, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) | (
        { __typename?: 'FirmSupport' }
        & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      )> }
    )>>> }
  )> }
);

export type ListClientAccountNotesQueryVariables = Exact<{
  clientAccountId: Scalars['ID'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
}>;


export type ListClientAccountNotesQuery = (
  { __typename?: 'Query' }
  & { clientAccountNotes?: Maybe<(
    { __typename?: 'NoteList' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'Note' }
      & Pick<Note, 'id' | 'category' | 'dateTime' | 'title' | 'body' | 'startedAt' | 'versionNumber' | 'isPersonal' | 'isViewed'>
      & { revisions: Array<(
        { __typename?: 'NoteRevision' }
        & Pick<NoteRevision, 'versionNumber' | 'title' | 'body' | 'category' | 'dateTime' | 'draftedAt'>
        & { author?: Maybe<(
          { __typename?: 'FirmBarrister' }
          & Pick<FirmBarrister, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) | (
          { __typename?: 'FirmParalegal' }
          & Pick<FirmParalegal, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) | (
          { __typename?: 'FirmSolicitor' }
          & Pick<FirmSolicitor, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) | (
          { __typename?: 'FirmSupport' }
          & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        )> }
      )>, clientAccount?: Maybe<(
        { __typename?: 'ClientAccount' }
        & Pick<ClientAccount, 'id' | 'status' | 'referenceNumber'>
        & { client: (
          { __typename?: 'FirmClient' }
          & Pick<FirmClient, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      )>, matter?: Maybe<(
        { __typename?: 'Matter' }
        & Pick<Matter, 'id'>
      )>, author?: Maybe<(
        { __typename?: 'FirmBarrister' }
        & Pick<FirmBarrister, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) | (
        { __typename?: 'FirmParalegal' }
        & Pick<FirmParalegal, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) | (
        { __typename?: 'FirmSolicitor' }
        & Pick<FirmSolicitor, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) | (
        { __typename?: 'FirmSupport' }
        & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      )> }
    )>>> }
  )> }
);

export type GetNotesQueryVariables = Exact<{
  noteId: Scalars['ID'];
}>;


export type GetNotesQuery = (
  { __typename?: 'Query' }
  & { note?: Maybe<(
    { __typename?: 'Note' }
    & Pick<Note, 'id' | 'category' | 'dateTime' | 'title' | 'body' | 'startedAt' | 'versionNumber' | 'isPersonal' | 'isViewed'>
    & { revisions: Array<(
      { __typename?: 'NoteRevision' }
      & Pick<NoteRevision, 'versionNumber' | 'title' | 'body' | 'category' | 'dateTime' | 'draftedAt'>
      & { author?: Maybe<(
        { __typename?: 'FirmBarrister' }
        & Pick<FirmBarrister, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) | (
        { __typename?: 'FirmParalegal' }
        & Pick<FirmParalegal, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) | (
        { __typename?: 'FirmSolicitor' }
        & Pick<FirmSolicitor, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) | (
        { __typename?: 'FirmSupport' }
        & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      )> }
    )>, clientAccount?: Maybe<(
      { __typename?: 'ClientAccount' }
      & Pick<ClientAccount, 'id' | 'status' | 'referenceNumber'>
      & { client: (
        { __typename?: 'FirmClient' }
        & Pick<FirmClient, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
      ) }
    )>, matter?: Maybe<(
      { __typename?: 'Matter' }
      & Pick<Matter, 'id'>
    )>, author?: Maybe<(
      { __typename?: 'FirmBarrister' }
      & Pick<FirmBarrister, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) | (
      { __typename?: 'FirmParalegal' }
      & Pick<FirmParalegal, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) | (
      { __typename?: 'FirmSolicitor' }
      & Pick<FirmSolicitor, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ) | (
      { __typename?: 'FirmSupport' }
      & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    )> }
  )> }
);

export type ListNotificationsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
}>;


export type ListNotificationsQuery = (
  { __typename?: 'Query' }
  & { notifications: (
    { __typename?: 'NotificationList' }
    & Pick<NotificationList, 'perPage' | 'currentPage' | 'hasMorePages' | 'totalUnread' | 'total'>
    & { notifications?: Maybe<Array<Maybe<(
      { __typename?: 'Notification' }
      & Pick<Notification, 'id' | 'context' | 'type' | 'title' | 'body' | 'pictureUrl' | 'occurredOn' | 'readAt' | 'isRead'>
      & { path: Array<(
        { __typename?: 'PathSegment' }
        & Pick<PathSegment, 'id' | 'type' | 'name'>
      )> }
    )>>> }
  ) }
);

export type GetQuestionQueryVariables = Exact<{
  questionId: Scalars['ID'];
}>;


export type GetQuestionQuery = (
  { __typename?: 'Query' }
  & { question?: Maybe<(
    { __typename?: 'Question' }
    & Pick<Question, 'id' | 'text' | 'description' | 'proportionComplete' | 'isReleased' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    & { parts: Array<(
      { __typename?: 'AddressPart' }
      & Pick<AddressPart, 'useName' | 'id' | 'label' | 'instructions' | 'isRequired' | 'isComplete' | 'isHighlighted' | 'ordering' | 'officialAdvice' | 'additionalAdvice'>
      & { answerDraft?: Maybe<(
        { __typename?: 'AddressAnswerDraft' }
        & Pick<AddressAnswerDraft, 'id' | 'notApplicable'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'BooleanAnswerDraft' }
        & Pick<BooleanAnswerDraft, 'id' | 'notApplicable'>
        & { boolean: BooleanAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ChoiceAnswerDraft' }
        & Pick<ChoiceAnswerDraft, 'id' | 'notApplicable'>
        & { choice: ChoiceAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'DateAnswerDraft' }
        & Pick<DateAnswerDraft, 'id' | 'notApplicable'>
        & { date: DateAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'EmailAnswerDraft' }
        & Pick<EmailAnswerDraft, 'id' | 'notApplicable'>
        & { email: EmailAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'FileAnswerDraft' }
        & Pick<FileAnswerDraft, 'id' | 'notApplicable'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ListAnswerDraft' }
        & Pick<ListAnswerDraft, 'id' | 'notApplicable'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'MoneyAnswerDraft' }
        & Pick<MoneyAnswerDraft, 'id' | 'notApplicable'>
        & { money: MoneyAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'NumberAnswerDraft' }
        & Pick<NumberAnswerDraft, 'id' | 'notApplicable'>
        & { number: NumberAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PercentAnswerDraft' }
        & Pick<PercentAnswerDraft, 'id' | 'notApplicable'>
        & { percent: PercentAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PhoneAnswerDraft' }
        & Pick<PhoneAnswerDraft, 'id' | 'notApplicable'>
        & { phone: PhoneAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'StringAnswerDraft' }
        & Pick<StringAnswerDraft, 'id' | 'notApplicable'>
        & { string: StringAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'TextAnswerDraft' }
        & Pick<TextAnswerDraft, 'id' | 'notApplicable'>
        & { text: TextAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      )>, answer?: Maybe<(
        { __typename?: 'AddressAnswer' }
        & Pick<AddressAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'BooleanAnswer' }
        & Pick<BooleanAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { boolean: BooleanAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ChoiceAnswer' }
        & Pick<ChoiceAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { choice: ChoiceAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'DateAnswer' }
        & Pick<DateAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { date: DateAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'EmailAnswer' }
        & Pick<EmailAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { email: EmailAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'FileAnswer' }
        & Pick<FileAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ListAnswer' }
        & Pick<ListAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'MoneyAnswer' }
        & Pick<MoneyAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { money: MoneyAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'NumberAnswer' }
        & Pick<NumberAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { number: NumberAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PercentAnswer' }
        & Pick<PercentAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { percent: PercentAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PhoneAnswer' }
        & Pick<PhoneAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { phone: PhoneAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'StringAnswer' }
        & Pick<StringAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { string: StringAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'TextAnswer' }
        & Pick<TextAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { text: TextAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      )> }
    ) | (
      { __typename?: 'BooleanPart' }
      & Pick<BooleanPart, 'positiveLabel' | 'negativeLabel' | 'id' | 'label' | 'instructions' | 'isRequired' | 'isComplete' | 'isHighlighted' | 'ordering' | 'officialAdvice' | 'additionalAdvice'>
      & { answerDraft?: Maybe<(
        { __typename?: 'AddressAnswerDraft' }
        & Pick<AddressAnswerDraft, 'id' | 'notApplicable'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'BooleanAnswerDraft' }
        & Pick<BooleanAnswerDraft, 'id' | 'notApplicable'>
        & { boolean: BooleanAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ChoiceAnswerDraft' }
        & Pick<ChoiceAnswerDraft, 'id' | 'notApplicable'>
        & { choice: ChoiceAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'DateAnswerDraft' }
        & Pick<DateAnswerDraft, 'id' | 'notApplicable'>
        & { date: DateAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'EmailAnswerDraft' }
        & Pick<EmailAnswerDraft, 'id' | 'notApplicable'>
        & { email: EmailAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'FileAnswerDraft' }
        & Pick<FileAnswerDraft, 'id' | 'notApplicable'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ListAnswerDraft' }
        & Pick<ListAnswerDraft, 'id' | 'notApplicable'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'MoneyAnswerDraft' }
        & Pick<MoneyAnswerDraft, 'id' | 'notApplicable'>
        & { money: MoneyAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'NumberAnswerDraft' }
        & Pick<NumberAnswerDraft, 'id' | 'notApplicable'>
        & { number: NumberAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PercentAnswerDraft' }
        & Pick<PercentAnswerDraft, 'id' | 'notApplicable'>
        & { percent: PercentAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PhoneAnswerDraft' }
        & Pick<PhoneAnswerDraft, 'id' | 'notApplicable'>
        & { phone: PhoneAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'StringAnswerDraft' }
        & Pick<StringAnswerDraft, 'id' | 'notApplicable'>
        & { string: StringAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'TextAnswerDraft' }
        & Pick<TextAnswerDraft, 'id' | 'notApplicable'>
        & { text: TextAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      )>, answer?: Maybe<(
        { __typename?: 'AddressAnswer' }
        & Pick<AddressAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'BooleanAnswer' }
        & Pick<BooleanAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { boolean: BooleanAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ChoiceAnswer' }
        & Pick<ChoiceAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { choice: ChoiceAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'DateAnswer' }
        & Pick<DateAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { date: DateAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'EmailAnswer' }
        & Pick<EmailAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { email: EmailAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'FileAnswer' }
        & Pick<FileAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ListAnswer' }
        & Pick<ListAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'MoneyAnswer' }
        & Pick<MoneyAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { money: MoneyAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'NumberAnswer' }
        & Pick<NumberAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { number: NumberAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PercentAnswer' }
        & Pick<PercentAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { percent: PercentAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PhoneAnswer' }
        & Pick<PhoneAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { phone: PhoneAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'StringAnswer' }
        & Pick<StringAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { string: StringAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'TextAnswer' }
        & Pick<TextAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { text: TextAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      )> }
    ) | (
      { __typename?: 'ChoicePart' }
      & Pick<ChoicePart, 'minimumChoices' | 'maximumChoices' | 'id' | 'label' | 'instructions' | 'isRequired' | 'isComplete' | 'isHighlighted' | 'ordering' | 'officialAdvice' | 'additionalAdvice'>
      & { options: Array<(
        { __typename?: 'Option' }
        & Pick<Option, 'value' | 'description'>
      )>, answerDraft?: Maybe<(
        { __typename?: 'AddressAnswerDraft' }
        & Pick<AddressAnswerDraft, 'id' | 'notApplicable'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'BooleanAnswerDraft' }
        & Pick<BooleanAnswerDraft, 'id' | 'notApplicable'>
        & { boolean: BooleanAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ChoiceAnswerDraft' }
        & Pick<ChoiceAnswerDraft, 'id' | 'notApplicable'>
        & { choice: ChoiceAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'DateAnswerDraft' }
        & Pick<DateAnswerDraft, 'id' | 'notApplicable'>
        & { date: DateAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'EmailAnswerDraft' }
        & Pick<EmailAnswerDraft, 'id' | 'notApplicable'>
        & { email: EmailAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'FileAnswerDraft' }
        & Pick<FileAnswerDraft, 'id' | 'notApplicable'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ListAnswerDraft' }
        & Pick<ListAnswerDraft, 'id' | 'notApplicable'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'MoneyAnswerDraft' }
        & Pick<MoneyAnswerDraft, 'id' | 'notApplicable'>
        & { money: MoneyAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'NumberAnswerDraft' }
        & Pick<NumberAnswerDraft, 'id' | 'notApplicable'>
        & { number: NumberAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PercentAnswerDraft' }
        & Pick<PercentAnswerDraft, 'id' | 'notApplicable'>
        & { percent: PercentAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PhoneAnswerDraft' }
        & Pick<PhoneAnswerDraft, 'id' | 'notApplicable'>
        & { phone: PhoneAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'StringAnswerDraft' }
        & Pick<StringAnswerDraft, 'id' | 'notApplicable'>
        & { string: StringAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'TextAnswerDraft' }
        & Pick<TextAnswerDraft, 'id' | 'notApplicable'>
        & { text: TextAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      )>, answer?: Maybe<(
        { __typename?: 'AddressAnswer' }
        & Pick<AddressAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'BooleanAnswer' }
        & Pick<BooleanAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { boolean: BooleanAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ChoiceAnswer' }
        & Pick<ChoiceAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { choice: ChoiceAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'DateAnswer' }
        & Pick<DateAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { date: DateAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'EmailAnswer' }
        & Pick<EmailAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { email: EmailAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'FileAnswer' }
        & Pick<FileAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ListAnswer' }
        & Pick<ListAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'MoneyAnswer' }
        & Pick<MoneyAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { money: MoneyAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'NumberAnswer' }
        & Pick<NumberAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { number: NumberAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PercentAnswer' }
        & Pick<PercentAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { percent: PercentAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PhoneAnswer' }
        & Pick<PhoneAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { phone: PhoneAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'StringAnswer' }
        & Pick<StringAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { string: StringAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'TextAnswer' }
        & Pick<TextAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { text: TextAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      )> }
    ) | (
      { __typename?: 'DatePart' }
      & Pick<DatePart, 'earliestDate' | 'latestDate' | 'useTime' | 'id' | 'label' | 'instructions' | 'isRequired' | 'isComplete' | 'isHighlighted' | 'ordering' | 'officialAdvice' | 'additionalAdvice'>
      & { answerDraft?: Maybe<(
        { __typename?: 'AddressAnswerDraft' }
        & Pick<AddressAnswerDraft, 'id' | 'notApplicable'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'BooleanAnswerDraft' }
        & Pick<BooleanAnswerDraft, 'id' | 'notApplicable'>
        & { boolean: BooleanAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ChoiceAnswerDraft' }
        & Pick<ChoiceAnswerDraft, 'id' | 'notApplicable'>
        & { choice: ChoiceAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'DateAnswerDraft' }
        & Pick<DateAnswerDraft, 'id' | 'notApplicable'>
        & { date: DateAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'EmailAnswerDraft' }
        & Pick<EmailAnswerDraft, 'id' | 'notApplicable'>
        & { email: EmailAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'FileAnswerDraft' }
        & Pick<FileAnswerDraft, 'id' | 'notApplicable'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ListAnswerDraft' }
        & Pick<ListAnswerDraft, 'id' | 'notApplicable'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'MoneyAnswerDraft' }
        & Pick<MoneyAnswerDraft, 'id' | 'notApplicable'>
        & { money: MoneyAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'NumberAnswerDraft' }
        & Pick<NumberAnswerDraft, 'id' | 'notApplicable'>
        & { number: NumberAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PercentAnswerDraft' }
        & Pick<PercentAnswerDraft, 'id' | 'notApplicable'>
        & { percent: PercentAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PhoneAnswerDraft' }
        & Pick<PhoneAnswerDraft, 'id' | 'notApplicable'>
        & { phone: PhoneAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'StringAnswerDraft' }
        & Pick<StringAnswerDraft, 'id' | 'notApplicable'>
        & { string: StringAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'TextAnswerDraft' }
        & Pick<TextAnswerDraft, 'id' | 'notApplicable'>
        & { text: TextAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      )>, answer?: Maybe<(
        { __typename?: 'AddressAnswer' }
        & Pick<AddressAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'BooleanAnswer' }
        & Pick<BooleanAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { boolean: BooleanAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ChoiceAnswer' }
        & Pick<ChoiceAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { choice: ChoiceAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'DateAnswer' }
        & Pick<DateAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { date: DateAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'EmailAnswer' }
        & Pick<EmailAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { email: EmailAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'FileAnswer' }
        & Pick<FileAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ListAnswer' }
        & Pick<ListAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'MoneyAnswer' }
        & Pick<MoneyAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { money: MoneyAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'NumberAnswer' }
        & Pick<NumberAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { number: NumberAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PercentAnswer' }
        & Pick<PercentAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { percent: PercentAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PhoneAnswer' }
        & Pick<PhoneAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { phone: PhoneAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'StringAnswer' }
        & Pick<StringAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { string: StringAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'TextAnswer' }
        & Pick<TextAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { text: TextAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      )> }
    ) | (
      { __typename?: 'EmailPart' }
      & Pick<EmailPart, 'id' | 'label' | 'instructions' | 'isRequired' | 'isComplete' | 'isHighlighted' | 'ordering' | 'officialAdvice' | 'additionalAdvice'>
      & { answerDraft?: Maybe<(
        { __typename?: 'AddressAnswerDraft' }
        & Pick<AddressAnswerDraft, 'id' | 'notApplicable'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'BooleanAnswerDraft' }
        & Pick<BooleanAnswerDraft, 'id' | 'notApplicable'>
        & { boolean: BooleanAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ChoiceAnswerDraft' }
        & Pick<ChoiceAnswerDraft, 'id' | 'notApplicable'>
        & { choice: ChoiceAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'DateAnswerDraft' }
        & Pick<DateAnswerDraft, 'id' | 'notApplicable'>
        & { date: DateAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'EmailAnswerDraft' }
        & Pick<EmailAnswerDraft, 'id' | 'notApplicable'>
        & { email: EmailAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'FileAnswerDraft' }
        & Pick<FileAnswerDraft, 'id' | 'notApplicable'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ListAnswerDraft' }
        & Pick<ListAnswerDraft, 'id' | 'notApplicable'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'MoneyAnswerDraft' }
        & Pick<MoneyAnswerDraft, 'id' | 'notApplicable'>
        & { money: MoneyAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'NumberAnswerDraft' }
        & Pick<NumberAnswerDraft, 'id' | 'notApplicable'>
        & { number: NumberAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PercentAnswerDraft' }
        & Pick<PercentAnswerDraft, 'id' | 'notApplicable'>
        & { percent: PercentAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PhoneAnswerDraft' }
        & Pick<PhoneAnswerDraft, 'id' | 'notApplicable'>
        & { phone: PhoneAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'StringAnswerDraft' }
        & Pick<StringAnswerDraft, 'id' | 'notApplicable'>
        & { string: StringAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'TextAnswerDraft' }
        & Pick<TextAnswerDraft, 'id' | 'notApplicable'>
        & { text: TextAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      )>, answer?: Maybe<(
        { __typename?: 'AddressAnswer' }
        & Pick<AddressAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'BooleanAnswer' }
        & Pick<BooleanAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { boolean: BooleanAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ChoiceAnswer' }
        & Pick<ChoiceAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { choice: ChoiceAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'DateAnswer' }
        & Pick<DateAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { date: DateAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'EmailAnswer' }
        & Pick<EmailAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { email: EmailAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'FileAnswer' }
        & Pick<FileAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ListAnswer' }
        & Pick<ListAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'MoneyAnswer' }
        & Pick<MoneyAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { money: MoneyAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'NumberAnswer' }
        & Pick<NumberAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { number: NumberAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PercentAnswer' }
        & Pick<PercentAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { percent: PercentAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PhoneAnswer' }
        & Pick<PhoneAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { phone: PhoneAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'StringAnswer' }
        & Pick<StringAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { string: StringAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'TextAnswer' }
        & Pick<TextAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { text: TextAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      )> }
    ) | (
      { __typename?: 'FilePart' }
      & Pick<FilePart, 'maximumFiles' | 'id' | 'label' | 'instructions' | 'isRequired' | 'isComplete' | 'isHighlighted' | 'ordering' | 'officialAdvice' | 'additionalAdvice'>
      & { answerDraft?: Maybe<(
        { __typename?: 'AddressAnswerDraft' }
        & Pick<AddressAnswerDraft, 'id' | 'notApplicable'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'BooleanAnswerDraft' }
        & Pick<BooleanAnswerDraft, 'id' | 'notApplicable'>
        & { boolean: BooleanAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ChoiceAnswerDraft' }
        & Pick<ChoiceAnswerDraft, 'id' | 'notApplicable'>
        & { choice: ChoiceAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'DateAnswerDraft' }
        & Pick<DateAnswerDraft, 'id' | 'notApplicable'>
        & { date: DateAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'EmailAnswerDraft' }
        & Pick<EmailAnswerDraft, 'id' | 'notApplicable'>
        & { email: EmailAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'FileAnswerDraft' }
        & Pick<FileAnswerDraft, 'id' | 'notApplicable'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ListAnswerDraft' }
        & Pick<ListAnswerDraft, 'id' | 'notApplicable'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'MoneyAnswerDraft' }
        & Pick<MoneyAnswerDraft, 'id' | 'notApplicable'>
        & { money: MoneyAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'NumberAnswerDraft' }
        & Pick<NumberAnswerDraft, 'id' | 'notApplicable'>
        & { number: NumberAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PercentAnswerDraft' }
        & Pick<PercentAnswerDraft, 'id' | 'notApplicable'>
        & { percent: PercentAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PhoneAnswerDraft' }
        & Pick<PhoneAnswerDraft, 'id' | 'notApplicable'>
        & { phone: PhoneAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'StringAnswerDraft' }
        & Pick<StringAnswerDraft, 'id' | 'notApplicable'>
        & { string: StringAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'TextAnswerDraft' }
        & Pick<TextAnswerDraft, 'id' | 'notApplicable'>
        & { text: TextAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      )>, answer?: Maybe<(
        { __typename?: 'AddressAnswer' }
        & Pick<AddressAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'BooleanAnswer' }
        & Pick<BooleanAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { boolean: BooleanAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ChoiceAnswer' }
        & Pick<ChoiceAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { choice: ChoiceAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'DateAnswer' }
        & Pick<DateAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { date: DateAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'EmailAnswer' }
        & Pick<EmailAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { email: EmailAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'FileAnswer' }
        & Pick<FileAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ListAnswer' }
        & Pick<ListAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'MoneyAnswer' }
        & Pick<MoneyAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { money: MoneyAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'NumberAnswer' }
        & Pick<NumberAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { number: NumberAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PercentAnswer' }
        & Pick<PercentAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { percent: PercentAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PhoneAnswer' }
        & Pick<PhoneAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { phone: PhoneAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'StringAnswer' }
        & Pick<StringAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { string: StringAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'TextAnswer' }
        & Pick<TextAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { text: TextAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      )> }
    ) | (
      { __typename?: 'ListPart' }
      & Pick<ListPart, 'minimumItems' | 'maximumItems' | 'id' | 'label' | 'instructions' | 'isRequired' | 'isComplete' | 'isHighlighted' | 'ordering' | 'officialAdvice' | 'additionalAdvice'>
      & { attributes: Array<(
        { __typename?: 'AddressAttribute' }
        & Pick<AddressAttribute, 'useName' | 'id' | 'label' | 'instructions' | 'isRequired' | 'ordering'>
      ) | (
        { __typename?: 'DateAttribute' }
        & Pick<DateAttribute, 'earliestDate' | 'latestDate' | 'useTime' | 'id' | 'label' | 'instructions' | 'isRequired' | 'ordering'>
      ) | (
        { __typename?: 'EmailAttribute' }
        & Pick<EmailAttribute, 'id' | 'label' | 'instructions' | 'isRequired' | 'ordering'>
      ) | (
        { __typename?: 'FileAttribute' }
        & Pick<FileAttribute, 'maximumFiles' | 'id' | 'label' | 'instructions' | 'isRequired' | 'ordering'>
      ) | (
        { __typename?: 'MoneyAttribute' }
        & Pick<MoneyAttribute, 'minimumAmount' | 'maximumAmount' | 'currency' | 'id' | 'label' | 'instructions' | 'isRequired' | 'ordering'>
      ) | (
        { __typename?: 'NumberAttribute' }
        & Pick<NumberAttribute, 'minimumValue' | 'maximumValue' | 'id' | 'label' | 'instructions' | 'isRequired' | 'ordering'>
      ) | (
        { __typename?: 'PercentAttribute' }
        & Pick<PercentAttribute, 'minimumValue' | 'maximumValue' | 'id' | 'label' | 'instructions' | 'isRequired' | 'ordering'>
      ) | (
        { __typename?: 'PhoneAttribute' }
        & Pick<PhoneAttribute, 'id' | 'label' | 'instructions' | 'isRequired' | 'ordering'>
      ) | (
        { __typename?: 'StringAttribute' }
        & Pick<StringAttribute, 'maximumLength' | 'minimumLength' | 'id' | 'label' | 'instructions' | 'isRequired' | 'ordering'>
      ) | (
        { __typename?: 'TextAttribute' }
        & Pick<TextAttribute, 'maximumLength' | 'minimumLength' | 'id' | 'label' | 'instructions' | 'isRequired' | 'ordering'>
      )>, answerDraft?: Maybe<(
        { __typename?: 'AddressAnswerDraft' }
        & Pick<AddressAnswerDraft, 'id' | 'notApplicable'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'BooleanAnswerDraft' }
        & Pick<BooleanAnswerDraft, 'id' | 'notApplicable'>
        & { boolean: BooleanAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ChoiceAnswerDraft' }
        & Pick<ChoiceAnswerDraft, 'id' | 'notApplicable'>
        & { choice: ChoiceAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'DateAnswerDraft' }
        & Pick<DateAnswerDraft, 'id' | 'notApplicable'>
        & { date: DateAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'EmailAnswerDraft' }
        & Pick<EmailAnswerDraft, 'id' | 'notApplicable'>
        & { email: EmailAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'FileAnswerDraft' }
        & Pick<FileAnswerDraft, 'id' | 'notApplicable'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ListAnswerDraft' }
        & Pick<ListAnswerDraft, 'id' | 'notApplicable'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'MoneyAnswerDraft' }
        & Pick<MoneyAnswerDraft, 'id' | 'notApplicable'>
        & { money: MoneyAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'NumberAnswerDraft' }
        & Pick<NumberAnswerDraft, 'id' | 'notApplicable'>
        & { number: NumberAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PercentAnswerDraft' }
        & Pick<PercentAnswerDraft, 'id' | 'notApplicable'>
        & { percent: PercentAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PhoneAnswerDraft' }
        & Pick<PhoneAnswerDraft, 'id' | 'notApplicable'>
        & { phone: PhoneAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'StringAnswerDraft' }
        & Pick<StringAnswerDraft, 'id' | 'notApplicable'>
        & { string: StringAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'TextAnswerDraft' }
        & Pick<TextAnswerDraft, 'id' | 'notApplicable'>
        & { text: TextAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      )>, answer?: Maybe<(
        { __typename?: 'AddressAnswer' }
        & Pick<AddressAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'BooleanAnswer' }
        & Pick<BooleanAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { boolean: BooleanAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ChoiceAnswer' }
        & Pick<ChoiceAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { choice: ChoiceAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'DateAnswer' }
        & Pick<DateAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { date: DateAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'EmailAnswer' }
        & Pick<EmailAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { email: EmailAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'FileAnswer' }
        & Pick<FileAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ListAnswer' }
        & Pick<ListAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'MoneyAnswer' }
        & Pick<MoneyAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { money: MoneyAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'NumberAnswer' }
        & Pick<NumberAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { number: NumberAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PercentAnswer' }
        & Pick<PercentAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { percent: PercentAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PhoneAnswer' }
        & Pick<PhoneAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { phone: PhoneAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'StringAnswer' }
        & Pick<StringAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { string: StringAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'TextAnswer' }
        & Pick<TextAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { text: TextAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      )> }
    ) | (
      { __typename?: 'MoneyPart' }
      & Pick<MoneyPart, 'minimumAmount' | 'maximumAmount' | 'currency' | 'id' | 'label' | 'instructions' | 'isRequired' | 'isComplete' | 'isHighlighted' | 'ordering' | 'officialAdvice' | 'additionalAdvice'>
      & { answerDraft?: Maybe<(
        { __typename?: 'AddressAnswerDraft' }
        & Pick<AddressAnswerDraft, 'id' | 'notApplicable'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'BooleanAnswerDraft' }
        & Pick<BooleanAnswerDraft, 'id' | 'notApplicable'>
        & { boolean: BooleanAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ChoiceAnswerDraft' }
        & Pick<ChoiceAnswerDraft, 'id' | 'notApplicable'>
        & { choice: ChoiceAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'DateAnswerDraft' }
        & Pick<DateAnswerDraft, 'id' | 'notApplicable'>
        & { date: DateAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'EmailAnswerDraft' }
        & Pick<EmailAnswerDraft, 'id' | 'notApplicable'>
        & { email: EmailAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'FileAnswerDraft' }
        & Pick<FileAnswerDraft, 'id' | 'notApplicable'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ListAnswerDraft' }
        & Pick<ListAnswerDraft, 'id' | 'notApplicable'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'MoneyAnswerDraft' }
        & Pick<MoneyAnswerDraft, 'id' | 'notApplicable'>
        & { money: MoneyAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'NumberAnswerDraft' }
        & Pick<NumberAnswerDraft, 'id' | 'notApplicable'>
        & { number: NumberAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PercentAnswerDraft' }
        & Pick<PercentAnswerDraft, 'id' | 'notApplicable'>
        & { percent: PercentAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PhoneAnswerDraft' }
        & Pick<PhoneAnswerDraft, 'id' | 'notApplicable'>
        & { phone: PhoneAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'StringAnswerDraft' }
        & Pick<StringAnswerDraft, 'id' | 'notApplicable'>
        & { string: StringAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'TextAnswerDraft' }
        & Pick<TextAnswerDraft, 'id' | 'notApplicable'>
        & { text: TextAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      )>, answer?: Maybe<(
        { __typename?: 'AddressAnswer' }
        & Pick<AddressAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'BooleanAnswer' }
        & Pick<BooleanAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { boolean: BooleanAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ChoiceAnswer' }
        & Pick<ChoiceAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { choice: ChoiceAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'DateAnswer' }
        & Pick<DateAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { date: DateAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'EmailAnswer' }
        & Pick<EmailAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { email: EmailAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'FileAnswer' }
        & Pick<FileAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ListAnswer' }
        & Pick<ListAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'MoneyAnswer' }
        & Pick<MoneyAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { money: MoneyAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'NumberAnswer' }
        & Pick<NumberAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { number: NumberAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PercentAnswer' }
        & Pick<PercentAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { percent: PercentAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PhoneAnswer' }
        & Pick<PhoneAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { phone: PhoneAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'StringAnswer' }
        & Pick<StringAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { string: StringAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'TextAnswer' }
        & Pick<TextAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { text: TextAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      )> }
    ) | (
      { __typename?: 'NumberPart' }
      & Pick<NumberPart, 'minimumValue' | 'maximumValue' | 'id' | 'label' | 'instructions' | 'isRequired' | 'isComplete' | 'isHighlighted' | 'ordering' | 'officialAdvice' | 'additionalAdvice'>
      & { answerDraft?: Maybe<(
        { __typename?: 'AddressAnswerDraft' }
        & Pick<AddressAnswerDraft, 'id' | 'notApplicable'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'BooleanAnswerDraft' }
        & Pick<BooleanAnswerDraft, 'id' | 'notApplicable'>
        & { boolean: BooleanAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ChoiceAnswerDraft' }
        & Pick<ChoiceAnswerDraft, 'id' | 'notApplicable'>
        & { choice: ChoiceAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'DateAnswerDraft' }
        & Pick<DateAnswerDraft, 'id' | 'notApplicable'>
        & { date: DateAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'EmailAnswerDraft' }
        & Pick<EmailAnswerDraft, 'id' | 'notApplicable'>
        & { email: EmailAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'FileAnswerDraft' }
        & Pick<FileAnswerDraft, 'id' | 'notApplicable'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ListAnswerDraft' }
        & Pick<ListAnswerDraft, 'id' | 'notApplicable'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'MoneyAnswerDraft' }
        & Pick<MoneyAnswerDraft, 'id' | 'notApplicable'>
        & { money: MoneyAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'NumberAnswerDraft' }
        & Pick<NumberAnswerDraft, 'id' | 'notApplicable'>
        & { number: NumberAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PercentAnswerDraft' }
        & Pick<PercentAnswerDraft, 'id' | 'notApplicable'>
        & { percent: PercentAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PhoneAnswerDraft' }
        & Pick<PhoneAnswerDraft, 'id' | 'notApplicable'>
        & { phone: PhoneAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'StringAnswerDraft' }
        & Pick<StringAnswerDraft, 'id' | 'notApplicable'>
        & { string: StringAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'TextAnswerDraft' }
        & Pick<TextAnswerDraft, 'id' | 'notApplicable'>
        & { text: TextAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      )>, answer?: Maybe<(
        { __typename?: 'AddressAnswer' }
        & Pick<AddressAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'BooleanAnswer' }
        & Pick<BooleanAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { boolean: BooleanAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ChoiceAnswer' }
        & Pick<ChoiceAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { choice: ChoiceAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'DateAnswer' }
        & Pick<DateAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { date: DateAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'EmailAnswer' }
        & Pick<EmailAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { email: EmailAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'FileAnswer' }
        & Pick<FileAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ListAnswer' }
        & Pick<ListAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'MoneyAnswer' }
        & Pick<MoneyAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { money: MoneyAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'NumberAnswer' }
        & Pick<NumberAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { number: NumberAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PercentAnswer' }
        & Pick<PercentAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { percent: PercentAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PhoneAnswer' }
        & Pick<PhoneAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { phone: PhoneAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'StringAnswer' }
        & Pick<StringAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { string: StringAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'TextAnswer' }
        & Pick<TextAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { text: TextAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      )> }
    ) | (
      { __typename?: 'PercentPart' }
      & Pick<PercentPart, 'minimumValue' | 'maximumValue' | 'id' | 'label' | 'instructions' | 'isRequired' | 'isComplete' | 'isHighlighted' | 'ordering' | 'officialAdvice' | 'additionalAdvice'>
      & { answerDraft?: Maybe<(
        { __typename?: 'AddressAnswerDraft' }
        & Pick<AddressAnswerDraft, 'id' | 'notApplicable'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'BooleanAnswerDraft' }
        & Pick<BooleanAnswerDraft, 'id' | 'notApplicable'>
        & { boolean: BooleanAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ChoiceAnswerDraft' }
        & Pick<ChoiceAnswerDraft, 'id' | 'notApplicable'>
        & { choice: ChoiceAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'DateAnswerDraft' }
        & Pick<DateAnswerDraft, 'id' | 'notApplicable'>
        & { date: DateAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'EmailAnswerDraft' }
        & Pick<EmailAnswerDraft, 'id' | 'notApplicable'>
        & { email: EmailAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'FileAnswerDraft' }
        & Pick<FileAnswerDraft, 'id' | 'notApplicable'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ListAnswerDraft' }
        & Pick<ListAnswerDraft, 'id' | 'notApplicable'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'MoneyAnswerDraft' }
        & Pick<MoneyAnswerDraft, 'id' | 'notApplicable'>
        & { money: MoneyAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'NumberAnswerDraft' }
        & Pick<NumberAnswerDraft, 'id' | 'notApplicable'>
        & { number: NumberAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PercentAnswerDraft' }
        & Pick<PercentAnswerDraft, 'id' | 'notApplicable'>
        & { percent: PercentAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PhoneAnswerDraft' }
        & Pick<PhoneAnswerDraft, 'id' | 'notApplicable'>
        & { phone: PhoneAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'StringAnswerDraft' }
        & Pick<StringAnswerDraft, 'id' | 'notApplicable'>
        & { string: StringAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'TextAnswerDraft' }
        & Pick<TextAnswerDraft, 'id' | 'notApplicable'>
        & { text: TextAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      )>, answer?: Maybe<(
        { __typename?: 'AddressAnswer' }
        & Pick<AddressAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'BooleanAnswer' }
        & Pick<BooleanAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { boolean: BooleanAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ChoiceAnswer' }
        & Pick<ChoiceAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { choice: ChoiceAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'DateAnswer' }
        & Pick<DateAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { date: DateAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'EmailAnswer' }
        & Pick<EmailAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { email: EmailAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'FileAnswer' }
        & Pick<FileAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ListAnswer' }
        & Pick<ListAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'MoneyAnswer' }
        & Pick<MoneyAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { money: MoneyAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'NumberAnswer' }
        & Pick<NumberAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { number: NumberAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PercentAnswer' }
        & Pick<PercentAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { percent: PercentAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PhoneAnswer' }
        & Pick<PhoneAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { phone: PhoneAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'StringAnswer' }
        & Pick<StringAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { string: StringAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'TextAnswer' }
        & Pick<TextAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { text: TextAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      )> }
    ) | (
      { __typename?: 'PhonePart' }
      & Pick<PhonePart, 'id' | 'label' | 'instructions' | 'isRequired' | 'isComplete' | 'isHighlighted' | 'ordering' | 'officialAdvice' | 'additionalAdvice'>
      & { answerDraft?: Maybe<(
        { __typename?: 'AddressAnswerDraft' }
        & Pick<AddressAnswerDraft, 'id' | 'notApplicable'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'BooleanAnswerDraft' }
        & Pick<BooleanAnswerDraft, 'id' | 'notApplicable'>
        & { boolean: BooleanAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ChoiceAnswerDraft' }
        & Pick<ChoiceAnswerDraft, 'id' | 'notApplicable'>
        & { choice: ChoiceAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'DateAnswerDraft' }
        & Pick<DateAnswerDraft, 'id' | 'notApplicable'>
        & { date: DateAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'EmailAnswerDraft' }
        & Pick<EmailAnswerDraft, 'id' | 'notApplicable'>
        & { email: EmailAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'FileAnswerDraft' }
        & Pick<FileAnswerDraft, 'id' | 'notApplicable'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ListAnswerDraft' }
        & Pick<ListAnswerDraft, 'id' | 'notApplicable'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'MoneyAnswerDraft' }
        & Pick<MoneyAnswerDraft, 'id' | 'notApplicable'>
        & { money: MoneyAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'NumberAnswerDraft' }
        & Pick<NumberAnswerDraft, 'id' | 'notApplicable'>
        & { number: NumberAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PercentAnswerDraft' }
        & Pick<PercentAnswerDraft, 'id' | 'notApplicable'>
        & { percent: PercentAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PhoneAnswerDraft' }
        & Pick<PhoneAnswerDraft, 'id' | 'notApplicable'>
        & { phone: PhoneAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'StringAnswerDraft' }
        & Pick<StringAnswerDraft, 'id' | 'notApplicable'>
        & { string: StringAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'TextAnswerDraft' }
        & Pick<TextAnswerDraft, 'id' | 'notApplicable'>
        & { text: TextAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      )>, answer?: Maybe<(
        { __typename?: 'AddressAnswer' }
        & Pick<AddressAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'BooleanAnswer' }
        & Pick<BooleanAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { boolean: BooleanAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ChoiceAnswer' }
        & Pick<ChoiceAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { choice: ChoiceAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'DateAnswer' }
        & Pick<DateAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { date: DateAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'EmailAnswer' }
        & Pick<EmailAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { email: EmailAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'FileAnswer' }
        & Pick<FileAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ListAnswer' }
        & Pick<ListAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'MoneyAnswer' }
        & Pick<MoneyAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { money: MoneyAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'NumberAnswer' }
        & Pick<NumberAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { number: NumberAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PercentAnswer' }
        & Pick<PercentAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { percent: PercentAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PhoneAnswer' }
        & Pick<PhoneAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { phone: PhoneAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'StringAnswer' }
        & Pick<StringAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { string: StringAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'TextAnswer' }
        & Pick<TextAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { text: TextAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      )> }
    ) | (
      { __typename?: 'StringPart' }
      & Pick<StringPart, 'maximumLength' | 'minimumLength' | 'id' | 'label' | 'instructions' | 'isRequired' | 'isComplete' | 'isHighlighted' | 'ordering' | 'officialAdvice' | 'additionalAdvice'>
      & { answerDraft?: Maybe<(
        { __typename?: 'AddressAnswerDraft' }
        & Pick<AddressAnswerDraft, 'id' | 'notApplicable'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'BooleanAnswerDraft' }
        & Pick<BooleanAnswerDraft, 'id' | 'notApplicable'>
        & { boolean: BooleanAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ChoiceAnswerDraft' }
        & Pick<ChoiceAnswerDraft, 'id' | 'notApplicable'>
        & { choice: ChoiceAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'DateAnswerDraft' }
        & Pick<DateAnswerDraft, 'id' | 'notApplicable'>
        & { date: DateAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'EmailAnswerDraft' }
        & Pick<EmailAnswerDraft, 'id' | 'notApplicable'>
        & { email: EmailAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'FileAnswerDraft' }
        & Pick<FileAnswerDraft, 'id' | 'notApplicable'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ListAnswerDraft' }
        & Pick<ListAnswerDraft, 'id' | 'notApplicable'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'MoneyAnswerDraft' }
        & Pick<MoneyAnswerDraft, 'id' | 'notApplicable'>
        & { money: MoneyAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'NumberAnswerDraft' }
        & Pick<NumberAnswerDraft, 'id' | 'notApplicable'>
        & { number: NumberAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PercentAnswerDraft' }
        & Pick<PercentAnswerDraft, 'id' | 'notApplicable'>
        & { percent: PercentAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PhoneAnswerDraft' }
        & Pick<PhoneAnswerDraft, 'id' | 'notApplicable'>
        & { phone: PhoneAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'StringAnswerDraft' }
        & Pick<StringAnswerDraft, 'id' | 'notApplicable'>
        & { string: StringAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'TextAnswerDraft' }
        & Pick<TextAnswerDraft, 'id' | 'notApplicable'>
        & { text: TextAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      )>, answer?: Maybe<(
        { __typename?: 'AddressAnswer' }
        & Pick<AddressAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'BooleanAnswer' }
        & Pick<BooleanAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { boolean: BooleanAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ChoiceAnswer' }
        & Pick<ChoiceAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { choice: ChoiceAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'DateAnswer' }
        & Pick<DateAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { date: DateAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'EmailAnswer' }
        & Pick<EmailAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { email: EmailAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'FileAnswer' }
        & Pick<FileAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ListAnswer' }
        & Pick<ListAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'MoneyAnswer' }
        & Pick<MoneyAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { money: MoneyAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'NumberAnswer' }
        & Pick<NumberAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { number: NumberAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PercentAnswer' }
        & Pick<PercentAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { percent: PercentAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PhoneAnswer' }
        & Pick<PhoneAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { phone: PhoneAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'StringAnswer' }
        & Pick<StringAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { string: StringAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'TextAnswer' }
        & Pick<TextAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { text: TextAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      )> }
    ) | (
      { __typename?: 'TextPart' }
      & Pick<TextPart, 'maximumLength' | 'minimumLength' | 'id' | 'label' | 'instructions' | 'isRequired' | 'isComplete' | 'isHighlighted' | 'ordering' | 'officialAdvice' | 'additionalAdvice'>
      & { answerDraft?: Maybe<(
        { __typename?: 'AddressAnswerDraft' }
        & Pick<AddressAnswerDraft, 'id' | 'notApplicable'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'BooleanAnswerDraft' }
        & Pick<BooleanAnswerDraft, 'id' | 'notApplicable'>
        & { boolean: BooleanAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ChoiceAnswerDraft' }
        & Pick<ChoiceAnswerDraft, 'id' | 'notApplicable'>
        & { choice: ChoiceAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'DateAnswerDraft' }
        & Pick<DateAnswerDraft, 'id' | 'notApplicable'>
        & { date: DateAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'EmailAnswerDraft' }
        & Pick<EmailAnswerDraft, 'id' | 'notApplicable'>
        & { email: EmailAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'FileAnswerDraft' }
        & Pick<FileAnswerDraft, 'id' | 'notApplicable'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ListAnswerDraft' }
        & Pick<ListAnswerDraft, 'id' | 'notApplicable'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'MoneyAnswerDraft' }
        & Pick<MoneyAnswerDraft, 'id' | 'notApplicable'>
        & { money: MoneyAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'NumberAnswerDraft' }
        & Pick<NumberAnswerDraft, 'id' | 'notApplicable'>
        & { number: NumberAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PercentAnswerDraft' }
        & Pick<PercentAnswerDraft, 'id' | 'notApplicable'>
        & { percent: PercentAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PhoneAnswerDraft' }
        & Pick<PhoneAnswerDraft, 'id' | 'notApplicable'>
        & { phone: PhoneAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'StringAnswerDraft' }
        & Pick<StringAnswerDraft, 'id' | 'notApplicable'>
        & { string: StringAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'TextAnswerDraft' }
        & Pick<TextAnswerDraft, 'id' | 'notApplicable'>
        & { text: TextAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      )>, answer?: Maybe<(
        { __typename?: 'AddressAnswer' }
        & Pick<AddressAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'BooleanAnswer' }
        & Pick<BooleanAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { boolean: BooleanAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ChoiceAnswer' }
        & Pick<ChoiceAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { choice: ChoiceAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'DateAnswer' }
        & Pick<DateAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { date: DateAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'EmailAnswer' }
        & Pick<EmailAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { email: EmailAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'FileAnswer' }
        & Pick<FileAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ListAnswer' }
        & Pick<ListAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'MoneyAnswer' }
        & Pick<MoneyAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { money: MoneyAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'NumberAnswer' }
        & Pick<NumberAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { number: NumberAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PercentAnswer' }
        & Pick<PercentAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { percent: PercentAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PhoneAnswer' }
        & Pick<PhoneAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { phone: PhoneAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'StringAnswer' }
        & Pick<StringAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { string: StringAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'TextAnswer' }
        & Pick<TextAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { text: TextAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      )> }
    )> }
  )> }
);

export type GetEditableQuestionQueryVariables = Exact<{
  questionId: Scalars['ID'];
}>;


export type GetEditableQuestionQuery = (
  { __typename?: 'Query' }
  & { question?: Maybe<(
    { __typename?: 'Question' }
    & Pick<Question, 'id' | 'text' | 'description' | 'officialAdvice' | 'additionalAdvice'>
    & { parts: Array<(
      { __typename?: 'AddressPart' }
      & Pick<AddressPart, 'label' | 'instructions' | 'type' | 'ordering' | 'id' | 'officialAdvice' | 'additionalAdvice'>
      & { configuration: (
        { __typename?: 'AddressPartConfiguration' }
        & Pick<AddressPartConfiguration, 'useName' | 'isRequired'>
      ) }
    ) | (
      { __typename?: 'BooleanPart' }
      & Pick<BooleanPart, 'label' | 'instructions' | 'type' | 'ordering' | 'id' | 'officialAdvice' | 'additionalAdvice'>
      & { configuration: (
        { __typename?: 'BooleanPartConfiguration' }
        & Pick<BooleanPartConfiguration, 'positiveLabel' | 'negativeLabel'>
      ) }
    ) | (
      { __typename?: 'ChoicePart' }
      & Pick<ChoicePart, 'label' | 'instructions' | 'type' | 'ordering' | 'id' | 'officialAdvice' | 'additionalAdvice'>
      & { configuration: (
        { __typename?: 'ChoicePartConfiguration' }
        & Pick<ChoicePartConfiguration, 'isRequired' | 'minimumChoices' | 'maximumChoices'>
        & { options: Array<(
          { __typename?: 'Option' }
          & Pick<Option, 'value' | 'description'>
        )> }
      ) }
    ) | (
      { __typename?: 'DatePart' }
      & Pick<DatePart, 'label' | 'instructions' | 'type' | 'ordering' | 'id' | 'officialAdvice' | 'additionalAdvice'>
      & { configuration: (
        { __typename?: 'DatePartConfiguration' }
        & Pick<DatePartConfiguration, 'isRequired' | 'earliestDate' | 'latestDate' | 'useTime'>
      ) }
    ) | (
      { __typename?: 'EmailPart' }
      & Pick<EmailPart, 'label' | 'instructions' | 'type' | 'ordering' | 'id' | 'officialAdvice' | 'additionalAdvice'>
      & { configuration: (
        { __typename?: 'EmailPartConfiguration' }
        & Pick<EmailPartConfiguration, 'isRequired'>
      ) }
    ) | (
      { __typename?: 'FilePart' }
      & Pick<FilePart, 'label' | 'instructions' | 'type' | 'ordering' | 'id' | 'officialAdvice' | 'additionalAdvice'>
      & { configuration: (
        { __typename?: 'FilePartConfiguration' }
        & Pick<FilePartConfiguration, 'isRequired' | 'maximumFiles'>
      ) }
    ) | (
      { __typename?: 'ListPart' }
      & Pick<ListPart, 'label' | 'instructions' | 'type' | 'ordering' | 'id' | 'officialAdvice' | 'additionalAdvice'>
      & { configuration: (
        { __typename?: 'ListPartConfiguration' }
        & Pick<ListPartConfiguration, 'isRequired' | 'minimumItems' | 'maximumItems'>
        & { attributes: Array<(
          { __typename?: 'AddressAttribute' }
          & Pick<AddressAttribute, 'label' | 'instructions' | 'ordering' | 'type' | 'id'>
          & { configuration: (
            { __typename?: 'AddressPartConfiguration' }
            & Pick<AddressPartConfiguration, 'isRequired' | 'useName'>
          ) }
        ) | (
          { __typename?: 'DateAttribute' }
          & Pick<DateAttribute, 'label' | 'instructions' | 'ordering' | 'type' | 'id'>
          & { configuration: (
            { __typename?: 'DatePartConfiguration' }
            & Pick<DatePartConfiguration, 'isRequired' | 'earliestDate' | 'latestDate' | 'useTime'>
          ) }
        ) | (
          { __typename?: 'EmailAttribute' }
          & Pick<EmailAttribute, 'label' | 'instructions' | 'ordering' | 'type' | 'id'>
          & { configuration: (
            { __typename?: 'EmailPartConfiguration' }
            & Pick<EmailPartConfiguration, 'isRequired'>
          ) }
        ) | (
          { __typename?: 'FileAttribute' }
          & Pick<FileAttribute, 'label' | 'instructions' | 'ordering' | 'type' | 'id'>
          & { configuration: (
            { __typename?: 'FilePartConfiguration' }
            & Pick<FilePartConfiguration, 'isRequired' | 'maximumFiles'>
          ) }
        ) | (
          { __typename?: 'MoneyAttribute' }
          & Pick<MoneyAttribute, 'label' | 'instructions' | 'ordering' | 'type' | 'id'>
          & { configuration: (
            { __typename?: 'MoneyPartConfiguration' }
            & Pick<MoneyPartConfiguration, 'isRequired' | 'minimumAmount' | 'maximumAmount' | 'currency'>
          ) }
        ) | (
          { __typename?: 'NumberAttribute' }
          & Pick<NumberAttribute, 'label' | 'instructions' | 'ordering' | 'type' | 'id'>
          & { configuration: (
            { __typename?: 'NumberPartConfiguration' }
            & Pick<NumberPartConfiguration, 'isRequired' | 'minimumValue' | 'maximumValue'>
          ) }
        ) | (
          { __typename?: 'PercentAttribute' }
          & Pick<PercentAttribute, 'label' | 'instructions' | 'ordering' | 'type' | 'id'>
          & { configuration: (
            { __typename?: 'PercentPartConfiguration' }
            & Pick<PercentPartConfiguration, 'isRequired' | 'minimumValue' | 'maximumValue'>
          ) }
        ) | (
          { __typename?: 'PhoneAttribute' }
          & Pick<PhoneAttribute, 'label' | 'instructions' | 'ordering' | 'type' | 'id'>
          & { configuration: (
            { __typename?: 'PhonePartConfiguration' }
            & Pick<PhonePartConfiguration, 'isRequired'>
          ) }
        ) | (
          { __typename?: 'StringAttribute' }
          & Pick<StringAttribute, 'label' | 'instructions' | 'ordering' | 'type' | 'id'>
          & { configuration: (
            { __typename?: 'StringPartConfiguration' }
            & Pick<StringPartConfiguration, 'isRequired' | 'maximumLength' | 'minimumLength'>
          ) }
        ) | (
          { __typename?: 'TextAttribute' }
          & Pick<TextAttribute, 'label' | 'instructions' | 'ordering' | 'type' | 'id'>
          & { configuration: (
            { __typename?: 'TextPartConfiguration' }
            & Pick<TextPartConfiguration, 'isRequired' | 'maximumLength' | 'minimumLength'>
          ) }
        )> }
      ) }
    ) | (
      { __typename?: 'MoneyPart' }
      & Pick<MoneyPart, 'label' | 'instructions' | 'type' | 'ordering' | 'id' | 'officialAdvice' | 'additionalAdvice'>
      & { configuration: (
        { __typename?: 'MoneyPartConfiguration' }
        & Pick<MoneyPartConfiguration, 'isRequired' | 'maximumAmount' | 'minimumAmount' | 'currency'>
      ) }
    ) | (
      { __typename?: 'NumberPart' }
      & Pick<NumberPart, 'label' | 'instructions' | 'type' | 'ordering' | 'id' | 'officialAdvice' | 'additionalAdvice'>
      & { configuration: (
        { __typename?: 'NumberPartConfiguration' }
        & Pick<NumberPartConfiguration, 'isRequired' | 'minimumValue' | 'maximumValue'>
      ) }
    ) | (
      { __typename?: 'PercentPart' }
      & Pick<PercentPart, 'label' | 'instructions' | 'type' | 'ordering' | 'id' | 'officialAdvice' | 'additionalAdvice'>
      & { configuration: (
        { __typename?: 'PercentPartConfiguration' }
        & Pick<PercentPartConfiguration, 'isRequired' | 'minimumValue' | 'maximumValue'>
      ) }
    ) | (
      { __typename?: 'PhonePart' }
      & Pick<PhonePart, 'label' | 'instructions' | 'type' | 'ordering' | 'id' | 'officialAdvice' | 'additionalAdvice'>
      & { configuration: (
        { __typename?: 'PhonePartConfiguration' }
        & Pick<PhonePartConfiguration, 'isRequired'>
      ) }
    ) | (
      { __typename?: 'StringPart' }
      & Pick<StringPart, 'label' | 'instructions' | 'type' | 'ordering' | 'id' | 'officialAdvice' | 'additionalAdvice'>
      & { configuration: (
        { __typename?: 'StringPartConfiguration' }
        & Pick<StringPartConfiguration, 'isRequired' | 'maximumLength' | 'minimumLength'>
      ) }
    ) | (
      { __typename?: 'TextPart' }
      & Pick<TextPart, 'label' | 'instructions' | 'type' | 'ordering' | 'id' | 'officialAdvice' | 'additionalAdvice'>
      & { configuration: (
        { __typename?: 'TextPartConfiguration' }
        & Pick<TextPartConfiguration, 'isRequired' | 'maximumLength' | 'minimumLength'>
      ) }
    )> }
  )> }
);

export type SegmentsFragment = (
  { __typename?: 'PathSegment' }
  & Pick<PathSegment, 'id' | 'type' | 'name'>
);

export type FilesFragment = (
  { __typename?: 'MatchedFile' }
  & Pick<MatchedFile, 'id' | 'type' | 'name' | 'mime' | 'folderId' | 'lastRevisedOn'>
  & { path: Array<(
    { __typename?: 'PathSegment' }
    & SegmentsFragment
  )> }
);

export type ContactsFragment = (
  { __typename?: 'MatchedContact' }
  & Pick<MatchedContact, 'id' | 'type' | 'name' | 'pictureUrl' | 'isTransparentlyUser' | 'isAssociatedWithFirm' | 'firmName' | 'isAssociatedWithIssue' | 'issueName' | 'addedOn'>
  & { path: Array<(
    { __typename?: 'PathSegment' }
    & SegmentsFragment
  )> }
);

export type FoldersFragment = (
  { __typename?: 'MatchedFolder' }
  & Pick<MatchedFolder, 'id' | 'name' | 'addedOn'>
  & { path: Array<(
    { __typename?: 'PathSegment' }
    & SegmentsFragment
  )> }
);

export type MessagesFragment = (
  { __typename?: 'MatchedMessage' }
  & Pick<MatchedMessage, 'id' | 'conversationId' | 'senderName' | 'title' | 'body' | 'sentAt' | 'attachmentCount'>
  & { path: Array<(
    { __typename?: 'PathSegment' }
    & SegmentsFragment
  )> }
);

export type ClientAccountsFragment = (
  { __typename?: 'MatchedClientAccount' }
  & Pick<MatchedClientAccount, 'id' | 'clientName' | 'clientPictureUrl' | 'status'>
  & { path: Array<(
    { __typename?: 'PathSegment' }
    & SegmentsFragment
  )> }
);

export type TasksFragment = (
  { __typename?: 'MatchedTask' }
  & Pick<MatchedTask, 'id' | 'matterId' | 'name' | 'proportionComplete' | 'dueDate' | 'clientId' | 'clientName' | 'clientAccountId'>
  & { path: Array<(
    { __typename?: 'PathSegment' }
    & SegmentsFragment
  )> }
);

export type NotesFragment = (
  { __typename?: 'MatchedNote' }
  & Pick<MatchedNote, 'id' | 'category' | 'dateTime' | 'versionNumber' | 'authorId' | 'authorName' | 'authorPictureUrl' | 'title'>
  & { noteClientName: MatchedNote['clientName'], noteClientId: MatchedNote['clientId'], noteClientPictureUrl: MatchedNote['clientPictureUrl'] }
  & { path: Array<(
    { __typename?: 'PathSegment' }
    & SegmentsFragment
  )> }
);

export type SearchQueryVariables = Exact<{
  term: Scalars['String'];
  context?: Maybe<SearchContext>;
}>;


export type SearchQuery = (
  { __typename?: 'Query' }
  & { search?: Maybe<(
    { __typename?: 'SearchResult' }
    & Pick<SearchResult, 'term' | 'context'>
    & { contextualMatches?: Maybe<Array<(
      { __typename: 'MatchedContact' }
      & ContactsFragment
    ) | (
      { __typename: 'MatchedFile' }
      & FilesFragment
    ) | (
      { __typename: 'MatchedFolder' }
      & FoldersFragment
    ) | (
      { __typename: 'MatchedMessage' }
      & MessagesFragment
    ) | (
      { __typename: 'MatchedClientAccount' }
      & ClientAccountsFragment
    ) | (
      { __typename: 'MatchedNote' }
      & NotesFragment
    ) | (
      { __typename: 'MatchedTask' }
      & TasksFragment
    )>>, otherMatches: Array<(
      { __typename: 'MatchedContact' }
      & ContactsFragment
    ) | (
      { __typename: 'MatchedFile' }
      & FilesFragment
    ) | (
      { __typename: 'MatchedFolder' }
      & FoldersFragment
    ) | (
      { __typename: 'MatchedMessage' }
      & MessagesFragment
    ) | (
      { __typename: 'MatchedClientAccount' }
      & ClientAccountsFragment
    ) | (
      { __typename: 'MatchedNote' }
      & NotesFragment
    ) | (
      { __typename: 'MatchedTask' }
      & TasksFragment
    )> }
  )> }
);

export type ListTasksQueryVariables = Exact<{
  matterId: Scalars['ID'];
}>;


export type ListTasksQuery = (
  { __typename?: 'Query' }
  & { matterTasks: Array<(
    { __typename?: 'MatterTask' }
    & Pick<MatterTask, 'id' | 'name' | 'description' | 'status' | 'sectionCount' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'isModifiable' | 'officialAdvice' | 'additionalAdvice'>
    & { dueDate?: Maybe<(
      { __typename?: 'KeyDate' }
      & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
    )>, sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'officialAdvice' | 'additionalAdvice' | 'isHighlighted'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  )> }
);

export type ListLibraryTasksQueryVariables = Exact<{ [key: string]: never; }>;


export type ListLibraryTasksQuery = (
  { __typename?: 'Query' }
  & { libraryTasks: Array<(
    { __typename?: 'LibraryTask' }
    & Pick<LibraryTask, 'id' | 'name' | 'description' | 'matterTypes' | 'sectionCount' | 'isModifiable'>
  )> }
);

export type ListTemplateTasksQueryVariables = Exact<{
  includeUnpublished?: Maybe<Scalars['Boolean']>;
}>;


export type ListTemplateTasksQuery = (
  { __typename?: 'Query' }
  & { templateTasks: Array<(
    { __typename?: 'FirmTask' }
    & Pick<FirmTask, 'id' | 'name' | 'description' | 'matterTypes' | 'sectionCount' | 'isModifiable' | 'isPublished' | 'additionalAdvice' | 'officialAdvice'>
  ) | (
    { __typename?: 'LibraryTask' }
    & Pick<LibraryTask, 'id' | 'name' | 'description' | 'matterTypes' | 'sectionCount' | 'isModifiable' | 'isPublished' | 'additionalAdvice' | 'officialAdvice'>
  )> }
);

export type GetTaskQueryVariables = Exact<{
  taskId: Scalars['ID'];
}>;


export type GetTaskQuery = (
  { __typename?: 'Query' }
  & { task?: Maybe<(
    { __typename?: 'FirmTask' }
    & Pick<FirmTask, 'matterTypes' | 'isPublished' | 'id' | 'name' | 'description' | 'sectionCount' | 'isModifiable' | 'officialAdvice' | 'additionalAdvice'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'officialAdvice' | 'additionalAdvice' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  ) | (
    { __typename?: 'LibraryTask' }
    & Pick<LibraryTask, 'matterTypes' | 'id' | 'name' | 'description' | 'sectionCount' | 'isModifiable' | 'officialAdvice' | 'additionalAdvice'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'officialAdvice' | 'additionalAdvice' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  ) | (
    { __typename?: 'MatterTask' }
    & Pick<MatterTask, 'status' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'id' | 'name' | 'description' | 'sectionCount' | 'isModifiable' | 'officialAdvice' | 'additionalAdvice'>
    & { dueDate?: Maybe<(
      { __typename?: 'KeyDate' }
      & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
    )>, sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'officialAdvice' | 'additionalAdvice' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  )> }
);

export type GetTaskSectionQueryVariables = Exact<{
  taskId: Scalars['ID'];
  sectionId: Scalars['ID'];
}>;


export type GetTaskSectionQuery = (
  { __typename?: 'Query' }
  & { taskSection?: Maybe<(
    { __typename?: 'TaskSection' }
    & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    & { dueDate?: Maybe<(
      { __typename?: 'KeyDate' }
      & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
    )>, questions: Array<(
      { __typename?: 'Question' }
      & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
      & { parts: Array<(
        { __typename?: 'AddressPart' }
        & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      ) | (
        { __typename?: 'BooleanPart' }
        & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      ) | (
        { __typename?: 'ChoicePart' }
        & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      ) | (
        { __typename?: 'DatePart' }
        & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      ) | (
        { __typename?: 'EmailPart' }
        & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      ) | (
        { __typename?: 'FilePart' }
        & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      ) | (
        { __typename?: 'ListPart' }
        & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      ) | (
        { __typename?: 'MoneyPart' }
        & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      ) | (
        { __typename?: 'NumberPart' }
        & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      ) | (
        { __typename?: 'PercentPart' }
        & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      ) | (
        { __typename?: 'PhonePart' }
        & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      ) | (
        { __typename?: 'StringPart' }
        & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      ) | (
        { __typename?: 'TextPart' }
        & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
      )> }
    )> }
  )> }
);

export type ListPrivateTopicsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListPrivateTopicsQuery = (
  { __typename?: 'Query' }
  & { privateTopics: Array<(
    { __typename?: 'PrivateTopic' }
    & Pick<PrivateTopic, 'id' | 'ownerId' | 'name' | 'slug' | 'colour'>
  )> }
);

export type GetTopicQueryVariables = Exact<{
  topicId: Scalars['ID'];
}>;


export type GetTopicQuery = (
  { __typename?: 'Query' }
  & { topic?: Maybe<(
    { __typename?: 'FirmTopic' }
    & Pick<FirmTopic, 'firmId' | 'id' | 'name' | 'slug' | 'colour'>
  ) | (
    { __typename?: 'GeneralTopic' }
    & Pick<GeneralTopic, 'id' | 'name' | 'slug' | 'colour'>
  ) | (
    { __typename?: 'PrivateTopic' }
    & Pick<PrivateTopic, 'ownerId' | 'id' | 'name' | 'slug' | 'colour'>
  )> }
);

export type ClientUpdateSubscriptionVariables = Exact<{
  clientId: Scalars['ID'];
}>;


export type ClientUpdateSubscription = (
  { __typename?: 'Subscription' }
  & { clientUpdate?: Maybe<(
    { __typename?: 'FirmClient' }
    & Pick<FirmClient, 'id' | 'name' | 'fullName' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'previousFirstName' | 'previousLastName' | 'previousMiddleNames' | 'pictureUrl' | 'birthDate' | 'emailAddress' | 'mobilePhoneNumber' | 'homePhoneNumber' | 'workPhoneNumber' | 'nationalInsuranceNumber' | 'profileIsComplete' | 'status' | 'referenceNumber' | 'isVerified' | 'verifiedAt'>
    & { homeAddress?: Maybe<(
      { __typename?: 'Address' }
      & AddressFieldsFragment
    )>, previousHomeAddresses?: Maybe<Array<(
      { __typename?: 'Address' }
      & AddressFieldsFragment
    )>>, workAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'name'>
      & AddressFieldsFragment
    )>, addressProof?: Maybe<Array<(
      { __typename?: 'AddressProof' }
      & Pick<AddressProof, 'id' | 'type' | 'reference' | 'assetUrl' | 'country' | 'expiresAt' | 'assetFileId' | 'assetMime' | 'assetFileVersion'>
    )>>, identityProof?: Maybe<Array<(
      { __typename?: 'IdentityProof' }
      & Pick<IdentityProof, 'id' | 'type' | 'reference' | 'assetUrl' | 'country' | 'expiresAt' | 'assetFileId' | 'assetMime' | 'assetFileVersion'>
    )>>, bankDetails?: Maybe<(
      { __typename?: 'BankDetails' }
      & Pick<BankDetails, 'name' | 'accountNumber' | 'sortCode'>
      & { branchAddress?: Maybe<(
        { __typename?: 'Address' }
        & AddressFieldsFragment
      )> }
    )>, issues: Array<(
      { __typename?: 'Issue' }
      & Pick<Issue, 'id' | 'type' | 'isLinked' | 'linkCode' | 'linkRequested'>
      & { opponentIssue?: Maybe<(
        { __typename?: 'Issue' }
        & Pick<Issue, 'id'>
        & { legalTeam: Array<(
          { __typename?: 'LegalTeamMember' }
          & Pick<LegalTeamMember, 'id' | 'jobTitle' | 'isLead'>
          & { firm: (
            { __typename?: 'Firm' }
            & Pick<Firm, 'id' | 'name'>
          ), professional: (
            { __typename?: 'FirmBarrister' }
            & Pick<FirmBarrister, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
          ) | (
            { __typename?: 'FirmParalegal' }
            & Pick<FirmParalegal, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
          ) | (
            { __typename?: 'FirmSolicitor' }
            & Pick<FirmSolicitor, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
          ) | (
            { __typename?: 'FirmSupport' }
            & Pick<FirmSupport, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
          ) }
        )> }
      )>, opponent?: Maybe<(
        { __typename?: 'Opponent' }
        & Pick<Opponent, 'preferredName' | 'firstName' | 'lastName' | 'emailAddress' | 'nationalInsuranceNumber' | 'clientReferenceNumber' | 'isVerified'>
        & { solicitor: (
          { __typename?: 'OpponentSolicitor' }
          & Pick<OpponentSolicitor, 'firstName' | 'lastName' | 'emailAddress' | 'firmName'>
        ) }
      )> }
    )> }
  )> }
);

export type NewClientAccountSubscriptionVariables = Exact<{
  firmId: Scalars['ID'];
}>;


export type NewClientAccountSubscription = (
  { __typename?: 'Subscription' }
  & { newClientAccount?: Maybe<(
    { __typename?: 'ClientAccount' }
    & Pick<ClientAccount, 'id' | 'status' | 'referenceNumber'>
    & { client: (
      { __typename?: 'FirmClient' }
      & Pick<FirmClient, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl' | 'emailAddress' | 'mobilePhoneNumber' | 'nationalInsuranceNumber' | 'status' | 'referenceNumber'>
    ), leadProfessional: (
      { __typename?: 'FirmBarrister' }
      & Pick<FirmBarrister, 'id' | 'preferredName' | 'firstName' | 'lastName'>
    ) | (
      { __typename?: 'FirmParalegal' }
      & Pick<FirmParalegal, 'id' | 'preferredName' | 'firstName' | 'lastName'>
    ) | (
      { __typename?: 'FirmSolicitor' }
      & Pick<FirmSolicitor, 'id' | 'preferredName' | 'firstName' | 'lastName'>
    ) | (
      { __typename?: 'FirmSupport' }
      & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'lastName'>
    ), firm: (
      { __typename?: 'Firm' }
      & Pick<Firm, 'id' | 'name'>
    ) }
  )> }
);

export type ClientAccountUpdateSubscriptionVariables = Exact<{
  firmId: Scalars['ID'];
}>;


export type ClientAccountUpdateSubscription = (
  { __typename?: 'Subscription' }
  & { clientAccountUpdate?: Maybe<(
    { __typename?: 'ClientAccount' }
    & Pick<ClientAccount, 'id' | 'status' | 'isHighlighted' | 'referenceNumber'>
    & { client: (
      { __typename?: 'FirmClient' }
      & Pick<FirmClient, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl' | 'emailAddress' | 'mobilePhoneNumber' | 'nationalInsuranceNumber' | 'isVerified' | 'profileIsComplete' | 'status' | 'referenceNumber'>
      & { issues: Array<(
        { __typename?: 'Issue' }
        & Pick<Issue, 'id' | 'type' | 'isLinked' | 'linkCode' | 'linkRequested'>
        & { opponentIssue?: Maybe<(
          { __typename?: 'Issue' }
          & Pick<Issue, 'id'>
          & { legalTeam: Array<(
            { __typename?: 'LegalTeamMember' }
            & Pick<LegalTeamMember, 'id' | 'jobTitle' | 'isLead'>
            & { firm: (
              { __typename?: 'Firm' }
              & Pick<Firm, 'id' | 'name'>
            ), professional: (
              { __typename?: 'FirmBarrister' }
              & Pick<FirmBarrister, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
            ) | (
              { __typename?: 'FirmParalegal' }
              & Pick<FirmParalegal, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
            ) | (
              { __typename?: 'FirmSolicitor' }
              & Pick<FirmSolicitor, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
            ) | (
              { __typename?: 'FirmSupport' }
              & Pick<FirmSupport, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
            ) }
          )> }
        )>, opponent?: Maybe<(
          { __typename?: 'Opponent' }
          & Pick<Opponent, 'preferredName' | 'firstName' | 'lastName' | 'emailAddress' | 'nationalInsuranceNumber' | 'clientReferenceNumber' | 'isVerified'>
          & { solicitor: (
            { __typename?: 'OpponentSolicitor' }
            & Pick<OpponentSolicitor, 'firstName' | 'lastName' | 'emailAddress' | 'firmName'>
          ) }
        )> }
      )> }
    ), leadProfessional: (
      { __typename?: 'FirmBarrister' }
      & Pick<FirmBarrister, 'id' | 'preferredName' | 'firstName' | 'lastName'>
    ) | (
      { __typename?: 'FirmParalegal' }
      & Pick<FirmParalegal, 'id' | 'preferredName' | 'firstName' | 'lastName'>
    ) | (
      { __typename?: 'FirmSolicitor' }
      & Pick<FirmSolicitor, 'id' | 'preferredName' | 'firstName' | 'lastName'>
    ) | (
      { __typename?: 'FirmSupport' }
      & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'lastName'>
    ), firm: (
      { __typename?: 'Firm' }
      & Pick<Firm, 'id' | 'name'>
    ) }
  )> }
);

export type ClientAccountHighlightUpdateSubscriptionVariables = Exact<{
  firmId: Scalars['ID'];
}>;


export type ClientAccountHighlightUpdateSubscription = (
  { __typename?: 'Subscription' }
  & { clientAccountUpdate?: Maybe<(
    { __typename?: 'ClientAccount' }
    & Pick<ClientAccount, 'id' | 'status' | 'referenceNumber'>
    & { client: (
      { __typename?: 'FirmClient' }
      & Pick<FirmClient, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
    ), highlights: (
      { __typename?: 'HighlightCountsPayload' }
      & Pick<HighlightCountsPayload, 'conversationCount' | 'messageCount' | 'noteCount' | 'firmClientCount' | 'clientAssignmentCount' | 'matterCount' | 'keyDateCount' | 'taskCount' | 'clientCount' | 'questionCount' | 'answerCount' | 'firmMemberCount' | 'contactCount'>
    ) }
  )> }
);

export type ListClientAssignmentsForProfessionalUpdateSubscriptionVariables = Exact<{
  professionalId: Scalars['ID'];
}>;


export type ListClientAssignmentsForProfessionalUpdateSubscription = (
  { __typename?: 'Subscription' }
  & { clientAssignmentUpdate?: Maybe<(
    { __typename?: 'ClientAssignment' }
    & Pick<ClientAssignment, 'id'>
    & { clientAccount: (
      { __typename?: 'ClientAccount' }
      & Pick<ClientAccount, 'id' | 'status' | 'isHighlighted' | 'referenceNumber'>
      & { client: (
        { __typename?: 'FirmClient' }
        & Pick<FirmClient, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl' | 'emailAddress' | 'mobilePhoneNumber' | 'nationalInsuranceNumber' | 'isVerified' | 'profileIsComplete' | 'status' | 'referenceNumber'>
        & { issues: Array<(
          { __typename?: 'Issue' }
          & Pick<Issue, 'id' | 'type' | 'isLinked' | 'linkCode' | 'linkRequested'>
          & { opponentIssue?: Maybe<(
            { __typename?: 'Issue' }
            & Pick<Issue, 'id'>
            & { legalTeam: Array<(
              { __typename?: 'LegalTeamMember' }
              & Pick<LegalTeamMember, 'id' | 'jobTitle' | 'isLead'>
              & { firm: (
                { __typename?: 'Firm' }
                & Pick<Firm, 'id' | 'name'>
              ), professional: (
                { __typename?: 'FirmBarrister' }
                & Pick<FirmBarrister, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
              ) | (
                { __typename?: 'FirmParalegal' }
                & Pick<FirmParalegal, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
              ) | (
                { __typename?: 'FirmSolicitor' }
                & Pick<FirmSolicitor, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
              ) | (
                { __typename?: 'FirmSupport' }
                & Pick<FirmSupport, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
              ) }
            )> }
          )>, opponent?: Maybe<(
            { __typename?: 'Opponent' }
            & Pick<Opponent, 'preferredName' | 'firstName' | 'lastName' | 'emailAddress' | 'nationalInsuranceNumber' | 'clientReferenceNumber' | 'isVerified'>
            & { solicitor: (
              { __typename?: 'OpponentSolicitor' }
              & Pick<OpponentSolicitor, 'firstName' | 'lastName' | 'emailAddress' | 'firmName'>
            ) }
          )> }
        )> }
      ), leadProfessional: (
        { __typename?: 'FirmBarrister' }
        & Pick<FirmBarrister, 'id' | 'preferredName' | 'firstName' | 'lastName'>
      ) | (
        { __typename?: 'FirmParalegal' }
        & Pick<FirmParalegal, 'id' | 'preferredName' | 'firstName' | 'lastName'>
      ) | (
        { __typename?: 'FirmSolicitor' }
        & Pick<FirmSolicitor, 'id' | 'preferredName' | 'firstName' | 'lastName'>
      ) | (
        { __typename?: 'FirmSupport' }
        & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'lastName'>
      ), firm: (
        { __typename?: 'Firm' }
        & Pick<Firm, 'id' | 'name'>
      ) }
    ) }
  )> }
);

export type OnConversationUpdateSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnConversationUpdateSubscription = (
  { __typename?: 'Subscription' }
  & { conversationUpdate?: Maybe<(
    { __typename?: 'Conversation' }
    & Pick<Conversation, 'id' | 'pausedUntil' | 'unreadMessageCount' | 'lastMessageSentAt'>
  )> }
);

export type FirmUpdateSubscriptionVariables = Exact<{
  firmId: Scalars['ID'];
}>;


export type FirmUpdateSubscription = (
  { __typename?: 'Subscription' }
  & { firmUpdate?: Maybe<(
    { __typename?: 'Firm' }
    & Pick<Firm, 'id' | 'name' | 'logoUrl' | 'sraNumber' | 'seatCount' | 'vacantSeatCount' | 'occupiedSeatCount'>
    & { members: Array<(
      { __typename?: 'FirmMember' }
      & Pick<FirmMember, 'id' | 'role' | 'employeeNumber' | 'jobTitle' | 'office' | 'isActive' | 'isHighlighted' | 'activeSince' | 'status'>
      & { professional: (
        { __typename?: 'FirmBarrister' }
        & Pick<FirmBarrister, 'sraNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'status' | 'referenceNumber'>
      ) | (
        { __typename?: 'FirmParalegal' }
        & Pick<FirmParalegal, 'paralegalNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'status' | 'referenceNumber'>
      ) | (
        { __typename?: 'FirmSolicitor' }
        & Pick<FirmSolicitor, 'sraNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'status' | 'referenceNumber'>
      ) | (
        { __typename?: 'FirmSupport' }
        & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'status' | 'referenceNumber'>
      ), firm: (
        { __typename?: 'Firm' }
        & Pick<Firm, 'id' | 'name' | 'logoUrl'>
      ) }
    )> }
  )> }
);

export type FirmMemberUpdateSubscriptionVariables = Exact<{
  professionalId?: Maybe<Scalars['ID']>;
}>;


export type FirmMemberUpdateSubscription = (
  { __typename?: 'Subscription' }
  & { firmMemberUpdate?: Maybe<(
    { __typename?: 'FirmMember' }
    & Pick<FirmMember, 'id' | 'employeeNumber' | 'jobTitle' | 'office' | 'isActive' | 'isHighlighted' | 'activeSince' | 'role' | 'status'>
    & { professional: (
      { __typename?: 'FirmBarrister' }
      & Pick<FirmBarrister, 'sraNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'status' | 'referenceNumber'>
    ) | (
      { __typename?: 'FirmParalegal' }
      & Pick<FirmParalegal, 'paralegalNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'status' | 'referenceNumber'>
    ) | (
      { __typename?: 'FirmSolicitor' }
      & Pick<FirmSolicitor, 'sraNumber' | 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'status' | 'referenceNumber'>
    ) | (
      { __typename?: 'FirmSupport' }
      & Pick<FirmSupport, 'id' | 'preferredName' | 'firstName' | 'middleNames' | 'lastName' | 'workPhoneNumber' | 'emailAddress' | 'pictureUrl' | 'type' | 'status' | 'referenceNumber'>
    ), firm: (
      { __typename?: 'Firm' }
      & Pick<Firm, 'id' | 'name' | 'sraNumber' | 'logoUrl'>
    ) }
  )> }
);

export type HighlightChangeSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type HighlightChangeSubscription = (
  { __typename?: 'Subscription' }
  & { highlightChange: (
    { __typename?: 'HighlightCountsPayload' }
    & Pick<HighlightCountsPayload, 'contactCount' | 'conversationCount' | 'messageCount' | 'firmMemberCount' | 'noteCount' | 'firmClientCount' | 'clientAssignmentCount' | 'clientCount' | 'matterCount' | 'keyDateCount' | 'taskCount' | 'taskSectionCount' | 'questionCount' | 'answerCount'>
  ) }
);

export type IssueUpdateSubscriptionVariables = Exact<{
  issueId: Scalars['ID'];
}>;


export type IssueUpdateSubscription = (
  { __typename?: 'Subscription' }
  & { issueUpdate?: Maybe<(
    { __typename?: 'Issue' }
    & Pick<Issue, 'id' | 'type' | 'isLinked' | 'linkCode' | 'linkRequested'>
    & { opponentIssue?: Maybe<(
      { __typename?: 'Issue' }
      & Pick<Issue, 'id'>
      & { legalTeam: Array<(
        { __typename?: 'LegalTeamMember' }
        & Pick<LegalTeamMember, 'id' | 'jobTitle' | 'isLead'>
        & { firm: (
          { __typename?: 'Firm' }
          & Pick<Firm, 'id' | 'name'>
        ), professional: (
          { __typename?: 'FirmBarrister' }
          & Pick<FirmBarrister, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
        ) | (
          { __typename?: 'FirmParalegal' }
          & Pick<FirmParalegal, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
        ) | (
          { __typename?: 'FirmSolicitor' }
          & Pick<FirmSolicitor, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
        ) | (
          { __typename?: 'FirmSupport' }
          & Pick<FirmSupport, 'id' | 'emailAddress' | 'preferredName' | 'lastName' | 'type'>
        ) }
      )> }
    )>, opponent?: Maybe<(
      { __typename?: 'Opponent' }
      & Pick<Opponent, 'preferredName' | 'firstName' | 'lastName' | 'emailAddress' | 'nationalInsuranceNumber' | 'clientReferenceNumber' | 'isVerified'>
      & { solicitor: (
        { __typename?: 'OpponentSolicitor' }
        & Pick<OpponentSolicitor, 'firstName' | 'lastName' | 'emailAddress' | 'firmName'>
      ) }
    )>, client: (
      { __typename?: 'FirmClient' }
      & { accounts: Array<(
        { __typename?: 'ClientAccount' }
        & Pick<ClientAccount, 'id'>
      )> }
    ) }
  )> }
);

export type NewMessagesSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type NewMessagesSubscription = (
  { __typename?: 'Subscription' }
  & { newMessage?: Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'id' | 'conversationId' | 'body' | 'sentAt' | 'deliveredAt' | 'readAt'>
    & { sender: (
      { __typename?: 'ConversationParticipant' }
      & Pick<ConversationParticipant, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl' | 'role' | 'channel' | 'title'>
      & { group?: Maybe<(
        { __typename?: 'ConversationGroup' }
        & Pick<ConversationGroup, 'id' | 'name' | 'pictureUrl'>
      )> }
    ), repliedTo?: Maybe<(
      { __typename?: 'Message' }
      & Pick<Message, 'id' | 'body' | 'sentAt'>
      & { sender: (
        { __typename?: 'ConversationParticipant' }
        & Pick<ConversationParticipant, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl' | 'role' | 'channel' | 'title'>
        & { group?: Maybe<(
          { __typename?: 'ConversationGroup' }
          & Pick<ConversationGroup, 'id' | 'name' | 'pictureUrl'>
        )> }
      ) }
    )>, copies?: Maybe<Array<Maybe<(
      { __typename?: 'MessageCopy' }
      & Pick<MessageCopy, 'deliveredAt' | 'readAt'>
      & { recipient: (
        { __typename?: 'ConversationObserver' }
        & Pick<ConversationObserver, 'id'>
        & { group?: Maybe<(
          { __typename?: 'ConversationGroup' }
          & Pick<ConversationGroup, 'id'>
        )> }
      ) | (
        { __typename?: 'ConversationParticipant' }
        & Pick<ConversationParticipant, 'id'>
        & { group?: Maybe<(
          { __typename?: 'ConversationGroup' }
          & Pick<ConversationGroup, 'id'>
        )> }
      ) }
    )>>>, attachments: Array<(
      { __typename?: 'AttachedArchive' }
      & Pick<AttachedArchive, 'id' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'AttachedAudio' }
      & Pick<AttachedAudio, 'id' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'AttachedDocument' }
      & Pick<AttachedDocument, 'id' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'AttachedImage' }
      & Pick<AttachedImage, 'height' | 'width' | 'id' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'AttachedVideo' }
      & Pick<AttachedVideo, 'id' | 'name' | 'type' | 'mime' | 'size'>
    )>, topics: Array<(
      { __typename?: 'FirmTopic' }
      & Pick<FirmTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'GeneralTopic' }
      & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'PrivateTopic' }
      & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
    )> }
  )> }
);

export type MessageUpdateSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type MessageUpdateSubscription = (
  { __typename?: 'Subscription' }
  & { messageUpdate?: Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'id' | 'conversationId' | 'body' | 'sentAt' | 'deliveredAt' | 'readAt'>
    & { sender: (
      { __typename?: 'ConversationParticipant' }
      & Pick<ConversationParticipant, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl' | 'role' | 'channel' | 'title'>
      & { group?: Maybe<(
        { __typename?: 'ConversationGroup' }
        & Pick<ConversationGroup, 'id' | 'name' | 'pictureUrl'>
      )> }
    ), repliedTo?: Maybe<(
      { __typename?: 'Message' }
      & Pick<Message, 'id' | 'body' | 'sentAt'>
      & { sender: (
        { __typename?: 'ConversationParticipant' }
        & Pick<ConversationParticipant, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl' | 'role' | 'channel' | 'title'>
        & { group?: Maybe<(
          { __typename?: 'ConversationGroup' }
          & Pick<ConversationGroup, 'id' | 'name' | 'pictureUrl'>
        )> }
      ) }
    )>, copies?: Maybe<Array<Maybe<(
      { __typename?: 'MessageCopy' }
      & Pick<MessageCopy, 'deliveredAt' | 'readAt'>
      & { recipient: (
        { __typename?: 'ConversationObserver' }
        & Pick<ConversationObserver, 'id'>
        & { group?: Maybe<(
          { __typename?: 'ConversationGroup' }
          & Pick<ConversationGroup, 'id'>
        )> }
      ) | (
        { __typename?: 'ConversationParticipant' }
        & Pick<ConversationParticipant, 'id'>
        & { group?: Maybe<(
          { __typename?: 'ConversationGroup' }
          & Pick<ConversationGroup, 'id'>
        )> }
      ) }
    )>>>, attachments: Array<(
      { __typename?: 'AttachedArchive' }
      & Pick<AttachedArchive, 'id' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'AttachedAudio' }
      & Pick<AttachedAudio, 'id' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'AttachedDocument' }
      & Pick<AttachedDocument, 'id' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'AttachedImage' }
      & Pick<AttachedImage, 'height' | 'width' | 'id' | 'name' | 'type' | 'mime' | 'size'>
    ) | (
      { __typename?: 'AttachedVideo' }
      & Pick<AttachedVideo, 'id' | 'name' | 'type' | 'mime' | 'size'>
    )>, topics: Array<(
      { __typename?: 'FirmTopic' }
      & Pick<FirmTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'GeneralTopic' }
      & Pick<GeneralTopic, 'id' | 'name' | 'colour'>
    ) | (
      { __typename?: 'PrivateTopic' }
      & Pick<PrivateTopic, 'id' | 'name' | 'colour'>
    )> }
  )> }
);

export type NewNotificationsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type NewNotificationsSubscription = (
  { __typename?: 'Subscription' }
  & { newNotification?: Maybe<(
    { __typename?: 'Notification' }
    & Pick<Notification, 'id' | 'context' | 'type' | 'title' | 'body' | 'pictureUrl' | 'occurredOn' | 'readAt' | 'isRead'>
    & { path: Array<(
      { __typename?: 'PathSegment' }
      & Pick<PathSegment, 'id' | 'type' | 'name'>
    )> }
  )> }
);

export type QuestionUpdatesSubscriptionVariables = Exact<{
  questionId: Scalars['ID'];
}>;


export type QuestionUpdatesSubscription = (
  { __typename?: 'Subscription' }
  & { questionUpdate?: Maybe<(
    { __typename?: 'Question' }
    & Pick<Question, 'id' | 'text' | 'description' | 'proportionComplete' | 'isReleased' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
    & { parts: Array<(
      { __typename?: 'AddressPart' }
      & Pick<AddressPart, 'useName' | 'id' | 'label' | 'instructions' | 'isRequired' | 'isComplete' | 'isHighlighted' | 'ordering' | 'officialAdvice' | 'additionalAdvice'>
      & { answerDraft?: Maybe<(
        { __typename?: 'AddressAnswerDraft' }
        & Pick<AddressAnswerDraft, 'id' | 'notApplicable'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'BooleanAnswerDraft' }
        & Pick<BooleanAnswerDraft, 'id' | 'notApplicable'>
        & { boolean: BooleanAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ChoiceAnswerDraft' }
        & Pick<ChoiceAnswerDraft, 'id' | 'notApplicable'>
        & { choice: ChoiceAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'DateAnswerDraft' }
        & Pick<DateAnswerDraft, 'id' | 'notApplicable'>
        & { date: DateAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'EmailAnswerDraft' }
        & Pick<EmailAnswerDraft, 'id' | 'notApplicable'>
        & { email: EmailAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'FileAnswerDraft' }
        & Pick<FileAnswerDraft, 'id' | 'notApplicable'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ListAnswerDraft' }
        & Pick<ListAnswerDraft, 'id' | 'notApplicable'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'MoneyAnswerDraft' }
        & Pick<MoneyAnswerDraft, 'id' | 'notApplicable'>
        & { money: MoneyAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'NumberAnswerDraft' }
        & Pick<NumberAnswerDraft, 'id' | 'notApplicable'>
        & { number: NumberAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PercentAnswerDraft' }
        & Pick<PercentAnswerDraft, 'id' | 'notApplicable'>
        & { percent: PercentAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PhoneAnswerDraft' }
        & Pick<PhoneAnswerDraft, 'id' | 'notApplicable'>
        & { phone: PhoneAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'StringAnswerDraft' }
        & Pick<StringAnswerDraft, 'id' | 'notApplicable'>
        & { string: StringAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'TextAnswerDraft' }
        & Pick<TextAnswerDraft, 'id' | 'notApplicable'>
        & { text: TextAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      )>, answer?: Maybe<(
        { __typename?: 'AddressAnswer' }
        & Pick<AddressAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'BooleanAnswer' }
        & Pick<BooleanAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { boolean: BooleanAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ChoiceAnswer' }
        & Pick<ChoiceAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { choice: ChoiceAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'DateAnswer' }
        & Pick<DateAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { date: DateAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'EmailAnswer' }
        & Pick<EmailAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { email: EmailAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'FileAnswer' }
        & Pick<FileAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ListAnswer' }
        & Pick<ListAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'MoneyAnswer' }
        & Pick<MoneyAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { money: MoneyAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'NumberAnswer' }
        & Pick<NumberAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { number: NumberAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PercentAnswer' }
        & Pick<PercentAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { percent: PercentAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PhoneAnswer' }
        & Pick<PhoneAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { phone: PhoneAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'StringAnswer' }
        & Pick<StringAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { string: StringAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'TextAnswer' }
        & Pick<TextAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { text: TextAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      )> }
    ) | (
      { __typename?: 'BooleanPart' }
      & Pick<BooleanPart, 'positiveLabel' | 'negativeLabel' | 'id' | 'label' | 'instructions' | 'isRequired' | 'isComplete' | 'isHighlighted' | 'ordering' | 'officialAdvice' | 'additionalAdvice'>
      & { answerDraft?: Maybe<(
        { __typename?: 'AddressAnswerDraft' }
        & Pick<AddressAnswerDraft, 'id' | 'notApplicable'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'BooleanAnswerDraft' }
        & Pick<BooleanAnswerDraft, 'id' | 'notApplicable'>
        & { boolean: BooleanAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ChoiceAnswerDraft' }
        & Pick<ChoiceAnswerDraft, 'id' | 'notApplicable'>
        & { choice: ChoiceAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'DateAnswerDraft' }
        & Pick<DateAnswerDraft, 'id' | 'notApplicable'>
        & { date: DateAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'EmailAnswerDraft' }
        & Pick<EmailAnswerDraft, 'id' | 'notApplicable'>
        & { email: EmailAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'FileAnswerDraft' }
        & Pick<FileAnswerDraft, 'id' | 'notApplicable'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ListAnswerDraft' }
        & Pick<ListAnswerDraft, 'id' | 'notApplicable'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'MoneyAnswerDraft' }
        & Pick<MoneyAnswerDraft, 'id' | 'notApplicable'>
        & { money: MoneyAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'NumberAnswerDraft' }
        & Pick<NumberAnswerDraft, 'id' | 'notApplicable'>
        & { number: NumberAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PercentAnswerDraft' }
        & Pick<PercentAnswerDraft, 'id' | 'notApplicable'>
        & { percent: PercentAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PhoneAnswerDraft' }
        & Pick<PhoneAnswerDraft, 'id' | 'notApplicable'>
        & { phone: PhoneAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'StringAnswerDraft' }
        & Pick<StringAnswerDraft, 'id' | 'notApplicable'>
        & { string: StringAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'TextAnswerDraft' }
        & Pick<TextAnswerDraft, 'id' | 'notApplicable'>
        & { text: TextAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      )>, answer?: Maybe<(
        { __typename?: 'AddressAnswer' }
        & Pick<AddressAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'BooleanAnswer' }
        & Pick<BooleanAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { boolean: BooleanAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ChoiceAnswer' }
        & Pick<ChoiceAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { choice: ChoiceAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'DateAnswer' }
        & Pick<DateAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { date: DateAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'EmailAnswer' }
        & Pick<EmailAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { email: EmailAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'FileAnswer' }
        & Pick<FileAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ListAnswer' }
        & Pick<ListAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'MoneyAnswer' }
        & Pick<MoneyAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { money: MoneyAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'NumberAnswer' }
        & Pick<NumberAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { number: NumberAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PercentAnswer' }
        & Pick<PercentAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { percent: PercentAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PhoneAnswer' }
        & Pick<PhoneAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { phone: PhoneAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'StringAnswer' }
        & Pick<StringAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { string: StringAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'TextAnswer' }
        & Pick<TextAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { text: TextAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      )> }
    ) | (
      { __typename?: 'ChoicePart' }
      & Pick<ChoicePart, 'minimumChoices' | 'maximumChoices' | 'id' | 'label' | 'instructions' | 'isRequired' | 'isComplete' | 'isHighlighted' | 'ordering' | 'officialAdvice' | 'additionalAdvice'>
      & { options: Array<(
        { __typename?: 'Option' }
        & Pick<Option, 'value' | 'description'>
      )>, answerDraft?: Maybe<(
        { __typename?: 'AddressAnswerDraft' }
        & Pick<AddressAnswerDraft, 'id' | 'notApplicable'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'BooleanAnswerDraft' }
        & Pick<BooleanAnswerDraft, 'id' | 'notApplicable'>
        & { boolean: BooleanAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ChoiceAnswerDraft' }
        & Pick<ChoiceAnswerDraft, 'id' | 'notApplicable'>
        & { choice: ChoiceAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'DateAnswerDraft' }
        & Pick<DateAnswerDraft, 'id' | 'notApplicable'>
        & { date: DateAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'EmailAnswerDraft' }
        & Pick<EmailAnswerDraft, 'id' | 'notApplicable'>
        & { email: EmailAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'FileAnswerDraft' }
        & Pick<FileAnswerDraft, 'id' | 'notApplicable'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ListAnswerDraft' }
        & Pick<ListAnswerDraft, 'id' | 'notApplicable'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'MoneyAnswerDraft' }
        & Pick<MoneyAnswerDraft, 'id' | 'notApplicable'>
        & { money: MoneyAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'NumberAnswerDraft' }
        & Pick<NumberAnswerDraft, 'id' | 'notApplicable'>
        & { number: NumberAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PercentAnswerDraft' }
        & Pick<PercentAnswerDraft, 'id' | 'notApplicable'>
        & { percent: PercentAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PhoneAnswerDraft' }
        & Pick<PhoneAnswerDraft, 'id' | 'notApplicable'>
        & { phone: PhoneAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'StringAnswerDraft' }
        & Pick<StringAnswerDraft, 'id' | 'notApplicable'>
        & { string: StringAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'TextAnswerDraft' }
        & Pick<TextAnswerDraft, 'id' | 'notApplicable'>
        & { text: TextAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      )>, answer?: Maybe<(
        { __typename?: 'AddressAnswer' }
        & Pick<AddressAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'BooleanAnswer' }
        & Pick<BooleanAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { boolean: BooleanAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ChoiceAnswer' }
        & Pick<ChoiceAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { choice: ChoiceAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'DateAnswer' }
        & Pick<DateAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { date: DateAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'EmailAnswer' }
        & Pick<EmailAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { email: EmailAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'FileAnswer' }
        & Pick<FileAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ListAnswer' }
        & Pick<ListAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'MoneyAnswer' }
        & Pick<MoneyAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { money: MoneyAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'NumberAnswer' }
        & Pick<NumberAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { number: NumberAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PercentAnswer' }
        & Pick<PercentAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { percent: PercentAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PhoneAnswer' }
        & Pick<PhoneAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { phone: PhoneAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'StringAnswer' }
        & Pick<StringAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { string: StringAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'TextAnswer' }
        & Pick<TextAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { text: TextAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      )> }
    ) | (
      { __typename?: 'DatePart' }
      & Pick<DatePart, 'earliestDate' | 'latestDate' | 'useTime' | 'id' | 'label' | 'instructions' | 'isRequired' | 'isComplete' | 'isHighlighted' | 'ordering' | 'officialAdvice' | 'additionalAdvice'>
      & { answerDraft?: Maybe<(
        { __typename?: 'AddressAnswerDraft' }
        & Pick<AddressAnswerDraft, 'id' | 'notApplicable'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'BooleanAnswerDraft' }
        & Pick<BooleanAnswerDraft, 'id' | 'notApplicable'>
        & { boolean: BooleanAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ChoiceAnswerDraft' }
        & Pick<ChoiceAnswerDraft, 'id' | 'notApplicable'>
        & { choice: ChoiceAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'DateAnswerDraft' }
        & Pick<DateAnswerDraft, 'id' | 'notApplicable'>
        & { date: DateAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'EmailAnswerDraft' }
        & Pick<EmailAnswerDraft, 'id' | 'notApplicable'>
        & { email: EmailAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'FileAnswerDraft' }
        & Pick<FileAnswerDraft, 'id' | 'notApplicable'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ListAnswerDraft' }
        & Pick<ListAnswerDraft, 'id' | 'notApplicable'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'MoneyAnswerDraft' }
        & Pick<MoneyAnswerDraft, 'id' | 'notApplicable'>
        & { money: MoneyAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'NumberAnswerDraft' }
        & Pick<NumberAnswerDraft, 'id' | 'notApplicable'>
        & { number: NumberAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PercentAnswerDraft' }
        & Pick<PercentAnswerDraft, 'id' | 'notApplicable'>
        & { percent: PercentAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PhoneAnswerDraft' }
        & Pick<PhoneAnswerDraft, 'id' | 'notApplicable'>
        & { phone: PhoneAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'StringAnswerDraft' }
        & Pick<StringAnswerDraft, 'id' | 'notApplicable'>
        & { string: StringAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'TextAnswerDraft' }
        & Pick<TextAnswerDraft, 'id' | 'notApplicable'>
        & { text: TextAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      )>, answer?: Maybe<(
        { __typename?: 'AddressAnswer' }
        & Pick<AddressAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'BooleanAnswer' }
        & Pick<BooleanAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { boolean: BooleanAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ChoiceAnswer' }
        & Pick<ChoiceAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { choice: ChoiceAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'DateAnswer' }
        & Pick<DateAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { date: DateAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'EmailAnswer' }
        & Pick<EmailAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { email: EmailAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'FileAnswer' }
        & Pick<FileAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ListAnswer' }
        & Pick<ListAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'MoneyAnswer' }
        & Pick<MoneyAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { money: MoneyAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'NumberAnswer' }
        & Pick<NumberAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { number: NumberAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PercentAnswer' }
        & Pick<PercentAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { percent: PercentAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PhoneAnswer' }
        & Pick<PhoneAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { phone: PhoneAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'StringAnswer' }
        & Pick<StringAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { string: StringAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'TextAnswer' }
        & Pick<TextAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { text: TextAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      )> }
    ) | (
      { __typename?: 'EmailPart' }
      & Pick<EmailPart, 'id' | 'label' | 'instructions' | 'isRequired' | 'isComplete' | 'isHighlighted' | 'ordering' | 'officialAdvice' | 'additionalAdvice'>
      & { answerDraft?: Maybe<(
        { __typename?: 'AddressAnswerDraft' }
        & Pick<AddressAnswerDraft, 'id' | 'notApplicable'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'BooleanAnswerDraft' }
        & Pick<BooleanAnswerDraft, 'id' | 'notApplicable'>
        & { boolean: BooleanAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ChoiceAnswerDraft' }
        & Pick<ChoiceAnswerDraft, 'id' | 'notApplicable'>
        & { choice: ChoiceAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'DateAnswerDraft' }
        & Pick<DateAnswerDraft, 'id' | 'notApplicable'>
        & { date: DateAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'EmailAnswerDraft' }
        & Pick<EmailAnswerDraft, 'id' | 'notApplicable'>
        & { email: EmailAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'FileAnswerDraft' }
        & Pick<FileAnswerDraft, 'id' | 'notApplicable'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ListAnswerDraft' }
        & Pick<ListAnswerDraft, 'id' | 'notApplicable'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'MoneyAnswerDraft' }
        & Pick<MoneyAnswerDraft, 'id' | 'notApplicable'>
        & { money: MoneyAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'NumberAnswerDraft' }
        & Pick<NumberAnswerDraft, 'id' | 'notApplicable'>
        & { number: NumberAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PercentAnswerDraft' }
        & Pick<PercentAnswerDraft, 'id' | 'notApplicable'>
        & { percent: PercentAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PhoneAnswerDraft' }
        & Pick<PhoneAnswerDraft, 'id' | 'notApplicable'>
        & { phone: PhoneAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'StringAnswerDraft' }
        & Pick<StringAnswerDraft, 'id' | 'notApplicable'>
        & { string: StringAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'TextAnswerDraft' }
        & Pick<TextAnswerDraft, 'id' | 'notApplicable'>
        & { text: TextAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      )>, answer?: Maybe<(
        { __typename?: 'AddressAnswer' }
        & Pick<AddressAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'BooleanAnswer' }
        & Pick<BooleanAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { boolean: BooleanAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ChoiceAnswer' }
        & Pick<ChoiceAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { choice: ChoiceAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'DateAnswer' }
        & Pick<DateAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { date: DateAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'EmailAnswer' }
        & Pick<EmailAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { email: EmailAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'FileAnswer' }
        & Pick<FileAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ListAnswer' }
        & Pick<ListAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'MoneyAnswer' }
        & Pick<MoneyAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { money: MoneyAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'NumberAnswer' }
        & Pick<NumberAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { number: NumberAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PercentAnswer' }
        & Pick<PercentAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { percent: PercentAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PhoneAnswer' }
        & Pick<PhoneAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { phone: PhoneAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'StringAnswer' }
        & Pick<StringAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { string: StringAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'TextAnswer' }
        & Pick<TextAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { text: TextAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      )> }
    ) | (
      { __typename?: 'FilePart' }
      & Pick<FilePart, 'maximumFiles' | 'id' | 'label' | 'instructions' | 'isRequired' | 'isComplete' | 'isHighlighted' | 'ordering' | 'officialAdvice' | 'additionalAdvice'>
      & { answerDraft?: Maybe<(
        { __typename?: 'AddressAnswerDraft' }
        & Pick<AddressAnswerDraft, 'id' | 'notApplicable'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'BooleanAnswerDraft' }
        & Pick<BooleanAnswerDraft, 'id' | 'notApplicable'>
        & { boolean: BooleanAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ChoiceAnswerDraft' }
        & Pick<ChoiceAnswerDraft, 'id' | 'notApplicable'>
        & { choice: ChoiceAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'DateAnswerDraft' }
        & Pick<DateAnswerDraft, 'id' | 'notApplicable'>
        & { date: DateAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'EmailAnswerDraft' }
        & Pick<EmailAnswerDraft, 'id' | 'notApplicable'>
        & { email: EmailAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'FileAnswerDraft' }
        & Pick<FileAnswerDraft, 'id' | 'notApplicable'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ListAnswerDraft' }
        & Pick<ListAnswerDraft, 'id' | 'notApplicable'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'MoneyAnswerDraft' }
        & Pick<MoneyAnswerDraft, 'id' | 'notApplicable'>
        & { money: MoneyAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'NumberAnswerDraft' }
        & Pick<NumberAnswerDraft, 'id' | 'notApplicable'>
        & { number: NumberAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PercentAnswerDraft' }
        & Pick<PercentAnswerDraft, 'id' | 'notApplicable'>
        & { percent: PercentAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PhoneAnswerDraft' }
        & Pick<PhoneAnswerDraft, 'id' | 'notApplicable'>
        & { phone: PhoneAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'StringAnswerDraft' }
        & Pick<StringAnswerDraft, 'id' | 'notApplicable'>
        & { string: StringAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'TextAnswerDraft' }
        & Pick<TextAnswerDraft, 'id' | 'notApplicable'>
        & { text: TextAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      )>, answer?: Maybe<(
        { __typename?: 'AddressAnswer' }
        & Pick<AddressAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'BooleanAnswer' }
        & Pick<BooleanAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { boolean: BooleanAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ChoiceAnswer' }
        & Pick<ChoiceAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { choice: ChoiceAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'DateAnswer' }
        & Pick<DateAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { date: DateAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'EmailAnswer' }
        & Pick<EmailAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { email: EmailAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'FileAnswer' }
        & Pick<FileAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ListAnswer' }
        & Pick<ListAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'MoneyAnswer' }
        & Pick<MoneyAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { money: MoneyAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'NumberAnswer' }
        & Pick<NumberAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { number: NumberAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PercentAnswer' }
        & Pick<PercentAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { percent: PercentAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PhoneAnswer' }
        & Pick<PhoneAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { phone: PhoneAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'StringAnswer' }
        & Pick<StringAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { string: StringAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'TextAnswer' }
        & Pick<TextAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { text: TextAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      )> }
    ) | (
      { __typename?: 'ListPart' }
      & Pick<ListPart, 'minimumItems' | 'maximumItems' | 'id' | 'label' | 'instructions' | 'isRequired' | 'isComplete' | 'isHighlighted' | 'ordering' | 'officialAdvice' | 'additionalAdvice'>
      & { attributes: Array<(
        { __typename?: 'AddressAttribute' }
        & Pick<AddressAttribute, 'useName' | 'id' | 'label' | 'instructions' | 'isRequired' | 'ordering'>
      ) | (
        { __typename?: 'DateAttribute' }
        & Pick<DateAttribute, 'earliestDate' | 'latestDate' | 'useTime' | 'id' | 'label' | 'instructions' | 'isRequired' | 'ordering'>
      ) | (
        { __typename?: 'EmailAttribute' }
        & Pick<EmailAttribute, 'id' | 'label' | 'instructions' | 'isRequired' | 'ordering'>
      ) | (
        { __typename?: 'FileAttribute' }
        & Pick<FileAttribute, 'maximumFiles' | 'id' | 'label' | 'instructions' | 'isRequired' | 'ordering'>
      ) | (
        { __typename?: 'MoneyAttribute' }
        & Pick<MoneyAttribute, 'minimumAmount' | 'maximumAmount' | 'currency' | 'id' | 'label' | 'instructions' | 'isRequired' | 'ordering'>
      ) | (
        { __typename?: 'NumberAttribute' }
        & Pick<NumberAttribute, 'minimumValue' | 'maximumValue' | 'id' | 'label' | 'instructions' | 'isRequired' | 'ordering'>
      ) | (
        { __typename?: 'PercentAttribute' }
        & Pick<PercentAttribute, 'minimumValue' | 'maximumValue' | 'id' | 'label' | 'instructions' | 'isRequired' | 'ordering'>
      ) | (
        { __typename?: 'PhoneAttribute' }
        & Pick<PhoneAttribute, 'id' | 'label' | 'instructions' | 'isRequired' | 'ordering'>
      ) | (
        { __typename?: 'StringAttribute' }
        & Pick<StringAttribute, 'maximumLength' | 'minimumLength' | 'id' | 'label' | 'instructions' | 'isRequired' | 'ordering'>
      ) | (
        { __typename?: 'TextAttribute' }
        & Pick<TextAttribute, 'maximumLength' | 'minimumLength' | 'id' | 'label' | 'instructions' | 'isRequired' | 'ordering'>
      )>, answerDraft?: Maybe<(
        { __typename?: 'AddressAnswerDraft' }
        & Pick<AddressAnswerDraft, 'id' | 'notApplicable'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'BooleanAnswerDraft' }
        & Pick<BooleanAnswerDraft, 'id' | 'notApplicable'>
        & { boolean: BooleanAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ChoiceAnswerDraft' }
        & Pick<ChoiceAnswerDraft, 'id' | 'notApplicable'>
        & { choice: ChoiceAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'DateAnswerDraft' }
        & Pick<DateAnswerDraft, 'id' | 'notApplicable'>
        & { date: DateAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'EmailAnswerDraft' }
        & Pick<EmailAnswerDraft, 'id' | 'notApplicable'>
        & { email: EmailAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'FileAnswerDraft' }
        & Pick<FileAnswerDraft, 'id' | 'notApplicable'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ListAnswerDraft' }
        & Pick<ListAnswerDraft, 'id' | 'notApplicable'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'MoneyAnswerDraft' }
        & Pick<MoneyAnswerDraft, 'id' | 'notApplicable'>
        & { money: MoneyAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'NumberAnswerDraft' }
        & Pick<NumberAnswerDraft, 'id' | 'notApplicable'>
        & { number: NumberAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PercentAnswerDraft' }
        & Pick<PercentAnswerDraft, 'id' | 'notApplicable'>
        & { percent: PercentAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PhoneAnswerDraft' }
        & Pick<PhoneAnswerDraft, 'id' | 'notApplicable'>
        & { phone: PhoneAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'StringAnswerDraft' }
        & Pick<StringAnswerDraft, 'id' | 'notApplicable'>
        & { string: StringAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'TextAnswerDraft' }
        & Pick<TextAnswerDraft, 'id' | 'notApplicable'>
        & { text: TextAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      )>, answer?: Maybe<(
        { __typename?: 'AddressAnswer' }
        & Pick<AddressAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'BooleanAnswer' }
        & Pick<BooleanAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { boolean: BooleanAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ChoiceAnswer' }
        & Pick<ChoiceAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { choice: ChoiceAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'DateAnswer' }
        & Pick<DateAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { date: DateAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'EmailAnswer' }
        & Pick<EmailAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { email: EmailAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'FileAnswer' }
        & Pick<FileAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ListAnswer' }
        & Pick<ListAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'MoneyAnswer' }
        & Pick<MoneyAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { money: MoneyAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'NumberAnswer' }
        & Pick<NumberAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { number: NumberAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PercentAnswer' }
        & Pick<PercentAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { percent: PercentAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PhoneAnswer' }
        & Pick<PhoneAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { phone: PhoneAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'StringAnswer' }
        & Pick<StringAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { string: StringAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'TextAnswer' }
        & Pick<TextAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { text: TextAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      )> }
    ) | (
      { __typename?: 'MoneyPart' }
      & Pick<MoneyPart, 'minimumAmount' | 'maximumAmount' | 'currency' | 'id' | 'label' | 'instructions' | 'isRequired' | 'isComplete' | 'isHighlighted' | 'ordering' | 'officialAdvice' | 'additionalAdvice'>
      & { answerDraft?: Maybe<(
        { __typename?: 'AddressAnswerDraft' }
        & Pick<AddressAnswerDraft, 'id' | 'notApplicable'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'BooleanAnswerDraft' }
        & Pick<BooleanAnswerDraft, 'id' | 'notApplicable'>
        & { boolean: BooleanAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ChoiceAnswerDraft' }
        & Pick<ChoiceAnswerDraft, 'id' | 'notApplicable'>
        & { choice: ChoiceAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'DateAnswerDraft' }
        & Pick<DateAnswerDraft, 'id' | 'notApplicable'>
        & { date: DateAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'EmailAnswerDraft' }
        & Pick<EmailAnswerDraft, 'id' | 'notApplicable'>
        & { email: EmailAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'FileAnswerDraft' }
        & Pick<FileAnswerDraft, 'id' | 'notApplicable'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ListAnswerDraft' }
        & Pick<ListAnswerDraft, 'id' | 'notApplicable'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'MoneyAnswerDraft' }
        & Pick<MoneyAnswerDraft, 'id' | 'notApplicable'>
        & { money: MoneyAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'NumberAnswerDraft' }
        & Pick<NumberAnswerDraft, 'id' | 'notApplicable'>
        & { number: NumberAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PercentAnswerDraft' }
        & Pick<PercentAnswerDraft, 'id' | 'notApplicable'>
        & { percent: PercentAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PhoneAnswerDraft' }
        & Pick<PhoneAnswerDraft, 'id' | 'notApplicable'>
        & { phone: PhoneAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'StringAnswerDraft' }
        & Pick<StringAnswerDraft, 'id' | 'notApplicable'>
        & { string: StringAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'TextAnswerDraft' }
        & Pick<TextAnswerDraft, 'id' | 'notApplicable'>
        & { text: TextAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      )>, answer?: Maybe<(
        { __typename?: 'AddressAnswer' }
        & Pick<AddressAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'BooleanAnswer' }
        & Pick<BooleanAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { boolean: BooleanAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ChoiceAnswer' }
        & Pick<ChoiceAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { choice: ChoiceAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'DateAnswer' }
        & Pick<DateAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { date: DateAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'EmailAnswer' }
        & Pick<EmailAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { email: EmailAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'FileAnswer' }
        & Pick<FileAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ListAnswer' }
        & Pick<ListAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'MoneyAnswer' }
        & Pick<MoneyAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { money: MoneyAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'NumberAnswer' }
        & Pick<NumberAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { number: NumberAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PercentAnswer' }
        & Pick<PercentAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { percent: PercentAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PhoneAnswer' }
        & Pick<PhoneAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { phone: PhoneAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'StringAnswer' }
        & Pick<StringAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { string: StringAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'TextAnswer' }
        & Pick<TextAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { text: TextAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      )> }
    ) | (
      { __typename?: 'NumberPart' }
      & Pick<NumberPart, 'minimumValue' | 'maximumValue' | 'id' | 'label' | 'instructions' | 'isRequired' | 'isComplete' | 'isHighlighted' | 'ordering' | 'officialAdvice' | 'additionalAdvice'>
      & { answerDraft?: Maybe<(
        { __typename?: 'AddressAnswerDraft' }
        & Pick<AddressAnswerDraft, 'id' | 'notApplicable'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'BooleanAnswerDraft' }
        & Pick<BooleanAnswerDraft, 'id' | 'notApplicable'>
        & { boolean: BooleanAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ChoiceAnswerDraft' }
        & Pick<ChoiceAnswerDraft, 'id' | 'notApplicable'>
        & { choice: ChoiceAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'DateAnswerDraft' }
        & Pick<DateAnswerDraft, 'id' | 'notApplicable'>
        & { date: DateAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'EmailAnswerDraft' }
        & Pick<EmailAnswerDraft, 'id' | 'notApplicable'>
        & { email: EmailAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'FileAnswerDraft' }
        & Pick<FileAnswerDraft, 'id' | 'notApplicable'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ListAnswerDraft' }
        & Pick<ListAnswerDraft, 'id' | 'notApplicable'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'MoneyAnswerDraft' }
        & Pick<MoneyAnswerDraft, 'id' | 'notApplicable'>
        & { money: MoneyAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'NumberAnswerDraft' }
        & Pick<NumberAnswerDraft, 'id' | 'notApplicable'>
        & { number: NumberAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PercentAnswerDraft' }
        & Pick<PercentAnswerDraft, 'id' | 'notApplicable'>
        & { percent: PercentAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PhoneAnswerDraft' }
        & Pick<PhoneAnswerDraft, 'id' | 'notApplicable'>
        & { phone: PhoneAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'StringAnswerDraft' }
        & Pick<StringAnswerDraft, 'id' | 'notApplicable'>
        & { string: StringAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'TextAnswerDraft' }
        & Pick<TextAnswerDraft, 'id' | 'notApplicable'>
        & { text: TextAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      )>, answer?: Maybe<(
        { __typename?: 'AddressAnswer' }
        & Pick<AddressAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'BooleanAnswer' }
        & Pick<BooleanAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { boolean: BooleanAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ChoiceAnswer' }
        & Pick<ChoiceAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { choice: ChoiceAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'DateAnswer' }
        & Pick<DateAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { date: DateAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'EmailAnswer' }
        & Pick<EmailAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { email: EmailAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'FileAnswer' }
        & Pick<FileAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ListAnswer' }
        & Pick<ListAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'MoneyAnswer' }
        & Pick<MoneyAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { money: MoneyAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'NumberAnswer' }
        & Pick<NumberAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { number: NumberAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PercentAnswer' }
        & Pick<PercentAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { percent: PercentAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PhoneAnswer' }
        & Pick<PhoneAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { phone: PhoneAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'StringAnswer' }
        & Pick<StringAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { string: StringAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'TextAnswer' }
        & Pick<TextAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { text: TextAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      )> }
    ) | (
      { __typename?: 'PercentPart' }
      & Pick<PercentPart, 'minimumValue' | 'maximumValue' | 'id' | 'label' | 'instructions' | 'isRequired' | 'isComplete' | 'isHighlighted' | 'ordering' | 'officialAdvice' | 'additionalAdvice'>
      & { answerDraft?: Maybe<(
        { __typename?: 'AddressAnswerDraft' }
        & Pick<AddressAnswerDraft, 'id' | 'notApplicable'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'BooleanAnswerDraft' }
        & Pick<BooleanAnswerDraft, 'id' | 'notApplicable'>
        & { boolean: BooleanAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ChoiceAnswerDraft' }
        & Pick<ChoiceAnswerDraft, 'id' | 'notApplicable'>
        & { choice: ChoiceAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'DateAnswerDraft' }
        & Pick<DateAnswerDraft, 'id' | 'notApplicable'>
        & { date: DateAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'EmailAnswerDraft' }
        & Pick<EmailAnswerDraft, 'id' | 'notApplicable'>
        & { email: EmailAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'FileAnswerDraft' }
        & Pick<FileAnswerDraft, 'id' | 'notApplicable'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ListAnswerDraft' }
        & Pick<ListAnswerDraft, 'id' | 'notApplicable'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'MoneyAnswerDraft' }
        & Pick<MoneyAnswerDraft, 'id' | 'notApplicable'>
        & { money: MoneyAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'NumberAnswerDraft' }
        & Pick<NumberAnswerDraft, 'id' | 'notApplicable'>
        & { number: NumberAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PercentAnswerDraft' }
        & Pick<PercentAnswerDraft, 'id' | 'notApplicable'>
        & { percent: PercentAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PhoneAnswerDraft' }
        & Pick<PhoneAnswerDraft, 'id' | 'notApplicable'>
        & { phone: PhoneAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'StringAnswerDraft' }
        & Pick<StringAnswerDraft, 'id' | 'notApplicable'>
        & { string: StringAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'TextAnswerDraft' }
        & Pick<TextAnswerDraft, 'id' | 'notApplicable'>
        & { text: TextAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      )>, answer?: Maybe<(
        { __typename?: 'AddressAnswer' }
        & Pick<AddressAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'BooleanAnswer' }
        & Pick<BooleanAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { boolean: BooleanAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ChoiceAnswer' }
        & Pick<ChoiceAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { choice: ChoiceAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'DateAnswer' }
        & Pick<DateAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { date: DateAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'EmailAnswer' }
        & Pick<EmailAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { email: EmailAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'FileAnswer' }
        & Pick<FileAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ListAnswer' }
        & Pick<ListAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'MoneyAnswer' }
        & Pick<MoneyAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { money: MoneyAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'NumberAnswer' }
        & Pick<NumberAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { number: NumberAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PercentAnswer' }
        & Pick<PercentAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { percent: PercentAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PhoneAnswer' }
        & Pick<PhoneAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { phone: PhoneAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'StringAnswer' }
        & Pick<StringAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { string: StringAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'TextAnswer' }
        & Pick<TextAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { text: TextAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      )> }
    ) | (
      { __typename?: 'PhonePart' }
      & Pick<PhonePart, 'id' | 'label' | 'instructions' | 'isRequired' | 'isComplete' | 'isHighlighted' | 'ordering' | 'officialAdvice' | 'additionalAdvice'>
      & { answerDraft?: Maybe<(
        { __typename?: 'AddressAnswerDraft' }
        & Pick<AddressAnswerDraft, 'id' | 'notApplicable'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'BooleanAnswerDraft' }
        & Pick<BooleanAnswerDraft, 'id' | 'notApplicable'>
        & { boolean: BooleanAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ChoiceAnswerDraft' }
        & Pick<ChoiceAnswerDraft, 'id' | 'notApplicable'>
        & { choice: ChoiceAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'DateAnswerDraft' }
        & Pick<DateAnswerDraft, 'id' | 'notApplicable'>
        & { date: DateAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'EmailAnswerDraft' }
        & Pick<EmailAnswerDraft, 'id' | 'notApplicable'>
        & { email: EmailAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'FileAnswerDraft' }
        & Pick<FileAnswerDraft, 'id' | 'notApplicable'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ListAnswerDraft' }
        & Pick<ListAnswerDraft, 'id' | 'notApplicable'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'MoneyAnswerDraft' }
        & Pick<MoneyAnswerDraft, 'id' | 'notApplicable'>
        & { money: MoneyAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'NumberAnswerDraft' }
        & Pick<NumberAnswerDraft, 'id' | 'notApplicable'>
        & { number: NumberAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PercentAnswerDraft' }
        & Pick<PercentAnswerDraft, 'id' | 'notApplicable'>
        & { percent: PercentAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PhoneAnswerDraft' }
        & Pick<PhoneAnswerDraft, 'id' | 'notApplicable'>
        & { phone: PhoneAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'StringAnswerDraft' }
        & Pick<StringAnswerDraft, 'id' | 'notApplicable'>
        & { string: StringAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'TextAnswerDraft' }
        & Pick<TextAnswerDraft, 'id' | 'notApplicable'>
        & { text: TextAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      )>, answer?: Maybe<(
        { __typename?: 'AddressAnswer' }
        & Pick<AddressAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'BooleanAnswer' }
        & Pick<BooleanAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { boolean: BooleanAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ChoiceAnswer' }
        & Pick<ChoiceAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { choice: ChoiceAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'DateAnswer' }
        & Pick<DateAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { date: DateAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'EmailAnswer' }
        & Pick<EmailAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { email: EmailAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'FileAnswer' }
        & Pick<FileAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ListAnswer' }
        & Pick<ListAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'MoneyAnswer' }
        & Pick<MoneyAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { money: MoneyAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'NumberAnswer' }
        & Pick<NumberAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { number: NumberAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PercentAnswer' }
        & Pick<PercentAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { percent: PercentAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PhoneAnswer' }
        & Pick<PhoneAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { phone: PhoneAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'StringAnswer' }
        & Pick<StringAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { string: StringAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'TextAnswer' }
        & Pick<TextAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { text: TextAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      )> }
    ) | (
      { __typename?: 'StringPart' }
      & Pick<StringPart, 'maximumLength' | 'minimumLength' | 'id' | 'label' | 'instructions' | 'isRequired' | 'isComplete' | 'isHighlighted' | 'ordering' | 'officialAdvice' | 'additionalAdvice'>
      & { answerDraft?: Maybe<(
        { __typename?: 'AddressAnswerDraft' }
        & Pick<AddressAnswerDraft, 'id' | 'notApplicable'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'BooleanAnswerDraft' }
        & Pick<BooleanAnswerDraft, 'id' | 'notApplicable'>
        & { boolean: BooleanAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ChoiceAnswerDraft' }
        & Pick<ChoiceAnswerDraft, 'id' | 'notApplicable'>
        & { choice: ChoiceAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'DateAnswerDraft' }
        & Pick<DateAnswerDraft, 'id' | 'notApplicable'>
        & { date: DateAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'EmailAnswerDraft' }
        & Pick<EmailAnswerDraft, 'id' | 'notApplicable'>
        & { email: EmailAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'FileAnswerDraft' }
        & Pick<FileAnswerDraft, 'id' | 'notApplicable'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ListAnswerDraft' }
        & Pick<ListAnswerDraft, 'id' | 'notApplicable'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'MoneyAnswerDraft' }
        & Pick<MoneyAnswerDraft, 'id' | 'notApplicable'>
        & { money: MoneyAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'NumberAnswerDraft' }
        & Pick<NumberAnswerDraft, 'id' | 'notApplicable'>
        & { number: NumberAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PercentAnswerDraft' }
        & Pick<PercentAnswerDraft, 'id' | 'notApplicable'>
        & { percent: PercentAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PhoneAnswerDraft' }
        & Pick<PhoneAnswerDraft, 'id' | 'notApplicable'>
        & { phone: PhoneAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'StringAnswerDraft' }
        & Pick<StringAnswerDraft, 'id' | 'notApplicable'>
        & { string: StringAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'TextAnswerDraft' }
        & Pick<TextAnswerDraft, 'id' | 'notApplicable'>
        & { text: TextAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      )>, answer?: Maybe<(
        { __typename?: 'AddressAnswer' }
        & Pick<AddressAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'BooleanAnswer' }
        & Pick<BooleanAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { boolean: BooleanAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ChoiceAnswer' }
        & Pick<ChoiceAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { choice: ChoiceAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'DateAnswer' }
        & Pick<DateAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { date: DateAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'EmailAnswer' }
        & Pick<EmailAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { email: EmailAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'FileAnswer' }
        & Pick<FileAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ListAnswer' }
        & Pick<ListAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'MoneyAnswer' }
        & Pick<MoneyAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { money: MoneyAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'NumberAnswer' }
        & Pick<NumberAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { number: NumberAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PercentAnswer' }
        & Pick<PercentAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { percent: PercentAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PhoneAnswer' }
        & Pick<PhoneAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { phone: PhoneAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'StringAnswer' }
        & Pick<StringAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { string: StringAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'TextAnswer' }
        & Pick<TextAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { text: TextAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      )> }
    ) | (
      { __typename?: 'TextPart' }
      & Pick<TextPart, 'maximumLength' | 'minimumLength' | 'id' | 'label' | 'instructions' | 'isRequired' | 'isComplete' | 'isHighlighted' | 'ordering' | 'officialAdvice' | 'additionalAdvice'>
      & { answerDraft?: Maybe<(
        { __typename?: 'AddressAnswerDraft' }
        & Pick<AddressAnswerDraft, 'id' | 'notApplicable'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'BooleanAnswerDraft' }
        & Pick<BooleanAnswerDraft, 'id' | 'notApplicable'>
        & { boolean: BooleanAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ChoiceAnswerDraft' }
        & Pick<ChoiceAnswerDraft, 'id' | 'notApplicable'>
        & { choice: ChoiceAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'DateAnswerDraft' }
        & Pick<DateAnswerDraft, 'id' | 'notApplicable'>
        & { date: DateAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'EmailAnswerDraft' }
        & Pick<EmailAnswerDraft, 'id' | 'notApplicable'>
        & { email: EmailAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'FileAnswerDraft' }
        & Pick<FileAnswerDraft, 'id' | 'notApplicable'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'ListAnswerDraft' }
        & Pick<ListAnswerDraft, 'id' | 'notApplicable'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'MoneyAnswerDraft' }
        & Pick<MoneyAnswerDraft, 'id' | 'notApplicable'>
        & { money: MoneyAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'NumberAnswerDraft' }
        & Pick<NumberAnswerDraft, 'id' | 'notApplicable'>
        & { number: NumberAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PercentAnswerDraft' }
        & Pick<PercentAnswerDraft, 'id' | 'notApplicable'>
        & { percent: PercentAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'PhoneAnswerDraft' }
        & Pick<PhoneAnswerDraft, 'id' | 'notApplicable'>
        & { phone: PhoneAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'StringAnswerDraft' }
        & Pick<StringAnswerDraft, 'id' | 'notApplicable'>
        & { string: StringAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      ) | (
        { __typename?: 'TextAnswerDraft' }
        & Pick<TextAnswerDraft, 'id' | 'notApplicable'>
        & { text: TextAnswerDraft['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ) }
      )>, answer?: Maybe<(
        { __typename?: 'AddressAnswer' }
        & Pick<AddressAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
        )>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'BooleanAnswer' }
        & Pick<BooleanAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { boolean: BooleanAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ChoiceAnswer' }
        & Pick<ChoiceAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { choice: ChoiceAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'DateAnswer' }
        & Pick<DateAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { date: DateAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'EmailAnswer' }
        & Pick<EmailAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { email: EmailAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'FileAnswer' }
        & Pick<FileAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { file?: Maybe<Array<(
          { __typename?: 'SupportingArchive' }
          & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingAudio' }
          & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingDocument' }
          & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingImage' }
          & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        ) | (
          { __typename?: 'SupportingVideo' }
          & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'ListAnswer' }
        & Pick<ListAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { list?: Maybe<Array<(
          { __typename?: 'ListItem' }
          & Pick<ListItem, 'ordering'>
          & { properties: Array<(
            { __typename?: 'AddressListItemProperty' }
            & Pick<AddressListItemProperty, 'attributeId'>
            & { address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
            )> }
          ) | (
            { __typename?: 'DateListItemProperty' }
            & Pick<DateListItemProperty, 'attributeId'>
            & { date: DateListItemProperty['value'] }
          ) | (
            { __typename?: 'EmailListItemProperty' }
            & Pick<EmailListItemProperty, 'attributeId'>
            & { email: EmailListItemProperty['value'] }
          ) | (
            { __typename?: 'FileListItemProperty' }
            & Pick<FileListItemProperty, 'attributeId'>
            & { file: FileListItemProperty['value'] }
          ) | (
            { __typename?: 'MoneyListItemProperty' }
            & Pick<MoneyListItemProperty, 'attributeId'>
            & { money: MoneyListItemProperty['value'] }
          ) | (
            { __typename?: 'NumberListItemProperty' }
            & Pick<NumberListItemProperty, 'attributeId'>
            & { number: NumberListItemProperty['value'] }
          ) | (
            { __typename?: 'PercentListItemProperty' }
            & Pick<PercentListItemProperty, 'attributeId'>
            & { percent: PercentListItemProperty['value'] }
          ) | (
            { __typename?: 'PhoneListItemProperty' }
            & Pick<PhoneListItemProperty, 'attributeId'>
            & { phone: PhoneListItemProperty['value'] }
          ) | (
            { __typename?: 'StringListItemProperty' }
            & Pick<StringListItemProperty, 'attributeId'>
            & { string: StringListItemProperty['value'] }
          ) | (
            { __typename?: 'TextListItemProperty' }
            & Pick<TextListItemProperty, 'attributeId'>
            & { text: TextListItemProperty['value'] }
          )> }
        )>>, proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'MoneyAnswer' }
        & Pick<MoneyAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { money: MoneyAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'NumberAnswer' }
        & Pick<NumberAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { number: NumberAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PercentAnswer' }
        & Pick<PercentAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { percent: PercentAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'PhoneAnswer' }
        & Pick<PhoneAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { phone: PhoneAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'StringAnswer' }
        & Pick<StringAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { string: StringAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      ) | (
        { __typename?: 'TextAnswer' }
        & Pick<TextAnswer, 'id' | 'proposedOn' | 'status' | 'notApplicable' | 'isAcceptedByClient' | 'isAcceptedByProfessional'>
        & { text: TextAnswer['currentValue'] }
        & { proposer: (
          { __typename?: 'Proposer' }
          & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
        ), history: Array<(
          { __typename?: 'AddressAnswerRevision' }
          & Pick<AddressAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
          )>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'BooleanAnswerRevision' }
          & Pick<BooleanAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { boolean: BooleanAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ChoiceAnswerRevision' }
          & Pick<ChoiceAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { choice: ChoiceAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'DateAnswerRevision' }
          & Pick<DateAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { date: DateAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'EmailAnswerRevision' }
          & Pick<EmailAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { email: EmailAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'FileAnswerRevision' }
          & Pick<FileAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { file?: Maybe<Array<(
            { __typename?: 'SupportingArchive' }
            & Pick<SupportingArchive, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingAudio' }
            & Pick<SupportingAudio, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingDocument' }
            & Pick<SupportingDocument, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingImage' }
            & Pick<SupportingImage, 'height' | 'width' | 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          ) | (
            { __typename?: 'SupportingVideo' }
            & Pick<SupportingVideo, 'id' | 'fileId' | 'fileVersion' | 'name' | 'type' | 'mime' | 'size'>
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'ListAnswerRevision' }
          & Pick<ListAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { list?: Maybe<Array<(
            { __typename?: 'ListItem' }
            & Pick<ListItem, 'ordering'>
            & { properties: Array<(
              { __typename?: 'AddressListItemProperty' }
              & Pick<AddressListItemProperty, 'attributeId'>
              & { address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'name' | 'line1' | 'line2' | 'line3' | 'city' | 'region' | 'postCode' | 'country'>
              )> }
            ) | (
              { __typename?: 'DateListItemProperty' }
              & Pick<DateListItemProperty, 'attributeId'>
              & { date: DateListItemProperty['value'] }
            ) | (
              { __typename?: 'EmailListItemProperty' }
              & Pick<EmailListItemProperty, 'attributeId'>
              & { email: EmailListItemProperty['value'] }
            ) | (
              { __typename?: 'FileListItemProperty' }
              & Pick<FileListItemProperty, 'attributeId'>
              & { file: FileListItemProperty['value'] }
            ) | (
              { __typename?: 'MoneyListItemProperty' }
              & Pick<MoneyListItemProperty, 'attributeId'>
              & { money: MoneyListItemProperty['value'] }
            ) | (
              { __typename?: 'NumberListItemProperty' }
              & Pick<NumberListItemProperty, 'attributeId'>
              & { number: NumberListItemProperty['value'] }
            ) | (
              { __typename?: 'PercentListItemProperty' }
              & Pick<PercentListItemProperty, 'attributeId'>
              & { percent: PercentListItemProperty['value'] }
            ) | (
              { __typename?: 'PhoneListItemProperty' }
              & Pick<PhoneListItemProperty, 'attributeId'>
              & { phone: PhoneListItemProperty['value'] }
            ) | (
              { __typename?: 'StringListItemProperty' }
              & Pick<StringListItemProperty, 'attributeId'>
              & { string: StringListItemProperty['value'] }
            ) | (
              { __typename?: 'TextListItemProperty' }
              & Pick<TextListItemProperty, 'attributeId'>
              & { text: TextListItemProperty['value'] }
            )> }
          )>>, proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'MoneyAnswerRevision' }
          & Pick<MoneyAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { money: MoneyAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'NumberAnswerRevision' }
          & Pick<NumberAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { number: NumberAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PercentAnswerRevision' }
          & Pick<PercentAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { percent: PercentAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'PhoneAnswerRevision' }
          & Pick<PhoneAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { phone: PhoneAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'StringAnswerRevision' }
          & Pick<StringAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { string: StringAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        ) | (
          { __typename?: 'TextAnswerRevision' }
          & Pick<TextAnswerRevision, 'id' | 'notApplicable' | 'proposedOn'>
          & { text: TextAnswerRevision['value'] }
          & { proposer: (
            { __typename?: 'Proposer' }
            & Pick<Proposer, 'id' | 'preferredName' | 'firstName' | 'lastName' | 'pictureUrl'>
          ) }
        )> }
      )> }
    )> }
  )> }
);

export type TaskUpdateSubscriptionVariables = Exact<{
  taskId: Scalars['ID'];
}>;


export type TaskUpdateSubscription = (
  { __typename?: 'Subscription' }
  & { taskUpdate?: Maybe<(
    { __typename?: 'FirmTask' }
    & Pick<FirmTask, 'matterTypes' | 'isPublished' | 'id' | 'name' | 'description' | 'sectionCount' | 'isModifiable' | 'officialAdvice' | 'additionalAdvice'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'officialAdvice' | 'additionalAdvice' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  ) | (
    { __typename?: 'LibraryTask' }
    & Pick<LibraryTask, 'matterTypes' | 'id' | 'name' | 'description' | 'sectionCount' | 'isModifiable' | 'officialAdvice' | 'additionalAdvice'>
    & { sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'officialAdvice' | 'additionalAdvice' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  ) | (
    { __typename?: 'MatterTask' }
    & Pick<MatterTask, 'status' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'id' | 'name' | 'description' | 'sectionCount' | 'isModifiable' | 'officialAdvice' | 'additionalAdvice'>
    & { dueDate?: Maybe<(
      { __typename?: 'KeyDate' }
      & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
    )>, sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'officialAdvice' | 'additionalAdvice' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  )> }
);

export type NewMatterTaskSubscriptionVariables = Exact<{
  matterId: Scalars['ID'];
}>;


export type NewMatterTaskSubscription = (
  { __typename?: 'Subscription' }
  & { newMatterTask?: Maybe<(
    { __typename?: 'MatterTask' }
    & Pick<MatterTask, 'id' | 'name' | 'description' | 'status' | 'sectionCount' | 'proportionComplete' | 'proportionReleased' | 'isHighlighted' | 'isModifiable' | 'officialAdvice' | 'additionalAdvice'>
    & { dueDate?: Maybe<(
      { __typename?: 'KeyDate' }
      & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
    )>, sections: Array<(
      { __typename?: 'TaskSection' }
      & Pick<TaskSection, 'id' | 'name' | 'description' | 'ordering' | 'officialAdvice' | 'additionalAdvice' | 'isHighlighted'>
      & { dueDate?: Maybe<(
        { __typename?: 'KeyDate' }
        & Pick<KeyDate, 'id' | 'date' | 'isAllDay' | 'name' | 'description'>
      )>, questions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text' | 'description' | 'isReleased' | 'proportionComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice' | 'status'>
        & { parts: Array<(
          { __typename?: 'AddressPart' }
          & Pick<AddressPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'BooleanPart' }
          & Pick<BooleanPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ChoicePart' }
          & Pick<ChoicePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'DatePart' }
          & Pick<DatePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'EmailPart' }
          & Pick<EmailPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'FilePart' }
          & Pick<FilePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'ListPart' }
          & Pick<ListPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'MoneyPart' }
          & Pick<MoneyPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'NumberPart' }
          & Pick<NumberPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PercentPart' }
          & Pick<PercentPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'PhonePart' }
          & Pick<PhonePart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'StringPart' }
          & Pick<StringPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        ) | (
          { __typename?: 'TextPart' }
          & Pick<TextPart, 'id' | 'label' | 'isComplete' | 'isHighlighted' | 'officialAdvice' | 'additionalAdvice'>
        )> }
      )> }
    )> }
  )> }
);

export const AddressFieldsFragmentDoc = gql`
    fragment addressFields on Address {
  line1
  line2
  line3
  city
  region
  postCode
  country
}
    `;
export const ConversationFragmentDoc = gql`
    fragment conversation on Conversation {
  id
  pictureUrl
  name
  description
  participants {
    id
    preferredName
    firstName
    lastName
    pictureUrl
    role
    title
    status
  }
  groups {
    id
    name
    pictureUrl
    members {
      id
      name
      pictureUrl
      title
    }
  }
  startedAt
  unreadMessageCount
  lastMessageReadAt
  lastMessageSentAt
  lastMessageDeliveredAt
  pausedUntil
  lastMessage {
    id
  }
  metadata {
    ... on KeyValueArray {
      key
      values: value
    }
    ... on KeyValueString {
      key
      value
    }
  }
}
    `;
export const FileInfoFragmentDoc = gql`
    fragment fileInfo on SupportingFile {
  id
  source
  name
  fileId
  fileVersion
  size
  type
  mime
}
    `;
export const SegmentsFragmentDoc = gql`
    fragment Segments on PathSegment {
  id
  type
  name
}
    `;
export const FilesFragmentDoc = gql`
    fragment Files on MatchedFile {
  id
  type
  name
  mime
  folderId
  lastRevisedOn
  path {
    ...Segments
  }
}
    ${SegmentsFragmentDoc}`;
export const ContactsFragmentDoc = gql`
    fragment Contacts on MatchedContact {
  id
  type
  name
  pictureUrl
  isTransparentlyUser
  isAssociatedWithFirm
  firmName
  isAssociatedWithIssue
  issueName
  addedOn
  path {
    ...Segments
  }
}
    ${SegmentsFragmentDoc}`;
export const FoldersFragmentDoc = gql`
    fragment Folders on MatchedFolder {
  id
  name
  addedOn
  path {
    ...Segments
  }
}
    ${SegmentsFragmentDoc}`;
export const MessagesFragmentDoc = gql`
    fragment Messages on MatchedMessage {
  id
  conversationId
  senderName
  title
  body
  sentAt
  attachmentCount
  path {
    ...Segments
  }
}
    ${SegmentsFragmentDoc}`;
export const ClientAccountsFragmentDoc = gql`
    fragment ClientAccounts on MatchedClientAccount {
  id
  clientName
  clientPictureUrl
  status
  path {
    ...Segments
  }
}
    ${SegmentsFragmentDoc}`;
export const TasksFragmentDoc = gql`
    fragment Tasks on MatchedTask {
  id
  matterId
  name
  proportionComplete
  dueDate
  clientId
  clientName
  clientAccountId
  path {
    ...Segments
  }
}
    ${SegmentsFragmentDoc}`;
export const NotesFragmentDoc = gql`
    fragment Notes on MatchedNote {
  id
  category
  dateTime
  versionNumber
  authorId
  authorName
  authorPictureUrl
  title
  noteClientName: clientName
  noteClientId: clientId
  noteClientPictureUrl: clientPictureUrl
  path {
    ...Segments
  }
}
    ${SegmentsFragmentDoc}`;
export const UpdateBreadcrumbsDocument = gql`
    mutation UpdateBreadcrumbs($breadcrumbs: [BreadcrumbInput]!) {
  updateBreadcrumbs(input: $breadcrumbs) @client {
    name
    to
    image
  }
}
    `;

/**
 * __useUpdateBreadcrumbsMutation__
 *
 * To run a mutation, you first call `useUpdateBreadcrumbsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBreadcrumbsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBreadcrumbsMutation, { data, loading, error }] = useUpdateBreadcrumbsMutation({
 *   variables: {
 *      breadcrumbs: // value for 'breadcrumbs'
 *   },
 * });
 */
export function useUpdateBreadcrumbsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBreadcrumbsMutation, UpdateBreadcrumbsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBreadcrumbsMutation, UpdateBreadcrumbsMutationVariables>(UpdateBreadcrumbsDocument, baseOptions);
      }
export type UpdateBreadcrumbsMutationHookResult = ReturnType<typeof useUpdateBreadcrumbsMutation>;
export type UpdateBreadcrumbsMutationResult = ApolloReactCommon.MutationResult<UpdateBreadcrumbsMutation>;
export type UpdateBreadcrumbsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBreadcrumbsMutation, UpdateBreadcrumbsMutationVariables>;
export const SetupClientAccountDocument = gql`
    mutation SetupClientAccount($input: SetupClientAccountInput!) {
  setupClientAccount(input: $input) {
    id
    status
    referenceNumber
    client {
      id
      preferredName
      firstName
      lastName
      pictureUrl
      emailAddress
      mobilePhoneNumber
      nationalInsuranceNumber
      status
      referenceNumber
    }
    leadProfessional {
      id
      preferredName
      firstName
      lastName
    }
    firm {
      id
      name
    }
  }
}
    `;

/**
 * __useSetupClientAccountMutation__
 *
 * To run a mutation, you first call `useSetupClientAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupClientAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupClientAccountMutation, { data, loading, error }] = useSetupClientAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetupClientAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetupClientAccountMutation, SetupClientAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<SetupClientAccountMutation, SetupClientAccountMutationVariables>(SetupClientAccountDocument, baseOptions);
      }
export type SetupClientAccountMutationHookResult = ReturnType<typeof useSetupClientAccountMutation>;
export type SetupClientAccountMutationResult = ApolloReactCommon.MutationResult<SetupClientAccountMutation>;
export type SetupClientAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<SetupClientAccountMutation, SetupClientAccountMutationVariables>;
export const AssignFirmMemberToClientDocument = gql`
    mutation AssignFirmMemberToClient($input: AssignFirmMemberToClientInput!) {
  assignFirmMemberToClient(input: $input) {
    id
    isLead
    firmMember {
      id
      employeeNumber
      jobTitle
      office
      isActive
      isHighlighted
      activeSince
      role
      status
      professional {
        id
        preferredName
        firstName
        middleNames
        lastName
        workPhoneNumber
        emailAddress
        pictureUrl
        type
        status
        referenceNumber
        ... on FirmBarrister {
          sraNumber
        }
        ... on FirmSolicitor {
          sraNumber
        }
        ... on FirmParalegal {
          paralegalNumber
        }
      }
      firm {
        id
        name
        logoUrl
      }
    }
    clientAccount {
      id
      isHighlighted
      status
      referenceNumber
      client {
        id
        preferredName
        firstName
        lastName
        pictureUrl
        emailAddress
        mobilePhoneNumber
        nationalInsuranceNumber
        isVerified
        profileIsComplete
        status
        referenceNumber
        issues {
          id
          type
          isLinked
          linkCode
          linkRequested
          opponentIssue {
            id
            legalTeam {
              id
              jobTitle
              isLead
              firm {
                id
                name
              }
              professional {
                id
                emailAddress
                preferredName
                lastName
                type
              }
            }
          }
          opponent {
            preferredName
            firstName
            lastName
            emailAddress
            nationalInsuranceNumber
            clientReferenceNumber
            isVerified
            solicitor {
              firstName
              lastName
              emailAddress
              firmName
            }
          }
        }
      }
      leadProfessional {
        id
        preferredName
        firstName
        lastName
      }
      firm {
        id
        name
      }
    }
    assignedOn
  }
}
    `;

/**
 * __useAssignFirmMemberToClientMutation__
 *
 * To run a mutation, you first call `useAssignFirmMemberToClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignFirmMemberToClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignFirmMemberToClientMutation, { data, loading, error }] = useAssignFirmMemberToClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignFirmMemberToClientMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignFirmMemberToClientMutation, AssignFirmMemberToClientMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignFirmMemberToClientMutation, AssignFirmMemberToClientMutationVariables>(AssignFirmMemberToClientDocument, baseOptions);
      }
export type AssignFirmMemberToClientMutationHookResult = ReturnType<typeof useAssignFirmMemberToClientMutation>;
export type AssignFirmMemberToClientMutationResult = ApolloReactCommon.MutationResult<AssignFirmMemberToClientMutation>;
export type AssignFirmMemberToClientMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignFirmMemberToClientMutation, AssignFirmMemberToClientMutationVariables>;
export const RevokeClientAssignmentDocument = gql`
    mutation RevokeClientAssignment($input: RevokeClientAssignmentInput!) {
  revokeClientAssignment(input: $input) {
    clientAssignmentId
    revokedOn
  }
}
    `;

/**
 * __useRevokeClientAssignmentMutation__
 *
 * To run a mutation, you first call `useRevokeClientAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeClientAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeClientAssignmentMutation, { data, loading, error }] = useRevokeClientAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRevokeClientAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RevokeClientAssignmentMutation, RevokeClientAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<RevokeClientAssignmentMutation, RevokeClientAssignmentMutationVariables>(RevokeClientAssignmentDocument, baseOptions);
      }
export type RevokeClientAssignmentMutationHookResult = ReturnType<typeof useRevokeClientAssignmentMutation>;
export type RevokeClientAssignmentMutationResult = ApolloReactCommon.MutationResult<RevokeClientAssignmentMutation>;
export type RevokeClientAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<RevokeClientAssignmentMutation, RevokeClientAssignmentMutationVariables>;
export const UpdateClientPersonalDetailsDocument = gql`
    mutation UpdateClientPersonalDetails($input: UpdateClientPersonalDetailsInput!) {
  updateClientPersonalDetails(input: $input) {
    id
    preferredName
    firstName
    lastName
    previousFirstName
    previousLastName
    previousMiddleNames
    preferredName
    middleNames
    birthDate
    nationalInsuranceNumber
    profileIsComplete
  }
}
    `;

/**
 * __useUpdateClientPersonalDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateClientPersonalDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientPersonalDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientPersonalDetailsMutation, { data, loading, error }] = useUpdateClientPersonalDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientPersonalDetailsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateClientPersonalDetailsMutation, UpdateClientPersonalDetailsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateClientPersonalDetailsMutation, UpdateClientPersonalDetailsMutationVariables>(UpdateClientPersonalDetailsDocument, baseOptions);
      }
export type UpdateClientPersonalDetailsMutationHookResult = ReturnType<typeof useUpdateClientPersonalDetailsMutation>;
export type UpdateClientPersonalDetailsMutationResult = ApolloReactCommon.MutationResult<UpdateClientPersonalDetailsMutation>;
export type UpdateClientPersonalDetailsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateClientPersonalDetailsMutation, UpdateClientPersonalDetailsMutationVariables>;
export const UpdateClientContactInformationDocument = gql`
    mutation UpdateClientContactInformation($input: UpdateClientContactInformationInput!) {
  updateClientContactInformation(input: $input) {
    id
    fullName
    emailAddress
    mobilePhoneNumber
    nationalInsuranceNumber
    homePhoneNumber
    workPhoneNumber
    profileIsComplete
  }
}
    `;

/**
 * __useUpdateClientContactInformationMutation__
 *
 * To run a mutation, you first call `useUpdateClientContactInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientContactInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientContactInformationMutation, { data, loading, error }] = useUpdateClientContactInformationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientContactInformationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateClientContactInformationMutation, UpdateClientContactInformationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateClientContactInformationMutation, UpdateClientContactInformationMutationVariables>(UpdateClientContactInformationDocument, baseOptions);
      }
export type UpdateClientContactInformationMutationHookResult = ReturnType<typeof useUpdateClientContactInformationMutation>;
export type UpdateClientContactInformationMutationResult = ApolloReactCommon.MutationResult<UpdateClientContactInformationMutation>;
export type UpdateClientContactInformationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateClientContactInformationMutation, UpdateClientContactInformationMutationVariables>;
export const UpdateClientAddressesDocument = gql`
    mutation UpdateClientAddresses($input: UpdateClientAddressesInput!) {
  updateClientAddresses(input: $input) {
    id
    homeAddress {
      ...addressFields
    }
    previousHomeAddresses {
      ...addressFields
    }
    workAddress {
      name
      ...addressFields
    }
    profileIsComplete
  }
}
    ${AddressFieldsFragmentDoc}`;

/**
 * __useUpdateClientAddressesMutation__
 *
 * To run a mutation, you first call `useUpdateClientAddressesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientAddressesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientAddressesMutation, { data, loading, error }] = useUpdateClientAddressesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientAddressesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateClientAddressesMutation, UpdateClientAddressesMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateClientAddressesMutation, UpdateClientAddressesMutationVariables>(UpdateClientAddressesDocument, baseOptions);
      }
export type UpdateClientAddressesMutationHookResult = ReturnType<typeof useUpdateClientAddressesMutation>;
export type UpdateClientAddressesMutationResult = ApolloReactCommon.MutationResult<UpdateClientAddressesMutation>;
export type UpdateClientAddressesMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateClientAddressesMutation, UpdateClientAddressesMutationVariables>;
export const UpdateClientIdentityProofDocument = gql`
    mutation UpdateClientIdentityProof($input: UpdateClientIdentityProofInput!) {
  updateClientIdentityProof(input: $input) {
    id
    name
    identityProof {
      id
      type
      reference
      assetUrl
      country
      expiresAt
      assetFileId
      assetMime
      assetFileVersion
    }
    profileIsComplete
  }
}
    `;

/**
 * __useUpdateClientIdentityProofMutation__
 *
 * To run a mutation, you first call `useUpdateClientIdentityProofMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientIdentityProofMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientIdentityProofMutation, { data, loading, error }] = useUpdateClientIdentityProofMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientIdentityProofMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateClientIdentityProofMutation, UpdateClientIdentityProofMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateClientIdentityProofMutation, UpdateClientIdentityProofMutationVariables>(UpdateClientIdentityProofDocument, baseOptions);
      }
export type UpdateClientIdentityProofMutationHookResult = ReturnType<typeof useUpdateClientIdentityProofMutation>;
export type UpdateClientIdentityProofMutationResult = ApolloReactCommon.MutationResult<UpdateClientIdentityProofMutation>;
export type UpdateClientIdentityProofMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateClientIdentityProofMutation, UpdateClientIdentityProofMutationVariables>;
export const UpdateClientAddressProofDocument = gql`
    mutation UpdateClientAddressProof($input: UpdateClientAddressProofInput!) {
  updateClientAddressProof(input: $input) {
    id
    name
    addressProof {
      id
      type
      reference
      assetUrl
      country
      expiresAt
      assetFileId
      assetMime
      assetFileVersion
    }
    profileIsComplete
  }
}
    `;

/**
 * __useUpdateClientAddressProofMutation__
 *
 * To run a mutation, you first call `useUpdateClientAddressProofMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientAddressProofMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientAddressProofMutation, { data, loading, error }] = useUpdateClientAddressProofMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientAddressProofMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateClientAddressProofMutation, UpdateClientAddressProofMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateClientAddressProofMutation, UpdateClientAddressProofMutationVariables>(UpdateClientAddressProofDocument, baseOptions);
      }
export type UpdateClientAddressProofMutationHookResult = ReturnType<typeof useUpdateClientAddressProofMutation>;
export type UpdateClientAddressProofMutationResult = ApolloReactCommon.MutationResult<UpdateClientAddressProofMutation>;
export type UpdateClientAddressProofMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateClientAddressProofMutation, UpdateClientAddressProofMutationVariables>;
export const UpdateClientBankDetailsDocument = gql`
    mutation UpdateClientBankDetails($input: UpdateClientBankDetailsInput!) {
  updateClientBankDetails(input: $input) {
    id
    bankDetails {
      name
      branchAddress {
        ...addressFields
      }
      accountNumber
      sortCode
    }
    profileIsComplete
  }
}
    ${AddressFieldsFragmentDoc}`;

/**
 * __useUpdateClientBankDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateClientBankDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientBankDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientBankDetailsMutation, { data, loading, error }] = useUpdateClientBankDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientBankDetailsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateClientBankDetailsMutation, UpdateClientBankDetailsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateClientBankDetailsMutation, UpdateClientBankDetailsMutationVariables>(UpdateClientBankDetailsDocument, baseOptions);
      }
export type UpdateClientBankDetailsMutationHookResult = ReturnType<typeof useUpdateClientBankDetailsMutation>;
export type UpdateClientBankDetailsMutationResult = ApolloReactCommon.MutationResult<UpdateClientBankDetailsMutation>;
export type UpdateClientBankDetailsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateClientBankDetailsMutation, UpdateClientBankDetailsMutationVariables>;
export const VerifyClientDocument = gql`
    mutation VerifyClient($input: VerifyClientInput!) {
  verifyClient(input: $input) {
    id
    name
    isVerified
    verifiedAt
  }
}
    `;

/**
 * __useVerifyClientMutation__
 *
 * To run a mutation, you first call `useVerifyClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyClientMutation, { data, loading, error }] = useVerifyClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyClientMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerifyClientMutation, VerifyClientMutationVariables>) {
        return ApolloReactHooks.useMutation<VerifyClientMutation, VerifyClientMutationVariables>(VerifyClientDocument, baseOptions);
      }
export type VerifyClientMutationHookResult = ReturnType<typeof useVerifyClientMutation>;
export type VerifyClientMutationResult = ApolloReactCommon.MutationResult<VerifyClientMutation>;
export type VerifyClientMutationOptions = ApolloReactCommon.BaseMutationOptions<VerifyClientMutation, VerifyClientMutationVariables>;
export const ResendClientInviteDocument = gql`
    mutation resendClientInvite($input: ResendClientInviteInput!) {
  resendClientInvite(input: $input) {
    id
  }
}
    `;

/**
 * __useResendClientInviteMutation__
 *
 * To run a mutation, you first call `useResendClientInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendClientInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendClientInviteMutation, { data, loading, error }] = useResendClientInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendClientInviteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResendClientInviteMutation, ResendClientInviteMutationVariables>) {
        return ApolloReactHooks.useMutation<ResendClientInviteMutation, ResendClientInviteMutationVariables>(ResendClientInviteDocument, baseOptions);
      }
export type ResendClientInviteMutationHookResult = ReturnType<typeof useResendClientInviteMutation>;
export type ResendClientInviteMutationResult = ApolloReactCommon.MutationResult<ResendClientInviteMutation>;
export type ResendClientInviteMutationOptions = ApolloReactCommon.BaseMutationOptions<ResendClientInviteMutation, ResendClientInviteMutationVariables>;
export const ApproveClientAccountDocument = gql`
    mutation ApproveClientAccount($input: ApproveClientAccountInput!) {
  approveClientAccount(input: $input) {
    id
    status
    referenceNumber
    client {
      id
      name
      status
    }
  }
}
    `;

/**
 * __useApproveClientAccountMutation__
 *
 * To run a mutation, you first call `useApproveClientAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveClientAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveClientAccountMutation, { data, loading, error }] = useApproveClientAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveClientAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveClientAccountMutation, ApproveClientAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveClientAccountMutation, ApproveClientAccountMutationVariables>(ApproveClientAccountDocument, baseOptions);
      }
export type ApproveClientAccountMutationHookResult = ReturnType<typeof useApproveClientAccountMutation>;
export type ApproveClientAccountMutationResult = ApolloReactCommon.MutationResult<ApproveClientAccountMutation>;
export type ApproveClientAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveClientAccountMutation, ApproveClientAccountMutationVariables>;
export const ChangeClientAccountActivityDocument = gql`
    mutation ChangeClientAccountActivity($input: ChangeClientAccountActivityInput!) {
  changeClientAccountActivity(input: $input) {
    id
    status
    referenceNumber
    client {
      id
      name
      status
    }
  }
}
    `;

/**
 * __useChangeClientAccountActivityMutation__
 *
 * To run a mutation, you first call `useChangeClientAccountActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeClientAccountActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeClientAccountActivityMutation, { data, loading, error }] = useChangeClientAccountActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeClientAccountActivityMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeClientAccountActivityMutation, ChangeClientAccountActivityMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeClientAccountActivityMutation, ChangeClientAccountActivityMutationVariables>(ChangeClientAccountActivityDocument, baseOptions);
      }
export type ChangeClientAccountActivityMutationHookResult = ReturnType<typeof useChangeClientAccountActivityMutation>;
export type ChangeClientAccountActivityMutationResult = ApolloReactCommon.MutationResult<ChangeClientAccountActivityMutation>;
export type ChangeClientAccountActivityMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeClientAccountActivityMutation, ChangeClientAccountActivityMutationVariables>;
export const UpdateClientAccountDocument = gql`
    mutation UpdateClientAccount($input: UpdateClientAccountInput!) {
  updateClientAccount(input: $input) {
    id
    referenceNumber
    leadProfessional {
      id
      preferredName
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useUpdateClientAccountMutation__
 *
 * To run a mutation, you first call `useUpdateClientAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientAccountMutation, { data, loading, error }] = useUpdateClientAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateClientAccountMutation, UpdateClientAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateClientAccountMutation, UpdateClientAccountMutationVariables>(UpdateClientAccountDocument, baseOptions);
      }
export type UpdateClientAccountMutationHookResult = ReturnType<typeof useUpdateClientAccountMutation>;
export type UpdateClientAccountMutationResult = ApolloReactCommon.MutationResult<UpdateClientAccountMutation>;
export type UpdateClientAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateClientAccountMutation, UpdateClientAccountMutationVariables>;
export const VerifyEmailAddressDocument = gql`
    mutation VerifyEmailAddress($input: VerifyEmailAddressInput!) {
  verifyEmailAddress(input: $input) {
    id
    fullName
    email
  }
}
    `;

/**
 * __useVerifyEmailAddressMutation__
 *
 * To run a mutation, you first call `useVerifyEmailAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailAddressMutation, { data, loading, error }] = useVerifyEmailAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyEmailAddressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerifyEmailAddressMutation, VerifyEmailAddressMutationVariables>) {
        return ApolloReactHooks.useMutation<VerifyEmailAddressMutation, VerifyEmailAddressMutationVariables>(VerifyEmailAddressDocument, baseOptions);
      }
export type VerifyEmailAddressMutationHookResult = ReturnType<typeof useVerifyEmailAddressMutation>;
export type VerifyEmailAddressMutationResult = ApolloReactCommon.MutationResult<VerifyEmailAddressMutation>;
export type VerifyEmailAddressMutationOptions = ApolloReactCommon.BaseMutationOptions<VerifyEmailAddressMutation, VerifyEmailAddressMutationVariables>;
export const AddContactDocument = gql`
    mutation AddContact($input: AddContactInput!) {
  addContact(input: $input) {
    id
    preferredName
    firstName
    lastName
    emailAddress
    type
    verificationCode
    conversationId
  }
}
    `;

/**
 * __useAddContactMutation__
 *
 * To run a mutation, you first call `useAddContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContactMutation, { data, loading, error }] = useAddContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddContactMutation, AddContactMutationVariables>) {
        return ApolloReactHooks.useMutation<AddContactMutation, AddContactMutationVariables>(AddContactDocument, baseOptions);
      }
export type AddContactMutationHookResult = ReturnType<typeof useAddContactMutation>;
export type AddContactMutationResult = ApolloReactCommon.MutationResult<AddContactMutation>;
export type AddContactMutationOptions = ApolloReactCommon.BaseMutationOptions<AddContactMutation, AddContactMutationVariables>;
export const VerifyContactDocument = gql`
    mutation VerifyContact($input: VerifyContactInput!) {
  verifyContact(input: $input) {
    id
    fullName
    isTransparentlyUser
    isVerified
  }
}
    `;

/**
 * __useVerifyContactMutation__
 *
 * To run a mutation, you first call `useVerifyContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyContactMutation, { data, loading, error }] = useVerifyContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerifyContactMutation, VerifyContactMutationVariables>) {
        return ApolloReactHooks.useMutation<VerifyContactMutation, VerifyContactMutationVariables>(VerifyContactDocument, baseOptions);
      }
export type VerifyContactMutationHookResult = ReturnType<typeof useVerifyContactMutation>;
export type VerifyContactMutationResult = ApolloReactCommon.MutationResult<VerifyContactMutation>;
export type VerifyContactMutationOptions = ApolloReactCommon.BaseMutationOptions<VerifyContactMutation, VerifyContactMutationVariables>;
export const UpdateContactDocument = gql`
    mutation UpdateContact($input: UpdateContactInput!) {
  updateContact(input: $input) {
    id
    preferredName
    firstName
    lastName
    emailAddress
    type
    status
    isVerified
  }
}
    `;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContactMutation, UpdateContactMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(UpdateContactDocument, baseOptions);
      }
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export type UpdateContactMutationResult = ApolloReactCommon.MutationResult<UpdateContactMutation>;
export type UpdateContactMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContactMutation, UpdateContactMutationVariables>;
export const RestartContactVerificationDocument = gql`
    mutation RestartContactVerification($input: RestartContactVerificationInput!) {
  restartContactVerification(input: $input) {
    id
    preferredName
    firstName
    lastName
    emailAddress
    status
  }
}
    `;

/**
 * __useRestartContactVerificationMutation__
 *
 * To run a mutation, you first call `useRestartContactVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestartContactVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restartContactVerificationMutation, { data, loading, error }] = useRestartContactVerificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRestartContactVerificationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RestartContactVerificationMutation, RestartContactVerificationMutationVariables>) {
        return ApolloReactHooks.useMutation<RestartContactVerificationMutation, RestartContactVerificationMutationVariables>(RestartContactVerificationDocument, baseOptions);
      }
export type RestartContactVerificationMutationHookResult = ReturnType<typeof useRestartContactVerificationMutation>;
export type RestartContactVerificationMutationResult = ApolloReactCommon.MutationResult<RestartContactVerificationMutation>;
export type RestartContactVerificationMutationOptions = ApolloReactCommon.BaseMutationOptions<RestartContactVerificationMutation, RestartContactVerificationMutationVariables>;
export const PauseConversationDocument = gql`
    mutation PauseConversation($input: PauseConversationInput!) {
  pauseConversation(input: $input) {
    id
    startedAt
    pausedUntil
  }
}
    `;

/**
 * __usePauseConversationMutation__
 *
 * To run a mutation, you first call `usePauseConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePauseConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pauseConversationMutation, { data, loading, error }] = usePauseConversationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePauseConversationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PauseConversationMutation, PauseConversationMutationVariables>) {
        return ApolloReactHooks.useMutation<PauseConversationMutation, PauseConversationMutationVariables>(PauseConversationDocument, baseOptions);
      }
export type PauseConversationMutationHookResult = ReturnType<typeof usePauseConversationMutation>;
export type PauseConversationMutationResult = ApolloReactCommon.MutationResult<PauseConversationMutation>;
export type PauseConversationMutationOptions = ApolloReactCommon.BaseMutationOptions<PauseConversationMutation, PauseConversationMutationVariables>;
export const ResumeConversationDocument = gql`
    mutation ResumeConversation($input: ResumeConversationInput!) {
  resumeConversation(input: $input) {
    id
    startedAt
    pausedUntil
  }
}
    `;

/**
 * __useResumeConversationMutation__
 *
 * To run a mutation, you first call `useResumeConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResumeConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resumeConversationMutation, { data, loading, error }] = useResumeConversationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResumeConversationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResumeConversationMutation, ResumeConversationMutationVariables>) {
        return ApolloReactHooks.useMutation<ResumeConversationMutation, ResumeConversationMutationVariables>(ResumeConversationDocument, baseOptions);
      }
export type ResumeConversationMutationHookResult = ReturnType<typeof useResumeConversationMutation>;
export type ResumeConversationMutationResult = ApolloReactCommon.MutationResult<ResumeConversationMutation>;
export type ResumeConversationMutationOptions = ApolloReactCommon.BaseMutationOptions<ResumeConversationMutation, ResumeConversationMutationVariables>;
export const MarkAllMessagesReadDocument = gql`
    mutation MarkAllMessagesRead($input: MarkAllMessagesReadInput!) {
  markAllMessagesRead(input: $input) {
    id
    lastMessageReadAt
  }
}
    `;

/**
 * __useMarkAllMessagesReadMutation__
 *
 * To run a mutation, you first call `useMarkAllMessagesReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllMessagesReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllMessagesReadMutation, { data, loading, error }] = useMarkAllMessagesReadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkAllMessagesReadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MarkAllMessagesReadMutation, MarkAllMessagesReadMutationVariables>) {
        return ApolloReactHooks.useMutation<MarkAllMessagesReadMutation, MarkAllMessagesReadMutationVariables>(MarkAllMessagesReadDocument, baseOptions);
      }
export type MarkAllMessagesReadMutationHookResult = ReturnType<typeof useMarkAllMessagesReadMutation>;
export type MarkAllMessagesReadMutationResult = ApolloReactCommon.MutationResult<MarkAllMessagesReadMutation>;
export type MarkAllMessagesReadMutationOptions = ApolloReactCommon.BaseMutationOptions<MarkAllMessagesReadMutation, MarkAllMessagesReadMutationVariables>;
export const SetCurrentUserDocument = gql`
    mutation SetCurrentUser($user: UserInput!) {
  setCurrentUser(input: $user) @client {
    id
    preferredName
    givenName
    middleName
    familyName
    picture
    email
    phone
    blocked
    referenceNumber
    roles {
      id
      name
      description
    }
  }
}
    `;

/**
 * __useSetCurrentUserMutation__
 *
 * To run a mutation, you first call `useSetCurrentUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCurrentUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCurrentUserMutation, { data, loading, error }] = useSetCurrentUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useSetCurrentUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetCurrentUserMutation, SetCurrentUserMutationVariables>) {
        return ApolloReactHooks.useMutation<SetCurrentUserMutation, SetCurrentUserMutationVariables>(SetCurrentUserDocument, baseOptions);
      }
export type SetCurrentUserMutationHookResult = ReturnType<typeof useSetCurrentUserMutation>;
export type SetCurrentUserMutationResult = ApolloReactCommon.MutationResult<SetCurrentUserMutation>;
export type SetCurrentUserMutationOptions = ApolloReactCommon.BaseMutationOptions<SetCurrentUserMutation, SetCurrentUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    id
    givenName
    middleName
    familyName
    fullName
    email
    phone
    picture
  }
}
    `;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const ChangeUserPasswordDocument = gql`
    mutation ChangeUserPassword($input: ChangeUserPasswordInput!) {
  changeUserPassword(input: $input) {
    id
    givenName
    middleName
    familyName
    fullName
    email
    phone
  }
}
    `;

/**
 * __useChangeUserPasswordMutation__
 *
 * To run a mutation, you first call `useChangeUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserPasswordMutation, { data, loading, error }] = useChangeUserPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeUserPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables>(ChangeUserPasswordDocument, baseOptions);
      }
export type ChangeUserPasswordMutationHookResult = ReturnType<typeof useChangeUserPasswordMutation>;
export type ChangeUserPasswordMutationResult = ApolloReactCommon.MutationResult<ChangeUserPasswordMutation>;
export type ChangeUserPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables>;
export const RequestPasswordResetDocument = gql`
    mutation RequestPasswordReset($input: RequestPasswordResetInput!) {
  requestPasswordReset(input: $input) {
    token
    expiresIn
    expiresAt
  }
}
    `;

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMutation, { data, loading, error }] = useRequestPasswordResetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>) {
        return ApolloReactHooks.useMutation<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>(RequestPasswordResetDocument, baseOptions);
      }
export type RequestPasswordResetMutationHookResult = ReturnType<typeof useRequestPasswordResetMutation>;
export type RequestPasswordResetMutationResult = ApolloReactCommon.MutationResult<RequestPasswordResetMutation>;
export type RequestPasswordResetMutationOptions = ApolloReactCommon.BaseMutationOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input) {
    user {
      id
      email
    }
  }
}
    `;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const AddFirmMemberDocument = gql`
    mutation AddFirmMember($input: AddFirmMemberInput!) {
  addFirmMember(input: $input) {
    id
    role
    employeeNumber
    jobTitle
    office
    isActive
    isHighlighted
    activeSince
    status
    professional {
      id
      preferredName
      firstName
      middleNames
      lastName
      workPhoneNumber
      emailAddress
      pictureUrl
      type
      referenceNumber
      status
      ... on FirmBarrister {
        sraNumber
      }
      ... on FirmSolicitor {
        sraNumber
      }
      ... on FirmParalegal {
        paralegalNumber
      }
    }
    firm {
      id
      name
      logoUrl
    }
  }
}
    `;

/**
 * __useAddFirmMemberMutation__
 *
 * To run a mutation, you first call `useAddFirmMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFirmMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFirmMemberMutation, { data, loading, error }] = useAddFirmMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFirmMemberMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddFirmMemberMutation, AddFirmMemberMutationVariables>) {
        return ApolloReactHooks.useMutation<AddFirmMemberMutation, AddFirmMemberMutationVariables>(AddFirmMemberDocument, baseOptions);
      }
export type AddFirmMemberMutationHookResult = ReturnType<typeof useAddFirmMemberMutation>;
export type AddFirmMemberMutationResult = ApolloReactCommon.MutationResult<AddFirmMemberMutation>;
export type AddFirmMemberMutationOptions = ApolloReactCommon.BaseMutationOptions<AddFirmMemberMutation, AddFirmMemberMutationVariables>;
export const UpdateFirmMemberDocument = gql`
    mutation UpdateFirmMember($input: UpdateFirmMemberInput!) {
  updateFirmMember(input: $input) {
    id
    role
    employeeNumber
    jobTitle
    office
    isActive
    isHighlighted
    activeSince
    status
    professional {
      id
      preferredName
      firstName
      middleNames
      lastName
      workPhoneNumber
      emailAddress
      pictureUrl
      type
      referenceNumber
      status
      ... on FirmBarrister {
        sraNumber
      }
      ... on FirmSolicitor {
        sraNumber
      }
      ... on FirmParalegal {
        paralegalNumber
      }
    }
    firm {
      id
      name
      logoUrl
    }
  }
}
    `;

/**
 * __useUpdateFirmMemberMutation__
 *
 * To run a mutation, you first call `useUpdateFirmMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFirmMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFirmMemberMutation, { data, loading, error }] = useUpdateFirmMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFirmMemberMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateFirmMemberMutation, UpdateFirmMemberMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateFirmMemberMutation, UpdateFirmMemberMutationVariables>(UpdateFirmMemberDocument, baseOptions);
      }
export type UpdateFirmMemberMutationHookResult = ReturnType<typeof useUpdateFirmMemberMutation>;
export type UpdateFirmMemberMutationResult = ApolloReactCommon.MutationResult<UpdateFirmMemberMutation>;
export type UpdateFirmMemberMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateFirmMemberMutation, UpdateFirmMemberMutationVariables>;
export const UpdateProfessionalDocument = gql`
    mutation UpdateProfessional($input: UpdateProfessionalInput!) {
  updateProfessional(input: $input) {
    id
    preferredName
    firstName
    middleNames
    lastName
    workPhoneNumber
    emailAddress
    pictureUrl
    type
    referenceNumber
    status
    ... on FirmBarrister {
      sraNumber
    }
    ... on FirmSolicitor {
      sraNumber
    }
    ... on FirmParalegal {
      paralegalNumber
    }
  }
}
    `;

/**
 * __useUpdateProfessionalMutation__
 *
 * To run a mutation, you first call `useUpdateProfessionalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfessionalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfessionalMutation, { data, loading, error }] = useUpdateProfessionalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfessionalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProfessionalMutation, UpdateProfessionalMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProfessionalMutation, UpdateProfessionalMutationVariables>(UpdateProfessionalDocument, baseOptions);
      }
export type UpdateProfessionalMutationHookResult = ReturnType<typeof useUpdateProfessionalMutation>;
export type UpdateProfessionalMutationResult = ApolloReactCommon.MutationResult<UpdateProfessionalMutation>;
export type UpdateProfessionalMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProfessionalMutation, UpdateProfessionalMutationVariables>;
export const ChangeFirmMemberStatusDocument = gql`
    mutation ChangeFirmMemberStatus($input: ChangeFirmMemberStatusInput!) {
  changeFirmMemberStatus(input: $input) {
    id
    role
    employeeNumber
    jobTitle
    office
    isActive
    isHighlighted
    activeSince
    status
    professional {
      id
      preferredName
      firstName
      middleNames
      lastName
      workPhoneNumber
      emailAddress
      pictureUrl
      type
      referenceNumber
      status
      ... on FirmBarrister {
        sraNumber
      }
      ... on FirmSolicitor {
        sraNumber
      }
      ... on FirmParalegal {
        paralegalNumber
      }
    }
    firm {
      id
      name
      logoUrl
    }
  }
}
    `;

/**
 * __useChangeFirmMemberStatusMutation__
 *
 * To run a mutation, you first call `useChangeFirmMemberStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeFirmMemberStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeFirmMemberStatusMutation, { data, loading, error }] = useChangeFirmMemberStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeFirmMemberStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeFirmMemberStatusMutation, ChangeFirmMemberStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeFirmMemberStatusMutation, ChangeFirmMemberStatusMutationVariables>(ChangeFirmMemberStatusDocument, baseOptions);
      }
export type ChangeFirmMemberStatusMutationHookResult = ReturnType<typeof useChangeFirmMemberStatusMutation>;
export type ChangeFirmMemberStatusMutationResult = ApolloReactCommon.MutationResult<ChangeFirmMemberStatusMutation>;
export type ChangeFirmMemberStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeFirmMemberStatusMutation, ChangeFirmMemberStatusMutationVariables>;
export const UpdateFirmDetailsDocument = gql`
    mutation UpdateFirmDetails($input: UpdateFirmDetailsInput!) {
  updateFirmDetails(input: $input) {
    id
    sraNumber
    name
    logoUrl
  }
}
    `;

/**
 * __useUpdateFirmDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateFirmDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFirmDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFirmDetailsMutation, { data, loading, error }] = useUpdateFirmDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFirmDetailsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateFirmDetailsMutation, UpdateFirmDetailsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateFirmDetailsMutation, UpdateFirmDetailsMutationVariables>(UpdateFirmDetailsDocument, baseOptions);
      }
export type UpdateFirmDetailsMutationHookResult = ReturnType<typeof useUpdateFirmDetailsMutation>;
export type UpdateFirmDetailsMutationResult = ApolloReactCommon.MutationResult<UpdateFirmDetailsMutation>;
export type UpdateFirmDetailsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateFirmDetailsMutation, UpdateFirmDetailsMutationVariables>;
export const ResendProfessionalInviteDocument = gql`
    mutation ResendProfessionalInvite($input: ResendProfessionalInviteInput!) {
  resendProfessionalInvite(input: $input) {
    id
  }
}
    `;

/**
 * __useResendProfessionalInviteMutation__
 *
 * To run a mutation, you first call `useResendProfessionalInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendProfessionalInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendProfessionalInviteMutation, { data, loading, error }] = useResendProfessionalInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendProfessionalInviteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResendProfessionalInviteMutation, ResendProfessionalInviteMutationVariables>) {
        return ApolloReactHooks.useMutation<ResendProfessionalInviteMutation, ResendProfessionalInviteMutationVariables>(ResendProfessionalInviteDocument, baseOptions);
      }
export type ResendProfessionalInviteMutationHookResult = ReturnType<typeof useResendProfessionalInviteMutation>;
export type ResendProfessionalInviteMutationResult = ApolloReactCommon.MutationResult<ResendProfessionalInviteMutation>;
export type ResendProfessionalInviteMutationOptions = ApolloReactCommon.BaseMutationOptions<ResendProfessionalInviteMutation, ResendProfessionalInviteMutationVariables>;
export const AddFileDocument = gql`
    mutation AddFile($folderId: ID!, $asset: Upload!) {
  addFile(folderId: $folderId, asset: $asset) {
    id
    name
    type
    mime
    size
    version
    lastRevisedOn
    currentRevisionId
    revisions {
      id
      version
      originalName
      type
      mime
      size
      revisedOn
    }
    topics {
      id
      name
      colour
    }
  }
}
    `;

/**
 * __useAddFileMutation__
 *
 * To run a mutation, you first call `useAddFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFileMutation, { data, loading, error }] = useAddFileMutation({
 *   variables: {
 *      folderId: // value for 'folderId'
 *      asset: // value for 'asset'
 *   },
 * });
 */
export function useAddFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddFileMutation, AddFileMutationVariables>) {
        return ApolloReactHooks.useMutation<AddFileMutation, AddFileMutationVariables>(AddFileDocument, baseOptions);
      }
export type AddFileMutationHookResult = ReturnType<typeof useAddFileMutation>;
export type AddFileMutationResult = ApolloReactCommon.MutationResult<AddFileMutation>;
export type AddFileMutationOptions = ApolloReactCommon.BaseMutationOptions<AddFileMutation, AddFileMutationVariables>;
export const AddFilesDocument = gql`
    mutation AddFiles($assets: [Upload!]!, $folderId: ID!) {
  addFiles(folderId: $folderId, assets: $assets) {
    id
    name
    type
    mime
    size
    version
    lastRevisedOn
    currentRevisionId
    revisions {
      id
      version
      originalName
      type
      mime
      size
      revisedOn
    }
    topics {
      id
      name
      colour
    }
  }
}
    `;

/**
 * __useAddFilesMutation__
 *
 * To run a mutation, you first call `useAddFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFilesMutation, { data, loading, error }] = useAddFilesMutation({
 *   variables: {
 *      assets: // value for 'assets'
 *      folderId: // value for 'folderId'
 *   },
 * });
 */
export function useAddFilesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddFilesMutation, AddFilesMutationVariables>) {
        return ApolloReactHooks.useMutation<AddFilesMutation, AddFilesMutationVariables>(AddFilesDocument, baseOptions);
      }
export type AddFilesMutationHookResult = ReturnType<typeof useAddFilesMutation>;
export type AddFilesMutationResult = ApolloReactCommon.MutationResult<AddFilesMutation>;
export type AddFilesMutationOptions = ApolloReactCommon.BaseMutationOptions<AddFilesMutation, AddFilesMutationVariables>;
export const AddFolderDocument = gql`
    mutation AddFolder($input: AddFolderInput!) {
  addFolder(input: $input) {
    id
    name
    isRestricted
    owner {
      preferredName
      firstName
      lastName
    }
    parent {
      id
      name
      isRestricted
    }
  }
}
    `;

/**
 * __useAddFolderMutation__
 *
 * To run a mutation, you first call `useAddFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFolderMutation, { data, loading, error }] = useAddFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFolderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddFolderMutation, AddFolderMutationVariables>) {
        return ApolloReactHooks.useMutation<AddFolderMutation, AddFolderMutationVariables>(AddFolderDocument, baseOptions);
      }
export type AddFolderMutationHookResult = ReturnType<typeof useAddFolderMutation>;
export type AddFolderMutationResult = ApolloReactCommon.MutationResult<AddFolderMutation>;
export type AddFolderMutationOptions = ApolloReactCommon.BaseMutationOptions<AddFolderMutation, AddFolderMutationVariables>;
export const ReviseFileDocument = gql`
    mutation ReviseFile($fileId: ID!, $asset: Upload!) {
  reviseFile(fileId: $fileId, asset: $asset) {
    id
    name
    type
    mime
    size
    version
    lastRevisedOn
    currentRevisionId
    revisions {
      id
      version
      originalName
      type
      mime
      size
      revisedOn
    }
    topics {
      id
      name
      colour
    }
  }
}
    `;

/**
 * __useReviseFileMutation__
 *
 * To run a mutation, you first call `useReviseFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviseFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviseFileMutation, { data, loading, error }] = useReviseFileMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *      asset: // value for 'asset'
 *   },
 * });
 */
export function useReviseFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviseFileMutation, ReviseFileMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviseFileMutation, ReviseFileMutationVariables>(ReviseFileDocument, baseOptions);
      }
export type ReviseFileMutationHookResult = ReturnType<typeof useReviseFileMutation>;
export type ReviseFileMutationResult = ApolloReactCommon.MutationResult<ReviseFileMutation>;
export type ReviseFileMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviseFileMutation, ReviseFileMutationVariables>;
export const MoveFileDocument = gql`
    mutation MoveFile($input: MoveFileInput!) {
  moveFile(input: $input) {
    id
    name
    folder {
      id
      name
    }
  }
}
    `;

/**
 * __useMoveFileMutation__
 *
 * To run a mutation, you first call `useMoveFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveFileMutation, { data, loading, error }] = useMoveFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MoveFileMutation, MoveFileMutationVariables>) {
        return ApolloReactHooks.useMutation<MoveFileMutation, MoveFileMutationVariables>(MoveFileDocument, baseOptions);
      }
export type MoveFileMutationHookResult = ReturnType<typeof useMoveFileMutation>;
export type MoveFileMutationResult = ApolloReactCommon.MutationResult<MoveFileMutation>;
export type MoveFileMutationOptions = ApolloReactCommon.BaseMutationOptions<MoveFileMutation, MoveFileMutationVariables>;
export const RenameFolderDocument = gql`
    mutation RenameFolder($input: RenameFolderInput!) {
  renameFolder(input: $input) {
    id
    name
    fileCount
    parent {
      id
      name
    }
  }
}
    `;

/**
 * __useRenameFolderMutation__
 *
 * To run a mutation, you first call `useRenameFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameFolderMutation, { data, loading, error }] = useRenameFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRenameFolderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RenameFolderMutation, RenameFolderMutationVariables>) {
        return ApolloReactHooks.useMutation<RenameFolderMutation, RenameFolderMutationVariables>(RenameFolderDocument, baseOptions);
      }
export type RenameFolderMutationHookResult = ReturnType<typeof useRenameFolderMutation>;
export type RenameFolderMutationResult = ApolloReactCommon.MutationResult<RenameFolderMutation>;
export type RenameFolderMutationOptions = ApolloReactCommon.BaseMutationOptions<RenameFolderMutation, RenameFolderMutationVariables>;
export const RenameFileDocument = gql`
    mutation RenameFile($input: RenameFileInput!) {
  renameFile(input: $input) {
    id
    name
    type
    mime
    folder {
      id
      name
    }
  }
}
    `;

/**
 * __useRenameFileMutation__
 *
 * To run a mutation, you first call `useRenameFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameFileMutation, { data, loading, error }] = useRenameFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRenameFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RenameFileMutation, RenameFileMutationVariables>) {
        return ApolloReactHooks.useMutation<RenameFileMutation, RenameFileMutationVariables>(RenameFileDocument, baseOptions);
      }
export type RenameFileMutationHookResult = ReturnType<typeof useRenameFileMutation>;
export type RenameFileMutationResult = ApolloReactCommon.MutationResult<RenameFileMutation>;
export type RenameFileMutationOptions = ApolloReactCommon.BaseMutationOptions<RenameFileMutation, RenameFileMutationVariables>;
export const MoveFolderDocument = gql`
    mutation MoveFolder($input: MoveFolderInput!) {
  moveFolder(input: $input) {
    id
    name
    parent {
      id
      name
    }
  }
}
    `;

/**
 * __useMoveFolderMutation__
 *
 * To run a mutation, you first call `useMoveFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveFolderMutation, { data, loading, error }] = useMoveFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveFolderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MoveFolderMutation, MoveFolderMutationVariables>) {
        return ApolloReactHooks.useMutation<MoveFolderMutation, MoveFolderMutationVariables>(MoveFolderDocument, baseOptions);
      }
export type MoveFolderMutationHookResult = ReturnType<typeof useMoveFolderMutation>;
export type MoveFolderMutationResult = ApolloReactCommon.MutationResult<MoveFolderMutation>;
export type MoveFolderMutationOptions = ApolloReactCommon.BaseMutationOptions<MoveFolderMutation, MoveFolderMutationVariables>;
export const RemoveFileDocument = gql`
    mutation RemoveFile($input: RemoveFileInput!) {
  removeFile(input: $input) {
    fileId
    removedOn
  }
}
    `;

/**
 * __useRemoveFileMutation__
 *
 * To run a mutation, you first call `useRemoveFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFileMutation, { data, loading, error }] = useRemoveFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveFileMutation, RemoveFileMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveFileMutation, RemoveFileMutationVariables>(RemoveFileDocument, baseOptions);
      }
export type RemoveFileMutationHookResult = ReturnType<typeof useRemoveFileMutation>;
export type RemoveFileMutationResult = ApolloReactCommon.MutationResult<RemoveFileMutation>;
export type RemoveFileMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveFileMutation, RemoveFileMutationVariables>;
export const RemoveFolderDocument = gql`
    mutation RemoveFolder($input: RemoveFolderInput!) {
  removeFolder(input: $input) {
    folderId
    removedOn
  }
}
    `;

/**
 * __useRemoveFolderMutation__
 *
 * To run a mutation, you first call `useRemoveFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFolderMutation, { data, loading, error }] = useRemoveFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveFolderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveFolderMutation, RemoveFolderMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveFolderMutation, RemoveFolderMutationVariables>(RemoveFolderDocument, baseOptions);
      }
export type RemoveFolderMutationHookResult = ReturnType<typeof useRemoveFolderMutation>;
export type RemoveFolderMutationResult = ApolloReactCommon.MutationResult<RemoveFolderMutation>;
export type RemoveFolderMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveFolderMutation, RemoveFolderMutationVariables>;
export const AssociateFileWithTopicDocument = gql`
    mutation AssociateFileWithTopic($input: AssociateFileWithTopicInput!) {
  associateFileWithTopic(input: $input) {
    id
    topics {
      id
      name
      colour
    }
  }
}
    `;

/**
 * __useAssociateFileWithTopicMutation__
 *
 * To run a mutation, you first call `useAssociateFileWithTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssociateFileWithTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [associateFileWithTopicMutation, { data, loading, error }] = useAssociateFileWithTopicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssociateFileWithTopicMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssociateFileWithTopicMutation, AssociateFileWithTopicMutationVariables>) {
        return ApolloReactHooks.useMutation<AssociateFileWithTopicMutation, AssociateFileWithTopicMutationVariables>(AssociateFileWithTopicDocument, baseOptions);
      }
export type AssociateFileWithTopicMutationHookResult = ReturnType<typeof useAssociateFileWithTopicMutation>;
export type AssociateFileWithTopicMutationResult = ApolloReactCommon.MutationResult<AssociateFileWithTopicMutation>;
export type AssociateFileWithTopicMutationOptions = ApolloReactCommon.BaseMutationOptions<AssociateFileWithTopicMutation, AssociateFileWithTopicMutationVariables>;
export const DisassociateFileFromTopicDocument = gql`
    mutation DisassociateFileFromTopic($input: DisassociateFileFromTopicInput!) {
  disassociateFileFromTopic(input: $input) {
    id
    topics {
      id
      name
      colour
    }
  }
}
    `;

/**
 * __useDisassociateFileFromTopicMutation__
 *
 * To run a mutation, you first call `useDisassociateFileFromTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisassociateFileFromTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disassociateFileFromTopicMutation, { data, loading, error }] = useDisassociateFileFromTopicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisassociateFileFromTopicMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DisassociateFileFromTopicMutation, DisassociateFileFromTopicMutationVariables>) {
        return ApolloReactHooks.useMutation<DisassociateFileFromTopicMutation, DisassociateFileFromTopicMutationVariables>(DisassociateFileFromTopicDocument, baseOptions);
      }
export type DisassociateFileFromTopicMutationHookResult = ReturnType<typeof useDisassociateFileFromTopicMutation>;
export type DisassociateFileFromTopicMutationResult = ApolloReactCommon.MutationResult<DisassociateFileFromTopicMutation>;
export type DisassociateFileFromTopicMutationOptions = ApolloReactCommon.BaseMutationOptions<DisassociateFileFromTopicMutation, DisassociateFileFromTopicMutationVariables>;
export const ClearHighlightDocument = gql`
    mutation ClearHighlight($input: ClearHighlightInput!) {
  clearHighlight(input: $input) {
    highlightableId
    clearedOn
  }
}
    `;

/**
 * __useClearHighlightMutation__
 *
 * To run a mutation, you first call `useClearHighlightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearHighlightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearHighlightMutation, { data, loading, error }] = useClearHighlightMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClearHighlightMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClearHighlightMutation, ClearHighlightMutationVariables>) {
        return ApolloReactHooks.useMutation<ClearHighlightMutation, ClearHighlightMutationVariables>(ClearHighlightDocument, baseOptions);
      }
export type ClearHighlightMutationHookResult = ReturnType<typeof useClearHighlightMutation>;
export type ClearHighlightMutationResult = ApolloReactCommon.MutationResult<ClearHighlightMutation>;
export type ClearHighlightMutationOptions = ApolloReactCommon.BaseMutationOptions<ClearHighlightMutation, ClearHighlightMutationVariables>;
export const ClearHighlightsDocument = gql`
    mutation ClearHighlights($input: ClearHighlightsInput!) {
  clearHighlights(input: $input) {
    highlightableIds
    clearedOn
  }
}
    `;

/**
 * __useClearHighlightsMutation__
 *
 * To run a mutation, you first call `useClearHighlightsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearHighlightsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearHighlightsMutation, { data, loading, error }] = useClearHighlightsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClearHighlightsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClearHighlightsMutation, ClearHighlightsMutationVariables>) {
        return ApolloReactHooks.useMutation<ClearHighlightsMutation, ClearHighlightsMutationVariables>(ClearHighlightsDocument, baseOptions);
      }
export type ClearHighlightsMutationHookResult = ReturnType<typeof useClearHighlightsMutation>;
export type ClearHighlightsMutationResult = ApolloReactCommon.MutationResult<ClearHighlightsMutation>;
export type ClearHighlightsMutationOptions = ApolloReactCommon.BaseMutationOptions<ClearHighlightsMutation, ClearHighlightsMutationVariables>;
export const StartIssueDocument = gql`
    mutation StartIssue($input: StartIssueInput!) {
  startIssue(input: $input) {
    id
    type
    name
    opponent {
      preferredName
      firstName
      lastName
      emailAddress
      nationalInsuranceNumber
      clientReferenceNumber
      isVerified
    }
    opponentIssue {
      id
      type
      name
    }
  }
}
    `;

/**
 * __useStartIssueMutation__
 *
 * To run a mutation, you first call `useStartIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startIssueMutation, { data, loading, error }] = useStartIssueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartIssueMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StartIssueMutation, StartIssueMutationVariables>) {
        return ApolloReactHooks.useMutation<StartIssueMutation, StartIssueMutationVariables>(StartIssueDocument, baseOptions);
      }
export type StartIssueMutationHookResult = ReturnType<typeof useStartIssueMutation>;
export type StartIssueMutationResult = ApolloReactCommon.MutationResult<StartIssueMutation>;
export type StartIssueMutationOptions = ApolloReactCommon.BaseMutationOptions<StartIssueMutation, StartIssueMutationVariables>;
export const IdentifyOpponentDocument = gql`
    mutation IdentifyOpponent($input: IdentifyOpponentInput!) {
  identifyOpponent(input: $input) {
    id
    type
    isLinked
    linkCode
    linkRequested
    opponentIssue {
      id
      legalTeam {
        id
        jobTitle
        isLead
        firm {
          id
          name
        }
        professional {
          id
          emailAddress
          preferredName
          lastName
          type
        }
      }
    }
    opponent {
      preferredName
      firstName
      lastName
      emailAddress
      nationalInsuranceNumber
      clientReferenceNumber
      isVerified
      solicitor {
        firstName
        lastName
        emailAddress
        firmName
      }
    }
  }
}
    `;

/**
 * __useIdentifyOpponentMutation__
 *
 * To run a mutation, you first call `useIdentifyOpponentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIdentifyOpponentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [identifyOpponentMutation, { data, loading, error }] = useIdentifyOpponentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIdentifyOpponentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IdentifyOpponentMutation, IdentifyOpponentMutationVariables>) {
        return ApolloReactHooks.useMutation<IdentifyOpponentMutation, IdentifyOpponentMutationVariables>(IdentifyOpponentDocument, baseOptions);
      }
export type IdentifyOpponentMutationHookResult = ReturnType<typeof useIdentifyOpponentMutation>;
export type IdentifyOpponentMutationResult = ApolloReactCommon.MutationResult<IdentifyOpponentMutation>;
export type IdentifyOpponentMutationOptions = ApolloReactCommon.BaseMutationOptions<IdentifyOpponentMutation, IdentifyOpponentMutationVariables>;
export const IdentifyOpponentSolicitorDocument = gql`
    mutation IdentifyOpponentSolicitor($input: IdentifyOpponentSolicitorInput!) {
  identifyOpponentSolicitor(input: $input) {
    id
    type
    isLinked
    linkCode
    linkRequested
    opponentIssue {
      id
      legalTeam {
        id
        jobTitle
        isLead
        firm {
          id
          name
        }
        professional {
          id
          emailAddress
          preferredName
          lastName
          type
        }
      }
    }
    opponent {
      preferredName
      firstName
      lastName
      emailAddress
      nationalInsuranceNumber
      clientReferenceNumber
      isVerified
      solicitor {
        firstName
        lastName
        emailAddress
        firmName
      }
    }
  }
}
    `;

/**
 * __useIdentifyOpponentSolicitorMutation__
 *
 * To run a mutation, you first call `useIdentifyOpponentSolicitorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIdentifyOpponentSolicitorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [identifyOpponentSolicitorMutation, { data, loading, error }] = useIdentifyOpponentSolicitorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIdentifyOpponentSolicitorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IdentifyOpponentSolicitorMutation, IdentifyOpponentSolicitorMutationVariables>) {
        return ApolloReactHooks.useMutation<IdentifyOpponentSolicitorMutation, IdentifyOpponentSolicitorMutationVariables>(IdentifyOpponentSolicitorDocument, baseOptions);
      }
export type IdentifyOpponentSolicitorMutationHookResult = ReturnType<typeof useIdentifyOpponentSolicitorMutation>;
export type IdentifyOpponentSolicitorMutationResult = ApolloReactCommon.MutationResult<IdentifyOpponentSolicitorMutation>;
export type IdentifyOpponentSolicitorMutationOptions = ApolloReactCommon.BaseMutationOptions<IdentifyOpponentSolicitorMutation, IdentifyOpponentSolicitorMutationVariables>;
export const RequestOpponentConnectionDocument = gql`
    mutation RequestOpponentConnection($input: RequestOpponentConnectionInput!) {
  requestOpponentConnection(input: $input) {
    id
    linkCode
    linkRequested
  }
}
    `;

/**
 * __useRequestOpponentConnectionMutation__
 *
 * To run a mutation, you first call `useRequestOpponentConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestOpponentConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestOpponentConnectionMutation, { data, loading, error }] = useRequestOpponentConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestOpponentConnectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RequestOpponentConnectionMutation, RequestOpponentConnectionMutationVariables>) {
        return ApolloReactHooks.useMutation<RequestOpponentConnectionMutation, RequestOpponentConnectionMutationVariables>(RequestOpponentConnectionDocument, baseOptions);
      }
export type RequestOpponentConnectionMutationHookResult = ReturnType<typeof useRequestOpponentConnectionMutation>;
export type RequestOpponentConnectionMutationResult = ApolloReactCommon.MutationResult<RequestOpponentConnectionMutation>;
export type RequestOpponentConnectionMutationOptions = ApolloReactCommon.BaseMutationOptions<RequestOpponentConnectionMutation, RequestOpponentConnectionMutationVariables>;
export const LinkIssueDocument = gql`
    mutation LinkIssue($input: LinkIssueInput!) {
  linkIssue(input: $input) {
    id
    type
    opponent {
      preferredName
      firstName
      lastName
      emailAddress
      nationalInsuranceNumber
      clientReferenceNumber
      isVerified
    }
    opponentIssue {
      id
      type
    }
  }
}
    `;

/**
 * __useLinkIssueMutation__
 *
 * To run a mutation, you first call `useLinkIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkIssueMutation, { data, loading, error }] = useLinkIssueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkIssueMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LinkIssueMutation, LinkIssueMutationVariables>) {
        return ApolloReactHooks.useMutation<LinkIssueMutation, LinkIssueMutationVariables>(LinkIssueDocument, baseOptions);
      }
export type LinkIssueMutationHookResult = ReturnType<typeof useLinkIssueMutation>;
export type LinkIssueMutationResult = ApolloReactCommon.MutationResult<LinkIssueMutation>;
export type LinkIssueMutationOptions = ApolloReactCommon.BaseMutationOptions<LinkIssueMutation, LinkIssueMutationVariables>;
export const LoginWithPasswordDocument = gql`
    mutation LoginWithPassword($input: LoginWithPasswordInput!) {
  loginWithPassword(input: $input) {
    __typename
    successful
    message
    ... on SuccessfulAuthenticationPayload {
      accessToken
      idToken
      expiresIn
      scope
      user {
        id
        givenName
        middleName
        familyName
        picture
        email
        phone
        roles {
          id
          name
          description
        }
      }
    }
  }
}
    `;

/**
 * __useLoginWithPasswordMutation__
 *
 * To run a mutation, you first call `useLoginWithPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithPasswordMutation, { data, loading, error }] = useLoginWithPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginWithPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginWithPasswordMutation, LoginWithPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginWithPasswordMutation, LoginWithPasswordMutationVariables>(LoginWithPasswordDocument, baseOptions);
      }
export type LoginWithPasswordMutationHookResult = ReturnType<typeof useLoginWithPasswordMutation>;
export type LoginWithPasswordMutationResult = ApolloReactCommon.MutationResult<LoginWithPasswordMutation>;
export type LoginWithPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginWithPasswordMutation, LoginWithPasswordMutationVariables>;
export const StartMatterDocument = gql`
    mutation StartMatter($input: StartMatterInput!) {
  startMatter(input: $input) {
    id
    type
    status
    advisories
    advisoryCount
    isHighlighted
    isRemovable
    clientAccount {
      id
      referenceNumber
      client {
        id
        preferredName
        firstName
        lastName
        pictureUrl
      }
    }
    leadProfessional {
      id
      preferredName
      firstName
      lastName
      pictureUrl
    }
    startedAt
    endedAt
  }
}
    `;

/**
 * __useStartMatterMutation__
 *
 * To run a mutation, you first call `useStartMatterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartMatterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startMatterMutation, { data, loading, error }] = useStartMatterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartMatterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StartMatterMutation, StartMatterMutationVariables>) {
        return ApolloReactHooks.useMutation<StartMatterMutation, StartMatterMutationVariables>(StartMatterDocument, baseOptions);
      }
export type StartMatterMutationHookResult = ReturnType<typeof useStartMatterMutation>;
export type StartMatterMutationResult = ApolloReactCommon.MutationResult<StartMatterMutation>;
export type StartMatterMutationOptions = ApolloReactCommon.BaseMutationOptions<StartMatterMutation, StartMatterMutationVariables>;
export const RemoveMatterDocument = gql`
    mutation RemoveMatter($input: RemoveMatterInput!) {
  removeMatter(input: $input) {
    matterId
  }
}
    `;

/**
 * __useRemoveMatterMutation__
 *
 * To run a mutation, you first call `useRemoveMatterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMatterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMatterMutation, { data, loading, error }] = useRemoveMatterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveMatterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveMatterMutation, RemoveMatterMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveMatterMutation, RemoveMatterMutationVariables>(RemoveMatterDocument, baseOptions);
      }
export type RemoveMatterMutationHookResult = ReturnType<typeof useRemoveMatterMutation>;
export type RemoveMatterMutationResult = ApolloReactCommon.MutationResult<RemoveMatterMutation>;
export type RemoveMatterMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveMatterMutation, RemoveMatterMutationVariables>;
export const AddKeyDateDocument = gql`
    mutation AddKeyDate($input: AddKeyDateInput!) {
  addKeyDate(input: $input) {
    id
    date
    isAllDay
    name
    description
  }
}
    `;

/**
 * __useAddKeyDateMutation__
 *
 * To run a mutation, you first call `useAddKeyDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddKeyDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addKeyDateMutation, { data, loading, error }] = useAddKeyDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddKeyDateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddKeyDateMutation, AddKeyDateMutationVariables>) {
        return ApolloReactHooks.useMutation<AddKeyDateMutation, AddKeyDateMutationVariables>(AddKeyDateDocument, baseOptions);
      }
export type AddKeyDateMutationHookResult = ReturnType<typeof useAddKeyDateMutation>;
export type AddKeyDateMutationResult = ApolloReactCommon.MutationResult<AddKeyDateMutation>;
export type AddKeyDateMutationOptions = ApolloReactCommon.BaseMutationOptions<AddKeyDateMutation, AddKeyDateMutationVariables>;
export const ChangeMatterLeadDocument = gql`
    mutation ChangeMatterLead($input: ChangeMatterLeadInput!) {
  changeMatterLead(input: $input) {
    id
    leadProfessional {
      id
      preferredName
      firstName
      lastName
      pictureUrl
    }
  }
}
    `;

/**
 * __useChangeMatterLeadMutation__
 *
 * To run a mutation, you first call `useChangeMatterLeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeMatterLeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeMatterLeadMutation, { data, loading, error }] = useChangeMatterLeadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeMatterLeadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeMatterLeadMutation, ChangeMatterLeadMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeMatterLeadMutation, ChangeMatterLeadMutationVariables>(ChangeMatterLeadDocument, baseOptions);
      }
export type ChangeMatterLeadMutationHookResult = ReturnType<typeof useChangeMatterLeadMutation>;
export type ChangeMatterLeadMutationResult = ApolloReactCommon.MutationResult<ChangeMatterLeadMutation>;
export type ChangeMatterLeadMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeMatterLeadMutation, ChangeMatterLeadMutationVariables>;
export const ModifyKeyDateDocument = gql`
    mutation ModifyKeyDate($input: ModifyKeyDateInput!) {
  modifyKeyDate(input: $input) {
    id
    date
    isAllDay
    name
    description
  }
}
    `;

/**
 * __useModifyKeyDateMutation__
 *
 * To run a mutation, you first call `useModifyKeyDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyKeyDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyKeyDateMutation, { data, loading, error }] = useModifyKeyDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyKeyDateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ModifyKeyDateMutation, ModifyKeyDateMutationVariables>) {
        return ApolloReactHooks.useMutation<ModifyKeyDateMutation, ModifyKeyDateMutationVariables>(ModifyKeyDateDocument, baseOptions);
      }
export type ModifyKeyDateMutationHookResult = ReturnType<typeof useModifyKeyDateMutation>;
export type ModifyKeyDateMutationResult = ApolloReactCommon.MutationResult<ModifyKeyDateMutation>;
export type ModifyKeyDateMutationOptions = ApolloReactCommon.BaseMutationOptions<ModifyKeyDateMutation, ModifyKeyDateMutationVariables>;
export const RemoveKeyDateDocument = gql`
    mutation RemoveKeyDate($input: RemoveKeyDateInput!) {
  removeKeyDate(input: $input) {
    keyDateId
    removedOn
  }
}
    `;

/**
 * __useRemoveKeyDateMutation__
 *
 * To run a mutation, you first call `useRemoveKeyDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveKeyDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeKeyDateMutation, { data, loading, error }] = useRemoveKeyDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveKeyDateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveKeyDateMutation, RemoveKeyDateMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveKeyDateMutation, RemoveKeyDateMutationVariables>(RemoveKeyDateDocument, baseOptions);
      }
export type RemoveKeyDateMutationHookResult = ReturnType<typeof useRemoveKeyDateMutation>;
export type RemoveKeyDateMutationResult = ApolloReactCommon.MutationResult<RemoveKeyDateMutation>;
export type RemoveKeyDateMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveKeyDateMutation, RemoveKeyDateMutationVariables>;
export const PrepareCourtCaseDocument = gql`
    mutation PrepareCourtCase($input: PrepareCourtCaseInput!) {
  prepareCourtCase(input: $input) {
    id
    courtName
    caseNumber
    hearingDate {
      id
      date
      isAllDay
      name
      description
    }
    status
  }
}
    `;

/**
 * __usePrepareCourtCaseMutation__
 *
 * To run a mutation, you first call `usePrepareCourtCaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrepareCourtCaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prepareCourtCaseMutation, { data, loading, error }] = usePrepareCourtCaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrepareCourtCaseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PrepareCourtCaseMutation, PrepareCourtCaseMutationVariables>) {
        return ApolloReactHooks.useMutation<PrepareCourtCaseMutation, PrepareCourtCaseMutationVariables>(PrepareCourtCaseDocument, baseOptions);
      }
export type PrepareCourtCaseMutationHookResult = ReturnType<typeof usePrepareCourtCaseMutation>;
export type PrepareCourtCaseMutationResult = ApolloReactCommon.MutationResult<PrepareCourtCaseMutation>;
export type PrepareCourtCaseMutationOptions = ApolloReactCommon.BaseMutationOptions<PrepareCourtCaseMutation, PrepareCourtCaseMutationVariables>;
export const UpdateCourtCaseDocument = gql`
    mutation UpdateCourtCase($input: UpdateCourtCaseInput!) {
  updateCourtCase(input: $input) {
    id
    courtName
    caseNumber
    hearingDate {
      id
      date
      isAllDay
      name
      description
    }
    status
  }
}
    `;

/**
 * __useUpdateCourtCaseMutation__
 *
 * To run a mutation, you first call `useUpdateCourtCaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourtCaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourtCaseMutation, { data, loading, error }] = useUpdateCourtCaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCourtCaseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCourtCaseMutation, UpdateCourtCaseMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCourtCaseMutation, UpdateCourtCaseMutationVariables>(UpdateCourtCaseDocument, baseOptions);
      }
export type UpdateCourtCaseMutationHookResult = ReturnType<typeof useUpdateCourtCaseMutation>;
export type UpdateCourtCaseMutationResult = ApolloReactCommon.MutationResult<UpdateCourtCaseMutation>;
export type UpdateCourtCaseMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCourtCaseMutation, UpdateCourtCaseMutationVariables>;
export const MakeConversationExtractDocument = gql`
    mutation MakeConversationExtract($input: MakeConversationExtractInput!) {
  makeConversationExtract(input: $input) {
    id
    name
    messageFilters {
      conversationId
      isUnread
      isVisible
      hasAttachments
      sentAfter
      sentBefore
      senderIds
    }
  }
}
    `;

/**
 * __useMakeConversationExtractMutation__
 *
 * To run a mutation, you first call `useMakeConversationExtractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeConversationExtractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeConversationExtractMutation, { data, loading, error }] = useMakeConversationExtractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMakeConversationExtractMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MakeConversationExtractMutation, MakeConversationExtractMutationVariables>) {
        return ApolloReactHooks.useMutation<MakeConversationExtractMutation, MakeConversationExtractMutationVariables>(MakeConversationExtractDocument, baseOptions);
      }
export type MakeConversationExtractMutationHookResult = ReturnType<typeof useMakeConversationExtractMutation>;
export type MakeConversationExtractMutationResult = ApolloReactCommon.MutationResult<MakeConversationExtractMutation>;
export type MakeConversationExtractMutationOptions = ApolloReactCommon.BaseMutationOptions<MakeConversationExtractMutation, MakeConversationExtractMutationVariables>;
export const DiscardConversationExtractDocument = gql`
    mutation DiscardConversationExtract($input: DiscardConversationExtractInput!) {
  discardConversationExtract(input: $input) {
    conversationExtractId
    discardedOn
  }
}
    `;

/**
 * __useDiscardConversationExtractMutation__
 *
 * To run a mutation, you first call `useDiscardConversationExtractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDiscardConversationExtractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [discardConversationExtractMutation, { data, loading, error }] = useDiscardConversationExtractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDiscardConversationExtractMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DiscardConversationExtractMutation, DiscardConversationExtractMutationVariables>) {
        return ApolloReactHooks.useMutation<DiscardConversationExtractMutation, DiscardConversationExtractMutationVariables>(DiscardConversationExtractDocument, baseOptions);
      }
export type DiscardConversationExtractMutationHookResult = ReturnType<typeof useDiscardConversationExtractMutation>;
export type DiscardConversationExtractMutationResult = ApolloReactCommon.MutationResult<DiscardConversationExtractMutation>;
export type DiscardConversationExtractMutationOptions = ApolloReactCommon.BaseMutationOptions<DiscardConversationExtractMutation, DiscardConversationExtractMutationVariables>;
export const AddSupportingFileDocument = gql`
    mutation AddSupportingFile($input: AddSupportingFileInput!) {
  addSupportingFile(input: $input) {
    id
    source
    name
    fileId
    fileVersion
    size
    type
    mime
    ... on SupportingImage {
      height
      width
    }
  }
}
    `;

/**
 * __useAddSupportingFileMutation__
 *
 * To run a mutation, you first call `useAddSupportingFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSupportingFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSupportingFileMutation, { data, loading, error }] = useAddSupportingFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSupportingFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddSupportingFileMutation, AddSupportingFileMutationVariables>) {
        return ApolloReactHooks.useMutation<AddSupportingFileMutation, AddSupportingFileMutationVariables>(AddSupportingFileDocument, baseOptions);
      }
export type AddSupportingFileMutationHookResult = ReturnType<typeof useAddSupportingFileMutation>;
export type AddSupportingFileMutationResult = ApolloReactCommon.MutationResult<AddSupportingFileMutation>;
export type AddSupportingFileMutationOptions = ApolloReactCommon.BaseMutationOptions<AddSupportingFileMutation, AddSupportingFileMutationVariables>;
export const RemoveSupportingFileDocument = gql`
    mutation RemoveSupportingFile($input: RemoveSupportingFileInput!) {
  removeSupportingFile(input: $input) {
    supportingFileId
    removedOn
  }
}
    `;

/**
 * __useRemoveSupportingFileMutation__
 *
 * To run a mutation, you first call `useRemoveSupportingFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSupportingFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSupportingFileMutation, { data, loading, error }] = useRemoveSupportingFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveSupportingFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveSupportingFileMutation, RemoveSupportingFileMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveSupportingFileMutation, RemoveSupportingFileMutationVariables>(RemoveSupportingFileDocument, baseOptions);
      }
export type RemoveSupportingFileMutationHookResult = ReturnType<typeof useRemoveSupportingFileMutation>;
export type RemoveSupportingFileMutationResult = ApolloReactCommon.MutationResult<RemoveSupportingFileMutation>;
export type RemoveSupportingFileMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveSupportingFileMutation, RemoveSupportingFileMutationVariables>;
export const ExportSupportingMaterialDocument = gql`
    mutation ExportSupportingMaterial($input: ExportSupportingMaterialInput!) {
  exportSupportingMaterial(input: $input) {
    matterId
    materialIds
    startedOn
  }
}
    `;

/**
 * __useExportSupportingMaterialMutation__
 *
 * To run a mutation, you first call `useExportSupportingMaterialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportSupportingMaterialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportSupportingMaterialMutation, { data, loading, error }] = useExportSupportingMaterialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportSupportingMaterialMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ExportSupportingMaterialMutation, ExportSupportingMaterialMutationVariables>) {
        return ApolloReactHooks.useMutation<ExportSupportingMaterialMutation, ExportSupportingMaterialMutationVariables>(ExportSupportingMaterialDocument, baseOptions);
      }
export type ExportSupportingMaterialMutationHookResult = ReturnType<typeof useExportSupportingMaterialMutation>;
export type ExportSupportingMaterialMutationResult = ApolloReactCommon.MutationResult<ExportSupportingMaterialMutation>;
export type ExportSupportingMaterialMutationOptions = ApolloReactCommon.BaseMutationOptions<ExportSupportingMaterialMutation, ExportSupportingMaterialMutationVariables>;
export const EndMatterDocument = gql`
    mutation EndMatter($input: EndMatterInput!) {
  endMatter(input: $input) {
    id
    type
    status
    endedAt
  }
}
    `;

/**
 * __useEndMatterMutation__
 *
 * To run a mutation, you first call `useEndMatterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndMatterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endMatterMutation, { data, loading, error }] = useEndMatterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEndMatterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EndMatterMutation, EndMatterMutationVariables>) {
        return ApolloReactHooks.useMutation<EndMatterMutation, EndMatterMutationVariables>(EndMatterDocument, baseOptions);
      }
export type EndMatterMutationHookResult = ReturnType<typeof useEndMatterMutation>;
export type EndMatterMutationResult = ApolloReactCommon.MutationResult<EndMatterMutation>;
export type EndMatterMutationOptions = ApolloReactCommon.BaseMutationOptions<EndMatterMutation, EndMatterMutationVariables>;
export const SendMessageDocument = gql`
    mutation SendMessage($input: ComposeMessageInput!) {
  sendMessage(input: $input) {
    id
    conversationId
    sender {
      id
      preferredName
      firstName
      lastName
      pictureUrl
      role
      channel
      title
      group {
        id
        name
        pictureUrl
      }
    }
    repliedTo {
      id
      body
      sentAt
      sender {
        id
        preferredName
        firstName
        lastName
        pictureUrl
        role
        channel
        title
        group {
          id
          name
          pictureUrl
        }
      }
    }
    copies {
      recipient {
        id
        group {
          id
        }
      }
      deliveredAt
      readAt
    }
    body
    sentAt
    deliveredAt
    readAt
    attachments {
      id
      name
      type
      mime
      size
      ... on AttachedImage {
        height
        width
      }
    }
    topics {
      id
      name
      colour
    }
  }
}
    `;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMessageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendMessageMutation, SendMessageMutationVariables>) {
        return ApolloReactHooks.useMutation<SendMessageMutation, SendMessageMutationVariables>(SendMessageDocument, baseOptions);
      }
export type SendMessageMutationHookResult = ReturnType<typeof useSendMessageMutation>;
export type SendMessageMutationResult = ApolloReactCommon.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<SendMessageMutation, SendMessageMutationVariables>;
export const EditMessageDraftDocument = gql`
    mutation EditMessageDraft($input: ComposeMessageInput!) {
  editMessageDraft(input: $input) {
    id
    body
    updatedAt
  }
}
    `;

/**
 * __useEditMessageDraftMutation__
 *
 * To run a mutation, you first call `useEditMessageDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMessageDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMessageDraftMutation, { data, loading, error }] = useEditMessageDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMessageDraftMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMessageDraftMutation, EditMessageDraftMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMessageDraftMutation, EditMessageDraftMutationVariables>(EditMessageDraftDocument, baseOptions);
      }
export type EditMessageDraftMutationHookResult = ReturnType<typeof useEditMessageDraftMutation>;
export type EditMessageDraftMutationResult = ApolloReactCommon.MutationResult<EditMessageDraftMutation>;
export type EditMessageDraftMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMessageDraftMutation, EditMessageDraftMutationVariables>;
export const MarkMessageDeliveredDocument = gql`
    mutation MarkMessageDelivered($input: MarkMessageDeliveredInput!) {
  markMessageDelivered(input: $input) {
    id
    conversationId
    sender {
      id
      preferredName
      firstName
      lastName
      pictureUrl
      role
      channel
      title
      group {
        id
        name
        pictureUrl
      }
    }
    repliedTo {
      id
      body
      sentAt
      sender {
        id
        preferredName
        firstName
        lastName
        pictureUrl
        role
        channel
        title
        group {
          id
          name
          pictureUrl
        }
      }
    }
    copies {
      recipient {
        id
        group {
          id
        }
      }
      deliveredAt
      readAt
    }
    body
    sentAt
    deliveredAt
    readAt
    attachments {
      id
      name
      type
      mime
      size
      ... on AttachedImage {
        height
        width
      }
    }
    topics {
      id
      name
      colour
    }
  }
}
    `;

/**
 * __useMarkMessageDeliveredMutation__
 *
 * To run a mutation, you first call `useMarkMessageDeliveredMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkMessageDeliveredMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markMessageDeliveredMutation, { data, loading, error }] = useMarkMessageDeliveredMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkMessageDeliveredMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MarkMessageDeliveredMutation, MarkMessageDeliveredMutationVariables>) {
        return ApolloReactHooks.useMutation<MarkMessageDeliveredMutation, MarkMessageDeliveredMutationVariables>(MarkMessageDeliveredDocument, baseOptions);
      }
export type MarkMessageDeliveredMutationHookResult = ReturnType<typeof useMarkMessageDeliveredMutation>;
export type MarkMessageDeliveredMutationResult = ApolloReactCommon.MutationResult<MarkMessageDeliveredMutation>;
export type MarkMessageDeliveredMutationOptions = ApolloReactCommon.BaseMutationOptions<MarkMessageDeliveredMutation, MarkMessageDeliveredMutationVariables>;
export const MarkMessageReadDocument = gql`
    mutation MarkMessageRead($input: MarkMessageReadInput!) {
  markMessageRead(input: $input) {
    id
    conversationId
    sender {
      id
      preferredName
      firstName
      lastName
      pictureUrl
      role
      channel
      title
      group {
        id
        name
        pictureUrl
      }
    }
    repliedTo {
      id
      body
      sentAt
      sender {
        id
        preferredName
        firstName
        lastName
        pictureUrl
        role
        channel
        title
        group {
          id
          name
          pictureUrl
        }
      }
    }
    copies {
      recipient {
        id
        group {
          id
        }
      }
      deliveredAt
      readAt
    }
    body
    sentAt
    deliveredAt
    readAt
    attachments {
      id
      name
      type
      mime
      size
      ... on AttachedImage {
        height
        width
      }
    }
    topics {
      id
      name
      colour
    }
  }
}
    `;

/**
 * __useMarkMessageReadMutation__
 *
 * To run a mutation, you first call `useMarkMessageReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkMessageReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markMessageReadMutation, { data, loading, error }] = useMarkMessageReadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkMessageReadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MarkMessageReadMutation, MarkMessageReadMutationVariables>) {
        return ApolloReactHooks.useMutation<MarkMessageReadMutation, MarkMessageReadMutationVariables>(MarkMessageReadDocument, baseOptions);
      }
export type MarkMessageReadMutationHookResult = ReturnType<typeof useMarkMessageReadMutation>;
export type MarkMessageReadMutationResult = ApolloReactCommon.MutationResult<MarkMessageReadMutation>;
export type MarkMessageReadMutationOptions = ApolloReactCommon.BaseMutationOptions<MarkMessageReadMutation, MarkMessageReadMutationVariables>;
export const AssociateMessageWithTopicDocument = gql`
    mutation AssociateMessageWithTopic($input: AssociateMessageWithTopicInput!) {
  associateMessageWithTopic(input: $input) {
    id
    topics {
      id
      name
      colour
    }
  }
}
    `;

/**
 * __useAssociateMessageWithTopicMutation__
 *
 * To run a mutation, you first call `useAssociateMessageWithTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssociateMessageWithTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [associateMessageWithTopicMutation, { data, loading, error }] = useAssociateMessageWithTopicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssociateMessageWithTopicMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssociateMessageWithTopicMutation, AssociateMessageWithTopicMutationVariables>) {
        return ApolloReactHooks.useMutation<AssociateMessageWithTopicMutation, AssociateMessageWithTopicMutationVariables>(AssociateMessageWithTopicDocument, baseOptions);
      }
export type AssociateMessageWithTopicMutationHookResult = ReturnType<typeof useAssociateMessageWithTopicMutation>;
export type AssociateMessageWithTopicMutationResult = ApolloReactCommon.MutationResult<AssociateMessageWithTopicMutation>;
export type AssociateMessageWithTopicMutationOptions = ApolloReactCommon.BaseMutationOptions<AssociateMessageWithTopicMutation, AssociateMessageWithTopicMutationVariables>;
export const DisassociateMessageFromTopicDocument = gql`
    mutation DisassociateMessageFromTopic($input: DisassociateMessageFromTopicInput!) {
  disassociateMessageFromTopic(input: $input) {
    id
    topics {
      id
      name
      colour
    }
  }
}
    `;

/**
 * __useDisassociateMessageFromTopicMutation__
 *
 * To run a mutation, you first call `useDisassociateMessageFromTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisassociateMessageFromTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disassociateMessageFromTopicMutation, { data, loading, error }] = useDisassociateMessageFromTopicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisassociateMessageFromTopicMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DisassociateMessageFromTopicMutation, DisassociateMessageFromTopicMutationVariables>) {
        return ApolloReactHooks.useMutation<DisassociateMessageFromTopicMutation, DisassociateMessageFromTopicMutationVariables>(DisassociateMessageFromTopicDocument, baseOptions);
      }
export type DisassociateMessageFromTopicMutationHookResult = ReturnType<typeof useDisassociateMessageFromTopicMutation>;
export type DisassociateMessageFromTopicMutationResult = ApolloReactCommon.MutationResult<DisassociateMessageFromTopicMutation>;
export type DisassociateMessageFromTopicMutationOptions = ApolloReactCommon.BaseMutationOptions<DisassociateMessageFromTopicMutation, DisassociateMessageFromTopicMutationVariables>;
export const DraftNoteDocument = gql`
    mutation DraftNote($input: DraftNoteInput!) {
  draftNote(input: $input) {
    id
    category
    dateTime
    title
    body
    startedAt
    versionNumber
    isPersonal
    isViewed
    revisions {
      versionNumber
      title
      body
      category
      dateTime
      draftedAt
      author {
        id
        preferredName
        firstName
        lastName
        pictureUrl
      }
    }
    clientAccount {
      id
      status
      referenceNumber
      client {
        id
        preferredName
        firstName
        lastName
        pictureUrl
      }
    }
    matter {
      id
    }
    author {
      id
      preferredName
      firstName
      lastName
      pictureUrl
    }
  }
}
    `;

/**
 * __useDraftNoteMutation__
 *
 * To run a mutation, you first call `useDraftNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDraftNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [draftNoteMutation, { data, loading, error }] = useDraftNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDraftNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DraftNoteMutation, DraftNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<DraftNoteMutation, DraftNoteMutationVariables>(DraftNoteDocument, baseOptions);
      }
export type DraftNoteMutationHookResult = ReturnType<typeof useDraftNoteMutation>;
export type DraftNoteMutationResult = ApolloReactCommon.MutationResult<DraftNoteMutation>;
export type DraftNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<DraftNoteMutation, DraftNoteMutationVariables>;
export const ReviseNoteDocument = gql`
    mutation ReviseNote($input: ReviseNoteInput!) {
  reviseNote(input: $input) {
    id
    category
    dateTime
    title
    body
    startedAt
    versionNumber
    isPersonal
    isViewed
    revisions {
      versionNumber
      title
      body
      category
      dateTime
      draftedAt
      author {
        id
        preferredName
        firstName
        lastName
        pictureUrl
      }
    }
    clientAccount {
      id
      status
      referenceNumber
      client {
        id
        preferredName
        firstName
        lastName
        pictureUrl
      }
    }
    matter {
      id
    }
    author {
      id
      preferredName
      firstName
      lastName
      pictureUrl
    }
  }
}
    `;

/**
 * __useReviseNoteMutation__
 *
 * To run a mutation, you first call `useReviseNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviseNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviseNoteMutation, { data, loading, error }] = useReviseNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReviseNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReviseNoteMutation, ReviseNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<ReviseNoteMutation, ReviseNoteMutationVariables>(ReviseNoteDocument, baseOptions);
      }
export type ReviseNoteMutationHookResult = ReturnType<typeof useReviseNoteMutation>;
export type ReviseNoteMutationResult = ApolloReactCommon.MutationResult<ReviseNoteMutation>;
export type ReviseNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<ReviseNoteMutation, ReviseNoteMutationVariables>;
export const AssociateNoteDocument = gql`
    mutation AssociateNote($input: AssociateNoteInput!) {
  associateNote(input: $input) {
    id
    category
    dateTime
    title
    body
    startedAt
    versionNumber
    isPersonal
    isViewed
    revisions {
      versionNumber
      title
      body
      category
      dateTime
      draftedAt
      author {
        id
        preferredName
        firstName
        lastName
        pictureUrl
      }
    }
    clientAccount {
      id
      status
      referenceNumber
      client {
        id
        preferredName
        firstName
        lastName
        pictureUrl
      }
    }
    matter {
      id
    }
    author {
      id
      preferredName
      firstName
      lastName
      pictureUrl
    }
  }
}
    `;

/**
 * __useAssociateNoteMutation__
 *
 * To run a mutation, you first call `useAssociateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssociateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [associateNoteMutation, { data, loading, error }] = useAssociateNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssociateNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssociateNoteMutation, AssociateNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<AssociateNoteMutation, AssociateNoteMutationVariables>(AssociateNoteDocument, baseOptions);
      }
export type AssociateNoteMutationHookResult = ReturnType<typeof useAssociateNoteMutation>;
export type AssociateNoteMutationResult = ApolloReactCommon.MutationResult<AssociateNoteMutation>;
export type AssociateNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<AssociateNoteMutation, AssociateNoteMutationVariables>;
export const MarkNoteViewedDocument = gql`
    mutation MarkNoteViewed($input: MarkNoteViewedInput!) {
  markNoteViewed(input: $input) {
    id
    category
    dateTime
    title
    body
    startedAt
    versionNumber
    isPersonal
    isViewed
    revisions {
      versionNumber
      title
      body
      category
      dateTime
      draftedAt
      author {
        id
        preferredName
        firstName
        lastName
        pictureUrl
      }
    }
    clientAccount {
      id
      status
      referenceNumber
      client {
        id
        preferredName
        firstName
        lastName
        pictureUrl
      }
    }
    matter {
      id
    }
    author {
      id
      preferredName
      firstName
      lastName
      pictureUrl
    }
  }
}
    `;

/**
 * __useMarkNoteViewedMutation__
 *
 * To run a mutation, you first call `useMarkNoteViewedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNoteViewedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNoteViewedMutation, { data, loading, error }] = useMarkNoteViewedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkNoteViewedMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MarkNoteViewedMutation, MarkNoteViewedMutationVariables>) {
        return ApolloReactHooks.useMutation<MarkNoteViewedMutation, MarkNoteViewedMutationVariables>(MarkNoteViewedDocument, baseOptions);
      }
export type MarkNoteViewedMutationHookResult = ReturnType<typeof useMarkNoteViewedMutation>;
export type MarkNoteViewedMutationResult = ApolloReactCommon.MutationResult<MarkNoteViewedMutation>;
export type MarkNoteViewedMutationOptions = ApolloReactCommon.BaseMutationOptions<MarkNoteViewedMutation, MarkNoteViewedMutationVariables>;
export const DiscardNoteDocument = gql`
    mutation DiscardNote($input: DiscardNoteInput!) {
  discardNote(input: $input) {
    noteId
    discardedOn
  }
}
    `;

/**
 * __useDiscardNoteMutation__
 *
 * To run a mutation, you first call `useDiscardNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDiscardNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [discardNoteMutation, { data, loading, error }] = useDiscardNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDiscardNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DiscardNoteMutation, DiscardNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<DiscardNoteMutation, DiscardNoteMutationVariables>(DiscardNoteDocument, baseOptions);
      }
export type DiscardNoteMutationHookResult = ReturnType<typeof useDiscardNoteMutation>;
export type DiscardNoteMutationResult = ApolloReactCommon.MutationResult<DiscardNoteMutation>;
export type DiscardNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<DiscardNoteMutation, DiscardNoteMutationVariables>;
export const MarkNotificationReadDocument = gql`
    mutation MarkNotificationRead($input: MarkNotificationReadInput!) {
  markNotificationRead(input: $input) {
    id
    type
    title
    body
    readAt
    isRead
  }
}
    `;

/**
 * __useMarkNotificationReadMutation__
 *
 * To run a mutation, you first call `useMarkNotificationReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationReadMutation, { data, loading, error }] = useMarkNotificationReadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkNotificationReadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MarkNotificationReadMutation, MarkNotificationReadMutationVariables>) {
        return ApolloReactHooks.useMutation<MarkNotificationReadMutation, MarkNotificationReadMutationVariables>(MarkNotificationReadDocument, baseOptions);
      }
export type MarkNotificationReadMutationHookResult = ReturnType<typeof useMarkNotificationReadMutation>;
export type MarkNotificationReadMutationResult = ApolloReactCommon.MutationResult<MarkNotificationReadMutation>;
export type MarkNotificationReadMutationOptions = ApolloReactCommon.BaseMutationOptions<MarkNotificationReadMutation, MarkNotificationReadMutationVariables>;
export const MarkAllNotificationsReadDocument = gql`
    mutation MarkAllNotificationsRead {
  markAllNotificationsRead {
    affectedNotificationCount
  }
}
    `;

/**
 * __useMarkAllNotificationsReadMutation__
 *
 * To run a mutation, you first call `useMarkAllNotificationsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllNotificationsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllNotificationsReadMutation, { data, loading, error }] = useMarkAllNotificationsReadMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarkAllNotificationsReadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MarkAllNotificationsReadMutation, MarkAllNotificationsReadMutationVariables>) {
        return ApolloReactHooks.useMutation<MarkAllNotificationsReadMutation, MarkAllNotificationsReadMutationVariables>(MarkAllNotificationsReadDocument, baseOptions);
      }
export type MarkAllNotificationsReadMutationHookResult = ReturnType<typeof useMarkAllNotificationsReadMutation>;
export type MarkAllNotificationsReadMutationResult = ApolloReactCommon.MutationResult<MarkAllNotificationsReadMutation>;
export type MarkAllNotificationsReadMutationOptions = ApolloReactCommon.BaseMutationOptions<MarkAllNotificationsReadMutation, MarkAllNotificationsReadMutationVariables>;
export const RegisterPushInterestDocument = gql`
    mutation RegisterPushInterest($input: RegisterPushInterestInput!) {
  registerPushInterest(input: $input) {
    affectedPushToken
  }
}
    `;

/**
 * __useRegisterPushInterestMutation__
 *
 * To run a mutation, you first call `useRegisterPushInterestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterPushInterestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerPushInterestMutation, { data, loading, error }] = useRegisterPushInterestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterPushInterestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterPushInterestMutation, RegisterPushInterestMutationVariables>) {
        return ApolloReactHooks.useMutation<RegisterPushInterestMutation, RegisterPushInterestMutationVariables>(RegisterPushInterestDocument, baseOptions);
      }
export type RegisterPushInterestMutationHookResult = ReturnType<typeof useRegisterPushInterestMutation>;
export type RegisterPushInterestMutationResult = ApolloReactCommon.MutationResult<RegisterPushInterestMutation>;
export type RegisterPushInterestMutationOptions = ApolloReactCommon.BaseMutationOptions<RegisterPushInterestMutation, RegisterPushInterestMutationVariables>;
export const RevokePushInterestDocument = gql`
    mutation RevokePushInterest($input: RevokePushInterestInput!) {
  revokePushInterest(input: $input) {
    affectedPushToken
  }
}
    `;

/**
 * __useRevokePushInterestMutation__
 *
 * To run a mutation, you first call `useRevokePushInterestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokePushInterestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokePushInterestMutation, { data, loading, error }] = useRevokePushInterestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRevokePushInterestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RevokePushInterestMutation, RevokePushInterestMutationVariables>) {
        return ApolloReactHooks.useMutation<RevokePushInterestMutation, RevokePushInterestMutationVariables>(RevokePushInterestDocument, baseOptions);
      }
export type RevokePushInterestMutationHookResult = ReturnType<typeof useRevokePushInterestMutation>;
export type RevokePushInterestMutationResult = ApolloReactCommon.MutationResult<RevokePushInterestMutation>;
export type RevokePushInterestMutationOptions = ApolloReactCommon.BaseMutationOptions<RevokePushInterestMutation, RevokePushInterestMutationVariables>;
export const PrepareQuestionDocument = gql`
    mutation PrepareQuestion($input: PrepareQuestionInput!) {
  prepareQuestion(input: $input) {
    id
    text
    description
    isReleased
    proportionComplete
    isHighlighted
    officialAdvice
    additionalAdvice
    status
    parts {
      id
      label
      isComplete
      isHighlighted
      officialAdvice
      additionalAdvice
    }
  }
}
    `;

/**
 * __usePrepareQuestionMutation__
 *
 * To run a mutation, you first call `usePrepareQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrepareQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prepareQuestionMutation, { data, loading, error }] = usePrepareQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrepareQuestionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PrepareQuestionMutation, PrepareQuestionMutationVariables>) {
        return ApolloReactHooks.useMutation<PrepareQuestionMutation, PrepareQuestionMutationVariables>(PrepareQuestionDocument, baseOptions);
      }
export type PrepareQuestionMutationHookResult = ReturnType<typeof usePrepareQuestionMutation>;
export type PrepareQuestionMutationResult = ApolloReactCommon.MutationResult<PrepareQuestionMutation>;
export type PrepareQuestionMutationOptions = ApolloReactCommon.BaseMutationOptions<PrepareQuestionMutation, PrepareQuestionMutationVariables>;
export const ReleaseQuestionDocument = gql`
    mutation ReleaseQuestion($input: ReleaseQuestionInput!) {
  releaseQuestion(input: $input) {
    id
    text
    description
    isReleased
    proportionComplete
    isHighlighted
    officialAdvice
    additionalAdvice
    status
    parts {
      id
      label
      isComplete
      isHighlighted
      officialAdvice
      additionalAdvice
    }
  }
}
    `;

/**
 * __useReleaseQuestionMutation__
 *
 * To run a mutation, you first call `useReleaseQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReleaseQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [releaseQuestionMutation, { data, loading, error }] = useReleaseQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReleaseQuestionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReleaseQuestionMutation, ReleaseQuestionMutationVariables>) {
        return ApolloReactHooks.useMutation<ReleaseQuestionMutation, ReleaseQuestionMutationVariables>(ReleaseQuestionDocument, baseOptions);
      }
export type ReleaseQuestionMutationHookResult = ReturnType<typeof useReleaseQuestionMutation>;
export type ReleaseQuestionMutationResult = ApolloReactCommon.MutationResult<ReleaseQuestionMutation>;
export type ReleaseQuestionMutationOptions = ApolloReactCommon.BaseMutationOptions<ReleaseQuestionMutation, ReleaseQuestionMutationVariables>;
export const UnreleaseQuestionDocument = gql`
    mutation UnreleaseQuestion($input: UnreleaseQuestionInput!) {
  unreleaseQuestion(input: $input) {
    id
    text
    description
    isReleased
    proportionComplete
    isHighlighted
    officialAdvice
    additionalAdvice
    status
    parts {
      id
      label
      isComplete
      isHighlighted
      officialAdvice
      additionalAdvice
    }
  }
}
    `;

/**
 * __useUnreleaseQuestionMutation__
 *
 * To run a mutation, you first call `useUnreleaseQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnreleaseQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unreleaseQuestionMutation, { data, loading, error }] = useUnreleaseQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnreleaseQuestionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnreleaseQuestionMutation, UnreleaseQuestionMutationVariables>) {
        return ApolloReactHooks.useMutation<UnreleaseQuestionMutation, UnreleaseQuestionMutationVariables>(UnreleaseQuestionDocument, baseOptions);
      }
export type UnreleaseQuestionMutationHookResult = ReturnType<typeof useUnreleaseQuestionMutation>;
export type UnreleaseQuestionMutationResult = ApolloReactCommon.MutationResult<UnreleaseQuestionMutation>;
export type UnreleaseQuestionMutationOptions = ApolloReactCommon.BaseMutationOptions<UnreleaseQuestionMutation, UnreleaseQuestionMutationVariables>;
export const EditQuestionTextDocument = gql`
    mutation EditQuestionText($input: EditQuestionInput!) {
  editQuestion(input: $input) {
    id
    text
    description
    additionalAdvice
  }
}
    `;

/**
 * __useEditQuestionTextMutation__
 *
 * To run a mutation, you first call `useEditQuestionTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditQuestionTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editQuestionTextMutation, { data, loading, error }] = useEditQuestionTextMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditQuestionTextMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditQuestionTextMutation, EditQuestionTextMutationVariables>) {
        return ApolloReactHooks.useMutation<EditQuestionTextMutation, EditQuestionTextMutationVariables>(EditQuestionTextDocument, baseOptions);
      }
export type EditQuestionTextMutationHookResult = ReturnType<typeof useEditQuestionTextMutation>;
export type EditQuestionTextMutationResult = ApolloReactCommon.MutationResult<EditQuestionTextMutation>;
export type EditQuestionTextMutationOptions = ApolloReactCommon.BaseMutationOptions<EditQuestionTextMutation, EditQuestionTextMutationVariables>;
export const RemoveQuestionDocument = gql`
    mutation RemoveQuestion($input: RemoveQuestionInput!) {
  removeQuestion(input: $input) {
    questionId
    removedOn
  }
}
    `;

/**
 * __useRemoveQuestionMutation__
 *
 * To run a mutation, you first call `useRemoveQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeQuestionMutation, { data, loading, error }] = useRemoveQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveQuestionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveQuestionMutation, RemoveQuestionMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveQuestionMutation, RemoveQuestionMutationVariables>(RemoveQuestionDocument, baseOptions);
      }
export type RemoveQuestionMutationHookResult = ReturnType<typeof useRemoveQuestionMutation>;
export type RemoveQuestionMutationResult = ApolloReactCommon.MutationResult<RemoveQuestionMutation>;
export type RemoveQuestionMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveQuestionMutation, RemoveQuestionMutationVariables>;
export const AnswerQuestionPartDocument = gql`
    mutation AnswerQuestionPart($input: AnswerQuestionPartInput!) {
  answerQuestionPart(input: $input) {
    id
    proposedOn
    status
    isHighlighted
    notApplicable
    isAcceptedByClient
    isAcceptedByProfessional
    proposer {
      id
      preferredName
      firstName
      lastName
      pictureUrl
    }
    ... on AddressAnswer {
      address: currentValue {
        name
        line1
        line2
        line3
        city
        region
        postCode
        country
      }
    }
    ... on BooleanAnswer {
      boolean: currentValue
    }
    ... on StringAnswer {
      string: currentValue
    }
    ... on ChoiceAnswer {
      choice: currentValue
    }
    ... on DateAnswer {
      date: currentValue
    }
    ... on EmailAnswer {
      email: currentValue
    }
    ... on FileAnswer {
      file: currentValue {
        id
        fileId
        fileVersion
        name
        type
        mime
        size
        ... on SupportingImage {
          height
          width
        }
      }
    }
    ... on ListAnswer {
      list: currentValue {
        ordering
        properties {
          attributeId
          ... on AddressListItemProperty {
            address: value {
              name
              line1
              line2
              line3
              city
              region
              postCode
              country
            }
          }
          ... on DateListItemProperty {
            date: value
          }
          ... on EmailListItemProperty {
            email: value
          }
          ... on FileListItemProperty {
            file: value
          }
          ... on MoneyListItemProperty {
            money: value
          }
          ... on NumberListItemProperty {
            number: value
          }
          ... on PercentListItemProperty {
            percent: value
          }
          ... on PhoneListItemProperty {
            phone: value
          }
          ... on StringListItemProperty {
            string: value
          }
          ... on TextListItemProperty {
            text: value
          }
        }
      }
    }
    ... on MoneyAnswer {
      money: currentValue
    }
    ... on NumberAnswer {
      number: currentValue
    }
    ... on PercentAnswer {
      percent: currentValue
    }
    ... on PhoneAnswer {
      phone: currentValue
    }
    ... on TextAnswer {
      text: currentValue
    }
    history {
      id
      proposer {
        id
        preferredName
        firstName
        lastName
        pictureUrl
      }
      proposedOn
      notApplicable
      ... on AddressAnswerRevision {
        address: value {
          name
          line1
          line2
          line3
          city
          region
          postCode
          country
        }
      }
      ... on BooleanAnswerRevision {
        boolean: value
      }
      ... on StringAnswerRevision {
        string: value
      }
      ... on ChoiceAnswerRevision {
        choice: value
      }
      ... on DateAnswerRevision {
        date: value
      }
      ... on EmailAnswerRevision {
        email: value
      }
      ... on FileAnswerRevision {
        file: value {
          id
          fileId
          fileVersion
          name
          type
          mime
          size
          ... on SupportingImage {
            height
            width
          }
        }
      }
      ... on ListAnswerRevision {
        list: value {
          ordering
          properties {
            attributeId
            ... on AddressListItemProperty {
              address: value {
                name
                line1
                line2
                line3
                city
                region
                postCode
                country
              }
            }
            ... on DateListItemProperty {
              date: value
            }
            ... on EmailListItemProperty {
              email: value
            }
            ... on FileListItemProperty {
              file: value
            }
            ... on MoneyListItemProperty {
              money: value
            }
            ... on NumberListItemProperty {
              number: value
            }
            ... on PercentListItemProperty {
              percent: value
            }
            ... on PhoneListItemProperty {
              phone: value
            }
            ... on StringListItemProperty {
              string: value
            }
            ... on TextListItemProperty {
              text: value
            }
          }
        }
      }
      ... on MoneyAnswerRevision {
        money: value
      }
      ... on NumberAnswerRevision {
        number: value
      }
      ... on PercentAnswerRevision {
        percent: value
      }
      ... on PhoneAnswerRevision {
        phone: value
      }
      ... on TextAnswerRevision {
        text: value
      }
    }
  }
}
    `;

/**
 * __useAnswerQuestionPartMutation__
 *
 * To run a mutation, you first call `useAnswerQuestionPartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnswerQuestionPartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [answerQuestionPartMutation, { data, loading, error }] = useAnswerQuestionPartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAnswerQuestionPartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AnswerQuestionPartMutation, AnswerQuestionPartMutationVariables>) {
        return ApolloReactHooks.useMutation<AnswerQuestionPartMutation, AnswerQuestionPartMutationVariables>(AnswerQuestionPartDocument, baseOptions);
      }
export type AnswerQuestionPartMutationHookResult = ReturnType<typeof useAnswerQuestionPartMutation>;
export type AnswerQuestionPartMutationResult = ApolloReactCommon.MutationResult<AnswerQuestionPartMutation>;
export type AnswerQuestionPartMutationOptions = ApolloReactCommon.BaseMutationOptions<AnswerQuestionPartMutation, AnswerQuestionPartMutationVariables>;
export const AcceptAnswerDocument = gql`
    mutation AcceptAnswer($input: AcceptAnswerInput!) {
  acceptAnswer(input: $input) {
    id
    proposedOn
    status
    isHighlighted
    notApplicable
    proposer {
      id
      preferredName
      firstName
      lastName
      pictureUrl
    }
    ... on AddressAnswer {
      address: currentValue {
        name
        line1
        line2
        line3
        city
        region
        postCode
        country
      }
    }
    ... on BooleanAnswer {
      boolean: currentValue
    }
    ... on StringAnswer {
      string: currentValue
    }
    ... on ChoiceAnswer {
      choice: currentValue
    }
    ... on DateAnswer {
      date: currentValue
    }
    ... on EmailAnswer {
      email: currentValue
    }
    ... on FileAnswer {
      file: currentValue {
        id
        fileId
        fileVersion
        name
        type
        mime
        size
        ... on SupportingImage {
          height
          width
        }
      }
    }
    ... on ListAnswer {
      list: currentValue {
        ordering
        properties {
          attributeId
          ... on AddressListItemProperty {
            address: value {
              name
              line1
              line2
              line3
              city
              region
              postCode
              country
            }
          }
          ... on DateListItemProperty {
            date: value
          }
          ... on EmailListItemProperty {
            email: value
          }
          ... on FileListItemProperty {
            file: value
          }
          ... on MoneyListItemProperty {
            money: value
          }
          ... on NumberListItemProperty {
            number: value
          }
          ... on PercentListItemProperty {
            percent: value
          }
          ... on PhoneListItemProperty {
            phone: value
          }
          ... on StringListItemProperty {
            string: value
          }
          ... on TextListItemProperty {
            text: value
          }
        }
      }
    }
    ... on MoneyAnswer {
      money: currentValue
    }
    ... on NumberAnswer {
      number: currentValue
    }
    ... on PercentAnswer {
      percent: currentValue
    }
    ... on PhoneAnswer {
      phone: currentValue
    }
    ... on TextAnswer {
      text: currentValue
    }
    history {
      id
      proposer {
        id
        preferredName
        firstName
        lastName
        pictureUrl
      }
      notApplicable
      proposedOn
      ... on AddressAnswerRevision {
        address: value {
          name
          line1
          line2
          line3
          city
          region
          postCode
          country
        }
      }
      ... on BooleanAnswerRevision {
        boolean: value
      }
      ... on StringAnswerRevision {
        string: value
      }
      ... on ChoiceAnswerRevision {
        choice: value
      }
      ... on DateAnswerRevision {
        date: value
      }
      ... on EmailAnswerRevision {
        email: value
      }
      ... on FileAnswerRevision {
        file: value {
          id
          fileId
          fileVersion
          name
          type
          mime
          size
          ... on SupportingImage {
            height
            width
          }
        }
      }
      ... on ListAnswerRevision {
        list: value {
          ordering
          properties {
            attributeId
            ... on AddressListItemProperty {
              address: value {
                name
                line1
                line2
                line3
                city
                region
                postCode
                country
              }
            }
            ... on DateListItemProperty {
              date: value
            }
            ... on EmailListItemProperty {
              email: value
            }
            ... on FileListItemProperty {
              file: value
            }
            ... on MoneyListItemProperty {
              money: value
            }
            ... on NumberListItemProperty {
              number: value
            }
            ... on PercentListItemProperty {
              percent: value
            }
            ... on PhoneListItemProperty {
              phone: value
            }
            ... on StringListItemProperty {
              string: value
            }
            ... on TextListItemProperty {
              text: value
            }
          }
        }
      }
      ... on MoneyAnswerRevision {
        money: value
      }
      ... on NumberAnswerRevision {
        number: value
      }
      ... on PercentAnswerRevision {
        percent: value
      }
      ... on PhoneAnswerRevision {
        phone: value
      }
      ... on TextAnswerRevision {
        text: value
      }
    }
  }
}
    `;

/**
 * __useAcceptAnswerMutation__
 *
 * To run a mutation, you first call `useAcceptAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptAnswerMutation, { data, loading, error }] = useAcceptAnswerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptAnswerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptAnswerMutation, AcceptAnswerMutationVariables>) {
        return ApolloReactHooks.useMutation<AcceptAnswerMutation, AcceptAnswerMutationVariables>(AcceptAnswerDocument, baseOptions);
      }
export type AcceptAnswerMutationHookResult = ReturnType<typeof useAcceptAnswerMutation>;
export type AcceptAnswerMutationResult = ApolloReactCommon.MutationResult<AcceptAnswerMutation>;
export type AcceptAnswerMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptAnswerMutation, AcceptAnswerMutationVariables>;
export const ChangeAnswerLockDocument = gql`
    mutation ChangeAnswerLock($input: ChangeAnswerLockInput!) {
  changeAnswerLock(input: $input) {
    id
    proposedOn
    status
    isHighlighted
    notApplicable
    proposer {
      id
      preferredName
      firstName
      lastName
      pictureUrl
    }
    ... on AddressAnswer {
      address: currentValue {
        name
        line1
        line2
        line3
        city
        region
        postCode
        country
      }
    }
    ... on BooleanAnswer {
      boolean: currentValue
    }
    ... on StringAnswer {
      string: currentValue
    }
    ... on ChoiceAnswer {
      choice: currentValue
    }
    ... on DateAnswer {
      date: currentValue
    }
    ... on EmailAnswer {
      email: currentValue
    }
    ... on FileAnswer {
      file: currentValue {
        id
        fileId
        fileVersion
        name
        type
        mime
        size
        ... on SupportingImage {
          height
          width
        }
      }
    }
    ... on ListAnswer {
      list: currentValue {
        ordering
        properties {
          attributeId
          ... on AddressListItemProperty {
            address: value {
              name
              line1
              line2
              line3
              city
              region
              postCode
              country
            }
          }
          ... on DateListItemProperty {
            date: value
          }
          ... on EmailListItemProperty {
            email: value
          }
          ... on FileListItemProperty {
            file: value
          }
          ... on MoneyListItemProperty {
            money: value
          }
          ... on NumberListItemProperty {
            number: value
          }
          ... on PercentListItemProperty {
            percent: value
          }
          ... on PhoneListItemProperty {
            phone: value
          }
          ... on StringListItemProperty {
            string: value
          }
          ... on TextListItemProperty {
            text: value
          }
        }
      }
    }
    ... on MoneyAnswer {
      money: currentValue
    }
    ... on NumberAnswer {
      number: currentValue
    }
    ... on PercentAnswer {
      percent: currentValue
    }
    ... on PhoneAnswer {
      phone: currentValue
    }
    ... on TextAnswer {
      text: currentValue
    }
    history {
      id
      proposer {
        id
        preferredName
        firstName
        lastName
        pictureUrl
      }
      proposedOn
      notApplicable
      ... on AddressAnswerRevision {
        address: value {
          name
          line1
          line2
          line3
          city
          region
          postCode
          country
        }
      }
      ... on BooleanAnswerRevision {
        boolean: value
      }
      ... on StringAnswerRevision {
        string: value
      }
      ... on ChoiceAnswerRevision {
        choice: value
      }
      ... on DateAnswerRevision {
        date: value
      }
      ... on EmailAnswerRevision {
        email: value
      }
      ... on FileAnswerRevision {
        file: value {
          id
          fileId
          fileVersion
          name
          type
          mime
          size
          ... on SupportingImage {
            height
            width
          }
        }
      }
      ... on ListAnswerRevision {
        list: value {
          ordering
          properties {
            attributeId
            ... on AddressListItemProperty {
              address: value {
                name
                line1
                line2
                line3
                city
                region
                postCode
                country
              }
            }
            ... on DateListItemProperty {
              date: value
            }
            ... on EmailListItemProperty {
              email: value
            }
            ... on FileListItemProperty {
              file: value
            }
            ... on MoneyListItemProperty {
              money: value
            }
            ... on NumberListItemProperty {
              number: value
            }
            ... on PercentListItemProperty {
              percent: value
            }
            ... on PhoneListItemProperty {
              phone: value
            }
            ... on StringListItemProperty {
              string: value
            }
            ... on TextListItemProperty {
              text: value
            }
          }
        }
      }
      ... on MoneyAnswerRevision {
        money: value
      }
      ... on NumberAnswerRevision {
        number: value
      }
      ... on PercentAnswerRevision {
        percent: value
      }
      ... on PhoneAnswerRevision {
        phone: value
      }
      ... on TextAnswerRevision {
        text: value
      }
    }
  }
}
    `;

/**
 * __useChangeAnswerLockMutation__
 *
 * To run a mutation, you first call `useChangeAnswerLockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeAnswerLockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeAnswerLockMutation, { data, loading, error }] = useChangeAnswerLockMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeAnswerLockMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeAnswerLockMutation, ChangeAnswerLockMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeAnswerLockMutation, ChangeAnswerLockMutationVariables>(ChangeAnswerLockDocument, baseOptions);
      }
export type ChangeAnswerLockMutationHookResult = ReturnType<typeof useChangeAnswerLockMutation>;
export type ChangeAnswerLockMutationResult = ApolloReactCommon.MutationResult<ChangeAnswerLockMutation>;
export type ChangeAnswerLockMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeAnswerLockMutation, ChangeAnswerLockMutationVariables>;
export const DraftAnswerQuestionPartDocument = gql`
    mutation DraftAnswerQuestionPart($input: AnswerQuestionPartInput!) {
  draftAnswerQuestionPart(input: $input) {
    id
    notApplicable
    proposer {
      id
      preferredName
      firstName
      lastName
      pictureUrl
    }
    ... on AddressAnswerDraft {
      address: currentValue {
        name
        line1
        line2
        line3
        city
        region
        postCode
        country
      }
    }
    ... on BooleanAnswerDraft {
      boolean: currentValue
    }
    ... on StringAnswerDraft {
      string: currentValue
    }
    ... on ChoiceAnswerDraft {
      choice: currentValue
    }
    ... on DateAnswerDraft {
      date: currentValue
    }
    ... on EmailAnswerDraft {
      email: currentValue
    }
    ... on FileAnswerDraft {
      file: currentValue {
        id
        fileId
        fileVersion
        name
        type
        mime
        size
        ... on SupportingImage {
          height
          width
        }
      }
    }
    ... on ListAnswerDraft {
      list: currentValue {
        ordering
        properties {
          attributeId
          ... on AddressListItemProperty {
            address: value {
              name
              line1
              line2
              line3
              city
              region
              postCode
              country
            }
          }
          ... on DateListItemProperty {
            date: value
          }
          ... on EmailListItemProperty {
            email: value
          }
          ... on FileListItemProperty {
            file: value
          }
          ... on MoneyListItemProperty {
            money: value
          }
          ... on NumberListItemProperty {
            number: value
          }
          ... on PercentListItemProperty {
            percent: value
          }
          ... on PhoneListItemProperty {
            phone: value
          }
          ... on StringListItemProperty {
            string: value
          }
          ... on TextListItemProperty {
            text: value
          }
        }
      }
    }
    ... on MoneyAnswerDraft {
      money: currentValue
    }
    ... on NumberAnswerDraft {
      number: currentValue
    }
    ... on PercentAnswerDraft {
      percent: currentValue
    }
    ... on PhoneAnswerDraft {
      phone: currentValue
    }
    ... on TextAnswerDraft {
      text: currentValue
    }
  }
}
    `;

/**
 * __useDraftAnswerQuestionPartMutation__
 *
 * To run a mutation, you first call `useDraftAnswerQuestionPartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDraftAnswerQuestionPartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [draftAnswerQuestionPartMutation, { data, loading, error }] = useDraftAnswerQuestionPartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDraftAnswerQuestionPartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DraftAnswerQuestionPartMutation, DraftAnswerQuestionPartMutationVariables>) {
        return ApolloReactHooks.useMutation<DraftAnswerQuestionPartMutation, DraftAnswerQuestionPartMutationVariables>(DraftAnswerQuestionPartDocument, baseOptions);
      }
export type DraftAnswerQuestionPartMutationHookResult = ReturnType<typeof useDraftAnswerQuestionPartMutation>;
export type DraftAnswerQuestionPartMutationResult = ApolloReactCommon.MutationResult<DraftAnswerQuestionPartMutation>;
export type DraftAnswerQuestionPartMutationOptions = ApolloReactCommon.BaseMutationOptions<DraftAnswerQuestionPartMutation, DraftAnswerQuestionPartMutationVariables>;
export const ClearAnswerDraftDocument = gql`
    mutation ClearAnswerDraft($input: ClearAnswerDraftInput!) {
  clearAnswerDraft(input: $input) {
    id
  }
}
    `;

/**
 * __useClearAnswerDraftMutation__
 *
 * To run a mutation, you first call `useClearAnswerDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearAnswerDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearAnswerDraftMutation, { data, loading, error }] = useClearAnswerDraftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClearAnswerDraftMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClearAnswerDraftMutation, ClearAnswerDraftMutationVariables>) {
        return ApolloReactHooks.useMutation<ClearAnswerDraftMutation, ClearAnswerDraftMutationVariables>(ClearAnswerDraftDocument, baseOptions);
      }
export type ClearAnswerDraftMutationHookResult = ReturnType<typeof useClearAnswerDraftMutation>;
export type ClearAnswerDraftMutationResult = ApolloReactCommon.MutationResult<ClearAnswerDraftMutation>;
export type ClearAnswerDraftMutationOptions = ApolloReactCommon.BaseMutationOptions<ClearAnswerDraftMutation, ClearAnswerDraftMutationVariables>;
export const PrepareMatterTaskDocument = gql`
    mutation PrepareMatterTask($input: PrepareMatterTaskInput!) {
  prepareMatterTask(input: $input) {
    id
    name
    description
    status
    sectionCount
    proportionComplete
    proportionReleased
    isHighlighted
    officialAdvice
    additionalAdvice
    dueDate {
      id
      date
      isAllDay
      name
      description
    }
    sections {
      id
      name
      description
      ordering
      isHighlighted
      officialAdvice
      additionalAdvice
      dueDate {
        id
        date
        isAllDay
        name
        description
      }
      questions {
        id
        text
        description
        isReleased
        proportionComplete
        isHighlighted
        officialAdvice
        additionalAdvice
        status
        parts {
          id
          label
          isComplete
          isHighlighted
          officialAdvice
          additionalAdvice
        }
      }
    }
  }
}
    `;

/**
 * __usePrepareMatterTaskMutation__
 *
 * To run a mutation, you first call `usePrepareMatterTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrepareMatterTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prepareMatterTaskMutation, { data, loading, error }] = usePrepareMatterTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrepareMatterTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PrepareMatterTaskMutation, PrepareMatterTaskMutationVariables>) {
        return ApolloReactHooks.useMutation<PrepareMatterTaskMutation, PrepareMatterTaskMutationVariables>(PrepareMatterTaskDocument, baseOptions);
      }
export type PrepareMatterTaskMutationHookResult = ReturnType<typeof usePrepareMatterTaskMutation>;
export type PrepareMatterTaskMutationResult = ApolloReactCommon.MutationResult<PrepareMatterTaskMutation>;
export type PrepareMatterTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<PrepareMatterTaskMutation, PrepareMatterTaskMutationVariables>;
export const PrepareFirmTaskDocument = gql`
    mutation PrepareFirmTask($input: PrepareFirmTaskInput!) {
  prepareFirmTask(input: $input) {
    id
    name
    description
    matterTypes
    sectionCount
    isModifiable
    isPublished
    additionalAdvice
    officialAdvice
  }
}
    `;

/**
 * __usePrepareFirmTaskMutation__
 *
 * To run a mutation, you first call `usePrepareFirmTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrepareFirmTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prepareFirmTaskMutation, { data, loading, error }] = usePrepareFirmTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrepareFirmTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PrepareFirmTaskMutation, PrepareFirmTaskMutationVariables>) {
        return ApolloReactHooks.useMutation<PrepareFirmTaskMutation, PrepareFirmTaskMutationVariables>(PrepareFirmTaskDocument, baseOptions);
      }
export type PrepareFirmTaskMutationHookResult = ReturnType<typeof usePrepareFirmTaskMutation>;
export type PrepareFirmTaskMutationResult = ApolloReactCommon.MutationResult<PrepareFirmTaskMutation>;
export type PrepareFirmTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<PrepareFirmTaskMutation, PrepareFirmTaskMutationVariables>;
export const ChangeTaskPublishStatusDocument = gql`
    mutation ChangeTaskPublishStatus($input: ChangeTaskPublishStatusInput!) {
  changeTaskPublishStatus(input: $input) {
    id
    name
    isPublished
  }
}
    `;

/**
 * __useChangeTaskPublishStatusMutation__
 *
 * To run a mutation, you first call `useChangeTaskPublishStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTaskPublishStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTaskPublishStatusMutation, { data, loading, error }] = useChangeTaskPublishStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeTaskPublishStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeTaskPublishStatusMutation, ChangeTaskPublishStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeTaskPublishStatusMutation, ChangeTaskPublishStatusMutationVariables>(ChangeTaskPublishStatusDocument, baseOptions);
      }
export type ChangeTaskPublishStatusMutationHookResult = ReturnType<typeof useChangeTaskPublishStatusMutation>;
export type ChangeTaskPublishStatusMutationResult = ApolloReactCommon.MutationResult<ChangeTaskPublishStatusMutation>;
export type ChangeTaskPublishStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeTaskPublishStatusMutation, ChangeTaskPublishStatusMutationVariables>;
export const SetTaskDueDateDocument = gql`
    mutation SetTaskDueDate($input: SetTaskDueDateInput!) {
  setTaskDueDate(input: $input) {
    id
    status
    dueDate {
      id
      date
      isAllDay
      name
      description
    }
  }
}
    `;

/**
 * __useSetTaskDueDateMutation__
 *
 * To run a mutation, you first call `useSetTaskDueDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTaskDueDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTaskDueDateMutation, { data, loading, error }] = useSetTaskDueDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTaskDueDateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetTaskDueDateMutation, SetTaskDueDateMutationVariables>) {
        return ApolloReactHooks.useMutation<SetTaskDueDateMutation, SetTaskDueDateMutationVariables>(SetTaskDueDateDocument, baseOptions);
      }
export type SetTaskDueDateMutationHookResult = ReturnType<typeof useSetTaskDueDateMutation>;
export type SetTaskDueDateMutationResult = ApolloReactCommon.MutationResult<SetTaskDueDateMutation>;
export type SetTaskDueDateMutationOptions = ApolloReactCommon.BaseMutationOptions<SetTaskDueDateMutation, SetTaskDueDateMutationVariables>;
export const ClearTaskDueDateDocument = gql`
    mutation ClearTaskDueDate($input: ClearTaskDueDateInput!) {
  clearTaskDueDate(input: $input) {
    id
    status
    dueDate {
      id
      date
      isAllDay
      name
      description
    }
  }
}
    `;

/**
 * __useClearTaskDueDateMutation__
 *
 * To run a mutation, you first call `useClearTaskDueDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearTaskDueDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearTaskDueDateMutation, { data, loading, error }] = useClearTaskDueDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClearTaskDueDateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClearTaskDueDateMutation, ClearTaskDueDateMutationVariables>) {
        return ApolloReactHooks.useMutation<ClearTaskDueDateMutation, ClearTaskDueDateMutationVariables>(ClearTaskDueDateDocument, baseOptions);
      }
export type ClearTaskDueDateMutationHookResult = ReturnType<typeof useClearTaskDueDateMutation>;
export type ClearTaskDueDateMutationResult = ApolloReactCommon.MutationResult<ClearTaskDueDateMutation>;
export type ClearTaskDueDateMutationOptions = ApolloReactCommon.BaseMutationOptions<ClearTaskDueDateMutation, ClearTaskDueDateMutationVariables>;
export const RemoveTaskDocument = gql`
    mutation RemoveTask($input: RemoveTaskInput!) {
  removeTask(input: $input) {
    taskId
    removedOn
  }
}
    `;

/**
 * __useRemoveTaskMutation__
 *
 * To run a mutation, you first call `useRemoveTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTaskMutation, { data, loading, error }] = useRemoveTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveTaskMutation, RemoveTaskMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveTaskMutation, RemoveTaskMutationVariables>(RemoveTaskDocument, baseOptions);
      }
export type RemoveTaskMutationHookResult = ReturnType<typeof useRemoveTaskMutation>;
export type RemoveTaskMutationResult = ApolloReactCommon.MutationResult<RemoveTaskMutation>;
export type RemoveTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveTaskMutation, RemoveTaskMutationVariables>;
export const RenameTaskDocument = gql`
    mutation RenameTask($input: RenameTaskInput!) {
  renameTask(input: $input) {
    id
    name
    description
  }
}
    `;

/**
 * __useRenameTaskMutation__
 *
 * To run a mutation, you first call `useRenameTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameTaskMutation, { data, loading, error }] = useRenameTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRenameTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RenameTaskMutation, RenameTaskMutationVariables>) {
        return ApolloReactHooks.useMutation<RenameTaskMutation, RenameTaskMutationVariables>(RenameTaskDocument, baseOptions);
      }
export type RenameTaskMutationHookResult = ReturnType<typeof useRenameTaskMutation>;
export type RenameTaskMutationResult = ApolloReactCommon.MutationResult<RenameTaskMutation>;
export type RenameTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<RenameTaskMutation, RenameTaskMutationVariables>;
export const DescribeTaskDocument = gql`
    mutation DescribeTask($input: DescribeTaskInput!) {
  describeTask(input: $input) {
    id
    name
    description
  }
}
    `;

/**
 * __useDescribeTaskMutation__
 *
 * To run a mutation, you first call `useDescribeTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDescribeTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [describeTaskMutation, { data, loading, error }] = useDescribeTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDescribeTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DescribeTaskMutation, DescribeTaskMutationVariables>) {
        return ApolloReactHooks.useMutation<DescribeTaskMutation, DescribeTaskMutationVariables>(DescribeTaskDocument, baseOptions);
      }
export type DescribeTaskMutationHookResult = ReturnType<typeof useDescribeTaskMutation>;
export type DescribeTaskMutationResult = ApolloReactCommon.MutationResult<DescribeTaskMutation>;
export type DescribeTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<DescribeTaskMutation, DescribeTaskMutationVariables>;
export const DefineTaskSectionDocument = gql`
    mutation DefineTaskSection($input: DefineTaskSectionInput!) {
  defineTaskSection(input: $input) {
    id
    sections {
      id
      name
      description
      ordering
      proportionComplete
      proportionReleased
      isHighlighted
      officialAdvice
      additionalAdvice
      dueDate {
        id
        date
        isAllDay
        name
        description
      }
      questions {
        id
        text
        description
        isReleased
        proportionComplete
        isHighlighted
        officialAdvice
        additionalAdvice
        status
        parts {
          id
          label
          isComplete
          isHighlighted
          officialAdvice
          additionalAdvice
        }
      }
    }
  }
}
    `;

/**
 * __useDefineTaskSectionMutation__
 *
 * To run a mutation, you first call `useDefineTaskSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDefineTaskSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [defineTaskSectionMutation, { data, loading, error }] = useDefineTaskSectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDefineTaskSectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DefineTaskSectionMutation, DefineTaskSectionMutationVariables>) {
        return ApolloReactHooks.useMutation<DefineTaskSectionMutation, DefineTaskSectionMutationVariables>(DefineTaskSectionDocument, baseOptions);
      }
export type DefineTaskSectionMutationHookResult = ReturnType<typeof useDefineTaskSectionMutation>;
export type DefineTaskSectionMutationResult = ApolloReactCommon.MutationResult<DefineTaskSectionMutation>;
export type DefineTaskSectionMutationOptions = ApolloReactCommon.BaseMutationOptions<DefineTaskSectionMutation, DefineTaskSectionMutationVariables>;
export const RemoveTaskSectionDocument = gql`
    mutation RemoveTaskSection($input: RemoveTaskSectionInput!) {
  removeTaskSection(input: $input) {
    id
    sections {
      id
      name
      description
      ordering
      proportionComplete
      proportionReleased
      isHighlighted
      officialAdvice
      additionalAdvice
      dueDate {
        id
        date
        isAllDay
        name
        description
      }
      questions {
        id
        text
        description
        isReleased
        proportionComplete
        isHighlighted
        officialAdvice
        additionalAdvice
        status
        parts {
          id
          label
          isComplete
          isHighlighted
          officialAdvice
          additionalAdvice
        }
      }
    }
  }
}
    `;

/**
 * __useRemoveTaskSectionMutation__
 *
 * To run a mutation, you first call `useRemoveTaskSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTaskSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTaskSectionMutation, { data, loading, error }] = useRemoveTaskSectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTaskSectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveTaskSectionMutation, RemoveTaskSectionMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveTaskSectionMutation, RemoveTaskSectionMutationVariables>(RemoveTaskSectionDocument, baseOptions);
      }
export type RemoveTaskSectionMutationHookResult = ReturnType<typeof useRemoveTaskSectionMutation>;
export type RemoveTaskSectionMutationResult = ApolloReactCommon.MutationResult<RemoveTaskSectionMutation>;
export type RemoveTaskSectionMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveTaskSectionMutation, RemoveTaskSectionMutationVariables>;
export const UpdateTaskSectionDocument = gql`
    mutation UpdateTaskSection($renameInput: RenameTaskSectionInput!, $describeInput: DescribeTaskSectionInput!) {
  renameTaskSection(input: $renameInput) {
    id
  }
  describeTaskSection(input: $describeInput) {
    id
    sections {
      id
      name
      description
      ordering
      proportionComplete
      proportionReleased
      isHighlighted
      officialAdvice
      additionalAdvice
      dueDate {
        id
        date
        isAllDay
        name
        description
      }
      questions {
        id
        text
        description
        isReleased
        proportionComplete
        isHighlighted
        officialAdvice
        additionalAdvice
        status
        parts {
          id
          label
          isComplete
          isHighlighted
          officialAdvice
          additionalAdvice
        }
      }
    }
  }
}
    `;

/**
 * __useUpdateTaskSectionMutation__
 *
 * To run a mutation, you first call `useUpdateTaskSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskSectionMutation, { data, loading, error }] = useUpdateTaskSectionMutation({
 *   variables: {
 *      renameInput: // value for 'renameInput'
 *      describeInput: // value for 'describeInput'
 *   },
 * });
 */
export function useUpdateTaskSectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTaskSectionMutation, UpdateTaskSectionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTaskSectionMutation, UpdateTaskSectionMutationVariables>(UpdateTaskSectionDocument, baseOptions);
      }
export type UpdateTaskSectionMutationHookResult = ReturnType<typeof useUpdateTaskSectionMutation>;
export type UpdateTaskSectionMutationResult = ApolloReactCommon.MutationResult<UpdateTaskSectionMutation>;
export type UpdateTaskSectionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTaskSectionMutation, UpdateTaskSectionMutationVariables>;
export const RenameTaskSectionDocument = gql`
    mutation RenameTaskSection($renameInput: RenameTaskSectionInput!) {
  renameTaskSection(input: $renameInput) {
    id
    sections {
      id
      name
      description
      ordering
      proportionComplete
      proportionReleased
      isHighlighted
      officialAdvice
      additionalAdvice
      dueDate {
        id
        date
        isAllDay
        name
        description
      }
      questions {
        id
        text
        description
        isReleased
        proportionComplete
        isHighlighted
        officialAdvice
        additionalAdvice
        status
        parts {
          id
          label
          isComplete
          isHighlighted
          officialAdvice
          additionalAdvice
        }
      }
    }
  }
}
    `;

/**
 * __useRenameTaskSectionMutation__
 *
 * To run a mutation, you first call `useRenameTaskSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameTaskSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameTaskSectionMutation, { data, loading, error }] = useRenameTaskSectionMutation({
 *   variables: {
 *      renameInput: // value for 'renameInput'
 *   },
 * });
 */
export function useRenameTaskSectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RenameTaskSectionMutation, RenameTaskSectionMutationVariables>) {
        return ApolloReactHooks.useMutation<RenameTaskSectionMutation, RenameTaskSectionMutationVariables>(RenameTaskSectionDocument, baseOptions);
      }
export type RenameTaskSectionMutationHookResult = ReturnType<typeof useRenameTaskSectionMutation>;
export type RenameTaskSectionMutationResult = ApolloReactCommon.MutationResult<RenameTaskSectionMutation>;
export type RenameTaskSectionMutationOptions = ApolloReactCommon.BaseMutationOptions<RenameTaskSectionMutation, RenameTaskSectionMutationVariables>;
export const DescribeTaskSectionDocument = gql`
    mutation DescribeTaskSection($input: DescribeTaskSectionInput!) {
  describeTaskSection(input: $input) {
    id
    sections {
      id
      name
      description
      ordering
      proportionComplete
      proportionReleased
      isHighlighted
      officialAdvice
      additionalAdvice
      dueDate {
        id
        date
        isAllDay
        name
        description
      }
      questions {
        id
        text
        description
        isReleased
        proportionComplete
        isHighlighted
        officialAdvice
        additionalAdvice
        status
        parts {
          id
          label
          isComplete
          isHighlighted
          officialAdvice
          additionalAdvice
        }
      }
    }
  }
}
    `;

/**
 * __useDescribeTaskSectionMutation__
 *
 * To run a mutation, you first call `useDescribeTaskSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDescribeTaskSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [describeTaskSectionMutation, { data, loading, error }] = useDescribeTaskSectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDescribeTaskSectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DescribeTaskSectionMutation, DescribeTaskSectionMutationVariables>) {
        return ApolloReactHooks.useMutation<DescribeTaskSectionMutation, DescribeTaskSectionMutationVariables>(DescribeTaskSectionDocument, baseOptions);
      }
export type DescribeTaskSectionMutationHookResult = ReturnType<typeof useDescribeTaskSectionMutation>;
export type DescribeTaskSectionMutationResult = ApolloReactCommon.MutationResult<DescribeTaskSectionMutation>;
export type DescribeTaskSectionMutationOptions = ApolloReactCommon.BaseMutationOptions<DescribeTaskSectionMutation, DescribeTaskSectionMutationVariables>;
export const ReorderTaskSectionsDocument = gql`
    mutation ReorderTaskSections($input: ReorderTaskSectionsInput!) {
  reorderTaskSections(input: $input) {
    id
    sections {
      id
      name
      description
      ordering
      proportionComplete
      proportionReleased
      isHighlighted
      officialAdvice
      additionalAdvice
      dueDate {
        id
        date
        isAllDay
        name
        description
      }
      questions {
        id
        text
        description
        isReleased
        proportionComplete
        isHighlighted
        officialAdvice
        additionalAdvice
        status
        parts {
          id
          label
          isComplete
          isHighlighted
          officialAdvice
          additionalAdvice
        }
      }
    }
  }
}
    `;

/**
 * __useReorderTaskSectionsMutation__
 *
 * To run a mutation, you first call `useReorderTaskSectionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderTaskSectionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderTaskSectionsMutation, { data, loading, error }] = useReorderTaskSectionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReorderTaskSectionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReorderTaskSectionsMutation, ReorderTaskSectionsMutationVariables>) {
        return ApolloReactHooks.useMutation<ReorderTaskSectionsMutation, ReorderTaskSectionsMutationVariables>(ReorderTaskSectionsDocument, baseOptions);
      }
export type ReorderTaskSectionsMutationHookResult = ReturnType<typeof useReorderTaskSectionsMutation>;
export type ReorderTaskSectionsMutationResult = ApolloReactCommon.MutationResult<ReorderTaskSectionsMutation>;
export type ReorderTaskSectionsMutationOptions = ApolloReactCommon.BaseMutationOptions<ReorderTaskSectionsMutation, ReorderTaskSectionsMutationVariables>;
export const SetTaskSectionDueDateDocument = gql`
    mutation SetTaskSectionDueDate($input: SetTaskSectionDueDateInput!) {
  setTaskSectionDueDate(input: $input) {
    id
    sections {
      id
      name
      description
      ordering
      proportionComplete
      proportionReleased
      isHighlighted
      officialAdvice
      additionalAdvice
      dueDate {
        id
        date
        isAllDay
        name
        description
      }
      questions {
        id
        text
        description
        isReleased
        proportionComplete
        isHighlighted
        officialAdvice
        additionalAdvice
        status
        parts {
          id
          label
          isComplete
          isHighlighted
          officialAdvice
          additionalAdvice
        }
      }
    }
  }
}
    `;

/**
 * __useSetTaskSectionDueDateMutation__
 *
 * To run a mutation, you first call `useSetTaskSectionDueDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTaskSectionDueDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTaskSectionDueDateMutation, { data, loading, error }] = useSetTaskSectionDueDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTaskSectionDueDateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetTaskSectionDueDateMutation, SetTaskSectionDueDateMutationVariables>) {
        return ApolloReactHooks.useMutation<SetTaskSectionDueDateMutation, SetTaskSectionDueDateMutationVariables>(SetTaskSectionDueDateDocument, baseOptions);
      }
export type SetTaskSectionDueDateMutationHookResult = ReturnType<typeof useSetTaskSectionDueDateMutation>;
export type SetTaskSectionDueDateMutationResult = ApolloReactCommon.MutationResult<SetTaskSectionDueDateMutation>;
export type SetTaskSectionDueDateMutationOptions = ApolloReactCommon.BaseMutationOptions<SetTaskSectionDueDateMutation, SetTaskSectionDueDateMutationVariables>;
export const ClearTaskSectionDueDateDocument = gql`
    mutation ClearTaskSectionDueDate($input: ClearTaskSectionDueDateInput!) {
  clearTaskSectionDueDate(input: $input) {
    id
    sections {
      id
      name
      description
      ordering
      proportionComplete
      proportionReleased
      isHighlighted
      officialAdvice
      additionalAdvice
      dueDate {
        id
        date
        isAllDay
        name
        description
      }
      questions {
        id
        text
        description
        isReleased
        proportionComplete
        isHighlighted
        officialAdvice
        additionalAdvice
        status
        parts {
          id
          label
          isComplete
          isHighlighted
          officialAdvice
          additionalAdvice
        }
      }
    }
  }
}
    `;

/**
 * __useClearTaskSectionDueDateMutation__
 *
 * To run a mutation, you first call `useClearTaskSectionDueDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearTaskSectionDueDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearTaskSectionDueDateMutation, { data, loading, error }] = useClearTaskSectionDueDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClearTaskSectionDueDateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClearTaskSectionDueDateMutation, ClearTaskSectionDueDateMutationVariables>) {
        return ApolloReactHooks.useMutation<ClearTaskSectionDueDateMutation, ClearTaskSectionDueDateMutationVariables>(ClearTaskSectionDueDateDocument, baseOptions);
      }
export type ClearTaskSectionDueDateMutationHookResult = ReturnType<typeof useClearTaskSectionDueDateMutation>;
export type ClearTaskSectionDueDateMutationResult = ApolloReactCommon.MutationResult<ClearTaskSectionDueDateMutation>;
export type ClearTaskSectionDueDateMutationOptions = ApolloReactCommon.BaseMutationOptions<ClearTaskSectionDueDateMutation, ClearTaskSectionDueDateMutationVariables>;
export const ReleaseTaskDocument = gql`
    mutation ReleaseTask($input: ReleaseTaskInput!) {
  releaseTask(input: $input) {
    id
    name
    description
    sectionCount
    officialAdvice
    additionalAdvice
    ... on MatterTask {
      status
      proportionComplete
      proportionReleased
      isHighlighted
      dueDate {
        id
        date
        isAllDay
        name
        description
      }
    }
    sections {
      id
      name
      description
      ordering
      proportionComplete
      proportionReleased
      isHighlighted
      officialAdvice
      additionalAdvice
      dueDate {
        id
        date
        isAllDay
        name
        description
      }
      questions {
        id
        text
        description
        isReleased
        proportionComplete
        isHighlighted
        officialAdvice
        additionalAdvice
        status
        parts {
          id
          label
          isComplete
          isHighlighted
          officialAdvice
          additionalAdvice
        }
      }
    }
  }
}
    `;

/**
 * __useReleaseTaskMutation__
 *
 * To run a mutation, you first call `useReleaseTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReleaseTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [releaseTaskMutation, { data, loading, error }] = useReleaseTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReleaseTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReleaseTaskMutation, ReleaseTaskMutationVariables>) {
        return ApolloReactHooks.useMutation<ReleaseTaskMutation, ReleaseTaskMutationVariables>(ReleaseTaskDocument, baseOptions);
      }
export type ReleaseTaskMutationHookResult = ReturnType<typeof useReleaseTaskMutation>;
export type ReleaseTaskMutationResult = ApolloReactCommon.MutationResult<ReleaseTaskMutation>;
export type ReleaseTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<ReleaseTaskMutation, ReleaseTaskMutationVariables>;
export const UnreleaseTaskDocument = gql`
    mutation UnreleaseTask($input: UnreleaseTaskInput!) {
  unreleaseTask(input: $input) {
    id
    name
    description
    sectionCount
    ... on MatterTask {
      status
      proportionComplete
      proportionReleased
      dueDate {
        id
        date
        isAllDay
        name
        description
      }
      isHighlighted
    }
    officialAdvice
    additionalAdvice
    sections {
      id
      name
      description
      ordering
      proportionComplete
      proportionReleased
      isHighlighted
      officialAdvice
      additionalAdvice
      dueDate {
        id
        date
        isAllDay
        name
        description
      }
      questions {
        id
        text
        description
        isReleased
        proportionComplete
        isHighlighted
        officialAdvice
        additionalAdvice
        status
        parts {
          id
          label
          isComplete
          isHighlighted
          officialAdvice
          additionalAdvice
        }
      }
    }
  }
}
    `;

/**
 * __useUnreleaseTaskMutation__
 *
 * To run a mutation, you first call `useUnreleaseTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnreleaseTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unreleaseTaskMutation, { data, loading, error }] = useUnreleaseTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnreleaseTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnreleaseTaskMutation, UnreleaseTaskMutationVariables>) {
        return ApolloReactHooks.useMutation<UnreleaseTaskMutation, UnreleaseTaskMutationVariables>(UnreleaseTaskDocument, baseOptions);
      }
export type UnreleaseTaskMutationHookResult = ReturnType<typeof useUnreleaseTaskMutation>;
export type UnreleaseTaskMutationResult = ApolloReactCommon.MutationResult<UnreleaseTaskMutation>;
export type UnreleaseTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<UnreleaseTaskMutation, UnreleaseTaskMutationVariables>;
export const ReleaseTaskSectionDocument = gql`
    mutation ReleaseTaskSection($input: ReleaseTaskSectionInput!) {
  releaseTaskSection(input: $input) {
    id
    name
    description
    sectionCount
    ... on MatterTask {
      status
      proportionComplete
      proportionReleased
      dueDate {
        id
        date
        isAllDay
        name
        description
      }
      isHighlighted
    }
    officialAdvice
    additionalAdvice
    sections {
      id
      name
      description
      ordering
      proportionComplete
      proportionReleased
      isHighlighted
      officialAdvice
      additionalAdvice
      dueDate {
        id
        date
        isAllDay
        name
        description
      }
      questions {
        id
        text
        description
        isReleased
        proportionComplete
        isHighlighted
        officialAdvice
        additionalAdvice
        status
        parts {
          id
          label
          isComplete
          isHighlighted
          officialAdvice
          additionalAdvice
        }
      }
    }
  }
}
    `;

/**
 * __useReleaseTaskSectionMutation__
 *
 * To run a mutation, you first call `useReleaseTaskSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReleaseTaskSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [releaseTaskSectionMutation, { data, loading, error }] = useReleaseTaskSectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReleaseTaskSectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReleaseTaskSectionMutation, ReleaseTaskSectionMutationVariables>) {
        return ApolloReactHooks.useMutation<ReleaseTaskSectionMutation, ReleaseTaskSectionMutationVariables>(ReleaseTaskSectionDocument, baseOptions);
      }
export type ReleaseTaskSectionMutationHookResult = ReturnType<typeof useReleaseTaskSectionMutation>;
export type ReleaseTaskSectionMutationResult = ApolloReactCommon.MutationResult<ReleaseTaskSectionMutation>;
export type ReleaseTaskSectionMutationOptions = ApolloReactCommon.BaseMutationOptions<ReleaseTaskSectionMutation, ReleaseTaskSectionMutationVariables>;
export const UnreleaseTaskSectionDocument = gql`
    mutation UnreleaseTaskSection($input: UnreleaseTaskSectionInput!) {
  unreleaseTaskSection(input: $input) {
    id
    name
    description
    sectionCount
    ... on MatterTask {
      status
      proportionComplete
      proportionReleased
      dueDate {
        id
        date
        isAllDay
        name
        description
      }
      isHighlighted
    }
    officialAdvice
    additionalAdvice
    sections {
      id
      name
      description
      ordering
      proportionComplete
      proportionReleased
      isHighlighted
      officialAdvice
      additionalAdvice
      dueDate {
        id
        date
        isAllDay
        name
        description
      }
      questions {
        id
        text
        description
        isReleased
        proportionComplete
        isHighlighted
        officialAdvice
        additionalAdvice
        status
        parts {
          id
          label
          isComplete
          isHighlighted
          officialAdvice
          additionalAdvice
        }
      }
    }
  }
}
    `;

/**
 * __useUnreleaseTaskSectionMutation__
 *
 * To run a mutation, you first call `useUnreleaseTaskSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnreleaseTaskSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unreleaseTaskSectionMutation, { data, loading, error }] = useUnreleaseTaskSectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnreleaseTaskSectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnreleaseTaskSectionMutation, UnreleaseTaskSectionMutationVariables>) {
        return ApolloReactHooks.useMutation<UnreleaseTaskSectionMutation, UnreleaseTaskSectionMutationVariables>(UnreleaseTaskSectionDocument, baseOptions);
      }
export type UnreleaseTaskSectionMutationHookResult = ReturnType<typeof useUnreleaseTaskSectionMutation>;
export type UnreleaseTaskSectionMutationResult = ApolloReactCommon.MutationResult<UnreleaseTaskSectionMutation>;
export type UnreleaseTaskSectionMutationOptions = ApolloReactCommon.BaseMutationOptions<UnreleaseTaskSectionMutation, UnreleaseTaskSectionMutationVariables>;
export const ChangeTaskAdviceDocument = gql`
    mutation ChangeTaskAdvice($input: ChangeTaskAdviceInput!) {
  changeTaskAdvice(input: $input) {
    id
    officialAdvice
    additionalAdvice
  }
}
    `;

/**
 * __useChangeTaskAdviceMutation__
 *
 * To run a mutation, you first call `useChangeTaskAdviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTaskAdviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTaskAdviceMutation, { data, loading, error }] = useChangeTaskAdviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeTaskAdviceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeTaskAdviceMutation, ChangeTaskAdviceMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeTaskAdviceMutation, ChangeTaskAdviceMutationVariables>(ChangeTaskAdviceDocument, baseOptions);
      }
export type ChangeTaskAdviceMutationHookResult = ReturnType<typeof useChangeTaskAdviceMutation>;
export type ChangeTaskAdviceMutationResult = ApolloReactCommon.MutationResult<ChangeTaskAdviceMutation>;
export type ChangeTaskAdviceMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeTaskAdviceMutation, ChangeTaskAdviceMutationVariables>;
export const ChangeTaskSectionAdviceDocument = gql`
    mutation ChangeTaskSectionAdvice($input: ChangeTaskSectionAdviceInput!) {
  changeTaskSectionAdvice(input: $input) {
    id
    sections {
      id
      officialAdvice
      additionalAdvice
    }
  }
}
    `;

/**
 * __useChangeTaskSectionAdviceMutation__
 *
 * To run a mutation, you first call `useChangeTaskSectionAdviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTaskSectionAdviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTaskSectionAdviceMutation, { data, loading, error }] = useChangeTaskSectionAdviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeTaskSectionAdviceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeTaskSectionAdviceMutation, ChangeTaskSectionAdviceMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeTaskSectionAdviceMutation, ChangeTaskSectionAdviceMutationVariables>(ChangeTaskSectionAdviceDocument, baseOptions);
      }
export type ChangeTaskSectionAdviceMutationHookResult = ReturnType<typeof useChangeTaskSectionAdviceMutation>;
export type ChangeTaskSectionAdviceMutationResult = ApolloReactCommon.MutationResult<ChangeTaskSectionAdviceMutation>;
export type ChangeTaskSectionAdviceMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeTaskSectionAdviceMutation, ChangeTaskSectionAdviceMutationVariables>;
export const ChangeTaskMatterTypesDocument = gql`
    mutation ChangeTaskMatterTypes($input: ChangeTaskMatterTypesInput!) {
  changeTaskMatterTypes(input: $input) {
    id
    matterTypes
  }
}
    `;

/**
 * __useChangeTaskMatterTypesMutation__
 *
 * To run a mutation, you first call `useChangeTaskMatterTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTaskMatterTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTaskMatterTypesMutation, { data, loading, error }] = useChangeTaskMatterTypesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeTaskMatterTypesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeTaskMatterTypesMutation, ChangeTaskMatterTypesMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeTaskMatterTypesMutation, ChangeTaskMatterTypesMutationVariables>(ChangeTaskMatterTypesDocument, baseOptions);
      }
export type ChangeTaskMatterTypesMutationHookResult = ReturnType<typeof useChangeTaskMatterTypesMutation>;
export type ChangeTaskMatterTypesMutationResult = ApolloReactCommon.MutationResult<ChangeTaskMatterTypesMutation>;
export type ChangeTaskMatterTypesMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeTaskMatterTypesMutation, ChangeTaskMatterTypesMutationVariables>;
export const CreatePrivateTopicDocument = gql`
    mutation CreatePrivateTopic($input: CreatePrivateTopicInput!) {
  createPrivateTopic(input: $input) {
    id
    ownerId
    name
    slug
    colour
  }
}
    `;

/**
 * __useCreatePrivateTopicMutation__
 *
 * To run a mutation, you first call `useCreatePrivateTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePrivateTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPrivateTopicMutation, { data, loading, error }] = useCreatePrivateTopicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePrivateTopicMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePrivateTopicMutation, CreatePrivateTopicMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePrivateTopicMutation, CreatePrivateTopicMutationVariables>(CreatePrivateTopicDocument, baseOptions);
      }
export type CreatePrivateTopicMutationHookResult = ReturnType<typeof useCreatePrivateTopicMutation>;
export type CreatePrivateTopicMutationResult = ApolloReactCommon.MutationResult<CreatePrivateTopicMutation>;
export type CreatePrivateTopicMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePrivateTopicMutation, CreatePrivateTopicMutationVariables>;
export const UpdateTopicDocument = gql`
    mutation UpdateTopic($input: UpdateTopicInput!) {
  updateTopic(input: $input) {
    id
    name
    slug
    colour
    ... on PrivateTopic {
      ownerId
    }
    ... on FirmTopic {
      firmId
    }
  }
}
    `;

/**
 * __useUpdateTopicMutation__
 *
 * To run a mutation, you first call `useUpdateTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTopicMutation, { data, loading, error }] = useUpdateTopicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTopicMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTopicMutation, UpdateTopicMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTopicMutation, UpdateTopicMutationVariables>(UpdateTopicDocument, baseOptions);
      }
export type UpdateTopicMutationHookResult = ReturnType<typeof useUpdateTopicMutation>;
export type UpdateTopicMutationResult = ApolloReactCommon.MutationResult<UpdateTopicMutation>;
export type UpdateTopicMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTopicMutation, UpdateTopicMutationVariables>;
export const RemoveTopicDocument = gql`
    mutation RemoveTopic($input: RemoveTopicInput!) {
  removeTopic(input: $input) {
    topicId
    removedOn
  }
}
    `;

/**
 * __useRemoveTopicMutation__
 *
 * To run a mutation, you first call `useRemoveTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTopicMutation, { data, loading, error }] = useRemoveTopicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTopicMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveTopicMutation, RemoveTopicMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveTopicMutation, RemoveTopicMutationVariables>(RemoveTopicDocument, baseOptions);
      }
export type RemoveTopicMutationHookResult = ReturnType<typeof useRemoveTopicMutation>;
export type RemoveTopicMutationResult = ApolloReactCommon.MutationResult<RemoveTopicMutation>;
export type RemoveTopicMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveTopicMutation, RemoveTopicMutationVariables>;
export const BreadcrumbsQueryDocument = gql`
    query BreadcrumbsQuery {
  getBreadcrumbs @client {
    name
    to
    image
  }
}
    `;

/**
 * __useBreadcrumbsQueryQuery__
 *
 * To run a query within a React component, call `useBreadcrumbsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBreadcrumbsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBreadcrumbsQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useBreadcrumbsQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BreadcrumbsQueryQuery, BreadcrumbsQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<BreadcrumbsQueryQuery, BreadcrumbsQueryQueryVariables>(BreadcrumbsQueryDocument, baseOptions);
      }
export function useBreadcrumbsQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BreadcrumbsQueryQuery, BreadcrumbsQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BreadcrumbsQueryQuery, BreadcrumbsQueryQueryVariables>(BreadcrumbsQueryDocument, baseOptions);
        }
export type BreadcrumbsQueryQueryHookResult = ReturnType<typeof useBreadcrumbsQueryQuery>;
export type BreadcrumbsQueryLazyQueryHookResult = ReturnType<typeof useBreadcrumbsQueryLazyQuery>;
export type BreadcrumbsQueryQueryResult = ApolloReactCommon.QueryResult<BreadcrumbsQueryQuery, BreadcrumbsQueryQueryVariables>;
export const ListClientAccountsForFirmDocument = gql`
    query ListClientAccountsForFirm($firmId: ID!, $clientId: ID) {
  clientAccountsForFirm(firmId: $firmId, clientId: $clientId) {
    items {
      id
      isHighlighted
      status
      referenceNumber
      client {
        id
        preferredName
        firstName
        lastName
        pictureUrl
        emailAddress
        mobilePhoneNumber
        nationalInsuranceNumber
        isVerified
        profileIsComplete
        status
        referenceNumber
        issues {
          id
          type
          isLinked
          linkCode
          linkRequested
          opponentIssue {
            id
            legalTeam {
              id
              jobTitle
              isLead
              firm {
                id
                name
              }
              professional {
                id
                emailAddress
                preferredName
                lastName
                type
              }
            }
          }
          opponent {
            preferredName
            firstName
            lastName
            emailAddress
            nationalInsuranceNumber
            clientReferenceNumber
            isVerified
            solicitor {
              firstName
              lastName
              emailAddress
              firmName
            }
          }
        }
      }
      leadProfessional {
        id
        preferredName
        firstName
        lastName
      }
      firm {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useListClientAccountsForFirmQuery__
 *
 * To run a query within a React component, call `useListClientAccountsForFirmQuery` and pass it any options that fit your needs.
 * When your component renders, `useListClientAccountsForFirmQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListClientAccountsForFirmQuery({
 *   variables: {
 *      firmId: // value for 'firmId'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useListClientAccountsForFirmQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListClientAccountsForFirmQuery, ListClientAccountsForFirmQueryVariables>) {
        return ApolloReactHooks.useQuery<ListClientAccountsForFirmQuery, ListClientAccountsForFirmQueryVariables>(ListClientAccountsForFirmDocument, baseOptions);
      }
export function useListClientAccountsForFirmLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListClientAccountsForFirmQuery, ListClientAccountsForFirmQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListClientAccountsForFirmQuery, ListClientAccountsForFirmQueryVariables>(ListClientAccountsForFirmDocument, baseOptions);
        }
export type ListClientAccountsForFirmQueryHookResult = ReturnType<typeof useListClientAccountsForFirmQuery>;
export type ListClientAccountsForFirmLazyQueryHookResult = ReturnType<typeof useListClientAccountsForFirmLazyQuery>;
export type ListClientAccountsForFirmQueryResult = ApolloReactCommon.QueryResult<ListClientAccountsForFirmQuery, ListClientAccountsForFirmQueryVariables>;
export const ListClientAccountsForProfessionalDocument = gql`
    query ListClientAccountsForProfessional($firmId: ID, $professionalId: ID!) {
  clientAccountsForProfessional(firmId: $firmId, professionalId: $professionalId) {
    items {
      id
      isHighlighted
      status
      referenceNumber
      client {
        id
        preferredName
        firstName
        lastName
        pictureUrl
        emailAddress
        mobilePhoneNumber
        nationalInsuranceNumber
        isVerified
        profileIsComplete
        status
        referenceNumber
        issues {
          id
          type
          isLinked
          linkCode
          linkRequested
          opponentIssue {
            id
            legalTeam {
              id
              jobTitle
              isLead
              firm {
                id
                name
              }
              professional {
                id
                emailAddress
                preferredName
                lastName
                type
              }
            }
          }
          opponent {
            preferredName
            firstName
            lastName
            emailAddress
            nationalInsuranceNumber
            clientReferenceNumber
            isVerified
            solicitor {
              firstName
              lastName
              emailAddress
              firmName
            }
          }
        }
      }
      leadProfessional {
        id
        preferredName
        firstName
        lastName
      }
      firm {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useListClientAccountsForProfessionalQuery__
 *
 * To run a query within a React component, call `useListClientAccountsForProfessionalQuery` and pass it any options that fit your needs.
 * When your component renders, `useListClientAccountsForProfessionalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListClientAccountsForProfessionalQuery({
 *   variables: {
 *      firmId: // value for 'firmId'
 *      professionalId: // value for 'professionalId'
 *   },
 * });
 */
export function useListClientAccountsForProfessionalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListClientAccountsForProfessionalQuery, ListClientAccountsForProfessionalQueryVariables>) {
        return ApolloReactHooks.useQuery<ListClientAccountsForProfessionalQuery, ListClientAccountsForProfessionalQueryVariables>(ListClientAccountsForProfessionalDocument, baseOptions);
      }
export function useListClientAccountsForProfessionalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListClientAccountsForProfessionalQuery, ListClientAccountsForProfessionalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListClientAccountsForProfessionalQuery, ListClientAccountsForProfessionalQueryVariables>(ListClientAccountsForProfessionalDocument, baseOptions);
        }
export type ListClientAccountsForProfessionalQueryHookResult = ReturnType<typeof useListClientAccountsForProfessionalQuery>;
export type ListClientAccountsForProfessionalLazyQueryHookResult = ReturnType<typeof useListClientAccountsForProfessionalLazyQuery>;
export type ListClientAccountsForProfessionalQueryResult = ApolloReactCommon.QueryResult<ListClientAccountsForProfessionalQuery, ListClientAccountsForProfessionalQueryVariables>;
export const ListClientAccountsForClientDocument = gql`
    query ListClientAccountsForClient($clientId: ID!, $firmId: ID) {
  clientAccountsForClient(clientId: $clientId, firmId: $firmId) {
    items {
      id
      status
      referenceNumber
      firm {
        id
        name
        logoUrl
      }
    }
  }
}
    `;

/**
 * __useListClientAccountsForClientQuery__
 *
 * To run a query within a React component, call `useListClientAccountsForClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useListClientAccountsForClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListClientAccountsForClientQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      firmId: // value for 'firmId'
 *   },
 * });
 */
export function useListClientAccountsForClientQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListClientAccountsForClientQuery, ListClientAccountsForClientQueryVariables>) {
        return ApolloReactHooks.useQuery<ListClientAccountsForClientQuery, ListClientAccountsForClientQueryVariables>(ListClientAccountsForClientDocument, baseOptions);
      }
export function useListClientAccountsForClientLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListClientAccountsForClientQuery, ListClientAccountsForClientQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListClientAccountsForClientQuery, ListClientAccountsForClientQueryVariables>(ListClientAccountsForClientDocument, baseOptions);
        }
export type ListClientAccountsForClientQueryHookResult = ReturnType<typeof useListClientAccountsForClientQuery>;
export type ListClientAccountsForClientLazyQueryHookResult = ReturnType<typeof useListClientAccountsForClientLazyQuery>;
export type ListClientAccountsForClientQueryResult = ApolloReactCommon.QueryResult<ListClientAccountsForClientQuery, ListClientAccountsForClientQueryVariables>;
export const ListClientAssignmentsForProfessionalDocument = gql`
    query ListClientAssignmentsForProfessional($professionalId: ID!, $firmId: ID) {
  assignmentsForProfessional(professionalId: $professionalId, firmId: $firmId) {
    id
    clientAccount {
      id
      isHighlighted
      status
      referenceNumber
      client {
        id
        preferredName
        firstName
        lastName
        pictureUrl
        emailAddress
        mobilePhoneNumber
        nationalInsuranceNumber
        isVerified
        profileIsComplete
        status
        referenceNumber
        issues {
          id
          type
          isLinked
          linkCode
          linkRequested
          opponentIssue {
            id
            legalTeam {
              id
              jobTitle
              isLead
              firm {
                id
                name
              }
              professional {
                id
                emailAddress
                preferredName
                lastName
                type
              }
            }
          }
          opponent {
            preferredName
            firstName
            lastName
            emailAddress
            nationalInsuranceNumber
            clientReferenceNumber
            isVerified
            solicitor {
              firstName
              lastName
              emailAddress
              firmName
            }
          }
        }
      }
      leadProfessional {
        id
        preferredName
        firstName
        lastName
      }
      firm {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useListClientAssignmentsForProfessionalQuery__
 *
 * To run a query within a React component, call `useListClientAssignmentsForProfessionalQuery` and pass it any options that fit your needs.
 * When your component renders, `useListClientAssignmentsForProfessionalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListClientAssignmentsForProfessionalQuery({
 *   variables: {
 *      professionalId: // value for 'professionalId'
 *      firmId: // value for 'firmId'
 *   },
 * });
 */
export function useListClientAssignmentsForProfessionalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListClientAssignmentsForProfessionalQuery, ListClientAssignmentsForProfessionalQueryVariables>) {
        return ApolloReactHooks.useQuery<ListClientAssignmentsForProfessionalQuery, ListClientAssignmentsForProfessionalQueryVariables>(ListClientAssignmentsForProfessionalDocument, baseOptions);
      }
export function useListClientAssignmentsForProfessionalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListClientAssignmentsForProfessionalQuery, ListClientAssignmentsForProfessionalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListClientAssignmentsForProfessionalQuery, ListClientAssignmentsForProfessionalQueryVariables>(ListClientAssignmentsForProfessionalDocument, baseOptions);
        }
export type ListClientAssignmentsForProfessionalQueryHookResult = ReturnType<typeof useListClientAssignmentsForProfessionalQuery>;
export type ListClientAssignmentsForProfessionalLazyQueryHookResult = ReturnType<typeof useListClientAssignmentsForProfessionalLazyQuery>;
export type ListClientAssignmentsForProfessionalQueryResult = ApolloReactCommon.QueryResult<ListClientAssignmentsForProfessionalQuery, ListClientAssignmentsForProfessionalQueryVariables>;
export const ListClientAssignmentsForClientDocument = gql`
    query ListClientAssignmentsForClient($clientId: ID!, $firmId: ID) {
  assignmentsForClient(clientId: $clientId, firmId: $firmId) {
    id
    isLead
    firmMember {
      id
      employeeNumber
      jobTitle
      office
      isActive
      isHighlighted
      activeSince
      role
      status
      professional {
        id
        preferredName
        firstName
        middleNames
        lastName
        workPhoneNumber
        emailAddress
        pictureUrl
        type
        status
        referenceNumber
        ... on FirmBarrister {
          sraNumber
        }
        ... on FirmSolicitor {
          sraNumber
        }
        ... on FirmParalegal {
          paralegalNumber
        }
      }
      firm {
        id
        name
        logoUrl
      }
    }
  }
}
    `;

/**
 * __useListClientAssignmentsForClientQuery__
 *
 * To run a query within a React component, call `useListClientAssignmentsForClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useListClientAssignmentsForClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListClientAssignmentsForClientQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      firmId: // value for 'firmId'
 *   },
 * });
 */
export function useListClientAssignmentsForClientQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListClientAssignmentsForClientQuery, ListClientAssignmentsForClientQueryVariables>) {
        return ApolloReactHooks.useQuery<ListClientAssignmentsForClientQuery, ListClientAssignmentsForClientQueryVariables>(ListClientAssignmentsForClientDocument, baseOptions);
      }
export function useListClientAssignmentsForClientLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListClientAssignmentsForClientQuery, ListClientAssignmentsForClientQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListClientAssignmentsForClientQuery, ListClientAssignmentsForClientQueryVariables>(ListClientAssignmentsForClientDocument, baseOptions);
        }
export type ListClientAssignmentsForClientQueryHookResult = ReturnType<typeof useListClientAssignmentsForClientQuery>;
export type ListClientAssignmentsForClientLazyQueryHookResult = ReturnType<typeof useListClientAssignmentsForClientLazyQuery>;
export type ListClientAssignmentsForClientQueryResult = ApolloReactCommon.QueryResult<ListClientAssignmentsForClientQuery, ListClientAssignmentsForClientQueryVariables>;
export const GetClientAccountDocument = gql`
    query GetClientAccount($clientAccountId: ID!) {
  clientAccount(clientAccountId: $clientAccountId) {
    id
    isHighlighted
    status
    referenceNumber
    client {
      id
      preferredName
      firstName
      lastName
      pictureUrl
      emailAddress
      mobilePhoneNumber
      nationalInsuranceNumber
      profileIsComplete
      status
      referenceNumber
      issues {
        id
        type
        isLinked
        linkCode
        linkRequested
        opponentIssue {
          id
          legalTeam {
            id
            jobTitle
            isLead
            firm {
              id
              name
            }
            professional {
              id
              emailAddress
              preferredName
              lastName
              type
            }
          }
        }
        opponent {
          preferredName
          firstName
          lastName
          emailAddress
          nationalInsuranceNumber
          clientReferenceNumber
          isVerified
          solicitor {
            firstName
            lastName
            emailAddress
            firmName
          }
        }
        opponentConversation {
          id
        }
      }
    }
    leadProfessional {
      id
      preferredName
      firstName
      lastName
    }
    firm {
      id
      name
    }
  }
}
    `;

/**
 * __useGetClientAccountQuery__
 *
 * To run a query within a React component, call `useGetClientAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientAccountQuery({
 *   variables: {
 *      clientAccountId: // value for 'clientAccountId'
 *   },
 * });
 */
export function useGetClientAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClientAccountQuery, GetClientAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClientAccountQuery, GetClientAccountQueryVariables>(GetClientAccountDocument, baseOptions);
      }
export function useGetClientAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClientAccountQuery, GetClientAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClientAccountQuery, GetClientAccountQueryVariables>(GetClientAccountDocument, baseOptions);
        }
export type GetClientAccountQueryHookResult = ReturnType<typeof useGetClientAccountQuery>;
export type GetClientAccountLazyQueryHookResult = ReturnType<typeof useGetClientAccountLazyQuery>;
export type GetClientAccountQueryResult = ApolloReactCommon.QueryResult<GetClientAccountQuery, GetClientAccountQueryVariables>;
export const ListClientAccountsByNotesDocument = gql`
    query ListClientAccountsByNotes($firmId: ID!, $professionalId: ID!) {
  clientAccountsForProfessional(professionalId: $professionalId, firmId: $firmId) {
    items {
      id
      status
      referenceNumber
      client {
        id
        preferredName
        firstName
        lastName
        pictureUrl
      }
      notes {
        items {
          id
          isViewed
          startedAt
          revisions {
            draftedAt
          }
        }
      }
    }
  }
}
    `;

/**
 * __useListClientAccountsByNotesQuery__
 *
 * To run a query within a React component, call `useListClientAccountsByNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListClientAccountsByNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListClientAccountsByNotesQuery({
 *   variables: {
 *      firmId: // value for 'firmId'
 *      professionalId: // value for 'professionalId'
 *   },
 * });
 */
export function useListClientAccountsByNotesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListClientAccountsByNotesQuery, ListClientAccountsByNotesQueryVariables>) {
        return ApolloReactHooks.useQuery<ListClientAccountsByNotesQuery, ListClientAccountsByNotesQueryVariables>(ListClientAccountsByNotesDocument, baseOptions);
      }
export function useListClientAccountsByNotesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListClientAccountsByNotesQuery, ListClientAccountsByNotesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListClientAccountsByNotesQuery, ListClientAccountsByNotesQueryVariables>(ListClientAccountsByNotesDocument, baseOptions);
        }
export type ListClientAccountsByNotesQueryHookResult = ReturnType<typeof useListClientAccountsByNotesQuery>;
export type ListClientAccountsByNotesLazyQueryHookResult = ReturnType<typeof useListClientAccountsByNotesLazyQuery>;
export type ListClientAccountsByNotesQueryResult = ApolloReactCommon.QueryResult<ListClientAccountsByNotesQuery, ListClientAccountsByNotesQueryVariables>;
export const ListClientAccountHighlightsDocument = gql`
    query ListClientAccountHighlights($firmId: ID!, $professionalId: ID!) {
  clientAccountsForProfessional(professionalId: $professionalId, firmId: $firmId) {
    items {
      id
      status
      referenceNumber
      client {
        id
        preferredName
        firstName
        lastName
        pictureUrl
      }
      highlights {
        conversationCount
        messageCount
        noteCount
        firmClientCount
        clientAssignmentCount
        matterCount
        keyDateCount
        taskCount
        clientCount
        questionCount
        answerCount
        firmMemberCount
        contactCount
      }
    }
  }
}
    `;

/**
 * __useListClientAccountHighlightsQuery__
 *
 * To run a query within a React component, call `useListClientAccountHighlightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListClientAccountHighlightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListClientAccountHighlightsQuery({
 *   variables: {
 *      firmId: // value for 'firmId'
 *      professionalId: // value for 'professionalId'
 *   },
 * });
 */
export function useListClientAccountHighlightsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListClientAccountHighlightsQuery, ListClientAccountHighlightsQueryVariables>) {
        return ApolloReactHooks.useQuery<ListClientAccountHighlightsQuery, ListClientAccountHighlightsQueryVariables>(ListClientAccountHighlightsDocument, baseOptions);
      }
export function useListClientAccountHighlightsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListClientAccountHighlightsQuery, ListClientAccountHighlightsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListClientAccountHighlightsQuery, ListClientAccountHighlightsQueryVariables>(ListClientAccountHighlightsDocument, baseOptions);
        }
export type ListClientAccountHighlightsQueryHookResult = ReturnType<typeof useListClientAccountHighlightsQuery>;
export type ListClientAccountHighlightsLazyQueryHookResult = ReturnType<typeof useListClientAccountHighlightsLazyQuery>;
export type ListClientAccountHighlightsQueryResult = ApolloReactCommon.QueryResult<ListClientAccountHighlightsQuery, ListClientAccountHighlightsQueryVariables>;
export const GetClientProfileDocument = gql`
    query GetClientProfile($clientId: ID!) {
  client(clientId: $clientId) {
    id
    name
    fullName
    preferredName
    firstName
    middleNames
    lastName
    previousFirstName
    previousLastName
    previousMiddleNames
    pictureUrl
    birthDate
    emailAddress
    mobilePhoneNumber
    homePhoneNumber
    workPhoneNumber
    nationalInsuranceNumber
    homeAddress {
      ...addressFields
    }
    previousHomeAddresses {
      ...addressFields
    }
    workAddress {
      name
      ...addressFields
    }
    addressProof {
      id
      type
      reference
      assetUrl
      country
      expiresAt
      assetFileId
      assetMime
      assetFileVersion
    }
    identityProof {
      id
      type
      reference
      assetUrl
      country
      expiresAt
      assetFileId
      assetMime
      assetFileVersion
    }
    bankDetails {
      name
      branchAddress {
        ...addressFields
      }
      accountNumber
      sortCode
    }
    profileIsComplete
    status
    referenceNumber
    isVerified
    verifiedAt
    issues {
      id
      type
      isLinked
      linkCode
      linkRequested
      opponentIssue {
        id
        legalTeam {
          id
          jobTitle
          isLead
          firm {
            id
            name
          }
          professional {
            id
            emailAddress
            preferredName
            lastName
            type
          }
        }
      }
      opponent {
        preferredName
        firstName
        lastName
        emailAddress
        nationalInsuranceNumber
        clientReferenceNumber
        isVerified
        solicitor {
          firstName
          lastName
          emailAddress
          firmName
        }
      }
    }
  }
}
    ${AddressFieldsFragmentDoc}`;

/**
 * __useGetClientProfileQuery__
 *
 * To run a query within a React component, call `useGetClientProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientProfileQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetClientProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClientProfileQuery, GetClientProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClientProfileQuery, GetClientProfileQueryVariables>(GetClientProfileDocument, baseOptions);
      }
export function useGetClientProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClientProfileQuery, GetClientProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClientProfileQuery, GetClientProfileQueryVariables>(GetClientProfileDocument, baseOptions);
        }
export type GetClientProfileQueryHookResult = ReturnType<typeof useGetClientProfileQuery>;
export type GetClientProfileLazyQueryHookResult = ReturnType<typeof useGetClientProfileLazyQuery>;
export type GetClientProfileQueryResult = ApolloReactCommon.QueryResult<GetClientProfileQuery, GetClientProfileQueryVariables>;
export const ContactListDocument = gql`
    query ContactList {
  contactList {
    id
    preferredName
    firstName
    lastName
    pictureUrl
    emailAddress
    isVerified
    isTransparentlyUser
    isAssociatedWithFirm
    type
    status
    conversationId
    title
    isHighlighted
    firm {
      id
      name
      logoUrl
    }
    isAssociatedWithIssue
    issue {
      id
      type
    }
  }
}
    `;

/**
 * __useContactListQuery__
 *
 * To run a query within a React component, call `useContactListQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactListQuery({
 *   variables: {
 *   },
 * });
 */
export function useContactListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContactListQuery, ContactListQueryVariables>) {
        return ApolloReactHooks.useQuery<ContactListQuery, ContactListQueryVariables>(ContactListDocument, baseOptions);
      }
export function useContactListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContactListQuery, ContactListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContactListQuery, ContactListQueryVariables>(ContactListDocument, baseOptions);
        }
export type ContactListQueryHookResult = ReturnType<typeof useContactListQuery>;
export type ContactListLazyQueryHookResult = ReturnType<typeof useContactListLazyQuery>;
export type ContactListQueryResult = ApolloReactCommon.QueryResult<ContactListQuery, ContactListQueryVariables>;
export const ListConversationsDocument = gql`
    query ListConversations($memberId: ID!) {
  conversations(memberId: $memberId) {
    id
    pictureUrl
    name
    description
    participants {
      id
      preferredName
      firstName
      lastName
      pictureUrl
      role
      title
      status
    }
    groups {
      id
      name
      pictureUrl
      members {
        id
        name
        pictureUrl
        title
      }
    }
    startedAt
    unreadMessageCount
    lastMessageReadAt
    lastMessageSentAt
    lastMessageDeliveredAt
    pausedUntil
    lastMessage {
      id
    }
    metadata {
      ... on KeyValueArray {
        key
        values: value
      }
      ... on KeyValueString {
        key
        value
      }
    }
  }
}
    `;

/**
 * __useListConversationsQuery__
 *
 * To run a query within a React component, call `useListConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListConversationsQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useListConversationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListConversationsQuery, ListConversationsQueryVariables>) {
        return ApolloReactHooks.useQuery<ListConversationsQuery, ListConversationsQueryVariables>(ListConversationsDocument, baseOptions);
      }
export function useListConversationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListConversationsQuery, ListConversationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListConversationsQuery, ListConversationsQueryVariables>(ListConversationsDocument, baseOptions);
        }
export type ListConversationsQueryHookResult = ReturnType<typeof useListConversationsQuery>;
export type ListConversationsLazyQueryHookResult = ReturnType<typeof useListConversationsLazyQuery>;
export type ListConversationsQueryResult = ApolloReactCommon.QueryResult<ListConversationsQuery, ListConversationsQueryVariables>;
export const GetConversationDocument = gql`
    query GetConversation($conversationId: ID!) {
  conversation(conversationId: $conversationId) {
    participants {
      id
      preferredName
      firstName
      lastName
      pictureUrl
      role
      title
      status
    }
    groups {
      id
      name
      pictureUrl
      members {
        id
        name
        pictureUrl
        title
      }
    }
    draft {
      body
    }
    pausedUntil
  }
}
    `;

/**
 * __useGetConversationQuery__
 *
 * To run a query within a React component, call `useGetConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useGetConversationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetConversationQuery, GetConversationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetConversationQuery, GetConversationQueryVariables>(GetConversationDocument, baseOptions);
      }
export function useGetConversationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetConversationQuery, GetConversationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetConversationQuery, GetConversationQueryVariables>(GetConversationDocument, baseOptions);
        }
export type GetConversationQueryHookResult = ReturnType<typeof useGetConversationQuery>;
export type GetConversationLazyQueryHookResult = ReturnType<typeof useGetConversationLazyQuery>;
export type GetConversationQueryResult = ApolloReactCommon.QueryResult<GetConversationQuery, GetConversationQueryVariables>;
export const GetConversationWithDocument = gql`
    query GetConversationWith($participantId: ID!) {
  conversationWith(participantId: $participantId) {
    id
  }
}
    `;

/**
 * __useGetConversationWithQuery__
 *
 * To run a query within a React component, call `useGetConversationWithQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationWithQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationWithQuery({
 *   variables: {
 *      participantId: // value for 'participantId'
 *   },
 * });
 */
export function useGetConversationWithQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetConversationWithQuery, GetConversationWithQueryVariables>) {
        return ApolloReactHooks.useQuery<GetConversationWithQuery, GetConversationWithQueryVariables>(GetConversationWithDocument, baseOptions);
      }
export function useGetConversationWithLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetConversationWithQuery, GetConversationWithQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetConversationWithQuery, GetConversationWithQueryVariables>(GetConversationWithDocument, baseOptions);
        }
export type GetConversationWithQueryHookResult = ReturnType<typeof useGetConversationWithQuery>;
export type GetConversationWithLazyQueryHookResult = ReturnType<typeof useGetConversationWithLazyQuery>;
export type GetConversationWithQueryResult = ApolloReactCommon.QueryResult<GetConversationWithQuery, GetConversationWithQueryVariables>;
export const ListClientConversationsDocument = gql`
    query ListClientConversations($clientAccountId: ID!) {
  clientAccount(clientAccountId: $clientAccountId) {
    id
    clientConversation {
      ...conversation
    }
    firmConversation {
      ...conversation
    }
    client {
      id
      issues {
        id
        type
        opponentConversation {
          ...conversation
        }
        disclosureConversation {
          ...conversation
        }
      }
    }
  }
}
    ${ConversationFragmentDoc}`;

/**
 * __useListClientConversationsQuery__
 *
 * To run a query within a React component, call `useListClientConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListClientConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListClientConversationsQuery({
 *   variables: {
 *      clientAccountId: // value for 'clientAccountId'
 *   },
 * });
 */
export function useListClientConversationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListClientConversationsQuery, ListClientConversationsQueryVariables>) {
        return ApolloReactHooks.useQuery<ListClientConversationsQuery, ListClientConversationsQueryVariables>(ListClientConversationsDocument, baseOptions);
      }
export function useListClientConversationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListClientConversationsQuery, ListClientConversationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListClientConversationsQuery, ListClientConversationsQueryVariables>(ListClientConversationsDocument, baseOptions);
        }
export type ListClientConversationsQueryHookResult = ReturnType<typeof useListClientConversationsQuery>;
export type ListClientConversationsLazyQueryHookResult = ReturnType<typeof useListClientConversationsLazyQuery>;
export type ListClientConversationsQueryResult = ApolloReactCommon.QueryResult<ListClientConversationsQuery, ListClientConversationsQueryVariables>;
export const GetCurrentUserClientDocument = gql`
    query GetCurrentUserClient {
  getCurrentUser @client {
    id
    givenName
    middleName
    familyName
    picture
    email
    phone
    roles {
      id
      name
      description
    }
  }
}
    `;

/**
 * __useGetCurrentUserClientQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserClientQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserClientQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCurrentUserClientQuery, GetCurrentUserClientQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCurrentUserClientQuery, GetCurrentUserClientQueryVariables>(GetCurrentUserClientDocument, baseOptions);
      }
export function useGetCurrentUserClientLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCurrentUserClientQuery, GetCurrentUserClientQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCurrentUserClientQuery, GetCurrentUserClientQueryVariables>(GetCurrentUserClientDocument, baseOptions);
        }
export type GetCurrentUserClientQueryHookResult = ReturnType<typeof useGetCurrentUserClientQuery>;
export type GetCurrentUserClientLazyQueryHookResult = ReturnType<typeof useGetCurrentUserClientLazyQuery>;
export type GetCurrentUserClientQueryResult = ApolloReactCommon.QueryResult<GetCurrentUserClientQuery, GetCurrentUserClientQueryVariables>;
export const GetCurrentUserDocument = gql`
    query GetCurrentUser {
  currentUser {
    id
    preferredName
    givenName
    middleName
    familyName
    picture
    email
    phone
    blocked
    referenceNumber
    roles {
      id
      name
      description
    }
  }
}
    `;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, baseOptions);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, baseOptions);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = ApolloReactCommon.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetFirmDocument = gql`
    query GetFirm($firmId: ID!) {
  firm(firmId: $firmId) {
    id
    name
    logoUrl
    sraNumber
    seatCount
    vacantSeatCount
    occupiedSeatCount
    members {
      id
      role
      employeeNumber
      jobTitle
      office
      isActive
      isHighlighted
      activeSince
      status
      professional {
        id
        preferredName
        firstName
        middleNames
        lastName
        workPhoneNumber
        emailAddress
        pictureUrl
        type
        status
        referenceNumber
        ... on FirmBarrister {
          sraNumber
        }
        ... on FirmSolicitor {
          sraNumber
        }
        ... on FirmParalegal {
          paralegalNumber
        }
      }
      firm {
        id
        name
        logoUrl
      }
    }
  }
}
    `;

/**
 * __useGetFirmQuery__
 *
 * To run a query within a React component, call `useGetFirmQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFirmQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFirmQuery({
 *   variables: {
 *      firmId: // value for 'firmId'
 *   },
 * });
 */
export function useGetFirmQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFirmQuery, GetFirmQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFirmQuery, GetFirmQueryVariables>(GetFirmDocument, baseOptions);
      }
export function useGetFirmLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFirmQuery, GetFirmQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFirmQuery, GetFirmQueryVariables>(GetFirmDocument, baseOptions);
        }
export type GetFirmQueryHookResult = ReturnType<typeof useGetFirmQuery>;
export type GetFirmLazyQueryHookResult = ReturnType<typeof useGetFirmLazyQuery>;
export type GetFirmQueryResult = ApolloReactCommon.QueryResult<GetFirmQuery, GetFirmQueryVariables>;
export const GetFirmMemberDocument = gql`
    query GetFirmMember($firmMemberId: ID!) {
  firmMember(firmMemberId: $firmMemberId) {
    id
    role
    employeeNumber
    jobTitle
    office
    isActive
    isHighlighted
    activeSince
    status
    professional {
      id
      preferredName
      firstName
      middleNames
      lastName
      workPhoneNumber
      emailAddress
      pictureUrl
      type
      referenceNumber
      status
      ... on FirmBarrister {
        sraNumber
      }
      ... on FirmSolicitor {
        sraNumber
      }
      ... on FirmParalegal {
        paralegalNumber
      }
    }
    firm {
      id
      name
      logoUrl
    }
  }
}
    `;

/**
 * __useGetFirmMemberQuery__
 *
 * To run a query within a React component, call `useGetFirmMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFirmMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFirmMemberQuery({
 *   variables: {
 *      firmMemberId: // value for 'firmMemberId'
 *   },
 * });
 */
export function useGetFirmMemberQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFirmMemberQuery, GetFirmMemberQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFirmMemberQuery, GetFirmMemberQueryVariables>(GetFirmMemberDocument, baseOptions);
      }
export function useGetFirmMemberLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFirmMemberQuery, GetFirmMemberQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFirmMemberQuery, GetFirmMemberQueryVariables>(GetFirmMemberDocument, baseOptions);
        }
export type GetFirmMemberQueryHookResult = ReturnType<typeof useGetFirmMemberQuery>;
export type GetFirmMemberLazyQueryHookResult = ReturnType<typeof useGetFirmMemberLazyQuery>;
export type GetFirmMemberQueryResult = ApolloReactCommon.QueryResult<GetFirmMemberQuery, GetFirmMemberQueryVariables>;
export const ListFirmMembersDocument = gql`
    query ListFirmMembers($firmId: ID!) {
  firmMembers(firmId: $firmId) {
    id
    role
    employeeNumber
    jobTitle
    office
    isActive
    isHighlighted
    activeSince
    status
    professional {
      id
      preferredName
      firstName
      middleNames
      lastName
      workPhoneNumber
      emailAddress
      pictureUrl
      type
      referenceNumber
      status
      ... on FirmBarrister {
        sraNumber
      }
      ... on FirmSolicitor {
        sraNumber
      }
      ... on FirmParalegal {
        paralegalNumber
      }
    }
    firm {
      id
      name
      logoUrl
    }
  }
}
    `;

/**
 * __useListFirmMembersQuery__
 *
 * To run a query within a React component, call `useListFirmMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFirmMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFirmMembersQuery({
 *   variables: {
 *      firmId: // value for 'firmId'
 *   },
 * });
 */
export function useListFirmMembersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListFirmMembersQuery, ListFirmMembersQueryVariables>) {
        return ApolloReactHooks.useQuery<ListFirmMembersQuery, ListFirmMembersQueryVariables>(ListFirmMembersDocument, baseOptions);
      }
export function useListFirmMembersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListFirmMembersQuery, ListFirmMembersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListFirmMembersQuery, ListFirmMembersQueryVariables>(ListFirmMembersDocument, baseOptions);
        }
export type ListFirmMembersQueryHookResult = ReturnType<typeof useListFirmMembersQuery>;
export type ListFirmMembersLazyQueryHookResult = ReturnType<typeof useListFirmMembersLazyQuery>;
export type ListFirmMembersQueryResult = ApolloReactCommon.QueryResult<ListFirmMembersQuery, ListFirmMembersQueryVariables>;
export const ListFirmMembershipsDocument = gql`
    query ListFirmMemberships($professionalId: ID!) {
  firmMemberships(professionalId: $professionalId) {
    id
    employeeNumber
    jobTitle
    office
    isActive
    isHighlighted
    activeSince
    role
    status
    professional {
      id
      preferredName
      firstName
      middleNames
      lastName
      workPhoneNumber
      emailAddress
      pictureUrl
      type
      referenceNumber
      status
      ... on FirmBarrister {
        sraNumber
      }
      ... on FirmSolicitor {
        sraNumber
      }
      ... on FirmParalegal {
        paralegalNumber
      }
    }
    firm {
      id
      name
      sraNumber
      logoUrl
    }
  }
}
    `;

/**
 * __useListFirmMembershipsQuery__
 *
 * To run a query within a React component, call `useListFirmMembershipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFirmMembershipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFirmMembershipsQuery({
 *   variables: {
 *      professionalId: // value for 'professionalId'
 *   },
 * });
 */
export function useListFirmMembershipsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListFirmMembershipsQuery, ListFirmMembershipsQueryVariables>) {
        return ApolloReactHooks.useQuery<ListFirmMembershipsQuery, ListFirmMembershipsQueryVariables>(ListFirmMembershipsDocument, baseOptions);
      }
export function useListFirmMembershipsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListFirmMembershipsQuery, ListFirmMembershipsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListFirmMembershipsQuery, ListFirmMembershipsQueryVariables>(ListFirmMembershipsDocument, baseOptions);
        }
export type ListFirmMembershipsQueryHookResult = ReturnType<typeof useListFirmMembershipsQuery>;
export type ListFirmMembershipsLazyQueryHookResult = ReturnType<typeof useListFirmMembershipsLazyQuery>;
export type ListFirmMembershipsQueryResult = ApolloReactCommon.QueryResult<ListFirmMembershipsQuery, ListFirmMembershipsQueryVariables>;
export const ListFoldersDocument = gql`
    query ListFolders($parentId: ID) {
  folders(parentId: $parentId) {
    id
    owner {
      preferredName
      firstName
      lastName
    }
    name
    isRestricted
    subFolders {
      id
      name
      isRestricted
    }
    files {
      id
    }
    parent {
      id
      isRestricted
      name
      parent {
        id
        isRestricted
        name
        parent {
          id
          isRestricted
          name
          parent {
            id
            isRestricted
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useListFoldersQuery__
 *
 * To run a query within a React component, call `useListFoldersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFoldersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFoldersQuery({
 *   variables: {
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useListFoldersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListFoldersQuery, ListFoldersQueryVariables>) {
        return ApolloReactHooks.useQuery<ListFoldersQuery, ListFoldersQueryVariables>(ListFoldersDocument, baseOptions);
      }
export function useListFoldersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListFoldersQuery, ListFoldersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListFoldersQuery, ListFoldersQueryVariables>(ListFoldersDocument, baseOptions);
        }
export type ListFoldersQueryHookResult = ReturnType<typeof useListFoldersQuery>;
export type ListFoldersLazyQueryHookResult = ReturnType<typeof useListFoldersLazyQuery>;
export type ListFoldersQueryResult = ApolloReactCommon.QueryResult<ListFoldersQuery, ListFoldersQueryVariables>;
export const GetFoldersDocument = gql`
    query GetFolders($folderId: ID) {
  folder(folderId: $folderId) {
    id
    owner {
      preferredName
      firstName
      lastName
    }
    name
    isRestricted
    subFolders {
      id
      name
      isRestricted
    }
    files {
      id
      name
      type
      mime
      size
      version
      lastRevisedOn
      currentRevisionId
      revisions {
        id
        version
        originalName
        type
        mime
        size
        revisedOn
      }
      topics {
        id
        name
        colour
      }
    }
    parent {
      id
      name
      parent {
        id
        name
        parent {
          id
          name
          parent {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetFoldersQuery__
 *
 * To run a query within a React component, call `useGetFoldersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFoldersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFoldersQuery({
 *   variables: {
 *      folderId: // value for 'folderId'
 *   },
 * });
 */
export function useGetFoldersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFoldersQuery, GetFoldersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFoldersQuery, GetFoldersQueryVariables>(GetFoldersDocument, baseOptions);
      }
export function useGetFoldersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFoldersQuery, GetFoldersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFoldersQuery, GetFoldersQueryVariables>(GetFoldersDocument, baseOptions);
        }
export type GetFoldersQueryHookResult = ReturnType<typeof useGetFoldersQuery>;
export type GetFoldersLazyQueryHookResult = ReturnType<typeof useGetFoldersLazyQuery>;
export type GetFoldersQueryResult = ApolloReactCommon.QueryResult<GetFoldersQuery, GetFoldersQueryVariables>;
export const GetSubFoldersDocument = gql`
    query GetSubFolders($folderId: ID) {
  folder(folderId: $folderId) {
    id
    name
    subFolders {
      id
      name
      isRestricted
      subFolders {
        id
        name
        isRestricted
      }
    }
  }
}
    `;

/**
 * __useGetSubFoldersQuery__
 *
 * To run a query within a React component, call `useGetSubFoldersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubFoldersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubFoldersQuery({
 *   variables: {
 *      folderId: // value for 'folderId'
 *   },
 * });
 */
export function useGetSubFoldersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubFoldersQuery, GetSubFoldersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubFoldersQuery, GetSubFoldersQueryVariables>(GetSubFoldersDocument, baseOptions);
      }
export function useGetSubFoldersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubFoldersQuery, GetSubFoldersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubFoldersQuery, GetSubFoldersQueryVariables>(GetSubFoldersDocument, baseOptions);
        }
export type GetSubFoldersQueryHookResult = ReturnType<typeof useGetSubFoldersQuery>;
export type GetSubFoldersLazyQueryHookResult = ReturnType<typeof useGetSubFoldersLazyQuery>;
export type GetSubFoldersQueryResult = ApolloReactCommon.QueryResult<GetSubFoldersQuery, GetSubFoldersQueryVariables>;
export const GetHighlightCountsDocument = gql`
    query GetHighlightCounts($filters: [HighlightFilter!]) {
  highlightCounts(filters: $filters) {
    contactCount
    conversationCount
    messageCount
    firmMemberCount
    noteCount
    firmClientCount
    clientAssignmentCount
    clientCount
    matterCount
    keyDateCount
    taskCount
    taskSectionCount
    questionCount
    answerCount
  }
}
    `;

/**
 * __useGetHighlightCountsQuery__
 *
 * To run a query within a React component, call `useGetHighlightCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHighlightCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHighlightCountsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetHighlightCountsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetHighlightCountsQuery, GetHighlightCountsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetHighlightCountsQuery, GetHighlightCountsQueryVariables>(GetHighlightCountsDocument, baseOptions);
      }
export function useGetHighlightCountsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetHighlightCountsQuery, GetHighlightCountsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetHighlightCountsQuery, GetHighlightCountsQueryVariables>(GetHighlightCountsDocument, baseOptions);
        }
export type GetHighlightCountsQueryHookResult = ReturnType<typeof useGetHighlightCountsQuery>;
export type GetHighlightCountsLazyQueryHookResult = ReturnType<typeof useGetHighlightCountsLazyQuery>;
export type GetHighlightCountsQueryResult = ApolloReactCommon.QueryResult<GetHighlightCountsQuery, GetHighlightCountsQueryVariables>;
export const ListIssuesDocument = gql`
    query ListIssues($clientId: ID!) {
  issues(clientId: $clientId) {
    id
    type
    isLinked
    linkCode
    linkRequested
    opponentIssue {
      id
      legalTeam {
        id
        jobTitle
        isLead
        firm {
          id
          name
        }
        professional {
          id
          emailAddress
          preferredName
          lastName
          type
        }
      }
    }
    opponent {
      preferredName
      firstName
      lastName
      emailAddress
      nationalInsuranceNumber
      clientReferenceNumber
      isVerified
      solicitor {
        firstName
        lastName
        emailAddress
        firmName
      }
    }
    client {
      accounts {
        id
      }
    }
  }
}
    `;

/**
 * __useListIssuesQuery__
 *
 * To run a query within a React component, call `useListIssuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListIssuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListIssuesQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useListIssuesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListIssuesQuery, ListIssuesQueryVariables>) {
        return ApolloReactHooks.useQuery<ListIssuesQuery, ListIssuesQueryVariables>(ListIssuesDocument, baseOptions);
      }
export function useListIssuesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListIssuesQuery, ListIssuesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListIssuesQuery, ListIssuesQueryVariables>(ListIssuesDocument, baseOptions);
        }
export type ListIssuesQueryHookResult = ReturnType<typeof useListIssuesQuery>;
export type ListIssuesLazyQueryHookResult = ReturnType<typeof useListIssuesLazyQuery>;
export type ListIssuesQueryResult = ApolloReactCommon.QueryResult<ListIssuesQuery, ListIssuesQueryVariables>;
export const ListMattersForClientDocument = gql`
    query ListMattersForClient($clientId: ID!, $firmId: ID) {
  mattersForClient(clientId: $clientId, firmId: $firmId) {
    items {
      id
      type
      status
      advisories
      advisoryCount
      isHighlighted
      isRemovable
      clientAccount {
        id
        referenceNumber
        client {
          id
          preferredName
          firstName
          lastName
          pictureUrl
        }
      }
      leadProfessional {
        id
        preferredName
        firstName
        lastName
        pictureUrl
      }
      startedAt
      endedAt
    }
  }
}
    `;

/**
 * __useListMattersForClientQuery__
 *
 * To run a query within a React component, call `useListMattersForClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMattersForClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMattersForClientQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      firmId: // value for 'firmId'
 *   },
 * });
 */
export function useListMattersForClientQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListMattersForClientQuery, ListMattersForClientQueryVariables>) {
        return ApolloReactHooks.useQuery<ListMattersForClientQuery, ListMattersForClientQueryVariables>(ListMattersForClientDocument, baseOptions);
      }
export function useListMattersForClientLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListMattersForClientQuery, ListMattersForClientQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListMattersForClientQuery, ListMattersForClientQueryVariables>(ListMattersForClientDocument, baseOptions);
        }
export type ListMattersForClientQueryHookResult = ReturnType<typeof useListMattersForClientQuery>;
export type ListMattersForClientLazyQueryHookResult = ReturnType<typeof useListMattersForClientLazyQuery>;
export type ListMattersForClientQueryResult = ApolloReactCommon.QueryResult<ListMattersForClientQuery, ListMattersForClientQueryVariables>;
export const ListMattersForClientAccountDocument = gql`
    query ListMattersForClientAccount($clientId: ID!) {
  mattersForClientAccount(clientAccountId: $clientId) {
    items {
      id
      type
      status
      advisories
      advisoryCount
      isHighlighted
      isRemovable
      clientAccount {
        id
        referenceNumber
        client {
          id
          preferredName
          firstName
          lastName
          pictureUrl
        }
      }
      leadProfessional {
        id
        preferredName
        firstName
        lastName
        pictureUrl
      }
      startedAt
      endedAt
    }
  }
}
    `;

/**
 * __useListMattersForClientAccountQuery__
 *
 * To run a query within a React component, call `useListMattersForClientAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMattersForClientAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMattersForClientAccountQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useListMattersForClientAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListMattersForClientAccountQuery, ListMattersForClientAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<ListMattersForClientAccountQuery, ListMattersForClientAccountQueryVariables>(ListMattersForClientAccountDocument, baseOptions);
      }
export function useListMattersForClientAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListMattersForClientAccountQuery, ListMattersForClientAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListMattersForClientAccountQuery, ListMattersForClientAccountQueryVariables>(ListMattersForClientAccountDocument, baseOptions);
        }
export type ListMattersForClientAccountQueryHookResult = ReturnType<typeof useListMattersForClientAccountQuery>;
export type ListMattersForClientAccountLazyQueryHookResult = ReturnType<typeof useListMattersForClientAccountLazyQuery>;
export type ListMattersForClientAccountQueryResult = ApolloReactCommon.QueryResult<ListMattersForClientAccountQuery, ListMattersForClientAccountQueryVariables>;
export const ListMattersForProfessionalDocument = gql`
    query ListMattersForProfessional($professionalId: ID!, $firmId: ID) {
  mattersForProfessional(professionalId: $professionalId, firmId: $firmId) {
    items {
      id
      type
      status
      advisories
      advisoryCount
      isHighlighted
      isRemovable
      clientAccount {
        id
        referenceNumber
        client {
          id
          preferredName
          firstName
          lastName
          pictureUrl
        }
      }
      leadProfessional {
        id
        preferredName
        firstName
        lastName
        pictureUrl
      }
      startedAt
      endedAt
    }
  }
}
    `;

/**
 * __useListMattersForProfessionalQuery__
 *
 * To run a query within a React component, call `useListMattersForProfessionalQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMattersForProfessionalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMattersForProfessionalQuery({
 *   variables: {
 *      professionalId: // value for 'professionalId'
 *      firmId: // value for 'firmId'
 *   },
 * });
 */
export function useListMattersForProfessionalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListMattersForProfessionalQuery, ListMattersForProfessionalQueryVariables>) {
        return ApolloReactHooks.useQuery<ListMattersForProfessionalQuery, ListMattersForProfessionalQueryVariables>(ListMattersForProfessionalDocument, baseOptions);
      }
export function useListMattersForProfessionalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListMattersForProfessionalQuery, ListMattersForProfessionalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListMattersForProfessionalQuery, ListMattersForProfessionalQueryVariables>(ListMattersForProfessionalDocument, baseOptions);
        }
export type ListMattersForProfessionalQueryHookResult = ReturnType<typeof useListMattersForProfessionalQuery>;
export type ListMattersForProfessionalLazyQueryHookResult = ReturnType<typeof useListMattersForProfessionalLazyQuery>;
export type ListMattersForProfessionalQueryResult = ApolloReactCommon.QueryResult<ListMattersForProfessionalQuery, ListMattersForProfessionalQueryVariables>;
export const ListMattersForFirmDocument = gql`
    query ListMattersForFirm($firmId: ID!) {
  mattersForFirm(firmId: $firmId) {
    items {
      id
      type
      status
      advisories
      advisoryCount
      isHighlighted
      isRemovable
      clientAccount {
        id
        referenceNumber
        client {
          id
          preferredName
          firstName
          lastName
          pictureUrl
        }
      }
      leadProfessional {
        id
        preferredName
        firstName
        lastName
        pictureUrl
      }
      startedAt
      endedAt
    }
  }
}
    `;

/**
 * __useListMattersForFirmQuery__
 *
 * To run a query within a React component, call `useListMattersForFirmQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMattersForFirmQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMattersForFirmQuery({
 *   variables: {
 *      firmId: // value for 'firmId'
 *   },
 * });
 */
export function useListMattersForFirmQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListMattersForFirmQuery, ListMattersForFirmQueryVariables>) {
        return ApolloReactHooks.useQuery<ListMattersForFirmQuery, ListMattersForFirmQueryVariables>(ListMattersForFirmDocument, baseOptions);
      }
export function useListMattersForFirmLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListMattersForFirmQuery, ListMattersForFirmQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListMattersForFirmQuery, ListMattersForFirmQueryVariables>(ListMattersForFirmDocument, baseOptions);
        }
export type ListMattersForFirmQueryHookResult = ReturnType<typeof useListMattersForFirmQuery>;
export type ListMattersForFirmLazyQueryHookResult = ReturnType<typeof useListMattersForFirmLazyQuery>;
export type ListMattersForFirmQueryResult = ApolloReactCommon.QueryResult<ListMattersForFirmQuery, ListMattersForFirmQueryVariables>;
export const GetMatterDocument = gql`
    query GetMatter($matterId: ID!) {
  matter(matterId: $matterId) {
    id
    type
    status
    advisories
    advisoryCount
    isHighlighted
    isRemovable
    clientAccount {
      id
      referenceNumber
      client {
        id
        preferredName
        firstName
        lastName
        pictureUrl
      }
    }
    leadProfessional {
      id
      preferredName
      firstName
      lastName
      pictureUrl
    }
    startedAt
    endedAt
  }
}
    `;

/**
 * __useGetMatterQuery__
 *
 * To run a query within a React component, call `useGetMatterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMatterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMatterQuery({
 *   variables: {
 *      matterId: // value for 'matterId'
 *   },
 * });
 */
export function useGetMatterQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMatterQuery, GetMatterQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMatterQuery, GetMatterQueryVariables>(GetMatterDocument, baseOptions);
      }
export function useGetMatterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMatterQuery, GetMatterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMatterQuery, GetMatterQueryVariables>(GetMatterDocument, baseOptions);
        }
export type GetMatterQueryHookResult = ReturnType<typeof useGetMatterQuery>;
export type GetMatterLazyQueryHookResult = ReturnType<typeof useGetMatterLazyQuery>;
export type GetMatterQueryResult = ApolloReactCommon.QueryResult<GetMatterQuery, GetMatterQueryVariables>;
export const GetFullMatterDocument = gql`
    query GetFullMatter($matterId: ID!) {
  matter(matterId: $matterId) {
    id
    type
    status
    advisories
    advisoryCount
    isHighlighted
    isRemovable
    clientAccount {
      id
      referenceNumber
      client {
        id
        preferredName
        firstName
        lastName
        pictureUrl
      }
    }
    leadProfessional {
      id
      preferredName
      firstName
      lastName
      pictureUrl
    }
    startedAt
    endedAt
    keyDates {
      id
      date
      isAllDay
      name
      description
    }
    courtCases {
      id
      courtName
      caseNumber
      hearingDate {
        id
        date
        isAllDay
        name
        description
      }
      status
    }
  }
}
    `;

/**
 * __useGetFullMatterQuery__
 *
 * To run a query within a React component, call `useGetFullMatterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFullMatterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFullMatterQuery({
 *   variables: {
 *      matterId: // value for 'matterId'
 *   },
 * });
 */
export function useGetFullMatterQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFullMatterQuery, GetFullMatterQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFullMatterQuery, GetFullMatterQueryVariables>(GetFullMatterDocument, baseOptions);
      }
export function useGetFullMatterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFullMatterQuery, GetFullMatterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFullMatterQuery, GetFullMatterQueryVariables>(GetFullMatterDocument, baseOptions);
        }
export type GetFullMatterQueryHookResult = ReturnType<typeof useGetFullMatterQuery>;
export type GetFullMatterLazyQueryHookResult = ReturnType<typeof useGetFullMatterLazyQuery>;
export type GetFullMatterQueryResult = ApolloReactCommon.QueryResult<GetFullMatterQuery, GetFullMatterQueryVariables>;
export const ListClientAccountKeyDatesDocument = gql`
    query ListClientAccountKeyDates($clientAccountId: ID!) {
  clientAccountKeyDates(clientAccountId: $clientAccountId) {
    id
    date
    isAllDay
    name
    description
    isHighlighted
    matter {
      id
      type
      clientAccount {
        id
        referenceNumber
        client {
          id
          preferredName
          firstName
          lastName
          pictureUrl
        }
      }
    }
  }
}
    `;

/**
 * __useListClientAccountKeyDatesQuery__
 *
 * To run a query within a React component, call `useListClientAccountKeyDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListClientAccountKeyDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListClientAccountKeyDatesQuery({
 *   variables: {
 *      clientAccountId: // value for 'clientAccountId'
 *   },
 * });
 */
export function useListClientAccountKeyDatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListClientAccountKeyDatesQuery, ListClientAccountKeyDatesQueryVariables>) {
        return ApolloReactHooks.useQuery<ListClientAccountKeyDatesQuery, ListClientAccountKeyDatesQueryVariables>(ListClientAccountKeyDatesDocument, baseOptions);
      }
export function useListClientAccountKeyDatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListClientAccountKeyDatesQuery, ListClientAccountKeyDatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListClientAccountKeyDatesQuery, ListClientAccountKeyDatesQueryVariables>(ListClientAccountKeyDatesDocument, baseOptions);
        }
export type ListClientAccountKeyDatesQueryHookResult = ReturnType<typeof useListClientAccountKeyDatesQuery>;
export type ListClientAccountKeyDatesLazyQueryHookResult = ReturnType<typeof useListClientAccountKeyDatesLazyQuery>;
export type ListClientAccountKeyDatesQueryResult = ApolloReactCommon.QueryResult<ListClientAccountKeyDatesQuery, ListClientAccountKeyDatesQueryVariables>;
export const ListAllKeyDatesDocument = gql`
    query ListAllKeyDates {
  allKeyDates {
    items {
      id
      date
      isAllDay
      name
      description
      isHighlighted
      matter {
        id
        type
        clientAccount {
          id
          referenceNumber
          client {
            id
            preferredName
            firstName
            lastName
            pictureUrl
          }
        }
      }
    }
  }
}
    `;

/**
 * __useListAllKeyDatesQuery__
 *
 * To run a query within a React component, call `useListAllKeyDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllKeyDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllKeyDatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAllKeyDatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListAllKeyDatesQuery, ListAllKeyDatesQueryVariables>) {
        return ApolloReactHooks.useQuery<ListAllKeyDatesQuery, ListAllKeyDatesQueryVariables>(ListAllKeyDatesDocument, baseOptions);
      }
export function useListAllKeyDatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListAllKeyDatesQuery, ListAllKeyDatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListAllKeyDatesQuery, ListAllKeyDatesQueryVariables>(ListAllKeyDatesDocument, baseOptions);
        }
export type ListAllKeyDatesQueryHookResult = ReturnType<typeof useListAllKeyDatesQuery>;
export type ListAllKeyDatesLazyQueryHookResult = ReturnType<typeof useListAllKeyDatesLazyQuery>;
export type ListAllKeyDatesQueryResult = ApolloReactCommon.QueryResult<ListAllKeyDatesQuery, ListAllKeyDatesQueryVariables>;
export const ListCourtCasesDocument = gql`
    query ListCourtCases($matterId: ID!) {
  courtCases(matterId: $matterId) {
    id
    courtName
    caseNumber
    hearingDate {
      id
      date
      isAllDay
      name
      description
    }
    status
  }
}
    `;

/**
 * __useListCourtCasesQuery__
 *
 * To run a query within a React component, call `useListCourtCasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCourtCasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCourtCasesQuery({
 *   variables: {
 *      matterId: // value for 'matterId'
 *   },
 * });
 */
export function useListCourtCasesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListCourtCasesQuery, ListCourtCasesQueryVariables>) {
        return ApolloReactHooks.useQuery<ListCourtCasesQuery, ListCourtCasesQueryVariables>(ListCourtCasesDocument, baseOptions);
      }
export function useListCourtCasesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListCourtCasesQuery, ListCourtCasesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListCourtCasesQuery, ListCourtCasesQueryVariables>(ListCourtCasesDocument, baseOptions);
        }
export type ListCourtCasesQueryHookResult = ReturnType<typeof useListCourtCasesQuery>;
export type ListCourtCasesLazyQueryHookResult = ReturnType<typeof useListCourtCasesLazyQuery>;
export type ListCourtCasesQueryResult = ApolloReactCommon.QueryResult<ListCourtCasesQuery, ListCourtCasesQueryVariables>;
export const GetSupportingMaterialDocument = gql`
    query GetSupportingMaterial($matterId: ID!) {
  supportingMaterial(matterId: $matterId) {
    ... on MatterTask {
      id
      name
      status
      proportionComplete
    }
    ... on ConversationExtract {
      id
      name
      extractedAt
      messageFilters {
        conversationId
        senderIds
        sentBefore
        sentAfter
        hasAttachments
        viewerId
        isUnread
        isVisible
      }
      messages {
        perPage
        currentPage
        items {
          body
          sentAt
        }
      }
    }
    ... on SupportingAudio {
      ...fileInfo
    }
    ... on SupportingDocument {
      ...fileInfo
    }
    ... on SupportingImage {
      ...fileInfo
      height
      width
    }
    ... on SupportingVideo {
      ...fileInfo
    }
  }
}
    ${FileInfoFragmentDoc}`;

/**
 * __useGetSupportingMaterialQuery__
 *
 * To run a query within a React component, call `useGetSupportingMaterialQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportingMaterialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportingMaterialQuery({
 *   variables: {
 *      matterId: // value for 'matterId'
 *   },
 * });
 */
export function useGetSupportingMaterialQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupportingMaterialQuery, GetSupportingMaterialQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupportingMaterialQuery, GetSupportingMaterialQueryVariables>(GetSupportingMaterialDocument, baseOptions);
      }
export function useGetSupportingMaterialLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupportingMaterialQuery, GetSupportingMaterialQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupportingMaterialQuery, GetSupportingMaterialQueryVariables>(GetSupportingMaterialDocument, baseOptions);
        }
export type GetSupportingMaterialQueryHookResult = ReturnType<typeof useGetSupportingMaterialQuery>;
export type GetSupportingMaterialLazyQueryHookResult = ReturnType<typeof useGetSupportingMaterialLazyQuery>;
export type GetSupportingMaterialQueryResult = ApolloReactCommon.QueryResult<GetSupportingMaterialQuery, GetSupportingMaterialQueryVariables>;
export const ListMessagesDocument = gql`
    query ListMessages($conversationId: ID!, $input: QueryMessagesInput!) {
  conversation(conversationId: $conversationId) {
    id
    name
    description
    pictureUrl
    participants {
      id
      preferredName
      firstName
      lastName
      pictureUrl
      role
      title
      status
      group {
        id
        name
        pictureUrl
      }
    }
    groups {
      id
      name
      pictureUrl
      members {
        id
        name
        pictureUrl
        title
      }
    }
    observers {
      id
      preferredName
      firstName
      lastName
      pictureUrl
      role
      title
      group {
        id
        name
        pictureUrl
      }
    }
    draft {
      body
    }
    pausedUntil
    unreadMessageCount
    metadata {
      ... on KeyValueArray {
        key
        values: value
      }
      ... on KeyValueString {
        key
        value
      }
    }
  }
  messages(input: $input) {
    perPage
    lastPage
    hasMorePages
    lastPage
    to
    from
    currentPage
    total
    items {
      id
      conversationId
      sender {
        id
        preferredName
        firstName
        lastName
        pictureUrl
        role
        channel
        title
        group {
          id
          name
          pictureUrl
        }
      }
      repliedTo {
        id
        body
        sentAt
        sender {
          id
          preferredName
          firstName
          lastName
          pictureUrl
          role
          channel
          title
          group {
            id
            name
            pictureUrl
          }
        }
      }
      copies {
        recipient {
          id
          group {
            id
          }
        }
        deliveredAt
        readAt
      }
      body
      sentAt
      deliveredAt
      readAt
      attachments {
        id
        name
        type
        mime
        size
        ... on AttachedImage {
          height
          width
        }
      }
      topics {
        id
        name
        colour
      }
    }
  }
}
    `;

/**
 * __useListMessagesQuery__
 *
 * To run a query within a React component, call `useListMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMessagesQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListMessagesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListMessagesQuery, ListMessagesQueryVariables>) {
        return ApolloReactHooks.useQuery<ListMessagesQuery, ListMessagesQueryVariables>(ListMessagesDocument, baseOptions);
      }
export function useListMessagesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListMessagesQuery, ListMessagesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListMessagesQuery, ListMessagesQueryVariables>(ListMessagesDocument, baseOptions);
        }
export type ListMessagesQueryHookResult = ReturnType<typeof useListMessagesQuery>;
export type ListMessagesLazyQueryHookResult = ReturnType<typeof useListMessagesLazyQuery>;
export type ListMessagesQueryResult = ApolloReactCommon.QueryResult<ListMessagesQuery, ListMessagesQueryVariables>;
export const ListAuthoredNotesDocument = gql`
    query ListAuthoredNotes($authorId: ID!, $page: Int!, $pageSize: Int!) {
  authoredNotes(authorId: $authorId, page: $page, pageSize: $pageSize) {
    items {
      id
      isPersonal
      isViewed
      category
      dateTime
      title
      body
      startedAt
      versionNumber
      revisions {
        versionNumber
        title
        body
        category
        dateTime
        draftedAt
        author {
          id
          preferredName
          firstName
          lastName
          pictureUrl
        }
      }
      clientAccount {
        id
        status
        referenceNumber
        client {
          id
          preferredName
          firstName
          lastName
          pictureUrl
        }
      }
      matter {
        id
      }
      author {
        id
        preferredName
        firstName
        lastName
        pictureUrl
      }
    }
  }
}
    `;

/**
 * __useListAuthoredNotesQuery__
 *
 * To run a query within a React component, call `useListAuthoredNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAuthoredNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAuthoredNotesQuery({
 *   variables: {
 *      authorId: // value for 'authorId'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useListAuthoredNotesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListAuthoredNotesQuery, ListAuthoredNotesQueryVariables>) {
        return ApolloReactHooks.useQuery<ListAuthoredNotesQuery, ListAuthoredNotesQueryVariables>(ListAuthoredNotesDocument, baseOptions);
      }
export function useListAuthoredNotesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListAuthoredNotesQuery, ListAuthoredNotesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListAuthoredNotesQuery, ListAuthoredNotesQueryVariables>(ListAuthoredNotesDocument, baseOptions);
        }
export type ListAuthoredNotesQueryHookResult = ReturnType<typeof useListAuthoredNotesQuery>;
export type ListAuthoredNotesLazyQueryHookResult = ReturnType<typeof useListAuthoredNotesLazyQuery>;
export type ListAuthoredNotesQueryResult = ApolloReactCommon.QueryResult<ListAuthoredNotesQuery, ListAuthoredNotesQueryVariables>;
export const ListClientAccountNotesDocument = gql`
    query ListClientAccountNotes($clientAccountId: ID!, $page: Int!, $pageSize: Int!) {
  clientAccountNotes(
    clientAccountId: $clientAccountId
    page: $page
    pageSize: $pageSize
  ) {
    items {
      id
      category
      dateTime
      title
      body
      startedAt
      versionNumber
      isPersonal
      isViewed
      revisions {
        versionNumber
        title
        body
        category
        dateTime
        draftedAt
        author {
          id
          preferredName
          firstName
          lastName
          pictureUrl
        }
      }
      clientAccount {
        id
        status
        referenceNumber
        client {
          id
          preferredName
          firstName
          lastName
          pictureUrl
        }
      }
      matter {
        id
      }
      author {
        id
        preferredName
        firstName
        lastName
        pictureUrl
      }
    }
  }
}
    `;

/**
 * __useListClientAccountNotesQuery__
 *
 * To run a query within a React component, call `useListClientAccountNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListClientAccountNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListClientAccountNotesQuery({
 *   variables: {
 *      clientAccountId: // value for 'clientAccountId'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useListClientAccountNotesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListClientAccountNotesQuery, ListClientAccountNotesQueryVariables>) {
        return ApolloReactHooks.useQuery<ListClientAccountNotesQuery, ListClientAccountNotesQueryVariables>(ListClientAccountNotesDocument, baseOptions);
      }
export function useListClientAccountNotesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListClientAccountNotesQuery, ListClientAccountNotesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListClientAccountNotesQuery, ListClientAccountNotesQueryVariables>(ListClientAccountNotesDocument, baseOptions);
        }
export type ListClientAccountNotesQueryHookResult = ReturnType<typeof useListClientAccountNotesQuery>;
export type ListClientAccountNotesLazyQueryHookResult = ReturnType<typeof useListClientAccountNotesLazyQuery>;
export type ListClientAccountNotesQueryResult = ApolloReactCommon.QueryResult<ListClientAccountNotesQuery, ListClientAccountNotesQueryVariables>;
export const GetNotesDocument = gql`
    query GetNotes($noteId: ID!) {
  note(noteId: $noteId) {
    id
    category
    dateTime
    title
    body
    startedAt
    versionNumber
    isPersonal
    isViewed
    revisions {
      versionNumber
      title
      body
      category
      dateTime
      draftedAt
      author {
        id
        preferredName
        firstName
        lastName
        pictureUrl
      }
    }
    clientAccount {
      id
      status
      referenceNumber
      client {
        id
        preferredName
        firstName
        lastName
        pictureUrl
      }
    }
    matter {
      id
    }
    author {
      id
      preferredName
      firstName
      lastName
      pictureUrl
    }
  }
}
    `;

/**
 * __useGetNotesQuery__
 *
 * To run a query within a React component, call `useGetNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotesQuery({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useGetNotesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNotesQuery, GetNotesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNotesQuery, GetNotesQueryVariables>(GetNotesDocument, baseOptions);
      }
export function useGetNotesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNotesQuery, GetNotesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNotesQuery, GetNotesQueryVariables>(GetNotesDocument, baseOptions);
        }
export type GetNotesQueryHookResult = ReturnType<typeof useGetNotesQuery>;
export type GetNotesLazyQueryHookResult = ReturnType<typeof useGetNotesLazyQuery>;
export type GetNotesQueryResult = ApolloReactCommon.QueryResult<GetNotesQuery, GetNotesQueryVariables>;
export const ListNotificationsDocument = gql`
    query ListNotifications($page: Int, $pageSize: Int) {
  notifications(page: $page, pageSize: $pageSize) {
    perPage
    currentPage
    hasMorePages
    totalUnread
    total
    notifications {
      id
      context
      type
      title
      body
      pictureUrl
      occurredOn
      readAt
      isRead
      path {
        id
        type
        name
      }
    }
  }
}
    `;

/**
 * __useListNotificationsQuery__
 *
 * To run a query within a React component, call `useListNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListNotificationsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useListNotificationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListNotificationsQuery, ListNotificationsQueryVariables>) {
        return ApolloReactHooks.useQuery<ListNotificationsQuery, ListNotificationsQueryVariables>(ListNotificationsDocument, baseOptions);
      }
export function useListNotificationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListNotificationsQuery, ListNotificationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListNotificationsQuery, ListNotificationsQueryVariables>(ListNotificationsDocument, baseOptions);
        }
export type ListNotificationsQueryHookResult = ReturnType<typeof useListNotificationsQuery>;
export type ListNotificationsLazyQueryHookResult = ReturnType<typeof useListNotificationsLazyQuery>;
export type ListNotificationsQueryResult = ApolloReactCommon.QueryResult<ListNotificationsQuery, ListNotificationsQueryVariables>;
export const GetQuestionDocument = gql`
    query GetQuestion($questionId: ID!) {
  question(questionId: $questionId) {
    id
    text
    description
    proportionComplete
    isReleased
    isComplete
    isHighlighted
    officialAdvice
    additionalAdvice
    parts {
      id
      label
      instructions
      isRequired
      isComplete
      isHighlighted
      ordering
      officialAdvice
      additionalAdvice
      answerDraft {
        id
        notApplicable
        proposer {
          id
          preferredName
          firstName
          lastName
          pictureUrl
        }
        ... on AddressAnswerDraft {
          address: currentValue {
            name
            line1
            line2
            line3
            city
            region
            postCode
            country
          }
        }
        ... on BooleanAnswerDraft {
          boolean: currentValue
        }
        ... on StringAnswerDraft {
          string: currentValue
        }
        ... on ChoiceAnswerDraft {
          choice: currentValue
        }
        ... on DateAnswerDraft {
          date: currentValue
        }
        ... on EmailAnswerDraft {
          email: currentValue
        }
        ... on FileAnswerDraft {
          file: currentValue {
            id
            fileId
            fileVersion
            name
            type
            mime
            size
            ... on SupportingImage {
              height
              width
            }
          }
        }
        ... on ListAnswerDraft {
          list: currentValue {
            ordering
            properties {
              attributeId
              ... on AddressListItemProperty {
                address: value {
                  name
                  line1
                  line2
                  line3
                  city
                  region
                  postCode
                  country
                }
              }
              ... on DateListItemProperty {
                date: value
              }
              ... on EmailListItemProperty {
                email: value
              }
              ... on FileListItemProperty {
                file: value
              }
              ... on MoneyListItemProperty {
                money: value
              }
              ... on NumberListItemProperty {
                number: value
              }
              ... on PercentListItemProperty {
                percent: value
              }
              ... on PhoneListItemProperty {
                phone: value
              }
              ... on StringListItemProperty {
                string: value
              }
              ... on TextListItemProperty {
                text: value
              }
            }
          }
        }
        ... on MoneyAnswerDraft {
          money: currentValue
        }
        ... on NumberAnswerDraft {
          number: currentValue
        }
        ... on PercentAnswerDraft {
          percent: currentValue
        }
        ... on PhoneAnswerDraft {
          phone: currentValue
        }
        ... on TextAnswerDraft {
          text: currentValue
        }
      }
      answer {
        id
        proposedOn
        status
        notApplicable
        isAcceptedByClient
        isAcceptedByProfessional
        proposer {
          id
          preferredName
          firstName
          lastName
          pictureUrl
        }
        ... on AddressAnswer {
          address: currentValue {
            name
            line1
            line2
            line3
            city
            region
            postCode
            country
          }
        }
        ... on BooleanAnswer {
          boolean: currentValue
        }
        ... on StringAnswer {
          string: currentValue
        }
        ... on ChoiceAnswer {
          choice: currentValue
        }
        ... on DateAnswer {
          date: currentValue
        }
        ... on EmailAnswer {
          email: currentValue
        }
        ... on FileAnswer {
          file: currentValue {
            id
            fileId
            fileVersion
            name
            type
            mime
            size
            ... on SupportingImage {
              height
              width
            }
          }
        }
        ... on ListAnswer {
          list: currentValue {
            ordering
            properties {
              attributeId
              ... on AddressListItemProperty {
                address: value {
                  name
                  line1
                  line2
                  line3
                  city
                  region
                  postCode
                  country
                }
              }
              ... on DateListItemProperty {
                date: value
              }
              ... on EmailListItemProperty {
                email: value
              }
              ... on FileListItemProperty {
                file: value
              }
              ... on MoneyListItemProperty {
                money: value
              }
              ... on NumberListItemProperty {
                number: value
              }
              ... on PercentListItemProperty {
                percent: value
              }
              ... on PhoneListItemProperty {
                phone: value
              }
              ... on StringListItemProperty {
                string: value
              }
              ... on TextListItemProperty {
                text: value
              }
            }
          }
        }
        ... on MoneyAnswer {
          money: currentValue
        }
        ... on NumberAnswer {
          number: currentValue
        }
        ... on PercentAnswer {
          percent: currentValue
        }
        ... on PhoneAnswer {
          phone: currentValue
        }
        ... on TextAnswer {
          text: currentValue
        }
        history {
          id
          notApplicable
          proposer {
            id
            preferredName
            firstName
            lastName
            pictureUrl
          }
          proposedOn
          ... on AddressAnswerRevision {
            address: value {
              name
              line1
              line2
              line3
              city
              region
              postCode
              country
            }
          }
          ... on BooleanAnswerRevision {
            boolean: value
          }
          ... on StringAnswerRevision {
            string: value
          }
          ... on ChoiceAnswerRevision {
            choice: value
          }
          ... on DateAnswerRevision {
            date: value
          }
          ... on EmailAnswerRevision {
            email: value
          }
          ... on FileAnswerRevision {
            file: value {
              id
              fileId
              fileVersion
              name
              type
              mime
              size
              ... on SupportingImage {
                height
                width
              }
            }
          }
          ... on ListAnswerRevision {
            list: value {
              ordering
              properties {
                attributeId
                ... on AddressListItemProperty {
                  address: value {
                    name
                    line1
                    line2
                    line3
                    city
                    region
                    postCode
                    country
                  }
                }
                ... on DateListItemProperty {
                  date: value
                }
                ... on EmailListItemProperty {
                  email: value
                }
                ... on FileListItemProperty {
                  file: value
                }
                ... on MoneyListItemProperty {
                  money: value
                }
                ... on NumberListItemProperty {
                  number: value
                }
                ... on PercentListItemProperty {
                  percent: value
                }
                ... on PhoneListItemProperty {
                  phone: value
                }
                ... on StringListItemProperty {
                  string: value
                }
                ... on TextListItemProperty {
                  text: value
                }
              }
            }
          }
          ... on MoneyAnswerRevision {
            money: value
          }
          ... on NumberAnswerRevision {
            number: value
          }
          ... on PercentAnswerRevision {
            percent: value
          }
          ... on PhoneAnswerRevision {
            phone: value
          }
          ... on TextAnswerRevision {
            text: value
          }
        }
      }
      ... on AddressPart {
        useName
      }
      ... on BooleanPart {
        positiveLabel
        negativeLabel
      }
      ... on ChoicePart {
        options {
          value
          description
        }
        minimumChoices
        maximumChoices
      }
      ... on DatePart {
        earliestDate
        latestDate
        useTime
      }
      ... on FilePart {
        maximumFiles
      }
      ... on ListPart {
        attributes {
          id
          label
          instructions
          isRequired
          ordering
          ... on AddressAttribute {
            useName
          }
          ... on DateAttribute {
            earliestDate
            latestDate
            useTime
          }
          ... on FileAttribute {
            maximumFiles
          }
          ... on MoneyAttribute {
            minimumAmount
            maximumAmount
            currency
          }
          ... on NumberAttribute {
            minimumValue
            maximumValue
          }
          ... on PercentAttribute {
            minimumValue
            maximumValue
          }
          ... on StringAttribute {
            maximumLength
            minimumLength
          }
          ... on TextAttribute {
            maximumLength
            minimumLength
          }
        }
        minimumItems
        maximumItems
      }
      ... on MoneyPart {
        minimumAmount
        maximumAmount
        currency
      }
      ... on NumberPart {
        minimumValue
        maximumValue
      }
      ... on PercentPart {
        minimumValue
        maximumValue
      }
      ... on StringPart {
        maximumLength
        minimumLength
      }
      ... on TextPart {
        maximumLength
        minimumLength
      }
    }
  }
}
    `;

/**
 * __useGetQuestionQuery__
 *
 * To run a query within a React component, call `useGetQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionQuery({
 *   variables: {
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useGetQuestionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetQuestionQuery, GetQuestionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetQuestionQuery, GetQuestionQueryVariables>(GetQuestionDocument, baseOptions);
      }
export function useGetQuestionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetQuestionQuery, GetQuestionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetQuestionQuery, GetQuestionQueryVariables>(GetQuestionDocument, baseOptions);
        }
export type GetQuestionQueryHookResult = ReturnType<typeof useGetQuestionQuery>;
export type GetQuestionLazyQueryHookResult = ReturnType<typeof useGetQuestionLazyQuery>;
export type GetQuestionQueryResult = ApolloReactCommon.QueryResult<GetQuestionQuery, GetQuestionQueryVariables>;
export const GetEditableQuestionDocument = gql`
    query GetEditableQuestion($questionId: ID!) {
  question(questionId: $questionId) {
    id
    text
    description
    officialAdvice
    additionalAdvice
    parts {
      label
      instructions
      type
      ordering
      id
      officialAdvice
      additionalAdvice
      ... on AddressPart {
        configuration {
          useName
          isRequired
        }
      }
      ... on BooleanPart {
        configuration {
          positiveLabel
          negativeLabel
        }
      }
      ... on ChoicePart {
        configuration {
          isRequired
          options {
            value
            description
          }
          minimumChoices
          maximumChoices
        }
      }
      ... on DatePart {
        configuration {
          isRequired
          earliestDate
          latestDate
          useTime
        }
      }
      ... on EmailPart {
        configuration {
          isRequired
        }
      }
      ... on FilePart {
        configuration {
          isRequired
          maximumFiles
        }
      }
      ... on ListPart {
        configuration {
          isRequired
          attributes {
            label
            instructions
            ordering
            type
            id
            ... on AddressAttribute {
              configuration {
                isRequired
                useName
              }
            }
            ... on DateAttribute {
              configuration {
                isRequired
                earliestDate
                latestDate
                useTime
              }
            }
            ... on EmailAttribute {
              configuration {
                isRequired
              }
            }
            ... on FileAttribute {
              configuration {
                isRequired
                maximumFiles
              }
            }
            ... on MoneyAttribute {
              configuration {
                isRequired
                minimumAmount
                maximumAmount
                currency
              }
            }
            ... on NumberAttribute {
              configuration {
                isRequired
                minimumValue
                maximumValue
              }
            }
            ... on PercentAttribute {
              configuration {
                isRequired
                minimumValue
                maximumValue
              }
            }
            ... on PhoneAttribute {
              configuration {
                isRequired
              }
            }
            ... on StringAttribute {
              configuration {
                isRequired
                maximumLength
                minimumLength
              }
            }
            ... on TextAttribute {
              configuration {
                isRequired
                maximumLength
                minimumLength
              }
            }
          }
          minimumItems
          maximumItems
        }
      }
      ... on MoneyPart {
        configuration {
          isRequired
          maximumAmount
          minimumAmount
          currency
        }
      }
      ... on NumberPart {
        configuration {
          isRequired
          minimumValue
          maximumValue
        }
      }
      ... on PercentPart {
        configuration {
          isRequired
          minimumValue
          maximumValue
        }
      }
      ... on PhonePart {
        configuration {
          isRequired
        }
      }
      ... on StringPart {
        configuration {
          isRequired
          maximumLength
          minimumLength
        }
      }
      ... on TextPart {
        configuration {
          isRequired
          maximumLength
          minimumLength
        }
      }
    }
  }
}
    `;

/**
 * __useGetEditableQuestionQuery__
 *
 * To run a query within a React component, call `useGetEditableQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditableQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditableQuestionQuery({
 *   variables: {
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useGetEditableQuestionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEditableQuestionQuery, GetEditableQuestionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEditableQuestionQuery, GetEditableQuestionQueryVariables>(GetEditableQuestionDocument, baseOptions);
      }
export function useGetEditableQuestionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEditableQuestionQuery, GetEditableQuestionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEditableQuestionQuery, GetEditableQuestionQueryVariables>(GetEditableQuestionDocument, baseOptions);
        }
export type GetEditableQuestionQueryHookResult = ReturnType<typeof useGetEditableQuestionQuery>;
export type GetEditableQuestionLazyQueryHookResult = ReturnType<typeof useGetEditableQuestionLazyQuery>;
export type GetEditableQuestionQueryResult = ApolloReactCommon.QueryResult<GetEditableQuestionQuery, GetEditableQuestionQueryVariables>;
export const SearchDocument = gql`
    query Search($term: String!, $context: SearchContext) {
  search(term: $term, context: $context) {
    term
    context
    contextualMatches {
      __typename
      ...Folders
      ...Files
      ...Messages
      ...ClientAccounts
      ...Tasks
      ...Contacts
      ...Notes
    }
    otherMatches {
      __typename
      ...Folders
      ...Files
      ...Messages
      ...ClientAccounts
      ...Tasks
      ...Contacts
      ...Notes
    }
  }
}
    ${FoldersFragmentDoc}
${FilesFragmentDoc}
${MessagesFragmentDoc}
${ClientAccountsFragmentDoc}
${TasksFragmentDoc}
${ContactsFragmentDoc}
${NotesFragmentDoc}`;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      term: // value for 'term'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchQuery, SearchQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, baseOptions);
      }
export function useSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, baseOptions);
        }
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = ApolloReactCommon.QueryResult<SearchQuery, SearchQueryVariables>;
export const ListTasksDocument = gql`
    query ListTasks($matterId: ID!) {
  matterTasks(matterId: $matterId) {
    id
    name
    description
    status
    sectionCount
    dueDate {
      id
      date
      isAllDay
      name
      description
    }
    proportionComplete
    proportionReleased
    isHighlighted
    isModifiable
    officialAdvice
    additionalAdvice
    sections {
      id
      name
      description
      ordering
      officialAdvice
      additionalAdvice
      dueDate {
        id
        date
        isAllDay
        name
        description
      }
      isHighlighted
      questions {
        id
        text
        description
        isReleased
        proportionComplete
        isHighlighted
        officialAdvice
        additionalAdvice
        status
        parts {
          id
          label
          isComplete
          isHighlighted
          officialAdvice
          additionalAdvice
        }
      }
    }
  }
}
    `;

/**
 * __useListTasksQuery__
 *
 * To run a query within a React component, call `useListTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTasksQuery({
 *   variables: {
 *      matterId: // value for 'matterId'
 *   },
 * });
 */
export function useListTasksQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListTasksQuery, ListTasksQueryVariables>) {
        return ApolloReactHooks.useQuery<ListTasksQuery, ListTasksQueryVariables>(ListTasksDocument, baseOptions);
      }
export function useListTasksLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListTasksQuery, ListTasksQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListTasksQuery, ListTasksQueryVariables>(ListTasksDocument, baseOptions);
        }
export type ListTasksQueryHookResult = ReturnType<typeof useListTasksQuery>;
export type ListTasksLazyQueryHookResult = ReturnType<typeof useListTasksLazyQuery>;
export type ListTasksQueryResult = ApolloReactCommon.QueryResult<ListTasksQuery, ListTasksQueryVariables>;
export const ListLibraryTasksDocument = gql`
    query ListLibraryTasks {
  libraryTasks {
    id
    name
    description
    matterTypes
    sectionCount
    isModifiable
  }
}
    `;

/**
 * __useListLibraryTasksQuery__
 *
 * To run a query within a React component, call `useListLibraryTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLibraryTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLibraryTasksQuery({
 *   variables: {
 *   },
 * });
 */
export function useListLibraryTasksQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListLibraryTasksQuery, ListLibraryTasksQueryVariables>) {
        return ApolloReactHooks.useQuery<ListLibraryTasksQuery, ListLibraryTasksQueryVariables>(ListLibraryTasksDocument, baseOptions);
      }
export function useListLibraryTasksLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListLibraryTasksQuery, ListLibraryTasksQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListLibraryTasksQuery, ListLibraryTasksQueryVariables>(ListLibraryTasksDocument, baseOptions);
        }
export type ListLibraryTasksQueryHookResult = ReturnType<typeof useListLibraryTasksQuery>;
export type ListLibraryTasksLazyQueryHookResult = ReturnType<typeof useListLibraryTasksLazyQuery>;
export type ListLibraryTasksQueryResult = ApolloReactCommon.QueryResult<ListLibraryTasksQuery, ListLibraryTasksQueryVariables>;
export const ListTemplateTasksDocument = gql`
    query ListTemplateTasks($includeUnpublished: Boolean) {
  templateTasks(includeUnpublished: $includeUnpublished) {
    id
    name
    description
    matterTypes
    sectionCount
    isModifiable
    isPublished
    additionalAdvice
    officialAdvice
  }
}
    `;

/**
 * __useListTemplateTasksQuery__
 *
 * To run a query within a React component, call `useListTemplateTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTemplateTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTemplateTasksQuery({
 *   variables: {
 *      includeUnpublished: // value for 'includeUnpublished'
 *   },
 * });
 */
export function useListTemplateTasksQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListTemplateTasksQuery, ListTemplateTasksQueryVariables>) {
        return ApolloReactHooks.useQuery<ListTemplateTasksQuery, ListTemplateTasksQueryVariables>(ListTemplateTasksDocument, baseOptions);
      }
export function useListTemplateTasksLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListTemplateTasksQuery, ListTemplateTasksQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListTemplateTasksQuery, ListTemplateTasksQueryVariables>(ListTemplateTasksDocument, baseOptions);
        }
export type ListTemplateTasksQueryHookResult = ReturnType<typeof useListTemplateTasksQuery>;
export type ListTemplateTasksLazyQueryHookResult = ReturnType<typeof useListTemplateTasksLazyQuery>;
export type ListTemplateTasksQueryResult = ApolloReactCommon.QueryResult<ListTemplateTasksQuery, ListTemplateTasksQueryVariables>;
export const GetTaskDocument = gql`
    query GetTask($taskId: ID!) {
  task(taskId: $taskId) {
    id
    name
    description
    sectionCount
    isModifiable
    officialAdvice
    additionalAdvice
    ... on FirmTask {
      matterTypes
      isPublished
    }
    ... on LibraryTask {
      matterTypes
    }
    ... on MatterTask {
      dueDate {
        id
        date
        isAllDay
        name
        description
      }
      status
      proportionComplete
      proportionReleased
      isHighlighted
    }
    sections {
      id
      name
      description
      ordering
      officialAdvice
      additionalAdvice
      dueDate {
        id
        date
        isAllDay
        name
        description
      }
      proportionComplete
      proportionReleased
      isHighlighted
      questions {
        id
        text
        description
        isReleased
        proportionComplete
        isHighlighted
        officialAdvice
        additionalAdvice
        status
        parts {
          id
          label
          isComplete
          isHighlighted
          officialAdvice
          additionalAdvice
        }
      }
    }
  }
}
    `;

/**
 * __useGetTaskQuery__
 *
 * To run a query within a React component, call `useGetTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetTaskQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaskQuery, GetTaskQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaskQuery, GetTaskQueryVariables>(GetTaskDocument, baseOptions);
      }
export function useGetTaskLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaskQuery, GetTaskQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaskQuery, GetTaskQueryVariables>(GetTaskDocument, baseOptions);
        }
export type GetTaskQueryHookResult = ReturnType<typeof useGetTaskQuery>;
export type GetTaskLazyQueryHookResult = ReturnType<typeof useGetTaskLazyQuery>;
export type GetTaskQueryResult = ApolloReactCommon.QueryResult<GetTaskQuery, GetTaskQueryVariables>;
export const GetTaskSectionDocument = gql`
    query GetTaskSection($taskId: ID!, $sectionId: ID!) {
  taskSection(taskId: $taskId, sectionId: $sectionId) {
    id
    name
    description
    ordering
    dueDate {
      id
      date
      isAllDay
      name
      description
    }
    proportionComplete
    proportionReleased
    isHighlighted
    officialAdvice
    additionalAdvice
    questions {
      id
      text
      description
      isReleased
      proportionComplete
      isHighlighted
      officialAdvice
      additionalAdvice
      status
      parts {
        id
        label
        isComplete
        isHighlighted
        officialAdvice
        additionalAdvice
      }
    }
  }
}
    `;

/**
 * __useGetTaskSectionQuery__
 *
 * To run a query within a React component, call `useGetTaskSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskSectionQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      sectionId: // value for 'sectionId'
 *   },
 * });
 */
export function useGetTaskSectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaskSectionQuery, GetTaskSectionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaskSectionQuery, GetTaskSectionQueryVariables>(GetTaskSectionDocument, baseOptions);
      }
export function useGetTaskSectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaskSectionQuery, GetTaskSectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaskSectionQuery, GetTaskSectionQueryVariables>(GetTaskSectionDocument, baseOptions);
        }
export type GetTaskSectionQueryHookResult = ReturnType<typeof useGetTaskSectionQuery>;
export type GetTaskSectionLazyQueryHookResult = ReturnType<typeof useGetTaskSectionLazyQuery>;
export type GetTaskSectionQueryResult = ApolloReactCommon.QueryResult<GetTaskSectionQuery, GetTaskSectionQueryVariables>;
export const ListPrivateTopicsDocument = gql`
    query ListPrivateTopics {
  privateTopics {
    id
    ownerId
    name
    slug
    colour
  }
}
    `;

/**
 * __useListPrivateTopicsQuery__
 *
 * To run a query within a React component, call `useListPrivateTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPrivateTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPrivateTopicsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListPrivateTopicsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListPrivateTopicsQuery, ListPrivateTopicsQueryVariables>) {
        return ApolloReactHooks.useQuery<ListPrivateTopicsQuery, ListPrivateTopicsQueryVariables>(ListPrivateTopicsDocument, baseOptions);
      }
export function useListPrivateTopicsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListPrivateTopicsQuery, ListPrivateTopicsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListPrivateTopicsQuery, ListPrivateTopicsQueryVariables>(ListPrivateTopicsDocument, baseOptions);
        }
export type ListPrivateTopicsQueryHookResult = ReturnType<typeof useListPrivateTopicsQuery>;
export type ListPrivateTopicsLazyQueryHookResult = ReturnType<typeof useListPrivateTopicsLazyQuery>;
export type ListPrivateTopicsQueryResult = ApolloReactCommon.QueryResult<ListPrivateTopicsQuery, ListPrivateTopicsQueryVariables>;
export const GetTopicDocument = gql`
    query GetTopic($topicId: ID!) {
  topic(topicId: $topicId) {
    id
    name
    slug
    colour
    ... on PrivateTopic {
      ownerId
    }
    ... on FirmTopic {
      firmId
    }
  }
}
    `;

/**
 * __useGetTopicQuery__
 *
 * To run a query within a React component, call `useGetTopicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopicQuery({
 *   variables: {
 *      topicId: // value for 'topicId'
 *   },
 * });
 */
export function useGetTopicQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTopicQuery, GetTopicQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTopicQuery, GetTopicQueryVariables>(GetTopicDocument, baseOptions);
      }
export function useGetTopicLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTopicQuery, GetTopicQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTopicQuery, GetTopicQueryVariables>(GetTopicDocument, baseOptions);
        }
export type GetTopicQueryHookResult = ReturnType<typeof useGetTopicQuery>;
export type GetTopicLazyQueryHookResult = ReturnType<typeof useGetTopicLazyQuery>;
export type GetTopicQueryResult = ApolloReactCommon.QueryResult<GetTopicQuery, GetTopicQueryVariables>;
export const ClientUpdateDocument = gql`
    subscription ClientUpdate($clientId: ID!) {
  clientUpdate(clientId: $clientId) {
    id
    name
    fullName
    preferredName
    firstName
    middleNames
    lastName
    previousFirstName
    previousLastName
    previousMiddleNames
    pictureUrl
    birthDate
    emailAddress
    mobilePhoneNumber
    homePhoneNumber
    workPhoneNumber
    nationalInsuranceNumber
    homeAddress {
      ...addressFields
    }
    previousHomeAddresses {
      ...addressFields
    }
    workAddress {
      name
      ...addressFields
    }
    addressProof {
      id
      type
      reference
      assetUrl
      country
      expiresAt
      assetFileId
      assetMime
      assetFileVersion
    }
    identityProof {
      id
      type
      reference
      assetUrl
      country
      expiresAt
      assetFileId
      assetMime
      assetFileVersion
    }
    bankDetails {
      name
      branchAddress {
        ...addressFields
      }
      accountNumber
      sortCode
    }
    profileIsComplete
    status
    referenceNumber
    isVerified
    verifiedAt
    issues {
      id
      type
      isLinked
      linkCode
      linkRequested
      opponentIssue {
        id
        legalTeam {
          id
          jobTitle
          isLead
          firm {
            id
            name
          }
          professional {
            id
            emailAddress
            preferredName
            lastName
            type
          }
        }
      }
      opponent {
        preferredName
        firstName
        lastName
        emailAddress
        nationalInsuranceNumber
        clientReferenceNumber
        isVerified
        solicitor {
          firstName
          lastName
          emailAddress
          firmName
        }
      }
    }
  }
}
    ${AddressFieldsFragmentDoc}`;

/**
 * __useClientUpdateSubscription__
 *
 * To run a query within a React component, call `useClientUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useClientUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientUpdateSubscription({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useClientUpdateSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<ClientUpdateSubscription, ClientUpdateSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<ClientUpdateSubscription, ClientUpdateSubscriptionVariables>(ClientUpdateDocument, baseOptions);
      }
export type ClientUpdateSubscriptionHookResult = ReturnType<typeof useClientUpdateSubscription>;
export type ClientUpdateSubscriptionResult = ApolloReactCommon.SubscriptionResult<ClientUpdateSubscription>;
export const NewClientAccountDocument = gql`
    subscription NewClientAccount($firmId: ID!) {
  newClientAccount(firmId: $firmId) {
    id
    status
    referenceNumber
    client {
      id
      preferredName
      firstName
      lastName
      pictureUrl
      emailAddress
      mobilePhoneNumber
      nationalInsuranceNumber
      status
      referenceNumber
    }
    leadProfessional {
      id
      preferredName
      firstName
      lastName
    }
    firm {
      id
      name
    }
  }
}
    `;

/**
 * __useNewClientAccountSubscription__
 *
 * To run a query within a React component, call `useNewClientAccountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewClientAccountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewClientAccountSubscription({
 *   variables: {
 *      firmId: // value for 'firmId'
 *   },
 * });
 */
export function useNewClientAccountSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<NewClientAccountSubscription, NewClientAccountSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<NewClientAccountSubscription, NewClientAccountSubscriptionVariables>(NewClientAccountDocument, baseOptions);
      }
export type NewClientAccountSubscriptionHookResult = ReturnType<typeof useNewClientAccountSubscription>;
export type NewClientAccountSubscriptionResult = ApolloReactCommon.SubscriptionResult<NewClientAccountSubscription>;
export const ClientAccountUpdateDocument = gql`
    subscription ClientAccountUpdate($firmId: ID!) {
  clientAccountUpdate(firmId: $firmId) {
    id
    status
    isHighlighted
    referenceNumber
    client {
      id
      preferredName
      firstName
      lastName
      pictureUrl
      emailAddress
      mobilePhoneNumber
      nationalInsuranceNumber
      isVerified
      profileIsComplete
      status
      referenceNumber
      issues {
        id
        type
        isLinked
        linkCode
        linkRequested
        opponentIssue {
          id
          legalTeam {
            id
            jobTitle
            isLead
            firm {
              id
              name
            }
            professional {
              id
              emailAddress
              preferredName
              lastName
              type
            }
          }
        }
        opponent {
          preferredName
          firstName
          lastName
          emailAddress
          nationalInsuranceNumber
          clientReferenceNumber
          isVerified
          solicitor {
            firstName
            lastName
            emailAddress
            firmName
          }
        }
      }
    }
    leadProfessional {
      id
      preferredName
      firstName
      lastName
    }
    firm {
      id
      name
    }
  }
}
    `;

/**
 * __useClientAccountUpdateSubscription__
 *
 * To run a query within a React component, call `useClientAccountUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useClientAccountUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientAccountUpdateSubscription({
 *   variables: {
 *      firmId: // value for 'firmId'
 *   },
 * });
 */
export function useClientAccountUpdateSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<ClientAccountUpdateSubscription, ClientAccountUpdateSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<ClientAccountUpdateSubscription, ClientAccountUpdateSubscriptionVariables>(ClientAccountUpdateDocument, baseOptions);
      }
export type ClientAccountUpdateSubscriptionHookResult = ReturnType<typeof useClientAccountUpdateSubscription>;
export type ClientAccountUpdateSubscriptionResult = ApolloReactCommon.SubscriptionResult<ClientAccountUpdateSubscription>;
export const ClientAccountHighlightUpdateDocument = gql`
    subscription ClientAccountHighlightUpdate($firmId: ID!) {
  clientAccountUpdate(firmId: $firmId) {
    id
    status
    referenceNumber
    client {
      id
      preferredName
      firstName
      lastName
      pictureUrl
    }
    highlights {
      conversationCount
      messageCount
      noteCount
      firmClientCount
      clientAssignmentCount
      matterCount
      keyDateCount
      taskCount
      clientCount
      questionCount
      answerCount
      firmMemberCount
      contactCount
    }
  }
}
    `;

/**
 * __useClientAccountHighlightUpdateSubscription__
 *
 * To run a query within a React component, call `useClientAccountHighlightUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useClientAccountHighlightUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientAccountHighlightUpdateSubscription({
 *   variables: {
 *      firmId: // value for 'firmId'
 *   },
 * });
 */
export function useClientAccountHighlightUpdateSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<ClientAccountHighlightUpdateSubscription, ClientAccountHighlightUpdateSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<ClientAccountHighlightUpdateSubscription, ClientAccountHighlightUpdateSubscriptionVariables>(ClientAccountHighlightUpdateDocument, baseOptions);
      }
export type ClientAccountHighlightUpdateSubscriptionHookResult = ReturnType<typeof useClientAccountHighlightUpdateSubscription>;
export type ClientAccountHighlightUpdateSubscriptionResult = ApolloReactCommon.SubscriptionResult<ClientAccountHighlightUpdateSubscription>;
export const ListClientAssignmentsForProfessionalUpdateDocument = gql`
    subscription ListClientAssignmentsForProfessionalUpdate($professionalId: ID!) {
  clientAssignmentUpdate(professionalId: $professionalId) {
    id
    clientAccount {
      id
      status
      isHighlighted
      referenceNumber
      client {
        id
        preferredName
        firstName
        lastName
        pictureUrl
        emailAddress
        mobilePhoneNumber
        nationalInsuranceNumber
        isVerified
        profileIsComplete
        status
        referenceNumber
        issues {
          id
          type
          isLinked
          linkCode
          linkRequested
          opponentIssue {
            id
            legalTeam {
              id
              jobTitle
              isLead
              firm {
                id
                name
              }
              professional {
                id
                emailAddress
                preferredName
                lastName
                type
              }
            }
          }
          opponent {
            preferredName
            firstName
            lastName
            emailAddress
            nationalInsuranceNumber
            clientReferenceNumber
            isVerified
            solicitor {
              firstName
              lastName
              emailAddress
              firmName
            }
          }
        }
      }
      leadProfessional {
        id
        preferredName
        firstName
        lastName
      }
      firm {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useListClientAssignmentsForProfessionalUpdateSubscription__
 *
 * To run a query within a React component, call `useListClientAssignmentsForProfessionalUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListClientAssignmentsForProfessionalUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListClientAssignmentsForProfessionalUpdateSubscription({
 *   variables: {
 *      professionalId: // value for 'professionalId'
 *   },
 * });
 */
export function useListClientAssignmentsForProfessionalUpdateSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<ListClientAssignmentsForProfessionalUpdateSubscription, ListClientAssignmentsForProfessionalUpdateSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<ListClientAssignmentsForProfessionalUpdateSubscription, ListClientAssignmentsForProfessionalUpdateSubscriptionVariables>(ListClientAssignmentsForProfessionalUpdateDocument, baseOptions);
      }
export type ListClientAssignmentsForProfessionalUpdateSubscriptionHookResult = ReturnType<typeof useListClientAssignmentsForProfessionalUpdateSubscription>;
export type ListClientAssignmentsForProfessionalUpdateSubscriptionResult = ApolloReactCommon.SubscriptionResult<ListClientAssignmentsForProfessionalUpdateSubscription>;
export const OnConversationUpdateDocument = gql`
    subscription onConversationUpdate {
  conversationUpdate {
    id
    pausedUntil
    unreadMessageCount
    lastMessageSentAt
  }
}
    `;

/**
 * __useOnConversationUpdateSubscription__
 *
 * To run a query within a React component, call `useOnConversationUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnConversationUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnConversationUpdateSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnConversationUpdateSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OnConversationUpdateSubscription, OnConversationUpdateSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OnConversationUpdateSubscription, OnConversationUpdateSubscriptionVariables>(OnConversationUpdateDocument, baseOptions);
      }
export type OnConversationUpdateSubscriptionHookResult = ReturnType<typeof useOnConversationUpdateSubscription>;
export type OnConversationUpdateSubscriptionResult = ApolloReactCommon.SubscriptionResult<OnConversationUpdateSubscription>;
export const FirmUpdateDocument = gql`
    subscription FirmUpdate($firmId: ID!) {
  firmUpdate(firmId: $firmId) {
    id
    name
    logoUrl
    sraNumber
    seatCount
    vacantSeatCount
    occupiedSeatCount
    members {
      id
      role
      employeeNumber
      jobTitle
      office
      isActive
      isHighlighted
      activeSince
      status
      professional {
        id
        preferredName
        firstName
        middleNames
        lastName
        workPhoneNumber
        emailAddress
        pictureUrl
        type
        status
        referenceNumber
        ... on FirmBarrister {
          sraNumber
        }
        ... on FirmSolicitor {
          sraNumber
        }
        ... on FirmParalegal {
          paralegalNumber
        }
      }
      firm {
        id
        name
        logoUrl
      }
    }
  }
}
    `;

/**
 * __useFirmUpdateSubscription__
 *
 * To run a query within a React component, call `useFirmUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useFirmUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFirmUpdateSubscription({
 *   variables: {
 *      firmId: // value for 'firmId'
 *   },
 * });
 */
export function useFirmUpdateSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<FirmUpdateSubscription, FirmUpdateSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<FirmUpdateSubscription, FirmUpdateSubscriptionVariables>(FirmUpdateDocument, baseOptions);
      }
export type FirmUpdateSubscriptionHookResult = ReturnType<typeof useFirmUpdateSubscription>;
export type FirmUpdateSubscriptionResult = ApolloReactCommon.SubscriptionResult<FirmUpdateSubscription>;
export const FirmMemberUpdateDocument = gql`
    subscription FirmMemberUpdate($professionalId: ID) {
  firmMemberUpdate(professionalId: $professionalId) {
    id
    employeeNumber
    jobTitle
    office
    isActive
    isHighlighted
    activeSince
    role
    status
    professional {
      id
      preferredName
      firstName
      middleNames
      lastName
      workPhoneNumber
      emailAddress
      pictureUrl
      type
      status
      referenceNumber
      ... on FirmBarrister {
        sraNumber
      }
      ... on FirmSolicitor {
        sraNumber
      }
      ... on FirmParalegal {
        paralegalNumber
      }
    }
    firm {
      id
      name
      sraNumber
      logoUrl
    }
  }
}
    `;

/**
 * __useFirmMemberUpdateSubscription__
 *
 * To run a query within a React component, call `useFirmMemberUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useFirmMemberUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFirmMemberUpdateSubscription({
 *   variables: {
 *      professionalId: // value for 'professionalId'
 *   },
 * });
 */
export function useFirmMemberUpdateSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<FirmMemberUpdateSubscription, FirmMemberUpdateSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<FirmMemberUpdateSubscription, FirmMemberUpdateSubscriptionVariables>(FirmMemberUpdateDocument, baseOptions);
      }
export type FirmMemberUpdateSubscriptionHookResult = ReturnType<typeof useFirmMemberUpdateSubscription>;
export type FirmMemberUpdateSubscriptionResult = ApolloReactCommon.SubscriptionResult<FirmMemberUpdateSubscription>;
export const HighlightChangeDocument = gql`
    subscription HighlightChange {
  highlightChange {
    contactCount
    conversationCount
    messageCount
    firmMemberCount
    noteCount
    firmClientCount
    clientAssignmentCount
    clientCount
    matterCount
    keyDateCount
    taskCount
    taskSectionCount
    questionCount
    answerCount
  }
}
    `;

/**
 * __useHighlightChangeSubscription__
 *
 * To run a query within a React component, call `useHighlightChangeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useHighlightChangeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHighlightChangeSubscription({
 *   variables: {
 *   },
 * });
 */
export function useHighlightChangeSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<HighlightChangeSubscription, HighlightChangeSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<HighlightChangeSubscription, HighlightChangeSubscriptionVariables>(HighlightChangeDocument, baseOptions);
      }
export type HighlightChangeSubscriptionHookResult = ReturnType<typeof useHighlightChangeSubscription>;
export type HighlightChangeSubscriptionResult = ApolloReactCommon.SubscriptionResult<HighlightChangeSubscription>;
export const IssueUpdateDocument = gql`
    subscription IssueUpdate($issueId: ID!) {
  issueUpdate(issueId: $issueId) {
    id
    type
    isLinked
    linkCode
    linkRequested
    opponentIssue {
      id
      legalTeam {
        id
        jobTitle
        isLead
        firm {
          id
          name
        }
        professional {
          id
          emailAddress
          preferredName
          lastName
          type
        }
      }
    }
    opponent {
      preferredName
      firstName
      lastName
      emailAddress
      nationalInsuranceNumber
      clientReferenceNumber
      isVerified
      solicitor {
        firstName
        lastName
        emailAddress
        firmName
      }
    }
    client {
      accounts {
        id
      }
    }
  }
}
    `;

/**
 * __useIssueUpdateSubscription__
 *
 * To run a query within a React component, call `useIssueUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIssueUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIssueUpdateSubscription({
 *   variables: {
 *      issueId: // value for 'issueId'
 *   },
 * });
 */
export function useIssueUpdateSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<IssueUpdateSubscription, IssueUpdateSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<IssueUpdateSubscription, IssueUpdateSubscriptionVariables>(IssueUpdateDocument, baseOptions);
      }
export type IssueUpdateSubscriptionHookResult = ReturnType<typeof useIssueUpdateSubscription>;
export type IssueUpdateSubscriptionResult = ApolloReactCommon.SubscriptionResult<IssueUpdateSubscription>;
export const NewMessagesDocument = gql`
    subscription NewMessages {
  newMessage {
    id
    conversationId
    sender {
      id
      preferredName
      firstName
      lastName
      pictureUrl
      role
      channel
      title
      group {
        id
        name
        pictureUrl
      }
    }
    repliedTo {
      id
      body
      sentAt
      sender {
        id
        preferredName
        firstName
        lastName
        pictureUrl
        role
        channel
        title
        group {
          id
          name
          pictureUrl
        }
      }
    }
    copies {
      recipient {
        id
        group {
          id
        }
      }
      deliveredAt
      readAt
    }
    body
    sentAt
    deliveredAt
    readAt
    attachments {
      id
      name
      type
      mime
      size
      ... on AttachedImage {
        height
        width
      }
    }
    topics {
      id
      name
      colour
    }
  }
}
    `;

/**
 * __useNewMessagesSubscription__
 *
 * To run a query within a React component, call `useNewMessagesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewMessagesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewMessagesSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNewMessagesSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<NewMessagesSubscription, NewMessagesSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<NewMessagesSubscription, NewMessagesSubscriptionVariables>(NewMessagesDocument, baseOptions);
      }
export type NewMessagesSubscriptionHookResult = ReturnType<typeof useNewMessagesSubscription>;
export type NewMessagesSubscriptionResult = ApolloReactCommon.SubscriptionResult<NewMessagesSubscription>;
export const MessageUpdateDocument = gql`
    subscription MessageUpdate {
  messageUpdate {
    id
    conversationId
    sender {
      id
      preferredName
      firstName
      lastName
      pictureUrl
      role
      channel
      title
      group {
        id
        name
        pictureUrl
      }
    }
    repliedTo {
      id
      body
      sentAt
      sender {
        id
        preferredName
        firstName
        lastName
        pictureUrl
        role
        channel
        title
        group {
          id
          name
          pictureUrl
        }
      }
    }
    copies {
      recipient {
        id
        group {
          id
        }
      }
      deliveredAt
      readAt
    }
    body
    sentAt
    deliveredAt
    readAt
    attachments {
      id
      name
      type
      mime
      size
      ... on AttachedImage {
        height
        width
      }
    }
    topics {
      id
      name
      colour
    }
  }
}
    `;

/**
 * __useMessageUpdateSubscription__
 *
 * To run a query within a React component, call `useMessageUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMessageUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageUpdateSubscription({
 *   variables: {
 *   },
 * });
 */
export function useMessageUpdateSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<MessageUpdateSubscription, MessageUpdateSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<MessageUpdateSubscription, MessageUpdateSubscriptionVariables>(MessageUpdateDocument, baseOptions);
      }
export type MessageUpdateSubscriptionHookResult = ReturnType<typeof useMessageUpdateSubscription>;
export type MessageUpdateSubscriptionResult = ApolloReactCommon.SubscriptionResult<MessageUpdateSubscription>;
export const NewNotificationsDocument = gql`
    subscription NewNotifications {
  newNotification {
    id
    context
    type
    title
    body
    pictureUrl
    occurredOn
    readAt
    isRead
    path {
      id
      type
      name
    }
  }
}
    `;

/**
 * __useNewNotificationsSubscription__
 *
 * To run a query within a React component, call `useNewNotificationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewNotificationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewNotificationsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNewNotificationsSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<NewNotificationsSubscription, NewNotificationsSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<NewNotificationsSubscription, NewNotificationsSubscriptionVariables>(NewNotificationsDocument, baseOptions);
      }
export type NewNotificationsSubscriptionHookResult = ReturnType<typeof useNewNotificationsSubscription>;
export type NewNotificationsSubscriptionResult = ApolloReactCommon.SubscriptionResult<NewNotificationsSubscription>;
export const QuestionUpdatesDocument = gql`
    subscription QuestionUpdates($questionId: ID!) {
  questionUpdate(questionId: $questionId) {
    id
    text
    description
    proportionComplete
    isReleased
    isComplete
    isHighlighted
    officialAdvice
    additionalAdvice
    parts {
      id
      label
      instructions
      isRequired
      isComplete
      isHighlighted
      ordering
      officialAdvice
      additionalAdvice
      answerDraft {
        id
        notApplicable
        proposer {
          id
          preferredName
          firstName
          lastName
          pictureUrl
        }
        ... on AddressAnswerDraft {
          address: currentValue {
            name
            line1
            line2
            line3
            city
            region
            postCode
            country
          }
        }
        ... on BooleanAnswerDraft {
          boolean: currentValue
        }
        ... on StringAnswerDraft {
          string: currentValue
        }
        ... on ChoiceAnswerDraft {
          choice: currentValue
        }
        ... on DateAnswerDraft {
          date: currentValue
        }
        ... on EmailAnswerDraft {
          email: currentValue
        }
        ... on FileAnswerDraft {
          file: currentValue {
            id
            fileId
            fileVersion
            name
            type
            mime
            size
            ... on SupportingImage {
              height
              width
            }
          }
        }
        ... on ListAnswerDraft {
          list: currentValue {
            ordering
            properties {
              attributeId
              ... on AddressListItemProperty {
                address: value {
                  name
                  line1
                  line2
                  line3
                  city
                  region
                  postCode
                  country
                }
              }
              ... on DateListItemProperty {
                date: value
              }
              ... on EmailListItemProperty {
                email: value
              }
              ... on FileListItemProperty {
                file: value
              }
              ... on MoneyListItemProperty {
                money: value
              }
              ... on NumberListItemProperty {
                number: value
              }
              ... on PercentListItemProperty {
                percent: value
              }
              ... on PhoneListItemProperty {
                phone: value
              }
              ... on StringListItemProperty {
                string: value
              }
              ... on TextListItemProperty {
                text: value
              }
            }
          }
        }
        ... on MoneyAnswerDraft {
          money: currentValue
        }
        ... on NumberAnswerDraft {
          number: currentValue
        }
        ... on PercentAnswerDraft {
          percent: currentValue
        }
        ... on PhoneAnswerDraft {
          phone: currentValue
        }
        ... on TextAnswerDraft {
          text: currentValue
        }
      }
      answer {
        id
        proposedOn
        status
        notApplicable
        isAcceptedByClient
        isAcceptedByProfessional
        proposer {
          id
          preferredName
          firstName
          lastName
          pictureUrl
        }
        ... on AddressAnswer {
          address: currentValue {
            name
            line1
            line2
            line3
            city
            region
            postCode
            country
          }
        }
        ... on BooleanAnswer {
          boolean: currentValue
        }
        ... on StringAnswer {
          string: currentValue
        }
        ... on ChoiceAnswer {
          choice: currentValue
        }
        ... on DateAnswer {
          date: currentValue
        }
        ... on EmailAnswer {
          email: currentValue
        }
        ... on FileAnswer {
          file: currentValue {
            id
            fileId
            fileVersion
            name
            type
            mime
            size
            ... on SupportingImage {
              height
              width
            }
          }
        }
        ... on ListAnswer {
          list: currentValue {
            ordering
            properties {
              attributeId
              ... on AddressListItemProperty {
                address: value {
                  name
                  line1
                  line2
                  line3
                  city
                  region
                  postCode
                  country
                }
              }
              ... on DateListItemProperty {
                date: value
              }
              ... on EmailListItemProperty {
                email: value
              }
              ... on FileListItemProperty {
                file: value
              }
              ... on MoneyListItemProperty {
                money: value
              }
              ... on NumberListItemProperty {
                number: value
              }
              ... on PercentListItemProperty {
                percent: value
              }
              ... on PhoneListItemProperty {
                phone: value
              }
              ... on StringListItemProperty {
                string: value
              }
              ... on TextListItemProperty {
                text: value
              }
            }
          }
        }
        ... on MoneyAnswer {
          money: currentValue
        }
        ... on NumberAnswer {
          number: currentValue
        }
        ... on PercentAnswer {
          percent: currentValue
        }
        ... on PhoneAnswer {
          phone: currentValue
        }
        ... on TextAnswer {
          text: currentValue
        }
        history {
          id
          notApplicable
          proposer {
            id
            preferredName
            firstName
            lastName
            pictureUrl
          }
          proposedOn
          ... on AddressAnswerRevision {
            address: value {
              name
              line1
              line2
              line3
              city
              region
              postCode
              country
            }
          }
          ... on BooleanAnswerRevision {
            boolean: value
          }
          ... on StringAnswerRevision {
            string: value
          }
          ... on ChoiceAnswerRevision {
            choice: value
          }
          ... on DateAnswerRevision {
            date: value
          }
          ... on EmailAnswerRevision {
            email: value
          }
          ... on FileAnswerRevision {
            file: value {
              id
              fileId
              fileVersion
              name
              type
              mime
              size
              ... on SupportingImage {
                height
                width
              }
            }
          }
          ... on ListAnswerRevision {
            list: value {
              ordering
              properties {
                attributeId
                ... on AddressListItemProperty {
                  address: value {
                    name
                    line1
                    line2
                    line3
                    city
                    region
                    postCode
                    country
                  }
                }
                ... on DateListItemProperty {
                  date: value
                }
                ... on EmailListItemProperty {
                  email: value
                }
                ... on FileListItemProperty {
                  file: value
                }
                ... on MoneyListItemProperty {
                  money: value
                }
                ... on NumberListItemProperty {
                  number: value
                }
                ... on PercentListItemProperty {
                  percent: value
                }
                ... on PhoneListItemProperty {
                  phone: value
                }
                ... on StringListItemProperty {
                  string: value
                }
                ... on TextListItemProperty {
                  text: value
                }
              }
            }
          }
          ... on MoneyAnswerRevision {
            money: value
          }
          ... on NumberAnswerRevision {
            number: value
          }
          ... on PercentAnswerRevision {
            percent: value
          }
          ... on PhoneAnswerRevision {
            phone: value
          }
          ... on TextAnswerRevision {
            text: value
          }
        }
      }
      ... on AddressPart {
        useName
      }
      ... on BooleanPart {
        positiveLabel
        negativeLabel
      }
      ... on ChoicePart {
        options {
          value
          description
        }
        minimumChoices
        maximumChoices
      }
      ... on DatePart {
        earliestDate
        latestDate
        useTime
      }
      ... on FilePart {
        maximumFiles
      }
      ... on ListPart {
        attributes {
          id
          label
          instructions
          isRequired
          ordering
          ... on AddressAttribute {
            useName
          }
          ... on DateAttribute {
            earliestDate
            latestDate
            useTime
          }
          ... on FileAttribute {
            maximumFiles
          }
          ... on MoneyAttribute {
            minimumAmount
            maximumAmount
            currency
          }
          ... on NumberAttribute {
            minimumValue
            maximumValue
          }
          ... on PercentAttribute {
            minimumValue
            maximumValue
          }
          ... on StringAttribute {
            maximumLength
            minimumLength
          }
          ... on TextAttribute {
            maximumLength
            minimumLength
          }
        }
        minimumItems
        maximumItems
      }
      ... on MoneyPart {
        minimumAmount
        maximumAmount
        currency
      }
      ... on NumberPart {
        minimumValue
        maximumValue
      }
      ... on PercentPart {
        minimumValue
        maximumValue
      }
      ... on StringPart {
        maximumLength
        minimumLength
      }
      ... on TextPart {
        maximumLength
        minimumLength
      }
    }
  }
}
    `;

/**
 * __useQuestionUpdatesSubscription__
 *
 * To run a query within a React component, call `useQuestionUpdatesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useQuestionUpdatesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionUpdatesSubscription({
 *   variables: {
 *      questionId: // value for 'questionId'
 *   },
 * });
 */
export function useQuestionUpdatesSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<QuestionUpdatesSubscription, QuestionUpdatesSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<QuestionUpdatesSubscription, QuestionUpdatesSubscriptionVariables>(QuestionUpdatesDocument, baseOptions);
      }
export type QuestionUpdatesSubscriptionHookResult = ReturnType<typeof useQuestionUpdatesSubscription>;
export type QuestionUpdatesSubscriptionResult = ApolloReactCommon.SubscriptionResult<QuestionUpdatesSubscription>;
export const TaskUpdateDocument = gql`
    subscription TaskUpdate($taskId: ID!) {
  taskUpdate(taskId: $taskId) {
    id
    name
    description
    sectionCount
    isModifiable
    officialAdvice
    additionalAdvice
    ... on FirmTask {
      matterTypes
      isPublished
    }
    ... on LibraryTask {
      matterTypes
    }
    ... on MatterTask {
      dueDate {
        id
        date
        isAllDay
        name
        description
      }
      status
      proportionComplete
      proportionReleased
      isHighlighted
    }
    sections {
      id
      name
      description
      ordering
      officialAdvice
      additionalAdvice
      dueDate {
        id
        date
        isAllDay
        name
        description
      }
      proportionComplete
      proportionReleased
      isHighlighted
      questions {
        id
        text
        description
        isReleased
        proportionComplete
        isHighlighted
        officialAdvice
        additionalAdvice
        status
        parts {
          id
          label
          isComplete
          isHighlighted
          officialAdvice
          additionalAdvice
        }
      }
    }
  }
}
    `;

/**
 * __useTaskUpdateSubscription__
 *
 * To run a query within a React component, call `useTaskUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTaskUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskUpdateSubscription({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskUpdateSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<TaskUpdateSubscription, TaskUpdateSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<TaskUpdateSubscription, TaskUpdateSubscriptionVariables>(TaskUpdateDocument, baseOptions);
      }
export type TaskUpdateSubscriptionHookResult = ReturnType<typeof useTaskUpdateSubscription>;
export type TaskUpdateSubscriptionResult = ApolloReactCommon.SubscriptionResult<TaskUpdateSubscription>;
export const NewMatterTaskDocument = gql`
    subscription NewMatterTask($matterId: ID!) {
  newMatterTask(matterId: $matterId) {
    id
    name
    description
    status
    sectionCount
    dueDate {
      id
      date
      isAllDay
      name
      description
    }
    proportionComplete
    proportionReleased
    isHighlighted
    isModifiable
    officialAdvice
    additionalAdvice
    sections {
      id
      name
      description
      ordering
      officialAdvice
      additionalAdvice
      dueDate {
        id
        date
        isAllDay
        name
        description
      }
      isHighlighted
      questions {
        id
        text
        description
        isReleased
        proportionComplete
        isHighlighted
        officialAdvice
        additionalAdvice
        status
        parts {
          id
          label
          isComplete
          isHighlighted
          officialAdvice
          additionalAdvice
        }
      }
    }
  }
}
    `;

/**
 * __useNewMatterTaskSubscription__
 *
 * To run a query within a React component, call `useNewMatterTaskSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewMatterTaskSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewMatterTaskSubscription({
 *   variables: {
 *      matterId: // value for 'matterId'
 *   },
 * });
 */
export function useNewMatterTaskSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<NewMatterTaskSubscription, NewMatterTaskSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<NewMatterTaskSubscription, NewMatterTaskSubscriptionVariables>(NewMatterTaskDocument, baseOptions);
      }
export type NewMatterTaskSubscriptionHookResult = ReturnType<typeof useNewMatterTaskSubscription>;
export type NewMatterTaskSubscriptionResult = ApolloReactCommon.SubscriptionResult<NewMatterTaskSubscription>;