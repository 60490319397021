import * as React from 'react';
import {
  Keyboard,
  Platform,
  SafeAreaView,
  StyleSheet,
  useWindowDimensions,
  View,
} from 'react-native';
import { black, blue, white } from '../configs/colours';
import screenSizes from '../configs/screenSizes';
import { useGetFirmMembership } from '../hooks/useGetFirm';
import useIsNonPro from '../hooks/usIsNonPro';
import { useGetHighlightCountsQuery } from '../types/apolloTypes';
import Breadcrumbs from './Breadcrumbs';
import GlobalSearch from './GlobalSearch';
import BellIcon from './icons/BellIcon';
import Menu from './icons/MenuIcon';
import { NotificationContext } from './NotificationContext';
import PlatformTouchable from './PlatformTouchable';
import { ThemeContext } from './ThemeProvider';

interface Props {
  sideVisibility: boolean;
  toggleSideVisibility: (visibility: boolean) => void;
  notificationsVisibility: boolean;
  toggleNotificationsVisibility: (visibility: boolean) => void;
}

const Header: React.FunctionComponent<Props> = ({
  toggleSideVisibility,
  sideVisibility,
  notificationsVisibility,
  toggleNotificationsVisibility,
}) => {
  const { themeColours } = React.useContext(ThemeContext);
  const { width = 1000 } = useWindowDimensions();
  const { totalUnread } = React.useContext(NotificationContext);

  const { data: highlightData } = useGetHighlightCountsQuery();

  const totalHighlights = Object.values(highlightData?.highlightCounts ?? {})
    .filter((v) => typeof v === 'number')
    .reduce(
      (acc, cur) => parseInt(acc.toString()) + parseInt(cur.toString()),
      0,
    );

  const isNonPro = useIsNonPro();
  const firmMembership = useGetFirmMembership();

  const membershipActive = isNonPro ? true : firmMembership?.isActive;

  return (
    <SafeAreaView
      style={[
        HeaderStyle.wrapper,
        { backgroundColor: '#383D3F' },
        width > screenSizes.medium && HeaderStyle.wrapperDesktop,
      ]}
    >
      <View style={[width <= screenSizes.medium && HeaderStyle.wrapInner]}>
        {width <= screenSizes.medium && (
          <>
            <PlatformTouchable
              onPress={() => {
                toggleSideVisibility(!sideVisibility);
                Keyboard.dismiss();
              }}
              style={[HeaderStyle.menuWrap]}
            >
              <View>
                <Menu
                  toggled={Platform.OS === 'ios' && sideVisibility}
                  fill={themeColours.primaryFont}
                />
                {!!totalHighlights &&
                  totalHighlights > 0 &&
                  !sideVisibility && (
                    <View style={[HeaderStyle.highlightCircle]} />
                  )}
              </View>
            </PlatformTouchable>
            <Breadcrumbs />
            <PlatformTouchable
              style={[HeaderStyle.notificationButton]}
              onPress={() =>
                membershipActive &&
                toggleNotificationsVisibility(!notificationsVisibility)
              }
            >
              <>
                <BellIcon />
                {totalUnread > 0 && membershipActive && (
                  <View style={[HeaderStyle.notificationButtonAttention]} />
                )}
              </>
            </PlatformTouchable>
          </>
        )}

        <View style={[HeaderStyle.searchWrap]}>
          <GlobalSearch />
        </View>
        {width > screenSizes.medium && <Breadcrumbs />}
      </View>
    </SafeAreaView>
  );
};
export default Header;

const HeaderStyle = StyleSheet.create({
  wrapper: {
    position: 'relative',
    backgroundColor: white,
    shadowColor: black,
    zIndex: 2,
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.04,
    shadowRadius: 10,
    elevation: 5,
    minHeight: 72,
    justifyContent: 'flex-end',
  },
  wrapInner: {
    paddingHorizontal: 24,
    paddingVertical: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    minHeight: Platform.OS === 'web' ? 35 : 0,
  },
  menuWrap: {
    width: 30,
  },
  wrapperDesktop: {
    backgroundColor: '#2B2F31',
    shadowOpacity: 0,
    height: 100,
    padding: 0,
    paddingTop: 15,
    paddingRight: 15,
    borderBottomColor: '#4B4F52',
    borderBottomWidth: 1,
    justifyContent: 'flex-start',
  },
  searchWrap: {
    flexDirection: 'row',
  },
  notificationButton: {
    position: 'relative',
    marginRight: 35,
    marginLeft: -35,
  },
  notificationButtonAttention: {
    position: 'absolute',
    top: -4,
    right: -1,
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: blue,
  },
  notificationButtonAttentionDesktop: {
    top: 6,
    right: 7,
  },
  notificationButtonDesktop: {
    width: 36,
    height: 32,
    borderRadius: 3,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#323738',
    marginLeft: 15,
    marginRight: 0,
  },
  highlightCircle: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: blue,
    position: 'absolute',
    top: -4,
    right: 8,
  },
});
