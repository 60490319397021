import { format, isThisWeek, isToday, parseISO } from 'date-fns/esm';
import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, useWindowDimensions, View } from 'react-native';
import { blue } from '../configs/colours';
import screenSizes from '../configs/screenSizes';
import useResumeConversation from '../hooks/useResumeConversation';
import useIsNonPro from '../hooks/usIsNonPro';
import { useGetCurrentUserQuery, KeyValueString } from '../types/apolloTypes';
import { ConversationsConversation } from '../types/Conversations';
import { makeString } from './ConversationListItem';
import ModalInline from './ModalInline';
import PersonListItem from './PersonListItem';
import Pill from './Pill';

export enum MessageColours {
  purple = '#B16AFD',
  orange = '#FFC400',
  yellow = '#F6FC5E',
  green = '#00FF98',
}
interface Props {
  isInsitu?: boolean;
  isPending: boolean;
  colour: MessageColours;
  onSelect: () => void;
}
const ClientConversationListItem: React.FunctionComponent<
  Props & ConversationsConversation
> = ({
  groups,
  participants,
  id,
  onSelect,
  unreadMessageCount,
  pausedUntil,
  metadata,
  name,
  isPending,
  colour,
  pictureUrl,
  description,
}) => {
  const { width = 1000 } = useWindowDimensions();

  const date = parseISO(pausedUntil ? pausedUntil.toString() : '');
  const dateIsToday = isToday(date);
  const dateIsThisWeek = isThisWeek(date);
  const formattedDate = dateIsToday
    ? format(date, 'HH:mm')
    : dateIsThisWeek
    ? format(date, 'dd/MM/yy HH:mm')
    : 'resumed';

  const resumeConversation = useResumeConversation(id);

  const pausedDifference = !!pausedUntil
    ? parseInt(format(parseISO(pausedUntil), 'T'), 10) -
      parseInt(format(new Date(), 'T'), 10)
    : 0;

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (pausedDifference > 0) {
      timeout = setTimeout(() => {
        resumeConversation();
      }, pausedDifference);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [pausedUntil]);

  const pausedUntilText = (
    <View
      style={[
        ClientConversationListItemStyle.pausedWrap,
        width > screenSizes.medium &&
          ClientConversationListItemStyle.pausedWrapDesktop,
      ]}
    >
      <Text
        style={[
          ClientConversationListItemStyle.pausedText,
          width > screenSizes.medium &&
            ClientConversationListItemStyle.pausedTextDesktop,
        ]}
      >
        until{' '}
        <Text
          style={{
            color: '#E5F3F8',
          }}
        >
          {formattedDate}
        </Text>
      </Text>
    </View>
  );

  const [showUnpause, setShowUnpause] = useState<boolean>(false);

  const conversationName = name?.split(' ') ?? [];

  const [conversationFirstName, ...restOfConversationName] = conversationName;

  const conversationTypeMetadata = metadata.find(
    (metadata) => metadata.key === 'conversationType',
  ) as KeyValueString;

  const conversationType = conversationTypeMetadata?.value;

  return (
    <>
      <PersonListItem
        firstName={conversationFirstName ?? ''}
        lastName={restOfConversationName?.join(' ') ?? ''}
        pictureUrl={pictureUrl}
        roleText={description}
        isPending={isPending}
        onPress={!isPending && onSelect}
        totalNumber={!!pausedUntil ? 0 : unreadMessageCount}
        isHighlighted={
          (unreadMessageCount > 0 && conversationType !== 'opponent') ||
          !!pausedUntil
        }
        pills={[
          ...(isPending ? [{ text: 'not set up', colour: '#99A4A7' }] : []),
          ...(!isPending && conversationType === 'opponent'
            ? [{ text: 'observed', colour: '#99A4A7' }]
            : []),
          ...(!isPending && conversationType === 'firm'
            ? [{ text: 'sidebar', colour: '#99A4A7' }]
            : []),
          ...(!isPending && conversationType === 'disclosure'
            ? [{ text: 'other side', colour: '#99A4A7' }]
            : []),
        ]}
        otherComponent={
          !!pausedUntil && (
            <>
              {width > screenSizes.medium && pausedUntil && pausedUntilText}
              <View style={[ClientConversationListItemStyle.pausePillWrap]}>
                <Pill
                  value={unreadMessageCount}
                  lineStyle={true}
                  pausedPill={true}
                  onPress={() => setShowUnpause(true)}
                />
                {width <= screenSizes.medium && pausedUntil && pausedUntilText}
              </View>
            </>
          )
        }
        colour={colour}
        countColour={conversationType === 'opponent' ? '#99A4A7' : blue}
      />
      <ModalInline
        close={() => setShowUnpause(false)}
        showModal={showUnpause}
        size="small"
        actionText="resume"
        textContent="are you sure you want to resume this conversation?"
        title="resume conversation"
        action={resumeConversation}
      />
    </>
  );
};

export default ClientConversationListItem;

const ClientConversationListItemStyle = StyleSheet.create({
  pausePillWrap: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  pausedText: {
    marginTop: 3,
    fontFamily: 'Quicksand-Regular',
    alignItems: 'center',
    fontSize: 12,
    color: '#AAA9A9',
    marginLeft: 8,
  },
  pausedTextDesktop: {
    marginTop: 0,
  },
  pausedWrap: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  pausedWrapDesktop: {
    marginRight: 25,
  },
});
