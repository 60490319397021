import React, { lazy, Suspense } from 'react';
import { StyleSheet, View } from 'react-native';
import { MenuProvider } from 'react-native-popup-menu';
import { useGetFirmMembership } from '../hooks/useGetFirm';
import { Route, Switch, useLocation, Redirect } from '../utils/routing';
import Layout from './Layout';
import MenuContextProvider from './MenuContextProvider';
import useIsNonPro from '../hooks/usIsNonPro';
import NotificationProvider from './NotificationProvider';
import LoadingView from './LoadingView';
import PageWrap from './PageWrap';
import ClientConversations from '../containers/ClientConversations';

const Conversations = lazy(() => import('../containers/Conversations'));
const Conversation = lazy(() => import('../containers/Conversation'));
const Dashboard = lazy(() => import('../containers/Dashboard'));
const FileManager = lazy(() => import('../containers/FileManager'));
const Client = lazy(() => import('../containers/Client'));
const KeyDates = lazy(() => import('../containers/KeyDates'));
const Matter = lazy(() => import('../containers/Matter'));
const Matters = lazy(() => import('../containers/Matters'));
const Notes = lazy(() => import('../containers/Notes'));
const ProfileSettings = lazy(() => import('../containers/ProfileSettings'));
const SupportingMaterial = lazy(
  () => import('../containers/SupportingMaterial'),
);
const FirmMember = lazy(() => import('../containers/FirmMember'));
const ClientAccounts = lazy(() => import('../containers/ClientAccounts'));
const Contacts = lazy(() => import('../containers/Contacts'));
const ClientProfile = lazy(() => import('../containers/ClientProfile'));
const FirmMembers = lazy(() => import('../containers/FirmMembers'));
const FirmDashboard = lazy(() => import('../containers/FirmDashboard'));
const FirmClients = lazy(() => import('../containers/FirmClients'));
const AssignedProfessionals = lazy(
  () => import('../containers/AssignedProfessionals'),
);
const AssignedClients = lazy(() => import('../containers/AssignedClients'));
const FirmProfile = lazy(() => import('../containers/FirmProfile'));
const FirmMemberProfile = lazy(() => import('../containers/FirmMemberProfile'));
const InactiveAccount = lazy(() => import('../containers/InactiveAccount'));
const TaskLibrary = lazy(() => import('../containers/TaskLibrary'));
const LibraryTaskEditMode = lazy(
  () => import('../containers/LibraryTaskEditMode'),
);
const Task = lazy(() => import('../containers/Task'));
const Ticket = lazy(() => import('./Ticket'));
const TaskEditMode = lazy(() => import('../containers/TaskEditMode'));

const Routes: React.FunctionComponent = () => {
  const location = useLocation();
  const firmMembership = useGetFirmMembership();
  const isNonPro = useIsNonPro();
  const membershipActive = isNonPro ? true : firmMembership?.isActive;

  return (
    <MenuProvider>
      <MenuContextProvider>
        <NotificationProvider>
          <Layout>
            <View style={[RoutesStyle.container]}>
              <View style={[RoutesStyle.wrap]}>
                <Suspense
                  fallback={
                    <PageWrap
                      backgroundStyle="LOGO"
                      containerStyle={RoutesStyle.pageWrap}
                    >
                      <LoadingView />
                    </PageWrap>
                  }
                >
                  {membershipActive ? (
                    <Switch location={location}>
                      <Route exact={true} path="/" children={<Dashboard />} />
                      <Route path="/connect/:ticket" children={<Dashboard />} />
                      <Route
                        exact={true}
                        path={[
                          '/conversations/:id',
                          '/clients/:clientId/conversations/:id',
                          '/firm-administration/clients/:clientId/conversations/:id',
                          '/conversations/clients/:clientId/conversations/:id',
                        ]}
                        children={<Conversation />}
                      />
                      <Route
                        exact={true}
                        path={[
                          '/clients/:clientId/conversations',
                          '/firm-administration/clients/:clientId/conversations',
                          '/conversations/clients/:clientId/conversations',
                        ]}
                        children={<ClientConversations />}
                      />
                      <Route
                        exact={true}
                        path="/conversations"
                        children={<Conversations />}
                      />
                      <Route
                        exact={true}
                        path="/clients"
                        children={<ClientAccounts />}
                      />
                      <Route
                        exact={true}
                        path={[
                          '/clients/:clientId',
                          '/firm-administration/clients/:clientId',
                        ]}
                        children={<Client />}
                      />
                      <Route
                        exact={true}
                        path={[
                          '/clients/:clientId/matters',
                          '/firm-administration/clients/:clientId/matters',
                        ]}
                        children={<Matters />}
                      />
                      <Route
                        path={[
                          '/clients/:clientId/profile',
                          '/firm-administration/clients/:clientId/profile',
                        ]}
                        children={<ClientProfile />}
                      />
                      <Route
                        exact={true}
                        path={[
                          '/clients/:clientId/professionals',
                          '/firm-administration/clients/:clientId/professionals',
                        ]}
                        children={<AssignedProfessionals />}
                      />
                      <Route
                        exact={true}
                        path={[
                          '/clients/:clientId/key-dates',
                          '/firm-administration/clients/:clientId/key-dates',
                        ]}
                        children={<KeyDates />}
                      />

                      <Route
                        exact={true}
                        path={[
                          '/clients/:clientId/matters/:matterId',
                          '/firm-administration/clients/:clientId/matters/:matterId',
                        ]}
                        children={<Matter />}
                      />
                      <Route
                        exact={true}
                        path={[
                          '/clients/:clientId/matters/:matterId/supporting-materials',
                          '/firm-administration/clients/:clientId/matters/:matterId/supporting-materials',
                        ]}
                        children={<SupportingMaterial />}
                      />
                      <Route
                        exact={true}
                        path={[
                          '/clients/:clientId/matters/:matterId/tasks/:taskId',
                          '/firm-administration/clients/:clientId/matters/:matterId/tasks/:taskId',
                        ]}
                        children={<TaskEditMode />}
                      />
                      <Route
                        exact={true}
                        path={[
                          '/clients/:clientId/notes',
                          '/firm-administration/clients/:clientId/notes',
                        ]}
                        children={<Notes />}
                      />
                      <Route
                        path={[
                          '/matters/:matterId/tasks/:taskId/sections/:sectionId/questions/:questionId',
                          '/matters/:matterId/tasks/:taskId/sections/:sectionId/',
                          '/matters/:matterId/tasks/:taskId/sections',
                          '/clients/:clientId/matters/:matterId/tasks/:taskId/sections/:sectionId/questions/:questionId',
                          '/firm-administration/clients/:clientId/matters/:matterId/tasks/:taskId/sections/:sectionId/questions/:questionId',
                          '/clients/:clientId/matters/:matterId/tasks/:taskId/sections/:sectionId',
                          '/firm-administration/clients/:clientId/matters/:matterId/tasks/:taskId/sections/:sectionId',
                          '/clients/:clientId/matters/:matterId/tasks/:taskId/sections',
                          '/firm-administration/clients/:clientId/matters/:matterId/tasks/:taskId/sections',
                        ]}
                        children={<Task />}
                      />
                      <Route
                        exact={true}
                        path="/matters"
                        children={<Matters />}
                      />
                      <Route
                        exact={true}
                        path="/matters/:matterId"
                        children={<Matter />}
                      />
                      <Route
                        exact={true}
                        path="/matters/:matterId/supporting-materials"
                        children={<SupportingMaterial />}
                      />
                      <Route
                        exact={true}
                        path="/file-manager"
                        children={<FileManager />}
                      />
                      <Route
                        exact={true}
                        path="/contacts"
                        children={<Contacts />}
                      />
                      <Route exact={true} path="/notes" children={<Notes />} />
                      <Route
                        exact={true}
                        path="/key-dates"
                        children={<KeyDates />}
                      />
                      <Route
                        exact={true}
                        path="/task-library"
                        children={<TaskLibrary />}
                      />
                      <Route
                        exact={true}
                        path="/task-library/:taskId"
                        children={<LibraryTaskEditMode />}
                      />
                      <Route
                        exact={true}
                        path="/legal-team"
                        children={<AssignedProfessionals />}
                      />
                      <Route
                        path="/file-manager/:folderId"
                        children={<FileManager />}
                      />
                      <Redirect
                        from="/issues/link/:code"
                        to="/profile-settings/issue-management?code=:code"
                      />
                      <Route
                        exact={true}
                        path={'/profile-settings/issue-management/:ticket'}
                        children={<ProfileSettings />}
                      />
                      <Route
                        path={[
                          '/profile-settings',
                          '/profile-settings/issue-management',
                          '/profile-settings/issue-management/:ticket',
                          '/profile-settings/security-details',
                          '/profile-settings/personal-details',
                        ]}
                        children={<ProfileSettings />}
                      />
                      <Route
                        exact={true}
                        path="/firm-administration"
                        children={<FirmDashboard />}
                      />
                      <Route
                        exact={true}
                        path="/firm-administration/profile"
                        children={<FirmProfile />}
                      />
                      <Route
                        exact={true}
                        path="/firm-administration/members"
                        children={<FirmMembers />}
                      />
                      <Route
                        path="/firm-administration/members/:memberId"
                        exact={true}
                        children={<FirmMember />}
                      />
                      <Route
                        path="/firm-administration/members/:memberId/profile"
                        children={<FirmMemberProfile />}
                      />
                      <Route
                        path="/firm-administration/members/:memberId/clients"
                        children={<AssignedClients />}
                      />
                      <Route
                        path="/firm-administration/clients"
                        children={<FirmClients />}
                      />
                      <Route path="/ticket/:ticket" children={<Ticket />} />
                    </Switch>
                  ) : (
                    <Switch location={location}>
                      <Route children={<InactiveAccount />} />
                    </Switch>
                  )}
                </Suspense>
              </View>
            </View>
          </Layout>
        </NotificationProvider>
      </MenuContextProvider>
    </MenuProvider>
  );
};

export default Routes;

const RoutesStyle = StyleSheet.create({
  wrap: {
    position: 'absolute',
    flex: 1,
    height: '100%',
    width: '100%',
  },
  container: { position: 'relative', overflow: 'hidden', flex: 1 },
  pageWrap: {
    flex: 1,
  },
});
