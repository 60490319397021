import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Platform,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  useWindowDimensions,
  View,
} from 'react-native';
import { black, blue, white } from '../configs/colours';
import screenSizes from '../configs/screenSizes';
import useDebounce from '../hooks/useDebounce';
import { useGetFirmMembership } from '../hooks/useGetFirm';
import usePrevious from '../hooks/usePrevious';
import useIsNonPro from '../hooks/usIsNonPro';
import { SearchContext, useSearchLazyQuery } from '../types/apolloTypes';
import { useLocation } from '../utils/routing';
import GlobalSearchResult from './GlobalSearchResult';
import GlobalSearchResultGeneric from './GlobalSearchResultGeneric';
import CloseIcon from './icons/CloseIcon';
import FilterIcon from './icons/FilterIcon';
import NoSearchResultsIcon from './icons/NoSearchResultsIcon';
import SearchIcon from './icons/SearchIcon';
import LoadingView from './LoadingView';
import ModalWrap from './ModalWrap';
import PlatformTouchable from './PlatformTouchable';
import { ThemeContext } from './ThemeProvider';

interface SearchInputProps {
  onFocus?: () => void;
  onTextChange?: Dispatch<SetStateAction<string>>;
  focusOnRender?: boolean;
  value?: string;
  inputRef?: React.MutableRefObject<TextInput>;
}

const SearchInput: React.FunctionComponent<SearchInputProps> = ({
  onFocus,
  focusOnRender = false,
  onTextChange,
  value,
  inputRef,
}) => {
  const { themeColours } = useContext(ThemeContext);
  const { width = 1000 } = useWindowDimensions();
  const desktopWidth = width > screenSizes.medium;

  const [visibility, setVisibility] = useState(desktopWidth);
  const input = useRef<TextInput>();

  useEffect(() => {
    if (focusOnRender) {
      setTimeout(() => input.current.focus(), 10);
    }
    // if (visibility && input && input.current && width <= screenSizes.medium) {
    // }
    // if (!visibility && input && input.current && width <= screenSizes.medium) {
    //   input.current.blur();
    // }
  }, []);

  return (
    <View
      style={[
        GlobalSearchStyle.main,
        desktopWidth && GlobalSearchStyle.mainDesktop,
        width <= screenSizes.medium &&
          {
            // backgroundColor: animationValue.interpolate({
            //   inputRange: [0, 1],
            //   outputRange: [
            //     hexToRGB(themeColours.searchBackground, 1),
            //     hexToRGB(themeColours.searchBackground, 0),
            //   ],
            // }) as any,
          },
        visibility && {
          elevation: 5,
        },
      ]}
    >
      <View
        style={[
          GlobalSearchStyle.searchIcon,
          !desktopWidth && GlobalSearchStyle.btnPad,
          desktopWidth && GlobalSearchStyle.searchIconDesktop,
        ]}
      >
        <SearchIcon
          fill={desktopWidth ? '#E5F3F8' : '#9A9A9A'}
          width={13.09}
          height={13.09}
        />
      </View>
      <TextInput
        ref={inputRef || input}
        style={[
          { color: white },
          desktopWidth
            ? GlobalSearchStyle.inputDesktop
            : GlobalSearchStyle.input,
        ]}
        placeholder="find timelines, files, messages and more"
        placeholderTextColor={themeColours.searchColour}
        onChangeText={onTextChange}
        onBlur={() =>
          width <= screenSizes.medium && visibility && setVisibility(false)
        }
        onFocus={onFocus}
        value={value}
      />
      <View
        style={[
          GlobalSearchStyle.filterIcon,
          desktopWidth && GlobalSearchStyle.filterIconDesktop,
        ]}
      >
        <FilterIcon
          fill={desktopWidth ? '#E5F3F8' : themeColours.searchColour}
          width={desktopWidth ? 15.29 : undefined}
          height={desktopWidth ? 13.5 : undefined}
        />
      </View>
    </View>
  );
};

function getContext(location: string): SearchContext | null {
  if (
    location.indexOf('/matters') >= 0 ||
    location.indexOf('/key-dates') === 0
  ) {
    return SearchContext.Matters;
  }
  if (location.indexOf('/clients') === 0 || location.indexOf('/notes') > -1) {
    return SearchContext.FirmAdmin;
  }
  if (location.indexOf('/conversations') === 0) {
    return SearchContext.Messaging;
  }
  if (location.indexOf('/file-manager') === 0) {
    return SearchContext.Files;
  }
  if (location.indexOf('/contacts') === 0) {
    return SearchContext.Contacts;
  }

  return null;
}

function getContextTitle(context: SearchContext) {
  if (context === SearchContext.Files) return 'in files and folders';
  if (context === SearchContext.Matters) return 'in matters';
  if (context === SearchContext.Messaging) return 'in conversations';
  if (context === SearchContext.FirmAdmin) return 'in your firm';
  if (context === SearchContext.Contacts) return 'in your contacts';
  return null;
}

const GlobalSearch: React.FunctionComponent = () => {
  const { themeColours } = useContext(ThemeContext);
  const { width = 1000 } = useWindowDimensions();
  const desktopWidth = width > screenSizes.medium;

  const [visibility, setVisibility] = useState<boolean>(false);
  const [searchVal, setSearchVal] = useState<string>('');

  const location = useLocation();

  const [context, setContext] = useState<SearchContext | null>(null);

  useEffect(() => {
    const theContext = getContext(location.pathname);
    if (context !== theContext) {
      setContext(theContext);
    }
    if (searchVal !== '') setSearchVal('');
  }, [location?.pathname]);

  const searchValPrevLength = usePrevious<number>(searchVal.length) ?? 0;

  const input = useRef<TextInput>();

  useEffect(() => {
    if (
      searchVal.length === 0 &&
      searchValPrevLength > 0 &&
      input.current &&
      desktopWidth
    ) {
      input.current.focus();
    }
  }, [searchVal.length]);

  const debouncedSearchVal = useDebounce(searchVal, 500);

  const [search, { data: searchData, loading }] = useSearchLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!!debouncedSearchVal) {
      search({
        variables: {
          term: debouncedSearchVal,
          ...(!!context && { context }),
        },
      });
    }
  }, [debouncedSearchVal]);

  const totalResults =
    (searchData?.search?.contextualMatches?.length ?? 0) +
    (searchData?.search?.otherMatches?.length ?? 0);

  const isNonPro = useIsNonPro();
  const firmMembership = useGetFirmMembership();

  const membershipActive = isNonPro ? true : firmMembership?.isActive;

  return (
    <>
      {!desktopWidth && (
        <PlatformTouchable
          style={[GlobalSearchStyle.searchIconButton]}
          onPress={() => {
            if (membershipActive) {
              setVisibility(true);
            }
          }}
        >
          <SearchIcon
            fill={
              desktopWidth
                ? '#E5F3F8'
                : visibility
                ? themeColours.searchColour
                : themeColours.primaryFont
            }
            width={desktopWidth ? 13.09 : undefined}
            height={desktopWidth ? 13.09 : undefined}
          />
        </PlatformTouchable>
      )}

      {desktopWidth && (
        <View style={[GlobalSearchStyle.mainDesktop]}>
          <SearchInput
            onFocus={() => membershipActive && setVisibility(true)}
            onTextChange={setSearchVal}
            value={searchVal}
            inputRef={input}
          />
        </View>
      )}

      <ModalWrap
        visible={visibility && (!desktopWidth || searchVal.length > 0)}
        animated={true}
        animationType="fade"
        transparent={true}
      >
        <View style={[GlobalSearchStyle.modalBackground]}>
          {visibility && (!desktopWidth || searchVal.length > 0) && (
            <View
              style={[
                GlobalSearchStyle.modalWrap,
                desktopWidth && {
                  paddingLeft: 200,
                  paddingRight: 260,
                  paddingTop: 15,
                },
              ]}
            >
              <SafeAreaView
                style={[!desktopWidth && GlobalSearchStyle.inputWrap]}
              >
                <SearchInput
                  focusOnRender={true}
                  onTextChange={setSearchVal}
                  value={searchVal}
                />
                <PlatformTouchable
                  onPress={() => {
                    setVisibility(false);
                    setSearchVal('');
                  }}
                  style={[
                    GlobalSearchStyle.closeButton,
                    desktopWidth && GlobalSearchStyle.closeButtonDesktop,
                  ]}
                >
                  <CloseIcon fill={'#9A9A9A'} />
                </PlatformTouchable>
              </SafeAreaView>
              <View style={{ flex: 1 }}>
                <View
                  style={[
                    GlobalSearchStyle.searchResultsTotal,
                    desktopWidth && GlobalSearchStyle.searchResultsTotalDesktop,
                  ]}
                >
                  <Text style={[GlobalSearchStyle.searchResultsTotalText]}>
                    {!!searchVal && totalResults === 0 && !loading
                      ? 'no results found'
                      : `showing (${searchVal ? totalResults : 0}) results`}
                  </Text>
                </View>
                {!!searchVal && loading && totalResults === 0 && (
                  <LoadingView />
                )}
                {!!searchVal && totalResults === 0 && !loading && (
                  <View style={[GlobalSearchStyle.noResults]}>
                    <NoSearchResultsIcon />
                    <Text style={[GlobalSearchStyle.noResultsText]}>
                      please try different search terms
                    </Text>
                  </View>
                )}
                {!!searchVal && (!loading || totalResults > 0) && (
                  <ScrollView
                    contentContainerStyle={{
                      paddingTop: 1,
                      paddingBottom: 20,
                    }}
                  >
                    {searchData?.search?.contextualMatches?.length > 0 && (
                      <>
                        <View style={[GlobalSearchStyle.searchResultsTitle]}>
                          <Text
                            style={[GlobalSearchStyle.searchResultsTitleText]}
                          >
                            {getContextTitle(context)}
                          </Text>
                        </View>
                        {searchData?.search?.contextualMatches?.map(
                          (match, index) => (
                            <GlobalSearchResult
                              key={index}
                              match={match}
                              onPress={() => setVisibility(false)}
                            />
                          ),
                        )}
                      </>
                    )}
                    {searchData?.search?.otherMatches?.length > 0 && (
                      <>
                        {searchData?.search?.contextualMatches?.length > 0 && (
                          <View style={[GlobalSearchStyle.searchResultsTitle]}>
                            <Text
                              style={[GlobalSearchStyle.searchResultsTitleText]}
                            >
                              other results
                            </Text>
                          </View>
                        )}
                        <View
                          style={[
                            (!!!searchData?.search?.contextualMatches ||
                              searchData?.search?.contextualMatches.length ===
                                0) && {
                              marginTop: 10,
                            },
                          ]}
                        >
                          {searchData?.search?.otherMatches?.map(
                            (match, index) => (
                              <GlobalSearchResultGeneric
                                key={index}
                                match={match}
                                onPress={() => setVisibility(false)}
                              />
                            ),
                          )}
                        </View>
                      </>
                    )}
                  </ScrollView>
                )}
              </View>
            </View>
          )}
        </View>
      </ModalWrap>
    </>
  );
};
export default GlobalSearch;

const GlobalSearchStyle = StyleSheet.create({
  inputWrap: {
    position: 'relative',
    paddingHorizontal: 15,
    paddingBottom: Platform.OS === 'android' ? 8 : 5,
    alignItems: 'center',
    backgroundColor: '#383D3F',
    flexDirection: 'row',
    shadowColor: black,
    zIndex: 2,
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.04,
    shadowRadius: 10,
    elevation: 5,
    paddingTop: Platform.OS === 'android' ? 20 : 0,
  },
  main: {
    flex: 1,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.04,
    shadowRadius: 10,
    zIndex: 4,
    height: 30,
    backgroundColor: '#323738',
    maxHeight: 30,
    minHeight: 30,
    margin: 10,
    flexDirection: 'row',
    borderRadius: 4,
  },
  mainDesktop: {
    borderRadius: 4,
    position: 'relative',
    flex: 1,
    height: 32,
    backgroundColor: '#383D3F',
    maxHeight: 32,
    minHeight: 32,
    margin: 0,
    marginBottom: 10,
  },
  btnPad: {},
  modalBackground: {
    backgroundColor: 'rgba(43, 47, 49, .98)',
    alignItems: 'center',
    flex: 1,
  },
  modalWrap: {
    width: '100%',
    flex: 1,
  },
  closeButton: {
    paddingRight: 10,
  },
  closeButtonDesktop: {
    position: 'absolute',
    right: -30,
    top: 10,
  },
  input: {
    paddingTop: 3,
    paddingBottom: 3,
    paddingRight: 15,
    fontFamily: 'Quicksand-Bold',
    fontSize: 12,
    flex: 1,
  },
  inputDesktop: {
    paddingLeft: 15,
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 15,
    fontFamily: 'Quicksand-Medium',
    fontSize: 11,
    flex: 1,
    color: '#979D9F',
  },
  searchIconButton: {},
  searchIcon: {
    paddingVertical: 8,
    paddingHorizontal: 10,
  },
  searchIconDesktop: {
    position: 'relative',
    top: 0,
    left: 0,
    maxHeight: 32,
    minHeight: 32,
    width: 36,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(33, 36, 38, 0.25)',
  },
  filterIcon: {
    paddingHorizontal: 10,
    paddingVertical: 3,
  },
  filterIconDesktop: {
    position: 'relative',
    top: 0,
    left: 0,
    maxHeight: 32,
    minHeight: 32,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    width: 36,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(33, 36, 38, 0.25)',
  },
  searchResultsTotal: {
    backgroundColor: '#2C3F45',
    paddingHorizontal: 20,
    paddingVertical: 5,
  },
  searchResultsTotalDesktop: {
    borderRadius: 4,
  },
  searchResultsTotalText: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 10.5,
    color: blue,
  },
  searchResultsTitle: {
    marginTop: 10,
    paddingHorizontal: 20,
    paddingVertical: 5,
    marginBottom: 6,
  },
  searchResultsTitleText: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 13,
    color: '#AAA9A9',
  },
  noResults: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  noResultsText: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 12,
    color: '#AAA9A9',
    marginTop: 15,
  },
});
