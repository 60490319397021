import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { G, Circle, Path, Svg } from 'react-native-svg';
import { blue, white } from '../../configs/colours';

interface Props {
  fill?: string;
  secondaryFill?: string;
  background?: string;
  height?: number;
  width?: number;
  style?: StyleProp<ViewStyle>;
}

const ConversationsIcon: React.FunctionComponent<Props> = ({
  fill = blue,
  secondaryFill = white,
  background = '#212426',
  width = 14.84,
  height = 15.52,
  style,
}) => {
  return (
    <Svg height={height} viewBox="0 0 14.84 15.52" width={width} style={style}>
      <Path
        d="M5,11.69A5.61,5.61,0,0,1,6.22.62H8.45a5.6,5.6,0,0,1,.37,11.2H8.39L5,14.29Z"
        transform="translate(-0.02 0)"
        fill={background}
        stroke={fill}
        strokeWidth="1.25"
      />
      <G>
        <Circle cx="5.03" cy="6.21" r="0.79" fill={secondaryFill} />
        <Circle cx="7.4" cy="6.21" r="0.79" fill={secondaryFill} />
        <Circle cx="9.76" cy="6.21" r="0.79" fill={secondaryFill} />
      </G>
    </Svg>
  );
};

export default ConversationsIcon;
