import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { white } from '../../configs/colours';
import { StyleProp, ViewStyle } from 'react-native';

interface Props {
  fill?: string;
  opacity?: number;
  width?: number;
  height?: number;
  style?: StyleProp<ViewStyle>;
}

const SearchIcon: React.FunctionComponent<Props> = ({
  fill = white,
  width = 17.793,
  height = 17.793,
  opacity = 1,
  style,
}) => {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 17.793 17.793"
      style={style}
    >
      <Path
        d="m17.8 16.4-5.3-5.3c.9-1.2 1.4-2.6 1.4-4.1 0-1.9-.7-3.6-2-4.9s-3-2-4.9-2c-3.9-.1-7 3-7 6.8h1-1c0 1.9.7 3.6 2 4.9s3 2 4.9 2c1.5 0 2.9-.5 4.1-1.4l5.3 5.3zm-10.9-4.5c-1.3 0-2.6-.5-3.5-1.4-.9-1-1.4-2.3-1.4-3.6s.5-2.6 1.4-3.5c1-.9 2.2-1.4 3.5-1.4 1.3 0 2.6.5 3.5 1.4s1.4 2.2 1.4 3.5-.5 2.6-1.4 3.5-2.2 1.5-3.5 1.5z"
        fill={fill}
        opacity={opacity}
      />
    </Svg>
  );
};
export default SearchIcon;
