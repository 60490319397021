import { ApolloClient } from 'apollo-client';
import produce from 'immer';
import { formatISO } from '../../utils/dateFns';
import {
  ListConversationsDocument,
  ListConversationsQuery,
  ListConversationsQueryVariables,
  ListMessagesDocument,
  ListMessagesQuery,
  ListMessagesQueryVariables,
  NewMessagesSubscription,
} from './../../types/apolloTypes';

export function newMessagesUpdateMessageList(
  listQueryData: ListMessagesQuery,
  messageData: NewMessagesSubscription,
) {
  return produce(listQueryData, (draftState) => {
    // Check if messages data and send message data and then push message to message list
    if (draftState?.messages?.items) {
      const messageIndex = draftState.messages.items.findIndex(
        (m) => m.id === messageData.newMessage.id,
      );
      if (messageIndex > -1) {
        draftState.messages[messageIndex] = messageData.newMessage;
      } else {
        draftState.messages.items = [
          messageData.newMessage,
          ...draftState.messages.items,
        ];
      }
    }
  });
}

export function newMessagesUpdateMessages(
  client: ApolloClient<object>,
  data: NewMessagesSubscription,
) {
  // Update message list query with new message
  try {
    const variables = {
      conversationId: data.newMessage.conversationId,
      input: {
        conversationId: data.newMessage.conversationId,
        page: 1,
        pageSize: 50,
      },
    };

    const listQueryData = client.readQuery<
      ListMessagesQuery,
      ListMessagesQueryVariables
    >({
      query: ListMessagesDocument,
      variables,
    });

    if (listQueryData.conversation.pausedUntil) return;

    const nextListQueryState = newMessagesUpdateMessageList(
      listQueryData,
      data,
    );

    client.writeQuery({
      query: ListMessagesDocument,
      data: nextListQueryState,
      variables,
    });
  } catch (error) {
    if (error?.name !== 'Invariant Violation') {
      throw new Error(error);
    }
  }
}

export function newMessageUpdateConversationList(
  conversationQueryData: ListConversationsQuery,
  messageData: NewMessagesSubscription,
  currentUser: { id: string; firstName?: string; lastName?: string },
) {
  const nextConversationQueryState = produce(
    conversationQueryData,
    (draftState) => {
      const senderId = messageData.newMessage.sender.id;
      // Check if messages data and send message data and then push message to message list
      if (draftState && draftState.conversations) {
        draftState.conversations = draftState.conversations.map(
          (conversation) => {
            if (
              conversation &&
              conversation.id === messageData.newMessage.conversationId
            ) {
              if (conversation.pausedUntil) return conversation;
              return {
                ...conversation,
                lastMessageSentAt: formatISO(new Date()),
                lastMessage: {
                  __typename: 'Message',
                  id: messageData.newMessage.id,
                },
                unreadMessageCount:
                  senderId === currentUser.id
                    ? conversation.unreadMessageCount
                    : conversation.unreadMessageCount + 1,
              };
            }
            return conversation;
          },
        );
      }
    },
  );

  return nextConversationQueryState;
}

export function newMessagesUpdateConversations(
  client: ApolloClient<object>,
  data: NewMessagesSubscription,
  currentUser: { id: string; firstName: string; lastName: string } = {
    id: '',
    firstName: '',
    lastName: '',
  },
) {
  const cache = client.cache;
  // Add unread count to conversation list item
  try {
    const conversationQueryData = cache.readQuery<
      ListConversationsQuery,
      ListConversationsQueryVariables
    >({
      query: ListConversationsDocument,
      variables: { memberId: currentUser.id },
    });

    const nextConversationQueryState = newMessageUpdateConversationList(
      conversationQueryData,
      data,
      currentUser,
    );

    client.writeQuery({
      query: ListConversationsDocument,
      data: nextConversationQueryState,
      variables: { memberId: currentUser.id },
    });
  } catch (error) {
    if (error?.name !== 'Invariant Violation') {
      throw new Error(error);
    }
  }
}
