import React, {
  createContext,
  ReactNode,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import Modal, { ModalProps } from './Modal';
import { useImmer } from 'use-immer';
import DateTimePicker, { DatePickerProps } from './DateTimePicker';
import { format } from 'date-fns/esm';
import ErrorBoundary from './ErrorBoundary';

interface Props {
  children: ReactNode;
}

interface TMenuContext {
  activeMenu: string;
  setActiveMenu: Dispatch<SetStateAction<string>>;
  contextTitle: string;
  overlayTitle: string;
  contextComponent: ReactNode | null;
  overlayComponent: ReactNode | null;
  setContextComponent: Dispatch<SetStateAction<ReactNode | null>>;
  setOverlayComponent: Dispatch<SetStateAction<ReactNode | null>>;
  setContextTitle: Dispatch<SetStateAction<string>>;
  setOverlayTitle: Dispatch<SetStateAction<string>>;
  modalProps: ModalProps;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  updateModalProps: (f: (draft: ModalProps) => void | ModalProps) => void;
  datePickerProps: ModalProps & DatePickerProps;
  updateDatePickerProps: (
    f: (
      draft: DatePickerProps & ModalProps,
    ) => void | (DatePickerProps & ModalProps),
  ) => void;
  showDatePicker: boolean;
  setShowDatePicker: Dispatch<SetStateAction<boolean>>;
  displayMenuSlider: boolean;
  setDisplayMenuSlider: Dispatch<SetStateAction<boolean>>;
}

export const SIDE_MENU = 'SIDE_MENU';
export const NO_MENU = 'NO_MENU';
export const CONTEXT_MENU = 'CONTEXT_MENU';

const defaultModalProps: ModalProps = {
  title: 'New Modal',
  content: 'New Modal content',
  cancelText: 'cancel',
  cancelAction: () => null,
  actionText: 'ok',
  action: undefined,
  showModal: false,
  size: 'small',
};

const defaultDatePickerProps: ModalProps & DatePickerProps = {
  ...defaultModalProps,
  title: '',
  showModal: false,
  useTime: false,
  time: '01:00',
  date: format(new Date(), 'yyyy-MM-dd'),
  onSelectDate: () => null,
  onSelectTime: () => null,
  closeOnSelect: true,
  showDatePicker: false,
  setShowDatePicker: () => null,
  onChange: () => null,
  minDate: undefined,
  maxDate: undefined,
};

const defaultMenuContextProps = {
  activeMenu: NO_MENU,
  setActiveMenu: () => null,
  contextTitle: '',
  overlayTitle: 'overlay header',
  contextComponent: null,
  overlayComponent: null,
  setContextComponent: () => null,
  setContextTitle: () => null,
  setOverlayTitle: () => null,
  setOverlayComponent: () => null,
  modalProps: defaultModalProps,
  updateModalProps: () => [defaultModalProps, () => defaultModalProps],
  datePickerProps: defaultDatePickerProps,
  updateDatePickerProps: () => [
    defaultDatePickerProps,
    () => defaultDatePickerProps,
  ],
  showModal: false,
  setShowModal: () => null,
  showDatePicker: false,
  setShowDatePicker: () => null,
  displayMenuSlider: true,
  setDisplayMenuSlider: () => null,
};

export const MenuContext = createContext<TMenuContext>(defaultMenuContextProps);

const MenuContextProvider: React.FunctionComponent<Props> = ({ children }) => {
  const [activeMenu, setActiveMenu] = useState<string>(
    defaultMenuContextProps.activeMenu,
  );
  const [contextTitle, setContextTitle] = useState<string>(
    defaultMenuContextProps.contextTitle,
  );
  const [contextComponent, setContextComponent] = useState<ReactNode | null>(
    null,
  );
  const [overlayTitle, setOverlayTitle] = useState<string>(
    defaultMenuContextProps.overlayTitle,
  );
  const [overlayComponent, setOverlayComponent] = useState<ReactNode | null>(
    null,
  );
  const [modalProps, updateModalProps] = useImmer<ModalProps>(
    defaultModalProps,
  );
  const [showModal, setShowModal] = useState<boolean>(
    defaultMenuContextProps.showModal,
  );
  const [showDatePicker, setShowDatePicker] = useState<boolean>(
    defaultDatePickerProps.showModal,
  );

  const [displayMenuSlider, setDisplayMenuSlider] = useState<boolean>(
    defaultMenuContextProps.displayMenuSlider,
  );
  const [datePickerProps, updateDatePickerProps] = useImmer<
    DatePickerProps & ModalProps
  >(defaultDatePickerProps);

  return (
    <MenuContext.Provider
      value={{
        activeMenu,
        setActiveMenu,
        contextTitle,
        setContextTitle,
        contextComponent,
        setContextComponent,
        overlayTitle,
        setOverlayTitle,
        overlayComponent,
        setOverlayComponent,
        showModal,
        setShowModal,
        modalProps,
        updateModalProps,
        datePickerProps,
        updateDatePickerProps,
        showDatePicker,
        setShowDatePicker,
        displayMenuSlider,
        setDisplayMenuSlider,
      }}
    >
      {children}
      <ErrorBoundary>
        <Modal
          {...modalProps}
          setShowModal={setShowModal}
          showModal={showModal}
        />
      </ErrorBoundary>
      <Modal
        {...datePickerProps}
        content={() => (
          <DateTimePicker
            {...datePickerProps}
            setShowDatePicker={setShowDatePicker}
            showDatePicker={showDatePicker}
          />
        )}
        setShowModal={setShowDatePicker}
        showModal={showDatePicker}
      />
    </MenuContext.Provider>
  );
};
export default MenuContextProvider;
