import React, { useState, useRef, useEffect, useCallback } from 'react';
import { StyleSheet, View, Animated, Platform, Text } from 'react-native';
import { useAnimation } from '../hooks/useAnimation';
import PlatformTouchable from './PlatformTouchable';
import CloseIcon from './icons/CloseIcon';
import { black, blue } from '../configs/colours';
import TickIcon from './icons/TickIcon';
import BellIcon from './icons/BellIcon';

interface Props {
  removeAlert: (id: string) => void;
  hash: number;
}

export enum AlertTypes {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  INFO = 'INFO',
  ERROR = 'ERROR',
  LOADING = 'LOADING',
}

export interface AlertProps {
  id?: string;
  text: string;
  type: AlertTypes;
  onClose?: () => void;
  onPress?: () => void;
  duration?: number;
  cancellable?: boolean;
}

const Alert: React.FunctionComponent<Props & AlertProps> = ({
  id,
  removeAlert,
  duration = 4000,
  cancellable = true,
  text,
  type,
  hash,
  onClose,
  onPress,
}) => {
  const [visible, setVisible] = useState<boolean>(true);

  const timeoutRef = useRef<NodeJS.Timeout>();
  const timeoutTime = useRef<number>();

  // Toggle spring animation from size menu
  const value = useAnimation({
    type: 'spring',
    initialValue: visible ? 0 : 1,
    useNativeDriver: Platform.OS !== 'web',
    toValue: visible ? 1 : 0,
  });

  const closeAlert = useCallback(async () => {
    await setVisible(false);
    if (onClose) onClose();
    setTimeout(() => {
      removeAlert(id);
    }, 300);
  }, [hash]);

  useEffect(() => {
    if (duration > 0) {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(
        () => {
          closeAlert();
        },
        timeoutTime.current
          ? duration - (Date.now() - timeoutTime.current)
          : duration,
      );
      timeoutTime.current = Date.now();
    }
    return () => !!timeoutRef.current && clearTimeout(timeoutRef.current);
  }, [hash]);

  return (
    <PlatformTouchable onPress={onPress}>
      <Animated.View
        style={[
          AlertStyle.main,
          {
            opacity: value,
            transform: [
              {
                translateY: value.interpolate({
                  inputRange: [0, 1],
                  outputRange: visible ? [-20, 0] : [0, 20],
                }) as any,
              },
            ],
          },
        ]}
      >
        {type === AlertTypes.SUCCESS && (
          <View style={[AlertStyle.iconWrap]}>
            <TickIcon />
          </View>
        )}
        {type === AlertTypes.INFO && (
          <View style={[AlertStyle.iconWrap]}>
            <BellIcon width={12} height={13} fill={blue} />
          </View>
        )}
        <Text style={[AlertStyle.text]}>{text}</Text>
        {cancellable && (
          <PlatformTouchable
            style={[AlertStyle.closeButton]}
            onPress={closeAlert}
          >
            <CloseIcon width={7.5} height={7.5} fill="#728184" />
          </PlatformTouchable>
        )}
      </Animated.View>
    </PlatformTouchable>
  );
};

export default Alert;

const AlertStyle = StyleSheet.create({
  main: {
    backgroundColor: '#2B363A',
    marginBottom: 10,
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: 4,
    borderColor: '#234955',
    borderWidth: 1,
    shadowColor: black,
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.5,
    shadowRadius: 15,
    elevation: 5,
    paddingHorizontal: 15,
    paddingVertical: 7.5,
  },
  text: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 12,
    color: '#E3E3E3',
    flex: 1,
    flexWrap: 'wrap',
  },
  closeButton: {
    marginLeft: 10,
  },
  iconWrap: {
    marginRight: 10,
  },
});
