import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { Path, Svg } from 'react-native-svg';
import { white } from '../../configs/colours';

interface Props {
  fill?: string;
  height?: number;
  width?: number;
  style?: StyleProp<ViewStyle>;
}

const SpeechBubbleIcon: React.FunctionComponent<Props> = ({
  fill = white,
  width = 12.65,
  height = 13.6,
  style,
}) => {
  return (
    <Svg height={height} viewBox="0 0 12.65 13.6" width={width} style={style}>
      <Path
        d="M4.34,9.54A4.49,4.49,0,0,1,5.12.62H7.53a4.49,4.49,0,0,1,.58,9L4.34,12.36Z"
        transform="translate(0 0)"
        fill="none"
        stroke={fill}
        strokeWidth="1.25"
      />
    </Svg>
  );
};

export default SpeechBubbleIcon;
