import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { StyleProp, ViewStyle } from 'react-native';

interface Props {
  fill?: string;
  width?: number;
  height?: number;
  style?: StyleProp<ViewStyle>;
}

const TickIcon: React.FunctionComponent<Props> = ({
  fill = '#00c977',
  width = 11.33,
  height = 9,
  style,
}) => {
  return (
    <Svg height={height} viewBox="0 0 11.33 9" width={width} style={style}>
      <Path
        d="M1.41,6.08,3.75,8.41l7-7"
        transform="translate(-0.41 -0.41)"
        fill="none"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </Svg>
  );
};
export default TickIcon;
