import * as React from 'react';
import { Svg, Path } from 'react-native-svg';

interface Props {
  fill?: string;
  secondaryFill?: string;
  opacity?: number;
  width?: number;
  height?: number;
}

const DragHandleIcon: React.FunctionComponent<Props> = ({
  fill = '#cbcbcb',
  opacity = 1,
  width = 6.01,
  height = 9.23,
}) => {
  return (
    <Svg
      height={height}
      width={width}
      viewBox="0 0 6.01 9.23"
      opacity={opacity}
    >
      <Path
        d="M1.11,0a1.12,1.12,0,1,0,0,2.23l.22,0a1.14,1.14,0,0,0,.89-1.1A1.12,1.12,0,0,0,1.11,0Z"
        transform="translate(0 -0.01)"
        fill={fill}
      />
      <Path
        d="M1.11,3.5a1.12,1.12,0,0,0,0,2.24.83.83,0,0,0,.22,0A1.12,1.12,0,0,0,1.11,3.5Z"
        transform="translate(0 -0.01)"
        fill={fill}
      />
      <Path
        d="M1.11,7a1.12,1.12,0,1,0,0,2.23l.22,0a1.14,1.14,0,0,0,.89-1.1A1.12,1.12,0,0,0,1.11,7Z"
        transform="translate(0 -0.01)"
        fill={fill}
      />
      <Path
        d="M4.9,2.24l.22,0A1.13,1.13,0,0,0,6,1.12a1.12,1.12,0,0,0-2.23,0A1.11,1.11,0,0,0,4.9,2.24Z"
        transform="translate(0 -0.01)"
        fill={fill}
      />
      <Path
        d="M4.89,3.5a1.12,1.12,0,0,0,0,2.24.78.78,0,0,0,.22,0A1.12,1.12,0,0,0,4.89,3.5Z"
        transform="translate(0 -0.01)"
        fill={fill}
      />
      <Path
        d="M4.89,7a1.12,1.12,0,1,0,0,2.23l.22,0A1.13,1.13,0,0,0,6,8.11,1.1,1.1,0,0,0,4.89,7Z"
        transform="translate(0 -0.01)"
        fill={fill}
      />
    </Svg>
  );
};
export default DragHandleIcon;
