import { DataProxy } from 'apollo-cache';
import produce from 'immer';
import {
  AssociateNoteMutation,
  AssociateNoteMutationVariables,
  ListClientAccountNotesDocument,
  ListClientAccountNotesQuery,
  ListClientAccountNotesQueryVariables,
} from './../../types/apolloTypes';

// Update Cache Function to update after pause mutation
export default function associateNoteUpdate(
  cache: DataProxy,
  data: AssociateNoteMutation,
  variables: AssociateNoteMutationVariables,
) {
  // Try and update question query
  try {
    const listClientNotesQueryData = cache.readQuery<
      ListClientAccountNotesQuery,
      ListClientAccountNotesQueryVariables
    >({
      query: ListClientAccountNotesDocument,
      variables: {
        clientAccountId: variables.input.clientAccountId,
        page: 1,
        pageSize: 50,
      },
    });

    const nextListClientNotesState = produce(
      listClientNotesQueryData,
      (draftState) => {
        if (draftState?.clientAccountNotes?.items && data?.associateNote) {
          draftState.clientAccountNotes.items = [
            ...draftState.clientAccountNotes.items,
            data.associateNote,
          ];
        }
      },
    );

    cache.writeQuery({
      data: nextListClientNotesState,
      query: ListClientAccountNotesDocument,
      variables: {
        clientAccountId: variables.input.clientAccountId,
        page: 1,
        pageSize: 50,
      },
    });
  } catch (error) {
    if (error?.name !== 'Invariant Violation') {
      throw new Error(error);
    }
  }
}
