import React, { ReactNode, useContext } from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableHighlight,
  SafeAreaView,
} from 'react-native';
import { white, black, blue } from '../configs/colours';
import { ThemeContext } from './ThemeProvider';
import { MenuContext } from './MenuContextProvider';

interface Props {
  title: string;
  component: ReactNode | null;
}

const ContextOverlay: React.FunctionComponent<Props> = ({
  title = 'Context Menu',
  component = null,
}) => {
  const { themeColours } = useContext(ThemeContext);
  const { setOverlayTitle, setOverlayComponent } = React.useContext(
    MenuContext,
  );
  return (
    <View
      style={[
        ContextOverlayStyles.wrapper,
        { backgroundColor: themeColours.mainBackground },
      ]}
    >
      <SafeAreaView style={[{ backgroundColor: themeColours.header }]}>
        <View
          style={[
            ContextOverlayStyles.header,
            { backgroundColor: themeColours.header },
          ]}
        >
          <Text style={[ContextOverlayStyles.headerText]}>{` `}</Text>
          <Text
            style={[
              ContextOverlayStyles.headerText,
              { color: themeColours.primaryFont },
            ]}
          >
            {title}
          </Text>
          <TouchableHighlight style={[ContextOverlayStyles.doneButton]}>
            <Text
              style={[
                ContextOverlayStyles.headerText,
                ContextOverlayStyles.doneButtonText,
              ]}
              onPress={() => {
                setOverlayTitle('');
                setOverlayComponent(null);
              }}
            >
              done
            </Text>
          </TouchableHighlight>
        </View>
      </SafeAreaView>
      <View style={[ContextOverlayStyles.content]}>{component}</View>
    </View>
  );
};
export default ContextOverlay;

const ContextOverlayStyles = StyleSheet.create({
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  header: {
    position: 'relative',
    paddingHorizontal: 15,
    paddingBottom: 15,
    // height: 72,
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    alignContent: 'center',
    backgroundColor: white,
    flexDirection: 'row',
    shadowColor: black,
    zIndex: 2,
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.04,
    shadowRadius: 10,
    elevation: 5,
  },
  headerText: {
    flex: 1,
    fontSize: 14,
    fontFamily: 'Quicksand-Medium',
    textAlign: 'center',
  },
  doneButton: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexDirection: 'row',
  },
  doneButtonText: {
    textAlign: 'right',
    color: blue,
  },
  content: {
    flex: 1,
  },
});
