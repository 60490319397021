import React, { Component, ReactNode } from 'react';
import { isHoverEnabled } from '../utils/HoverState';

interface Props {
  onHoverIn?: () => void;
  onHoverOut?: () => void;
  children: ReactNode;
}

interface State {
  isHovered: boolean;
  showHover: boolean;
}

export default class Hoverable extends Component<Props, State> {
  public state = { isHovered: false, showHover: true };

  public render() {
    const { children } = this.props;
    const child =
      typeof children === 'function'
        ? children(this.state.showHover && this.state.isHovered)
        : children;

    return React.cloneElement(React.Children.only(child), {
      onMouseEnter: this.handleMouseEnter,
      onMouseLeave: this.handleMouseLeave,
      // prevent hover showing while responder
      onResponderGrant: this.handleGrant,
      onResponderRelease: this.handleRelease,
      // if child is Touchable
      onPressIn: this.handleGrant,
      onPressOut: this.handleRelease,
    });
  }

  private handleMouseEnter = () => {
    if (isHoverEnabled() && !this.state.isHovered) {
      const { onHoverIn } = this.props;
      if (onHoverIn) onHoverIn();
      this.setState(state => ({ ...state, isHovered: true }));
    }
  };

  private handleMouseLeave = () => {
    if (this.state.isHovered) {
      const { onHoverOut } = this.props;
      if (onHoverOut) onHoverOut();
      this.setState(state => ({ ...state, isHovered: false }));
    }
  };

  private handleGrant = () => {
    this.setState(state => ({ ...state, showHover: false }));
  };

  private handleRelease = () => {
    this.setState(state => ({ ...state, showHover: true }));
  };
}
