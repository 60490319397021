import { formatISO } from 'date-fns';
import { formatDistanceToNowStrict, parseISO } from 'date-fns/esm';
import React, { useRef, useContext } from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import { blue } from '../configs/colours';
import markNotificationReadUpdate from '../mutations/updates/MarkNotificationRead';
import {
  MarkNotificationReadMutationVariables,
  useMarkNotificationReadMutation,
  PathSegment,
} from '../types/apolloTypes';
import { NotificationsNotification } from '../types/Notifications';
import { useHistory } from '../utils/routing';
import ConversationsIcon from './icons/ConversationsIcon';
import PlatformTouchable from './PlatformTouchable';
import { NotificationContext } from './NotificationContext';
import isElectron from 'is-electron';
import MattersIcon from './icons/MattersIcon';
import FirmAdministrationIcon from './icons/FirmAdministrationIcon';
import useIsNonPro from '../hooks/usIsNonPro';
import ContactsIcon from './icons/ContactsIcon';
import useUpdateBreadcrumbs from '../hooks/useUpdateBreadcrumbs';

interface Props {
  visible: boolean;
  currentUserId: string;
}

type PathSegmentType = {
  __typename?: 'PathSegment';
} & Pick<PathSegment, 'id' | 'type' | 'name'>;

export function getLink(
  type: string,
  path: PathSegmentType[],
  isNonPro?: boolean,
): string {
  const clientAccount = path?.find((p) => p.type === 'client-account');
  // const client = path?.find(p => p.type === 'client');
  // const note = path?.find(p => p.type === 'note');
  const section = path?.find((p) => p.type === 'task-section');
  const matter = path?.find((p) => p.type === 'matter');
  // const firm = path?.find(p => p.type === 'firm');
  // const professional = path?.find(p => p.type === 'professional');
  // const firmMember = path?.find(p => p.type === 'firm-member');
  const question = path?.find((p) => p.type === 'question');
  const task = path?.find((p) => p.type === 'task');
  const conversation = path?.find((p) => p.type === 'conversation');
  const message = path?.find((p) => p.type === 'message');

  if (type === 'messageReceived') {
    return `/conversations/${conversation?.id}?message=${message?.id}`;
  }
  if (type === 'conversationResumed') {
    return `/conversations/${conversation?.id}`;
  }
  if (type === 'supportingMaterialExportCompleted') {
    return path.reduce((prev, curr, index) => {
      if (index === 0) return '/file-manager';
      if (curr.type === 'file') return `${prev}?file=${curr.id}`;
      return `${prev}/${curr.id}`;
    }, '');
  }

  if (type === 'firmMemberAdded' || type === 'seatAllocationChanged') {
    return '/firm-administration/members';
  }
  if (type === 'clientAccountApprovalRequired') {
    return `/firm-administration/clients/${clientAccount?.id}/profile/access-security`;
  }
  if (
    type === 'noteAddedOnClientAccount' ||
    type === 'noteRevisedOnClientAccount'
  ) {
    return `/clients/${clientAccount?.id}/notes`;
  }
  if (type === 'clientAccountSetup' || type === 'firmMemberAssignedToClient') {
    return '/clients';
  }
  if (type === 'clientProfileUpdated' || type === 'clientProfileCompleted') {
    if (isNonPro) {
      return '/profile-settings';
    }
    return `/clients/${clientAccount?.id}/profile`;
  }
  if (type === 'clientInvitationAccepted') {
    return `/clients/${clientAccount?.id}`;
  }
  if (type === 'clientProfileVerified') {
    if (isNonPro) {
      return '/profile-settings';
    }
    return `/clients/${clientAccount?.id}/profile/access-security`;
  }
  if (type === 'clientAccountApproved') {
    if (isNonPro) {
      return '/profile-settings/issue-management';
    }
    return `/clients/${clientAccount?.id}`;
  }
  if (
    type === 'clientLinkedToOpponent' ||
    type === 'clientIssueDetailsUpdated'
  ) {
    if (isNonPro) {
      return '/profile-settings/issue-management';
    }
    return `/clients/${clientAccount?.id}/profile/issue-management`;
  }
  if (type === 'newContact') {
    return '/contacts';
  }
  if (type === 'answerRequiresAttention' || type === 'questionReleased') {
    if (isNonPro) {
      return `/matters/${matter?.id}/tasks/${task?.id}/sections/${section?.id}/questions/${question?.id}`;
    }
    return `/clients/${clientAccount?.id}/matters/${matter?.id}/tasks/${task?.id}/sections/${section?.id}/questions/${question?.id}`;
  }
  if (type === 'firmMemberAssignedToClient') {
    if (isNonPro) {
      return `/legal-team`;
    }
  }
  if (type === 'taskSectionReleased') {
    if (isNonPro) {
      return `/matters/${matter?.id}/tasks/${task?.id}/sections/${section?.id}`;
    }
    return `/clients/${clientAccount?.id}/matters/${matter?.id}/tasks/${task?.id}/sections/${section?.id}`;
  }
  if (type === 'keyDateChanged' || type === 'keyDateAdded') {
    if (isNonPro) {
      return `/matters/${matter?.id}`;
    }
    return `/clients/${clientAccount?.id}/matters/${matter?.id}`;
  }
  if (type === 'taskReleased') {
    if (isNonPro) {
      return `/matters/${matter?.id}/tasks/${matter?.id}/sections`;
    }
    return `/clients/${clientAccount?.id}/matters/${matter?.id}/tasks/${task?.id}/sections`;
  }

  if (type === 'libraryTaskAdded' || type === 'libraryTaskEdited') {
    return '/task-library';
  }

  return '/';
}

export function getLinkName(
  type: string,
  path: PathSegmentType[],
  isNonPro?: boolean,
): string {
  const clientAccount = path?.find((p) => p.type === 'client-account');
  // const client = path?.find(p => p.type === 'client');
  // const note = path?.find(p => p.type === 'note');
  // const section = path?.find((p) => p.type === 'task-section');
  const matter = path?.find((p) => p.type === 'matter');
  // const firm = path?.find(p => p.type === 'firm');
  // const professional = path?.find(p => p.type === 'professional');
  // const firmMember = path?.find(p => p.type === 'firm-member');
  // const question = path?.find((p) => p.type === 'question');
  const task = path?.find((p) => p.type === 'task');
  const conversation = path?.find((p) => p.type === 'conversation');
  // const message = path?.find((p) => p.type === 'message');

  if (type === 'messageReceived' || type === 'conversationResumed') {
    return conversation?.name?.toLowerCase();
  }
  if (type === 'supportingMaterialExportCompleted') {
    return 'file manager';
  }
  if (type === 'firmMemberAdded' || type === 'seatAllocationChanged') {
    return 'member list';
  }
  if (
    type === 'noteAddedOnClientAccount' ||
    type === 'noteRevisedOnClientAccount' ||
    type === 'clientInvitationAccepted'
  ) {
    return clientAccount?.name?.toLowerCase();
  }
  if (type === 'clientAccountSetup' || type === 'firmMemberAssignedToClient') {
    return 'client list';
  }
  if (
    type === 'clientProfileUpdated' ||
    type === 'clientProfileCompleted' ||
    type === 'clientProfileVerified' ||
    type === 'clientAccountApproved' ||
    type === 'clientLinkedToOpponent' ||
    type === 'clientIssueDetailsUpdated' ||
    type === 'clientAccountApprovalRequired'
  ) {
    if (isNonPro) {
      return 'settings';
    }
    return 'client account';
  }
  if (type === 'newContact') {
    return 'contacts';
  }
  if (
    type === 'answerRequiresAttention' ||
    type === 'questionReleased' ||
    type === 'taskSectionReleased' ||
    type === 'taskReleased'
  ) {
    return `${task?.name?.toLowerCase()}${isNonPro ? '' : ' - edit'}`;
  }
  if (type === 'firmMemberAssignedToClient') {
    if (isNonPro) {
      return `your legal team`;
    }
  }
  if (type === 'keyDateChanged' || type === 'keyDateAdded') {
    return matter?.name?.toLowerCase();
  }
  if (type === 'libraryTaskAdded' || type === 'libraryTaskEdited') {
    return 'task library';
  }

  return 'dashboard';
}

const NotificationListItem: React.FunctionComponent<
  Props & NotificationsNotification
> = ({
  title,
  body,
  isRead,
  occurredOn,
  context,
  path,
  id,
  type,
  pictureUrl,
}) => {
  const when = formatDistanceToNowStrict(parseISO(occurredOn))?.split(' ');

  const history = useHistory();

  const variables: MarkNotificationReadMutationVariables = {
    input: {
      notificationId: id,
    },
  };

  const { setNotificationsVisible, setTotalUnread, totalUnread } = useContext(
    NotificationContext,
  );

  const [markRead] = useMarkNotificationReadMutation({
    variables,
    optimisticResponse: {
      markNotificationRead: {
        __typename: 'Notification',
        body,
        id,
        isRead: true,
        readAt: formatISO(new Date()),
        title,
        type,
      },
    },
    update: (cache, { data }) =>
      markNotificationReadUpdate(cache, data, variables),
    onCompleted: () => {
      const newTotal = totalUnread - 1;
      setTotalUnread(newTotal);
      if (isElectron()) {
        const { app } = window.require('electron').remote;
        app?.dock?.setBadge(`${newTotal > 0 ? newTotal : ''}`);
      }
    },
  });

  const isNonPro = useIsNonPro();

  const { updateAllBreadcrumbs } = useUpdateBreadcrumbs();

  return (
    <PlatformTouchable
      style={[
        NotificationListItemStyle.main,
        !isRead && NotificationListItemStyle.unread,
      ]}
      onPress={() => {
        if (!isRead) {
          markRead();
        }
        const link = getLink(type, path, isNonPro);

        history.push(link);
        updateAllBreadcrumbs([
          {
            to: link,
            name: getLinkName(type, path, isNonPro),
          },
        ]);
        setNotificationsVisible(false);
      }}
    >
      <>
        {!!pictureUrl && (
          <View style={[NotificationListItemStyle.imageWrap]}>
            <Image
              source={{
                uri: pictureUrl,
              }}
              style={[NotificationListItemStyle.image]}
            />
            {!isRead && (
              <View style={[NotificationListItemStyle.unreadCircle]} />
            )}
          </View>
        )}
        <View style={[NotificationListItemStyle.textWrap]}>
          <Text numberOfLines={1} style={[NotificationListItemStyle.title]}>
            {title?.toLowerCase()}
          </Text>
          <Text numberOfLines={1} style={[NotificationListItemStyle.body]}>
            {body?.toLowerCase()}
          </Text>
        </View>
        <View style={[NotificationListItemStyle.detailsWrap]}>
          {context === 'contacts' && <ContactsIcon />}
          {context === 'messaging' && <ConversationsIcon />}
          {context === 'firm-admin' && <FirmAdministrationIcon />}
          {context === 'matters' && <MattersIcon height={17} width={18} />}
          <Text style={[NotificationListItemStyle.date]}>
            {`${when?.[0]}${when?.[1].substr(0, 1)}`}
          </Text>
        </View>
      </>
    </PlatformTouchable>
  );
};

export default NotificationListItem;

const NotificationListItemStyle = StyleSheet.create({
  main: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomColor: '#444A4D',
    borderBottomWidth: 1,
    backgroundColor: '#383D3F',
    paddingVertical: 15,
    paddingHorizontal: 20,
  },
  unread: {
    backgroundColor: '#2B363A',
  },
  imageWrap: {
    position: 'relative',
  },
  image: {
    width: 20,
    height: 20,
    borderRadius: 3,
    marginRight: 15,
  },
  unreadCircle: {
    position: 'absolute',
    backgroundColor: blue,
    top: -4,
    right: 11,
    width: 8,
    height: 8,
    borderRadius: 4,
  },
  textWrap: {
    flex: 1,
  },
  title: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 13,
    color: '#E3E3E3',
  },
  body: {
    color: '#AAA9A9',
    fontFamily: 'Quicksand-Medium',
    fontSize: 11,
    marginTop: 3,
  },
  detailsWrap: {
    marginLeft: 15,
    alignItems: 'flex-end',
  },
  date: {
    color: '#728184',
    fontFamily: 'Quicksand-Medium',
    fontSize: 11,
    marginTop: 3,
  },
});
