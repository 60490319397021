import * as React from 'react';
import { Svg, Path, Rect, G } from 'react-native-svg';
import { ThemeContext } from '../ThemeProvider';
import { blue } from '../../configs/colours';

interface Props {
  fill?: string;
  secondaryFill?: string;
  width?: number;
  height?: number;
}

const DateIcon: React.FunctionComponent<Props> = ({
  fill = blue,
  secondaryFill = '#e5f3f8',
  width = 17.19,
  height = 17.02,
}) => {
  return (
    <Svg height={height} viewBox="0 0 17.19 17.02" width={width}>
      <Path d="M0,4H17V14a3,3,0,0,1-3,3H3a3,3,0,0,1-3-3V4Z" fill="none" />
      <Rect
        x="0.63"
        y="0.63"
        width="15.75"
        height="15.77"
        rx="1.38"
        fill="none"
        stroke={fill}
        strokeWidth="1.25"
      />
      <Path d="M.06,4.08H17.19" fill="none" stroke={fill} />
      <Path
        d="M7.68,9.17a1.71,1.71,0,0,1,.59.71A2.25,2.25,0,0,1,8.18,12a2,2,0,0,1-.83.76A3,3,0,0,1,6.08,13a2.8,2.8,0,0,1-.64-.08,2.51,2.51,0,0,1-.57-.22.54.54,0,0,1-.33-.49.6.6,0,0,1,.15-.39.47.47,0,0,1,.37-.18.72.72,0,0,1,.39.13,1.34,1.34,0,0,0,.85.3,1.1,1.1,0,0,0,.53-.14,1.05,1.05,0,0,0,.41-.4,1.13,1.13,0,0,0,.16-.61,1,1,0,0,0-.29-.76,1,1,0,0,0-.71-.27,1.08,1.08,0,0,0-.32,0l-.16,0a1.23,1.23,0,0,1-.38.07.39.39,0,0,1-.32-.13.53.53,0,0,1-.12-.35.59.59,0,0,1,.05-.23,1.05,1.05,0,0,1,.15-.21L6.7,7.57H4.92a.51.51,0,0,1-.51-.51.43.43,0,0,1,.15-.34.45.45,0,0,1,.36-.14H7.67a.53.53,0,0,1,.41.15.51.51,0,0,1,.15.39A.6.6,0,0,1,8,7.55L6.81,8.88A1.54,1.54,0,0,1,7.68,9.17Z"
        fill={secondaryFill}
      />
      <Path
        d="M11.29,6.73a.57.57,0,0,1,.15.4v5.2a.54.54,0,0,1-.17.39.65.65,0,0,1-.42.16.61.61,0,0,1-.42-.16.53.53,0,0,1-.16-.39V8.06l-.56.34a.5.5,0,0,1-.29.09A.49.49,0,0,1,9,8.31a.56.56,0,0,1-.16-.37A.5.5,0,0,1,9,7.67a.55.55,0,0,1,.2-.2l1.36-.81a.75.75,0,0,1,.39-.08A.54.54,0,0,1,11.29,6.73Z"
        fill={secondaryFill}
      />
    </Svg>
  );
};
export default DateIcon;
