import { Resolvers } from 'apollo-client';

const resolvers: Resolvers = {
  Mutation: {
    setCurrentUser: (__, { input: user }, { cache }) => {
      const data = {
        getCurrentUser: {
          ...user,
        },
      };
      cache.writeData({ data });
    },
  },
};

const defaults = {
  getCurrentUser: {
    __typename: 'User',
  },
};

export default { resolvers, defaults };
