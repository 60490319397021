import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { Path, Svg } from 'react-native-svg';
import { blue } from '../../configs/colours';

interface Props {
  fill?: string;
  secondaryFill?: string;
  height?: number;
  width?: number;
  style?: StyleProp<ViewStyle>;
}

const BellIcon: React.FunctionComponent<Props> = ({
  fill = '#f0fbff',
  secondaryFill = '#e7f3f8',
  width = 16,
  height = 17,
  style,
}) => {
  return (
    <Svg height={height} viewBox="0 0 16 17" width={width} style={style}>
      <Path
        d="M0,14a4.75,4.75,0,0,1,1.6-3.57V5.9A5.9,5.9,0,0,1,7.5,0h1a5.9,5.9,0,0,1,5.9,5.9v4.52A4.79,4.79,0,0,1,16,14Z"
        transform="translate(0)"
        fill="none"
      />
      <Path
        d="M14.14,12.5a3.25,3.25,0,0,0-.74-.95l-.5-.45V5.9A4.41,4.41,0,0,0,8.5,1.5h-1A4.41,4.41,0,0,0,3.1,5.9v5.2l-.5.45a3.25,3.25,0,0,0-.74.95H14.14M16,14H0a4.77,4.77,0,0,1,1.6-3.57V5.9A5.9,5.9,0,0,1,7.5,0h1a5.9,5.9,0,0,1,5.9,5.9v4.53A4.77,4.77,0,0,1,16,14Z"
        transform="translate(0)"
        fill={fill}
      />
      <Path
        d="M6.59,15.5H9.41a1.49,1.49,0,0,1-2.82,0Z"
        transform="translate(0)"
        fill={secondaryFill}
      />
      <Path d="M6,15h4a2,2,0,0,1-4,0Z" transform="translate(0)" fill={fill} />
    </Svg>
  );
};

export default BellIcon;
