import queryString from 'query-string';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import ClientConversationListItem, {
  MessageColours,
} from '../components/ClientConversationListItem';
import ChevronLeftIcon from '../components/icons/ChevronLeftIcon';
import LoadingView from '../components/LoadingView';
import { MenuContext } from '../components/MenuContextProvider';
import ModalInline from '../components/ModalInline';
import PageWrap from '../components/PageWrap';
import PersonHeader from '../components/PersonHeader';
import PlatformTouchable from '../components/PlatformTouchable';
import { blue, white } from '../configs/colours';
import { useGetFirmMembership } from '../hooks/useGetFirm';
import useUpdateBreadcrumbs from '../hooks/useUpdateBreadcrumbs';
import useIsNonPro from '../hooks/usIsNonPro';
import {
  useGetClientAccountQuery,
  useListClientConversationsQuery,
} from '../types/apolloTypes';
import { ConversationsConversation } from '../types/Conversations';
import { useHistory, useLocation, useParams } from '../utils/routing';

interface Props {
  isInsitu?: boolean;
  updateProps?: (f: (draft: any) => void | any) => void;
  clientAccountId?: string;
}

const ClientConversations: React.FunctionComponent<Props> = ({
  isInsitu = false,
  updateProps,
  clientAccountId,
}) => {
  const { clientId = clientAccountId } = useParams();
  const history = useHistory();
  const location = useLocation();

  const { pushBreadcrumb } = useUpdateBreadcrumbs();

  const { setContextTitle, setContextComponent } = useContext(MenuContext);

  useEffect(() => {
    setContextTitle('');
    setContextComponent(null);
  }, []);

  const { data: clientAccountData } = useGetClientAccountQuery({
    variables: {
      clientAccountId: clientId,
    },
    skip: !clientId,
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: clientAccountConversations,
    loading: loadingConversations,
  } = useListClientConversationsQuery({
    variables: {
      clientAccountId: clientId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !clientId,
  });

  const firmMembership = useGetFirmMembership();

  const inConversations =
    location?.pathname?.split('/')?.[1] === 'conversations';

  const includeFirmAdmin =
    location.pathname.indexOf('firm') > -1
      ? '/firm-administration'
      : inConversations
      ? '/conversations'
      : '';

  const isNonPro = useIsNonPro();

  const backLocation =
    (queryString.parse(location.search)?.back as string) || '';
  const backText =
    queryString.parse(location.search)?.backText || 'previous location';
  function backToPrevLocation() {
    history.push(backLocation);
  }

  const onSelectConversation = useCallback(
    (id: string, type: string, clientAccountId?: string) => {
      if (isInsitu) {
        updateProps((draft) => {
          draft.conversationId = id;
          draft.showConversation = true;
          draft.clientAccountId = clientAccountId ?? '';
        });
      } else {
        if (!!backLocation) {
          if (clientAccountId && !isNonPro) {
            history.push(
              `${includeFirmAdmin}/clients/${clientAccountId}/conversations`,
            );
          } else {
            history.push(
              `${includeFirmAdmin}/clients/${clientAccountId}/conversations?back=${backLocation}&backText=${backText}`,
            );
          }
        } else {
          if (type === 'client' && clientAccountId && !isNonPro) {
            history.push(
              `${includeFirmAdmin}/clients/${clientAccountId}/conversations`,
            );
          } else {
            history.push(
              `${includeFirmAdmin}/clients/${clientAccountId}/conversations/${id}`,
            );
          }
        }
      }
    },
    [],
  );

  const defaultConvo: ConversationsConversation = {
    id: '',
    pictureUrl: '',
    name: '',
    participants: [],
    groups: [],
    startedAt: '',
    unreadMessageCount: 0,
    lastMessageReadAt: null,
    lastMessageSentAt: null,
    lastMessageDeliveredAt: null,
    pausedUntil: null,
    lastMessage: {
      id: '',
      __typename: 'Message',
    },
    metadata: [],
    __typename: 'Conversation',
  };

  const clientName = `${clientAccountData?.clientAccount?.client?.preferredName} ${clientAccountData?.clientAccount?.client?.lastName}`;

  const clientConversation: ConversationsConversation = clientAccountConversations
    ?.clientAccount?.clientConversation ?? {
    ...defaultConvo,
    name: `${clientName}`,
  };

  const legalTeamConversation = clientAccountConversations?.clientAccount
    ?.firmConversation ?? {
    ...defaultConvo,
    name: firmMembership?.firm?.name,
  };

  const otherPartyConversation = clientAccountConversations?.clientAccount
    ?.client?.issues?.[0]?.opponentConversation ?? {
    ...defaultConvo,
    name: `Other Party`,
  };

  const otherPartyLegalTeamConversation = clientAccountConversations
    ?.clientAccount?.client?.issues?.[0]?.disclosureConversation ?? {
    ...defaultConvo,
    name: `Other Party Firm`,
  };

  const setInsituProps = (id: string) => {
    updateProps((draft) => {
      draft.clientAccountId = clientId;
      draft.conversationId = id;
      draft.showConversation = true;
    });
  };

  const onSelectClientConversation = () => {
    if (isInsitu) {
      setInsituProps(clientConversation?.id);
    } else {
      const path = `${includeFirmAdmin}/clients/${clientId}/conversations/${clientConversation?.id}`;
      history.push(path);
      pushBreadcrumb({
        name: clientConversation?.name?.toLowerCase(),
        to: path,
      });
    }
  };

  const onSelectLegalTeamConversation = () => {
    if (isInsitu) {
      setInsituProps(legalTeamConversation?.id);
    } else {
      const path = `${includeFirmAdmin}/clients/${clientId}/conversations/${legalTeamConversation?.id}`;
      history.push(path);
      pushBreadcrumb({
        name: legalTeamConversation?.name?.toLowerCase(),
        to: path,
      });
    }
  };

  const onSelectOtherPartyConversation = () => {
    if (isInsitu) {
      setInsituProps(otherPartyConversation?.id);
    } else {
      const path = `${includeFirmAdmin}/clients/${clientId}/conversations/${otherPartyConversation?.id}`;
      history.push(path);
      pushBreadcrumb({
        name: otherPartyConversation?.name?.toLowerCase(),
        to: path,
      });
    }
  };

  const onSelectOtherPartyLegalTeamConversation = () => {
    if (isInsitu) {
      setInsituProps(otherPartyLegalTeamConversation?.id);
    } else {
      const path = `${includeFirmAdmin}/clients/${clientId}/conversations/${otherPartyLegalTeamConversation?.id}`;
      history.push(path);
      pushBreadcrumb({
        name: otherPartyLegalTeamConversation?.name?.toLowerCase(),
        to: path,
      });
    }
  };

  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const content = (
    <>
      {!!backLocation && (
        <PlatformTouchable
          style={[ClientConversationsStyle.backButton]}
          onPress={backToPrevLocation}
        >
          <>
            <ChevronLeftIcon width={5.34} height={10.25} fill={white} />
            <Text
              style={[ClientConversationsStyle.backButtonText]}
              numberOfLines={1}
            >{`back to ${backText}`}</Text>
          </>
        </PlatformTouchable>
      )}
      {loadingConversations && !clientAccountConversations?.clientAccount ? (
        <LoadingView />
      ) : (
        <View style={[ClientConversationsStyle.conversationsWrapNoAccordion]}>
          {((isInsitu && clientConversation.id) || !isInsitu) && (
            <ClientConversationListItem
              onSelect={onSelectClientConversation}
              {...clientConversation}
              pictureUrl={
                clientConversation?.participants?.find(
                  (participant) =>
                    participant.id ===
                    clientAccountData?.clientAccount?.client?.id,
                )?.pictureUrl
              }
              isPending={!clientConversation.id}
              isInsitu={isInsitu}
              colour={MessageColours.green}
            />
          )}
          {((isInsitu && legalTeamConversation.id) || !isInsitu) && (
            <ClientConversationListItem
              onSelect={onSelectLegalTeamConversation}
              {...legalTeamConversation}
              pictureUrl={firmMembership?.firm?.logoUrl}
              isPending={!legalTeamConversation.id}
              isInsitu={isInsitu}
              colour={MessageColours.orange}
            />
          )}
          {((isInsitu && otherPartyConversation.id) || !isInsitu) && (
            <ClientConversationListItem
              onSelect={onSelectOtherPartyConversation}
              {...otherPartyConversation}
              isPending={!otherPartyConversation.id}
              isInsitu={isInsitu}
              colour={MessageColours.purple}
            />
          )}
          {((isInsitu && otherPartyLegalTeamConversation.id) || !isInsitu) && (
            <ClientConversationListItem
              onSelect={() => setShowConfirm(true)}
              {...otherPartyLegalTeamConversation}
              isPending={!otherPartyLegalTeamConversation.id}
              isInsitu={isInsitu}
              colour={MessageColours.yellow}
            />
          )}
        </View>
      )}
    </>
  );

  return (
    <>
      {!isInsitu ? (
        <>
          <PersonHeader
            userId={clientAccountData?.clientAccount?.client?.id}
            person={{
              id: clientAccountData?.clientAccount?.client.id,
              firstName:
                clientAccountData?.clientAccount?.client?.preferredName,
              lastName: clientAccountData?.clientAccount?.client?.lastName,
              pictureUrl: clientAccountData?.clientAccount?.client?.pictureUrl,
              title: clientAccountData?.clientAccount?.referenceNumber,
            }}
            title="all conversations"
            showContact={false}
          />
          <PageWrap
            containerStyle={
              {
                // ...(filteredConversations?.length === 0 &&
                //   !loading && {
                //     flex: 1,
                //     alignItems: 'center',
                //     justifyContent: 'center',
                //   }),
              }
            }
            // isScroll={sortBy !== 'a-z-first' && sortBy !== 'a-z-last'}
            backgroundStyle="LOGO"
          >
            {content}
          </PageWrap>
        </>
      ) : (
        <View
          style={[
            ClientConversationsStyle.main,
            isInsitu && { backgroundColor: '#303537' },
          ]}
        >
          {content}
        </View>
      )}
      <ModalInline
        size="small"
        close={() => setShowConfirm(false)}
        showModal={showConfirm}
        action={onSelectOtherPartyLegalTeamConversation}
        title="other side conversation"
        actionText="continue"
        textContent="you will be communicating with the legal team instructed by the other party, do you wish to continue?"
      />
    </>
  );
};

export default ClientConversations;

const ClientConversationsStyle = StyleSheet.create({
  main: {
    flex: 1,
  },
  backButton: {
    height: 32,
    width: '100%',
    backgroundColor: blue,
    paddingHorizontal: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backButtonText: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 12,
    color: white,
    marginLeft: 5,
  },
  conversationsWrapNoAccordion: {
    paddingHorizontal: 5,
    paddingTop: 5,
    backgroundColor: 'rgba(52, 56, 58, 0.75)',
  },
});
