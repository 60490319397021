import React from 'react';
import DivorceIcon from '../components/icons/DivorceIcon';
import FinanceIcon from '../components/icons/FinanceIcon';
import ChildrenIcon from '../components/icons/ChildrenIcon';

export const getMatterIcon = (type) => {
  if (type === 'DIVORCE') {
    return <DivorceIcon width={18.85} height={17.53} />;
  }
  if (type === 'FINANCE') {
    return <FinanceIcon width={18.53} height={12.74} />;
  }
  if (type === 'CHILD') {
    return <ChildrenIcon width={18.81} height={18.52} />;
  }
  return null;
};

export const getMatterType = (matter) => {
  const type = matter && matter.type;
  if (type && type === 'DIVORCE') {
    return 'divorce matter';
  }
  if (type && type === 'CHILD') {
    return 'child matter';
  }
  if (type && type === 'FINANCE') {
    return 'finance matter';
  }
  if (!type) {
    return '';
  }
};
