import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { G, Path, Rect, Svg } from 'react-native-svg';
import { blue } from '../../configs/colours';

interface Props {
  fill?: string;
  secondaryFill?: string;
  height?: number;
  width?: number;
  style?: StyleProp<ViewStyle>;
}

const MattersIcon: React.FunctionComponent<Props> = ({
  fill = blue,
  secondaryFill = '#e7f3f8',
  width = 20,
  height = 18,
  style,
}) => {
  return (
    <Svg height={height} viewBox="0 0 20 18" width={width} style={style}>
      <G>
        <Path d="M1,2H19V17a1,1,0,0,1-1,1H2a1,1,0,0,1-1-1V2Z" fill="#383d3f" />
        <Path
          d="M2.25,2.62h15.5a.63.63,0,0,1,.63.63V17a.38.38,0,0,1-.38.38H2A.38.38,0,0,1,1.62,17V3.25A.63.63,0,0,1,2.25,2.62Z"
          fill="none"
          stroke={fill}
          strokeWidth="1.25"
        />
      </G>
      <G>
        <G>
          <Path d="M1,0H19a1,1,0,0,1,1,1V4H0V1A1,1,0,0,1,1,0Z" fill="none" />
          <Rect
            x="0.5"
            y="0.5"
            width="19"
            height="3"
            rx="0.5"
            fill="none"
            stroke={fill}
          />
        </G>
        <Rect x="1" y="1" width="18" height="2" fill="#383d3f" />
      </G>
      <G>
        <Rect x="6" y="6" width="8" height="3" rx="1" fill="none" />
        <Path
          d="M7,6.38h6a.62.62,0,0,1,.62.62V8a.63.63,0,0,1-.62.63H7A.62.62,0,0,1,6.37,8V7A.63.63,0,0,1,7,6.37Z"
          fill="none"
          stroke={secondaryFill}
          strokeWidth="0.75"
        />
      </G>
    </Svg>
  );
};

export default MattersIcon;
