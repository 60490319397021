import { createContext } from 'react';
import { NotificationProps } from './Notification';
import { AlertProps } from './Alert';
import { NotificationsNotification } from '../types/Notifications';

export interface NotificationContextProps {
  notifications: NotificationProps[];
  alerts: AlertProps[];
  addAlert: (alert: AlertProps) => void;
  removeAlert: (id: string) => void;
  addNotification: (
    notification: NotificationProps & NotificationsNotification,
  ) => void;
  removeNotification: (id: string) => void;
  totalUnread: number;
  setTotalUnread: (total: number) => void;
  notificationsVisible: boolean;
  setNotificationsVisible: (visible: boolean) => void;
}

export const defaultNotificationContextProps: NotificationContextProps = {
  notifications: [],
  alerts: [],
  addAlert: () => null,
  removeAlert: () => null,
  addNotification: () => null,
  removeNotification: () => null,
  totalUnread: 0,
  setTotalUnread: () => null,
  notificationsVisible: false,
  setNotificationsVisible: () => null,
};

export const NotificationContext = createContext<NotificationContextProps>(
  defaultNotificationContextProps,
);
