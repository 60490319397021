import { ApolloClient } from 'apollo-client';
import { split } from 'apollo-link';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import QueueLink from 'apollo-link-queue';
import { WebSocketLink } from 'apollo-link-ws';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from 'apollo-utilities';

import { logoutGlobal } from '../components/AuthProvider';
import Resolvers from '../resolvers';

import { cache, persistor } from './persisitor';
import {
  getToken,
  replaceAndroid,
  subscriptionClient,
} from './subscriptionClient';

const errorLink = onError((error) => {
  if (error?.networkError || error?.graphQLErrors) {
    if (
      (error?.networkError?.message === 'Unauthenticated' ||
        !!error?.graphQLErrors?.find(
          (er) => er?.message === 'Unauthenticated',
        )) &&
      logoutGlobal
    ) {
      logoutGlobal(true, true);
    }
  }
});

const apiURL = replaceAndroid(process.env.API_URL);

const customFetch = (_, options) => {
  // Check if request is file upload
  if (options.body instanceof FormData) return fetch(apiURL, options);

  return fetch(apiURL, options);
};

const isObject = (node) => typeof node === 'object' && node !== null;

// rough first draft, could probably be optimised in a loads of different ways.
const hasFiles = (node, found = []) => {
  Object.keys(node).forEach((key) => {
    if (!isObject(node[key]) || found.length > 0) {
      return;
    }

    if (
      (typeof File !== 'undefined' && node[key] instanceof File) ||
      (typeof Blob !== 'undefined' && node[key] instanceof Blob)
    ) {
      found.push(node[key]);
      return;
    }

    hasFiles(node[key], found);
  });

  return found.length > 0;
};

const httpLink = split(
  ({ variables }) => hasFiles(variables),
  createUploadLink({
    uri: apiURL,
    fetch: customFetch,
  }),
  new BatchHttpLink({
    uri: apiURL,
    fetch: customFetch,
  }),
);

const authLink = setContext(async (_, { headers }) => {
  // return the headers to the context so httpLink can read them
  const token = await getToken();

  return {
    headers: {
      ...headers,
      accept: 'application/json',
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

// const isServer =
//   typeof process !== 'undefined' &&
//   typeof process.versions !== 'undefined' &&
//   typeof process.versions.node !== 'undefined' &&
//   typeof window === 'undefined';

const wsLink = new WebSocketLink(subscriptionClient);

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

export const offlineLink = new QueueLink();

cache.writeData({
  data: {
    ...Resolvers.defaults,
  },
});

const theClient = async () => {
  await persistor.restore();

  const client = new ApolloClient({
    link: authLink.concat(offlineLink).concat(errorLink).concat(link),
    resolvers: Resolvers.resolvers,
    cache,
    assumeImmutableResults: true,
  });

  return client;
};

export default theClient;
