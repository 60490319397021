import React, { ReactNode, useContext } from 'react';
import {
  GestureResponderEvent,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { blue, white } from '../configs/colours';
import { useHistory, useLocation } from '../utils/routing';
import Hoverable from './Hoverable';
import PlusCircleIcon from './icons/PlusCircleIcon';
import PlatformTouchable from './PlatformTouchable';
import { ThemeContext } from './ThemeProvider';
import screenSizes from '../configs/screenSizes';
import useUpdateBreadcrumbs from '../hooks/useUpdateBreadcrumbs';

interface Props {
  text: string;
  to?: string;
  icon: () => ReactNode;
  onLinkPress?: (e: GestureResponderEvent) => void;
  action?: () => void;
  activeMatch?: (location: string) => boolean;
  highlight?: number;
  isNonLink?: boolean;
}

const SideMenuLink: React.FunctionComponent<Props> = ({
  text,
  to,
  onLinkPress,
  icon,
  action,
  activeMatch,
  highlight,
  isNonLink = false,
}) => {
  const location = useLocation();
  const { width } = useWindowDimensions();
  const history = useHistory();
  const { themeColours } = useContext(ThemeContext);
  const { updateAllBreadcrumbs } = useUpdateBreadcrumbs();
  const active =
    !isNonLink && (activeMatch(location.pathname) || location.pathname === to);
  const onPress = (e: GestureResponderEvent) => {
    if (isNonLink && !!onLinkPress) {
      onLinkPress(e);
      return;
    }
    history.push(to);
    updateAllBreadcrumbs([
      {
        to,
        name: text,
      },
    ]);
    if (onLinkPress) {
      onLinkPress(e);
    }
  };
  return (
    <Hoverable>
      {(isHovered: boolean) => (
        <PlatformTouchable onPress={onPress} accessibilityRole="link">
          <View
            style={[
              SideMenuLinkStyle.listItem,
              {
                backgroundColor: active ? 'rgba(48,53,55,0.5)' : 'transparent',
                borderBottomColor: '#4B4F52',
              },
              active && {
                backgroundColor: '#234955',
              },
              isHovered && {
                borderLeftColor: blue,
                backgroundColor: '#34383A',
                borderLeftWidth: 4,
                paddingLeft: 14,
              },
            ]}
          >
            {!!icon && (
              <View style={[SideMenuLinkStyle.iconWrap]}>
                {icon()}
                {!!highlight && highlight > 0 && (
                  <View style={[SideMenuLinkStyle.highlightCircle]} />
                )}
              </View>
            )}

            {isHovered && highlight > 0 ? (
              <Text
                style={[SideMenuLinkStyle.newText]}
              >{`${highlight} new`}</Text>
            ) : (
              <Text
                style={[
                  SideMenuLinkStyle.listItemText,
                  width > screenSizes.medium &&
                    SideMenuLinkStyle.listItemTextDesktop,
                  {
                    color: isHovered
                      ? themeColours.primaryFont
                      : active
                      ? white
                      : !!highlight && highlight > 0
                      ? '#E5F3F8'
                      : '#979D9F',
                    fontFamily:
                      isHovered || (!!highlight && highlight > 0)
                        ? 'Quicksand-Bold'
                        : 'Quicksand-Medium',
                  },
                ]}
                numberOfLines={1}
              >
                {text}
              </Text>
            )}
            {!!action && (
              <PlatformTouchable
                onPress={action}
                style={[SideMenuLinkStyle.action]}
              >
                <PlusCircleIcon />
              </PlatformTouchable>
            )}
          </View>
        </PlatformTouchable>
      )}
    </Hoverable>
  );
};
export default SideMenuLink;

const SideMenuLinkStyle = StyleSheet.create({
  listItem: {
    paddingHorizontal: 15,
    paddingVertical: 15,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    minHeight: 49,
  },
  listItemTextDesktop: {
    fontSize: 14,
  },
  listItemText: {
    fontSize: 16,
    marginLeft: 10,
    flex: 1,
  },
  iconWrap: {
    width: 24,
    justifyContent: 'center',
    alignItems: 'center',
  },
  newText: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 12,
    color: blue,
    flex: 1,
    marginLeft: 10,
  },
  action: {
    paddingLeft: 4,
  },
  highlightCircle: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: blue,
    position: 'absolute',
    top: -3,
    right: 0,
  },
});
