import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { G, Path, Rect, Svg } from 'react-native-svg';
import { green } from '../../configs/colours';

interface Props {
  fill?: string;
  height?: number;
  width?: number;
  style?: StyleProp<ViewStyle>;
}

const FileIcon: React.FunctionComponent<Props> = ({
  fill = green,
  width = 15.389,
  height = 17.313,
  style,
}) => {
  return (
    <Svg
      height={height}
      viewBox="0 0 15.389 17.313"
      width={width}
      style={style}
    >
      <G>
        <Rect y="1.92" width="15.39" height="15.39" rx="2" fill="#282c2e" />
        <Rect
          x="0.5"
          y="2.42"
          width="14.39"
          height="14.39"
          rx="1.5"
          fill="none"
          stroke={fill}
          strokeLinejoin="round"
        />
      </G>
      <Path
        d="M4,7.18A1.48,1.48,0,0,1,2.53,5.7h0V1.48a1.48,1.48,0,1,1,3,0v1a.41.41,0,0,1-.44.4.42.42,0,0,1-.4-.4v-1a.64.64,0,1,0-1.27,0V5.7a.64.64,0,1,0,1.27,0V4.53A.42.42,0,0,1,5,4.09a.42.42,0,0,1,.44.4V5.7A1.47,1.47,0,0,1,4,7.18Z"
        transform="translate(0 0)"
        fill={fill}
      />
      <Path
        d="M11.88,10.46h-8A.41.41,0,0,1,3.51,10a.41.41,0,0,1,.39-.4h8a.42.42,0,0,1,.4.44.41.41,0,0,1-.4.4Z"
        transform="translate(0 0)"
        fill={fill}
      />
      <Path
        d="M11.88,13h-8a.41.41,0,0,1-.39-.44.41.41,0,0,1,.39-.4h8a.42.42,0,0,1,.4.44.41.41,0,0,1-.4.4Z"
        transform="translate(0 0)"
        fill={fill}
      />
    </Svg>
  );
};

export default FileIcon;
