import React, { ElementType } from 'react';
import {
  Platform,
  TouchableNativeFeedback,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  StyleProp,
  ViewStyle,
  BackgroundPropType,
  TouchableNativeFeedbackProps,
  TouchableOpacityProps,
} from 'react-native';

const shouldNotUseOpacity = Platform.OS === 'android' && Platform.Version > 20;
const TouchableComponent:
  | typeof TouchableNativeFeedback
  | typeof TouchableOpacity = shouldNotUseOpacity
  ? TouchableNativeFeedback
  : TouchableOpacity;

interface Props {
  fallback?: ElementType | null;
  style?: StyleProp<ViewStyle>;
  foreground?: BackgroundPropType;
  background?: BackgroundPropType;
  useForeground?: boolean;
}

const PlatformTouchable: React.FunctionComponent<Props &
  TouchableNativeFeedbackProps &
  TouchableOpacityProps> = ({
  children,
  style,
  foreground,
  background,
  useForeground,
  fallback = null,
  ...props
}) => {
  // Even though it works for TouchableWithoutFeedback and
  // TouchableNativeFeedback with this component, we want
  // the API to be the same for all components so we require
  // exactly one direct child for every touchable type.
  children = React.Children.only(children);

  if (TouchableComponent === TouchableNativeFeedback) {
    useForeground =
      foreground && TouchableNativeFeedback.canUseNativeForeground();

    if (foreground && background) {
      // tslint:disable-next-line
      console.warn(
        'Specified foreground and background for Touchable, only one can be used at a time. Defaulted to foreground.',
      );
    }

    return (
      <TouchableComponent
        {...props}
        useForeground={useForeground}
        background={(useForeground && foreground) || background}
      >
        <View style={style}>{children}</View>
      </TouchableComponent>
    );
  } else if (TouchableComponent === TouchableWithoutFeedback) {
    return (
      <TouchableWithoutFeedback {...props}>
        <View style={style}>{children}</View>
      </TouchableWithoutFeedback>
    );
  } else {
    const TouchableFallback: ElementType = fallback || TouchableComponent;
    return (
      <TouchableFallback {...props} style={style}>
        {children}
      </TouchableFallback>
    );
  }
};

export default PlatformTouchable;
