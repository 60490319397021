import React, { lazy, Suspense } from 'react';
import { Route, Switch, useLocation } from '../utils/routing';
import LoadingView from './LoadingView';

const Login = lazy(() => import('../containers/Login'));
const PasswordReset = lazy(() => import('../containers/PasswordReset'));
const VerifyEmailAddress = lazy(
  () => import('../containers/VerifyEmailAddress'),
);
const Welcome = lazy(() => import('../containers/Welcome'));
const Ticket = lazy(() => import('./Ticket'));

interface Props {}

const UnauthenticatedRoutes: React.FunctionComponent<Props> = () => {
  const location = useLocation();
  return (
    <Suspense fallback={<LoadingView />}>
      <Switch location={location}>
        <Route
          exact={true}
          path="/password-reset/:ticket"
          children={<PasswordReset />}
        />
        <Route
          exact={true}
          path="/verify-email/:ticket"
          children={<VerifyEmailAddress />}
        />
        <Route path="/ticket/:ticket" children={<Ticket />} />
        <Route
          path={[
            '/profile-settings/issue-management/:ticket',
            '/connect/:ticket',
          ]}
          children={<Login />}
        />
        <Route path="/welcome/:ticket" children={<Welcome />} />
        <Route children={<Login />} />
      </Switch>
    </Suspense>
  );
};

export default UnauthenticatedRoutes;
