import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { Path, Svg } from 'react-native-svg';
import { blue, white } from '../../configs/colours';

interface Props {
  fill?: string;
  secondaryFill?: string;
  height?: number;
  width?: number;
  style?: StyleProp<ViewStyle>;
}

const NoSearchResultsIcon: React.FunctionComponent<Props> = ({
  fill = '#9a9a9a',
  width = 18.45,
  height = 22.77,
  style,
}) => {
  return (
    <Svg height={height} viewBox="0 0 18.45 22.77" width={width} style={style}>
      <Path
        d="M10.19,16.7l5.37,5.36Z"
        fill="none"
        stroke={fill}
        strokeWidth="2"
      />
      <Path
        d="M1,12.87a5.37,5.37,0,1,1,5.36,5.36h0A5.36,5.36,0,0,1,1,12.87Z"
        fill="none"
        stroke={fill}
        strokeWidth="2"
      />
      <Path
        d="M15.92,3.41a.42.42,0,0,1,.3-.13,1.59,1.59,0,0,0,1-.33A1.16,1.16,0,0,0,17.59,2a1.15,1.15,0,0,0-.35-.85,1.24,1.24,0,0,0-.87-.33,1.33,1.33,0,0,0-.55.12,1.39,1.39,0,0,0-.46.33.58.58,0,0,1-.36.16.41.41,0,0,1-.24-.08.43.43,0,0,1-.2-.34A.42.42,0,0,1,14.7.75,2,2,0,0,1,15.44.2a2.25,2.25,0,0,1,.93-.2,2.15,2.15,0,0,1,1.05.26,1.88,1.88,0,0,1,.75.73,1.91,1.91,0,0,1,.28,1,1.9,1.9,0,0,1-.24,1,1.84,1.84,0,0,1-.65.68,2.23,2.23,0,0,1-.93.31h0s0,0,0,0v.59a.45.45,0,0,1-.12.31.41.41,0,0,1-.3.12.41.41,0,0,1-.31-.12.4.4,0,0,1-.11-.31V3.73A.45.45,0,0,1,15.92,3.41Zm-.06,3.11a.45.45,0,0,1-.12-.34V6a.47.47,0,0,1,.12-.34.45.45,0,0,1,.33-.12h.09a.45.45,0,0,1,.34.12.51.51,0,0,1,.11.34v.17a.5.5,0,0,1-.11.34.45.45,0,0,1-.34.12h-.09A.45.45,0,0,1,15.86,6.52Z"
        fill={fill}
      />
    </Svg>
  );
};

export default NoSearchResultsIcon;
