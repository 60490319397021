import { DataProxy } from 'apollo-cache';
import produce from 'immer';
import { LIST_MESSAGES_QUERY } from '../../queries/messages';
import { ListMessagesQueryVariables } from '../../types/apolloTypes';
import {
  ListConversationsDocument,
  ListConversationsQuery,
  ListConversationsQueryVariables,
  ListMessagesDocument,
  ListMessagesQuery,
  ResumeConversationMutation,
  ResumeConversationMutationVariables,
} from './../../types/apolloTypes';

// Update Cache Function to update after resume mutation
export default function resumeConversationUpdate(
  cache: DataProxy,
  data?: ResumeConversationMutation,
  variables?: ResumeConversationMutationVariables,
  individualId?: string,
) {
  // Update message list query with new message
  const queryVariables = {
    conversationId: variables.input.conversationId,
    input: {
      conversationId: variables.input.conversationId,
      page: 1,
      pageSize: 50,
    },
  };

  try {
    const listQueryData = cache.readQuery<
      ListMessagesQuery,
      ListMessagesQueryVariables
    >({
      query: ListMessagesDocument,
      variables: queryVariables,
    });

    const nextListQueryState = produce(listQueryData, (draftState) => {
      draftState.conversation = {
        ...draftState.conversation,
        pausedUntil: data.resumeConversation.pausedUntil,
      };
    });

    cache.writeQuery({
      query: ListMessagesDocument,
      data: nextListQueryState,
      variables: queryVariables,
    });
  } catch (error) {
    if (error?.name !== 'Invariant Violation') {
      throw new Error(error);
    }
  }

  try {
    const listQueryData = cache.readQuery<
      ListConversationsQuery,
      ListConversationsQueryVariables
    >({
      query: ListConversationsDocument,
      variables: {
        memberId: individualId,
      },
    });

    const nextListQueryState = produce(listQueryData, (draftState) => {
      const conversationIndex = draftState.conversations.findIndex(
        (c) => c.id === data.resumeConversation.id,
      );
      if (conversationIndex > -1) {
        draftState.conversations[conversationIndex] = {
          ...draftState.conversations[conversationIndex],
          pausedUntil: data.resumeConversation.pausedUntil,
        };
      }
    });

    cache.writeQuery({
      query: ListConversationsDocument,
      data: nextListQueryState,
      variables: {
        memberId: individualId,
      },
    });
  } catch (error) {
    if (error?.name !== 'Invariant Violation') {
      throw new Error(error);
    }
  }
}
