import { useMemo } from 'react';
import { useGetCurrentUserQuery } from '../types/apolloTypes';

export default function useIsNonPro(): boolean {
  const { data: currentUserData } = useGetCurrentUserQuery();

  const isNonPro = useMemo(
    () =>
      !!currentUserData?.currentUser?.roles?.find(
        (role) => role.name === 'Client',
      ) || false,
    [currentUserData?.currentUser?.id],
  );

  return isNonPro;
}
