export const white = '#ffffff';
export const whiteRGBA = (opacity: number = 1) =>
  `rgba(255,255,255, ${opacity})`;
export const black = '#000000';
export const blackRGBA = (opacity: number = 1) => `rgba(0,0,0, ${opacity})`;
export const lightGrey = '#E3E3E3';
export const lightGreyRGBA = (opacity: number = 1) =>
  `rgba(227,227,227, ${opacity})`;
export const lightGrey2 = '#EDEDED';
export const lightGrey2RGBA = (opacity: number = 1) =>
  `rgba(237,237,237, ${opacity})`;
export const lightGrey3 = '#F8F8F8';
export const lightGrey3RGBA = (opacity: number = 1) =>
  `rgba(248,248,248, ${opacity})`;
export const lightGrey4 = '#F5F5F5';
export const lightGrey4RGBA = (opacity: number = 1) =>
  `rgba(245,245,245, ${opacity})`;
export const lightGrey5 = '#FAFAFA';
export const lightGrey5RGBA = (opacity: number = 1) =>
  `rgba(250,250,250, ${opacity})`;
export const mediumGrey = '#8F9295';
export const mediumGreyRGBA = (opacity: number = 1) =>
  `rgba(143,146,149, ${opacity})`;
export const mediumGrey2 = '#484C50';
export const mediumGrey2RGBA = (opacity: number = 1) =>
  `rgba(72,76,80, ${opacity})`;
export const mediumGrey3 = '#3F4345';
export const mediumGrey3RGBA = (opacity: number = 1) =>
  `rgba(63,67,69, ${opacity})`;
export const darkGrey = '#343D3F';
export const darkGreyRGBA = (opacity: number = 1) =>
  `rgba(52,61,63, ${opacity})`;
export const darkGrey2 = '#303537';
export const darkGrey2RGBA = (opacity: number = 1) =>
  `rgba(48,53,55, ${opacity})`;
export const darkGrey3 = '#383D3F';
export const darkGrey3RGBA = (opacity: number = 1) =>
  `rgba(56,61,63, ${opacity})`;
export const darkGrey4 = '#444A4D';
export const darkGrey4RGBA = (opacity: number = 1) =>
  `rgba(68,74,77, ${opacity})`;
export const blue = '#02ACE6';
export const blueRGBA = (opacity: number = 1) => `rgba(2,172,230, ${opacity})`;
export const green = '#00C977';
export const greenRGBA = (opacity: number = 1) => `rgba(0,201,119, ${opacity})`;
export const purple = '#810EFC';
export const purpleRGBA = (opacity: number = 1) =>
  `rgba(129,14,252, ${opacity})`;

export function hexToRGB(hex: string, alpha: number) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
}
