import * as React from 'react';
import {
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
} from 'react-native';
import { black, lightGrey, white } from '../configs/colours';
import screenSizes from '../configs/screenSizes';
import { useGetFirmMembership } from '../hooks/useGetFirm';
import useUpdateBreadcrumbs from '../hooks/useUpdateBreadcrumbs';
import useIsNonPro from '../hooks/usIsNonPro';
import {
  FirmRole,
  IssueType,
  useGetCurrentUserQuery,
  useListClientAccountsForClientQuery,
  useListIssuesQuery,
} from '../types/apolloTypes';
import { calculateAspectRatioFit } from '../utils/heightDetectionHelpers';
import { useHistory, useLocation } from '../utils/routing';
import HighlightCircle from './HighlightCircle';
import BellIcon from './icons/BellIcon';
import LogoImage from './LogoImage';
import { NotificationContext } from './NotificationContext';
import PlatformTouchable from './PlatformTouchable';
import SideMenuLink from './SideMenuLink';
import { ThemeContext } from './ThemeProvider';

export interface MenuItem {
  name: string;
  location: string;
  hiddenFromRoles?: ('Client' | 'Firm Administrator' | 'Professional')[];
  showToRoles?: ('Client' | 'Firm Administrator' | 'Professional')[];
  icon: () => React.ReactNode;
  action?: () => void;
  activeMatch?: (location: string) => boolean;
  highlight?: number;
}

interface Props {
  menuItems?: MenuItem[];
  toggleVisibility?: () => void;
  notificationsVisibility: boolean;
  toggleNotificationsVisibility: (visibility: boolean) => void;
}

const SideMenu: React.FunctionComponent<Props> = ({
  menuItems = [],
  toggleVisibility = () => null,
  notificationsVisibility,
  toggleNotificationsVisibility,
}) => {
  const { width = 1000 } = useWindowDimensions();
  const { totalUnread } = React.useContext(NotificationContext);

  const { themeColours } = React.useContext(ThemeContext);

  // Responsive styles based on screen size;
  const responsiveStyles =
    width > screenSizes.medium ? SideMenuStyle.desktop : SideMenuStyle.mobile;

  // Check for react router context, if exists add toggle for side menu on route change. (Workaround on native)
  const location = useLocation();

  React.useEffect(() => {
    if (!!location) {
      toggleVisibility();
    }
  }, [location?.pathname]);

  const isNonPro = useIsNonPro();

  const { data: currentUserData } = useGetCurrentUserQuery();

  const { updateAllBreadcrumbs } = useUpdateBreadcrumbs();

  const history = useHistory();

  const { data: clientAccountsData } = useListClientAccountsForClientQuery({
    variables: {
      clientId: currentUserData?.currentUser?.id,
    },
    skip: !isNonPro || !currentUserData?.currentUser?.id,
  });

  const firmMembership = useGetFirmMembership();

  const membershipActive = isNonPro ? true : firmMembership?.isActive;

  const firmLogo = isNonPro
    ? clientAccountsData?.clientAccountsForClient?.items?.[0]?.firm?.logoUrl
    : firmMembership?.firm?.logoUrl;

  const findRole = (role) =>
    !!currentUserData?.currentUser?.roles?.find((userRole) => {
      if (
        firmMembership &&
        role === 'Firm Administrator' &&
        firmMembership.role === FirmRole.Administrator
      ) {
        return true;
      }
      return userRole.name === role;
    });

  const showMenuItem = React.useCallback<(item: MenuItem) => boolean>(
    (item) =>
      !item?.hiddenFromRoles?.some((role) => findRole(role)) ||
      item?.showToRoles?.some((role) => findRole(role)),
    [firmMembership?.firm?.id],
  );

  const [logoSize, setLogoSize] = React.useState({
    height: 0,
    width: 0,
  });

  React.useEffect(() => {
    if (!!firmLogo) {
      Image.getSize(
        firmLogo,
        (logoWidth, logoHeight) =>
          setLogoSize(calculateAspectRatioFit(logoWidth, logoHeight, 129, 28)),
        () => null,
      );
    }
  }, [firmLogo]);

  const { data: listIssuesData } = useListIssuesQuery({
    variables: {
      clientId: currentUserData?.currentUser?.id,
    },
    fetchPolicy: 'cache-and-network',
    skip: !isNonPro,
  });

  const separationIssue = listIssuesData?.issues?.find(
    (issue) => issue.type === IssueType.Separation,
  );

  return (
    <View
      style={[
        SideMenuStyle.main,
        responsiveStyles,
        {
          backgroundColor: '#2B2F31',
        },
      ]}
    >
      <View style={[SideMenuStyle.safeArea]}>
        <View
          style={[
            SideMenuStyle.logoWrapper,
            width > screenSizes.medium && SideMenuStyle.logoWrapperDesktop,
          ]}
        >
          <LogoImage width={148} height={45} />
        </View>
        <ScrollView
          style={SideMenuStyle.contentWrap}
          contentContainerStyle={SideMenuStyle.contentWrapInner}
        >
          <View style={SideMenuStyle.links}>
            {menuItems.map(
              (item) =>
                showMenuItem(item) && (
                  <SideMenuLink
                    key={item.location}
                    to={item.location}
                    text={item.name}
                    icon={item.icon}
                    action={membershipActive ? item.action : undefined}
                    activeMatch={item.activeMatch}
                    highlight={membershipActive ? item.highlight : 0}
                    // Toggle for side menu on route link click. (Workaround on web)
                    onLinkPress={() => toggleVisibility()}
                  />
                ),
            )}
          </View>
        </ScrollView>
        <View
          style={[
            SideMenuStyle.bottomContent,
            { flexBasis: width > screenSizes.medium ? 106 : 133 },
          ]}
        >
          {/* <ToggleOption
              text="dark theme"
              defaultToggled={theme === DARK}
              onToggledOn={() => setTheme(DARK)}
              onToggledOff={() => setTheme(LIGHT)}
            /> */}
          <SideMenuLink
            text="notifications"
            icon={() => <BellIcon />}
            highlight={membershipActive ? totalUnread : 0}
            isNonLink={true}
            // Toggle for side menu on route link click. (Workaround on web)
            onLinkPress={() => {
              if (membershipActive) {
                toggleVisibility();
                toggleNotificationsVisibility(true);
              }
            }}
          />
          <PlatformTouchable
            onPress={() => {
              history.push('/profile-settings');
              updateAllBreadcrumbs([
                {
                  name: 'settings',
                  to: '/profile-settings',
                },
              ]);
            }}
            style={[
              SideMenuStyle.profileWrap,
              width > screenSizes.medium && SideMenuStyle.profileWrapDesktop,
            ]}
          >
            <>
              {!!!firmLogo ? (
                <Text
                  style={[
                    SideMenuStyle.profileText,
                    { color: themeColours.primaryFont },
                  ]}
                >
                  {`${currentUserData?.currentUser?.givenName} `}
                  <Text style={[SideMenuStyle.profileTextBold]}>
                    {currentUserData?.currentUser?.familyName}
                  </Text>
                </Text>
              ) : (
                <Image
                  source={{
                    uri: firmLogo,
                  }}
                  style={[
                    SideMenuStyle.firmLogo,
                    {
                      width: logoSize.width,
                      height: logoSize.height,
                    },
                  ]}
                />
              )}

              <View>
                <Image
                  source={{
                    uri: currentUserData?.currentUser?.picture,
                  }}
                  style={SideMenuStyle.profileImage}
                />

                {!!!separationIssue?.opponent?.isVerified && isNonPro && (
                  <HighlightCircle />
                )}
              </View>
            </>
          </PlatformTouchable>
        </View>
      </View>
    </View>
  );
};
export default SideMenu;

const SideMenuStyle = StyleSheet.create({
  logoWrapper: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderBottomWidth: 1,
    padding: 10,
    height: 72,
    // backgroundColor: '#383D3F',
    backgroundColor: '#2B2F31',
    borderBottomColor: '#4B4F52',
  },
  logoWrapperDesktop: {
    backgroundColor: '#2B2F31',
    // justifyContent: 'center',
    alignItems: 'flex-start',
    height: 100,
    paddingLeft: 20,
    paddingBottom: 35,
  },
  safeArea: { flex: 1 },
  main: {
    backgroundColor: lightGrey,
    flex: 1,
    shadowColor: black,
    shadowOffset: {
      width: 3,
      height: 3,
    },
    shadowOpacity: 0.04,
    shadowRadius: 6,
    elevation: 5,
  },
  desktop: {
    minWidth: 195,
    maxWidth: 195,
  },
  mobile: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 300,
    height: '100%',
    zIndex: 3,
    transform: [{ translateX: Platform.OS === 'android' ? 0 : -300 }],
  },
  links: {
    flex: 1,
  },
  contentWrap: {
    flex: 1,
    backgroundColor: '#383D3F',
  },
  contentWrapInner: {
    justifyContent: 'space-evenly',
    flex: 1,
  },
  bottomContent: {
    justifyContent: 'flex-end',
    flexBasis: 137,
    borderTopWidth: 1,
    borderTopColor: '#4B4F52',
  },
  profileImage: {
    width: 28,
    height: 28,
    borderRadius: 3,
    marginLeft: 8,
  },
  firmLogo: {
    height: '100%',
    width: '100%',
    maxHeight: 28,
    maxWidth: 129,
  },
  profileWrap: {
    paddingHorizontal: 15,
    paddingTop: 15,
    paddingBottom: 30,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#212426',
    height: 82,
  },
  profileWrapDesktop: {
    paddingBottom: 15,
    height: 56,
  },
  logoutText: {
    fontFamily: 'Quicksand-Regular',
    fontSize: 14,
    color: white,
    textAlign: 'center',
  },
  profileText: {
    fontFamily: 'Quicksand-Regular',
    fontSize: 12,
    flex: 1,
  },
  profileTextBold: {
    fontFamily: 'Quicksand-Medium',
  },
  logoutButton: {
    borderTopColor: '#E6E6E6',
    borderTopWidth: 1,
    paddingVertical: 15,
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: -20,
  },
  // logoutText: {
  //   fontSize: 14,
  //   fontFamily: 'Quicksand-Regular',
  //   color: blue,
  //   marginLeft: 15,
  // },
});
