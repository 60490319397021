import queryString from 'query-string';
import * as React from 'react';
import {
  Animated,
  DrawerLayoutAndroid,
  Easing,
  Keyboard,
  Platform,
  StyleSheet,
  useWindowDimensions,
  View,
} from 'react-native';
import {
  Directions,
  FlingGestureHandler,
  State,
} from 'react-native-gesture-handler';
import screenSizes from '../configs/screenSizes';
import { useAnimation } from '../hooks/useAnimation';
import useDetectOffline from '../hooks/useDetectOffline';
import { useGetFirmMembership } from '../hooks/useGetFirm';
import useHasCompletedProfile from '../hooks/useHasCompletedProfile';
import usePrevious from '../hooks/usePrevious';
import useUpdateBreadcrumbs from '../hooks/useUpdateBreadcrumbs';
import useIsNonPro from '../hooks/usIsNonPro';
import { conversationUpdateUpdate } from '../subscriptions/updates/ConversationUpdate';
import { firmMemberUpdateUpdate } from '../subscriptions/updates/FirmMemberUpdate';
import { highlightChangeUpdate } from '../subscriptions/updates/HighlightChange';
import { messageUpdateUpdateMessages } from '../subscriptions/updates/MessageUpdate';
import { newMessagesUpdateMessages } from '../subscriptions/updates/NewMessages';
import {
  HighlightCountsPayload,
  IssueType,
  useFirmMemberUpdateSubscription,
  useGetCurrentUserQuery,
  useGetHighlightCountsQuery,
  useHighlightChangeSubscription,
  useListFirmMembershipsQuery,
  useListIssuesQuery,
  useMarkMessageDeliveredMutation,
  useMessageUpdateSubscription,
  useNewMessagesSubscription,
  useOnConversationUpdateSubscription,
} from '../types/apolloTypes';
import { useHistory, useLocation } from '../utils/routing';
import { AlertTypes } from './Alert';
import AttentionBanner from './AttentionBanner';
import ContextAside from './ContextAside';
import ContextOverlay from './ContextOverlay';
import Header from './Header';
import ClientsIcon from './icons/ClientsIcon';
import ContactsIcon from './icons/ContactsIcon';
import ConversationsIcon from './icons/ConversationsIcon';
import DashboardIcon from './icons/DashboardIcon';
import FilesIcon from './icons/FilesIcon';
import FirmAdministrationIcon from './icons/FirmAdministrationIcon';
import KeyDatesIcon from './icons/KeyDatesIcon';
import MattersIcon from './icons/MattersIcon';
import NotesIcon from './icons/NotesIcon';
import TaskLibraryIcon from './icons/TaskLibraryIcon';
import InsituConversationContextProvider from './InsituConversationContextProvider';
import LoadingView from './LoadingView';
import {
  CONTEXT_MENU,
  MenuContext,
  NO_MENU,
  SIDE_MENU,
} from './MenuContextProvider';
import MobileMenuSlider from './MobileMenuSlider';
import NoteForm from './NoteForm';
import { NotificationContext } from './NotificationContext';
import NotificationsList from './Notifications';
import SideMenu, { MenuItem } from './SideMenu';
import { ThemeContext } from './ThemeProvider';

interface Props {
  children: React.ReactNode;
}

const getMainPosition = (menu: string = NO_MENU) => {
  if (menu === NO_MENU) return 0;
  if (menu === CONTEXT_MENU) return -300;
  if (menu === SIDE_MENU) return 300;
};

const getSideVisibility = (activeMenu: string = NO_MENU) => {
  if (activeMenu === SIDE_MENU) return NO_MENU;
  return SIDE_MENU;
};

const getLeftSwipeMenu = (activeMenu: string = NO_MENU) => {
  if (activeMenu === SIDE_MENU) return NO_MENU;
  if (activeMenu === NO_MENU) return CONTEXT_MENU;
  return CONTEXT_MENU;
};

const getRightSwipeMenu = (activeMenu: string = NO_MENU) => {
  if (activeMenu === CONTEXT_MENU) return NO_MENU;
  if (activeMenu === NO_MENU) return SIDE_MENU;
  return SIDE_MENU;
};

const Layout: React.FunctionComponent<Props> = ({ children }) => {
  const { width = 1000 } = useWindowDimensions();
  const { themeColours } = React.useContext(ThemeContext);
  const {
    activeMenu,
    setActiveMenu,
    overlayTitle,
    overlayComponent,
    displayMenuSlider,
  } = React.useContext(MenuContext);
  const contentStyles =
    width > screenSizes.medium
      ? LayoutStyles.content
      : LayoutStyles.contentMobile;

  // Toggle spring animation from size menu
  const menuX = useAnimation({
    type: 'timing',
    initialValue: 0,
    duration: 300,
    easing: Easing.inOut(Easing.ease),
    useNativeDriver: Platform.OS !== 'web',
    toValue: getMainPosition(activeMenu),
  });

  const location = useLocation();
  const history = useHistory();

  const justLoggedIn = queryString.parse(location.search)?.loggedIn;
  const isFirstLogin = queryString.parse(location.search)?.firstLogin;

  const { updateAllBreadcrumbs } = useUpdateBreadcrumbs();

  const { data: currentUserData, refetch } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-and-network',
  });

  const isNonPro = useIsNonPro();

  const currentUserProIdVariables = {
    professionalId: currentUserData?.currentUser?.id,
  };

  useFirmMemberUpdateSubscription({
    skip: isNonPro || !currentUserData?.currentUser?.id,
    variables: currentUserProIdVariables,
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (subscriptionData?.data) {
        firmMemberUpdateUpdate(
          client,
          subscriptionData?.data,
          currentUserProIdVariables,
        );
      }
    },
  });

  const { data: listMembershipData } = useListFirmMembershipsQuery({
    variables: currentUserProIdVariables,
    fetchPolicy: 'cache-and-network',
    skip: isNonPro || !currentUserData?.currentUser?.id,
  });

  React.useEffect(() => {
    if (Platform.OS === 'web') {
      const focusFetch = () => refetch();
      window.addEventListener('focus', focusFetch);
      return () => window.removeEventListener('focus', focusFetch);
    }
  }, []);

  // Mark as delivered mutation
  const [markAsDelivered] = useMarkMessageDeliveredMutation();

  // New messages subscription
  useNewMessagesSubscription({
    onSubscriptionData: ({ client, subscriptionData }) => {
      const newMessage = subscriptionData?.data?.newMessage;
      if (!!newMessage) {
        try {
          markAsDelivered({
            variables: {
              input: {
                conversationId: newMessage.conversationId,
                messageId: newMessage.id,
              },
            },
          });
        } catch (e) {
          // TODO: Add proper error handler / tracker
          console.log(e); // tslint:disable-line
        }
        try {
          newMessagesUpdateMessages(client, subscriptionData?.data);
        } catch (e) {
          // tslint:disable-next-line
          console.log(e);
        }
      }
    },
  });

  // Message Update subscription
  useMessageUpdateSubscription({
    onSubscriptionData: ({ client, subscriptionData }) => {
      const messageUpdate = subscriptionData?.data?.messageUpdate;
      if (!!messageUpdate) {
        try {
          if (location.pathname.indexOf('/conversations/') < 0) {
            messageUpdateUpdateMessages(client, subscriptionData.data);
          }
        } catch (e) {
          // tslint:disable-next-line
          console.log(e);
        }
      }
    },
  });

  useOnConversationUpdateSubscription({
    onSubscriptionData: ({ client, subscriptionData }) => {
      const conversationUpdate = subscriptionData?.data?.conversationUpdate;
      if (!!conversationUpdate) {
        conversationUpdateUpdate(
          client,
          subscriptionData?.data,
          currentUserData?.currentUser?.id,
        );
      }
    },
  });

  const offlineStatus = useDetectOffline(true);

  const drawerLeft = React.useRef<DrawerLayoutAndroid>(null);
  const drawerRight = React.useRef<DrawerLayoutAndroid>(null);

  const notificationsDrawer = React.useRef<DrawerLayoutAndroid>(null);

  const toggleSideVisibility = () => {
    if (activeMenu === NO_MENU && drawerLeft.current) {
      drawerLeft.current.openDrawer();
    }
    setActiveMenu(getSideVisibility(activeMenu));
  };

  const prevOnlineStatus = usePrevious(offlineStatus);

  const {
    notificationsVisible,
    setNotificationsVisible,
    addAlert,
  } = React.useContext(NotificationContext);

  React.useEffect(() => {
    if (offlineStatus === 'offline') {
      addAlert({
        text: 'you have lost internet connection',
        type: AlertTypes.ERROR,
        duration: 0,
      });
    }
    if (offlineStatus === 'online' && prevOnlineStatus === 'offline') {
      addAlert({
        text: 'you now have internet connection',
        type: AlertTypes.SUCCESS,
        duration: 0,
      });
    }
  }, [offlineStatus]);

  const toggleNotificationVisibility = () => {
    if (notificationsVisible && notificationsDrawer.current) {
      notificationsDrawer.current.closeDrawer();
    } else if (!notificationsVisible && notificationsDrawer.current) {
      notificationsDrawer.current.openDrawer();
    }
    setNotificationsVisible(!notificationsVisible);
  };

  const [showNotesForm, setShowNotesForm] = React.useState<boolean>(false);

  const firmMembership = useGetFirmMembership();

  const setTitleHighlights = (highlights: HighlightCountsPayload) => {
    if (Platform.OS === 'web') {
      const totalHighlights = !isNonPro
        ? highlights.clientAssignmentCount +
          highlights.clientCount +
          highlights.messageCount +
          highlights.matterCount +
          highlights.keyDateCount +
          (firmMembership?.role === 'ADMINISTRATOR'
            ? highlights?.firmClientCount
            : 0) +
          (firmMembership?.role === 'ADMINISTRATOR'
            ? highlights?.firmMemberCount
            : 0) +
          highlights?.noteCount +
          highlights?.contactCount
        : highlights.messageCount +
          highlights.matterCount +
          highlights.keyDateCount +
          highlights?.contactCount;
      document.title = `${
        totalHighlights > 0 ? `(${totalHighlights}) ` : ''
      }transparently${document.title.split('transparently')?.[1]}`;
    }
  };

  const { data: highlightData } = useGetHighlightCountsQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => setTitleHighlights(data?.highlightCounts),
  });

  useHighlightChangeSubscription({
    onSubscriptionData: ({ client, subscriptionData }) => {
      highlightChangeUpdate(client, subscriptionData?.data);
      const highlights = subscriptionData?.data?.highlightChange;
      setTitleHighlights(highlights);
    },
  });

  const MenuItems: MenuItem[] = [
    {
      name: 'dashboard',
      icon: () => <DashboardIcon />,
      location: '/',
      activeMatch: (location) => location.indexOf('dashboard') > -1,
    },
    {
      name: 'client list',
      icon: () => <ClientsIcon />,
      location: '/clients',
      hiddenFromRoles: ['Client'],
      activeMatch: (location) =>
        location.indexOf('clients') > -1 &&
        location.indexOf('notes') === -1 &&
        location.indexOf('firm-administration') === -1 &&
        location.indexOf('key-dates') === -1 &&
        location.indexOf('matters') === -1 &&
        location.split('/')?.[1] !== 'conversations',
      highlight:
        (highlightData?.highlightCounts?.clientAssignmentCount ?? 0) +
        (highlightData?.highlightCounts?.clientCount ?? 0),
    },
    {
      name: 'conversations',
      icon: () => (
        <ConversationsIcon width={17} height={17.31} background="transparent" />
      ),
      location: '/conversations',
      activeMatch: (location) =>
        location.indexOf('conversations') > -1 &&
        location.split('/')?.[1] !== 'clients' &&
        location.split('/')?.[1] !== 'firm-administration',
      highlight: highlightData?.highlightCounts?.messageCount ?? 0,
    },
    {
      name: isNonPro ? 'matters' : 'client matters',
      icon: () => <MattersIcon />,
      location: '/matters',
      activeMatch: (location) => location.indexOf('matters') > -1,
      highlight: highlightData?.highlightCounts?.matterCount ?? 0,
    },
    {
      name: 'key dates',
      icon: () => <KeyDatesIcon />,
      location: '/key-dates',
      activeMatch: (location) => location.indexOf('key-dates') > -1,
      highlight: highlightData?.highlightCounts?.keyDateCount ?? 0,
    },
    {
      name: 'notes',
      icon: () => <NotesIcon />,
      location: '/notes',
      hiddenFromRoles: ['Client'],
      action: () => setShowNotesForm(true),
      activeMatch: (location) => location.indexOf('notes') > -1,
      highlight: highlightData?.highlightCounts?.noteCount ?? 0,
    },
    {
      name: 'file manager',
      icon: () => <FilesIcon width={25.56} height={21.79} />,
      location: '/file-manager',
      activeMatch: (location) => location.indexOf('file-manager') > -1,
    },
    {
      name: 'contacts',
      icon: () => <ContactsIcon />,
      location: '/contacts',
      activeMatch: (location) => location.indexOf('contacts') > -1,
      highlight: highlightData?.highlightCounts?.contactCount ?? 0,
    },
    {
      name: 'task library',
      icon: () => <TaskLibraryIcon />,
      location: '/task-library',
      hiddenFromRoles: ['Client'],
      activeMatch: (location) => location.indexOf('task-library') > -1,
      highlight: 0,
    },
    {
      name: 'firm administration',
      icon: () => <FirmAdministrationIcon />,
      location: '/firm-administration',
      hiddenFromRoles: ['Client', 'Professional'],
      showToRoles: ['Firm Administrator'],
      activeMatch: (location) =>
        location.indexOf('firm-administration') > -1 &&
        location.indexOf('notes') === -1 &&
        location.indexOf('key-dates') === -1 &&
        location.indexOf('matters') === -1,
      highlight:
        (highlightData?.highlightCounts?.firmClientCount ?? 0) +
        (highlightData?.highlightCounts?.firmMemberCount ?? 0),
    },
  ];

  const notifications = (
    <NotificationsList
      visible={notificationsVisible}
      toggleVisible={() => {
        setNotificationsVisible(false);
        if (notificationsDrawer.current) {
          notificationsDrawer.current.closeDrawer();
        }
      }}
    />
  );

  const { data: listIssuesData } = useListIssuesQuery({
    variables: {
      clientId: currentUserData?.currentUser?.id,
    },
    fetchPolicy: 'cache-and-network',
    skip: !isNonPro,
  });

  const profileComplete = useHasCompletedProfile();

  // Side menu component
  const sideMenu = (
    <SideMenu
      menuItems={MenuItems}
      toggleVisibility={() => {
        setActiveMenu(NO_MENU);
        if (drawerLeft.current) {
          drawerLeft.current.closeDrawer();
        }
      }}
      notificationsVisibility={notificationsVisible}
      toggleNotificationsVisibility={toggleNotificationVisibility}
    />
  );

  const separationIssue = listIssuesData?.issues?.find(
    (issue) => issue.type === IssueType.Separation,
  );

  const hasOpponent =
    !!separationIssue?.opponent?.firstName &&
    !!separationIssue?.opponent?.lastName &&
    !!separationIssue?.opponent?.emailAddress;

  React.useEffect(() => {
    if (justLoggedIn && isNonPro) {
      if (!profileComplete) {
        history.push('/profile-settings');
        updateAllBreadcrumbs([
          {
            name: 'settings',
            to: '/profile-settings',
          },
        ]);
      }
      if (profileComplete && !!separationIssue && !hasOpponent) {
        history.push('/profile-settings/issue-management');
        updateAllBreadcrumbs([
          {
            name: 'settings',
            to: '/profile-settings',
          },
        ]);
      }
    }
    if (!isNonPro && isFirstLogin) {
      history.push('/profile-settings?firstLogin=true');
      updateAllBreadcrumbs([
        {
          name: 'settings',
          to: '/profile-settings',
        },
      ]);
    }
  }, [profileComplete, hasOpponent]);

  // Main View component
  const mainView = (
    <View
      style={[contentStyles, { backgroundColor: themeColours.mainBackground }]}
    >
      <Header
        sideVisibility={
          width > screenSizes.medium ? false : activeMenu === SIDE_MENU
        }
        toggleSideVisibility={toggleSideVisibility}
        notificationsVisibility={notificationsVisible}
        toggleNotificationsVisibility={toggleNotificationVisibility}
      />
      {isNonPro &&
        !!separationIssue &&
        !hasOpponent &&
        profileComplete &&
        location.pathname.indexOf('/profile-settings') === -1 && (
          <AttentionBanner
            imageUri={currentUserData?.currentUser?.picture}
            text="missing other party information"
            secondaryText="requires your attention"
            onPress={() => history.push('/profile-settings/issue-management')}
          />
        )}
      {isNonPro &&
        !profileComplete &&
        location.pathname.indexOf('/profile-settings') === -1 && (
          <AttentionBanner
            imageUri={currentUserData?.currentUser?.picture}
            text="missing profile information"
            secondaryText="requires your attention"
            onPress={() => history.push('/profile-settings')}
          />
        )}
      {children}
    </View>
  );

  // Context menu component
  const contextMenu = (
    <ContextAside
      notificationsVisibility={notificationsVisible}
      toggleNotificationsVisibility={toggleNotificationVisibility}
    />
  );

  function onSwipeRight() {
    if (drawerLeft.current && activeMenu === NO_MENU) {
      drawerLeft.current.openDrawer();
    }
    if (drawerRight.current && activeMenu === CONTEXT_MENU) {
      drawerRight.current.closeDrawer();
    }
    setActiveMenu(getRightSwipeMenu(activeMenu));

    Keyboard.dismiss();
    // }
  }

  function onSwipeLeft() {
    if (drawerRight.current && activeMenu === NO_MENU) {
      drawerRight.current.openDrawer();
    }
    if (drawerLeft.current && activeMenu === SIDE_MENU) {
      drawerLeft.current.closeDrawer();
    }
    Keyboard.dismiss();

    setActiveMenu(getLeftSwipeMenu(activeMenu));
  }

  const [showSlider, setShowSlider] = React.useState<boolean>(true);

  let timeout = React.useRef<NodeJS.Timeout>().current;

  const endTimeout = () => {
    if (timeout) clearTimeout(timeout);
    if (!showSlider) setShowSlider(true);
  };
  const startTimeout = () => {
    endTimeout();
    timeout = setTimeout(() => setShowSlider(false), 6000);
  };

  React.useEffect(() => () => clearTimeout(timeout), []);

  const content = (
    <>
      <Animated.View
        style={[
          LayoutStyles.main,
          { backgroundColor: themeColours.mainBackground },
          {
            transform: [
              {
                translateX:
                  width < screenSizes.medium && Platform.OS !== 'android'
                    ? menuX
                    : 0,
              },
            ],
          },
        ]}
        onTouchStart={Platform.OS !== 'web' ? endTimeout : undefined}
        onTouchEnd={Platform.OS !== 'web' ? startTimeout : undefined}
      >
        {Platform.OS !== 'android' ? (
          <>
            {sideMenu}
            <InsituConversationContextProvider>
              {mainView}
              {contextMenu}
            </InsituConversationContextProvider>
            {notifications}
          </>
        ) : (
          <DrawerLayoutAndroid
            drawerWidth={width}
            ref={notificationsDrawer}
            // @ts-ignore
            drawerPosition="right"
            renderNavigationView={() => notifications}
            drawerLockMode="locked-open"
          >
            <DrawerLayoutAndroid
              drawerWidth={300}
              ref={drawerRight}
              // @ts-ignore
              drawerPosition="right"
              renderNavigationView={() => contextMenu}
              onDrawerOpen={() => {
                setActiveMenu(CONTEXT_MENU);
              }}
              onDrawerClose={() => {
                setActiveMenu(NO_MENU);
              }}
            >
              <DrawerLayoutAndroid
                drawerWidth={300}
                ref={drawerLeft}
                // @ts-ignore
                drawerPosition="left"
                renderNavigationView={() => sideMenu}
                onDrawerOpen={() => {
                  setActiveMenu(SIDE_MENU);
                }}
                onDrawerClose={() => {
                  setActiveMenu(NO_MENU);
                }}
              >
                {mainView}
              </DrawerLayoutAndroid>
            </DrawerLayoutAndroid>
          </DrawerLayoutAndroid>
        )}
      </Animated.View>
    </>
  );

  const flingContent =
    Platform.OS !== 'web' ? (
      <FlingGestureHandler
        onHandlerStateChange={({ nativeEvent }) => {
          if (nativeEvent.state === State.END && Platform.OS !== 'web') {
            onSwipeLeft();
          }
        }}
        direction={Directions.LEFT}
      >
        <FlingGestureHandler
          onHandlerStateChange={({ nativeEvent }) => {
            if (nativeEvent.state === State.END && Platform.OS !== 'web') {
              onSwipeRight();
            }
          }}
          direction={Directions.RIGHT}
        >
          {content}
        </FlingGestureHandler>
      </FlingGestureHandler>
    ) : (
      content
    );

  return currentUserData?.currentUser?.id &&
    (isNonPro || !!listMembershipData?.firmMemberships?.[0]?.firm?.id) ? (
    <>
      {flingContent}
      {width < screenSizes.medium && displayMenuSlider && showSlider && (
        <MobileMenuSlider drawerLeft={drawerLeft} drawerRight={drawerRight} />
      )}
      {overlayComponent && overlayTitle && (
        <ContextOverlay title={overlayTitle} component={overlayComponent} />
      )}
      {showNotesForm && (
        <NoteForm visible={showNotesForm} setVisible={setShowNotesForm} />
      )}
    </>
  ) : (
    <View style={[LayoutStyles.loader]}>
      <LoadingView />
    </View>
  );
};
export default Layout;

const LayoutStyles = StyleSheet.create({
  wrapper: {
    height: '100%',
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  content: {
    flex: 1,
    height: '100%',
    zIndex: 2,
    shadowOffset: {
      width: 3,
      height: 0,
    },
    shadowOpacity: 0.04,
    shadowRadius: 10,
    elevation: 3,
  },
  contentMobile: {
    flex: 1,
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    position: 'absolute',
    transform: [{ translateX: 0 }],
    shadowOffset: {
      width: 3,
      height: 0,
    },
    shadowOpacity: 0.04,
    shadowRadius: 10,
    elevation: 3,
  },
  loader: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
