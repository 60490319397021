import deburr from 'lodash.deburr';
import widthsMap from './widthsMap';

const settingsDefaults = { font: 'Quicksand-Medium', size: 100 };

const getCharWidth = (
  char: string,
  settings: {
    font: string;
    size: number;
    bold?: boolean;
    italic?: boolean;
  },
): number => {
  const font = settings.font.toLowerCase();
  const variant = 0 + (settings.bold ? 1 : 0) + (settings.italic ? 2 : 0);
  const map = widthsMap;
  const available = Object.keys(map);
  if (available.indexOf(font) === -1) {
    throw new Error(
      `This font is not supported. Supported fonts are: ${available.join(
        ', ',
      )}`,
    );
  }
  if (/[\x00-\x1F]/.test(char)) {
    // non-printable character
    return 0;
  }
  // use width of 'x' as fallback for unregistered char
  // @ts-ignore
  const widths = map[font][char] || map[font].x;
  const width = widths[variant];
  return width;
};

/**
 * @param {string} text
 * @param {number} maxLineWidth
 * @returns {number}
 */
export function getLineCount(
  text: string,
  settings: {
    font: string;
    size: number;
    bold?: boolean;
    italic?: boolean;
    lineWidth: number;
  },
): number {
  const sett = { ...settingsDefaults, ...settings };

  return text.split(/\n/).reduce((lineCount, line) => {
    const spaceWidth = getCharWidth(' ', sett) * (settings.size / 100);
    let total = 1;
    let currentLineWidth = -spaceWidth;

    line.split(' ').forEach((word) => {
      let width = word
        .split('')
        .reduce(
          (wordWith, character) =>
            wordWith + getCharWidth(character, sett) * (settings.size / 100),
          0,
        );

      if (width > 0) {
        width += spaceWidth;
      }

      if (width > settings.lineWidth) {
        const wordLines = Math.floor(width / settings.lineWidth);
        total += wordLines;
        width = width * (width / settings.lineWidth - wordLines);
        currentLineWidth += width;
      } else {
        currentLineWidth += width;
      }

      if (currentLineWidth >= settings.lineWidth) {
        total += 1;
        currentLineWidth = width;
      }
    });

    // let lineWidth =
    //   line
    //     .split('')
    //     .reduce(
    //       (lineWidth, character) =>
    //         lineWidth + getCharWidth(character, sett) * (settings.size / 100),
    //       0,
    //     ) || 1;

    return lineCount + total;
  }, 0);
}

/**
 * Get Width - Get the width of a string
 *
 * @param {string} string
 * @param {{ font: string; size: number; bold?: boolean; italic?: boolean }} settings
 * @returns {number} the width of the string
 */
const getWidth = (
  // tslint:disable-next-line
  string: string,
  settings: {
    font: string;
    size: number;
    bold?: boolean;
    italic?: boolean;
    lineWidth?: number;
  },
) => {
  const sett = { ...settingsDefaults, ...settings };
  const size = sett.size;

  let totalWidth = 0;
  let lineCount = 1;
  // const linesToRemove = string.matchAll(/(?!^)(\n)\w/).length;
  // lineCount -= linesToRemove;
  const wordWidths = [];

  if (!!settings.lineWidth) {
    const words = deburr(string).split(/\s+/);

    words.forEach((word) => {
      let wordWidth = 0;

      `${word} `.split('').forEach((char) => {
        wordWidth += getCharWidth(char, sett);
        // if (char.match(/\n/g)) {
        //   lineCount += 1;
        // }
      });

      wordWidths.push(wordWidth * (size / 100));
      return true;
    });

    let currWidth = 0;

    wordWidths.forEach((width, index) => {
      // TODO: handle multiple words that are longer than
      // HOWTO: (splice the long word into multiple words and push into words array)
      if (width > settings.lineWidth) {
        const lines = width / settings.lineWidth;
        const minLines = Math.floor(lines);

        lineCount += minLines;

        currWidth += Math.round(width * (lines - minLines));

        return true;
      }

      if (currWidth === 0 && index !== 0 && index - 1 > 0) {
        currWidth += wordWidths[index - 1];
      }
      if (currWidth + width < settings.lineWidth) {
        currWidth += width;
      } else {
        lineCount += 1;
        currWidth = 0;
      }
      return true;
    });
  }

  deburr(string)
    .split('')
    .forEach((char) => {
      totalWidth += getCharWidth(char, sett);
      return true;
    });

  return { totalWidth: totalWidth * (size / 100), wordWidths, lineCount };
};

export default getWidth;
