import { DataProxy } from 'apollo-cache';
import { formatISO } from 'date-fns/esm';
import produce from 'immer';
import {
  ListNotificationsDocument,
  ListNotificationsQuery,
  ListNotificationsQueryVariables,
} from '../../types/apolloTypes';

// Update Cache Function to update after pause mutation
export default function markAllNotificationsReadUpdate(cache: DataProxy) {
  // Try and update question query
  try {
    const listNotificationsData = cache.readQuery<
      ListNotificationsQuery,
      ListNotificationsQueryVariables
    >({
      query: ListNotificationsDocument,
    });

    const nextNotificationState = produce(
      listNotificationsData,
      (draftState) => {
        draftState.notifications.totalUnread = 0;

        draftState.notifications.notifications = draftState.notifications.notifications.map(
          (notification) => {
            return {
              ...notification,
              readAt: formatISO(Date.now()),
              isRead: true,
            };
          },
        );
      },
    );

    cache.writeQuery({
      query: ListNotificationsDocument,
      data: nextNotificationState,
    });
  } catch (error) {
    if (error?.name !== 'Invariant Violation') {
      throw new Error(error);
    }
  }
}
