import React, { createContext } from 'react';
import { useImmer } from 'use-immer';
import InsituConversationWrapper from './InsituConversationWrapper';

export interface InsituConversationContext {
  showConversation: boolean;
  updateProps: (
    f: (draft: InsituConversationContext) => void | InsituConversationContext,
  ) => void;
  conversationId: string;
  clientAccountId?: string;
}

const defaultInsituConversationContextProps: InsituConversationContext = {
  showConversation: false,
  updateProps: () => null,
  conversationId: '',
  clientAccountId: '',
};

export const InsituConversationContext = createContext<InsituConversationContext>(
  defaultInsituConversationContextProps,
);

const InsituConversationContextProvider: React.FunctionComponent = ({
  children,
}) => {
  const [props, updateProps] = useImmer(defaultInsituConversationContextProps);
  return (
    <InsituConversationContext.Provider value={{ ...props, updateProps }}>
      {children}
      {props.showConversation && (
        <InsituConversationWrapper
          updateProps={updateProps}
          conversationId={props.conversationId}
          clientAccountId={props.clientAccountId}
        />
      )}
    </InsituConversationContext.Provider>
  );
};

export default InsituConversationContextProvider;
