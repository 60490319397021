import { DataProxy } from 'apollo-cache';
import produce from 'immer';
import {
  ListNotificationsDocument,
  ListNotificationsQuery,
  ListNotificationsQueryVariables,
  MarkNotificationReadMutation,
  MarkNotificationReadMutationVariables,
} from '../../types/apolloTypes';

// Update Cache Function to update after pause mutation
export default function markNotificationReadUpdate(
  cache: DataProxy,
  data: MarkNotificationReadMutation,
  variables: MarkNotificationReadMutationVariables,
) {
  // Try and update question query
  try {
    const listNotificationsData = cache.readQuery<
      ListNotificationsQuery,
      ListNotificationsQueryVariables
    >({
      query: ListNotificationsDocument,
    });

    const nextNotificationState = produce(
      listNotificationsData,
      (draftState) => {
        draftState.notifications.totalUnread =
          draftState.notifications.totalUnread - 1;

        draftState.notifications.notifications = draftState.notifications.notifications.map(
          (notification) => {
            if (notification.id === variables.input.notificationId) {
              return {
                ...notification,
                readAt: data.markNotificationRead.readAt,
                isRead: true,
              };
            }
            return notification;
          },
        );
      },
    );

    cache.writeQuery({
      query: ListNotificationsDocument,
      data: nextNotificationState,
    });
  } catch (error) {
    if (error?.name !== 'Invariant Violation') {
      throw new Error(error);
    }
  }
}
