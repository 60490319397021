import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { Path, Rect, Svg } from 'react-native-svg';
import { blue, white } from '../../configs/colours';

interface Props {
  fill?: string;
  secondaryFill?: string;
  height?: number;
  width?: number;
  style?: StyleProp<ViewStyle>;
}

const DocumentFileIcon: React.FunctionComponent<Props> = ({
  fill = blue,
  secondaryFill = white,
  width = 15.71,
  height = 17.68,
  style,
}) => {
  return (
    <Svg height={height} viewBox="0 0 15.71 17.68" width={width} style={style}>
      <Rect y="1.96" width="15.71" height="15.71" rx="2" fill="none" />
      <Rect
        x="0.5"
        y="2.46"
        width="14.71"
        height="14.71"
        rx="1.5"
        fill="none"
        stroke={fill}
        strokeLinejoin="round"
      />
      <Path
        d="M4.09,7.33A1.51,1.51,0,0,1,2.58,5.82V1.51a1.51,1.51,0,1,1,3,0V2.59a.43.43,0,0,1-.86,0V1.51a.65.65,0,0,0-1.3,0V5.82a.65.65,0,0,0,1.3,0V4.62a.43.43,0,1,1,.86,0h0v1.2A1.51,1.51,0,0,1,4.09,7.33Z"
        transform="translate(0 0)"
        fill={fill}
      />
      <Path
        d="M12.13,10.68H4a.43.43,0,0,1-.43-.43A.43.43,0,0,1,4,9.82h8.14a.43.43,0,0,1,.43.43.43.43,0,0,1-.43.43Z"
        transform="translate(0 0)"
        fill={secondaryFill}
      />
      <Path
        d="M12.13,13.25H4a.43.43,0,0,1-.43-.43A.43.43,0,0,1,4,12.39h8.14a.43.43,0,0,1,.43.43.43.43,0,0,1-.43.43Z"
        transform="translate(0 0)"
        fill={secondaryFill}
      />
    </Svg>
  );
};

export default DocumentFileIcon;
