import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { white } from '../../configs/colours';

interface Props {
  fill?: string;
  width?: number;
  height?: number;
}

const ChevronLeftIcon: React.FunctionComponent<Props> = ({
  fill = white,
  height = 10.007,
  width = 6.417,
}) => {
  return (
    <Svg height={height} viewBox="0 0 6.417 10.007" width={width}>
      <Path
        d="m-773 6566.007-4-4 4-4"
        fill="none"
        stroke={fill}
        strokeLinecap="round"
        strokeWidth={2}
        transform="translate(778.414 -6556.999)"
      />
    </Svg>
  );
};
export default ChevronLeftIcon;
