import { ApolloClient } from 'apollo-client';
import produce from 'immer';
import {
  GetHighlightCountsDocument,
  GetHighlightCountsQuery,
  GetHighlightCountsQueryVariables,
  HighlightChangeSubscription,
} from '../../types/apolloTypes';

export function highlightChangeUpdate(
  client: ApolloClient<object>,
  notificationData: HighlightChangeSubscription,
) {
  const cache = client.cache;
  const highlights = notificationData?.highlightChange;
  // Update message list query with new message

  try {
    const getHighlightData = cache.readQuery<
      GetHighlightCountsQuery,
      GetHighlightCountsQueryVariables
    >({
      query: GetHighlightCountsDocument,
    });

    const nextHighlightState = produce(getHighlightData, (draftState) => {
      draftState.highlightCounts = highlights;
    });

    client.writeQuery({
      query: GetHighlightCountsDocument,
      data: nextHighlightState,
    });
  } catch (error) {
    if (error?.name !== 'Invariant Violation') {
      throw new Error(error);
    }
  }
}
