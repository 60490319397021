import { DataProxy } from 'apollo-cache';
import produce from 'immer';
import {
  DraftNoteMutation,
  ListAuthoredNotesQuery,
  ListAuthoredNotesQueryVariables,
} from '../../types/apolloTypes';
import { ListAuthoredNotesDocument } from './../../types/apolloTypes';

// Update Cache Function to update after pause mutation
export default function draftNoteUpdate(
  cache: DataProxy,
  data: DraftNoteMutation,
  currentUserId: string,
) {
  // Try and update question query
  try {
    const listAuthoredNotesQueryData = cache.readQuery<
      ListAuthoredNotesQuery,
      ListAuthoredNotesQueryVariables
    >({
      query: ListAuthoredNotesDocument,
      variables: {
        authorId: currentUserId,
        page: 1,
        pageSize: 50,
      },
    });

    const nextAuthoredNotesState = produce(
      listAuthoredNotesQueryData,
      (draftState) => {
        if (draftState?.authoredNotes?.items && data?.draftNote) {
          draftState.authoredNotes.items = [
            ...draftState.authoredNotes.items,
            data.draftNote,
          ];
        }
      },
    );

    cache.writeQuery({
      query: ListAuthoredNotesDocument,
      data: nextAuthoredNotesState,
      variables: {
        authorId: currentUserId,
        page: 1,
        pageSize: 50,
      },
    });
  } catch (error) {
    if (error?.name !== 'Invariant Violation') {
      throw new Error(error);
    }
  }
}
