import React from 'react';
import { View, ActivityIndicator, StyleSheet } from 'react-native';
import { purple } from '../configs/colours';

const LoadingView: React.FunctionComponent = () => {
  return (
    <View style={LoadingViewStyles.main}>
      <ActivityIndicator size="large" color={purple} />
    </View>
  );
};
export default LoadingView;

const LoadingViewStyles = StyleSheet.create({
  main: {
    flex: 1,
    alignContent: 'center',
    justifyContent: 'center',
  },
});
