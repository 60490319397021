import { Notifications } from 'expo';
import Constants from 'expo-constants';
import * as Permissions from 'expo-permissions';
import { Notification as NotificationType } from 'expo/build/Notifications/Notifications.types';
import isElectron from 'is-electron';
import React, { useCallback, useMemo, useState } from 'react';
import {
  Platform,
  StyleSheet,
  useWindowDimensions,
  Vibration,
  View,
} from 'react-native';
import { v4 as uuid } from 'uuid';
import screenSizes from '../configs/screenSizes';
import useUpdateBreadcrumbs from '../hooks/useUpdateBreadcrumbs';
import useIsNonPro from '../hooks/usIsNonPro';
import { newNotificationUpdate } from '../subscriptions/updates/NewNotification';
import {
  useNewNotificationsSubscription,
  useRegisterPushInterestMutation,
  useRevokePushInterestMutation,
} from '../types/apolloTypes';
import { NotificationsNotification } from '../types/Notifications';
import { useHistory, useLocation } from '../utils/routing';
import Alert, { AlertProps } from './Alert';
import InAppNotification, { NotificationProps } from './Notification';
import { NotificationContext } from './NotificationContext';
import { getLink, getLinkName } from './NotificationListItem';

interface Props {}

function stringToHash(string) {
  let hash = 0;

  if (string.length == 0) return hash;

  for (let i = 0; i < string.length; i++) {
    const char = string.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }

  return hash;
}

const NotificationProvider: React.FunctionComponent<Props> = ({ children }) => {
  const [notifications, setNotifications] = useState<
    Array<NotificationProps & NotificationsNotification>
  >([]);
  const [alerts, setAlerts] = useState<AlertProps[]>([]);
  const [totalUnread, setTotalUnread] = useState<number>(0);
  const [notificationsVisible, setNotificationsVisible] = useState<boolean>(
    false,
  );

  const location = useLocation();
  const history = useHistory();

  useNewNotificationsSubscription({
    onSubscriptionData: ({ client, subscriptionData }) => {
      newNotificationUpdate(client, subscriptionData?.data);
      if (!!subscriptionData?.data) {
        setTotalUnread(totalUnread + 1);
        if (isElectron()) {
          const { app } = window.require('electron').remote;
          app?.dock?.setBadge(`${totalUnread + 1}`);
        }

        if (
          Platform.OS === 'web' &&
          !isElectron() &&
          Notification?.permission !== 'granted' &&
          !!subscriptionData?.data
        ) {
          if (
            subscriptionData?.data?.newNotification?.context === 'messaging' &&
            location.pathname.indexOf('conversations') > -1
          ) {
            return;
          }
          addNotification({
            ...subscriptionData?.data?.newNotification,
            duration: 7000,
          });
        }
      }

      if (
        (isElectron() ||
          (Platform.OS === 'web' &&
            window?.Notification &&
            Notification?.permission === 'granted')) &&
        !!subscriptionData?.data
      ) {
        const notification = subscriptionData?.data?.newNotification;
        if (
          subscriptionData?.data?.newNotification?.context === 'messaging' &&
          location.pathname.indexOf('conversations') > -1
        ) {
          return;
        }
        const theNotification = new Notification(notification?.title, {
          body: notification?.body,
          ...(notification?.pictureUrl && { image: notification?.pictureUrl }),
        });
        theNotification.onclick = () => {
          history.push(getLink(notification?.type, notification?.path));
        };
      }
    },
  });

  const [registerInterest] = useRegisterPushInterestMutation();
  const [revokeInterest] = useRevokePushInterestMutation();

  const alertsJSON = JSON.stringify(alerts);
  const notificationsJSON = JSON.stringify(notifications);

  const [pushToken, setPushToken] = useState<string>();

  const registerForPushNotificationsAsync = async () => {
    if (Platform.OS === 'web') return;
    if (Constants.isDevice) {
      const { status: existingStatus } = await Permissions.getAsync(
        Permissions.NOTIFICATIONS,
      );
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await Permissions.askAsync(
          Permissions.NOTIFICATIONS,
        );
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        alert('Failed to get push token for push notification!');
        return;
      }
      const token = await Notifications.getExpoPushTokenAsync();
      await registerInterest({
        variables: {
          input: {
            pushToken: token,
          },
        },
      });
      setPushToken(token);
    } else {
      alert('Must use physical device for Push Notifications');
    }

    if (Platform.OS === 'android') {
      Notifications.createChannelAndroidAsync('default', {
        name: 'default',
        sound: true,
        priority: 'max',
        vibrate: [0, 250, 250, 250],
      });
    }
  };

  const isNonPro = useIsNonPro();

  const { updateAllBreadcrumbs } = useUpdateBreadcrumbs();

  const _handleNotification = useCallback(
    (notification: NotificationType) => {
      if (notification.origin === 'received') {
        if (location.pathname.indexOf('conversations') === -1) {
          Vibration?.vibrate(1);
        }
      }
      if (notification.origin === 'selected' && !!notification.data?.path) {
        const link = getLink(
          notification.data.type,
          notification.data?.path,
          isNonPro,
        );
        history.push(link);
        updateAllBreadcrumbs([
          {
            to: link,
            name: getLinkName(
              notification.data.type,
              notification.data?.path,
              isNonPro,
            ),
          },
        ]);
      }
      // this.setState({ notification: notification });
    },
    [notificationsJSON],
  );

  React.useEffect(() => {
    registerForPushNotificationsAsync();

    if (!!pushToken) {
      return () =>
        revokeInterest({
          variables: {
            input: {
              pushToken,
            },
          },
        });
    }
  }, []);

  React.useEffect(() => {
    Notifications.addListener(_handleNotification);
  }, [notificationsJSON]);

  const addAlert = useCallback(
    (alert: AlertProps) =>
      setAlerts([
        ...alerts,
        {
          id: uuid(),
          text: '',
          type: 'INFO',
          duration: 1000,
          onClose: () => null,
          onPress: () => null,
          ...alert,
        },
      ]),
    [alertsJSON],
  );

  const alertsHash = useMemo(() => stringToHash(alertsJSON), [alertsJSON]);
  const notificationsHash = useMemo(() => stringToHash(notificationsJSON), [
    notificationsJSON,
  ]);

  const removeAlert = useCallback(
    (id: string) => setAlerts(alerts.filter((alert) => alert.id !== id)),
    [alertsJSON],
  );

  const addNotification = useCallback(
    (notification: NotificationProps & NotificationsNotification) =>
      setNotifications([...notifications, notification]),
    [notificationsJSON],
  );

  const removeNotification = useCallback(
    (id: string) =>
      setNotifications(
        notifications.filter((notification) => notification.id !== id),
      ),
    [notificationsJSON],
  );

  const { width } = useWindowDimensions();

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        alerts,
        addAlert,
        removeAlert,
        addNotification,
        removeNotification,
        totalUnread,
        setTotalUnread,
        notificationsVisible,
        setNotificationsVisible,
      }}
    >
      {children}
      <View
        style={[
          NotificationProviderStyle.notificationsWrap,
          width > screenSizes.medium &&
            NotificationProviderStyle.notificationsWrapDesktop,
          width <= screenSizes.medium && {
            width: width - 20,
          },
        ]}
      >
        {notifications.map((notification) => (
          <InAppNotification
            key={notification.id}
            {...notification}
            removeNotification={removeNotification}
            hash={notificationsHash}
          />
        ))}
      </View>
      <View
        style={[
          NotificationProviderStyle.alertsWrap,
          {
            left: width > screenSizes.medium ? width / 2 - 250 : 10,
            width: width > screenSizes.medium ? 500 : width - 20,
          },
        ]}
      >
        {alerts.map((alert) => (
          <Alert
            key={alert.id}
            {...alert}
            removeAlert={removeAlert}
            hash={alertsHash}
          />
        ))}
      </View>
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;

const NotificationProviderStyle = StyleSheet.create({
  notificationsWrap: {
    position: 'absolute',
    top: 80,
    right: 10,
    alignItems: 'center',
  },
  notificationsWrapDesktop: {
    top: 30,
    right: 30,
  },
  alertsWrap: {
    position: 'absolute',
    width: 500,
    top: 120,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
