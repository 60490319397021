import {
  useGetCurrentUserQuery,
  useResumeConversationMutation,
  ResumeConversationMutationVariables,
} from '../types/apolloTypes';
import { formatISO } from '../utils/dateFns';
import resumeConversationUpdate from '../mutations/updates/ResumeConversation';

function useResumeConversation(conversationId: string) {
  const { data: currentUserData } = useGetCurrentUserQuery();

  const variables: ResumeConversationMutationVariables = {
    input: {
      conversationId,
    },
  };

  const [resumeConversation] = useResumeConversationMutation({
    variables,
    optimisticResponse: {
      resumeConversation: {
        __typename: 'Conversation',
        startedAt: formatISO(new Date()),
        pausedUntil: null,
        id: conversationId,
      },
    },
    update: (cache, { data }) =>
      resumeConversationUpdate(
        cache,
        data,
        variables,
        currentUserData?.currentUser?.id,
      ),
  });

  return resumeConversation;
}

export default useResumeConversation;
