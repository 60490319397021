import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { G, Path, Svg } from 'react-native-svg';
import { blue } from '../../configs/colours';

interface Props {
  fill?: string;
  secondaryFill?: string;
  height?: number;
  width?: number;
  style?: StyleProp<ViewStyle>;
}

const FilesIcon: React.FunctionComponent<Props> = ({
  fill = blue,
  width = 17.24,
  height = 16.2,
  style,
}) => {
  return (
    <Svg height={height} viewBox="0 0 17.24 16.2" width={width} style={style}>
      <G>
        <Path
          d="M4.5,1.5H15.24a2,2,0,0,1,2,2V9.7a2,2,0,0,1-2,2H6.5a2,2,0,0,1-2-2V1.5Z"
          fill="#383d3f"
        />
        <Path
          d="M5.75,2.12h9.49A1.38,1.38,0,0,1,16.62,3.5V9.7a1.38,1.38,0,0,1-1.38,1.37H6.5A1.38,1.38,0,0,1,5.12,9.7V2.75A.63.63,0,0,1,5.75,2.12Z"
          fill="none"
          stroke="#989d9f"
          strokeWidth="1.25"
        />
        <Path
          d="M5.5,0H7.62a1,1,0,0,1,1,1h0V2.25H4.5V1A1,1,0,0,1,5.5,0Z"
          fill="#979d9f"
        />
      </G>
      <G>
        <Path
          d="M2.25,3.75H13a2,2,0,0,1,2,2V12a2,2,0,0,1-2,2H4.25a2,2,0,0,1-2-2V3.75Z"
          fill="#383d3f"
        />
        <Path
          d="M3.5,4.37H13a1.38,1.38,0,0,1,1.38,1.38V12A1.37,1.37,0,0,1,13,13.32H4.25A1.38,1.38,0,0,1,2.87,12V5A.63.63,0,0,1,3.5,4.37Z"
          fill="none"
          stroke="#e7f3f8"
          strokeWidth="1.25"
        />
        <Path
          d="M3.25,2.25H5.37a1,1,0,0,1,1,1h0V4.5H2.25V3.25A1,1,0,0,1,3.25,2.25Z"
          fill="#e7f3f8"
        />
      </G>
      <G>
        <Path
          d="M0,6H10.74a2,2,0,0,1,2,2v6.2a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V6Z"
          fill="#383d3f"
        />
        <Path
          d="M1.25,6.62h9.49A1.38,1.38,0,0,1,12.12,8v6.2a1.38,1.38,0,0,1-1.38,1.37H2A1.39,1.39,0,0,1,.62,14.2V7.25A.63.63,0,0,1,1.25,6.62Z"
          fill="none"
          stroke={fill}
          strokeWidth="1.25"
        />
        <Path
          d="M1,4.5H3.12a1,1,0,0,1,1,1h0V6.75H0V5.5A1,1,0,0,1,1,4.5Z"
          fill={fill}
        />
      </G>
    </Svg>
  );
};

export default FilesIcon;
