import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { Path, Svg } from 'react-native-svg';
import { blue } from '../../configs/colours';

interface Props {
  fill?: string;
  secondaryFill?: string;
  height?: number;
  width?: number;
  style?: StyleProp<ViewStyle>;
}

const ClientsIcon: React.FunctionComponent<Props> = ({
  fill = blue,
  secondaryFill = '#e5f3f8',
  width = 20,
  height = 13,
  style,
}) => {
  return (
    <Svg height={height} viewBox="0 0 20 13" width={width} style={style}>
      <Path
        d="M5.49,5.67h0a2.9,2.9,0,0,0,2-3.13A2.86,2.86,0,0,0,4.82,0H4.16A2.9,2.9,0,0,0,3.5,5.67h0c-2,1-4.2,4.75-3.29,6.12C.75,12.6,2.6,13,4.5,13s3.76-.4,4.29-1.21C9.7,10.38,7.49,6.68,5.49,5.67ZM2.59,3A1.65,1.65,0,0,1,3.66,1.22a1.9,1.9,0,0,1,2.18.4,1.73,1.73,0,0,1,.43,2A1.69,1.69,0,0,1,4.53,4.78,1.82,1.82,0,0,1,3.15,4.2a1.61,1.61,0,0,1-.56-1.28Zm5.13,8.11c-.24.36-1.08.81-3.2.81s-3-.45-3.22-.81-.15-.62.45-1.78C2.6,7.67,3.62,6.72,4.49,6.72s1.9,1,2.77,2.57c.55,1.12.6,1.53.46,1.78Z"
        transform="translate(0)"
        fill={fill}
      />
      <Path
        d="M16.49,5.67h0A2.9,2.9,0,0,0,15.82,0h-.66a2.9,2.9,0,0,0-.66,5.67h0c-2,1.06-4.2,4.76-3.29,6.13.54.8,2.39,1.2,4.29,1.2s3.76-.4,4.29-1.2C20.7,10.39,18.49,6.68,16.49,5.67ZM13.59,3a1.64,1.64,0,0,1,1.07-1.74,1.89,1.89,0,0,1,2.18.39,1.73,1.73,0,0,1,.43,2,1.69,1.69,0,0,1-1.74,1.12,1.82,1.82,0,0,1-1.38-.58,1.6,1.6,0,0,1-.56-1.27Zm5.13,8.1c-.24.36-1.08.82-3.2.82s-3-.46-3.22-.82-.15-.62.45-1.78c.85-1.62,1.87-2.56,2.74-2.56s1.9.94,2.77,2.56c.55,1.12.6,1.53.46,1.78Z"
        transform="translate(0)"
        fill={secondaryFill}
      />
    </Svg>
  );
};

export default ClientsIcon;
