import React from 'react';
import { StyleSheet, View } from 'react-native';
import { blue } from '../configs/colours';

interface Props {
  top?: number;
  right?: number;
  relative?: boolean;
  marginRight?: number;
  marginLeft?: number;
}

const HighlightCircle: React.FunctionComponent<Props> = ({
  top = -4,
  right = -4,
  relative = false,
  marginRight = 0,
  marginLeft = 0,
}) => {
  return (
    <View
      style={[
        HighlightCircleStyle.main,
        top && { top },
        right && { right },
        relative && { position: 'relative' },
        marginRight && { marginRight },
        marginLeft && { marginLeft },
      ]}
    />
  );
};

export default HighlightCircle;

const HighlightCircleStyle = StyleSheet.create({
  main: {
    position: 'absolute',
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: blue,
  },
});
