import React, {
  ReactNode,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {
  Animated,
  Dimensions,
  Easing,
  Platform,
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
} from 'react-native';
import { black, blackRGBA, blue, white } from '../configs/colours';
import screenSizes from '../configs/screenSizes';
import {
  TimingAnimationConfig,
  useAnimationParallel,
} from '../hooks/useAnimation';
import ErrorBoundary from './ErrorBoundary';
import CloseIcon from './icons/CloseIcon';
import PlatformTouchable from './PlatformTouchable';
import { ThemeContext } from './ThemeProvider';

export interface ModalProps {
  title: string;
  content: ReactNode | string;
  cancelText?: string;
  cancelAction?: () => void;
  actionText?: string;
  action?: () => void;
  actionDisabled?: boolean;
  setShowModal?: (show: boolean) => void;
  showModal: boolean;
  size: 'small' | 'medium' | 'large';
}

const Modal: React.FunctionComponent<ModalProps> = ({
  title,
  content,
  actionText,
  cancelText,
  cancelAction,
  action,
  setShowModal,
  showModal,
  size = 'small',
  actionDisabled = false,
}) => {
  const { themeColours } = useContext(ThemeContext);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let timeout;
    if (showModal === true) setVisible(true);
    else {
      timeout = setTimeout(() => {
        setVisible(false);
      }, 600);
    }
    return () => clearTimeout(timeout);
  }, [showModal]);

  const sharedAnimProps: TimingAnimationConfig = {
    type: 'timing',
    initialValue: 0,
    duration: 300,
    useNativeDriver: Platform.OS !== 'web',
    toValue: showModal ? 1 : 0,
  };

  const [animatedValue, animatedValue2, animatedValue3] = useAnimationParallel(
    [
      {
        ...sharedAnimProps,
        delay: showModal ? 200 : 0,
      },
      {
        ...sharedAnimProps,
        duration: 400,
        easing: Easing.inOut(Easing.ease),
      },
      {
        ...sharedAnimProps,
        easing: Easing.in(Easing.ease),
        delay: showModal ? 500 : 0,
      },
    ],
    {},
    showModal,
  );

  const getModalWidth = (): {
    width: string | number;
    maxWidth?: string | number;
    minWidth?: string | number;
  } => {
    if (Dimensions.get('window').width <= screenSizes.medium) {
      return { width: '90%' };
    }
    switch (size) {
      case 'small':
        return { width: 400 };
      case 'medium':
        return { maxWidth: '90%', width: 640 };
      case 'large':
        return { maxWidth: '90%', width: 1200 };
    }
  };

  const contentWrap = useRef<View>();
  const [fullHeight, setFullHeight] = useState(false);
  const { height: pageHeight } = useWindowDimensions();

  useLayoutEffect(() => {
    if (contentWrap.current) {
      contentWrap.current.measure((_, __, ___, height) => {
        if (height > pageHeight - 180) {
          setFullHeight(true);
        }
      });
    }
  }, [contentWrap.current]);

  return (
    visible && (
      <Animated.View
        style={[
          ModalStyle.wrap,
          {
            opacity: animatedValue as any,
          },
        ]}
      >
        <Animated.View
          style={[
            ModalStyle.modal,
            {
              backgroundColor: themeColours.Modal.background,
              ...getModalWidth(),
              transform: [
                {
                  scale: animatedValue2.interpolate({
                    inputRange: [0, 1],
                    outputRange: [0.8, 1],
                  }) as any,
                },
                {
                  translateY: animatedValue2.interpolate({
                    inputRange: [0, 1],
                    outputRange: [80, 0],
                  }) as any,
                },
              ],
              opacity: animatedValue2 as any,
            },
            fullHeight && { flex: 1 },
          ]}
        >
          <>
            <Animated.View
              style={[
                ModalStyle.innerWrap,
                fullHeight && { flex: 1 },
                {
                  opacity: animatedValue3 as any,
                },
              ]}
            >
              <ErrorBoundary>
                <View
                  style={[
                    ModalStyle.contentWrap,
                    fullHeight && { flex: 1 },
                    {
                      maxHeight: pageHeight - 260,
                    },
                  ]}
                  ref={contentWrap}
                  collapsable={false}
                >
                  <>
                    {title?.length > 0 && (
                      <Text
                        style={[
                          ModalStyle.title,
                          { color: themeColours.Modal.title },
                        ]}
                      >
                        {title}
                      </Text>
                    )}
                    {typeof content === 'string' ? (
                      <Text
                        style={[
                          ModalStyle.text,
                          { color: themeColours.Modal.text },
                        ]}
                      >
                        {content}
                      </Text>
                    ) : (
                      typeof content === 'function' && content()
                    )}
                  </>
                </View>
                <View style={[ModalStyle.buttonWrap]}>
                  {action && (
                    <PlatformTouchable
                      style={[
                        ModalStyle.actionButton,
                        { opacity: actionDisabled ? 0.5 : 1 },
                      ]}
                      onPress={() => {
                        if (action) action();
                        setShowModal(false);
                      }}
                      disabled={actionDisabled}
                    >
                      <Text style={[ModalStyle.actionButtonText]}>
                        {actionText}
                      </Text>
                    </PlatformTouchable>
                  )}
                  <PlatformTouchable
                    style={[
                      ModalStyle.cancelButton,
                      !action && { flex: 1, width: '90%' },
                    ]}
                    onPress={() => {
                      if (cancelAction) cancelAction();
                      setShowModal(false);
                    }}
                  >
                    <Text style={[ModalStyle.cancelButtonText]}>
                      {cancelText}
                    </Text>
                  </PlatformTouchable>
                </View>
              </ErrorBoundary>
            </Animated.View>
            <PlatformTouchable
              style={ModalStyle.closeBtn}
              onPress={() => setShowModal(false)}
            >
              <CloseIcon fill="#C4C4C4" />
            </PlatformTouchable>
          </>
        </Animated.View>
      </Animated.View>
    )
  );
};
export default Modal;

export const ModalStyle = StyleSheet.create({
  wrap: {
    width: '100%',
    height: '100%',
    backgroundColor: blackRGBA(0.75),
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
  },
  modal: {
    position: 'relative',
    maxHeight: '90%',
    paddingHorizontal: 25,
    paddingVertical: 30,
    shadowColor: black,
    borderRadius: 5,
    shadowOffset: {
      width: 0,
      height: 18,
    },
    shadowOpacity: 0.04,
    shadowRadius: 40,
    elevation: 5,
    backgroundColor: white,
  },
  closeBtn: {
    position: 'absolute',
    top: 5,
    right: 5,
    padding: 10,
    zIndex: 2,
  },
  title: {
    textAlign: 'center',
    fontSize: 16,
    fontFamily: 'Quicksand-Bold',
    color: '#343D3F',
    textTransform: 'lowercase',
    marginBottom: 15,
  },
  text: {
    fontFamily: 'Quicksand-Regular',
    fontSize: 12,
    textAlign: 'center',
    color: '#AAA9A9',
  },
  buttonWrap: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: 20,
  },
  innerWrap: {
    // flex: 1,
  },
  contentWrap: {
    // flex: 1,
  },
  actionButton: {
    backgroundColor: blue,
    alignContent: 'center',
    justifyContent: 'center',
    width: 125,
    height: 40,
    borderRadius: 8,
    marginHorizontal: 5,
    borderColor: blue,
    borderWidth: 1.5,
  },
  actionButtonText: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 12,
    textTransform: 'lowercase',
    color: white,
    textAlign: 'center',
  },
  cancelButton: {
    alignContent: 'center',
    justifyContent: 'center',
    width: 125,
    height: 40,
    borderRadius: 8,
    marginHorizontal: 5,
    borderColor: blue,
    borderWidth: 1.5,
  },
  cancelButtonText: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 12,
    textTransform: 'lowercase',
    color: blue,
    textAlign: 'center',
  },
});
