import React, { ReactNode } from 'react';
import {
  Image,
  Platform,
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
} from 'react-native';
import verifiedIcon from '../../assets/images/logo-circle.svg';
import defaultProfile from '../../assets/images/profile.jpg';
import screenSizes from '../configs/screenSizes';
import { calculateAspectRatioFit } from '../utils/heightDetectionHelpers';
import ContextMenu, { ContextMenuItem } from './ContextMenu';
import HighlightCircle from './HighlightCircle';
import Hoverable from './Hoverable';
import PlatformTouchable from './PlatformTouchable';
import Pill from './Pill';
import { whiteRGBA, blue } from '../configs/colours';

interface Props {
  firstName: string;
  lastName: string;
  pictureUrl: string;
  onPress?: () => void;
  pills?: Array<{
    text: string;
    colour: string;
  }>;
  logoUrl?: string;
  isTransparentlyUser?: boolean;
  roleText?: string;
  actions?: Array<{ text: string; action: () => void }>;
  isPending?: boolean;
  isHighlighted?: boolean;
  totalNumber?: number;
  otherComponent?: ReactNode;
  colour?: string;
  countColour?: string;
}

const PersonListItem: React.FunctionComponent<Props> = ({
  firstName,
  lastName,
  pictureUrl,
  onPress,
  pills = [],
  logoUrl,
  isTransparentlyUser,
  roleText,
  actions = [],
  isPending = false,
  isHighlighted = false,
  totalNumber = 0,
  otherComponent,
  colour,
  countColour = blue,
}) => {
  const { width } = useWindowDimensions();

  const [logoSize, setLogoSize] = React.useState({
    height: 0,
    width: 0,
  });

  React.useEffect(() => {
    if (!!logoUrl) {
      Image.getSize(
        logoUrl,
        (logoWidth, logoHeight) =>
          setLogoSize(calculateAspectRatioFit(logoWidth, logoHeight, 129, 28)),
        () => null,
      );
    }
  }, [logoUrl]);

  return (
    <>
      <Hoverable>
        {(isHovered: boolean = false) => (
          <PlatformTouchable
            style={[
              PersonListItemStyle.main,
              width > screenSizes.medium && PersonListItemStyle.mainDesktop,
              {
                backgroundColor:
                  totalNumber > 0 || isHighlighted ? '#212426' : '#2B2F31',
              },
              isHovered &&
                !!onPress && {
                  backgroundColor: '#2C3F45',
                },
              !!colour && {
                borderLeftColor: colour,
                borderLeftWidth: 4,
                borderTopLeftRadius: 3,
                borderBottomLeftRadius: 3,
              },
            ]}
            onPress={onPress}
            disabled={!!!onPress}
          >
            <>
              <View style={[PersonListItemStyle.imageWrap]}>
                <Image
                  source={
                    !!pictureUrl
                      ? {
                          uri: pictureUrl,
                        }
                      : defaultProfile
                  }
                  style={[
                    PersonListItemStyle.image,
                    width > screenSizes.medium &&
                      PersonListItemStyle.imageDesktop,
                    isPending && { tintColor: 'gray' },
                  ]}
                />
                {isPending && (
                  <Image
                    source={{
                      uri: pictureUrl,
                    }}
                    style={[
                      PersonListItemStyle.image,
                      width > screenSizes.medium &&
                        PersonListItemStyle.imageDesktop,
                      { position: 'absolute', opacity: 0.3 },
                    ]}
                  />
                )}

                {isTransparentlyUser && (
                  <Image
                    source={verifiedIcon}
                    style={[
                      PersonListItemStyle.verifiedImage,
                      isHighlighted && {
                        top: 20,
                      },
                    ]}
                  />
                )}
                {isHighlighted && <HighlightCircle />}
              </View>
              <View
                style={[
                  PersonListItemStyle.contentWrap,
                  width > screenSizes.medium &&
                    PersonListItemStyle.contentWrapDesktop,
                ]}
              >
                <View
                  style={[
                    { flex: 1 },
                    width <= screenSizes.medium && { flexBasis: '100%' },
                  ]}
                >
                  <View style={[PersonListItemStyle.text, { flex: 1 }]}>
                    <View style={[{ flex: 1 }]}>
                      {!!firstName && (
                        <Text
                          style={[
                            PersonListItemStyle.name,
                            width > screenSizes.medium &&
                              PersonListItemStyle.nameDesktop,
                            isPending && { color: '#99A4A7' },
                          ]}
                          numberOfLines={1}
                        >
                          {firstName}{' '}
                          {!!lastName && (
                            <Text
                              style={[
                                PersonListItemStyle.nameBold,
                                isPending && { color: '#99A4A7' },
                              ]}
                            >
                              {lastName}
                            </Text>
                          )}
                        </Text>
                      )}
                      {!!roleText && (
                        <Text
                          style={[
                            PersonListItemStyle.role,
                            PersonListItemStyle.roleDesktop,
                          ]}
                          numberOfLines={1}
                        >
                          {roleText}
                        </Text>
                      )}
                    </View>
                  </View>
                </View>
                {pills.length > 0 && (
                  <View
                    style={[
                      PersonListItemStyle.pendingWrapWrap,
                      width <= screenSizes.medium && {
                        flex: 1,
                        flexBasis: '100%',
                      },
                      width > screenSizes.medium &&
                        PersonListItemStyle.pendingWrapWrapDesktop,
                    ]}
                  >
                    {pills.map((pill, index) => (
                      <View
                        style={[
                          PersonListItemStyle.pendingWrap,
                          {
                            borderColor: pill.colour,
                          },
                        ]}
                        key={`${pill.text}-${index}`}
                      >
                        <Text
                          style={[
                            PersonListItemStyle.pendingText,
                            { color: pill.colour },
                          ]}
                        >
                          {pill.text}
                        </Text>
                      </View>
                    ))}
                  </View>
                )}
              </View>
              {!!logoUrl && (
                <Image
                  source={{
                    uri: logoUrl,
                  }}
                  style={[
                    PersonListItemStyle.firmLogo,
                    {
                      width: logoSize.width,
                      height: logoSize.height,
                    },
                  ]}
                />
              )}
              {totalNumber > 0 && (
                <Pill
                  value={totalNumber}
                  lineStyle={true}
                  lineColour={countColour}
                />
              )}
              {otherComponent}
              <View
                style={[
                  PersonListItemStyle.contextWrap,
                  actions.length === 0 &&
                    Platform.OS === 'web' && {
                      // @ts-ignore
                      pointerEvents: 'none',
                    },
                ]}
              >
                <ContextMenu
                  buttonColour={
                    actions.length === 0 ? whiteRGBA(0.1) : undefined
                  }
                >
                  {actions.map((action, index) => (
                    <ContextMenuItem
                      key={`${action.text}-${index}`}
                      onPress={action.action}
                    >
                      {action.text}
                    </ContextMenuItem>
                  ))}
                </ContextMenu>
              </View>
            </>
          </PlatformTouchable>
        )}
      </Hoverable>
    </>
  );
};

export default PersonListItem;

const PersonListItemStyle = StyleSheet.create({
  main: {
    flexDirection: 'row',
    paddingHorizontal: 20,
    borderColor: '#3F4345',
    borderWidth: 1,
    alignItems: 'center',
    backgroundColor: '#212426',
    paddingVertical: 15,
    marginBottom: 5,
    flexWrap: 'wrap',
  },
  mainDesktop: {
    paddingVertical: 10,
    flexWrap: 'nowrap',
  },
  contentWrap: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
  },
  contentWrapDesktop: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  image: {
    width: 32,
    height: 32,
    borderRadius: 5,
  },
  imageWrap: {
    marginRight: 15,
  },
  imageDesktop: {
    width: 30,
    height: 30,
  },
  name: {
    fontSize: 15,
    fontFamily: 'Quicksand-Regular',
    color: '#E5F3F8',
  },
  nameDesktop: {
    fontSize: 14,
    flex: 1,
  },
  nameBold: {
    fontFamily: 'Quicksand-Medium',
  },
  role: {
    color: '#99A4A7',
    fontSize: 13,
    fontFamily: 'Quicksand-Medium',
  },
  roleDesktop: {
    fontSize: 12,
  },
  text: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  firmLogo: {
    height: '100%',
    width: '100%',
    maxHeight: 28,
    maxWidth: 129,
    marginRight: 10,
  },
  contextWrap: {
    marginBottom: -5,
    marginLeft: 10,
  },
  verifiedImage: {
    position: 'absolute',
    top: -4,
    right: -7,
    width: 14,
    height: 14,
  },
  pendingWrap: {
    borderWidth: 1,
    borderColor: '#99A4A7',
    paddingHorizontal: 7.5,
    paddingVertical: 3,
    borderRadius: 4,
    marginHorizontal: 5,
  },
  pendingText: {
    fontFamily: 'Quicksand-Bold',
    fontSize: 10,
    color: '#99A4A7',
  },
  pendingWrapWrapDesktop: {
    marginHorizontal: 10,
    marginTop: 0,
  },
  pendingWrapWrap: {
    marginTop: 10,
    marginHorizontal: -7.5,
    flexDirection: 'row',
    alignItems: 'center',
  },
});
