import * as React from 'react';
import { Svg, Circle } from 'react-native-svg';

interface Props {
  fill?: string;
  width?: number;
  height?: number;
}

const KebabIcon: React.FunctionComponent<Props> = ({
  fill = '#C9C9C9',
  width = 3.11,
  height = 14,
}) => {
  return (
    <Svg height={height} viewBox="0 0 3.11 14" width={width}>
      <Circle cx="1.55" cy="7" r="1.55" fill={fill} />
      <Circle cx="1.55" cy="1.55" r="1.55" fill={fill} />
      <Circle cx="1.55" cy="12.44" r="1.55" fill={fill} />
    </Svg>
  );
};
export default KebabIcon;
