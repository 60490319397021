import React, { useState, useRef, lazy, Suspense } from 'react';
import {
  View,
  StyleSheet,
  Text,
  Image,
  Platform,
  Animated,
} from 'react-native';
import PlatformTouchable from './PlatformTouchable';

import { InsituConversationContext } from './InsituConversationContextProvider';
import { white, black, blue } from '../configs/colours';
import CloseIcon from './icons/CloseIcon';
import DragHandleIcon from './icons/DragHandleIcon';
import { PanGestureHandler, State } from 'react-native-gesture-handler';
import LoadingView from './LoadingView';
import ClientConversations from '../containers/ClientConversations';
import useIsNonPro from '../hooks/usIsNonPro';
import ChevronLeftIcon from './icons/ChevronLeftIcon';
// import Animated from 'react-native-reanimated';

const Conversation = lazy(() => import('../containers/Conversation'));
const Conversations = lazy(() => import('../containers/Conversations'));

interface Props {
  updateProps: (
    f: (draft: InsituConversationContext) => void | InsituConversationContext,
  ) => void;
  conversationId: string;
  clientAccountId?: string;
}

const InsituConversationWrapper: React.FunctionComponent<Props> = ({
  updateProps,
  conversationId,
  clientAccountId,
}) => {
  const isNonPro = useIsNonPro();

  const [conversationDetails, setConversationDetails] = useState<{
    name: string;
    picture: string;
  }>({
    name: !isNonPro && clientAccountId ? 'all conversations' : '',
    picture: '',
  });

  const translateX = useRef(new Animated.Value(0)).current;
  const translateY = useRef(new Animated.Value(0)).current;
  const lastOffset = useRef({ x: 0, y: 0 }).current;
  const onGestureEvent = Animated.event(
    [
      {
        nativeEvent: {
          translationX: translateX,
          translationY: translateY,
        },
      },
    ],
    { useNativeDriver: Platform.OS !== 'web' },
  );

  const onHandlerStateChange = (event) => {
    if (event?.nativeEvent?.oldState === State.ACTIVE) {
      lastOffset.x += event?.nativeEvent?.translationX ?? 0;
      lastOffset.y += event?.nativeEvent?.translationY ?? 0;
      translateX.setOffset(lastOffset.x);
      translateX.setValue(0);
      translateY.setOffset(lastOffset.y);
      translateY.setValue(0);
    }
  };

  return (
    <Animated.View
      style={[
        InsituConversationWrapperStyle.main,
        {
          transform: [{ translateX }, { translateY }],
        },
      ]}
    >
      <View style={[InsituConversationWrapperStyle.header]}>
        <PanGestureHandler
          onGestureEvent={onGestureEvent}
          onHandlerStateChange={onHandlerStateChange}
          minDist={2}
        >
          <View style={[InsituConversationWrapperStyle.dragHandle]}>
            <DragHandleIcon />
          </View>
        </PanGestureHandler>

        <View style={[InsituConversationWrapperStyle.titleWrap]}>
          {!!conversationDetails?.picture && (
            <Image
              source={{
                uri: conversationDetails?.picture,
              }}
              style={[InsituConversationWrapperStyle.image]}
            />
          )}
          <Text style={[InsituConversationWrapperStyle.title]}>
            {conversationDetails?.name ?? 'conversation'}
          </Text>
        </View>
        <PlatformTouchable
          onPress={() => {
            updateProps((draft) => {
              draft.showConversation = false;
              draft.conversationId = '';
              draft.clientAccountId = '';
            });
          }}
          style={[InsituConversationWrapperStyle.closeButton]}
        >
          <>
            <CloseIcon fill={white} width={8} height={8} />
            <Text style={[InsituConversationWrapperStyle.closeButtonText]}>
              Close
            </Text>
          </>
        </PlatformTouchable>
      </View>
      <Suspense fallback={<LoadingView />}>
        {!!conversationId ? (
          <>
            {!isNonPro && clientAccountId && (
              <PlatformTouchable
                style={[InsituConversationWrapperStyle.backButton]}
                onPress={() => {
                  updateProps((draft) => {
                    draft.showConversation = true;
                    draft.conversationId = '';
                  });
                  setConversationDetails({
                    name: 'all conversations',
                    picture: '',
                  });
                }}
              >
                <>
                  <ChevronLeftIcon width={5.34} height={10.25} fill={white} />
                  <Text
                    style={[InsituConversationWrapperStyle.backButtonText]}
                    numberOfLines={1}
                  >{`back to all conversations`}</Text>
                </>
              </PlatformTouchable>
            )}
            <Conversation
              conversationId={conversationId}
              setConversationDetails={setConversationDetails}
            />
          </>
        ) : !isNonPro && clientAccountId ? (
          <ClientConversations
            isInsitu={true}
            updateProps={updateProps}
            clientAccountId={clientAccountId}
          />
        ) : (
          <Conversations isInsitu={true} updateProps={updateProps} />
        )}
      </Suspense>
    </Animated.View>
  );
};

export default InsituConversationWrapper;

const InsituConversationWrapperStyle = StyleSheet.create({
  main: {
    position: 'absolute',
    right: 20,
    bottom: 20,
    width: 320,
    height: 420,
    borderRadius: 4,
    overflow: 'hidden',
    borderColor: '#838383',
    borderWidth: 1,
    shadowColor: black,
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 5,
    zIndex: 8,
  },
  header: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderBottomColor: '#838383',
    borderBottomWidth: 1,
    backgroundColor: '#282B2E',
    flexDirection: 'row',
    alignItems: 'center',
  },
  closeButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  closeButtonText: {
    color: white,
    fontFamily: 'Quicksand-Medium',
    fontSize: 10,
    marginLeft: 4,
  },
  image: {
    width: 24,
    height: 24,
    borderRadius: 4,
  },
  titleWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    paddingRight: 10,
    justifyContent: 'center',
  },
  title: {
    color: white,
    fontFamily: 'Quicksand-Medium',
    fontSize: 14,
    marginLeft: 5,
  },
  dragHandle: {
    marginRight: 10,
    padding: 10,
    margin: -10,
  },
  backButton: {
    height: 32,
    width: '100%',
    backgroundColor: blue,
    paddingHorizontal: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backButtonText: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 12,
    color: white,
    marginLeft: 5,
  },
});
