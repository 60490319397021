import { Dimensions } from 'react-native';
import {
  white,
  darkGrey,
  black,
  lightGrey,
  lightGrey2,
  mediumGrey,
  darkGrey2,
  mediumGrey2,
  darkGrey3,
  mediumGrey3,
  lightGrey4,
  lightGrey5,
  darkGrey4,
  whiteRGBA,
  blue,
} from './colours';
import screenSizes from './screenSizes';

export interface TThemeColours {
  primaryFont: string;
  secondaryFont: string;
  mainBackground: string;
  sideBackground: string;
  contextBackground: string;
  borderColor: string;
  header: string;
  highlight: string;
  searchBackground: string;
  searchColour: string;
  unreadConversation: string;
  conversationBorder: string;
  hoverHighlight: string;
  breadcrumb: string;
  actionButton: string;
  separatorBackground: string;
  separatorBorder: string;
  ButtonToggle: {
    border: string;
  };
  SortOptions: {
    border: string;
    icon: string;
    text: string;
  };
  ClientListItem: {
    background: string;
    contextButton: string;
    color: string;
    border: string;
  };
  AlphabetSeparator: {
    color: string;
    background: string;
  };
  AlphabetList: {
    color: string;
    background: string;
  };
  ContextMenu: {
    borderColor: string;
    background: string;
  };
  SkeletonScreen: {
    gradientStart: string;
    gradientEnd: string;
    fill: string;
    background: string;
  };
  Attachment: {
    name: string;
    text: string;
    border: string;
    background: string;
  };
  Modal: {
    title: string;
    text: string;
    background: string;
  };
  PauseConversationModal: {
    border: string;
  };
  PrepareTaskModalContent: {
    border: string;
  };
  OptionsList: {
    activeBackground: string;
    background: string;
    title: string;
    itemBackground: string;
    border: string;
    optionColor: string;
    activeOptionBackground: string;
  };
  PausedMessageCover: {
    background: string;
    highlight: string;
    text: string;
  };
  MessageListItem: {
    contextMenuButton: string;
    contextMenuButtonAlt: string;
    messageColour: string;
    messageColourAlt: string;
    messageTextColour: string;
    messageReplyTextColour: string;
    messageReplyColour: string;
    messageReplyBorder: string;
    messageOuterBackground: string;
    messageInnerBorder: string;
    messageAltInnerBorder: string;
  };
  EmptyConversation: {
    text: string;
    background: string;
  };
  StartMatterModalContent: {
    borderTop: string;
    selectedBorder: string;
    selectedBackground: string;
    color: string;
  };
  DivorceIcon: {
    fill: string;
    secondary: string;
  };
  DateIcon: {
    stroke: string;
    fill: string;
    secondary: string;
  };
  FinanceIcon: {
    stroke: string;
    fill: string;
    secondary: string;
  };
  ChildrenIcon: {
    stroke: string;
    fill: string;
    secondary: string;
    third: string;
  };
  TaskPanel: {
    background: string;
    borderTop: string;
    taskCount: string;
    touchable: string;
    textColour: string;
  };
  assignmentColours: {
    unassigned: string;
    client: string;
    you: string;
    unassignedColour: string;
    clientColour: string;
    youColour: string;
  };
  Button: {
    solidBackground: string;
    color: string;
  };
  TaskListItem: {
    background: string;
    name: string;
    text: string;
    otherTextHighlight: string;
    progressBar: string;
    progress: string;
  };
  TitleInput: {
    label: string;
  };
  Input: {
    label: string;
    color: string;
    placeholder: string;
    border: string;
    background: string;
  };
  ProfileSettings: {
    background: string;
    inactiveTab: string;
    check: string;
    validationText: string;
  };
  ClientMatterHeader: {
    background: string;
    border: string;
    color: string;
  };
  TaskEditMode: {
    titleBackground: string;
    titleBorder: string;
    titleColor: string;
    textColor: string;
    sectionBackground: string;
    progressIcon: string;
    sectionActiveBackground: string;
    sectionBorder: string;
    sectionActiveBorder: string;
  };
  TaskSectionKanbanBoard: {
    background: string;
  };
  ButtonLarge: {
    inactiveBackground: string;
  };
  SelectList: {
    background: string;
    text: string;
    selected: string;
  };
}

export const LIGHT: 'LIGHT' = 'LIGHT';
export const DARK: 'DARK' = 'DARK';
export interface TThemeContext {
  theme: 'LIGHT' | 'DARK';
  setTheme: (theme: 'LIGHT' | 'DARK') => void;
  themeColours: TThemeColours;
}

export const lightColours: TThemeColours = {
  primaryFont: darkGrey,
  secondaryFont: black,
  header: white,
  mainBackground: lightGrey5,
  sideBackground: lightGrey4,
  contextBackground: white,
  borderColor: lightGrey,
  highlight: lightGrey2,
  searchBackground: white,
  searchColour: '#B5B5B5',
  unreadConversation: '#F7F7F7',
  conversationBorder: '#F2F2F2',
  hoverHighlight: '#E5F3F8',
  breadcrumb: '#F0F0F0',
  actionButton: '#E6F7FD',
  separatorBorder: '#BEBCBC',
  separatorBackground: '#E6F8FF',
  ButtonToggle: {
    border: '#E3E3E3',
  },
  SortOptions: {
    border: '#F2F2F2',
    icon: '#3C4547',
    text: '#3C4547',
  },
  ClientListItem: {
    background: white,
    contextButton: '#D5D5D5',
    color: '#343D3F',
    border: '#F2F2F2',
  },
  AlphabetSeparator: {
    color: '#AAA9A9',
    background: '#FAFAFA',
  },
  AlphabetList: {
    color: '#343D3F',
    background: white,
  },
  ContextMenu: {
    borderColor: '#BFE2EF',
    background: white,
  },
  SkeletonScreen: {
    gradientStart: 'rgba(237, 237, 237, 1)',
    gradientEnd: 'rgba(219, 219, 219, 1)',
    fill: '#E2E2E2',
    background: '#F4F4F4',
  },
  Attachment: {
    name: '#566569',
    text: '#AAA9A9',
    border: '#BEBEBE',
    background: white,
  },
  Modal: {
    background: white,
    title: '#343D3F',
    text: '#AAA9A9',
  },
  PauseConversationModal: {
    border: '#EBEBEB',
  },
  PrepareTaskModalContent: {
    border: '#EBEBEB',
  },
  OptionsList: {
    activeBackground: '#F7F7F7',
    background: white,
    title: '#3C4547',
    itemBackground: white,
    border: '#F2F2F2',
    optionColor: '#343D3F',
    activeOptionBackground: '#E5F3F8',
  },
  PausedMessageCover: {
    background: whiteRGBA(1),
    highlight: '#566569',
    text: '#AAA9A9',
  },
  MessageListItem: {
    contextMenuButton: '#D5D5D5',
    contextMenuButtonAlt: '#8CBED8',
    messageColour: white,
    messageColourAlt: 'rgb(239, 250, 255)',
    messageTextColour: '#566569',
    messageReplyTextColour: '#498498',
    messageReplyColour: white,
    messageReplyBorder: '#DDDDDD',
    messageOuterBackground: 'rgba(219,219,219,0.9)',
    messageInnerBorder: '#9B9B9B',
    messageAltInnerBorder: '#A4AEB4',
  },
  EmptyConversation: {
    text: '#343D3F',
    background: white,
  },
  StartMatterModalContent: {
    borderTop: '#E2E2E2',
    selectedBorder: 'rgba(2,172,230,.5)',
    selectedBackground: 'rgba(229,243,248,.69)',
    color: '#343D3F',
  },
  DivorceIcon: {
    fill: blue,
    secondary: '#707070',
  },
  DateIcon: {
    stroke: '#707070',
    fill: '#e1f1f7',
    secondary: '#02ace6',
  },
  ChildrenIcon: {
    fill: '#E5F3F8',
    stroke: '#707070',
    secondary: blue,
    third: white,
  },
  FinanceIcon: {
    fill: white,
    stroke: '#707070',
    secondary: '#BCEDFF',
  },
  TaskPanel: {
    textColour: '#AAA9A9',
    background: '#EFF1F2',
    borderTop: '#E8E8E8',
    taskCount: '#AAA9A9',
    touchable: '#C9C9C9',
  },
  assignmentColours: {
    unassigned: '#F4F4F4',
    client: '#FFD972',
    you: 'rgba(255,29,0,0.22)',
    unassignedColour: '#7C7C7C',
    clientColour: '#5A4D2B',
    youColour: '#6A4636',
  },
  Button: {
    solidBackground: white,
    color: blue,
  },
  TaskListItem: {
    background: '#FFFFFF',
    name: '#343D3F',
    text: '#AAA9A9',
    otherTextHighlight: '#000000',
    progressBar: '#E5F3F8',
    progress: '#00A1E5',
  },
  TitleInput: {
    label: '#000000',
  },
  Input: {
    label: '#AAA9A9',
    color: darkGrey,
    placeholder: '#B5B5B5',
    border: '#E6E6E6',
    background: white,
  },
  ProfileSettings: {
    background: white,
    check: '#494C4D',
    validationText: '#AAA9A9',
    inactiveTab: '#F8F8F8',
  },
  ClientMatterHeader: {
    background: white,
    border: '#AAA9A9',
    color: darkGrey,
  },
  TaskEditMode: {
    titleBackground: '#FAFAFA',
    titleBorder: '#AAA9A9',
    titleColor: darkGrey,
    textColor: darkGrey,
    sectionBackground: white,
    progressIcon: darkGrey,
    sectionActiveBackground: 'rgb(232, 242, 248)',
    sectionBorder: '#AAA9A9',
    sectionActiveBorder: '#FAFAFA',
  },
  TaskSectionKanbanBoard: {
    background: whiteRGBA(0.8),
  },
  ButtonLarge: {
    inactiveBackground: '#E5F3F8',
  },
  SelectList: {
    background: white,
    text: '#AAA9A9',
    selected: 'rgba(229, 243, 248, 0.7)',
  },
};

export const darkColours: TThemeColours = {
  primaryFont: white,
  secondaryFont: mediumGrey,
  header: '#2B2F31',
  mainBackground: darkGrey2,
  sideBackground: darkGrey3,
  contextBackground:
    Dimensions.get('window').width > screenSizes.medium ? '#383D3F' : darkGrey4,
  borderColor: mediumGrey3,
  highlight: mediumGrey2,
  searchBackground: '#383D3F',
  searchColour: '#B5B5B5',
  unreadConversation: '#292D2F',
  conversationBorder: '#3F4345',
  hoverHighlight: '#2C3F45',
  breadcrumb:
    Dimensions.get('window').width > screenSizes.medium ? '#383D3F' : '#303537',
  actionButton: '#1E3239',
  separatorBorder: '#686E71',
  separatorBackground: '#142127',
  ButtonToggle: {
    // border: '#4F5558',
    border: blue,
  },
  SortOptions: {
    border: '#787E83',
    icon: white,
    text: white,
  },
  ClientListItem: {
    background: '#303537',
    contextButton: '#818181',
    color: white,
    border: '#3F4345',
  },
  AlphabetSeparator: {
    color: white,
    background: '#2C3F45',
  },
  AlphabetList: {
    color: white,
    background: '#303537',
  },
  ContextMenu: {
    borderColor: '#506A72',
    background: '#303537',
  },
  SkeletonScreen: {
    gradientStart: '#4A4F50',
    gradientEnd: '#404444',
    fill: '#303333',
    background: '#3C3F3F',
  },
  Attachment: {
    name: white,
    text: '#AAA9A9',
    border: '#818181',
    background: '#282C2E',
  },
  Modal: {
    background: '#383D3F',
    title: white,
    text: '#AAA9A9',
  },
  PauseConversationModal: {
    border: '#4A4E50',
  },
  PrepareTaskModalContent: {
    border: '#4A4E50',
  },
  OptionsList: {
    activeBackground: '#333739',
    background:
      Dimensions.get('window').width > screenSizes.medium
        ? '#383D3F'
        : darkGrey4,
    title: white,
    itemBackground: '#383D3F',
    border: '#3F4345',
    optionColor: white,
    activeOptionBackground: '#2C3F45',
  },
  PausedMessageCover: {
    background: 'rgba(56, 61, 63, 1)',
    highlight: white,
    text: '#AAA9A9',
  },
  MessageListItem: {
    contextMenuButton: '#818181',
    contextMenuButtonAlt: '#658391',
    messageColour: '#282B2E',
    messageColourAlt: '#123540',
    messageReplyTextColour: '#82B6C7',
    messageReplyColour: '#2C3F45',
    messageReplyBorder: '#3E4F57',
    messageTextColour: white,
    messageOuterBackground: 'rgba(84,88,90,0.9)',
    messageInnerBorder: '#838383',
    messageAltInnerBorder: '#307990',
  },
  EmptyConversation: {
    text: white,
    background: '#282C2E',
  },
  StartMatterModalContent: {
    borderTop: '#3F4345',
    selectedBorder: 'rgba(2,172,230,.5)',
    selectedBackground: '#2C3F45',
    color: white,
  },
  DivorceIcon: {
    fill: blue,
    secondary: white,
  },
  DateIcon: {
    stroke: '#E3E3E3',
    fill: '#2C3F45',
    secondary: '#02ace6',
  },
  ChildrenIcon: {
    fill: '#2C3F45',
    stroke: '#E3E3E3',
    secondary: blue,
    third: '#383D3F',
  },
  FinanceIcon: {
    fill: '#383D3F',
    stroke: '#E3E3E3',
    secondary: blue,
  },
  TaskPanel: {
    textColour: '#6E6E6E',
    background: '#2B2F31',
    borderTop: '#3F4345',
    taskCount: '#818181',
    touchable: '#818181',
  },
  assignmentColours: {
    unassigned: '#575F62',
    client: '#C9A954',
    you: 'rgba(255,29,0,0.22)',
    unassignedColour: '#B9B9B9',
    clientColour: '#41381F',
    youColour: '#C78568',
  },
  Button: {
    solidBackground: '#212426',
    color: blue,
  },
  TaskListItem: {
    background: '#212426',
    name: white,
    text: '#AAA9A9',
    otherTextHighlight: white,
    progressBar: '#2C3F45',
    progress: '#00A1E5',
  },
  TitleInput: {
    label: white,
  },
  Input: {
    label: '#AAA9A9',
    color: white,
    placeholder: '#B5B5B5',
    border: '#4A4E50',
    background: '#383D3F',
  },
  ProfileSettings: {
    background: '#303537',
    check: '#494C4D',
    validationText: '#AAA9A9',
    inactiveTab: '#2B2F31',
  },
  ClientMatterHeader: {
    background: '#212426',
    border: '#414D50',
    color: white,
  },
  TaskEditMode: {
    titleBackground: '#262A2C',
    titleBorder: '#414D50',
    titleColor: white,
    textColor: white,
    sectionBackground: '#212426',
    progressIcon: white,
    sectionActiveBackground: '#123540',
    sectionBorder: '#2C3F45',
    sectionActiveBorder: '#235D6F',
  },
  TaskSectionKanbanBoard: {
    background: 'rgba(56, 61, 63, 0.9)',
  },
  ButtonLarge: {
    inactiveBackground: '#2C3F45',
  },
  SelectList: {
    background: '#383d3f',
    text: '#AAA9A9',
    selected: '#2C3F45',
  },
};
