import { ApolloClient } from 'apollo-client';
import produce from 'immer';
import {
  ListNotificationsDocument,
  ListNotificationsQuery,
  ListNotificationsQueryVariables,
  NewNotificationsSubscription,
} from '../../types/apolloTypes';

export function newNotificationUpdate(
  client: ApolloClient<object>,
  notificationData: NewNotificationsSubscription,
) {
  const cache = client.cache;
  const notification = notificationData?.newNotification;
  // Update message list query with new message

  try {
    const listNotificationsData = cache.readQuery<
      ListNotificationsQuery,
      ListNotificationsQueryVariables
    >({
      query: ListNotificationsDocument,
    });

    const nextNotificationState = produce(
      listNotificationsData,
      (draftState) => {
        draftState.notifications.totalUnread =
          draftState.notifications.totalUnread + 1;
        draftState.notifications.total = draftState.notifications.total + 1;
        draftState.notifications.notifications = [
          notification,
          ...draftState.notifications.notifications,
        ];
      },
    );

    client.writeQuery({
      query: ListNotificationsDocument,
      data: nextNotificationState,
    });
  } catch (error) {
    if (error?.name !== 'Invariant Violation') {
      throw new Error(error);
    }
  }
}
