import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { Path, Svg } from 'react-native-svg';
import { blue } from '../../configs/colours';

interface Props {
  fill?: string;
  height?: number;
  width?: number;
  style?: StyleProp<ViewStyle>;
}

const FirmAdministrationIcon: React.FunctionComponent<Props> = ({
  fill = blue,
  width = 14.74,
  height = 17.78,
  style,
}) => {
  return (
    <Svg height={height} viewBox="0 0 14.74 17.78" width={width} style={style}>
      <Path
        d="M6.07,6.26h0A3.2,3.2,0,0,0,5.33,0H4.6a3.2,3.2,0,0,0-.73,6.26h0C1.66,7.43-.77,11.52.24,13c.59.9,2.64,1.33,4.73,1.33S9.13,13.92,9.72,13C10.71,11.47,8.28,7.38,6.07,6.26Zm-3.21-3A1.82,1.82,0,0,1,4,1.35a2.09,2.09,0,0,1,2.41.44A1.9,1.9,0,0,1,6.92,4,1.85,1.85,0,0,1,5,5.28a2,2,0,0,1-1.52-.64,1.77,1.77,0,0,1-.62-1.41Zm5.67,9c-.26.39-1.19.9-3.53.9s-3.3-.5-3.56-.9c-.15-.23-.17-.68.5-2C2.87,8.47,4,7.43,5,7.43s2.1,1,3.06,2.83c.6,1.24.66,1.69.51,2Z"
        transform="translate(0 0)"
        fill="#e5f3f8"
      />
      <Path
        d="M14,10l-3.52-1.9L7,10v3.52C7,15.32,10.48,17,10.48,17S14,15.15,14,13.51Z"
        transform="translate(0 0)"
        fill="#383d3f"
        stroke="#02ace6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </Svg>
  );
};

export default FirmAdministrationIcon;
