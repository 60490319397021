import { ApolloClient } from 'apollo-client';
import produce from 'immer';
import {
  ListConversationsDocument,
  ListConversationsQuery,
  ListConversationsQueryVariables,
  ListMessagesDocument,
  ListMessagesQuery,
  ListMessagesQueryVariables,
  OnConversationUpdateSubscription,
} from './../../types/apolloTypes';

export function conversationUpdateUpdate(
  client: ApolloClient<object>,
  conversationData: OnConversationUpdateSubscription,
  individualId: string,
) {
  const cache = client.cache;
  const conversation = conversationData?.conversationUpdate;
  // Update message list query with new message
  const variables = {
    conversationId: conversation.id,
    input: {
      conversationId: conversation.id,
      page: 1,
      pageSize: 50,
    },
  };

  try {
    const listQueryData = cache.readQuery<
      ListMessagesQuery,
      ListMessagesQueryVariables
    >({
      query: ListMessagesDocument,
      variables,
    });

    const nextListQueryState = produce(listQueryData, (draftState) => {
      draftState.conversation = {
        ...draftState.conversation,
        pausedUntil: conversation.pausedUntil,
        unreadMessageCount: conversation.unreadMessageCount,
      };
    });

    client.writeQuery({
      query: ListMessagesDocument,
      data: nextListQueryState,
      variables,
    });
  } catch (error) {
    if (error?.name !== 'Invariant Violation') {
      throw new Error(error);
    }
  }

  try {
    const listQueryData = cache.readQuery<
      ListConversationsQuery,
      ListConversationsQueryVariables
    >({
      query: ListConversationsDocument,
      variables: {
        memberId: individualId,
      },
    });

    const nextListQueryState = produce(listQueryData, (draftState) => {
      const conversationIndex = draftState.conversations.findIndex(
        (c) => c.id === conversation.id,
      );
      if (conversationIndex > -1) {
        draftState.conversations[conversationIndex] = {
          ...draftState.conversations[conversationIndex],
          ...conversation,
        };
      }
    });

    client.writeQuery({
      query: ListConversationsDocument,
      data: nextListQueryState,
      variables: {
        memberId: individualId,
      },
    });
  } catch (error) {
    if (error?.name !== 'Invariant Violation') {
      throw new Error(error);
    }
  }
}
