import * as React from 'react';
import { Svg, Path, Line } from 'react-native-svg';
import { white } from '../../configs/colours';
import { StyleProp, ViewStyle } from 'react-native';

interface Props {
  fill?: string;
  width?: number;
  height?: number;
  style?: StyleProp<ViewStyle>;
}

const FilterIcon: React.FunctionComponent<Props> = ({
  fill = '#e5f3f8',
  height = 16.79,
  width = 15,
  style,
}) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 16.79 15" style={style}>
      <Path
        d="M.75.75H16"
        fill="none"
        stroke={fill}
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <Line
        x1="3.62"
        y1="5.34"
        x2="13.16"
        y2="5.34"
        fill="none"
        stroke={fill}
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <Line
        x1="6.39"
        y1="9.92"
        x2="10.39"
        y2="9.92"
        fill="none"
        stroke={fill}
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <Line
        x1="7.24"
        y1="14.25"
        x2="9.54"
        y2="14.25"
        fill="none"
        stroke={fill}
        strokeLinecap="round"
        strokeWidth="1.5"
      />
    </Svg>
  );
};
export default FilterIcon;
