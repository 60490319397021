import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { Path, Svg } from 'react-native-svg';
import { blue } from '../../configs/colours';

interface Props {
  fill?: string;
  secondaryFill?: string;
  height?: number;
  width?: number;
  style?: StyleProp<ViewStyle>;
}

const TasksIcon: React.FunctionComponent<Props> = ({
  fill = blue,
  secondaryFill = '#979D9F',
  width = 17.31,
  height = 11.3,
  style,
}) => {
  return (
    <Svg height={height} viewBox="0 0 17.31 11.3" width={width} style={style}>
      <Path
        d="M1.06,3.7,2.43,4.92,5.1,1"
        transform="translate(-0.31 -0.29)"
        fill="none"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <Path
        d="M9.09,3.38h7.78"
        transform="translate(-0.31 -0.29)"
        stroke={secondaryFill}
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <Path
        d="M1.06,9.62l1.37,1.22L5.1,7"
        transform="translate(-0.31 -0.29)"
        fill="none"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <Path
        d="M9.09,9.29h7.78"
        transform="translate(-0.31 -0.29)"
        stroke={secondaryFill}
        strokeLinecap="round"
        strokeWidth="1.5"
      />
    </Svg>
  );
};

export default TasksIcon;
