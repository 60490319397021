import {
  FirmBarrister,
  FirmParalegal,
  FirmProfessional,
  FirmSolicitor,
  FirmSupport,
} from './../types/apolloTypes';
import {
  Firm,
  FirmMember,
  useGetCurrentUserQuery,
  useListFirmMembershipsQuery,
} from '../types/apolloTypes';
import useIsNonPro from './usIsNonPro';
export const useGetFirmMembership: () => { __typename?: 'FirmMember' } & Pick<
  FirmMember,
  | 'id'
  | 'employeeNumber'
  | 'jobTitle'
  | 'office'
  | 'isActive'
  | 'isHighlighted'
  | 'activeSince'
  | 'role'
> & {
    professional:
      | ({ __typename?: 'FirmBarrister' } & Pick<
          FirmBarrister,
          | 'id'
          | 'referenceNumber'
          | 'type'
          | 'firstName'
          | 'preferredName'
          | 'middleNames'
          | 'workPhoneNumber'
          | 'lastName'
          | 'pictureUrl'
          | 'sraNumber'
        >)
      | ({ __typename?: 'FirmParalegal' } & Pick<
          FirmParalegal,
          | 'id'
          | 'referenceNumber'
          | 'type'
          | 'firstName'
          | 'preferredName'
          | 'middleNames'
          | 'workPhoneNumber'
          | 'lastName'
          | 'pictureUrl'
          | 'paralegalNumber'
        >)
      | ({ __typename?: 'FirmSolicitor' } & Pick<
          FirmSolicitor,
          | 'id'
          | 'referenceNumber'
          | 'type'
          | 'firstName'
          | 'preferredName'
          | 'middleNames'
          | 'workPhoneNumber'
          | 'lastName'
          | 'pictureUrl'
          | 'sraNumber'
        >)
      | ({ __typename?: 'FirmSupport' } & Pick<
          FirmSupport,
          | 'id'
          | 'referenceNumber'
          | 'type'
          | 'firstName'
          | 'preferredName'
          | 'middleNames'
          | 'workPhoneNumber'
          | 'lastName'
          | 'pictureUrl'
        >);
    firm: { __typename?: 'Firm' } & Pick<
      Firm,
      'id' | 'name' | 'sraNumber' | 'logoUrl'
    >;
  } = () => {
  const isNonPro = useIsNonPro();

  const { data: currentUserData } = useGetCurrentUserQuery();

  const { data: listFirmMembershipsData } = useListFirmMembershipsQuery({
    variables: {
      professionalId: currentUserData?.currentUser?.id,
    },
    skip: isNonPro,
  });

  return listFirmMembershipsData?.firmMemberships?.[0];
};
