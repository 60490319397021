import { Dimensions, PixelRatio, Platform } from 'react-native';
import { MessageTopic } from '../types/Conversations';
import {
  MessagesWithBreakers,
  SectionListSectionsProp,
} from './../components/MessageList';
import getWidth, { getLineCount } from './getStringWidth';

export function getSectionListHeights<T>({
  data,
  sectionHeaderHeight = 0,
  extraContentHeight = 0,
  sectionFooterHeight = 0,
  propToCheck = '',
}: {
  data: SectionListSectionsProp<T> | null;
  sectionHeaderHeight?: number;
  extraContentHeight?: number;
  sectionFooterHeight?: number;
  propToCheck: string;
}): number[] {
  const window = Dimensions.get('window').width;
  const heights: number[] = [];
  if (data) {
    data.forEach((section) => {
      heights.push(sectionHeaderHeight);
      section.data.forEach((dataItem) => {
        const text =
          (propToCheck && dataItem && (dataItem as any)[propToCheck]) || '';
        const numberOfLineBreaks = (text.match(/\n/g) || []).length;
        const textWidth = getWidth(text, {
          font: 'Quicksand-Medium',
          size: 14 * PixelRatio.getFontScale(),
        });
        const horizontalPadding = 51;
        const viewPortWidth =
          (window >= 760 ? window - 410 : window) - horizontalPadding;
        const numberOfLines =
          Math.ceil(textWidth.totalWidth / viewPortWidth) + numberOfLineBreaks;
        const textHeight = numberOfLines * 20;
        const height = textHeight + extraContentHeight;
        heights.push(height);
      });
      heights.push(sectionFooterHeight);
    });
  }

  return heights;
}

export const getTopicsHeight = (
  topics: MessageTopic[],
  viewPortWidth: number,
) => {
  const topicsWidth =
    topics?.reduce((prev, curr) => {
      return (
        getWidth(curr.name, {
          font: 'Quicksand-Medium',
          size: Math.round(11 * PixelRatio.getFontScale()),
          lineWidth: viewPortWidth,
        }).totalWidth +
        prev +
        15
      );
    }, 0) - 5;

  const topicLineCount = topicsWidth / viewPortWidth;

  return Math.ceil(topicLineCount) * 29 + 5;
};

export function getFlatListMessageHeights({
  data,
  sectionHeaderHeight = 0,
  extraContentHeight = 0,
  extraContentWidth = 0,
  propToCheck = '',
  fontSize = 12,
  showMeta = false,
  metaHeight = 0,
  imageHeight = 0,
  lineHeight: theLineHeight = 20,
  attachmentHeight = 0,
  isNonPro = false,
  currentUserId = '',
  isInsitu = false,
  showMoreItems = [],
  showTopicsItems = [],
  compactMessages = true,
  conversationType,
}: {
  data: MessagesWithBreakers | null;
  sectionHeaderHeight?: number;
  extraContentHeight?: number;
  sectionFooterHeight?: number;
  extraContentWidth?: number;
  fontSize?: number;
  propToCheck: string;
  metaHeight?: number;
  showMeta?: boolean;
  imageHeight?: number;
  attachmentHeight?: number;
  lineHeight?: number;
  isNonPro?: boolean;
  currentUserId?: string;
  isInsitu?: boolean;
  showMoreItems?: string[];
  showTopicsItems?: string[];
  compactMessages?: boolean;
  conversationType: 'client' | 'contact' | 'firm' | undefined;
}): number[] {
  const window = Dimensions.get('window').width;
  const lineHeight = theLineHeight * PixelRatio.getFontScale();
  const heights: number[] = [];
  if (data) {
    data.forEach((section) => {
      if (section.type === 'message' && section.message) {
        const text =
          (propToCheck && section && (section as any).message[propToCheck]) ||
          '';
        const numberOfLineBreaks = (text.match(/\n/g) || []).length;
        const numberOfDoubleLineBreaks = (text.match(/\n\n/g) || []).length;

        const scrollbarWidth = Platform.OS === 'web' ? 10 : 0;
        // const replyHeight = section.message.repliedTo ? 64 : 0;
        const horizontalPadding = extraContentWidth + scrollbarWidth;

        const viewPortWidth = isInsitu
          ? 320 - horizontalPadding
          : (window >= 760 ? window - 410 : window) - horizontalPadding;

        // const textWidth = getWidth(text, {
        //   font: 'Quicksand-Medium',
        //   size: Math.round(fontSize * PixelRatio.getFontScale()),
        //   lineWidth: viewPortWidth,
        // });

        const totalTopics = section.message?.topics?.length;

        const topicsHeights =
          totalTopics > 0 &&
          (showTopicsItems.includes(section.message.id) || showMeta)
            ? getTopicsHeight(section.message?.topics, viewPortWidth)
            : 0;

        // const topicsHeight = totalTopics > 0 ? 12.5 : 0;

        /// -------- DEBUG --------
        // if (
        //   section.message.id === 'ad03a27d-d2ed-4dbc-86db-db2433fe4688' &&
        //   Platform.OS === 'web'
        // ) {
        //   console.log(
        //     `sectionHeaderHeight: ${sectionHeaderHeight}\n`,
        //     `extraContentHeight: ${extraContentHeight}\n`,
        //     `extraContentWidth: ${extraContentWidth}\n`,
        //     `propToCheck: ${propToCheck}\n`,
        //     `fontSize: ${fontSize}\n`,
        //     `showMeta: ${showMeta}\n`,
        //     `metaHeight: ${metaHeight}\n`,
        //     `imageHeight: ${imageHeight}\n`,
        //     `lineHeight: ${lineHeight}\n`,
        //     `attachmentHeight: ${attachmentHeight}\n`,
        //     `isNonPro: ${isNonPro}\n`,
        //     `currentUserId: ${currentUserId}\n`,
        //     `isInsitu: ${isInsitu}\n`,
        //     `showMoreItems: ${showMoreItems}\n`,
        //   );
        // }
        /// -------- END DEBUG --------

        const isCurrentUser = currentUserId === section.message.sender.id;

        const currentUserGroup = section.message?.copies?.find(
          (copy) =>
            copy.recipient.id === currentUserId && !!copy.recipient.group,
        )?.recipient?.group;
        const currentUserInGroup = !!currentUserGroup;
        const senderInGroup = !!section.message.sender.group;

        const currentUserInSameGroupAsSender =
          senderInGroup &&
          section.message.sender?.group?.id === currentUserGroup?.id;

        let metaCount = 0;
        const otherMessage = section.message.copies.find(
          (copy) => copy.recipient.id !== currentUserId,
        );
        if (showMeta) {
          if (otherMessage.readAt) metaCount += 1;
          if (otherMessage.deliveredAt) metaCount += 1;
          if (section.message.sentAt) metaCount += 1;
        } else {
          if (
            (otherMessage.readAt &&
              (isCurrentUser ||
                (currentUserInSameGroupAsSender &&
                  conversationType !== 'firm' &&
                  conversationType !== 'contact'))) ||
            (otherMessage.deliveredAt &&
              (isCurrentUser ||
                (currentUserInSameGroupAsSender &&
                  conversationType !== 'firm' &&
                  conversationType !== 'contact'))) ||
            (section.message.sentAt &&
              (isCurrentUser ||
                (currentUserInSameGroupAsSender &&
                  conversationType !== 'firm' &&
                  conversationType !== 'contact')))
          ) {
            metaCount = 1;
          }
        }

        const attachments = section.message.attachments;

        let attachmentHeights =
          attachments.length > 0
            ? isCurrentUser ||
              (currentUserInSameGroupAsSender &&
                conversationType !== 'contact' &&
                conversationType !== 'firm') ||
              showMeta ||
              totalTopics > 0
              ? 20
              : 15
            : 0;

        if (attachments.length > 0) {
          attachments.forEach((_, index) => {
            let rowHeight = 0;
            if (window >= 760 && !isInsitu) {
              if (index % 2 === 0) {
                rowHeight = attachmentHeight;
              }
            } else {
              rowHeight = attachmentHeight;
            }

            attachmentHeights = attachmentHeights + rowHeight;
          });
        }

        const numberOfLines = getLineCount(text, {
          font: 'Quicksand-Medium',
          size: Math.round(fontSize * PixelRatio.getFontScale()),
          lineWidth: viewPortWidth,
        });

        const textHeight =
          compactMessages && numberOfLines > 4
            ? showMoreItems.includes(section.message.id)
              ? numberOfLines * lineHeight + 10
              : 4 * lineHeight +
                10 +
                (isCurrentUser ||
                (currentUserInSameGroupAsSender &&
                  conversationType !== 'firm' &&
                  conversationType !== 'contact')
                  ? 0
                  : 12)
            : numberOfLines * lineHeight;

        if (metaCount === 0 && totalTopics > 0) {
          metaCount += 1;
        }

        const metaHeights = metaCount * metaHeight * PixelRatio.getFontScale();

        let height = 0;

        /// -------- DEBUG --------
        // if (
        //   section.message.id === 'ad03a27d-d2ed-4dbc-86db-db2433fe4688' &&
        //   Platform.OS === 'web'
        // ) {
        //   console.log(`${textHeight}, ${numberOfLines}, ${numberOfLineBreaks}`);
        // }
        /// -------- END DEBUG --------

        if (
          !isCurrentUser &&
          (!currentUserInSameGroupAsSender ||
            conversationType === 'firm' ||
            conversationType === 'contact') &&
          !section.message.readAt
        ) {
          height = (extraContentHeight - 30) * PixelRatio.getFontScale();
        } else {
          height =
            textHeight +
            (extraContentHeight -
              (!showMeta &&
              totalTopics === 0 &&
              !isCurrentUser &&
              (!currentUserInSameGroupAsSender ||
                conversationType === 'firm' ||
                conversationType === 'contact')
                ? 15
                : 0)) *
              PixelRatio.getFontScale() +
            // replyHeight +
            metaHeights +
            attachmentHeights +
            topicsHeights +
            (showMeta ? 5 : 0);
          // topicsHeight;
        }

        heights.push(height);
      } else if (section.type === 'date') {
        heights.push(sectionHeaderHeight);
      } else if (section.type === 'reply') {
        heights.push(35);
      }
    });
  }

  return heights;
}

/**
 * Resize arbitary width x height region to fit inside another region.
 *
 * Conserve aspect ratio of the orignal region. Useful when shrinking/enlarging
 * images to fit into a certain area.
 *
 * @param {Number} srcWidth Source area width
 *
 * @param {Number} srcHeight Source area height
 *
 * @param {Number} maxWidth Fittable area maximum available width
 *
 * @param {Number} srcWidth Fittable area maximum available height
 *
 * @return {Object} { width, heigth }
 *
 */
export function calculateAspectRatioFit(
  srcWidth: number,
  srcHeight: number,
  maxWidth: number,
  maxHeight: number,
): { width: number; height: number } {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

  return { width: srcWidth * ratio, height: srcHeight * ratio };
}
