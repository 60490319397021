import * as React from 'react';
import { Svg, Path } from 'react-native-svg';
import { white } from '../../configs/colours';
import { StyleProp, ViewStyle } from 'react-native';

interface Props {
  fill?: string;
  width?: number;
  height?: number;
  style?: StyleProp<ViewStyle>;
}

const CloseIcon: React.FunctionComponent<Props> = ({
  fill = white,
  width = 10.589,
  height = 10.589,
  style,
}) => {
  return (
    <Svg
      height={height}
      viewBox="0 0 10.589 10.589"
      width={width}
      style={style}
    >
      <Path
        fill="none"
        stroke={fill}
        strokeWidth="1.5"
        d="M5.295 5.295l4.764 4.764-4.764-4.764L10.059.53 5.295 5.295.53.53l4.765 4.765L.53 10.059z"
      />
    </Svg>
  );
};
export default CloseIcon;
