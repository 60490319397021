import * as React from 'react';
import { Animated, Platform } from 'react-native';
import { G, Path, Svg } from 'react-native-svg';
import { darkGrey } from '../../configs/colours';
import { useAnimation } from '../../hooks/useAnimation';

interface Props {
  toggled: boolean;
  fill?: string;
}

const AnimatedPath = Animated.createAnimatedComponent(Path);

const Menu: React.FunctionComponent<Props> = ({
  toggled = true,
  fill = darkGrey,
}) => {
  const animationValue = useAnimation({
    type: 'spring',
    initialValue: 0,
    useNativeDriver: Platform.OS !== 'web',
    toValue: toggled ? 1 : 0,
  });

  return (
    <Svg height="14.773" viewBox="0 0 18.104 14.773" width="18.104">
      <G fill="none" stroke={fill} strokeLinecap="square" strokeWidth="2">
        <AnimatedPath
          d="m1 1h16.104"
          // @ts-ignore // TODO: check this
          style={{
            transform: [
              {
                rotate: animationValue.interpolate({
                  inputRange: [0, 1],
                  outputRange: ['0deg', '-45deg'],
                }) as any,
              },
              {
                translateX: animationValue.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0, -8],
                }) as any,
              },
              {
                translateY: animationValue.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0, 10],
                }) as any,
              },
            ],
          }}
        />
        <AnimatedPath
          d="m1 7.387h16.104"
          // @ts-ignore // TODO: check this
          style={{
            opacity: animationValue.interpolate({
              inputRange: [0, 1],
              outputRange: [1, 0],
            }) as any,
          }}
        />
        <AnimatedPath
          d="m1 13.773h16.104"
          // @ts-ignore // TODO: check this
          style={{
            transform: [
              {
                rotate: animationValue.interpolate({
                  inputRange: [0, 1],
                  outputRange: ['0deg', '45deg'],
                }) as any,
              },
              {
                translateX: animationValue.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0, 3],
                }) as any,
              },
              {
                translateY: animationValue.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0, -15],
                }) as any,
              },
            ],
          }}
        />
      </G>
    </Svg>
  );
};
export default Menu;
