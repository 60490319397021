import { useEffect, useState } from 'react';
import { offlineLink } from './../client/apolloClient';

function useDetectOffline(setOfflineLink = false) {
  const [onlineStatus, setOnlineStatus] = useState<'online' | 'offline'>(
    'online',
  );

  function closeLink() {
    setOnlineStatus('offline');
    if (setOnlineStatus) {
      offlineLink.close();
    }
  }

  function openLink() {
    setOnlineStatus('online');
    if (setOfflineLink) {
      offlineLink.open();
    }
  }

  // Detect online status and queue requests depending on
  useEffect(() => {
    window.addEventListener('offline', closeLink);
    window.addEventListener('online', openLink);

    return () => {
      window.removeEventListener('offline', closeLink);
      window.removeEventListener('online', openLink);
    };
  }, []);

  return onlineStatus;
}

export default useDetectOffline;
