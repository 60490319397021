import React, {
  ComponentType,
  ReactNode,
  useContext,
  useEffect,
  useRef,
} from 'react';
import {
  StyleSheet,
  Text,
  View,
  StyleProp,
  ViewStyle,
  TextStyle,
  useWindowDimensions,
} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import {
  Menu,
  MenuOption,
  MenuOptions,
  MenuTrigger,
  renderers,
} from 'react-native-popup-menu';
import ChevronDownIcon from './icons/ChevronDownIcon';
import { ThemeContext } from './ThemeProvider';
import { blue, white } from '../configs/colours';
import screenSizes from '../configs/screenSizes';
import PlatformTouchable from './PlatformTouchable';

interface SelectListOption {
  text: string;
  value: string;
  action?: () => void;
  icon?: ReactNode;
  colour?: string;
  image?: string;
}

interface SelectListItemProps {
  onSelect: (value: string) => void;
  selectedValue?: string;
  listStyle?: 'default' | 'border';
  isLast: boolean;
}

interface SelectListProps {
  options: SelectListOption[];
  onSelect: (value: string) => void;
  selectedValue?: string;
  placeholder?: string;
  label?: string;
  listStyle?: 'default' | 'border';
  style?: StyleProp<ViewStyle>;
  selectedTextStyle?: StyleProp<TextStyle>;
  placeholderStyle?: StyleProp<TextStyle>;
  menuStyle?: StyleProp<ViewStyle>;
  disabled?: boolean;
  invalid?: boolean;
  matchInputStyle?: boolean;
  required?: boolean;
  onPress?: () => void;
  onClear?: () => void;
  scrollToSelected?: boolean;
}

interface SelectIconProps {
  colour: string;
  icon: ReactNode;
  border?: boolean;
}

export const SelectIcon: React.FunctionComponent<SelectIconProps> = ({
  icon,
  colour,
  border = false,
}) => {
  return (
    <View
      style={[
        SelectIconStyle.main,
        { backgroundColor: colour },
        border && { borderColor: blue, borderWidth: 1 },
      ]}
    >
      {icon}
    </View>
  );
};

export const SelectListItem: React.FunctionComponent<
  SelectListItemProps & SelectListOption
> = ({
  text,
  action,
  value,
  icon,
  onSelect,
  colour,
  selectedValue,
  isLast = false,
  listStyle = 'default',
}) => {
  const { themeColours } = useContext(ThemeContext);

  return (
    <MenuOption
      onSelect={() => {
        if (action) {
          action();
        }
        onSelect(value);
      }}
      customStyles={{
        optionTouchable: {
          ...(listStyle === 'border' &&
            !isLast && { ...SelectListItemStyle.mainBorder }),
        },
        optionWrapper: {
          ...SelectListItemStyle.main,
          backgroundColor: themeColours.SelectList.background,
          ...(selectedValue === value && {
            backgroundColor: themeColours.SelectList.selected,
          }),
        },
      }}
    >
      <View style={[SelectListItemStyle.wrap]}>
        {icon && (
          <View>
            <SelectIcon icon={icon} colour={colour} />
          </View>
        )}
        <Text style={[SelectListItemStyle.text]}>{text}</Text>
      </View>
    </MenuOption>
  );
};

const SelectList: React.FunctionComponent<SelectListProps> = ({
  options,
  onSelect,
  selectedValue,
  placeholder = 'Select an option',
  listStyle = 'default',
  label,
  style,
  selectedTextStyle,
  placeholderStyle,
  menuStyle,
  disabled,
  invalid = false,
  matchInputStyle = false,
  required = false,
  onPress,
  onClear,
  scrollToSelected = true,
}) => {
  const { themeColours } = useContext(ThemeContext);
  const selectedOption = options.find(
    (option) => option.value === selectedValue,
  );
  const SelectedIcon = selectedOption && selectedOption.icon;
  const input = useRef();
  const { width } = useWindowDimensions();

  const scroll = useRef<ScrollView>();

  return (
    <Menu
      renderer={renderers.NotAnimatedContextMenu}
      onOpen={() => {
        setTimeout(() => {
          if (scrollToSelected && scroll.current && selectedValue) {
            const selectedIndex = options.findIndex(
              (option) => option.value === selectedValue,
            );

            scroll.current.scrollTo({
              y: selectedIndex * 33,
              x: 0,
              animated: false,
            });
          }
        }, 300);
      }}
    >
      {label && (
        <View
          style={[
            matchInputStyle && SelectListStyle.labelWrap,
            width > screenSizes.medium &&
              matchInputStyle &&
              SelectListStyle.labelWrapDesktop,
          ]}
        >
          <Text
            style={[
              SelectListStyle.label,
              { color: themeColours.Input.label },
              matchInputStyle && SelectListStyle.selectListLabel,
              width > screenSizes.medium &&
                matchInputStyle &&
                SelectListStyle.selectListLabelDesktop,
              invalid && {
                color: '#FCC12D',
              },
            ]}
          >
            {`${label}${required && !invalid ? ' *' : ''}`}
          </Text>
        </View>
      )}
      <MenuTrigger
        customStyles={{
          triggerOuterWrapper: { flex: 1 },
          triggerWrapper: { flex: 1 },
        }}
        disabled={disabled}
        onPress={onPress}
      >
        <View
          style={[
            SelectListStyle.buttonWrap,
            listStyle === 'border' && SelectListStyle.buttonWrapBorder,
            { backgroundColor: themeColours.SelectList.background },
            matchInputStyle && SelectListStyle.selectList,
            width > screenSizes.medium &&
              matchInputStyle &&
              SelectListStyle.selectListDesktop,
            style,
            invalid && {
              borderColor: '#FCC12D',
            },
          ]}
          ref={input}
        >
          {selectedOption ? (
            <>
              {SelectedIcon && (
                <View>
                  <SelectIcon
                    icon={selectedOption.icon}
                    colour={selectedOption.colour}
                  />
                </View>
              )}
              <Text
                style={[
                  SelectListStyle.selectText,
                  matchInputStyle && SelectListStyle.selectListText,
                  matchInputStyle &&
                    width > screenSizes.medium &&
                    SelectListStyle.selectListTextDesktop,
                  selectedTextStyle,
                  invalid && {
                    color: '#FCC12D',
                  },
                ]}
              >
                {selectedOption.text}
              </Text>
            </>
          ) : (
            <Text
              style={[
                SelectListStyle.selectText,
                matchInputStyle && SelectListStyle.selectListPlaceholder,
                matchInputStyle &&
                  width > screenSizes.medium &&
                  SelectListStyle.selectListPlaceholderDesktop,
                placeholderStyle,
              ]}
            >
              {placeholder}
            </Text>
          )}
          {!!onClear && !!selectedOption && !disabled && (
            <PlatformTouchable
              onPress={onClear}
              style={[SelectListStyle.clearButton]}
            >
              <Text style={[SelectListStyle.clearText]}>clear</Text>
            </PlatformTouchable>
          )}
          {!!!disabled && <ChevronDownIcon />}
        </View>
      </MenuTrigger>
      <MenuOptions
        optionsContainerStyle={[
          SelectListStyle.menu,
          listStyle === 'border' && SelectListStyle.menuBorder,
          matchInputStyle && SelectListStyle.selectListMenu,
          menuStyle,
        ]}
      >
        <ScrollView
          style={[SelectListStyle.scrollView]}
          contentContainerStyle={{ padding: 0 }}
          ref={scroll}
        >
          {options.map((option, index) => (
            <SelectListItem
              key={option.value}
              {...option}
              selectedValue={selectedValue}
              onSelect={onSelect}
              listStyle={listStyle}
              isLast={index === options.length - 1}
            />
          ))}
        </ScrollView>
      </MenuOptions>
    </Menu>
  );
};

export default SelectList;

const SelectListStyle = StyleSheet.create({
  menu: {
    marginTop: 30,
    backgroundColor: 'white',
    borderRadius: 3,
    shadowRadius: 6,
    shadowColor: 'rgba(0, 0, 0, 1.0)',
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 5 },
    elevation: 6,
    minWidth: 180,
    maxWidth: 280,
  },
  menuBorder: {
    borderWidth: 1,
    borderColor: '#E6E6E6',
    borderRadius: 5,
  },
  scrollView: {
    flex: 1,
    maxHeight: 180,
    padding: 0,
  },
  selectText: {
    color: '#AAA9A9',
    fontFamily: 'Quicksand-Medium',
    fontSize: 12,
    flex: 1,
  },
  buttonWrap: {
    width: '100%',
    alignItems: 'center',
    flex: 1,
    backgroundColor: 'white',
    borderRadius: 3,
    minHeight: 32,
    paddingVertical: 5,
    paddingHorizontal: 10,
    flexDirection: 'row',
  },
  buttonWrapBorder: {
    borderWidth: 1,
    borderColor: '#E6E6E6',
    borderRadius: 5,
    paddingVertical: 8,
    paddingHorizontal: 12,
  },
  label: {
    fontFamily: 'Quicksand-Bold',
    fontSize: 14,
    color: '#AAA9A9',
    marginBottom: 5,
  },
  selectList: {
    borderWidth: 1,
    borderRadius: 5,
    borderColor: '#6C7276',
    paddingVertical: 11,
    paddingHorizontal: 15,
    backgroundColor: '#303537',
    color: white,
    textAlignVertical: 'top',
    marginBottom: 15,
    minHeight: 0,
  },
  selectListDesktop: {
    paddingVertical: 7,
    paddingHorizontal: 10,
  },
  selectListText: {
    textAlignVertical: 'top',
    fontFamily: 'Quicksand-Regular',
    fontSize: 15,
    color: white,
  },
  selectListTextDesktop: {
    fontSize: 14,
  },
  selectListPlaceholder: {
    fontFamily: 'Quicksand-Regular',
    fontSize: 15,
    color: '#99A4A7',
  },
  selectListPlaceholderDesktop: {
    fontSize: 14,
    color: '#99A4A7',
  },
  selectListMenu: {
    backgroundColor: '#25282B',
    borderColor: '#4a4e50',
    borderWidth: 1,
  },
  selectListLabel: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 14,
    color: '#AAA9A9',
    marginBottom: 0,
  },
  selectListLabelDesktop: {
    fontSize: 12,
  },
  labelWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
    paddingLeft: 15,
  },
  labelWrapDesktop: {
    paddingLeft: 10,
  },
  clearButton: {
    position: 'absolute',
    top: 7,
    right: 25,
  },
  clearText: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 10.5,
    color: blue,
  },
});

const SelectListItemStyle = StyleSheet.create({
  main: {
    // paddingVertical: 10,
    // paddingHorizontal: 20,
    alignItems: 'center',
    flexDirection: 'row',
    minWidth: 180,
    // width: 220,
    maxWidth: 280,
  },
  mainBorder: {
    borderBottomWidth: 1,
    borderBottomColor: '#E6E6E6',
  },
  text: {
    color: '#AAA9A9',
    fontFamily: 'Quicksand-Medium',
    fontSize: 12,
  },
  wrap: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 4,
    paddingHorizontal: 6,
  },
});

const SelectIconStyle = StyleSheet.create({
  main: {
    width: 18,
    height: 18,
    borderRadius: 3,
    padding: 4,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 8,
  },
});
