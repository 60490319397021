import breadcrumbs from './breadcrumbs';
import currentUser from './currentUser';

export default {
  resolvers: {
    Mutation: {
      ...breadcrumbs.resolvers.Mutation,
      ...currentUser.resolvers.Mutation,
    },
  },
  defaults: {
    ...breadcrumbs.defaults,
    ...currentUser.defaults,
  },
};
