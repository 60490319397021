import { DataProxy } from 'apollo-cache';
import produce from 'immer';
import {
  GetTaskDocument,
  GetTaskQuery,
  GetTaskQueryVariables,
  UpdateTaskSectionMutation,
  UpdateTaskSectionMutationVariables,
} from '../../types/apolloTypes';

// Update Cache Function to update after pause mutation
export default function updateTaskSectionUpdate(
  cache: DataProxy,
  data?: UpdateTaskSectionMutation,
  variables?: UpdateTaskSectionMutationVariables,
) {
  if (!variables.renameInput.taskId) return;

  // List task query variables
  const taskId = variables.renameInput.taskId;

  // Try and update task query
  try {
    const getTaskQueryData = cache.readQuery<
      GetTaskQuery,
      GetTaskQueryVariables
    >({
      query: GetTaskDocument,
      variables: { taskId },
    });

    const nextTaskState = produce(getTaskQueryData, (draftState) => {
      if (draftState && draftState.task && data && data.describeTaskSection) {
        draftState.task = {
          ...draftState.task,
          sections: data.describeTaskSection.sections,
        };
      }
    });

    cache.writeQuery({
      query: GetTaskDocument,
      data: nextTaskState,
      variables: { taskId },
    });
  } catch (error) {
    if (error?.name !== 'Invariant Violation') {
      throw new Error(error);
    }
  }
}
