import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { Circle, Path, Rect, Svg } from 'react-native-svg';
import { blue, white } from '../../configs/colours';

interface Props {
  fill?: string;
  secondaryFill?: string;
  height?: number;
  width?: number;
  style?: StyleProp<ViewStyle>;
}

const ImageFileIcon: React.FunctionComponent<Props> = ({
  fill = blue,
  secondaryFill = white,
  width = 15.75,
  height = 15.75,
  style,
}) => {
  return (
    <Svg height={height} viewBox="0 0 15.75 15.75" width={width} style={style}>
      <Path d="M0,11.52s2.89.76,6-2.07,9-1.6,9-1.6v7.5H1.1Z" fill={fill} />
      <Rect width="15.75" height="15.75" rx="2" fill="none" />
      <Rect
        x="0.5"
        y="0.5"
        width="14.75"
        height="14.75"
        rx="1.5"
        fill="none"
        stroke={secondaryFill}
      />
      <Circle cx="4.45" cy="4.79" r="1.37" fill={fill} />
    </Svg>
  );
};

export default ImageFileIcon;
