import { format, parseISO } from 'date-fns/esm';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Image, Platform, StyleSheet, Text, View } from 'react-native';
import * as Yup from 'yup';
import { blue, white } from '../configs/colours';
import { useGetFirmMembership } from '../hooks/useGetFirm';
import useUpdateInputHeight from '../hooks/useUpdateInputHeight';
import associateNoteUpdate from '../mutations/updates/AssociateNote';
import discardNoteUpdate from '../mutations/updates/DiscardNote';
import draftNoteUpdate from '../mutations/updates/DraftNote';
import reviseNoteUpdate from '../mutations/updates/ReviseNote';
import {
  ClientAccountStatus,
  NoteCategory,
  useAssociateNoteMutation,
  useDraftNoteMutation,
  useGetCurrentUserQuery,
  useListClientAccountsForFirmQuery,
  useListMattersForClientLazyQuery,
  useMarkNoteViewedMutation,
  useReviseNoteMutation,
} from '../types/apolloTypes';
import { AuthoredNotesNote, NoteNoteRevision } from '../types/Notes';
import { getMatterType } from '../utils/getMatterHelpers';
import DateInput from './DateInput';
import { getDateFromISO, getTimeFromISO } from './DateTimePicker';
import Hoverable from './Hoverable';
import NotesIcon from './icons/NotesIcon';
import ModalInline from './ModalInline';
import PlatformTouchable from './PlatformTouchable';
import { QuestionPartStyles } from './QuestionFormParts/QuestionPart';
import SelectList from './SelectList';
import Textarea from './Textarea';
import TextInput from './TextInput';
const { string: stringYup, object } = Yup;

interface Props {
  note?: AuthoredNotesNote;
  revision?: NoteNoteRevision;
  editable?: boolean;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  clientId?: string;
}

const NoteForm: React.FunctionComponent<Props> = ({
  note,
  editable = true,
  visible,
  setVisible,
  revision,
  clientId,
}) => {
  const [draftNote] = useDraftNoteMutation();
  const [reviseNote] = useReviseNoteMutation();
  const [associateNote] = useAssociateNoteMutation();

  const { data: currentUserData } = useGetCurrentUserQuery();

  const [showRevision, setShowRevision] = useState<boolean>(false);
  const [activeRevision, setActiveRevision] = useState<NoteNoteRevision>();

  useEffect(() => {
    if (!showRevision) {
      setActiveRevision(undefined);
    }
  }, [showRevision]);

  const [markNoteViewed] = useMarkNoteViewedMutation({
    variables: {
      input: { noteId: note?.id ?? '' },
    },
  });

  const isOwn =
    !note?.id ||
    (note?.id && note?.author?.id === currentUserData.currentUser?.id);

  useEffect(() => {
    if (
      !note?.isViewed &&
      note?.author?.id !== currentUserData?.currentUser?.id &&
      note?.id
    ) {
      markNoteViewed();
    }
  }, []);

  const firmMembership = useGetFirmMembership();
  const firmId = firmMembership?.firm?.id;

  const { data: clientListData } = useListClientAccountsForFirmQuery({
    variables: {
      firmId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !!!firmId,
  });

  const formik = useFormik({
    initialValues: {
      title: note?.title ?? revision?.title ?? '',
      body: note?.body ?? revision?.body ?? '',
      dateTime: note?.dateTime ?? revision?.dateTime ?? '',
      category: note?.category ?? revision?.category ?? '',
      clientId: clientId ?? note?.clientAccount?.id ?? '',
      matterId: note?.matter?.id || '',
    },
    enableReinitialize: true,
    validationSchema: object().shape({
      title: stringYup().required('please write a title'),
      body: stringYup().required('please write the body of the note'),
      category: stringYup().required('please choose a category'),
    }),
    onSubmit: async (values) => {
      const chosenClient = clientListData?.clientAccountsForFirm?.items?.find(
        (client) => client.id === values.clientId,
      );

      setVisible(false);

      if (!note?.id) {
        const variables = {
          input: {
            title: values.title,
            body: values.body,
            category: values.category as NoteCategory,
            dateTime: values.dateTime,
          },
        };

        const note = {
          title: values.title,
          body: values.body,
          category: values.category as NoteCategory,
          dateTime: values.dateTime,
          isViewed: true,
          isPersonal: true,
          startedAt: '',
          revisions: [],
          versionNumber: 1,
        };

        const draft = await draftNote({
          variables,
          optimisticResponse: {
            draftNote: {
              ...note,
              __typename: 'Note',
              id: 'new-note',
              matter: values.matterId
                ? { __typename: 'Matter', id: values.matterId }
                : null,
              clientAccount: !!chosenClient
                ? {
                    __typename: 'ClientAccount',
                    id: chosenClient.id,
                    status: ClientAccountStatus.Active,
                    referenceNumber: chosenClient.referenceNumber,
                    client: {
                      __typename: 'FirmClient',
                      id: chosenClient.client.id,
                      preferredName: chosenClient.client.preferredName,
                      firstName: chosenClient.client.firstName,
                      lastName: chosenClient.client.lastName,
                      pictureUrl: chosenClient.client.pictureUrl,
                    },
                  }
                : null,
              author: {
                __typename: firmMembership?.professional?.__typename,
                id: currentUserData.currentUser?.id,
                preferredName: currentUserData?.currentUser?.preferredName,
                firstName: currentUserData?.currentUser?.familyName,
                lastName: currentUserData?.currentUser?.givenName,
                pictureUrl: currentUserData?.currentUser?.picture,
              },
            },
          },
          update: (cache, { data }) =>
            draftNoteUpdate(cache, data, currentUserData?.currentUser?.id),
        });
        if (!!chosenClient && draft.data.draftNote) {
          const associateVariables = {
            input: {
              noteId: draft.data.draftNote.id,
              clientAccountId: values.clientId,
              ...(values.matterId && { matterId: values.matterId }),
            },
          };
          associateNote({
            variables: associateVariables,
            optimisticResponse: {
              associateNote: {
                ...note,
                __typename: 'Note',
                id: draft.data.draftNote.id,
                matter: values.matterId
                  ? { __typename: 'Matter', id: values.matterId }
                  : null,
                author: {
                  __typename: firmMembership?.professional?.__typename,
                  id: currentUserData.currentUser?.id,
                  preferredName: currentUserData?.currentUser?.preferredName,
                  firstName: currentUserData?.currentUser?.familyName,
                  lastName: currentUserData?.currentUser?.givenName,
                  pictureUrl: currentUserData?.currentUser?.picture,
                },
                clientAccount: !!chosenClient
                  ? {
                      __typename: 'ClientAccount',
                      id: chosenClient.id,
                      status: ClientAccountStatus.Active,
                      referenceNumber: chosenClient.referenceNumber,
                      client: {
                        __typename: 'FirmClient',
                        id: chosenClient.client.id,
                        preferredName: chosenClient.client.preferredName,
                        firstName: chosenClient.client.firstName,
                        lastName: chosenClient.client.lastName,
                        pictureUrl: chosenClient.client.pictureUrl,
                      },
                    }
                  : null,
              },
            },
            update: (cache, { data }) =>
              associateNoteUpdate(cache, data, associateVariables),
          });
        }
      } else {
        const variables = {
          input: {
            noteId: note?.id,
            title: values.title,
            body: values.body,
            category: values.category as NoteCategory,
            dateTime: values.dateTime,
          },
        };
        if (
          formik.initialValues.body !== values.body ||
          formik.initialValues.category !== values.category ||
          formik.initialValues.title !== values.title ||
          formik.initialValues.dateTime !== values.dateTime
        ) {
          await reviseNote({
            variables,
            optimisticResponse: {
              reviseNote: {
                __typename: 'Note',
                id: note?.id,
                title: values.title,
                body: values.body,
                category: values.category as NoteCategory,
                dateTime: values.dateTime,
                author: {
                  __typename: firmMembership?.professional?.__typename,
                  id: currentUserData?.currentUser?.id,
                  preferredName: currentUserData?.currentUser?.preferredName,
                  firstName: currentUserData?.currentUser?.familyName,
                  lastName: currentUserData?.currentUser?.givenName,
                  pictureUrl: currentUserData?.currentUser?.picture,
                },
                matter: values.matterId
                  ? { __typename: 'Matter', id: values.matterId }
                  : null,
                clientAccount: !!chosenClient
                  ? {
                      __typename: 'ClientAccount',
                      id: chosenClient.id,
                      status: ClientAccountStatus.Active,
                      referenceNumber: chosenClient.referenceNumber,
                      client: {
                        __typename: 'FirmClient',
                        id: chosenClient.client.id,
                        preferredName: chosenClient.client.preferredName,
                        firstName: chosenClient.client.firstName,
                        lastName: chosenClient.client.lastName,
                        pictureUrl: chosenClient.client.pictureUrl,
                      },
                    }
                  : null,
                isViewed: note?.isViewed,
                isPersonal: note?.isPersonal,
                startedAt: note?.startedAt,
                revisions: note?.revisions,
                versionNumber: note?.versionNumber,
              },
            },
            update: (cache, { data }) =>
              reviseNoteUpdate(cache, data, currentUserData?.currentUser?.id),
          });
        }

        if (
          !!chosenClient &&
          (!!!note?.clientAccount ||
            chosenClient.id !== note?.clientAccount?.id)
        ) {
          associateNote({
            variables: {
              input: {
                noteId: note?.id,
                clientAccountId: values.clientId,
                ...(values.matterId && { matterId: values.matterId }),
              },
            },
            update: (cache) =>
              discardNoteUpdate(
                cache,
                {
                  __typename: 'Mutation',
                  discardNote: {
                    __typename: 'NoteDiscardedPayload',
                    noteId: note?.id,
                    discardedOn: '',
                  },
                },
                undefined,
                note?.clientAccount?.id,
              ),
          });
        }
      }
    },
    validateOnMount: true,
  });

  const [titleFocused, setTitleFocused] = useState<boolean>(false);
  const [bodyFocused, setBodyFocused] = useState<boolean>(false);

  const {
    onInputChange,
    inputHeight,
    textInput,
    updateInputHeight,
  } = useUpdateInputHeight({
    minHeight: 110,
  });

  useEffect(() => {
    if (textInput.current) {
      setTimeout(() => {
        if (Platform.OS === 'web' && textInput.current) {
          // @ts-ignore
          updateInputHeight(textInput.current);
        }
      }, 200);
    }
  }, [textInput.current]);

  const [showWarning, setShowWarning] = useState<boolean>(false);

  const chosenClient = clientListData?.clientAccountsForFirm?.items?.find(
    (client) => client.id === formik.values.clientId,
  );

  const [listMatters, { data: mattersData }] = useListMattersForClientLazyQuery(
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const canEdit = isOwn || editable;

  const [isEditable, setIsEditable] = useState<boolean>(!note && !revision);

  useEffect(() => {
    if (!!chosenClient) {
      listMatters({
        variables: {
          clientId: chosenClient.client.id,
        },
      });
    }
  }, [!!chosenClient]);

  const displayMatter =
    (!isEditable && !!note?.matter?.id) ||
    (isEditable && !!formik.values.clientId);

  return (
    <ModalInline
      title={
        note?.id
          ? isEditable
            ? `update note - version ${note?.versionNumber}`
            : `note preview - version ${note?.versionNumber}`
          : revision?.versionNumber
          ? `note preview - version ${revision?.versionNumber}`
          : 'draft note'
      }
      showModal={visible}
      size="large"
      close={() => setVisible(false)}
      actionText={
        canEdit && !isEditable
          ? 'edit'
          : isEditable && !revision
          ? 'save'
          : 'ok'
      }
      cancelText={
        (!isOwn && !editable) || revision?.versionNumber ? 'ok' : undefined
      }
      closeOnAction={false}
      actionDisabled={!formik.isValid}
      slimStyle={true}
      action={
        isEditable && !revision
          ? () => {
              if (!!!note?.clientAccount && formik.values.clientId) {
                setShowWarning(true);
              } else {
                formik.submitForm();
              }
            }
          : canEdit && !revision?.versionNumber
          ? () => setIsEditable(true)
          : undefined
      }
    >
      <View style={[NoteFormStyle.main]}>
        <Hoverable>
          {(isHovered: boolean) => (
            <TextInput
              onChangeText={(text) => formik.setFieldValue('title', text)}
              value={formik.values.title}
              style={[
                NoteFormStyle.title,
                (titleFocused || isHovered) && NoteFormStyle.titleFocused,
              ]}
              iconWrapStyle={{
                borderRightWidth: 0,
                width: 45,
                height: 45,
              }}
              focusOnMount={isEditable && !revision}
              margin={false}
              onFocus={() => {
                if (isOwn || editable) {
                  setTitleFocused(true);
                }
              }}
              onBlur={() => {
                setTitleFocused(false);
                formik.setFieldTouched('title');
              }}
              placeholder="type new note title..."
              icon={<NotesIcon />}
              iconPosition="START"
              editable={isEditable && !revision}
              invalid={!!formik.errors?.title && !!formik.touched.title}
            />
          )}
        </Hoverable>
        {!!formik.errors?.title && !!formik.touched.title && (
          <Text style={[QuestionPartStyles.errorText, { marginTop: 5 }]}>
            {formik.errors?.title}
          </Text>
        )}
        <Hoverable>
          {(isHovered: boolean) => (
            <Textarea
              inputRef={textInput}
              onChangeText={(text) => formik.setFieldValue('body', text)}
              value={formik.values.body}
              style={[
                NoteFormStyle.body,
                { minHeight: 110 },
                (bodyFocused ||
                  isHovered ||
                  (!!formik.errors?.body && !!formik.touched.body)) &&
                  NoteFormStyle.bodyFocused,
                Platform.OS === 'web' && {
                  // @ts-ignore
                  outlineStyle: 'none',
                  height: inputHeight,
                  overflow: 'hidden',
                },
              ]}
              numberOfLines={5}
              onFocus={() =>
                (editable || isOwn) &&
                !revision?.versionNumber &&
                setBodyFocused(true)
              }
              onBlur={() => {
                formik.setFieldTouched('body');
                setBodyFocused(false);
              }}
              invalid={!!formik.errors?.body && !!formik.touched.body}
              onChange={onInputChange}
              placeholder="type your note content here..."
              editable={isEditable && !revision}
            />
          )}
        </Hoverable>
        {!!formik.errors?.body && !!formik.touched.body && (
          <Text style={[QuestionPartStyles.errorText]}>
            {formik.errors?.body}
          </Text>
        )}
        <SelectList
          options={[
            { text: 'phone call', value: 'PHONE_CALL' },
            { text: 'meeting', value: 'MEETING' },
            { text: 'court', value: 'COURT' },
            { text: 'other', value: 'OTHER' },
          ]}
          placeholder="select a note type...."
          onSelect={(value) => {
            formik.setFieldTouched('category');
            formik.setFieldValue('category', value);
          }}
          onClear={() => formik.setFieldValue('category', '')}
          selectedValue={formik.values.category}
          label="note type"
          matchInputStyle={true}
          style={{
            borderColor: 'rgb(64, 69, 71)',
            backgroundColor: 'rgb(37, 40, 43)',
          }}
          disabled={!isEditable || !!revision}
          invalid={!!formik.errors?.category && !!formik.touched.category}
        />
        {!!formik.errors?.category && !!formik.touched.category && (
          <Text style={[QuestionPartStyles.errorText]}>
            {formik.errors?.category}
          </Text>
        )}
        <DateInput
          date={
            formik.values.dateTime ? getDateFromISO(formik.values.dateTime) : ''
          }
          time={
            formik.values.dateTime ? getTimeFromISO(formik.values.dateTime) : ''
          }
          label={`date & time`}
          placeholder="select when this occurred..."
          useTime={true}
          onClear={() => formik.setFieldValue('dateTime', '')}
          onChange={(date) => formik.setFieldValue('dateTime', date)}
          style={NoteFormStyle.input}
          editable={isEditable && !revision}
        />
        {((clientListData?.clientAccountsForFirm?.items?.length > 0 &&
          !revision?.versionNumber) ||
          (!!revision?.versionNumber && !!formik.values.clientId)) && (
          <SelectList
            options={clientListData?.clientAccountsForFirm?.items.map(
              (client) => ({
                text: `${client.client.preferredName} ${client.client.lastName}`,
                value: client.id,
              }),
            )}
            onClear={
              !!!note?.clientAccount?.id
                ? () => formik.setFieldValue('clientId', '')
                : undefined
            }
            placeholder="select a client..."
            matchInputStyle={true}
            style={{
              borderColor: 'rgb(64, 69, 71)',
              backgroundColor: 'rgb(37, 40, 43)',
            }}
            onSelect={(value) => formik.setFieldValue('clientId', value)}
            selectedValue={formik.values.clientId}
            label="associate with client"
            disabled={!isEditable || !!revision}
          />
        )}
        {displayMatter &&
          mattersData?.mattersForClient?.items &&
          mattersData?.mattersForClient?.items.length > 0 && (
            <SelectList
              options={mattersData?.mattersForClient?.items.map((matter) => ({
                text: getMatterType({ type: matter.type }),
                value: matter.id,
              }))}
              onClear={() => formik.setFieldValue('matterId', '')}
              placeholder="select a matter..."
              onSelect={(value) => formik.setFieldValue('matterId', value)}
              selectedValue={formik.values.matterId}
              label="associate with client's matter"
              matchInputStyle={true}
              style={{
                borderColor: 'rgb(64, 69, 71)',
                backgroundColor: 'rgb(37, 40, 43)',
              }}
              disabled={!isEditable || !!revision}
            />
          )}
        {(!!note?.id || !!revision?.versionNumber) && (
          <View style={[NoteFormStyle.meta]}>
            {!!note?.author && (
              <View style={[NoteFormStyle.authorWrap]}>
                {!!note?.author?.pictureUrl && (
                  <Image
                    source={{
                      uri: note?.author?.pictureUrl,
                    }}
                    style={[NoteFormStyle.authorImage]}
                  />
                )}

                <Text
                  style={[NoteFormStyle.author]}
                >{`author: ${note?.author.preferredName} ${note?.author.lastName}`}</Text>
              </View>
            )}
            <Text style={[NoteFormStyle.versionInfoTitle]}>
              version information
            </Text>
            <View style={[NoteFormStyle.versionInfoWrap]}>
              <Text style={[NoteFormStyle.metaText]}>version number</Text>
              <Text style={[NoteFormStyle.metaText]}>
                {note?.versionNumber ?? revision?.versionNumber}
              </Text>
            </View>
            <View style={[NoteFormStyle.versionInfoWrap]}>
              <Text style={[NoteFormStyle.metaText]}>modified at</Text>
              <Text style={[NoteFormStyle.metaText]}>
                {!!revision?.draftedAt
                  ? format(parseISO(revision?.draftedAt), 'dd MMM yyyy HH:mm')
                  : !!note?.revisions?.[0]?.draftedAt &&
                    format(
                      parseISO(note?.revisions?.[0]?.draftedAt),
                      'dd MMM yyyy HH:mm',
                    )}
              </Text>
            </View>
            <View style={[NoteFormStyle.versionInfoWrap]}>
              <Text style={[NoteFormStyle.metaText]}>modified by</Text>
              <View style={[NoteFormStyle.metaTextWrap]}>
                {!!revision?.author ? (
                  <>
                    {!!revision?.author?.pictureUrl && (
                      <Image
                        source={{
                          uri: revision?.author?.pictureUrl,
                        }}
                        style={[NoteFormStyle.authorImage]}
                      />
                    )}
                    <Text style={[NoteFormStyle.metaText]}>
                      {`${revision?.author?.preferredName} ${revision?.author?.lastName}`}
                    </Text>
                  </>
                ) : (
                  !!note?.revisions?.[0]?.author && (
                    <>
                      {!!note?.revisions?.[0]?.author?.pictureUrl && (
                        <Image
                          source={{
                            uri: note?.revisions?.[0]?.author?.pictureUrl,
                          }}
                          style={[NoteFormStyle.authorImage]}
                        />
                      )}
                      <Text style={[NoteFormStyle.metaText]}>
                        {`${note?.revisions?.[0]?.author?.preferredName} ${note?.revisions?.[0]?.author?.lastName}`}
                      </Text>
                    </>
                  )
                )}
              </View>
            </View>
            {note?.revisions?.length > 1 && (
              <View style={[NoteFormStyle.previousVersionsWrap]}>
                <Text style={[NoteFormStyle.versionInfoTitle]}>
                  previous versions
                </Text>
                {note?.revisions?.map(
                  (rev) =>
                    rev.versionNumber !== note.versionNumber && (
                      <View
                        style={[NoteFormStyle.versionInfoWrap]}
                        key={`rev-${rev.versionNumber}`}
                      >
                        <PlatformTouchable
                          onPress={() => {
                            setActiveRevision(rev);
                            setShowRevision(true);
                          }}
                        >
                          <Text
                            style={[NoteFormStyle.previousVersionText]}
                          >{`${rev.title} - version ${rev.versionNumber}`}</Text>
                        </PlatformTouchable>
                        <Text style={[NoteFormStyle.metaText]}>
                          {!!rev?.draftedAt &&
                            format(
                              parseISO(rev?.draftedAt),
                              'dd MMM yyyy HH:mm',
                            )}
                        </Text>
                      </View>
                    ),
                )}
              </View>
            )}
          </View>
        )}
      </View>
      <NoteForm
        editable={false}
        visible={showRevision}
        setVisible={setShowRevision}
        revision={activeRevision}
      />
      <ModalInline
        title="save note"
        close={() => setShowWarning(false)}
        showModal={showWarning}
        size="small"
        action={formik.submitForm}
        actionText="confirm"
      >
        <Text style={[NoteFormStyle.warningText]}>
          <Text style={[NoteFormStyle.warningTextWarning]}>warning: </Text>
          associating{' '}
          <Text
            style={[NoteFormStyle.warningTextClient]}
          >{`${chosenClient?.client?.preferredName} ${chosenClient?.client?.lastName}`}</Text>{' '}
          with this note is an irreversible action and will require a firm admin
          to change or remove the association.
        </Text>
      </ModalInline>
    </ModalInline>
  );
};

export default NoteForm;

const NoteFormStyle = StyleSheet.create({
  main: {
    padding: 20,
    paddingBottom: 20,
  },
  title: {
    borderColor: 'transparent',
    paddingHorizontal: 0,
    paddingVertical: 10,
    fontSize: 18,
    fontFamily: 'Quicksand-Medium',
    color: '#8E979A',
    backgroundColor: '#282C2E',
    marginBottom: 0,
  },
  titleFocused: {
    paddingHorizontal: 10,
    borderColor: '#4A4E50',
  },
  body: {
    borderColor: 'transparent',
    paddingHorizontal: 0,
    paddingVertical: 10,
    fontFamily: 'Quicksand-Medium',
    fontSize: 14,
    color: '#8E979A',
    backgroundColor: '#282C2E',
  },
  bodyFocused: {
    paddingHorizontal: 10,
    borderColor: '#4A4E50',
  },
  input: {
    backgroundColor: '#25282B',
    borderColor: '#404547',
  },
  warningText: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 13,
    color: '#AAA9A9',
  },
  warningTextWarning: {
    fontFamily: 'Quicksand-Bold',
    color: '#FBC001',
  },
  warningTextClient: {
    color: white,
    textDecorationLine: 'underline',
  },
  meta: {
    marginTop: 10,
    paddingVertical: 10,
    borderTopColor: '#4A4A4A',
    borderTopWidth: 1,
    marginBottom: 15,
  },
  authorWrap: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 10,
  },
  author: {
    fontFamily: 'Quicksand-Regular',
    fontSize: 12,
    color: '#AAA9A9',
  },
  authorImage: {
    width: 20,
    height: 20,
    borderRadius: 3,
    marginRight: 5,
  },
  versionInfoTitle: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 12,
    color: white,
    marginBottom: 5,
  },
  versionInfoWrap: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 5,
  },
  metaText: {
    fontFamily: 'Quicksand-Regular',
    fontSize: 12,
    color: '#AAA9A9',
  },
  metaTextWrap: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  previousVersionsWrap: {
    marginTop: 10,
  },
  previousVersionText: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 12,
    color: blue,
  },
});
