import { DataProxy } from 'apollo-cache';
import produce from 'immer';
import {
  DiscardNoteMutation,
  ListAuthoredNotesDocument,
  ListAuthoredNotesQuery,
  ListAuthoredNotesQueryVariables,
} from '../../types/apolloTypes';
import {
  ListClientAccountNotesDocument,
  ListClientAccountNotesQuery,
  ListClientAccountNotesQueryVariables,
} from './../../types/apolloTypes';

// Update Cache Function to update after pause mutation
export default function discardNoteUpdate(
  cache: DataProxy,
  data: DiscardNoteMutation,
  currentUserId?: string,
  clientAccountId?: string,
) {
  // Try and update question query
  if (currentUserId) {
    try {
      const listAuthoredNotesQueryData = cache.readQuery<
        ListAuthoredNotesQuery,
        ListAuthoredNotesQueryVariables
      >({
        query: ListAuthoredNotesDocument,
        variables: {
          authorId: currentUserId,
          page: 1,
          pageSize: 50,
        },
      });

      const nextAuthoredNotesState = produce(
        listAuthoredNotesQueryData,
        (draftState) => {
          if (draftState?.authoredNotes?.items && data?.discardNote) {
            draftState.authoredNotes.items = draftState.authoredNotes.items.filter(
              (note) => note.id !== data.discardNote.noteId,
            );
          }
        },
      );

      cache.writeQuery({
        query: ListAuthoredNotesDocument,
        data: nextAuthoredNotesState,
        variables: {
          authorId: currentUserId,
          page: 1,
          pageSize: 50,
        },
      });
    } catch (error) {
      if (error?.name !== 'Invariant Violation') {
        throw new Error(error);
      }
    }
  }
  if (clientAccountId) {
    try {
      const listAuthoredNotesQueryData = cache.readQuery<
        ListClientAccountNotesQuery,
        ListClientAccountNotesQueryVariables
      >({
        query: ListClientAccountNotesDocument,
        variables: {
          clientAccountId,
          page: 1,
          pageSize: 50,
        },
      });
      const nextAuthoredNotesState = produce(
        listAuthoredNotesQueryData,
        (draftState) => {
          if (draftState?.clientAccountNotes?.items && data?.discardNote) {
            draftState.clientAccountNotes.items = draftState.clientAccountNotes.items.filter(
              (note) => note.id !== data.discardNote.noteId,
            );
          }
        },
      );

      cache.writeQuery({
        data: nextAuthoredNotesState,
        query: ListClientAccountNotesDocument,
        variables: {
          clientAccountId,
          page: 1,
          pageSize: 50,
        },
      });
    } catch (error) {
      if (error?.name !== 'Invariant Violation') {
        throw new Error(error);
      }
    }
  }
}
