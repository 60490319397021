const widthsMap = {
  'quicksand-medium': {
    '0': [61, 61, 60, 61],
    '1': [35, 36, 33, 36],
    '2': [55, 54, 57, 54],
    '3': [52, 52, 52, 52],
    '4': [65, 71, 56, 71],
    '5': [57, 58, 55, 58],
    '6': [54, 55, 54, 55],
    '7': [55, 57, 52, 57],
    '8': [58, 59, 57, 59],
    '9': [55, 55, 55, 55],
    ' ': [28, 28, 27, 28],
    '!': [23, 27, 19, 27],
    '"': [41, 43, 38, 43],
    '#': [68, 69, 65, 69],
    $: [58, 60, 56, 60],
    '%': [80, 82, 78, 82],
    '&': [70, 71, 68, 71],
    "'": [20, 21, 19, 21],
    '(': [36, 36, 37, 36],
    ')': [36, 36, 37, 36],
    '*': [34, 36, 31, 36],
    '+': [57, 58, 56, 58],
    ',': [24, 28, 19, 28],
    '-': [39, 40, 39, 40],
    '.': [22, 25, 18, 25],
    '/': [55, 57, 52, 57],
    ':': [24, 27, 19, 27],
    ';': [25, 29, 20, 29],
    '<': [52, 53, 52, 53],
    '=': [56, 62, 48, 62],
    '>': [53, 53, 53, 53],
    '?': [51, 53, 49, 53],
    '@': [97, 96, 97, 96],
    A: [64, 64, 65, 64],
    B: [65, 66, 65, 66],
    C: [64, 63, 65, 63],
    D: [72, 72, 72, 72],
    E: [57, 57, 57, 57],
    F: [56, 56, 57, 56],
    G: [70, 70, 70, 70],
    H: [72, 72, 72, 72],
    I: [27, 28, 25, 28],
    J: [56, 55, 57, 55],
    K: [66, 66, 68, 66],
    L: [55, 56, 53, 56],
    M: [82, 82, 83, 82],
    N: [73, 74, 73, 74],
    O: [76, 76, 77, 76],
    P: [60, 60, 60, 60],
    Q: [76, 76, 78, 76],
    R: [68, 69, 67, 69],
    S: [58, 60, 56, 60],
    T: [62, 63, 60, 63],
    U: [71, 71, 71, 71],
    V: [67, 68, 67, 68],
    W: [95, 94, 97, 94],
    X: [63, 66, 61, 66],
    Y: [58, 60, 56, 60],
    Z: [65, 65, 64, 65],
    '[': [40, 41, 40, 41],
    '\\': [55, 57, 52, 57],
    ']': [40, 41, 40, 41],
    '^': [58, 62, 52, 62],
    _: [69, 70, 67, 70],
    '`': [25, 26, 23, 26],
    a: [61, 62, 60, 62],
    b: [61, 62, 60, 62],
    c: [51, 52, 51, 52],
    d: [61, 62, 60, 62],
    e: [57, 58, 56, 58],
    f: [39, 42, 35, 42],
    g: [63, 63, 62, 63],
    h: [58, 59, 57, 59],
    i: [23, 24, 21, 24],
    j: [26, 28, 24, 28],
    k: [56, 57, 54, 57],
    l: [25, 27, 23, 27],
    m: [92, 92, 91, 92],
    n: [59, 60, 58, 60],
    o: [61, 61, 60, 61],
    p: [61, 62, 60, 62],
    q: [61, 62, 60, 62],
    r: [41, 43, 39, 43],
    s: [48, 48, 47, 48],
    t: [38, 41, 34, 41],
    u: [58, 59, 57, 59],
    v: [55, 56, 53, 56],
    w: [76, 77, 75, 77],
    x: [49, 49, 48, 49],
    y: [58, 59, 57, 59],
    z: [48, 48, 48, 48],
    '{': [41, 40, 43, 40],
    '|': [23, 25, 21, 25],
    '}': [41, 40, 43, 40],
    '~': [51, 54, 46, 54],
  },
};

export default widthsMap;
