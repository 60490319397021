import { useCallback, useEffect } from 'react';
import { Platform } from 'react-native';
import {
  Breadcrumb,
  useBreadcrumbsQueryQuery,
  useUpdateBreadcrumbsMutation,
} from './../types/apolloTypes';

const useUpdateBreadcrumbs = () => {
  const { data: breadcrumbData } = useBreadcrumbsQueryQuery();

  const [updateBreadcrumbs] = useUpdateBreadcrumbsMutation();

  const updateAllBreadcrumbs = (breadcrumbs: Breadcrumb[]) => {
    updateBreadcrumbs({
      variables: {
        breadcrumbs,
      },
    });
    if (Platform.OS === 'web') {
      document.title = `${
        document.title.split('-')?.[0] || 'transparently'
      } - ${breadcrumbs?.[breadcrumbs.length - 1]?.name?.toLowerCase()}`;
    }
  };

  const pushBreadcrumb = useCallback(
    (breadcrumb: Breadcrumb) => {
      const lastBreadcrumb =
        breadcrumbData?.getBreadcrumbs[
          breadcrumbData?.getBreadcrumbs?.length - 1
        ];
      if (
        lastBreadcrumb?.name === breadcrumb?.name &&
        lastBreadcrumb?.to === breadcrumb?.to
      ) {
        return;
      }
      updateAllBreadcrumbs([
        ...(breadcrumbData?.getBreadcrumbs ?? []),
        breadcrumb,
      ]);
    },
    [JSON.stringify(breadcrumbData?.getBreadcrumbs)],
  );

  return {
    updateAllBreadcrumbs,
    pushBreadcrumb,
    breadcrumbs: breadcrumbData?.getBreadcrumbs ?? [],
  };
};

export const usePopLastOnUnmount = (breadcrumbs: Breadcrumb[]) => {
  const [updateBreadcrumbs] = useUpdateBreadcrumbsMutation({
    variables: {
      breadcrumbs,
    },
  });

  useEffect(() => {
    const newCrumbs = [...breadcrumbs];
    newCrumbs.pop();
    return () => {
      updateBreadcrumbs({
        variables: {
          breadcrumbs: newCrumbs,
        },
      });
    };
  }, []);
};

export default useUpdateBreadcrumbs;
