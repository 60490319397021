import useIsNonPro from './usIsNonPro';
import {
  useGetCurrentUserQuery,
  useGetClientProfileQuery,
} from '../types/apolloTypes';
import { useLocation } from '../utils/routing';

export default function useHasCompletedProfile(): boolean {
  const isNonPro = useIsNonPro();

  const { data: currentUserData } = useGetCurrentUserQuery();
  const { data: clientData } = useGetClientProfileQuery({
    variables: {
      clientId: currentUserData?.currentUser?.id,
    },
    skip: !isNonPro || (isNonPro && !!!currentUserData?.currentUser?.id),
  });

  return isNonPro && clientData?.client?.profileIsComplete;
}
