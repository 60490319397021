import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { Line, Svg, Circle } from 'react-native-svg';
import { blue } from '../../configs/colours';

interface Props {
  fill?: string;
  height?: number;
  width?: number;
  style?: StyleProp<ViewStyle>;
}

const PlusCircleIcon: React.FunctionComponent<Props> = ({
  fill = blue,
  width = 17,
  height = 17,
  style,
}) => {
  return (
    <Svg height={height} viewBox="0 0 17 17" width={width} style={style}>
      <Circle cx="8.5" cy="8.5" r="8.5" fill="none" />
      <Circle
        cx="8.5"
        cy="8.5"
        r="7.75"
        fill="none"
        stroke={fill}
        strokeWidth="1.5"
      />
      <Line
        x1="8.5"
        y1="5"
        x2="8.5"
        y2="12"
        fill="none"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <Line
        x1="5"
        y1="8.5"
        x2="12"
        y2="8.5"
        fill="none"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
    </Svg>
  );
};

export default PlusCircleIcon;
