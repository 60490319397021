import * as React from 'react';
import { Path, Rect, Svg } from 'react-native-svg';
import { ThemeContext } from '../ThemeProvider';

interface Props {
  fill?: string;
  secondaryFill?: string;
  stroke?: string;
  width?: number;
  height?: number;
}

const FinanceIcon: React.FunctionComponent<Props> = ({
  fill,
  secondaryFill,
  stroke,
  width = 32,
  height = 22,
}) => {
  const { themeColours } = React.useContext(ThemeContext);

  return (
    <Svg height={height} viewBox="0 0 32 22" width={width}>
      <Rect
        x="3.45"
        y="3.47"
        width="28.55"
        height="18.53"
        rx="2"
        fill={secondaryFill || themeColours.FinanceIcon.secondary}
      />
      <Rect
        width="28.55"
        height="18.53"
        rx="2"
        fill={fill || themeColours.FinanceIcon.fill}
      />
      <Rect
        x="0.5"
        y="0.5"
        width="27.55"
        height="17.53"
        rx="1.5"
        fill="none"
        stroke={stroke || themeColours.FinanceIcon.stroke}
        strokeLinejoin="round"
      />
      <Path
        d="M11,11.25a.5.5,0,0,1,.38.17.63.63,0,0,1,.14.4.66.66,0,0,1-.19.46,1.42,1.42,0,0,1-.58.34,3,3,0,0,1-1.17.24,9.88,9.88,0,0,1-1.79-.32,8.13,8.13,0,0,0-1.62-.31,4.87,4.87,0,0,0-1.25.2,1,1,0,0,0-.33.13.8.8,0,0,1-.34.11.47.47,0,0,1-.35-.15.54.54,0,0,1-.15-.38.61.61,0,0,1,.15-.41,1,1,0,0,1,.41-.28,8.68,8.68,0,0,1,1-.27,2.41,2.41,0,0,0,.32-1.26A5.23,5.23,0,0,0,5.37,8.3H4.54A.56.56,0,0,1,4,7.75H4a.47.47,0,0,1,.16-.38.66.66,0,0,1,.42-.15H5a4.25,4.25,0,0,1-.16-1.06A3.29,3.29,0,0,1,5.2,4.62a2.47,2.47,0,0,1,1-1.05A3.17,3.17,0,0,1,7.8,3.2,3.75,3.75,0,0,1,9,3.33a2.65,2.65,0,0,1,.94.49c.38.24.56.48.56.73a.64.64,0,0,1-.19.45.63.63,0,0,1-.46.2.57.57,0,0,1-.26,0A.75.75,0,0,1,9.44,5a2,2,0,0,0-1.55-.65,1.53,1.53,0,0,0-1.24.51,1.84,1.84,0,0,0-.46,1.27,3.74,3.74,0,0,0,.17,1H8.79a.54.54,0,0,1,.42.16.53.53,0,0,1,.16.41.48.48,0,0,1-.16.39.63.63,0,0,1-.42.16H6.72A4.77,4.77,0,0,1,7,9.65a2.87,2.87,0,0,1-.42,1.45,3.51,3.51,0,0,1,.65.09l.85.24c.37.11.65.18.85.22a2.7,2.7,0,0,0,.63.07,1.6,1.6,0,0,0,.53-.09,2.33,2.33,0,0,0,.49-.21A.91.91,0,0,1,11,11.25Z"
        fill={stroke || themeColours.FinanceIcon.stroke}
      />
    </Svg>
  );
};

export default FinanceIcon;
