import { formatDistanceToNowStrict, formatISO, parseISO } from 'date-fns/esm';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Animated,
  Image,
  Platform,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { black, blue } from '../configs/colours';
import { useAnimation } from '../hooks/useAnimation';
import markNotificationReadUpdate from '../mutations/updates/MarkNotificationRead';
import {
  MarkNotificationReadMutationVariables,
  useMarkNotificationReadMutation,
} from '../types/apolloTypes';
import { useHistory } from '../utils/routing';
import CloseIcon from './icons/CloseIcon';
import ConversationsIcon from './icons/ConversationsIcon';
import { NotificationContext } from './NotificationContext';
import PlatformTouchable from './PlatformTouchable';
import { NotificationsNotification } from '../types/Notifications';
import isElectron from 'is-electron';
import { getLink, getLinkName } from './NotificationListItem';
import useIsNonPro from '../hooks/usIsNonPro';
import ContactsIcon from './icons/ContactsIcon';
import FirmAdministrationIcon from './icons/FirmAdministrationIcon';
import MattersIcon from './icons/MattersIcon';
import useUpdateBreadcrumbs from '../hooks/useUpdateBreadcrumbs';

interface Props {
  removeNotification: (id: string) => void;
  hash: number;
}

export interface NotificationProps {
  duration?: number;
  onClose?: () => void;
}

const Notification: React.FunctionComponent<
  Props & NotificationProps & NotificationsNotification
> = ({
  id,
  duration = 0,
  removeNotification,
  hash,
  title,
  body,
  isRead,
  path,
  occurredOn,
  context,
  pictureUrl,
  type,
}) => {
  const when = formatDistanceToNowStrict(parseISO(occurredOn))?.split(' ');

  const [visible, setVisible] = useState<boolean>(true);

  const timeoutRef = useRef<NodeJS.Timeout>();
  const timeoutTime = useRef<number>();

  const { setNotificationsVisible, setTotalUnread, totalUnread } = useContext(
    NotificationContext,
  );

  const history = useHistory();

  const isNonPro = useIsNonPro();

  // Toggle spring animation from size menu
  const value = useAnimation({
    type: 'spring',
    initialValue: visible ? 0 : 1,
    useNativeDriver: Platform.OS !== 'web',
    toValue: visible ? 1 : 0,
  });

  const closeNotification = useCallback(async () => {
    await setVisible(false);
    timeoutRef.current = setTimeout(() => {
      removeNotification(id);
    }, 300);
  }, [hash]);

  useEffect(() => {
    if (duration > 0) {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(
        () => {
          closeNotification();
        },
        timeoutTime.current
          ? duration - (Date.now() - timeoutTime.current)
          : duration,
      );
      timeoutTime.current = Date.now();
    }
    return () => !!timeoutRef.current && clearTimeout(timeoutRef.current);
  }, [hash]);

  const variables: MarkNotificationReadMutationVariables = {
    input: {
      notificationId: id,
    },
  };

  const [markRead] = useMarkNotificationReadMutation({
    variables,
    optimisticResponse: {
      markNotificationRead: {
        __typename: 'Notification',
        body,
        id,
        isRead: true,
        readAt: formatISO(new Date()),
        title,
        type,
      },
    },
    update: (cache, { data }) =>
      markNotificationReadUpdate(cache, data, variables),
    onCompleted: () => {
      const newTotal = totalUnread - 1;
      setTotalUnread(newTotal);
      if (isElectron()) {
        const { app } = window.require('electron').remote;
        app?.dock?.setBadge(`${newTotal > 0 ? newTotal : ''}`);
      }
    },
  });

  const { updateAllBreadcrumbs } = useUpdateBreadcrumbs();

  return (
    <Animated.View
      style={{
        opacity: value,
        transform: [
          {
            translateY: value.interpolate({
              inputRange: [0, 1],
              outputRange: visible ? [-20, 0] : [0, 20],
            }) as any,
          },
        ],
      }}
    >
      <PlatformTouchable
        style={[NotificationStyle.main, NotificationStyle.unread]}
        onPress={() => {
          if (!isRead) {
            markRead();
          }
          const link = getLink(type, path, isNonPro);

          history.push(link);
          updateAllBreadcrumbs([
            {
              to: link,
              name: getLinkName(type, path, isNonPro),
            },
          ]);
          setNotificationsVisible(false);
          closeNotification();
        }}
      >
        <>
          {!!pictureUrl && (
            <View style={[NotificationStyle.imageWrap]}>
              <Image
                source={{
                  uri: pictureUrl,
                }}
                style={[NotificationStyle.image]}
              />
              {/* <View style={[NotificationStyle.unreadCircle]} /> */}
            </View>
          )}
          <View style={[NotificationStyle.textWrap]}>
            <Text numberOfLines={1} style={[NotificationStyle.title]}>
              {title?.toLowerCase()}
            </Text>
            <Text numberOfLines={1} style={[NotificationStyle.body]}>
              {body?.toLowerCase()}
            </Text>
          </View>
          <View style={[NotificationStyle.detailsWrap]}>
            {context === 'contacts' && <ContactsIcon />}
            {context === 'messaging' && <ConversationsIcon />}
            {context === 'firm-admin' && <FirmAdministrationIcon />}
            {context === 'matters' && <MattersIcon height={17} width={18} />}
            <Text style={[NotificationStyle.date]}>{`${
              when?.[0]
            }${when?.[1].substr(0, 1)}`}</Text>
          </View>
          <PlatformTouchable
            style={[NotificationStyle.closeButton]}
            onPress={closeNotification}
          >
            <CloseIcon width={7.5} height={7.5} fill="#728184" />
          </PlatformTouchable>
        </>
      </PlatformTouchable>
    </Animated.View>
  );
};

export default Notification;

const NotificationStyle = StyleSheet.create({
  main: {
    flexDirection: 'row',
    paddingVertical: 15,
    paddingHorizontal: 20,
    width: 300,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#234955',
    shadowColor: black,
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.5,
    shadowRadius: 15,
    elevation: 5,
    marginBottom: 10,
  },
  unread: {
    backgroundColor: '#2B363A',
  },
  imageWrap: {
    position: 'relative',
  },
  image: {
    width: 20,
    height: 20,
    borderRadius: 3,
  },
  unreadCircle: {
    position: 'absolute',
    backgroundColor: blue,
    top: -4,
    right: -4,
    width: 8,
    height: 8,
    borderRadius: 4,
  },
  textWrap: {
    flex: 1,
    paddingHorizontal: 15,
  },
  title: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 13,
    color: '#E3E3E3',
  },
  body: {
    color: '#AAA9A9',
    fontFamily: 'Quicksand-Medium',
    fontSize: 11,
    marginTop: 3,
  },
  detailsWrap: {},
  date: {
    color: '#728184',
    fontFamily: 'Quicksand-Medium',
    fontSize: 11,
    marginTop: 3,
  },
  closeButton: {
    marginLeft: 15,
  },
});
