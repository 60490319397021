import React, { useContext, MutableRefObject } from 'react';
import {
  NativeSyntheticEvent,
  StyleProp,
  StyleSheet,
  Text,
  TextInput,
  TextInputChangeEventData,
  TextStyle,
  View,
  Platform,
  useWindowDimensions,
} from 'react-native';
import { ThemeContext } from './ThemeProvider';
import HighlightCircle from './HighlightCircle';
import { white } from '../configs/colours';
import screenSizes from '../configs/screenSizes';

interface Props {
  label?: string;
  onChangeText?: (val: string) => void;
  onChange?: (e: NativeSyntheticEvent<TextInputChangeEventData>) => void;
  value: any;
  numberOfLines?: number;
  placeholder?: string;
  onBlur?: () => void;
  onFocus?: () => void;
  style?: StyleProp<TextStyle>;
  invalid?: boolean;
  instructions?: string;
  editable?: boolean;
  inputRef?: MutableRefObject<TextInput>;
  isHighlighted?: boolean;
  margin?: boolean;
}

const Textarea: React.FunctionComponent<Props> = ({
  label,
  onChangeText,
  value = '',
  numberOfLines = 4,
  placeholder = '',
  onBlur,
  onFocus,
  style,
  onChange,
  invalid,
  instructions,
  editable = true,
  inputRef,
  isHighlighted = false,
  margin = true,
}) => {
  const { themeColours, theme } = useContext(ThemeContext);
  const { width } = useWindowDimensions();

  return (
    <View>
      {!!label && (
        <View
          style={[
            TextareaStyle.labelWrap,
            width > screenSizes.medium && TextareaStyle.labelWrapDesktop,
          ]}
        >
          {!!label && (
            <Text
              style={[
                TextareaStyle.label,
                width > screenSizes.medium && TextareaStyle.labelDesktop,
                invalid && {
                  color: '#FBC001',
                },
                isHighlighted && {
                  marginRight: 8,
                },
              ]}
            >
              {label}
            </Text>
          )}
          {isHighlighted && (
            <HighlightCircle relative={true} top={0} right={0} />
          )}
        </View>
      )}
      {!!instructions && (
        <Text
          style={[
            TextareaStyle.instructions,
            { color: themeColours.Input.label },
            invalid && {
              color: '#FBC001',
            },
          ]}
        >
          {instructions}
        </Text>
      )}
      <View
        // pointerEvents={onPress ? 'none' : 'auto'}
        style={[margin && TextareaStyle.wrap]}
      >
        <TextInput
          ref={inputRef}
          style={[
            TextareaStyle.main,
            width > screenSizes.medium && TextareaStyle.mainDesktop,
            style,
            invalid && {
              borderColor: '#FBC001',
            },
          ]}
          aria-invalid={invalid}
          onFocus={onFocus}
          onChangeText={onChangeText}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          placeholderTextColor={themeColours.Input.placeholder}
          numberOfLines={numberOfLines}
          multiline={true}
          onBlur={onBlur}
          editable={editable}
          {...(Platform.OS !== 'web' && {
            textAlignVertical: 'top',
          })}
          keyboardAppearance={theme === 'DARK' ? 'dark' : 'light'}
        />
      </View>
    </View>
  );
};
export default Textarea;

const TextareaStyle = StyleSheet.create({
  wrap: {
    marginBottom: 15,
  },
  label: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 14,
    color: '#AAA9A9',
  },
  labelDesktop: {
    fontSize: 12,
  },
  labelWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
    paddingLeft: 15,
  },
  labelWrapDesktop: {
    paddingLeft: 10,
  },
  main: {
    borderWidth: 1,
    borderRadius: 5,
    borderColor: '#6C7276',
    paddingVertical: 11,
    paddingHorizontal: 15,
    backgroundColor: '#303537',
    color: white,
    fontFamily: 'Quicksand-Regular',
    fontSize: 15,
    textAlignVertical: 'top',
  },
  mainDesktop: {
    fontSize: 14,
    paddingVertical: 7,
    paddingHorizontal: 10,
  },
  instructions: {
    fontFamily: 'Quicksand-Regular',
    fontSize: 12,
    color: '#AAA9A9',
    marginBottom: 5,
  },
});
