import {
  addDays,
  format,
  isAfter,
  isBefore,
  isEqual,
  parseISO,
} from 'date-fns/esm';
import React, {
  Dispatch,
  lazy,
  SetStateAction,
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Picker, Platform, ScrollView, StyleSheet, View } from 'react-native';
import { blue, white, whiteRGBA } from '../configs/colours';
import { formatISO } from '../utils/dateFns';
import ChevronLeftIcon from './icons/ChevronLeftIcon';
import ChevronRightIcon from './icons/ChevronRightIcon';
import LoadingView from './LoadingView';
import SelectList from './SelectList';
import { ThemeContext } from './ThemeProvider';

const Calendar = lazy(() => import('react-native-calendars/src/calendar'));

const { Item: PickerItem } = Picker;

export interface DatePickerProps {
  useTime: boolean;
  time: string;
  date: string;
  timeRange?: [string, string];
  dateRange?: [string, string];
  closeOnSelect: boolean;
  onSelectDate: (date: string, range?: [string, string], time?: string) => void;
  onSelectTime: (time: string, range?: [string, string], date?: string) => void;
  showDatePicker: boolean;
  setShowDatePicker: Dispatch<SetStateAction<boolean>>;
  onChange?: (dateTime: string) => void;
  minDate?: string;
  maxDate?: string;
  useDateRange?: boolean;
}

export interface TimePickerProps {
  onSelectTime: (time: string, range?: [string, string]) => void;
  onChange?: (dateTime: string) => void;
  currentTime: string;
  currentTimeRange: [string, string];
  setCurrentTime: (time: string) => void;
  setCurrentTimeRange: (time: [string, string]) => void;
  currentDate: string;
  currentDateRange: [string, string];
  useDateRange: boolean;
  isStartDate?: boolean;
}

export function getTimeFromISO(time: string) {
  return format(parseISO(time), 'HH:mm').toLowerCase();
}

export function getDateFromISO(date: string) {
  return format(parseISO(date), 'yyyy-MM-dd');
}

export function getISOFromDateTime(date: string, time?: string) {
  if (!date) return;
  if (time) {
    return formatISO(new Date(`${date}T${time}:00`));
  }
  return formatISO(new Date(`${date}T00:00:00`));
}

const TimePicker: React.FunctionComponent<TimePickerProps> = ({
  currentTime,
  setCurrentTime,
  onSelectTime,
  onChange,
  currentDate,
  useDateRange,
  isStartDate,
  setCurrentTimeRange,
  currentTimeRange,
}) => {
  const { themeColours } = useContext(ThemeContext);
  return (
    <View
      style={[
        DateTimePickerStyle.timeWrap,
        useDateRange && !isStartDate && { marginTop: 10 },
      ]}
    >
      <Picker
        style={[
          DateTimePickerStyle.picker,
          Platform.OS === 'web' && DateTimePickerStyle.pickerWeb,
          {
            backgroundColor: themeColours.Modal.background,
            color: themeColours.Modal.title,
          },
        ]}
        itemStyle={[{ color: themeColours.Modal.title }]}
        selectedValue={
          useDateRange
            ? isStartDate
              ? currentTimeRange[0].substr(0, 2)
              : currentTimeRange[1].substr(0, 2)
            : currentTime.substr(0, 2)
        }
        onValueChange={(value) => {
          setCurrentTime(`${value}${currentTime.substr(2, 5)}`);
          if (useDateRange) {
            if (isStartDate) {
              setCurrentTimeRange([
                `${value}${currentTime.substr(2, 5)}`,
                currentTimeRange[1],
              ]);
              onSelectTime(`${value}${currentTime.substr(2, 5)}`, [
                `${value}${currentTime.substr(2, 5)}`,
                currentTimeRange[1],
              ]);
            } else {
              setCurrentTimeRange([
                currentTimeRange[0],
                `${value}${currentTime.substr(2, 5)}`,
              ]);
              onSelectTime(`${value}${currentTime.substr(2, 5)}`, [
                currentTimeRange[0],
                `${value}${currentTime.substr(2, 5)}`,
              ]);
            }
          } else {
            onSelectTime(`${value}${currentTime.substr(2, 5)}`);
          }
          onChange(
            getISOFromDateTime(
              currentDate,
              `${value}${currentTime.substr(2, 5)}`,
            ),
          );
        }}
      >
        {Array.from(Array(24), (_, i) => i).map((val) => {
          const valString = val.toString();
          const value =
            valString.length === 1 ? ('0' + valString).slice(-2) : valString;
          return <Picker.Item key={val} label={value} value={value} />;
        })}
      </Picker>
      <Picker
        style={[
          DateTimePickerStyle.picker,
          Platform.OS === 'web' && DateTimePickerStyle.pickerWeb,
          {
            backgroundColor: themeColours.Modal.background,
            // @ts-ignore
            color: themeColours.Modal.title,
          },
        ]}
        itemStyle={[{ color: themeColours.Modal.title }]}
        selectedValue={
          useDateRange
            ? isStartDate
              ? currentTimeRange[0].substr(3, 2)
              : currentTimeRange[1].substr(3, 2)
            : currentTime.substr(3, 2)
        }
        onValueChange={(value) => {
          setCurrentTime(`${currentTime.substr(0, 3)}${value}`);
          if (useDateRange) {
            if (isStartDate) {
              setCurrentTimeRange([
                `${currentTimeRange[0].substr(0, 3)}${value}`,
                currentTimeRange[1],
              ]);
              onSelectTime(`${currentTime.substr(0, 3)}${value}`, [
                `${currentTimeRange[0].substr(0, 3)}`,
                currentTimeRange[1],
              ]);
            } else {
              setCurrentTimeRange([
                currentTimeRange[0],
                `${currentTimeRange[1].substr(0, 3)}`,
              ]);
              onSelectTime(
                `${currentTime.substr(0, 3)}${value}${currentTime.substr(
                  5,
                  2,
                )}`,
                [
                  currentTimeRange[0],
                  `${currentTimeRange[1].substr(0, 3)}${value}`,
                ],
              );
            }
          } else {
            onSelectTime(`${currentTime.substr(0, 3)}${value}`);
          }

          onChange(
            getISOFromDateTime(
              currentDate,
              `${currentTime.substr(0, 3)}${value}`,
            ),
          );
        }}
      >
        {Array.from(Array(60).keys()).map((val) => {
          const valString = val.toString();
          const value =
            valString.length === 1 ? ('0' + valString).slice(-2) : valString;
          // @ts-ignore
          return <PickerItem key={val} label={value} value={value} />;
        })}
      </Picker>
    </View>
  );
};

const DateTimePicker: React.FunctionComponent<DatePickerProps> = ({
  date,
  time,
  closeOnSelect,
  onSelectDate,
  onSelectTime,
  setShowDatePicker,
  useTime,
  onChange,
  minDate,
  maxDate,
  dateRange,
  timeRange,
  useDateRange = false,
}) => {
  const { themeColours } = useContext(ThemeContext);
  const [currentDate, setCurrentDate] = useState<string>(date);
  const [currentDateRange, setCurrentDateRange] = useState<[string, string]>(
    dateRange || ['', ''],
  );
  const [currentTime, setCurrentTime] = useState<string>(time || '12:00');
  const [currentTimeRange, setCurrentTimeRange] = useState<[string, string]>(
    timeRange || ['12:00', '12:00'],
  );

  useEffect(() => {
    import('react-native-calendars').then((calendars) => {
      calendars.LocaleConfig.locales['en'] = {
        monthNames: [
          'january',
          'february',
          'march',
          'april',
          'may',
          'june',
          'july',
          'august',
          'september',
          'october',
          'november',
          'december',
        ],
        monthNamesShort: [
          'jan',
          'feb',
          'mar',
          'apr',
          'may',
          'jun',
          'jul',
          'aug',
          'sep',
          'oct',
          'nov',
          'dec',
        ],
        dayNames: [
          'sunday',
          'monday',
          'tuesday',
          'wednesday',
          'thursday',
          'friday',
          'saturday',
        ],
        dayNamesShort: ['sun', 'mon', 'tue', 'wed', 'thur', 'fri', 'sat'],
        today: 'today',
      };
      calendars.LocaleConfig.defaultLocale = 'en';
    });
  }, []);

  const getDateRangeDays = (startDate?: string, endDate?: string) => {
    if (!!!startDate && !!!endDate) return {};
    if (!!startDate && !!!endDate) {
      return {
        [startDate]: {
          selected: true,
          color: blue,
          textColor: white,
        },
      };
    }
    const days = [];

    if (!!startDate && !!endDate) {
      let theCurrentDate = new Date(startDate);

      while (
        isBefore(theCurrentDate, new Date(endDate)) ||
        isEqual(theCurrentDate, new Date(endDate))
      ) {
        days.push(format(theCurrentDate, 'yyyy-MM-dd'));
        theCurrentDate = addDays(theCurrentDate, 1);
      }

      return days.reduce((obj, item, index) => {
        return {
          ...obj,
          [item]: {
            selected: true,
            startingDay: index === 0,
            endingDay: index === days.length - 1,
            color: blue,
            textColor: white,
          },
        };
      }, {});
    }

    return {};
  };

  const calendar = useRef();

  const [dateMonth, setDateMonth] = useState<string>();

  const years = useMemo(
    () =>
      [...new Array(150)]
        .map((_, index) => {
          const val = (index + 1910).toString();
          return { value: val, text: val };
        })
        .filter(
          (year) =>
            ((minDate &&
              Number(year.value) >
                Number(format(parseISO(minDate), 'yyyy')) - 1) ||
              !minDate) &&
            ((maxDate &&
              Number(year.value) <
                Number(format(parseISO(maxDate), 'yyyy')) + 1) ||
              !maxDate),
        ),
    [maxDate, minDate],
  );

  return (
    <ScrollView style={[DateTimePickerStyle.wrap]}>
      <Suspense fallback={<LoadingView />}>
        <Calendar
          // Initially visible month. Default = Date()
          ref={calendar}
          firstDay={1}
          current={useDateRange ? currentDateRange[0] : currentDate}
          onDayPress={(day) => {
            setCurrentDate(day.dateString);

            if (useDateRange) {
              if (currentDateRange[0] && !currentDateRange[1]) {
                const endIsAfter = isAfter(
                  new Date(day.dateString),
                  new Date(currentDateRange[0]),
                );
                setCurrentDateRange(
                  endIsAfter
                    ? [currentDateRange[0], day.dateString]
                    : [day.dateString, currentDateRange[0]],
                );
                onSelectDate(
                  day.dateString,
                  endIsAfter
                    ? [currentDateRange[0], day.dateString]
                    : [day.dateString, currentDateRange[0]],
                );
              } else {
                setCurrentDateRange([day.dateString, '']);
                onSelectDate(day.dateString, [day.dateString, '']);
              }
            } else {
              onSelectDate(day.dateString);
            }
            if (closeOnSelect) {
              setShowDatePicker(false);
            }
            if (useTime && !time) {
              onSelectTime('12:00', [day.dateString, ''], day.dateString);
            }
            onChange(
              getISOFromDateTime(
                day.dateString,
                useTime && currentTime ? currentTime : undefined,
              ),
            );
          }}
          markingType={
            useDateRange && !!currentDateRange[1] ? 'period' : undefined
          }
          renderArrow={(direction) =>
            direction === 'left' ? (
              <ChevronLeftIcon fill={blue} />
            ) : (
              <ChevronRightIcon fill={blue} />
            )
          }
          minDate={minDate}
          maxDate={maxDate}
          markedDates={
            useDateRange
              ? getDateRangeDays(currentDateRange[0], currentDateRange[1])
              : {
                  [currentDate]: { selected: true, selectedColor: blue },
                }
          }
          style={[DateTimePickerStyle.calendar]}
          theme={{
            textDayFontFamily: 'Quicksand-Regular',
            textMonthFontFamily: 'Quicksand-Medium',
            textDayHeaderFontFamily: 'Quicksand-Regular',
            backgroundColor: themeColours.Modal.background,
            calendarBackground: themeColours.Modal.background,
            dayTextColor: themeColours.Modal.title,
            monthTextColor: themeColours.Modal.title,
            textDisabledColor: whiteRGBA(0.2),
          }}
          renderHeader={(date) => {
            return (
              <View style={[DateTimePickerStyle.header]}>
                <SelectList
                  options={[
                    { value: '0', text: 'january' },
                    { value: '1', text: 'february' },
                    { value: '2', text: 'march' },
                    { value: '3', text: 'april' },
                    { value: '4', text: 'may' },
                    { value: '5', text: 'june' },
                    { value: '6', text: 'july' },
                    { value: '7', text: 'august' },
                    { value: '8', text: 'september' },
                    { value: '9', text: 'october' },
                    { value: '10', text: 'november' },
                    { value: '11', text: 'december' },
                  ]}
                  placeholder="month"
                  placeholderStyle={{ marginRight: 5 }}
                  selectedValue={date.getMonth().toString()}
                  selectedTextStyle={DateTimePickerStyle.headerText}
                  onSelect={(value) => {
                    // @ts-ignore
                    calendar?.current?.updateMonth(
                      date.setMonth(parseInt(value)),
                    );
                    setDateMonth(`${date.getMonth()} ${date.getFullYear()}`);
                  }}
                />
                <SelectList
                  options={years}
                  selectedTextStyle={DateTimePickerStyle.headerText}
                  selectedValue={date.getFullYear().toString()}
                  placeholder="year"
                  placeholderStyle={{ marginRight: 5 }}
                  onSelect={(value) => {
                    // @ts-ignore
                    calendar?.current?.updateMonth(
                      date.setFullYear(value).setMonth(date.getMonth()),
                    );
                    setDateMonth(`${date.getMonth()} ${date.getFullYear()}`);
                  }}
                />
              </View>
            );
          }}
        />
        {useTime && (
          <View>
            <TimePicker
              currentTime={currentTime}
              currentDate={currentDate}
              currentTimeRange={currentTimeRange}
              currentDateRange={currentDateRange}
              onSelectTime={onSelectTime}
              setCurrentTime={setCurrentTime}
              setCurrentTimeRange={setCurrentTimeRange}
              onChange={onChange}
              useDateRange={useDateRange}
              isStartDate={true}
            />
            {useDateRange && (
              <TimePicker
                currentTime={currentTime}
                currentDate={currentDate}
                currentTimeRange={currentTimeRange}
                currentDateRange={currentDateRange}
                onSelectTime={onSelectTime}
                setCurrentTime={setCurrentTime}
                setCurrentTimeRange={setCurrentTimeRange}
                onChange={onChange}
                useDateRange={useDateRange}
              />
            )}
          </View>
        )}
      </Suspense>
    </ScrollView>
  );
};
export default DateTimePicker;

const DateTimePickerStyle = StyleSheet.create({
  wrap: {
    // flex: 1,
  },
  timeWrap: {
    height: Platform.OS === 'web' ? 'auto' : 60,
    overflow: 'hidden',
    flexDirection: 'row',
    // marginHorizontal: Platform.OS === 'web' ? -10 : 0,
  },
  picker: {
    marginTop: Platform.OS === 'ios' ? -80 : 0,
    width: '50%',
    // fontFamily: 'Quicksand-Medium',
  },
  pickerWeb: {
    marginHorizontal: 10,
    padding: 5,
    backgroundColor: 'white',
    borderColor: '#E2E2E2',
  },
  calendar: {
    marginBottom: 15,
    minHeight: Platform.OS === 'web' ? 346 : 0,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerText: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 14,
    color: white,
    marginRight: 5,
  },
});
