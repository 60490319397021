import React from 'react';
import {
  Image,
  Platform,
  StyleProp,
  StyleSheet,
  useWindowDimensions,
  View,
  ViewStyle,
} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import backgroundPng from '../../assets/backgrounds/background-semi-opacity.png';
import background from '../../assets/backgrounds/background-semi-opacity.svg';
import screenSizes from '../configs/screenSizes';
import useHasCompletedProfile from '../hooks/useHasCompletedProfile';
import useIsNonPro from '../hooks/usIsNonPro';
import ErrorBoundary from './ErrorBoundary';

interface Props {
  style?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  isScroll?: boolean;
  backgroundStyle?: 'IMAGE' | 'LOGO' | 'BLANK';
}

const PageWrap: React.FunctionComponent<Props> = ({
  children,
  style,
  containerStyle,
  isScroll = true,
  backgroundStyle = 'BLANK',
}) => {
  const { width, height } = useWindowDimensions();

  const isNonPro = useIsNonPro();

  const profileComplete = useHasCompletedProfile();

  const backgroundHeight =
    width > screenSizes.xxLarge
      ? 1625
      : width > screenSizes.medium
      ? 1161
      : 696;
  const backgroundWidth =
    width > screenSizes.xxLarge ? 1258 : width > screenSizes.medium ? 871 : 522;

  return isScroll ? (
    <ErrorBoundary>
      {backgroundStyle === 'LOGO' && (
        <View
          style={{
            height:
              height -
              (width > screenSizes.medium ? 100 : 72) -
              (isNonPro && !profileComplete ? 38 : 0),
            top: 0,
            left: 0,
            width: '100%',
            position: 'absolute',
            overflow: 'hidden',
          }}
        >
          <Image
            source={Platform.OS !== 'web' ? backgroundPng : background}
            style={[
              {
                top: 0,
                left: width > screenSizes.medium ? 0 : -50,
                width: '100%',
                minHeight: backgroundHeight,
                minWidth: backgroundWidth,
                height: '100%',
                flex: 1,
                position: 'absolute',
                zIndex: 0,
              },
            ]}
          />
        </View>
      )}
      <ScrollView
        style={[PageWrapStyle.main, style]}
        contentContainerStyle={[PageWrapStyle.container, containerStyle]}
      >
        {children}
      </ScrollView>
    </ErrorBoundary>
  ) : (
    <ErrorBoundary>
      {backgroundStyle === 'LOGO' && (
        <Image
          source={Platform.OS !== 'web' ? backgroundPng : background}
          style={[
            {
              top: 0,
              left: width > screenSizes.medium ? 0 : -50,
              width: '100%',
              minHeight: backgroundHeight,
              minWidth: backgroundWidth,
              height: '100%',
              flex: 1,
              position: 'absolute',
              zIndex: 0,
            },
          ]}
        />
      )}
      <View
        style={[
          PageWrapStyle.main,
          style,
          PageWrapStyle.container,
          containerStyle,
        ]}
      >
        {children}
      </View>
    </ErrorBoundary>
  );
};

export default PageWrap;

const PageWrapStyle = StyleSheet.create({
  main: {
    flex: 1,
    overflow: 'hidden',
    zIndex: 1,
  },
  container: {
    paddingHorizontal: 5,
    paddingVertical: 10,
  },
});
