import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { Path, Svg, Line, Rect, Circle, G } from 'react-native-svg';
import { blue } from '../../configs/colours';

interface Props {
  fill?: string;
  secondaryFill?: string;
  height?: number;
  width?: number;
  style?: StyleProp<ViewStyle>;
}

const NotesIcon: React.FunctionComponent<Props> = ({
  fill = blue,
  secondaryFill = '#e5f3f8',
  width = 16,
  height = 19.65,
  style,
}) => {
  return (
    <Svg height={height} viewBox="0 0 16 19.65" width={width} style={style}>
      <G>
        <G>
          <Rect y="1.25" width="16" height="18.4" rx="1" fill="#383d3f" />
          <Path
            d="M1,1.88H15a.37.37,0,0,1,.37.37v16.4A.38.38,0,0,1,15,19H1a.37.37,0,0,1-.37-.37V2.25A.37.37,0,0,1,1,1.88Z"
            fill="none"
            stroke={fill}
            strokeWidth="1.25"
          />
        </G>
        <Line
          x1="0.5"
          y1="5.25"
          x2="15.5"
          y2="5.25"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
        />
        <G>
          <G>
            <Line
              x1="5.25"
              y1="8.5"
              x2="12.75"
              y2="8.5"
              fill="none"
              stroke={secondaryFill}
              strokeLinecap="round"
            />
            <Line
              x1="5.25"
              y1="12"
              x2="12.75"
              y2="12"
              fill="none"
              stroke={secondaryFill}
              strokeLinecap="round"
            />
            <Line
              x1="5.25"
              y1="15.5"
              x2="12.75"
              y2="15.5"
              fill="none"
              stroke={secondaryFill}
              strokeLinecap="round"
            />
          </G>
          <Circle cx="3.25" cy="8.5" r="0.75" fill="#fff" />
          <Circle cx="3.25" cy="12" r="0.75" fill="#fff" />
          <Circle cx="3.25" cy="15.5" r="0.75" fill="#fff" />
        </G>
        <G>
          <Line
            x1="11"
            y1="0.5"
            x2="11"
            y2="3.25"
            fill="none"
            stroke={fill}
            strokeLinecap="round"
          />
          <Line
            x1="9"
            y1="0.5"
            x2="9"
            y2="3.25"
            fill="none"
            stroke={fill}
            strokeLinecap="round"
          />
          <Line
            x1="7"
            y1="0.5"
            x2="7"
            y2="3.25"
            fill="none"
            stroke={fill}
            strokeLinecap="round"
          />
          <Line
            x1="5"
            y1="0.5"
            x2="5"
            y2="3.25"
            fill="none"
            stroke={fill}
            strokeLinecap="round"
          />
          <Line
            x1="3"
            y1="0.5"
            x2="3"
            y2="3.25"
            fill="none"
            stroke={fill}
            strokeLinecap="round"
          />
          <Line
            x1="13"
            y1="0.5"
            x2="13"
            y2="3.25"
            fill="none"
            stroke={fill}
            strokeLinecap="round"
          />
        </G>
      </G>
    </Svg>
  );
};

export default NotesIcon;
