import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { Path, Svg } from 'react-native-svg';

interface Props {
  fill?: string;
  secondaryFill?: string;
  height?: number;
  width?: number;
  style?: StyleProp<ViewStyle>;
}

const TaskLibraryIcon: React.FunctionComponent<Props> = ({
  fill = '#02ace6',
  secondaryFill = '#979D9F',
  width = 18.71,
  height = 11.59,
  style,
}) => {
  return (
    <Svg height={height} viewBox="0 0 20.21 13.09" width={width} style={style}>
      <Path
        d="M1.06,4.19,2.68,5.63,5.84,1"
        transform="translate(-0.31 -0.29)"
        fill="none"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <Path
        d="M10.56,3.8h9.21"
        transform="translate(-0.31 -0.29)"
        fill={secondaryFill}
        stroke={secondaryFill}
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <Path
        d="M1.06,11.19l1.62,1.44L5.84,8"
        transform="translate(-0.31 -0.29)"
        fill="none"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <Path
        d="M10.56,10.8h9.21"
        transform="translate(-0.31 -0.29)"
        fill={secondaryFill}
        stroke={secondaryFill}
        strokeLinecap="round"
        strokeWidth="1.5"
      />
    </Svg>
  );
};

export default TaskLibraryIcon;
