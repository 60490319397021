import { format, isThisYear, parseISO } from 'date-fns/esm';
import React from 'react';
import {
  Image,
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
} from 'react-native';
import { blue, white } from '../configs/colours';
import screenSizes from '../configs/screenSizes';
import useIsNonPro from '../hooks/usIsNonPro';
import { SearchMatch } from '../types/Search';
import { useHistory } from '../utils/routing';
import ConversationsIcon from './icons/ConversationsIcon';
import DocumentFileIcon from './icons/DocumentFileIcon';
import FileIcon from './icons/FileIcon';
import FolderIcon from './icons/FolderIcon';
import ImageFileIcon from './icons/ImageFileIcon';
import NotesIcon from './icons/NotesIcon';
import TasksIcon from './icons/TasksIcon';
import TickIcon from './icons/TickIcon';
import VideoFileIcon from './icons/VideoFileIcon';
import PlatformTouchable from './PlatformTouchable';
import { TaskSectionListItemStyle } from './TaskSectionListItem';

interface Props {
  match: SearchMatch;
  onPress: () => void;
}

export function getTitle(match: SearchMatch) {
  if (match.__typename === 'MatchedClientAccount') return match.clientName;
  if (match.__typename === 'MatchedFile') return match.name;
  if (match.__typename === 'MatchedFolder') return match.name;
  if (match.__typename === 'MatchedTask') return match.name;
  if (match.__typename === 'MatchedContact') return match.name;
  if (match.__typename === 'MatchedNote') return match.title;
  if (match.__typename === 'MatchedMessage') {
    return match.title?.toLowerCase();
  }

  return '';
}

export function getType(match: SearchMatch) {
  if (match.__typename === 'MatchedClientAccount') return 'client account';
  if (match.__typename === 'MatchedFile') return 'file';
  if (match.__typename === 'MatchedFolder') return 'folder';
  if (match.__typename === 'MatchedTask') return 'task';
  if (match.__typename === 'MatchedMessage') return 'message';
  if (match.__typename === 'MatchedContact') return 'contact';
  if (match.__typename === 'MatchedNote') return 'note';

  return '';
}

export function getIcon(match: SearchMatch) {
  if (match.__typename === 'MatchedClientAccount') {
    return (
      <Image
        source={{ uri: match.clientPictureUrl }}
        style={[GlobalSearchResultStyle.picture]}
      />
    );
  }
  if (match.__typename === 'MatchedContact') {
    return (
      <Image
        source={{ uri: match.pictureUrl }}
        style={[GlobalSearchResultStyle.picture]}
      />
    );
  }
  if (match.__typename === 'MatchedFile') {
    if (match.type === 'document') return <DocumentFileIcon />;
    if (match.type === 'video') return <VideoFileIcon />;
    if (match.type === 'image') return <ImageFileIcon />;
    return <FileIcon />;
  }
  if (match.__typename === 'MatchedFolder') return <FolderIcon />;
  if (match.__typename === 'MatchedTask') return <TasksIcon />;
  if (match.__typename === 'MatchedMessage') return <ConversationsIcon />;
  if (match.__typename === 'MatchedNote') return <NotesIcon />;
  return null;
}

function getSecondaryTop(match: SearchMatch) {
  if (match.__typename === 'MatchedFile') {
    return (
      <Text style={[GlobalSearchResultStyle.topSecondaryText]}>
        {match.type}
      </Text>
    );
  }
  if (match.__typename === 'MatchedTask') {
    return (
      <View
        style={[
          TaskSectionListItemStyle.progressInfoWrap,
          TaskSectionListItemStyle.titleMarginLeft,
        ]}
      >
        <TickIcon fill={white} />
        <Text style={[TaskSectionListItemStyle.progressInfoText]}>
          {`${Math.floor(match.proportionComplete * 100)}%`}
        </Text>
      </View>
    );
  }
  return null;
}

function getText(match: SearchMatch) {
  if (match.__typename === 'MatchedMessage') {
    return match?.body ? `"${match?.body?.substr(0, 140) ?? ''}"` : '';
  }
  if (match.__typename === 'MatchedClientAccount') {
    return match.status.toLowerCase();
  }
  if (match.__typename === 'MatchedNote') {
    return match.noteClientName ? match.noteClientName : 'draft note';
  }
  if (match.__typename === 'MatchedContact') {
    if (match.isAssociatedWithFirm) {
      return 'professional';
    }
    if (match.isAssociatedWithIssue) {
      return 'other party';
    }
    if (
      match.isTransparentlyUser &&
      !match.isAssociatedWithFirm &&
      !match.isAssociatedWithIssue
    ) {
      return 'client';
    }
    return '';
  }
  if (match.__typename === 'MatchedFile') {
    const date = parseISO(match.lastRevisedOn);
    return `last modified: ${format(
      date,
      `HH:mm - eeee do MMMM${!isThisYear(date) ? ' yyyy' : ''}`,
    )}`;
  }
  if (match.__typename === 'MatchedFolder') {
    const date = parseISO(match.addedOn);
    return `added on: ${format(
      date,
      `HH:mm - eeee do MMMM${!isThisYear(date) ? ' yyyy' : ''}`,
    )}`;
  }
  if (match.__typename === 'MatchedTask' && !!match.dueDate) {
    const date = parseISO(match.dueDate);
    return `due on: ${format(
      date,
      `HH:mm - eeee do MMMM${!isThisYear(date) ? ' yyyy' : ''}`,
    )}`;
  }
  return null;
}

function getSubTitle(match: SearchMatch, isNonPro: boolean) {
  if (
    match.__typename === 'MatchedFolder' ||
    match.__typename === 'MatchedFile'
  ) {
    return match.path.reduce((prev, curr, index) => {
      if (index === 0) return 'file manager';
      if (curr.type === 'file') return `${prev} > ${curr.name}`;
      return `${prev} > ${curr.name}`;
    }, '');
  }
  if (match.__typename === 'MatchedTask') {
    if (isNonPro) {
      return `${match.path?.[0]?.name?.toLowerCase() ?? 'matter'} > tasks > ${
        match.path?.[1]?.name?.toLowerCase() ?? 'task'
      }`;
    }
    return `${match.clientName} > ${
      match.path?.[0]?.name?.toLowerCase() ?? 'matter'
    } > tasks > ${match.path?.[1]?.name?.toLowerCase() ?? 'task'}`;
  }
  if (match.__typename === 'MatchedNote') {
    return match?.category;
  }
  return null;
}

export function getLink(match: SearchMatch, isNonPro: boolean) {
  if (match.__typename === 'MatchedMessage') {
    return `/conversations/${match.conversationId}?message=${match.id}`;
  }
  if (match.__typename === 'MatchedClientAccount') {
    return `/clients/${match.id}`;
  }
  if (match.__typename === 'MatchedContact') {
    return `/contacts`;
  }
  if (match.__typename === 'MatchedNote') {
    if (match?.path?.[0]?.type === 'client-account') {
      return `/clients/${match?.path?.[0]?.id}/notes`;
    }
    return `/notes`;
  }
  if (
    match.__typename === 'MatchedFolder' ||
    match.__typename === 'MatchedFile'
  ) {
    return match.path.reduce((prev, curr, index) => {
      if (index === 0) return '/file-manager';
      if (curr.type === 'file') return `${prev}?file=${curr.id}`;
      return `${prev}/${curr.id}`;
    }, '');
  }
  if (match.__typename === 'MatchedTask') {
    if (isNonPro) return `/matters/${match.matterId}/tasks/${match.id}`;
    return `/clients/${match.clientAccountId}/matters/${match.matterId}/tasks/${match.id}`;
  }
  return '/';
}

const GlobalSearchResult: React.FunctionComponent<Props> = ({
  match,
  onPress,
}) => {
  const history = useHistory();
  const isNonPro = useIsNonPro();
  const { width } = useWindowDimensions();

  const text = getText(match);
  const subTitle = getSubTitle(match, isNonPro);

  return (
    <PlatformTouchable
      style={[
        GlobalSearchResultStyle.main,
        width > screenSizes.medium && GlobalSearchResultStyle.mainDesktop,
      ]}
      onPress={() => {
        const link = getLink(match, isNonPro);
        history.push(link);

        onPress();
      }}
    >
      <>
        {getIcon(match)}
        <View style={[GlobalSearchResultStyle.detailsWrap]}>
          <View style={[GlobalSearchResultStyle.topContent]}>
            <Text style={[GlobalSearchResultStyle.title]}>
              {getTitle(match)}
            </Text>
            {getSecondaryTop(match)}
          </View>
          {!!subTitle && (
            <Text style={[GlobalSearchResultStyle.subTitle]} numberOfLines={1}>
              {subTitle}
            </Text>
          )}
          {!!text && (
            <Text
              style={[GlobalSearchResultStyle.text]}
              numberOfLines={
                match.__typename === 'MatchedMessage' ? 1 : undefined
              }
            >
              {text}
            </Text>
          )}
        </View>
      </>
    </PlatformTouchable>
  );
};

export default GlobalSearchResult;

const GlobalSearchResultStyle = StyleSheet.create({
  main: {
    borderColor: '#234955',
    marginTop: -1,
    backgroundColor: '#2B363A',
    paddingHorizontal: 20,
    paddingVertical: 15,
    flexDirection: 'row',
    borderTopWidth: 1,
    borderBottomWidth: 1,
  },
  mainDesktop: {
    borderRadius: 4,
    borderLeftWidth: 1,
    borderRightWidth: 1,
  },
  detailsWrap: {
    paddingLeft: 15,
    flex: 1,
  },
  topContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 13,
    color: '#E3E3E3',
    paddingRight: 15,
    marginBottom: 5,
    flex: 1,
  },
  topSecondaryText: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 13,
    color: '#AAA9A9',
  },
  subTitle: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 11,
    color: blue,
    marginBottom: 5,
  },
  text: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 11,
    color: '#AAA9A9',
    flex: 1,
  },
  picture: {
    width: 20,
    height: 20,
    borderRadius: 3,
  },
});
