import React, { MutableRefObject, useContext, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { useImmer } from 'use-immer';
import { blue, white } from '../configs/colours';
import useIsNonPro from '../hooks/usIsNonPro';
import removeTaskSectionUpdate from '../mutations/updates/RemoveTaskSection';
import updateTaskSectionUpdate from '../mutations/updates/UpdateTaskSection';
import {
  useRemoveTaskSectionMutation,
  useUpdateTaskSectionMutation,
  useGetFullMatterQuery,
  useGetTaskQuery,
  RemoveTaskSectionMutationVariables,
} from '../types/apolloTypes';
import { TaskSections, TaskSectionsSection } from '../types/Tasks';
import { useHistory, useParams } from '../utils/routing';
import ContextMenu, { ContextMenuItem } from './ContextMenu';
import AssignmentIcon from './icons/AssignmentIcon';
import EditIcon from './icons/EditIcon';
import TickIcon from './icons/TickIcon';
import { MenuContext } from './MenuContextProvider';
import PlatformTouchable from './PlatformTouchable';
import Textarea from './Textarea';
import TextInput from './TextInput';
import { ThemeContext } from './ThemeProvider';
import HighlightCircle from './HighlightCircle';
import AdvicePopover from './AdvicePopover';
import useUpdateBreadcrumbs from '../hooks/useUpdateBreadcrumbs';

interface Props {
  sections: TaskSections;
  isFirst?: boolean;
  isLast?: boolean;
  taskId: string;
  matterId: string;
  activeSection: string;
  placeholder?: boolean;
  setEditable?: (id: string) => void;
  elementRef?: MutableRefObject<View>;
  canRemove: boolean;
}

const TaskSectionListItem: React.FunctionComponent<
  Props & TaskSectionsSection
> = ({
  name,
  description,
  isFirst,
  isLast,
  taskId,
  id,
  sections,
  activeSection,
  placeholder = false,
  elementRef,
  proportionComplete,
  proportionReleased,
  isHighlighted,
  canRemove = true,
}) => {
  const { setShowModal, updateModalProps } = useContext(MenuContext);
  const { themeColours } = useContext(ThemeContext);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [fields, setFields] = useImmer({
    name,
    description,
  });

  const history = useHistory();

  const { data: taskData } = useGetTaskQuery({
    variables: { taskId },
  });

  const {
    taskId: paramsTaskId = '',
    clientId = '',
    matterId = '',
  } = useParams();

  const taskSectionVariables: RemoveTaskSectionMutationVariables = {
    input: { taskId, sectionId: id },
  };

  const updateTaskSectionVariables = {
    describeInput: {
      ...taskSectionVariables.input,
      sectionDescription: fields.description,
    },
    renameInput: {
      ...taskSectionVariables.input,
      sectionName: fields.name,
    },
  };

  const [removeTaskSection] = useRemoveTaskSectionMutation({
    variables: taskSectionVariables,
    optimisticResponse: {
      removeTaskSection: {
        __typename: 'MatterTask',
        id: taskId,
        sections: sections
          ?.map((s) => ({ ...s, questions: [] }))
          .filter((s) => s.id !== id),
      },
    },
    // Update cache to add new draft
    update: (cache, { data }) =>
      removeTaskSectionUpdate(cache, data, taskSectionVariables),
  });

  const [updateTaskSection] = useUpdateTaskSectionMutation({
    variables: updateTaskSectionVariables,
    optimisticResponse: {
      renameTaskSection: {
        __typename: 'MatterTask',
        id: taskId,
      },
      describeTaskSection: {
        __typename: 'MatterTask',
        id: taskId,
        sections: sections?.map((s) => {
          if (s.id === id) {
            return {
              ...s,
              description: fields.description,
              name: fields.name,
              questions: [],
            };
          }
          return { ...s, questions: [] };
        }),
      },
    },
    // Update cache to add new draft
    update: (cache, { data }) =>
      updateTaskSectionUpdate(cache, data, updateTaskSectionVariables),
  });

  const isNonPro = useIsNonPro();

  const { data: { matter } = { matter } } = useGetFullMatterQuery({
    variables: {
      matterId,
    },
    skip: !matterId,
  });

  const { pushBreadcrumb } = useUpdateBreadcrumbs();

  return (
    <PlatformTouchable
      onPress={() => {
        if (!editMode) {
          isNonPro
            ? history.push(
                `/matters/${matterId}/tasks/${paramsTaskId}/sections/${id}`,
              )
            : history.push(
                `/clients/${clientId}/matters/${matterId}/tasks/${paramsTaskId}/sections/${id}`,
              );
        }
      }}
      style={[
        { display: 'flex' },
        id === activeSection && {
          zIndex: 1,
        },
      ]}
    >
      <View
        ref={elementRef}
        collapsable={false}
        style={[
          TaskSectionListItemStyle.main,
          {
            backgroundColor: '#212426',
          },
          isFirst && {
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3,
            marginTop: 0,
          },
          isLast && {
            borderBottomLeftRadius: 3,
            borderBottomRightRadius: 3,
          },
          isHighlighted && {
            backgroundColor: '#212426',
          },
          id === activeSection && {
            borderColor: '#48717E',
            backgroundColor: '#123540',
          },

          placeholder && {
            opacity: 0,
          },
        ]}
      >
        <View style={[TaskSectionListItemStyle.nameWrap]}>
          {editMode ? (
            <View style={[TaskSectionListItemStyle.nameInputWrap]}>
              <TextInput
                value={fields.name}
                onChangeText={(val) => setFields((f) => ({ ...f, name: val }))}
                placeholder="set the section name"
                style={TaskSectionListItemStyle.nameInput}
              />
            </View>
          ) : (
            <View style={[TaskSectionListItemStyle.nameTextWrap]}>
              <Text
                style={[
                  TaskSectionListItemStyle.name,
                  isHighlighted && {
                    marginRight: 10,
                    fontFamily: 'Quicksand-Bold',
                  },
                ]}
                numberOfLines={2}
              >
                {name}
              </Text>
              {isHighlighted && (
                <HighlightCircle relative={true} top={0} right={0} />
              )}
            </View>
          )}
          {!isNonPro &&
            matter?.status !== 'ENDED' &&
            taskData?.task?.isModifiable && (
              <PlatformTouchable
                onPress={() => {
                  if (
                    editMode &&
                    (description !== fields.description || name !== fields.name)
                  ) {
                    updateTaskSection();
                  }
                  setEditMode(!editMode);
                }}
              >
                {editMode ? (
                  <View style={[TaskSectionListItemStyle.tickButton]}>
                    <TickIcon />
                  </View>
                ) : (
                  <EditIcon width={13} height={13} />
                )}
              </PlatformTouchable>
            )}
        </View>
        <View style={[TaskSectionListItemStyle.descriptionWrap]}>
          {editMode ? (
            <Textarea
              value={fields.description}
              onChangeText={(val) =>
                setFields((f) => ({ ...f, description: val }))
              }
              placeholder="set the section description"
              numberOfLines={3}
              style={TaskSectionListItemStyle.descriptionInput}
            />
          ) : (
            description !== '' && (
              <Text
                style={[TaskSectionListItemStyle.description]}
                numberOfLines={2}
              >
                {description}
              </Text>
            )
          )}
        </View>
        <View style={[TaskSectionListItemStyle.progressWrap]}>
          {typeof proportionReleased !== 'undefined' && (
            <View style={[TaskSectionListItemStyle.progressInfoWrap]}>
              <AssignmentIcon fill={themeColours.TaskEditMode.progressIcon} />
              <Text style={[TaskSectionListItemStyle.progressInfoText]}>
                {`${Math.floor(proportionReleased * 100)}%`}
              </Text>
            </View>
          )}
          {typeof proportionComplete !== 'undefined' && (
            <View
              style={[
                TaskSectionListItemStyle.progressInfoWrap,
                TaskSectionListItemStyle.titleMarginLeft,
              ]}
            >
              <TickIcon fill={themeColours.TaskEditMode.progressIcon} />
              <Text style={[TaskSectionListItemStyle.progressInfoText]}>
                {`${Math.floor(proportionComplete * 100)}%`}
              </Text>
            </View>
          )}
        </View>
        {!isNonPro && matter?.status !== 'ENDED' && canRemove && (
          <View style={[TaskSectionListItemStyle.contextMenuWrap]}>
            <ContextMenu buttonColour={'#AAA9A9'}>
              <ContextMenuItem
                onPress={() => {
                  updateModalProps((draft) => {
                    draft.title = 'remove section';
                    draft.size = 'small';
                    draft.action = () => {
                      removeTaskSection();
                    };
                    draft.content =
                      'are you sure you want to remove this section?';
                    draft.actionText = 'remove';
                  });
                  setShowModal(true);
                }}
              >
                remove section
              </ContextMenuItem>
            </ContextMenu>
          </View>
        )}
      </View>
    </PlatformTouchable>
  );
};

export default TaskSectionListItem;

export const TaskSectionListItemStyle = StyleSheet.create({
  main: {
    borderColor: '#677074',
    borderWidth: 1,
    marginTop: -1,
    padding: 15,
    position: 'relative',
    minHeight: 60,
  },
  name: {
    color: white,
    fontFamily: 'Quicksand-Medium',
    fontSize: 12,
  },
  description: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 10.5,
    color: '#AAA9A9',
    marginTop: 5,
  },
  contextMenuWrap: {
    position: 'absolute',
    top: 15,
    right: 15,
  },
  nameWrap: {
    flexDirection: 'row',
  },
  nameTextWrap: {
    flexDirection: 'row',
    flex: 1,
    marginRight: 10,
    alignItems: 'center',
  },
  nameInputWrap: {
    marginRight: 10,
    flex: 1,
  },
  nameInput: {
    padding: 5,
    marginBottom: 5,
    marginTop: -6,
    marginLeft: -6,
  },
  descriptionInput: {
    padding: 5,
    marginBottom: 0,
    fontSize: 10.5,
    // marginTop: -6,
    marginLeft: -6,
  },
  descriptionWrap: {
    // marginBottom: -10,
  },
  tickButton: {
    padding: 3,
    borderRadius: 3,
    height: 21,
    width: 21,
    backgroundColor: '#DFF5EC',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: -3,
  },
  titleMarginLeft: {
    marginLeft: 15,
  },
  progressInfoWrap: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  progressInfoText: {
    fontFamily: 'Quicksand-Bold',
    fontSize: 11,
    color: blue,
    marginLeft: 4,
  },
  progressWrap: {
    marginTop: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});
