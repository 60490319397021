import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  KeyboardTypeOptions,
  Platform,
  StyleProp,
  StyleSheet,
  Text,
  TextInput as RNTextInput,
  TextStyle,
  TouchableWithoutFeedback,
  useWindowDimensions,
  View,
  ViewStyle,
} from 'react-native';
import { blue, white } from '../configs/colours';
import screenSizes from '../configs/screenSizes';
import HighlightCircle from './HighlightCircle';
import PlatformTouchable from './PlatformTouchable';
import { ThemeContext } from './ThemeProvider';

interface Props {
  label?: string;
  onChangeText: (val: string) => void;
  value: any;
  numberOfLines?: number;
  placeholder?: string;
  onBlur?: () => void;
  onFocus?: () => void;
  icon?: ReactNode;
  onClear?: () => void;
  onPress?: () => void;
  style?: StyleProp<TextStyle>;
  iconWrapStyle?: StyleProp<ViewStyle>;
  margin?: boolean;
  keyboardType?: KeyboardTypeOptions;
  showHidePassword?: boolean;
  invalid?: boolean;
  type?:
    | 'none'
    | 'URL'
    | 'addressCity'
    | 'addressCityAndState'
    | 'addressState'
    | 'countryName'
    | 'creditCardNumber'
    | 'emailAddress'
    | 'familyName'
    | 'fullStreetAddress'
    | 'givenName'
    | 'jobTitle'
    | 'location'
    | 'middleName'
    | 'name'
    | 'namePrefix'
    | 'nameSuffix'
    | 'nickname'
    | 'organizationName'
    | 'postalCode'
    | 'streetAddressLine1'
    | 'streetAddressLine2'
    | 'sublocality'
    | 'telephoneNumber'
    | 'username'
    | 'password'
    | 'newPassword'
    | 'oneTimeCode';
  onSubmitEditing?: () => void;
  instructions?: string;
  iconPosition?: 'START' | 'END';
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters';
  editable?: boolean;
  testID?: string;
  focusOnMount?: boolean;
  isHighlighted?: boolean;
  showLabel?: boolean;
  required?: boolean;
}

const TextInput: React.FunctionComponent<Props> = ({
  label,
  onChangeText,
  value = '',
  placeholder = '',
  onBlur,
  icon,
  onClear,
  onPress,
  style,
  margin = true,
  keyboardType,
  type = 'none',
  showHidePassword = true,
  invalid,
  onSubmitEditing,
  onFocus,
  instructions,
  iconPosition = 'END',
  autoCapitalize = 'sentences',
  editable = true,
  iconWrapStyle,
  testID,
  focusOnMount = false,
  isHighlighted = false,
  showLabel = true,
  required = false,
}) => {
  const { width } = useWindowDimensions();
  const { themeColours, theme } = useContext(ThemeContext);
  const [showPassword, setShowPassword] = useState(false);
  const input = useRef<RNTextInput>();

  useEffect(() => {
    if (focusOnMount && input.current) {
      input.current.focus();
    }
  }, []);

  return (
    <View>
      <View
        style={[
          TextInputStyle.labelWrap,
          width > screenSizes.medium && TextInputStyle.labelWrapDesktop,
        ]}
      >
        {!!label && (
          <Text
            style={[
              TextInputStyle.label,
              width > screenSizes.medium && TextInputStyle.labelDesktop,
              { color: themeColours.Input.label },
              !showLabel && !!!value && { opacity: 0 },
              invalid && {
                color: '#FCC12D',
              },
              isHighlighted && {
                marginRight: 8,
              },
            ]}
          >
            {`${label}${required && !invalid ? ' *' : ''}`}
          </Text>
        )}
        {isHighlighted && <HighlightCircle relative={true} top={0} right={0} />}
      </View>
      {!!instructions && (
        <Text
          style={[
            TextInputStyle.instructions,
            { color: themeColours.Input.label },
            invalid && {
              color: '#FCC12D',
            },
          ]}
        >
          {instructions}
        </Text>
      )}
      <View style={[TextInputStyle.wrap]}>
        <TouchableWithoutFeedback
          onPress={onPress}
          onFocus={() => {
            if (input.current && editable) {
              input.current.focus();
            }
          }}
        >
          <View>
            <View
              pointerEvents={onPress ? 'none' : 'auto'}
              style={[margin && TextInputStyle.inputWrap]}
            >
              <RNTextInput
                testID={testID}
                ref={input}
                aria-invalid={invalid}
                style={[
                  TextInputStyle.main,
                  width > screenSizes.medium && TextInputStyle.mainDesktop,
                  Platform.OS === 'android' &&
                    !!!style && {
                      maxHeight: width > screenSizes.medium ? 31 : 43,
                    },
                  !editable && { borderColor: 'rgba(108,114,118,0.1)' },
                  style,
                  typeof icon !== 'undefined' &&
                    (iconPosition === 'END'
                      ? {
                          paddingRight: 48,
                        }
                      : { paddingLeft: 48 }),
                  typeof icon !== 'undefined' &&
                    typeof onClear !== 'undefined' &&
                    value &&
                    (iconPosition === 'END'
                      ? {
                          paddingRight: 73,
                        }
                      : { paddingLeft: 73 }),

                  type === 'password' &&
                    showHidePassword && {
                      paddingRight: 45,
                    },

                  invalid && {
                    borderColor: '#FCC12D',
                  },
                ]}
                onSubmitEditing={onSubmitEditing}
                keyboardType={keyboardType}
                secureTextEntry={type === 'password' && !showPassword}
                onChangeText={onChangeText}
                value={value}
                textContentType={type}
                placeholder={placeholder}
                placeholderTextColor="#99A4A7"
                onBlur={onBlur}
                onFocus={onFocus}
                autoCapitalize={autoCapitalize}
                editable={editable}
                {...(Platform.OS !== 'web' && {
                  textAlignVertical: 'center',
                })}
                keyboardAppearance={theme === 'DARK' ? 'dark' : 'light'}
              />
              <View style={[TextInputStyle.showHideWrap]}>
                {type === 'password' && showHidePassword && (
                  <PlatformTouchable
                    onPress={() => setShowPassword(!showPassword)}
                    style={[
                      TextInputStyle.showHideButton,
                      // Platform.OS === 'android' && { top: 15 },
                    ]}
                  >
                    <Text
                      style={[
                        TextInputStyle.showHideText,
                        width > screenSizes.medium &&
                          TextInputStyle.showHideTextDesktop,
                      ]}
                    >
                      {showPassword ? 'hide' : 'show'}
                    </Text>
                  </PlatformTouchable>
                )}
              </View>
            </View>
          </View>
        </TouchableWithoutFeedback>
        {!!value && !!onClear && (
          <PlatformTouchable
            onPress={onClear}
            style={[
              TextInputStyle.clearButton,
              typeof icon !== 'undefined' && {
                right: 48,
              },
            ]}
          >
            <Text
              style={[
                TextInputStyle.clearText,
                { color: themeColours.Input.label },
              ]}
            >
              clear
            </Text>
          </PlatformTouchable>
        )}

        {!!icon && (
          <TouchableWithoutFeedback onPress={onPress} disabled={!onPress}>
            <View
              style={[
                TextInputStyle.icon,
                {
                  height: width > screenSizes.medium ? 34 : 42,
                },
                iconPosition === 'END'
                  ? TextInputStyle.iconEnd
                  : TextInputStyle.iconStart,
                iconPosition === 'END'
                  ? !editable
                    ? { borderLeftColor: 'rgba(108,114,118,0.1)' }
                    : { borderLeftColor: '#6C7276' }
                  : !editable
                  ? { borderRightColor: 'rgba(108,114,118,0.1)' }
                  : { borderRightColor: '#6C7276' },
                invalid &&
                  (iconPosition === 'END'
                    ? {
                        borderLeftColor: '#FCC12D',
                      }
                    : {
                        borderRightColor: '#FCC12D',
                      }),
                iconWrapStyle,
              ]}
            >
              {icon}
            </View>
          </TouchableWithoutFeedback>
        )}
      </View>
    </View>
  );
};
export default TextInput;

export const TextInputStyle = StyleSheet.create({
  label: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 14,
    color: '#AAA9A9',
  },
  labelDesktop: {
    fontSize: 12,
  },
  labelWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
    paddingLeft: 15,
  },
  labelWrapDesktop: {
    paddingLeft: 10,
  },
  instructions: {
    fontFamily: 'Quicksand-Regular',
    fontSize: 12,
    color: '#AAA9A9',
    marginBottom: 5,
  },
  main: {
    borderWidth: 1,
    borderRadius: 5,
    borderColor: '#6C7276',
    paddingVertical: 11,
    paddingHorizontal: 15,
    backgroundColor: '#303537',
    color: white,
    fontFamily: 'Quicksand-Regular',
    fontSize: 15,
    textAlignVertical: 'top',
  },
  mainDesktop: {
    fontSize: 14,
    paddingVertical: 7,
    paddingHorizontal: 10,
  },
  inputWrap: {
    marginBottom: 15,
  },
  wrap: {
    position: 'relative',
  },
  icon: {
    position: 'absolute',
    padding: 5,
    top: 0,
    alignItems: 'center',
    justifyContent: 'center',
    width: 38,
  },
  iconStart: {
    borderRightColor: '#E8E8E8',
    borderRightWidth: 1,
    left: 0,
  },
  iconEnd: {
    borderLeftColor: '#E8E8E8',
    borderLeftWidth: 1,
    right: 0,
  },
  clearButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  clearText: {
    fontSize: 10.5,
    color: '#AAA9A9',
    fontFamily: 'Quicksand-Medium',
  },
  showHideWrap: {
    position: 'absolute',
    top: 0,
    right: 10,
    height: '100%',
    justifyContent: 'center',
  },
  showHideButton: {},
  showHideTextDesktop: {
    fontSize: 11,
  },
  showHideText: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 13,
    color: blue,
  },
});
