import React, { useContext } from 'react';
import {
  Image,
  Platform,
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
} from 'react-native';
import backgroundPng from '../../assets/backgrounds/background-full-opacity.png';
import background from '../../assets/backgrounds/background-full-opacity.svg';
import defaultProfile from '../../assets/images/profile.jpg';
import { black, blue, white, whiteRGBA } from '../configs/colours';
import screenSizes from '../configs/screenSizes';
import useIsNonPro from '../hooks/usIsNonPro';
import {
  useBreadcrumbsQueryQuery,
  useGetCurrentUserQuery,
  useListConversationsQuery,
} from '../types/apolloTypes';
import { KeyValueArrayType } from '../types/Conversations';
import { useHistory, useLocation } from '../utils/routing';
import Button from './Button';
import SpeechBubbleIcon from './icons/SpeechBubbleIcon';
import { InsituConversationContext } from './InsituConversationContextProvider';

export type PersonType = {
  id: string;
  firstName: string;
  lastName: string;
  pictureUrl: string;
  title: string;
};

interface Props {
  userId: string;
  title?: string;
  // icon?: ReactNode;
  showContact?: boolean;
  person: PersonType;
}

const PersonHeader: React.FunctionComponent<Props> = ({
  userId,
  title,
  showContact = true,
  person,
}) => {
  const { width } = useWindowDimensions();
  const history = useHistory();
  const location = useLocation();

  const isNonPro = useIsNonPro();

  const { updateProps } = useContext(InsituConversationContext);

  const { data: currentUserData } = useGetCurrentUserQuery();

  const {
    data: { conversations } = { conversations: [] },
  } = useListConversationsQuery({
    variables: {
      memberId: currentUserData?.currentUser?.id,
    },
    skip: !showContact || !!!currentUserData?.currentUser?.id,
  });

  const {
    data: { getBreadcrumbs: breadcrumbs } = { getBreadcrumbs: [] },
  } = useBreadcrumbsQueryQuery();

  const clientConversation = conversations?.find(
    (conversation) =>
      typeof conversation.participants.find(
        (participant) => participant.id === userId,
      ) !== 'undefined',
  );

  const clientConversationId = clientConversation?.id;

  const profileWrap = (
    <View style={[PersonHeaderStyle.profileWrap]}>
      <Image
        source={
          person?.pictureUrl
            ? {
                uri: person?.pictureUrl,
              }
            : defaultProfile
        }
        style={[PersonHeaderStyle.profileImage]}
      />
      {width > screenSizes.medium && (
        <View style={[PersonHeaderStyle.profileText]}>
          <Text style={[PersonHeaderStyle.name]} numberOfLines={1}>{`${
            person?.firstName || ''
          } ${person?.lastName || ''}`}</Text>
          <Text style={[PersonHeaderStyle.detail]} numberOfLines={1}>
            {person.title ?? ''}
          </Text>
        </View>
      )}
    </View>
  );

  const contactButton =
    !!clientConversationId &&
    userId !== currentUserData?.currentUser?.id &&
    showContact ? (
      <View style={[PersonHeaderStyle.buttonWrap]}>
        <Button
          text="contact"
          icon={<SpeechBubbleIcon fill={white} />}
          onPress={() => {
            if (width > screenSizes.medium) {
              updateProps((draft) => {
                const clientAccountIdsMeta = clientConversation?.metadata?.find(
                  (metadata) =>
                    metadata.key === 'clientAccountIds' &&
                    metadata.__typename === 'KeyValueArray',
                ) as KeyValueArrayType;
                const clientAccountId = clientAccountIdsMeta?.values?.[0];

                draft.conversationId = clientAccountId
                  ? ''
                  : clientConversationId;
                draft.showConversation = true;
                draft.clientAccountId = clientAccountIdsMeta?.values?.[0];
              });
            } else {
              history.push(
                `/conversations/${clientConversationId}?back=${
                  breadcrumbs[breadcrumbs.length - 1]?.to
                }&backText=${title}`,
              );
            }
          }}
          colour={white}
          fill={blue}
        />
      </View>
    ) : (
      <View style={{ flex: 1 }} />
    );

  const titleWrap = (
    <View style={[PersonHeaderStyle.titleWrap]}>
      <Text style={[PersonHeaderStyle.title]}>{title}</Text>
    </View>
  );

  const backgroundHeight =
    width > screenSizes.xxLarge
      ? 1625
      : width > screenSizes.medium
      ? 1161
      : 696;
  const backgroundWidth =
    width > screenSizes.xxLarge ? 1258 : width > screenSizes.medium ? 871 : 522;

  return (
    <View style={[PersonHeaderStyle.main, { backgroundColor: '#303537' }]}>
      <Image
        source={Platform.OS !== 'web' ? backgroundPng : background}
        style={[
          {
            top: 0,
            left: width > screenSizes.medium ? 0 : -50,
            width: width > screenSizes.medium ? '100%' : width + 50,
            minHeight: backgroundHeight,
            minWidth: backgroundWidth,
            height: '100%',
            flex: 1,
            position: 'absolute',
            zIndex: 0,
          },
        ]}
      />
      <View style={[PersonHeaderStyle.main, PersonHeaderStyle.mainInner]}>
        {profileWrap}
        {!!title && titleWrap}
        {contactButton}
      </View>
    </View>
  );
};

export default PersonHeader;

const PersonHeaderStyle = StyleSheet.create({
  main: {
    shadowColor: black,
    overflow: 'hidden',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 5,
    zIndex: 1,
    height: 49,
  },
  mainInner: {
    paddingHorizontal: 10,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  background: {},
  backgroundDesktop: {
    paddingHorizontal: 30,
  },
  profileButtonWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  title: {
    fontSize: 16,
    fontFamily: 'Quicksand-Regular',
    color: white,
    textAlign: 'center',
  },
  profileWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  profileText: {
    marginLeft: 15,
  },
  name: {
    color: white,
    fontSize: 15,
    fontFamily: 'Quicksand-Medium',
    lineHeight: 19,
  },
  detail: {
    color: '#AAA9A9',
    fontSize: 11,
    lineHeight: 14,
    fontFamily: 'Quicksand-Medium',
  },
  buttonWrap: {
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  profileImage: {
    width: 27,
    height: 27,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: whiteRGBA(0.25),
  },
  gradient: {
    position: 'absolute',
    width: '100%',
    height: '80%',
    bottom: 0,
    left: 0,
  },
  closedWrap: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: blue,
    paddingHorizontal: 5,
    paddingVertical: 3,
    marginLeft: 10,
  },
  closedText: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 10.5,
    color: blue,
  },
});
