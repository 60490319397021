import React, { RefObject, useContext } from 'react';
import {
  DrawerLayoutAndroid,
  PanResponder,
  StyleSheet,
  useWindowDimensions,
  View,
} from 'react-native';
import {
  CONTEXT_MENU,
  MenuContext,
  NO_MENU,
  SIDE_MENU,
} from './MenuContextProvider';
import PlatformTouchable from './PlatformTouchable';

interface Props {
  drawerLeft: RefObject<DrawerLayoutAndroid | null>;
  drawerRight: RefObject<DrawerLayoutAndroid | null>;
}

const MobileMenuSlider: React.FunctionComponent<Props> = ({
  drawerLeft,
  drawerRight,
}) => {
  const { width } = useWindowDimensions();
  const { activeMenu, setActiveMenu } = useContext(MenuContext);
  const panResponder = PanResponder.create({
    onMoveShouldSetPanResponder: () => true,
    onMoveShouldSetPanResponderCapture: () => true,
    onPanResponderMove: (__, { moveX }) => {
      if (
        moveX <= width / 2 + 7.5 &&
        moveX >= width / 2 - 7.5 &&
        activeMenu !== NO_MENU
      ) {
        if (drawerLeft.current) drawerLeft.current.closeDrawer();
        if (drawerRight.current) drawerRight.current.closeDrawer();
        setActiveMenu(NO_MENU);
        return;
      }
      if (moveX < width / 2 - 7.5 && activeMenu !== SIDE_MENU) {
        if (drawerLeft.current) drawerLeft.current.openDrawer();
        setActiveMenu(SIDE_MENU);
        return;
      }
      if (moveX > width / 2 + 7.5 && activeMenu !== CONTEXT_MENU) {
        if (drawerRight.current) drawerRight.current.openDrawer();
        setActiveMenu(CONTEXT_MENU);
        return;
      }
    },
  });

  return (
    <View style={MobileMenuSliderStyles.main} {...panResponder.panHandlers}>
      <PlatformTouchable onPress={() => setActiveMenu(SIDE_MENU)}>
        <View
          style={[
            MobileMenuSliderStyles.circle,
            activeMenu === SIDE_MENU && MobileMenuSliderStyles.active,
          ]}
        />
      </PlatformTouchable>
      <PlatformTouchable onPress={() => setActiveMenu(NO_MENU)}>
        <View
          style={[
            MobileMenuSliderStyles.circle,
            activeMenu === NO_MENU && MobileMenuSliderStyles.active,
          ]}
        />
      </PlatformTouchable>
      <PlatformTouchable onPress={() => setActiveMenu(CONTEXT_MENU)}>
        <View
          style={[
            MobileMenuSliderStyles.circle,
            activeMenu === CONTEXT_MENU && MobileMenuSliderStyles.active,
          ]}
        />
      </PlatformTouchable>
    </View>
  );
};
export default MobileMenuSlider;

const MobileMenuSliderStyles = StyleSheet.create({
  main: {
    flexDirection: 'row',
    position: 'absolute',
    alignItems: 'center',
    bottom: 10,
    alignSelf: 'center',
    backgroundColor: '#303537',
    borderRadius: 20,
    paddingHorizontal: 2.5,
    paddingVertical: 4,
  },
  iconWrap: { marginHorizontal: 8 },
  circle: {
    width: 8,
    borderRadius: 4,
    height: 8,
    marginHorizontal: 3.5,
    borderWidth: 1,
    borderColor: '#E5F3F8',
    backgroundColor: 'transparent',
  },
  active: {
    backgroundColor: '#E5F3F8',
  },
});
