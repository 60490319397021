import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { Line, Path, Circle, Svg } from 'react-native-svg';
import { blue } from '../../configs/colours';

interface Props {
  fill?: string;
  secondaryFill?: string;
  height?: number;
  width?: number;
  style?: StyleProp<ViewStyle>;
}

const DashboardIcon: React.FunctionComponent<Props> = ({
  fill = blue,
  secondaryFill = '#e5f3f8',
  width = 23.55,
  height = 15.56,
  style,
}) => {
  return (
    <Svg height={height} viewBox="0 0 23.55 15.56" width={width} style={style}>
      <Path
        d="M23,12.28C23,5.74,17.94.41,11.78.41S.59,5.74.59,12.28A12.25,12.25,0,0,0,1,15.56H22.51A12.25,12.25,0,0,0,23,12.28Z"
        fill="#383d3f"
      />
      <Circle cx="7.88" cy="4.4" r="0.66" fill="#fff" />
      <Circle cx="7.88" cy="4.4" r="0.16" fill="none" stroke={secondaryFill} />
      <Circle cx="19.3" cy="7.55" r="0.66" fill="#fff" />
      <Circle cx="19.3" cy="7.55" r="0.16" fill="none" stroke={secondaryFill} />
      <Circle cx="3.55" cy="11.89" r="0.66" fill="#fff" />
      <Circle
        cx="3.55"
        cy="11.89"
        r="0.16"
        fill="none"
        stroke={secondaryFill}
      />
      <Circle cx="4.86" cy="7.55" r="0.66" fill="#fff" />
      <Circle cx="4.86" cy="7.55" r="0.16" fill="none" stroke={secondaryFill} />
      <Circle cx="16.28" cy="4.4" r="0.66" fill="#fff" />
      <Circle cx="16.28" cy="4.4" r="0.16" fill="none" stroke={secondaryFill} />
      <Circle cx="20.35" cy="11.88" r="0.66" fill="#fff" />
      <Circle
        cx="20.35"
        cy="11.88"
        r="0.16"
        fill="none"
        stroke={secondaryFill}
      />
      <Circle cx="12.08" cy="2.69" r="0.66" fill="#fff" />
      <Circle
        cx="12.08"
        cy="2.69"
        r="0.16"
        fill="none"
        stroke={secondaryFill}
      />
      <Line
        x1="11.16"
        y1="10.7"
        x2="9.85"
        y2="7.95"
        fill="none"
        stroke={secondaryFill}
        strokeLinecap="round"
      />
      <Path
        d="M11.78.5A11.28,11.28,0,0,0,.5,11.78,11.13,11.13,0,0,0,1,15.06h8.5a2.78,2.78,0,1,1,4.88,0h8.19a11.13,11.13,0,0,0,.49-3.28A11.28,11.28,0,0,0,11.78.5Z"
        fill="none"
        stroke={fill}
      />
      <Path
        d="M14.71,13.75a2.78,2.78,0,1,0-5.22,1.31h4.88A2.77,2.77,0,0,0,14.71,13.75Z"
        fill="none"
        stroke={fill}
      />
      <Path d="M14.37,15.06H9.49" fill="none" stroke={fill} />
    </Svg>
  );
};

export default DashboardIcon;
