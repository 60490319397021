import { ApolloClient } from 'apollo-client';
import produce from 'immer';
import {
  FirmMemberUpdateSubscription,
  FirmMemberUpdateSubscriptionVariables,
  GetFirmDocument,
  GetFirmQuery,
  GetFirmQueryVariables,
  ListFirmMembershipsDocument,
  ListFirmMembershipsQuery,
  ListFirmMembershipsQueryVariables,
} from '../../types/apolloTypes';

export function firmMemberUpdateUpdate(
  client: ApolloClient<object>,
  data: FirmMemberUpdateSubscription,
  variables: FirmMemberUpdateSubscriptionVariables,
  firmId?: string,
) {
  const cache = client.cache;
  const memberUpdate = data?.firmMemberUpdate;
  // Update message list query with new message

  try {
    const getFirmMembershipsData = cache.readQuery<
      ListFirmMembershipsQuery,
      ListFirmMembershipsQueryVariables
    >({
      query: ListFirmMembershipsDocument,
      variables: {
        professionalId: variables.professionalId,
      },
    });

    const nextMembershipsState = produce(
      getFirmMembershipsData,
      (draftState) => {
        const index = draftState.firmMemberships.findIndex(
          (membership) => membership.id === memberUpdate.id,
        );
        draftState.firmMemberships[index] = memberUpdate;
      },
    );

    client.writeQuery({
      query: ListFirmMembershipsDocument,
      data: nextMembershipsState,
      variables: {
        professionalId: variables.professionalId,
      },
    });
  } catch (error) {
    if (error?.name !== 'Invariant Violation') {
      throw new Error(error);
    }
  }
  if (firmId) {
    try {
      const getFirmData = cache.readQuery<GetFirmQuery, GetFirmQueryVariables>({
        query: GetFirmDocument,
        variables: {
          firmId,
        },
      });

      const nextFirmState = produce(getFirmData, (draftState) => {
        const index = draftState.firm?.members.findIndex(
          (membership) => membership.id === memberUpdate.id,
        );
        if (index > -1) {
          draftState.firm.members[index] = memberUpdate;
        }
      });

      client.writeQuery({
        data: nextFirmState,
        query: GetFirmDocument,
        variables: {
          firmId,
        },
      });
    } catch (error) {
      if (error?.name !== 'Invariant Violation') {
        throw new Error(error);
      }
    }
  }
}
