import React, { createContext, ReactNode, useState, useEffect } from 'react';
import {
  LIGHT,
  TThemeContext,
  lightColours,
  TThemeColours,
  DARK,
  darkColours,
} from '../configs/themeTypes';
import { StatusBar } from 'react-native';

interface Props {
  children: ReactNode;
}

const ThemeProviderDefaults: TThemeContext = {
  theme: 'DARK',
  setTheme: () => null,
  themeColours: darkColours,
};

export const ThemeContext = createContext<TThemeContext>(ThemeProviderDefaults);

const ThemeProvider: React.FunctionComponent<Props> = ({ children }) => {
  const [themeName, setTheme] = useState<'LIGHT' | 'DARK'>(
    ThemeProviderDefaults.theme,
  );
  const [themeColoursState, setThemeColours] = useState<TThemeColours>(
    ThemeProviderDefaults.themeColours,
  );
  useEffect(() => {
    switch (themeName) {
      case LIGHT:
        setThemeColours(lightColours);
        break;
      case DARK:
        setThemeColours(darkColours);
        break;
    }
  }, [themeName]);
  return (
    <ThemeContext.Provider
      value={{ theme: themeName, setTheme, themeColours: themeColoursState }}
    >
      <StatusBar
        backgroundColor={themeColoursState.header}
        barStyle={themeName !== LIGHT ? 'light-content' : 'dark-content'}
      />
      {children}
    </ThemeContext.Provider>
  );
};
export default ThemeProvider;
