import { isBefore } from 'date-fns';
import {
  endOfWeek,
  isAfter,
  isThisWeek,
  isToday,
  parseISO,
  startOfWeek,
  subWeeks,
} from 'date-fns/esm';
import isElectron from 'is-electron';
import React, { useContext } from 'react';
import {
  Animated,
  Easing,
  Platform,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
} from 'react-native';
import { blue, white } from '../configs/colours';
import screenSizes from '../configs/screenSizes';
import { useAnimation } from '../hooks/useAnimation';
import markAllNotificationsReadUpdate from '../mutations/updates/MarkAllNotificationsRead';
import {
  useGetCurrentUserQuery,
  useListNotificationsQuery,
  useMarkAllNotificationsReadMutation,
} from '../types/apolloTypes';
import ContextMenu, { ContextMenuItem } from './ContextMenu';
import HighlightCircle from './HighlightCircle';
import BellIcon from './icons/BellIcon';
import CloseIcon from './icons/CloseIcon';
import { NotificationContext } from './NotificationContext';
import NotificationListItem from './NotificationListItem';
import PlatformTouchable from './PlatformTouchable';

interface Props {
  visible: boolean;
  toggleVisible: () => void;
}

const Notifications: React.FunctionComponent<Props> = ({
  toggleVisible,
  visible,
}) => {
  const { width } = useWindowDimensions();
  const { setTotalUnread } = useContext(NotificationContext);

  // Toggle spring animation from size menu
  const menuX = useAnimation({
    type: 'timing',
    easing: Easing.out(Easing.ease),
    duration: 300,
    initialValue:
      Platform.OS !== 'web' || width <= screenSizes.medium ? width : 400,
    useNativeDriver: Platform.OS !== 'web',
    toValue: visible
      ? 0
      : Platform.OS !== 'web' || width <= screenSizes.medium
      ? width
      : 400,
  });

  const [markAllNotificationsRead] = useMarkAllNotificationsReadMutation({
    update: (cache) => markAllNotificationsReadUpdate(cache),
    onCompleted: () => {
      setTotalUnread(0);
      if (isElectron()) {
        const { app } = window.require('electron').remote;
        app?.dock?.setBadge('');
      }
    },
  });

  const { data: currentUserData } = useGetCurrentUserQuery();

  const { data: notificationsData } = useListNotificationsQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const total = data.notifications.totalUnread;
      setTotalUnread(data.notifications.totalUnread);
      if (isElectron()) {
        const { app } = window.require('electron').remote;
        app?.dock?.setBadge(`${total > 0 ? total : ''}`);
      }
    },
  });

  const todaysNotifications = notificationsData?.notifications?.notifications?.filter(
    (notification) => isToday(parseISO(notification.occurredOn)),
  );
  const thisWeeksNotifications = notificationsData?.notifications?.notifications?.filter(
    (notification) =>
      !isToday(parseISO(notification.occurredOn)) &&
      isBefore(parseISO(notification.occurredOn), endOfWeek(new Date())) &&
      isAfter(parseISO(notification.occurredOn), startOfWeek(new Date())),
  );
  const lastWeeksNotifications = notificationsData?.notifications?.notifications?.filter(
    (notification) =>
      !isToday(parseISO(notification.occurredOn)) &&
      !isThisWeek(parseISO(notification.occurredOn)) &&
      isBefore(
        parseISO(notification.occurredOn),
        endOfWeek(subWeeks(new Date(), 1)),
      ) &&
      isAfter(
        parseISO(notification.occurredOn),
        startOfWeek(subWeeks(new Date(), 1)),
      ),
  );
  const olderNotifications = notificationsData?.notifications?.notifications?.filter(
    (notification) =>
      !isToday(parseISO(notification.occurredOn)) &&
      !isThisWeek(parseISO(notification.occurredOn)) &&
      isBefore(
        parseISO(notification.occurredOn),
        endOfWeek(subWeeks(new Date(), 2)),
      ),
  );

  const returnNotification = (notification) => (
    <NotificationListItem
      key={notification.id}
      {...notification}
      visible={visible}
      currentUserId={currentUserData?.currentUser?.id}
    />
  );

  const requestNotifications = () => {
    if (
      Platform.OS === 'web' &&
      window?.Notification &&
      Notification?.permission !== 'denied'
    ) {
      Notification.requestPermission(function (status) {
        // If the user said okay
        if (status === 'granted') {
          new Notification('notifications enabled');
        }
      });
    }
  };

  return (
    <Animated.View
      style={[
        Platform.OS === 'android'
          ? NotificationsStyle.mainAndroid
          : NotificationsStyle.main,
        Platform.OS === 'web' &&
          width > screenSizes.medium && {
            zIndex: 6,
          },
        width > screenSizes.medium && NotificationsStyle.mainDesktop,
        {
          transform: [
            {
              translateX: Platform.OS !== 'android' ? menuX : 0,
            },
          ],
        },
      ]}
    >
      <SafeAreaView style={{ flex: 1 }}>
        <View style={[NotificationsStyle.header]}>
          <View style={[NotificationsStyle.wrap]}>
            <BellIcon />
            {Platform.OS === 'web' &&
              window?.Notification &&
              Notification?.permission !== 'granted' &&
              Notification?.permission !== 'denied' && (
                <View style={[NotificationsStyle.contextWrap]}>
                  <ContextMenu>
                    <ContextMenuItem onPress={requestNotifications}>
                      enable notifications
                    </ContextMenuItem>
                  </ContextMenu>
                  <HighlightCircle right={10} />
                </View>
              )}
          </View>
          <Text style={[NotificationsStyle.title]}>notifications</Text>

          <View
            style={[NotificationsStyle.wrap, { justifyContent: 'flex-end' }]}
          >
            <PlatformTouchable onPress={toggleVisible}>
              <CloseIcon width={12} height={12} fill="#9A9A9A" />
            </PlatformTouchable>
          </View>
        </View>
        {notificationsData?.notifications?.notifications?.length === 0 && (
          <View style={[NotificationsStyle.emptyWrap]}>
            <BellIcon fill="#AAA9A9" />
            <Text style={[NotificationsStyle.emptyText]}>
              you have no notifications, when you do they will appear here
            </Text>
          </View>
        )}
        {notificationsData?.notifications?.notifications?.length > 0 && (
          <ScrollView>
            {todaysNotifications?.length > 0 && (
              <>
                <View style={[NotificationsStyle.separator]}>
                  <Text style={[NotificationsStyle.separatorText]}>today</Text>
                </View>
                {todaysNotifications?.map(returnNotification)}
              </>
            )}
            {thisWeeksNotifications?.length > 0 && (
              <>
                <View style={[NotificationsStyle.separator]}>
                  <Text style={[NotificationsStyle.separatorText]}>
                    this week
                  </Text>
                </View>
                {thisWeeksNotifications?.map(returnNotification)}
              </>
            )}
            {lastWeeksNotifications?.length > 0 && (
              <>
                <View style={[NotificationsStyle.separator]}>
                  <Text style={[NotificationsStyle.separatorText]}>
                    last week
                  </Text>
                </View>
                {lastWeeksNotifications?.map(returnNotification)}
              </>
            )}
            {olderNotifications?.length > 0 && (
              <>
                <View style={[NotificationsStyle.separator]}>
                  <Text style={[NotificationsStyle.separatorText]}>older</Text>
                </View>
                {olderNotifications?.map(returnNotification)}
              </>
            )}
            <PlatformTouchable
              style={[NotificationsStyle.markAllButton]}
              onPress={() => markAllNotificationsRead()}
            >
              <Text style={[NotificationsStyle.separatorText, { color: blue }]}>
                mark all read
              </Text>
            </PlatformTouchable>
          </ScrollView>
        )}
      </SafeAreaView>
    </Animated.View>
  );
};

export default Notifications;

const NotificationsStyle = StyleSheet.create({
  main: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%',
    width: '100%',
    backgroundColor: '#383D3F',
    zIndex: 4,
  },
  mainAndroid: {
    backgroundColor: '#383D3F',
    height: '100%',
  },
  mainDesktop: {
    width: 400,
    borderLeftColor: '#4B4F52',
    borderLeftWidth: 1,
    paddingTop: 18,
  },
  header: {
    flexDirection: 'row',
    paddingVertical: 15,
    paddingHorizontal: 20,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    color: '#F0FBFF',
    fontFamily: 'Quicksand-Medium',
    fontSize: 18,
  },
  emptyWrap: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 60,
    paddingVertical: 40,
  },
  emptyText: {
    color: '#AAA9A9',
    fontFamily: 'Quicksand-Medium',
    fontSize: 13,
    marginTop: 10,
    textAlign: 'center',
  },
  separator: {
    backgroundColor: '#133540',
    borderTopColor: '#235D6F',
    borderBottomColor: '#4B4F52',
    borderBottomWidth: 1,
    borderTopWidth: 1,
    paddingHorizontal: 20,
    paddingVertical: 6,
  },
  separatorText: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 12,
    color: white,
  },
  markAllButton: {
    position: 'absolute',
    top: 7,
    right: 20,
  },
  contextWrap: {
    marginHorizontal: 10,
    marginTop: 5,
  },
  wrap: {
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: 50,
  },
});
