import React, { ReactNode, useContext } from 'react';
import {
  View,
  Text,
  StyleSheet,
  ViewStyle,
  StyleProp,
  TextStyle,
} from 'react-native';
import PlatformTouchable from './PlatformTouchable';
import { black, blue } from '../configs/colours';
import { ThemeContext } from './ThemeProvider';

interface Props {
  text: string;
  icon?: ReactNode;
  onPress: () => void;
  fill?: string;
  colour?: string;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  disabled?: boolean;
  testID?: string;
  inactive?: boolean;
}

export const DefaultButton: React.FunctionComponent<Props> = (props) => {
  return (
    <Button
      {...props}
      style={[
        ButtonStyle.blockButton,

        props.style,
        {
          opacity: props.disabled ? 0.5 : 1,
          backgroundColor: !props.inactive ? blue : '#4B4F52',
          borderColor: !props.inactive ? blue : '#4B4F52',
        },
      ]}
      textStyle={[
        ButtonStyle.buttonText,

        props.textStyle,
        {
          color: !props.inactive ? '#F0FBFF' : '#99A4A7',
        },
      ]}
    />
  );
};

export const LinedButton: React.FunctionComponent<Props> = (props) => {
  return (
    <Button
      {...props}
      style={[
        ButtonStyle.blockButton,
        {
          borderWidth: 1,
          borderColor: blue,
          opacity: props.disabled ? 0.5 : 1,
          backgroundColor: !props.inactive ? 'transparent' : '#4B4F52',
        },
        props.style,
      ]}
      textStyle={[
        ButtonStyle.buttonText,
        { color: !props.inactive ? blue : '#4B4F52' },
        props.textStyle,
      ]}
    />
  );
};

const Button: React.FunctionComponent<Props> = ({
  text,
  icon,
  onPress,
  fill,
  colour,
  style,
  textStyle,
  disabled,
  testID = 'button',
}) => {
  const { themeColours } = useContext(ThemeContext);

  return (
    <PlatformTouchable
      onPress={onPress}
      style={[
        ButtonStyle.blockButton,
        { backgroundColor: themeColours.Button.solidBackground },
        fill && { backgroundColor: fill },
        style,
      ]}
      disabled={disabled}
      testID={testID}
    >
      <View style={[ButtonStyle.contentWrap]} collapsable={false}>
        {icon && (
          <View style={[ButtonStyle.iconWrap, !!!text && { marginRight: 0 }]}>
            {icon}
          </View>
        )}
        {!!text && (
          <Text
            style={[
              ButtonStyle.buttonText,
              { color: themeColours.Button.color },
              colour && { color: colour },
              textStyle,
            ]}
            testID="button-text"
          >
            {text}
          </Text>
        )}
      </View>
    </PlatformTouchable>
  );
};

export default Button;

const ButtonStyle = StyleSheet.create({
  blockButton: {
    backgroundColor: 'white',
    borderRadius: 5,
    shadowColor: black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.04,
    shadowRadius: 4,
    elevation: 1,
    paddingVertical: 6,
    paddingHorizontal: 20,
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 28,
  },
  contentWrap: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 11,
    color: blue,
  },
  iconWrap: {
    marginRight: 8,
  },
});
