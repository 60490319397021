import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { blue, white } from '../configs/colours';
import PlatformTouchable from './PlatformTouchable';

interface Props {
  value: number;
  colour?: string;
  font?: string;
  lineStyle?: boolean;
  lineColour?: string;
  pausedPill?: boolean;
  onPress?: () => void;
}

const Pill: React.FunctionComponent<Props> = ({
  value = 0,
  colour = blue,
  font = white,
  lineStyle = false,
  pausedPill = false,
  lineColour,
  onPress,
}) => {
  return (
    <View
      style={[
        PillStyle.main,
        { backgroundColor: colour },
        lineStyle && PillStyle.mainLine,
        lineStyle &&
          lineColour && {
            borderColor: lineColour,
          },
      ]}
    >
      {pausedPill ? (
        <PlatformTouchable style={[PillStyle.pausedWrap]} onPress={onPress}>
          <>
            <View style={[PillStyle.pausedTangle]} />
            <View style={[PillStyle.pausedTangle]} />
          </>
        </PlatformTouchable>
      ) : (
        <Text
          style={[
            PillStyle.text,
            { color: font },
            lineStyle && PillStyle.textLine,
          ]}
        >
          {value}
        </Text>
      )}
    </View>
  );
};
export default Pill;

const PillStyle = StyleSheet.create({
  main: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 19,
    minWidth: 30,
    height: 17,
    paddingHorizontal: 5,
  },
  mainLine: {
    borderWidth: 1,
    borderColor: blue,
    backgroundColor: 'transparent',
  },
  text: {
    fontFamily: 'Quicksand-Bold',
    fontSize: 14,
  },
  textLine: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 12,
    color: '#E5F3F8',
  },
  pausedWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pausedTangle: {
    backgroundColor: blue,
    width: 2,
    height: 6,
    marginHorizontal: 1,
  },
});
