import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { white } from '../configs/colours';
import * as Sentry from '@sentry/react-native';

class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, { componentStack }) {
    // You can also log the error to an error reporting service
    Sentry.captureException(error, { contexts: { react: { componentStack } } });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI

      return (
        <View style={[ErrorBoundaryStyle.main]}>
          <Text style={[ErrorBoundaryStyle.text]}>Something went wrong.</Text>
        </View>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

const ErrorBoundaryStyle = StyleSheet.create({
  main: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    padding: 20,
  },
  text: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 12,
    color: white,
  },
});
