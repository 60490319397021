import React from 'react';
import {
  StyleSheet,
  View,
  Image,
  Text,
  useWindowDimensions,
} from 'react-native';
import ChevronRightIcon from './icons/ChevronRightIcon';
import PlatformTouchable from './PlatformTouchable';
import screenSizes from '../configs/screenSizes';

interface Props {
  imageUri?: string;
  text: string;
  secondaryText?: string;
  onPress: () => void;
}

const AttentionBanner: React.FunctionComponent<Props> = ({
  imageUri,
  onPress,
  text,
  secondaryText,
}) => {
  const { width } = useWindowDimensions();

  return (
    <PlatformTouchable
      style={[
        AttentionBannerStyle.main,
        width > screenSizes.medium && AttentionBannerStyle.mainDesktop,
      ]}
      onPress={onPress}
    >
      <>
        {!!imageUri && (
          <Image
            source={{ uri: imageUri }}
            style={[
              AttentionBannerStyle.image,
              width > screenSizes.medium && AttentionBannerStyle.imageDesktop,
            ]}
          />
        )}
        <View style={[AttentionBannerStyle.textWrap]}>
          <Text style={[AttentionBannerStyle.text]} numberOfLines={1}>
            {text}
          </Text>
          {!!secondaryText && (
            <Text
              style={[AttentionBannerStyle.secondaryText]}
              numberOfLines={1}
            >{` ${secondaryText}`}</Text>
          )}
        </View>
        <ChevronRightIcon width={5.2} height={10} fill="#E5F3F8" />
      </>
    </PlatformTouchable>
  );
};

export default AttentionBanner;

const AttentionBannerStyle = StyleSheet.create({
  main: {
    paddingHorizontal: 25,
    height: 38,
    backgroundColor: 'rgba(33, 36, 38, 0.95)',
    borderBottomColor: '#2C3F45',
    borderBottomWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainDesktop: {
    paddingHorizontal: 35,
  },
  image: {
    marginRight: 20,
    width: 19,
    height: 19,
    borderRadius: 4,
  },
  imageDesktop: {},
  textWrap: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 20,
  },
  text: {
    fontFamily: 'Quicksand-Bold',
    fontSize: 11,
    color: '#E5F3F8',
  },
  secondaryText: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 11,
    color: '#99A4A7',
    marginLeft: 5,
  },
});
