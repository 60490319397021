import * as React from 'react';
import { Path, Svg } from 'react-native-svg';

interface Props {
  fill?: string;
  width?: number;
  height?: number;
}

const ChevronDownIcon: React.FunctionComponent<Props> = ({
  fill = '#d0d0d0',
  width = 8.04,
  height = 4.77,
}) => {
  return (
    <Svg height={height} viewBox="0 0 8.04 4.77" width={width}>
      <Path
        d="M1.06,1.06,4.33,4.33,7.6,1.06"
        transform="translate(-0.31 -0.31)"
        fill="none"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </Svg>
  );
};

export default ChevronDownIcon;
