import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { Path, Svg } from 'react-native-svg';
import { blue } from '../../configs/colours';

interface Props {
  height?: number;
  width?: number;
  style?: StyleProp<ViewStyle>;
}

const ContactsIcon: React.FunctionComponent<Props> = ({
  width = 23.93,
  height = 16.51,
  style,
}) => {
  return (
    <Svg height={height} viewBox="0 0 23.93 16.51" width={width} style={style}>
      <Path
        d="M12.84,5h0a2.55,2.55,0,0,0,1.81-2.77A2.54,2.54,0,0,0,12.24,0h-.58a2.57,2.57,0,0,0-.58,5h0C9.31,6,7.35,9.24,8.16,10.45c.48.72,2.12,1.07,3.8,1.07s3.33-.36,3.8-1.07C16.57,9.2,14.62,5.92,12.84,5Zm-2.57-2.4a1.46,1.46,0,0,1,.94-1.54,1.69,1.69,0,0,1,1.94.35,1.55,1.55,0,0,1,.38,1.81,1.5,1.5,0,0,1-1.55,1,1.55,1.55,0,0,1-1.21-.51,1.38,1.38,0,0,1-.5-1.13Zm4.55,7.19c-.21.31-1,.72-2.84.72s-2.64-.41-2.85-.72-.14-.55.4-1.58C10.28,6.79,11.19,6,12,6s1.69.83,2.46,2.27c.48,1,.53,1.36.41,1.58Z"
        transform="translate(0)"
        fill="#00c977"
      />
      <Path
        d="M4.87,10h0a2.57,2.57,0,0,0-.6-5H3.69a2.57,2.57,0,0,0-.59,5h0c-1.77.93-3.72,4.21-2.91,5.43.47.71,2.11,1.07,3.8,1.07s3.33-.36,3.8-1.07C8.59,14.18,6.64,10.91,4.87,10ZM2.29,7.61a1.48,1.48,0,0,1,1-1.54,1.68,1.68,0,0,1,1.93.35,1.53,1.53,0,0,1,.38,1.81A1.5,1.5,0,0,1,4,9.22,1.61,1.61,0,0,1,2.79,8.7a1.39,1.39,0,0,1-.5-1.13Zm4.55,7.18c-.21.32-1,.72-2.83.72s-2.65-.4-2.86-.72-.13-.54.4-1.58C2.3,11.78,3.21,10.94,4,10.94s1.68.84,2.45,2.27c.49,1,.53,1.36.41,1.58Z"
        transform="translate(0)"
        fill="#02ace6"
      />
      <Path
        d="M20.82,10h0a2.57,2.57,0,0,0-.6-5h-.58a2.57,2.57,0,0,0-.59,5h0c-1.77.93-3.72,4.21-2.91,5.43.47.71,2.12,1.07,3.8,1.07s3.33-.36,3.8-1.07C24.55,14.18,22.59,10.91,20.82,10Zm-2.58-2.4a1.48,1.48,0,0,1,1-1.54,1.68,1.68,0,0,1,1.93.35,1.52,1.52,0,0,1,.38,1.81,1.5,1.5,0,0,1-1.54,1,1.61,1.61,0,0,1-1.22-.52,1.43,1.43,0,0,1-.5-1.13Zm4.55,7.18c-.21.32-.95.72-2.83.72s-2.64-.4-2.86-.72-.13-.54.4-1.58c.75-1.43,1.66-2.27,2.43-2.27s1.68.84,2.45,2.27c.49,1,.53,1.36.41,1.58Z"
        transform="translate(0)"
        fill="#b16afd"
      />
    </Svg>
  );
};

export default ContactsIcon;
