import * as React from 'react';
import { Path, Rect, Svg } from 'react-native-svg';
import { white } from '../../configs/colours';
import { ThemeContext } from '../ThemeProvider';

interface Props {
  fill?: string;
  secondaryFill?: string;
  stroke?: string;
  width?: number;
  height?: number;
}

const ChildrenIcon: React.FunctionComponent<Props> = ({
  fill = white,
  secondaryFill = '#bcedff',
  stroke = '#707070',
  width = 31.44,
  height = 30.96,
}) => {
  const { themeColours } = React.useContext(ThemeContext);

  return (
    <Svg height={height} viewBox="0 0 31.44 30.96" width={width}>
      <Path
        d="M11,2.64A2.64,2.64,0,0,1,8.38,5.27h0A2.64,2.64,0,1,0,8.38,0h0A2.64,2.64,0,0,1,11,2.64Z"
        transform="translate(0)"
        fill={themeColours.ChildrenIcon.third}
      />
      <Path
        d="M5.77,2.87A2.64,2.64,0,0,0,8.35,5.28,2.64,2.64,0,0,1,5.77,2.87Z"
        transform="translate(0)"
        fill={themeColours.ChildrenIcon.third}
      />
      <Path
        d="M8.35,0A2.63,2.63,0,0,0,5.77,2.4,2.63,2.63,0,0,1,8.35,0Z"
        transform="translate(0)"
        fill={themeColours.ChildrenIcon.third}
      />
      <Path
        d="M5.77,2.88a2.64,2.64,0,0,0,2.58,2.4h0A2.64,2.64,0,1,0,8.35,0h0A2.63,2.63,0,0,0,5.77,2.4l0,.24,0,.23Zm2.61-2A1.76,1.76,0,1,1,6.62,2.64,1.75,1.75,0,0,1,8.38.88Z"
        transform="translate(0)"
        fill={themeColours.ChildrenIcon.stroke}
      />
      <Rect
        x="6.08"
        y="11.89"
        width="4.29"
        height="1.57"
        fill={themeColours.ChildrenIcon.fill}
      />
      <Path
        d="M5.63,11h.45V6.15a.45.45,0,0,0-.27-.41.43.43,0,0,0-.5.1L.13,11.12a.44.44,0,1,0,.63.61L5.16,7.2v4.23A.44.44,0,0,1,5.61,11Z"
        transform="translate(0)"
        fill={themeColours.ChildrenIcon.stroke}
      />
      <Path
        d="M11.25,11.43V7.2l4.4,4.4a.45.45,0,0,0,.63,0,.44.44,0,0,0,0-.62L11.12,5.83a.43.43,0,0,0-.62,0,.39.39,0,0,0-.12.3V11h.44A.45.45,0,0,1,11.25,11.43Z"
        transform="translate(0)"
        fill={themeColours.ChildrenIcon.stroke}
      />
      <Rect
        x="6.07"
        y="11.89"
        width="0.01"
        height="1.57"
        fill={themeColours.ChildrenIcon.stroke}
      />
      <Rect
        x="10.37"
        y="11.89"
        height="1.57"
        fill={themeColours.ChildrenIcon.stroke}
      />
      <Path
        d="M6.18,19.08a.44.44,0,0,0,.44.44h0a.45.45,0,0,0,.44-.44V14.34H6.18Z"
        transform="translate(0)"
        fill={themeColours.ChildrenIcon.stroke}
      />
      <Path
        d="M9.41,19.08a.44.44,0,0,0,.44.44h0a.46.46,0,0,0,.44-.44V14.34H9.41Z"
        transform="translate(0)"
        fill={themeColours.ChildrenIcon.stroke}
      />
      <Path
        d="M22.71,16.61a2.63,2.63,0,1,1,1.16,0,2.63,2.63,0,1,0-1.16,0Z"
        transform="translate(0)"
        fill={themeColours.ChildrenIcon.third}
      />
      <Path
        d="M26,14.06a2.64,2.64,0,1,0-3.25,2.54,2.23,2.23,0,0,0,.63.09,2.38,2.38,0,0,0,.53-.05A2.64,2.64,0,0,0,26,14.06Zm-2.64,1.76a1.75,1.75,0,1,1,1.76-1.74,1.75,1.75,0,0,1-1.76,1.74Z"
        transform="translate(0)"
        fill={themeColours.ChildrenIcon.stroke}
      />
      <Path
        d="M31.3,22.45l-5.17-5.18h0a.48.48,0,0,1,.14.25l.24,1.37,4.18,4.18a.43.43,0,0,0,.62,0h0a.41.41,0,0,0,0-.59l0,0Z"
        transform="translate(0)"
        fill={themeColours.ChildrenIcon.stroke}
      />
      <Path
        d="M25.87,17.16"
        transform="translate(0)"
        fill={themeColours.ChildrenIcon.stroke}
      />
      <Path
        d="M20.57,17.16"
        transform="translate(0)"
        fill={themeColours.ChildrenIcon.stroke}
      />
      <Path
        d="M18.63,25.26l1.62-7.76a.43.43,0,0,1,.14-.23h0l-5.16,5.17a.44.44,0,1,0,.62.62L20,18.93,18.7,25.25a.45.45,0,0,0,.09.37.4.4,0,0,0,.34.15h0a.4.4,0,0,1-.34-.15A.48.48,0,0,1,18.63,25.26Z"
        transform="translate(0)"
        fill={themeColours.ChildrenIcon.stroke}
      />
      <Path
        d="M21.17,30.52a.44.44,0,0,0,.88,0V25.78h-.88Z"
        transform="translate(0)"
        fill={themeColours.ChildrenIcon.stroke}
      />
      <Path
        d="M24.4,30.52a.45.45,0,0,0,.46.42.44.44,0,0,0,.42-.42V25.78H24.4Z"
        transform="translate(0)"
        fill={themeColours.ChildrenIcon.stroke}
      />
      <Path
        d="M21.07,17.68,19.6,24.9h7l-1.27-7.24a.45.45,0,0,1,.34-.5A20.84,20.84,0,0,0,23.34,17c-1.1,0-2.3.1-2.64.12a.43.43,0,0,1,.37.5v0Z"
        transform="translate(0)"
        fill={themeColours.ChildrenIcon.fill}
      />
      <Path
        d="M10.82,11H5.63a.42.42,0,0,0-.43.38V13.9a.44.44,0,0,0,.44.44h5.18a.44.44,0,0,0,.44-.44V11.43A.43.43,0,0,0,10.82,11ZM6.08,13.46h0V11.89h4.3v1.57Z"
        transform="translate(0)"
        fill={themeColours.ChildrenIcon.secondary}
      />
      <Path
        d="M26.11,17.26a.44.44,0,0,0-.22-.1h0a.29.29,0,0,0-.13,0h0a.45.45,0,0,0-.34.49l1.27,7.24h-7l1.48-7.22a.44.44,0,0,0-.33-.52h-.17a.46.46,0,0,0-.36.34L18.7,25.24a.44.44,0,0,0,.08.37.46.46,0,0,0,.35.16h8.11a.44.44,0,0,0,.44-.44.17.17,0,0,0,0-.07l-1.18-6.38-.23-1.37A.58.58,0,0,0,26.11,17.26Z"
        transform="translate(0)"
        fill={themeColours.ChildrenIcon.secondary}
      />
    </Svg>
  );
};

export default ChildrenIcon;
