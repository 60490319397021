import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { G, Path, Rect, Svg } from 'react-native-svg';
import { blue } from '../../configs/colours';

interface Props {
  fill?: string;
  secondaryFill?: string;
  height?: number;
  width?: number;
  style?: StyleProp<ViewStyle>;
}

const KeyDatesIcon: React.FunctionComponent<Props> = ({
  fill = blue,
  secondaryFill = '#e5f3f8',
  width = 18,
  height = 18,
  style,
}) => {
  return (
    <Svg height={height} viewBox="0 0 18 18" width={width} style={style}>
      <Path d="M0,3H18V15a3,3,0,0,1-3,3H3a3,3,0,0,1-3-3V3Z" fill="none" />
      <G>
        <Rect width="18" height="18" rx="2" fill="#383d3f" />
        <Path
          d="M2,.63H16A1.38,1.38,0,0,1,17.38,2V16A1.37,1.37,0,0,1,16,17.38H2A1.38,1.38,0,0,1,.62,16V2A1.38,1.38,0,0,1,2,.63Z"
          fill="none"
          stroke={fill}
          strokeWidth="1.25"
        />
      </G>
      <Path d="M.05,4.19H17.67" fill="none" stroke={fill} />
      <Path
        d="M8.6,10.17a1.59,1.59,0,0,1,.53.62,2.07,2.07,0,0,1-.08,1.85,1.74,1.74,0,0,1-.75.67,2.6,2.6,0,0,1-1.12.23,2.22,2.22,0,0,1-.57-.07,2.35,2.35,0,0,1-.51-.19.49.49,0,0,1-.29-.43.55.55,0,0,1,.13-.36.44.44,0,0,1,.33-.16.67.67,0,0,1,.35.12,1.2,1.2,0,0,0,.76.27.9.9,0,0,0,.47-.13.89.89,0,0,0,.36-.36,1,1,0,0,0,.14-.54A.85.85,0,0,0,8.09,11a.88.88,0,0,0-.63-.24,1.2,1.2,0,0,0-.28,0l-.14,0a1,1,0,0,1-.34.07.33.33,0,0,1-.28-.13.44.44,0,0,1-.11-.3.54.54,0,0,1,0-.2.73.73,0,0,1,.14-.2L7.73,8.74H6.14a.43.43,0,0,1-.44-.45A.41.41,0,0,1,5.82,8a.44.44,0,0,1,.32-.13H8.59A.54.54,0,0,1,9,8a.48.48,0,0,1,.13.35.6.6,0,0,1-.19.39L7.82,9.91A1.38,1.38,0,0,1,8.6,10.17Z"
        fill={secondaryFill}
      />
      <Path
        d="M11.81,8a.49.49,0,0,1,.13.35V13a.45.45,0,0,1-.15.35.5.5,0,0,1-.37.14.48.48,0,0,1-.37-.14A.45.45,0,0,1,10.9,13V9.18l-.49.31a.59.59,0,0,1-.26.07.46.46,0,0,1-.34-.15.53.53,0,0,1-.14-.34.4.4,0,0,1,.07-.24.62.62,0,0,1,.18-.18l1.21-.72a.82.82,0,0,1,.34-.07A.48.48,0,0,1,11.81,8Z"
        fill={secondaryFill}
      />
    </Svg>
  );
};

export default KeyDatesIcon;
