import { Resolvers } from 'apollo-client';
import { Breadcrumb } from '../types/apolloTypes';

const resolvers: Resolvers = {
  Mutation: {
    updateBreadcrumbs: (__, { input }, { cache }) => {
      const data = {
        getBreadcrumbs: input.map((breadcrumb: Breadcrumb) => ({
          __typename: 'Breadcrumb',
          image: '',
          name: '',
          to: '',
          ...breadcrumb,
        })),
      };
      cache.writeData({ data });
    },
  },
};

const defaults = {
  getBreadcrumbs: [],
};

export default { resolvers, defaults };
