import React, {
  ReactNode,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {
  Animated,
  Dimensions,
  Easing,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
} from 'react-native';
import { MenuProvider } from 'react-native-popup-menu';
import { black, blackRGBA, blue, white } from '../configs/colours';
import screenSizes from '../configs/screenSizes';
import {
  TimingAnimationConfig,
  useAnimationParallel,
} from '../hooks/useAnimation';
import ErrorBoundary from './ErrorBoundary';
import CloseIcon from './icons/CloseIcon';
import ModalWrap from './ModalWrap';
import PlatformTouchable from './PlatformTouchable';
import { ThemeContext } from './ThemeProvider';

interface Props {
  title: string;
  cancelText?: string;
  cancelAction?: () => void;
  actionText?: string;
  action?: () => void;
  actionDisabled?: boolean;
  setShowModal?: (show: boolean) => void;
  showModal: boolean;
  size: 'small' | 'medium' | 'large';
  close: () => void;
  closeOnAction?: boolean;
  showBackground?: boolean;
  slimStyle?: boolean;
  textContent?: string;
  icon?: ReactNode;
  hideCancelAction?: boolean;
  scrollRef?: React.MutableRefObject<ScrollView>;
}

const ModalInline: React.FunctionComponent<Props> = ({
  title,
  actionText = 'submit',
  cancelText = 'cancel',
  cancelAction,
  action,
  showModal = false,
  size = 'small',
  actionDisabled = false,
  close,
  closeOnAction = true,
  children,
  showBackground = true,
  slimStyle = false,
  textContent,
  icon,
  hideCancelAction = false,
  scrollRef,
}) => {
  const { themeColours } = useContext(ThemeContext);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let timeout;
    if (showModal === true) setVisible(true);
    else {
      timeout = setTimeout(() => {
        setVisible(false);
      }, 600);
    }
    return () => clearTimeout(timeout);
  }, [showModal]);

  const sharedAnimProps: TimingAnimationConfig = {
    type: 'timing',
    initialValue: 0,
    duration: 300,
    useNativeDriver: Platform.OS !== 'web',
    toValue: showModal ? 1 : 0,
  };

  const [animatedValue, animatedValue2, animatedValue3] = useAnimationParallel(
    [
      {
        ...sharedAnimProps,
        delay: showModal ? 200 : 0,
      },
      {
        ...sharedAnimProps,
        duration: 400,
        easing: Easing.inOut(Easing.ease),
      },
      {
        ...sharedAnimProps,
        easing: Easing.in(Easing.ease),
        delay: showModal ? 500 : 0,
      },
    ],
    {},
    showModal,
  );

  const getModalWidth = (): {
    width: string | number;
    maxWidth?: string | number;
    minWidth?: string | number;
  } => {
    if (Dimensions.get('window').width <= screenSizes.medium) {
      return { width: '90%' };
    }
    switch (size) {
      case 'small':
        return { width: 400 };
      case 'medium':
        return { maxWidth: '90%', width: 640 };
      case 'large':
        return { maxWidth: '90%', width: 1200 };
    }
  };

  const [fullHeight, setFullHeight] = useState(false);
  const { height: pageHeight } = useWindowDimensions();

  const content = (
    <>
      {visible && (
        <Animated.View
          style={[
            ModalInlineStyle.wrap,
            {
              opacity: animatedValue as any,
            },
            !showBackground && {
              backgroundColor: 'transparent',
            },
          ]}
        >
          <Animated.View
            style={[
              ModalInlineStyle.modal,
              {
                backgroundColor: themeColours.Modal.background,
                ...getModalWidth(),
                transform: [
                  {
                    scale: animatedValue2.interpolate({
                      inputRange: [0, 1],
                      outputRange: [0.8, 1],
                    }) as any,
                  },
                  {
                    translateY: animatedValue2.interpolate({
                      inputRange: [0, 1],
                      outputRange: [80, 0],
                    }) as any,
                  },
                ],
                opacity: animatedValue2 as any,
              },
              fullHeight && { flex: 1 },
              slimStyle && ModalInlineStyle.modalSlim,
            ]}
          >
            <>
              <Animated.View
                style={[
                  ModalInlineStyle.innerWrap,
                  fullHeight && { flex: 1 },
                  {
                    opacity: animatedValue3 as any,
                  },
                ]}
              >
                <ErrorBoundary>
                  {title?.length > 0 && (
                    <View style={[slimStyle && ModalInlineStyle.titleWrapSlim]}>
                      {!!icon && (
                        <View style={[ModalInlineStyle.iconWrap]}>{icon}</View>
                      )}
                      <Text
                        style={[
                          ModalInlineStyle.title,
                          slimStyle && ModalInlineStyle.titleSlim,
                          { color: themeColours.Modal.title },
                        ]}
                      >
                        {title}
                      </Text>
                    </View>
                  )}
                  <ScrollView
                    style={[
                      ModalInlineStyle.contentWrap,
                      fullHeight && { flex: 1 },
                      {
                        maxHeight: pageHeight - 260,
                        marginHorizontal: !slimStyle ? -25 : 0,
                      },
                    ]}
                    contentContainerStyle={{
                      paddingHorizontal: !slimStyle ? 25 : 0,
                    }}
                    // onContentSizeChange={(height) => {
                    //   if (height > pageHeight - 180) {
                    //     setFullHeight(true);
                    //   }
                    // }}
                    ref={scrollRef}
                    collapsable={false}
                  >
                    {showModal && !!textContent ? (
                      <Text style={[ModalInlineStyle.text]}>{textContent}</Text>
                    ) : (
                      children
                    )}
                  </ScrollView>
                  <View
                    style={[
                      ModalInlineStyle.buttonWrap,
                      slimStyle && ModalInlineStyle.buttonWrapSlim,
                      !!!action && hideCancelAction && { marginTop: 0 },
                    ]}
                  >
                    {action && (
                      <PlatformTouchable
                        style={[
                          ModalInlineStyle.actionButton,
                          { opacity: actionDisabled ? 0.5 : 1 },
                          slimStyle && ModalInlineStyle.slimButton,
                        ]}
                        onPress={() => {
                          if (action) action();
                          if (closeOnAction) close();
                        }}
                        disabled={actionDisabled}
                      >
                        <Text style={[ModalInlineStyle.actionButtonText]}>
                          {actionText}
                        </Text>
                      </PlatformTouchable>
                    )}
                    {!hideCancelAction && (
                      <PlatformTouchable
                        style={[
                          ModalInlineStyle.cancelButton,
                          !action && !slimStyle && { flex: 1, width: '90%' },
                          slimStyle && ModalInlineStyle.slimButton,
                        ]}
                        onPress={() => {
                          if (cancelAction) cancelAction();
                          close();
                        }}
                      >
                        <Text style={[ModalInlineStyle.cancelButtonText]}>
                          {cancelText}
                        </Text>
                      </PlatformTouchable>
                    )}
                  </View>
                </ErrorBoundary>
              </Animated.View>
              <PlatformTouchable
                style={[
                  ModalInlineStyle.closeBtn,
                  slimStyle && ModalInlineStyle.closeBtnSlim,
                ]}
                onPress={close}
              >
                <CloseIcon fill="#C4C4C4" />
              </PlatformTouchable>
            </>
          </Animated.View>
        </Animated.View>
      )}
    </>
  );

  return (
    <ModalWrap visible={showModal} animated={false} transparent={true}>
      {Platform.OS === 'web' ? (
        content
      ) : (
        <MenuProvider skipInstanceCheck={true}>{content}</MenuProvider>
      )}
    </ModalWrap>
  );
};

export default ModalInline;

export const ModalInlineStyle = StyleSheet.create({
  wrap: {
    width: '100%',
    height: '100%',
    backgroundColor: blackRGBA(0.75),
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
  },
  iconWrap: {
    marginRight: 10,
  },
  modal: {
    position: 'relative',
    maxHeight: '90%',
    paddingHorizontal: 25,
    paddingVertical: 30,
    shadowColor: black,
    borderRadius: 5,
    shadowOffset: {
      width: 0,
      height: 18,
    },
    shadowOpacity: 0.04,
    shadowRadius: 40,
    elevation: 5,
    backgroundColor: white,
  },
  modalSlim: {
    backgroundColor: '#282C2E',
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  closeBtn: {
    position: 'absolute',
    top: 5,
    right: 5,
    padding: 10,
    zIndex: 2,
  },
  closeBtnSlim: {
    top: 15,
    right: 15,
  },
  title: {
    textAlign: 'center',
    fontSize: 16,
    fontFamily: 'Quicksand-Bold',
    color: '#343D3F',
    textTransform: 'lowercase',
    marginBottom: 15,
  },
  titleSlim: {
    marginBottom: 0,
    textAlign: 'left',
  },
  titleWrapSlim: {
    paddingHorizontal: 25,
    paddingVertical: 20,
    borderBottomColor: '#4A4E50',
    borderBottomWidth: 1,
    alignItems: 'center',
    flexDirection: 'row',
  },
  text: {
    fontFamily: 'Quicksand-Regular',
    fontSize: 14,
    textAlign: 'center',
    color: '#AAA9A9',
  },
  buttonWrap: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: 20,
  },
  buttonWrapSlim: {
    paddingHorizontal: 25,
    paddingBottom: 20,
    paddingTop: 15,
    justifyContent: 'flex-start',
    marginTop: 0,
    backgroundColor: '#303537',
    shadowColor: black,
    shadowOffset: {
      width: 0,
      height: -4,
    },
    shadowOpacity: 0.016,
    shadowRadius: 10,
    elevation: 5,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  innerWrap: {
    // flex: 1,
  },
  contentWrap: {
    // flex: 1,
  },
  actionButton: {
    backgroundColor: blue,
    alignContent: 'center',
    justifyContent: 'center',
    width: 125,
    height: 40,
    borderRadius: 8,
    marginHorizontal: 5,
    borderColor: blue,
    borderWidth: 1.5,
  },
  actionButtonText: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 12,
    textTransform: 'lowercase',
    color: white,
    textAlign: 'center',
  },
  cancelButton: {
    alignContent: 'center',
    justifyContent: 'center',
    width: 125,
    height: 40,
    borderRadius: 8,
    marginHorizontal: 5,
    borderColor: blue,
    borderWidth: 1.5,
  },
  cancelButtonText: {
    fontFamily: 'Quicksand-Medium',
    fontSize: 12,
    textTransform: 'lowercase',
    color: blue,
    textAlign: 'center',
  },
  slimButton: {
    height: 32,
  },
});
