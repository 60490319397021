import React, { useContext } from 'react';
import { Platform, StyleSheet, Text, View } from 'react-native';
import {
  Menu,
  MenuOption,
  MenuOptions,
  MenuTrigger,
  renderers,
} from 'react-native-popup-menu';
import Svg, { Path } from 'react-native-svg';
import { blue } from '../configs/colours';
import KebabIcon from './icons/KebabIcon';
import { ThemeContext } from './ThemeProvider';
import PlusIcon from './icons/PlusIcon';

interface ContextMenuProps {
  buttonColour?: string;
  plusButton?: boolean;
  position?: 'TOP' | 'BOTTOM';
}

interface ContextMenuItemProps {
  onPress: () => void;
}

export const ContextMenuItem: React.FunctionComponent<ContextMenuItemProps> = ({
  children,
  onPress,
}) => {
  return (
    <MenuOption
      onSelect={onPress}
      customStyles={{
        optionWrapper: {
          ...ContextMenuItemStyle.main,
        },
      }}
    >
      <Text style={[ContextMenuItemStyle.text]}>{children}</Text>
    </MenuOption>
  );
};

export const childrenLength = (children) =>
  React.Children.toArray(children).length;

const ContextMenu: React.FunctionComponent<ContextMenuProps> = ({
  children,
  buttonColour = '#D5D5D5',
  plusButton = false,
  position = 'BOTTOM',
}) => {
  const { themeColours } = useContext(ThemeContext);

  return (
    <Menu renderer={renderers.NotAnimatedContextMenu}>
      <MenuTrigger>
        <View style={[ContextMenuStyle.buttonWrap]}>
          {plusButton ? (
            <PlusIcon fill={buttonColour} />
          ) : (
            <KebabIcon fill={buttonColour} />
          )}
        </View>
      </MenuTrigger>
      <MenuOptions
        optionsContainerStyle={[
          ContextMenuStyle.menu,
          position === 'TOP' && {
            transform: [{ translateY: -(childrenLength(children) * 31) }],
            marginTop: 0,
            marginBottom: 35,
          },
          {
            backgroundColor: themeColours.ContextMenu.background,
            borderColor: themeColours.ContextMenu.borderColor,
          },
        ]}
      >
        <View
          style={[
            position === 'TOP'
              ? ContextMenuStyle.bottomArrow
              : ContextMenuStyle.topArrow,
            position === 'TOP' && {
              top: childrenLength(children) * 31,
            },
            position === 'TOP' &&
              Platform.OS !== 'web' && {
                opacity: 0,
              },
          ]}
        >
          <Svg viewBox="0 0 14.68 7.71" width={14.68} height={7.71}>
            <Path
              d="M7.72,1.17A.49.49,0,0,0,7.34,1,.5.5,0,0,0,7,1.17L1.32,7.71h12Z"
              fill={themeColours.ContextMenu.background}
            />
            <Path
              d="M14.68,7.71,8.48.52A1.49,1.49,0,0,0,7.34,0,1.45,1.45,0,0,0,6.21.52L0,7.71H1.32L7,1.17A.5.5,0,0,1,7.34,1a.49.49,0,0,1,.38.17l5.64,6.54Z"
              fill={themeColours.ContextMenu.borderColor}
            />
          </Svg>
        </View>
        {children}
      </MenuOptions>
    </Menu>
  );
};
export default ContextMenu;

const ContextMenuStyle = StyleSheet.create({
  menu: {
    marginTop: 30,
    marginLeft: Platform.OS === 'web' ? -175 : 13,
    backgroundColor: 'white',
    borderColor: '#BFE2EF',
    borderWidth: 1,
    borderRadius: 3,
    shadowRadius: 6,
    shadowColor: 'rgba(0, 0, 0, 1.0)',
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 5 },
    elevation: 6,
  },
  topArrow: {
    position: 'absolute',
    top: -7.71,
    right: 15,
  },
  bottomArrow: {
    position: 'absolute',
    right: 15,
    transform: [{ rotate: '180deg' }],
    marginTop: 0,
    marginBottom: 30,
  },
  buttonWrap: {
    paddingVertical: 5,
    marginTop: -5,
    paddingHorizontal: 10,
    marginHorizontal: -10,
  },
});

const ContextMenuItemStyle = StyleSheet.create({
  main: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    justifyContent: 'center',
    alignItems: 'center',
    // width: 180,
  },
  text: {
    fontFamily: 'Quicksand-Medium',
    color: blue,
    fontSize: 12,
    textAlign: 'center',
    marginVertical: Platform.OS === 'web' ? 3 : 0,
  },
});
