import * as React from 'react';
import { Svg, Path, G, Circle } from 'react-native-svg';

interface Props {
  fill?: string;
  width?: number;
  height?: number;
}

const AssignmentIcon: React.FunctionComponent<Props> = ({
  fill = '#ffffff',
  width = 17.06,
  height = 11.2,
}) => {
  return (
    <Svg height={height} viewBox="0 0 17.06 11.2" width={width}>
      <Path
        d="M.5,5.35H5.85"
        transform="translate(0 0)"
        fill="none"
        stroke={fill}
        strokeLinecap="round"
      />
      <Path
        d="M3.18,2.68,5.85,5.35,3.18,8"
        transform="translate(0 0)"
        fill="none"
        stroke={fill}
        strokeLinecap="round"
      />
      <Circle cx="11" cy="2.5" r="2.5" fill="none" />
      <Circle cx="11" cy="2.5" r="2" fill="none" stroke={fill} />
      <Path
        d="M5.85,10.7s1-4.55,5.35-4.55,5.36,4.55,5.36,4.55"
        transform="translate(0 0)"
        fill="none"
        stroke={fill}
        strokeLinecap="round"
      />
    </Svg>
  );
};
export default AssignmentIcon;
