import * as React from 'react';
import { Svg, Path } from 'react-native-svg';

interface Props {
  fill?: string;
  width?: number;
  height?: number;
}

const PlusIcon: React.FunctionComponent<Props> = ({
  fill = '#C9C9C9',
  width = 15.5,
  height = 15.5,
}) => {
  return (
    <Svg height={height} viewBox="0 0 15.5 15.5" width={width}>
      <Path
        d="M7.75.75v14"
        fill="none"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <Path
        d="M.75,7.75h14"
        fill="none"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </Svg>
  );
};
export default PlusIcon;
