import { useEffect, useRef } from 'react';
import { Animated } from 'react-native';

export const useAnimatedValue = (initialValue: number): Animated.Value => {
  const ref = useRef(new Animated.Value(initialValue));
  return ref.current;
};

type AnimationType = 'spring' | 'timing';

interface BaseAnimationConfig {
  initialValue?: number;
  type?: AnimationType;
  callback?: () => void;
}

interface BaseLoopConfig {
  iterations?: number;
}

interface ArrayAnimationConfig {
  callback?: () => void;
}

export type TimingAnimationConfig = BaseAnimationConfig &
  ({ type: 'timing' } & Animated.TimingAnimationConfig);

export type SpringAnimationConfig = BaseAnimationConfig &
  ({ type: 'spring' } & Animated.SpringAnimationConfig);

export type LoopAnimationConfig = BaseAnimationConfig &
  ({ type: 'loop' } & Animated.LoopAnimationConfig);

export type UseAnimationConfig = TimingAnimationConfig | SpringAnimationConfig;

const getInitialValue = (config: UseAnimationConfig) => {
  if (typeof config.initialValue !== 'undefined') return config.initialValue;
  else {
    return config.toValue as number; // TODO deal with other types possibilities here
  }
};

export const useAnimation = (config: UseAnimationConfig): Animated.Value => {
  const animatedValue = useAnimatedValue(getInitialValue(config));

  const animate = () => {
    if (config.type === 'timing') {
      Animated.timing(animatedValue, config).start(
        () => config.callback && config.callback(),
      );
    } else if (config.type === 'spring') {
      Animated.spring(animatedValue, config).start(
        () => config.callback && config.callback(),
      );
    } else {
      // @ts-ignore
      throw new Error('unsupported animation type=' + config.type);
    }
  };

  // Currently useEffect is buggy, see https://github.com/facebook/react-native/issues/21967
  useEffect(animate, [config.toValue]);

  return animatedValue;
};

export const useAnimationLoop = (
  config: UseAnimationConfig & BaseLoopConfig,
): Animated.Value => {
  const animatedValue = useAnimatedValue(getInitialValue(config));

  const animate = () => {
    if (config.type === 'timing') {
      Animated.loop(Animated.timing(animatedValue, config), {
        iterations: config.iterations,
      }).start(() => config.callback && config.callback());
    } else if (config.type === 'spring') {
      Animated.loop(Animated.spring(animatedValue, config), {
        iterations: config.iterations,
      }).start(() => config.callback && config.callback());
    } else {
      // @ts-ignore
      throw new Error('unsupported animation type=' + config.type);
    }
  };

  // Currently useEffect is buggy, see https://github.com/facebook/react-native/issues/21967
  useEffect(animate, [config.toValue]);

  return animatedValue;
};

export const useAnimationSequence = (
  configs: UseAnimationConfig[],
  config?: ArrayAnimationConfig,
  prop?: any,
): Animated.Value[] => {
  const animatedValues = configs.map(animConfig =>
    useAnimatedValue(getInitialValue(animConfig)),
  );
  const animate = () => {
    Animated.sequence(
      configs.map((animConfig, index) => {
        if (animConfig.type === 'timing') {
          return Animated.timing(animatedValues[index], animConfig);
        } else if (animConfig.type === 'spring') {
          return Animated.spring(animatedValues[index], animConfig);
        } else {
          // @ts-ignore
          throw new Error('unsupported animation type=' + animConfig.type);
        }
      }),
    ).start(() => config.callback && config.callback());
  };

  // Currently useEffect is buggy, see https://github.com/facebook/react-native/issues/21967
  useEffect(animate, [prop]);

  return animatedValues;
};

export const useAnimationParallel = (
  configs: UseAnimationConfig[],
  config?: ArrayAnimationConfig,
  prop?: any,
): Animated.Value[] => {
  const animatedValues = configs.map(animConfig =>
    useAnimatedValue(getInitialValue(animConfig)),
  );
  const animate = () => {
    Animated.parallel(
      configs.map((animConfig, index) => {
        if (animConfig.type === 'timing') {
          return Animated.timing(animatedValues[index], animConfig);
        } else if (animConfig.type === 'spring') {
          return Animated.spring(animatedValues[index], animConfig);
        } else {
          // @ts-ignore
          throw new Error('unsupported animation type=' + animConfig.type);
        }
      }),
    ).start(() => config.callback && config.callback());
  };

  // Currently useEffect is buggy, see https://github.com/facebook/react-native/issues/21967
  useEffect(animate, [prop]);

  return animatedValues;
};

// import { useMemoOne } from 'use-memo-one';
// import Animated, { Easing } from '../components/Animated';
// import { useRef, useEffect, useMemo } from 'react';
// const {
//   Clock,
//   Value,
//   set,
//   cond,
//   startClock,
//   clockRunning,
//   timing,
//   debug,
//   stopClock,
//   spring,
//   block,
// } = Animated;

// function runTiming(clock, value, dest, callback?: () => void) {
//   const state = {
//     finished: new Value(0),
//     position: new Value(0),
//     time: new Value(0),
//     frameTime: new Value(0),
//   };

//   const config = {
//     duration: 300,
//     toValue: new Value(0),
//     easing: Easing.inOut(Easing.ease),
//   };

//   return block([
//     cond(
//       clockRunning(clock),
//       [
//         // if the clock is already running we update the toValue, in case a new dest has been passed in
//         set(config.toValue, dest),
//       ],
//       [
//         // if the clock isn't running we reset all the animation params and start the clock
//         set(state.finished, 0),
//         set(state.time, 0),
//         set(state.position, value),
//         set(state.frameTime, 0),
//         set(config.toValue, dest),
//         startClock(clock),
//       ],
//     ),
//     // we run the step here that is going to update position
//     timing(clock, state, config),
//     // if the animation is over we stop the clock
//     cond(state.finished, debug('stop clock', stopClock(clock))),
//     // we made the block return the updated position
//     state.position,
//   ]);
// }

// function runSpring(clock, value, velocity, dest) {
//   const state = {
//     finished: new Value(0),
//     velocity: new Value(0),
//     position: new Value(0),
//     time: new Value(0),
//   };

//   const config = {
//     damping: 12,
//     mass: 1,
//     stiffness: 50,
//     overshootClamping: true,
//     restSpeedThreshold: 0.001,
//     restDisplacementThreshold: 0.001,
//     toValue: new Value(0),
//   };

//   return [
//     cond(clockRunning(clock), 0, [
//       set(state.finished, 0),
//       set(state.velocity, velocity),
//       set(state.position, value),
//       set(config.toValue, dest),
//       startClock(clock),
//     ]),
//     // we run the step here that is going to update position
//     spring(clock, state, config),
//     // if the animation is over we stop the clock
//     cond(state.finished, stopClock(clock)),
//     // we made the block return the updated position
//     state.position,
//   ];
// }

// function useAnimation(to, duration = 300) {
//   // // we create a clock node
//   // const clock = useMemoOne(() => new Clock(), [to]);
//   // // and use runTiming method defined above to create a node that is going to be mapped
//   // // to the translateX transform.
//   // return useMemoOne(() => runTiming(clock, from, to), [clock]);

//   const _transX = useRef(new Value(0)).current;
//   const _config = useMemo(
//     () => ({
//       duration,
//       toValue: to,
//       easing: Easing.inOut(Easing.ease),
//     }),
//     [to],
//   );
//   const time = useMemo(() => timing(_transX, _config), [to]);

//   useEffect(() => {
//     time.start();
//   }, [to]);

//   return _transX;
// }

// export default useAnimation;
