import { format } from 'date-fns/esm';
import React, { useRef, useState } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import DateTimePicker from './DateTimePicker';
import DateIcon from './icons/DateIcon';
import ModalInline from './ModalInline';
import TextInput from './TextInput';

interface Props {
  date: string;
  time: string;
  useTime?: boolean;
  onSelectDate?: (
    date: string,
    dateRange?: [string, string],
    time?: string,
  ) => void;
  onSelectTime?: (
    time: string,
    dateRange?: [string, string],
    date?: string,
  ) => void;
  onClear: () => void;
  label?: string;
  placeholder?: string;
  onSubmit?: () => void;
  margin?: boolean;
  onChange?: (dateTime: string) => void;
  minDate?: string;
  maxDate?: string;
  invalid?: boolean;
  instructions?: string;
  style?: StyleProp<ViewStyle>;
  editable?: boolean;
  useDateRange?: boolean;
  dateRange?: [string, string];
  timeRange?: [string, string];
  isHighlighted?: boolean;
  required?: boolean;
}

const DateInput: React.FunctionComponent<Props> = ({
  date,
  time,
  onSelectDate = () => null,
  onSelectTime = () => null,
  onClear,
  label,
  placeholder,
  onSubmit = () => null,
  margin,
  onChange = () => null,
  useTime = true,
  minDate,
  maxDate,
  invalid,
  instructions,
  editable = true,
  style,
  useDateRange,
  dateRange,
  timeRange,
  isHighlighted = false,
  required = false,
}) => {
  const wrap = useRef<View>();
  const [dateFormat, setDateFormat] = useState<'short' | 'long'>('long');

  const getValue = () => {
    if (useDateRange) {
      if (dateRange[0] && dateRange[1]) {
        return date && time && useTime && timeRange[0] && timeRange[1]
          ? `${format(
              new Date(`${dateRange[0]}T${timeRange[0]}:00`),
              dateFormat === 'long'
                ? `EEEE do LLLL yyyy${useTime ? " '-' HH':'mm" : ''}`
                : `dd-MM-yyyy${useTime ? " '-' HH':'mm" : ''}`,
            )} - ${format(
              new Date(`${dateRange[0]}T${timeRange[1]}:00`),
              dateFormat === 'long'
                ? `EEEE do LLLL yyyy${useTime ? " '-' HH':'mm" : ''}`
                : `dd-MM-yyyy${useTime ? " '-' HH':'mm" : ''}`,
            )}`?.toLowerCase()
          : date?.toLowerCase()
          ? `${format(
              new Date(`${dateRange[0]}T00:00:00`),
              dateFormat === 'long' ? `EEEE do LLLL yy` : `dd-MM-yy`,
            )} - ${format(
              new Date(`${dateRange[1]}T00:00:00`),
              dateFormat === 'long' ? `EEEE do LLLL yy` : `dd-MM-yy`,
            )}`?.toLowerCase()
          : '';
      }
    } else {
      return date && time && useTime
        ? format(
            new Date(`${date}T${time}:00`),
            dateFormat === 'long'
              ? `EEEE do LLLL yyyy${useTime ? " '-' HH':'mm" : ''}`
              : `dd-MM-yyyy${useTime ? " '-' HH':'mm" : ''}`,
          )?.toLowerCase()
        : date?.toLowerCase()
        ? format(
            new Date(`${date}T00:00:00`),
            dateFormat === 'long' ? `EEEE do LLLL yyyy` : `dd-MM-yyyy`,
          )?.toLowerCase()
        : '';
    }
  };

  const [showModal, setShowModal] = useState(false);

  const defaults = {
    showModal: false,
    useTime: false,
    time: '01:00',
    date: format(new Date(), 'yyyy-MM-dd'),
    onSelectDate: () => null,
    onSelectTime: () => null,
    closeOnSelect: true,
    showDatePicker: false,
    setShowDatePicker: () => null,
    onChange: () => null,
    minDate: undefined,
    maxDate: undefined,
  };

  return (
    <View
      ref={wrap}
      onLayout={() => {
        if (wrap.current) {
          wrap.current.measure((_, __, width) => {
            if (width < 330) setDateFormat('short');
          });
        }
      }}
    >
      <TextInput
        onChangeText={() => null}
        style={style}
        value={getValue()}
        invalid={invalid}
        label={label}
        required={required}
        instructions={instructions}
        placeholder={placeholder}
        icon={<DateIcon />}
        onClear={editable ? onClear : undefined}
        editable={editable}
        onPress={() => {
          if (!editable) return;
          setShowModal(true);
        }}
        margin={margin}
        isHighlighted={isHighlighted}
      />
      <ModalInline
        title=""
        size="small"
        showModal={showModal}
        close={() => setShowModal(false)}
        action={onSubmit}
        actionText="select"
      >
        <DateTimePicker
          {...defaults}
          closeOnSelect={true}
          date={date}
          time={time}
          useTime={useTime}
          onSelectDate={onSelectDate}
          onSelectTime={onSelectTime}
          onChange={onChange}
          minDate={minDate}
          maxDate={maxDate}
          useDateRange={useDateRange}
          dateRange={dateRange}
          timeRange={timeRange}
          showDatePicker={false}
          setShowDatePicker={() => null}
        />
      </ModalInline>
    </View>
  );
};

export default DateInput;
